<template>
	<div id="login" class="app flex-row text-center">
		<img class="logo" :src="config.Params.MainLogo"><br>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 mt-4">
					<div class="card-group" style="border: 2px solid  #ffffff;">
						<div class="card p-4" style="margin: 0;">
							<div class="card-body">
								<h1>Login</h1>
								<p class="text-muted">Sign In to your {{ SiteName }} Administrative Account</p>
								<el-form ref="loginForm" label-position="top" :model="user" label-width="120px">
									<el-form-item
                                        prop="username"
                                        :rules="[
                                            { required: true, message: 'Email is required'},
                                            { type: 'email',  message: 'Email is invalid', trigger: 'blur'}

                                        ]"
                                    >
	                                    <el-input placeholder="Email" v-model="user.username" size="large">
	                                        <template #prepend><i class="fas fa-user"></i></template>
	                                    </el-input>
	                                </el-form-item>
									<el-form-item
                                        prop="password"
                                        :rules="[
                                            { required: true, message: 'Password is required'}

                                        ]"
                                    >
	                                    <el-input placeholder="Password" type="password" v-model="user.password"  size="large" @keyup.enter="login" show-password>
	                                        <template #prepend><i class="fas fa-lock"></i></template>
	                                    </el-input>
	                                </el-form-item>
	                            </el-form>
							</div>
							<div class="row text-center">
								<div class="col-12">
									<el-button type="success" class="btn-success px-4 loginButton" v-on:click="login()">Login</el-button>
								</div>
							</div>
							<div v-if="!config.Params.googlelogin || config.Params.googlelogin == 'y' || !config.Params.facebooklogin || config.Params.facebooklogin == 'y' || !config.Params.linkedinlogin || config.Params.linkedinlogin == 'y'" class="justify-content-center">
								<div class="sociallogin_sources">
									<div class="login_with"><div class="login_with_text">or Login with</div></div>
									<div class="justify-content-center">
										<div class="sociallogin_group">
											<div v-if="!config.Params.googlelogin || config.Params.googlelogin == 'y'" id="loginSource_googleplus" class="flL loginSource_googleplus login_source customGPlusSignIn" data-loginsource="googleplus" @click="socialLogin('googleplus')">
												<img class="socialLogin_icon" src="../assets/images/google-plus.svg">
												<span class="socialLogin_text">Google</span>
											</div>
											<div v-if="!config.Params.facebooklogin || config.Params.facebooklogin == 'y'" class="flL loginSource_facebook fb-login-button login_source" data-loginsource="facebook" @click="socialLogin('facebook')">
												<img class="socialLogin_icon" src="../assets/images/facebook.svg">
												<span class="socialLogin_text">Facebook</span>
											</div>
											<div v-if="!config.Params.linkedinlogin || config.Params.linkedinlogin == 'y'" class="flL loginSource_linkedin login_source customLinkedin" data-loginsource="linkedin" @click="socialLogin('linkedin')">
												<img class="socialLogin_icon" src="../assets/images/linkedin.svg">
												<span class="socialLogin_text">Linkedin</span>
											</div>
										</div>
		                            </div>
	                            </div>
							</div>
							<div class="row">
                                <div class="text-right col-6 forgotPassword">
                                    <a href="javascript:;" v-on:click.stop="gotoForgotPassword" >Forgot Password?</a>
                                </div>
                                <div class="text-left col-6 privacypolicy">
                                    <a href="javascript:;" v-on:click.stop="privacypolicy" >Privacy Policy</a>
                                </div>
                            </div>
						</div>
						<div class="card text-white bg-primary py-5 d-md-down-none" style="width: 44%;margin: 0;">
							<ContactUs ref="contactus" :show="showContactUsForm" :caller="'login'" @close="showContactUsForm = false"></ContactUs>
							<div class="card-body text-center">
								<div>
									<h2>Welcome</h2>
									<div v-html="WelcomeText"></div>
									<el-button type="primary" class="btn-primary mt-3" v-on:click.stop="showContactUs">Contact Us</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        <modal v-if="showModal" @close="showModal = false" width="700px" :draggable="false" :showClose="true">
	        <template v-slot:header>
		        <h5 class="modal-title">
			        Privacy Policy
		        </h5>
	        </template>
	        <template v-slot:body >
		        <span v-html="privacytext"></span>
	        </template>
	        <template v-slot:footer>
		        <el-button type="info" @click="showModal = false;">Close</el-button>
	        </template>
        </modal>
		<modal v-if="showRoleModal" @close="showRoleModal = false" width="400px" :draggable="false" :showClose="true">
			<template v-slot:header>
				<h5 class="modal-title">
					Select a Role to Login As
				</h5>
			</template>
			<template v-slot:body >
				<login-select v-loading="loading" :config="config" :parentrefs="parentrefs" :RoleSelectText="RoleSelectText"  :Roles="Roles" @roleselected="roleSelected"></login-select>
			</template>
			<template v-slot:footer>
				&nbsp;
			</template>
		</modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Modal from './global/Modal.vue';
import ContactUs from './global/ContactUs.vue';
import Util from '../assets/js/util.js';
import LoginSelect from "./LoginSelect";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
export default {
	name: 'Login',
	data() {
		return {
            currentStatus : STATUS_INITIAL,
			loading: false,
			Roles : [],
            showModal: false,
			showRoleModal : false,
			privacytext : '',
			validusername : '',
			user : {
				username: '',
	            password: ''
	        },
	        showContactUsForm: false,
	        loginpopup : '',
	        loginTO : '',
			loginresponse : '',
			RoleSelectText : 'Select the League you wish to login to manage',
			WelcomeText : 'Welcome to our Admin Site.  If you have a login, please use it now, otherwise, contact us for access details.'
		}
	},
	props:["config", "parentrefs"],
    components: {
	    LoginSelect,
        ContactUs,
        Modal
    },
	computed: {
		...mapGetters({ currentUser: 'currentUser' }),
		SiteName() {
			return this.config.Params.SiteName
		}
	},
	created () {
		this.checkCurrentLogin();
	},
	methods: {
	    checkCurrentLogin () {
			if (this.currentUser) {
				this.$router.replace(this.$route.query.redirect || '/dashboard')
			}
        },
        showContactUs () {
            this.showContactUsForm = Math.random();
        },
        socialLogin(provider) {
            if (this.currentStatus == STATUS_SAVING) {
                return true;
            }
            this.doLogin(provider);
        },
	    login() {
            if (this.currentStatus == STATUS_SAVING) {
                return true;
            }
            this.$refs['loginForm'].validate((valid) => {
            	if (valid) {
	                this.currentStatus = STATUS_SAVING;
					axios.post('/Login/Validation', {
						username: this.user.username,
						password: this.user.password,
						CustomerID: this.config.CustomerID
					}).then(response => {
						this.currentStatus = STATUS_INITIAL;
						if (response.data.result == 'success') {
							this.validusername = this.user.username;
			                this.loginSuccessful(response);
		                } else {
		                    this.loginFailed()
							this.displayPopup (response.data.message);
							this.user.password = '';
		                }
					}).catch(response => {
						this.currentStatus = STATUS_INITIAL;
						this.loginFailed();
					});

		        } else {
		            this.displayPopup ('Please enter both a valid Email and a Password');
		        }
		    });
	    },
		loginSuccessful (response) {
			this.loginresponse = response;
			if (response.data.Roles.length > 1) {
				this.Roles = response.data.Roles;
				this.showRoleModal = true;
			} else {
				this.loginresponse.data.Roles = response.data.Roles[0];
				this.getLoginData();
			}
		},
		roleSelected (index) {
	    	this.loading = true;
			localStorage.activeRole = index;
			this.loginresponse.data.Roles = this.loginresponse.data.Roles[index];
			this.getLoginData ();
		},
		getLoginData() {
			if (this.currentStatus == STATUS_SAVING) {
				return true;
			}
			this.currentStatus = STATUS_SAVING;
			axios.post('/Login/getroledata', {
				username: this.validusername,
				RoleSelected : this.loginresponse.data.Roles

			}).then(response => {
				this.currentStatus = STATUS_INITIAL;
				if (response.data.result == 'success') {
					this.finishlogin(response);
				} else {
					this.loginFailed()
					this.displayPopup (response.data.message);
					this.user.password = '';
				}
			}).catch(response => {
				this.currentStatus = STATUS_INITIAL;
				this.loginFailed();
			});
		},
		finishlogin (response) {
			if (!response.data.token) {
				this.loginFailed()
				return;
			}
			this.currentStatus = STATUS_SUCCESS;
			this.error = false;
			localStorage.token = response.data.token;

			localStorage.Roles = JSON.stringify(response.data.Roles);
			localStorage.RoleSecurity = JSON.stringify(response.data.RoleSecurity);
			if (response.data.super && response.data.super == 'y') {
				localStorage.Super = 'y';
			} else {
				localStorage.Super = '';
			}

			Util.createCookie('login', 'y');
			//Cookies.set('login', 'y', { domain: 'analyzer.valley' });
			this.$store.dispatch('login');
			let returnURL = localStorage.returnURL;
			delete localStorage.returnURL;
			self = this;
			if (!returnURL || returnURL == '/logout' || returnURL == '/Login' || returnURL == '/login') {
				setTimeout(() => { self.$router.go('/dashboard'); }, 100);
				this.$router.replace('/dashboard');
			} else if (returnURL.indexOf('http') === -1) {
				setTimeout(() => { self.$router.go(returnURL); }, 100);
				this.$router.replace(returnURL);
			} else {
				location.href = returnURL;
			}
		},
		loginFailed () {
			this.currentStatus = STATUS_FAILED;
			this.$store.dispatch('logout');
			delete localStorage.token;
			Util.eraseCookie('login');
		},
		gotoForgotPassword () {
			this.$router.replace('/forgotpassword');
		},
        privacypolicy () {
            this.showModal = true;
        },
        displayPopup (message) {
			this.$alert(message, 'Login Error', {
				type: 'error',
				confirmButtonText: 'OK',
			});
        },
		doLogin(loginService) {
			switch(loginService){
				case 'facebook':
					var that = this;
					FB.login(function(response) {
						if (response.authResponse) {
							that.onFacebookLogin();
						}
					}, {
						scope: 'public_profile,email'
					});
					break;
				case 'googleplus':
					//Prompt for google signin
					location.href = ('/login?google=y');
					break;
				case 'linkedin':
                    var redirect = window.location.host;
					let url = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=' + LinkedINClientID + '&redirect_uri=https%3A%2F%2F' + redirect + '%2Flogin%2Flinkedin&state=fooobar&scope=r_liteprofile%20r_emailaddress';
					this.loginpopup = window.open(url, 'linkedinOauthRedirect', 'height=700px,width=700px');
					this.loginTO = setInterval (this.isLinkedINWindowClosed, 250);
					break;
			}
		},
		gotLoginData () {
			var that = this;
			if (!loginData || loginData == '' ) {
				setTimeout(() => {
					that.gotLoginData();
				}, 500);
			} else {
				this.saveLoginAccount(loginData);
			}
		},
		isLinkedINWindowClosed () {
			if (this.loginpopup.closed) {
				if (linkedINData) {
					this.saveLoginAccount(linkedINData);
				}
				clearInterval(this.loginTO);
			}
		},

		onSuccess(data) {
            linkedin_user_data = {
                "username": data.values[0].firstName + ' ' + data.values[0].lastName,
                "avatar_url": data.values[0].pictureUrl,
                "login_oauth" : '',
                "login_id" : data.values[0].id,
                "login_service": "linkedin",
                "login_email": data.values[0].emailAddress
            };
            if (processLinkedin_login) {
                this.saveLoginAccount(JSON.stringify(linkedin_user_data));
            }
        },

        // Handle an error response from the API call
        onError(error) {
            console.log(error);
        },
		saveLoginAccount (data) {
			var logindata = JSON.parse(data);
			axios.post('/Login/Validation', {
				username: logindata.login_email,
				social: logindata.login_service,
				CustomerID: this.config.CustomerID,
			}).then(response => {
				if (response.data.result == 'success') {
					this.validusername = logindata.login_email;
					this.loginSuccessful(response);
	                loginData = '';
                } else {
                    this.loginFailed()
					this.displayPopup (response.data.message);
                }
			}).catch(response => {
				this.loginFailed();
			});
		},

		saveLinkedinResponse (data) {
			processLinkedin_login = true;
		},
		//get facebook user profile
		onFacebookLogin () {
			var data = {};
			var that = this;
			FB.api('/me', {fields: 'id, name, email'}, function(response) {
				if(response.id) {
					data.login_service = 'facebook';
					data.login_id = response.id;
					data.username = response.name;
					data.login_email = response.email;
					that.saveLoginAccount(JSON.stringify(data));
				}
			});
		},
	    openPopupWindow(url, name, width, height) {
            var myPopupWindow = '';

            // Set defaults for screen width and attempt to get
            // actual values
            var browserWidth = 1024, browserHeight = 768;
            if (screen) {
                browserWidth = screen.availWidth;
                browserHeight = screen.availHeight;
            }
            // Set popup left and top params
            var leftPos = (browserWidth-width)/2, topPos = (browserHeight-height)/2;

            // If it is already open
            if (!myPopupWindow.closed && myPopupWindow.location) {
                myPopupWindow.location.href = (url);
            } else {
                myPopupWindow = window.open((url),name, "location=no, scrollbars=yes, resizable=yes, center=yes, toolbar=no, menubar=no, width=" + width + ", height=" + height + ", top=" + topPos + ", left=" + leftPos);
                if (!myPopupWindow.opener) {
                    myPopupWindow.opener = self;
                }
            }
            // If my main window has focus - set it to the popup
            if (window.focus) {myPopupWindow.focus();}
        }
	},
	updated () {
		this.checkCurrentLogin();
	},
	mounted () {
		if (this.$route.query.msg) {
			this.$notify({
				title: '',
				message: "You have been logged out",
				type: 'warning',
				duration: 2000,
				showClose: false
			});
	    }
        if (this.$route.query.privacy) {
            this.showModal = true;
        }
	    if (typeof loginProvider === 'undefined') {
	    	//need a hard refresh to get social login stuff
			location.href = ('/login');
	    } else {
		    loginProvider = true;
	    }
		let self = this;

		delete localStorage.ids;
        delete localStorage.RoleSecurity;
        delete localStorage.Roles;
        delete localStorage.Super;
        delete localStorage.SeasonID;
        delete localStorage.token;
        delete localStorage.filters;
        delete localStorage.filtersCST;
		axios.post('/systemtext/get', {SystemTextID : 'loginwelcome', CustomerID: this.config.CustomerID}
		)
			.then(response => {
				if (response.data.result == 'success') {
					if (response.data.SystemText) {
						this.WelcomeText = response.data.SystemText.SystemText;
					}
				}
			}).catch(response => {

		});
		axios.post('/systemtext/get', {SystemTextID : 'roleselection', CustomerID: this.config.CustomerID}
		)
			.then(response => {
				if (response.data.result == 'success') {
					if (response.data.SystemText) {
						this.RoleSelectText = response.data.SystemText.SystemText;
					}
				}
			}).catch(response => {

		});
        axios.post('/systemtext/get', {SystemTextID : 'privacy', CustomerID: this.config.CustomerID}
        )
            .then(response => {
                if (response.data.result == 'success') {
                    if (response.data.SystemText) {
                        this.privacytext = response.data.SystemText.SystemText;
                    }
                }
            }).catch(response => {

        });
		//if url has google, then query for login data
		if (this.$route.query.google) {
			var that = this;
			setTimeout(() => {
				that.gotLoginData();
			}, 500);
		}
	}
}

</script>
<style >
@media (max-width: 991.98px) {
	.d-md-down-none {
        display: none!important;
    }
}
#app.login {
    margin-top: 0px;
    height: 100vh;
    overflow: auto;
}

#login .logo {
	max-width: 50%;
	max-height: 250px;
}

#login .card-body p, .card-body h2 {
	color: #fff;
}
#login .align-items-center {
	text-align:center;
}
#app.login .page-wrapper {
      margin: 0px;
      padding-top: 20px;
}
#login .card-body {
	padding: 0px 20px;
}

.loginButton {
	width: 250px;
	margin-bottom: 10px;
}
.forgotPassword {
	border-top: 1px solid #F4F6F9;
    padding: 15px 50px 0px 0px;
}
.privacypolicy {
    border-top: 1px solid #F4F6F9;
    padding: 15px 0px 0px 50px;
}
  div.sociallogin_sources {
    position: relative;
    margin-top: 15px;
    min-height: 110px;
    border-top: 1px solid #F4F6F9;
    position: relative;
    padding-top: 16px;
    text-align:center;
    width: 100%;
}
.login_with {
    position: absolute;
    top: -14px;
    left: 50%;
}
.login_with_text {
    position: relative;
    width: auto;
    left: -50%;
    text-align: center;
    color: #999999;
}
.login_source {
    border-radius: 2px;
    padding: 11px 10px 8px 9px;
    cursor: pointer;
    margin-bottom: 10px;
	white-space: nowrap;
	line-height: 45px;
}

.login_source .socialLogin_icon {
    height: 15px;
    margin: 5px;
    position: relative;
    top: -3px;
}
.login_source .socialLogin_text {
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    margin: 0px 0px 0px 10px;
}
.flL {
	display:inline;
    margin-left: 10px;
}
.loginSource_facebook {
	background-color: #3C5B9B;
}
.loginSource_linkedin {
    background-color: #0077b5;
}
.loginSource_googleplus {
    background-color: #dd4b39;
}
.sociallogin_group {
	padding: 5px;
}
@media (max-width: 550px) {
	.login_source {
	    width: 140px;
	}
	.flL {
	    margin-left: 3px;
	}
	.sociallogin_group {
		padding: 0px;
	}
	#login .card-body {
        padding: 0px;
    }
	.card {
		margin-top: 0px;
	}
	.container {
		padding-left: 0px;
		padding-right: 0px;
	}
	.card-group {
		min-width: 310px;
	}
    .forgotPassword, .privacypolicy {
        padding: 8px;
    }
}
#app.login div.modal-body {
    height: 500px;
    overflow-y: scroll;
    text-align: left;
}
#app.login .el-input__inner {
	padding: 0px 10px;
}
#app.login .el-input__wrapper {
	padding: 0;
}
</style>
