/* global localStorage */

import User from '@/models/User'
import * as MutationTypes from './mutation-types'
import Util from '../assets/js/util.js';

const state = {
	user: User.from(localStorage.token,localStorage.Roles,localStorage.RoleSecurity)
}

const mutations = {
	[MutationTypes.LOGIN] (state) {
		state.user = User.from(localStorage.token,localStorage.Roles,localStorage.RoleSecurity)
	},
	[MutationTypes.USERUPDATE] (state) {
		state.user = User.from(localStorage.token,localStorage.Roles,localStorage.RoleSecurity)
	},
	[MutationTypes.LOGOUT] (state) {
		state.user = null
	}
}

const getters = {
	currentUser (state) {
		if (!Util.readCookie('login')) {
		    delete localStorage.token
		    // this.$store.dispatch('logout');
		}
		return state.user
	}
}

const actions = {
	login ({ commit }) {
		commit(MutationTypes.LOGIN)
	},

	userupdate ({ commit }) {
		commit(MutationTypes.USERUPDATE)
	},

	logout ({ commit }) {
		commit(MutationTypes.LOGOUT)
	}
}

export default {
	state,
	mutations,
	getters,
	actions
}
