<template>
	<div id="teamconstraints" v-loading="loading">
		<div style="width: 100%;">
			<div v-if="!ProgramIDAccess && TeamIDAccess.length == 0" class="program-select" >
				<el-select v-model="LeagueID" ref="league" placeholder="Select" class="topDropdown" @change="leagueChange" default-first-option filterable>
					<el-option
						v-for="item in Leagues" 
						:key="item.LeagueID"
						:label="item.LeagueName"
						:value="item.LeagueID"
						>
					</el-option>
				</el-select>
			</div>
            <div class="program-select mt-2" >
				<el-select v-if="Programs.length > 1" class="topDropdown" v-model="ProgramID" :placeholder="'Select ' + config.Params.programName" @change="getProgramTeamConstraints()" default-first-option filterable>
					<el-option
						v-for="item in LeaguePrograms[LeagueID]"
						:key="item.ProgramID"
						:label="item.ProgramName"
						:value="item.ProgramID">
					</el-option>
				</el-select>
				<div v-else-if="Programs.length == 1" style="height: 35px;">
                    <label class="mb-1 control-label">{{config.Params.programName}}:</label>
                    <span class="programName dropdowns">{{Programs[0].ProgramName}}</span>
                </div>
			</div>
			<div v-if="!ProgramIDAccess && TeamIDAccess.length == 0" class="program-select mt-2">
                <div v-if="ActiveSeasons.length > 0" >
                    <el-select class="topDropdown" v-model="SeasonID"  @change="getProgramTeamConstraints" placeholder="Select" default-first-option filterable>
                        <el-option v-for="season in ActiveSeasons"
                                   :value="season.SeasonID"
                                   :key="season.SeasonID"
                                   :label="season.SeasonName">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
		<div v-if="TeamIDAccess.length == 0" class="mt-4 mb-2">
			<el-radio-group v-model="ByTeamOrAll">
				<el-radio-button label="Team">Show By Team</el-radio-button>
				<el-radio-button label="All">Show All</el-radio-button>
			</el-radio-group>
		</div>
		<template v-if="ByTeamOrAll == 'Team'" v-for="(Key, index) in SortedTeams">
			<div v-if="TeamConstraints[Key]" class="mt-1 mb-4">
				<h5 style="width: 100%;font-weight: 800;">{{Key}}<el-button v-if="(LessthanMaxConstraints(Key) && modifyAccess) || isAdmin" size="small" style="margin-left: 30px;" @click.prevent="newTeamConstraint(Key)"><i class="fa-solid fa-circle-plus"></i> Add New Blackout <template v-if="!isAdmin">({{Teams[Key].MaxConstraints}} Max)</template></el-button></h5>
				<el-table
				stripe
				:data="TeamConstraints[Key]"
				:empty-text="(Teams[Key].ManageConstraints != 'y' ? 'Blackout Entry not Allowed' : 'No Team Blackouts')"
				:row-class-name="tableRowClassName"
				style="width: 100%"
				size="small"
				max-height="600">
					<el-table-column
							label="Team"
							prop="TeamName"
							min-width="150"
							align="left">
						<template #default="scope">
							<div v-html="scope.row.TeamName" style="font-size: 14px;"></div>
						</template>
					</el-table-column>
					<el-table-column
							label="Date"
							prop="StartDate"
							min-width="150"
							align="center">
						<template #default="scope">
							<el-date-picker v-model="scope.row.StartDate" default-first-option filterable
								:clearable="false"
								:disabled="!modifyAccess"
								:disabled-date="disabledDates"
								type="date"
								style="width: 140px"
								format="MMM D, YYYY"
								value-format="YYYY-MM-DD"
								@change="scope.row.operation = 'U'"
								placeholder="Blackout Date">
							</el-date-picker>
						</template>
					</el-table-column>
					<el-table-column
							label="Reason"
							min-width="250"
							align="center">
						<template #default="scope">
							<el-input v-model="scope.row.description"
								:disabled="!modifyAccess"
								type="textarea"
								@change="scope.row.operation = 'U'"
								:autosize="{ minRows: 2, maxRows: 5}"
							>
							</el-input>
						</template>
					</el-table-column>
					<el-table-column v-if="modifyAccess"
						label="Remove"
						width="120"
						align="center">
						<template #default="scope">
							<el-popconfirm
								v-if="scope.row.ConstraintSlotID > 0"
								confirm-button-text='OK'
								cancel-button-text='No, Thanks'
								@confirm="deleteTeamConstraint(scope.row.TeamName, scope.$index)"
								icon-color="red"
								width="250"
								title="Are you sure you wish to Delete this Blackout?"
							>
								<template #reference>
									<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light">
										<div class="v-btn__content">
											<i class="far fa-2x fa-trash-alt"></i>
										</div>
									</button>								
								</template>
							</el-popconfirm>  
							<button v-else type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteTeamConstraint(scope.row.TeamName, scope.$index)">
								<div class="v-btn__content">
									<i class="far fa-2x fa-trash-alt"></i>
								</div>
							</button>	
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>
		<template v-else>
			<div v-if="AllConstraints.length > 0" class="mt-1 mb-4">
				<el-table
				stripe
				:data="AllConstraints"
				:empty-text="'No Team Blackouts'"
				:row-class-name="tableRowClassName"
				style="width: 100%"
				size="small"
				max-height="600">
					<el-table-column
							label="Team"
							prop="TeamName"
							min-width="150"
							sortable
							align="left">
						<template #default="scope">
							<div v-html="scope.row.TeamName" style="font-size: 14px;"></div>
						</template>
					</el-table-column>
					<el-table-column
							label="Date"
							prop="StartDate"
							min-width="150"
							sortable
							align="center">
						<template #default="scope">
							<el-date-picker v-model="scope.row.StartDate" default-first-option filterable
								type="date"
								:disabled="true"
								style="width: 140px"
								format="MMM D, YYYY"
								value-format="YYYY-MM-DD"
								placeholder="Game Date">
							</el-date-picker>
						</template>
					</el-table-column>
					<el-table-column
							label="Reason"
							min-width="250"
							align="center">
						<template #default="scope">
							<el-input v-model="scope.row.description"
								:disabled="true"
								type="textarea"
								:autosize="{ minRows: 2, maxRows: 5}"
							>
							</el-input>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>
		<div v-if="modifyAccess && ByTeamOrAll == 'Team'" class="row buttons mt-4">
			<div class="col-12 text-center">
				<button class="btn btn-success btn-icon" type="button" @click="save">
					<i class="fas fa-check"></i> <span> Save Blackouts</span>
				</button>
				<button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)">
					<i class="fas fa-times"></i> <span>Cancel</span>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'TeamConstraints',
	    data() {
	        return {
	            loading : true,
                currentStatus : STATUS_INITIAL,
				MaxConstraints : 0,
                TeamConstraints : {},
				AllConstraints : [],
				Teams : [],
				ActiveSeasons: [],
                Programs : [],
				LeaguePrograms : [],
				SeasonID : '',
                ProgramID : '',
				SortedTeams : [],
				ByTeamOrAll : 'Team',
				StartDate : '',
				EndDate : '',
				Errors : [],
				LeagueConstraints : [],
				Leagues : [],
				LeagueID : '',
	        }
	    },
		props:["config", "parentrefs"],
	    computed: {
		    ...mapGetters({ componentIDs: 'componentIDs' }),
		    ...mapGetters({ currentUser: 'currentUser' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
			TeamIDAccess () {
                return this.$TeamIDAccess();
			},
			isAdmin() {
				if (!this.ProgramIDAccess && this.TeamIDAccess.length == 0 ){
					return true;
				} else {
					return false;
				}
			},
		},
		methods: {
			reset () {
				this.getProgramTeamConstraints();
			},
			leagueChange () {
				this.ProgramID = '';
				this.TeamConstraints = {};
				this.$emit('updateHelpID', this.LeagueID);
			},
			LessthanMaxConstraints(TeamName) {
				var numConstraints = 0;
				for (let i = 0; i < this.TeamConstraints[TeamName].length; i++) {
					if (this.TeamConstraints[TeamName][i].recordstatus == 'A') {
						numConstraints++;
					}
				}
				if (numConstraints >= this.Teams[TeamName].MaxConstraints) {
					return false;
				} else {
					return true;
				}
			},
			disabledDates(date1) {
				var cmpDate = date1.toISOString().split('T')[0];
				if (cmpDate >= this.StartDate && cmpDate <= this.EndDate) {
					for (let i = 0; i < this.LeagueConstraints.length; i++) {
						if (this.LeagueConstraints[i]['currentDate'] == cmpDate) {
							return true;
						}
					}
					return false;
				} else {
					return true;
				}
			},
			newTeamConstraint(TeamName) {
				//loop through constraint to find the one with team name property, then add array
				for (const property in this.TeamConstraints) {
					if (property == TeamName) {
						var color = this.Teams[TeamName].color;
						this.TeamConstraints[TeamName].push({ConstraintSlotID : 0, StartDate : '', description :'', TeamID : this.Teams[TeamName].TeamID, TeamName : TeamName, SeasonID : this.SeasonID, ProgramID : this.ProgramID, color : color, recordstatus : 'A', operation : 'U'});
						break;
					}
				}
			},
			tableRowClassName({row, rowIndex}) {
				if (row.Error == 'y') {
                    return 'deleted-row';
                } else {
					return '';
				}
			},
			getInitialData () {
				var tables = ['ActiveSeasons', 'Programs', 'Leagues', 'LeaguePrograms'];
				axios.post('/index/tableData', {'tables': tables },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let value of tables) {
								this[value] = response.data[value];
							}
							if (!this.ProgramIDAccess && this.TeamIDAccess.length == 0) {
								if (this.componentIDs.AssignProgramID) {
									this.ProgramID = this.componentIDs.AssignProgramID;
								} else {
									this.ProgramID = this.Programs[0].ProgramID;
								}
								this.SeasonID = this.ActiveSeasons[0]['SeasonID'];
								for (let  i = 0; i < this.ActiveSeasons.length; i++) {
									if (this.ActiveSeasons[i]['Params']['DefaultSeason'] == 'y') {
										this.SeasonID = this.ActiveSeasons[i]['SeasonID'];
										break;
									}
								}
							} else{
								this.ProgramID = this.Programs[0].ProgramID;
							}
							if (!this.LeagueID) {
								this.LeagueID = this.Leagues[0]['LeagueID'];
							}
							this.getProgramTeamConstraints();
						}
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
					this.loading = false;
				});
			},
			getProgramTeamConstraints() {
				if (this.LeagueID) {
					this.loading = true;
					this.TeamConstraints = { };
					axios.post('/analyzer/constraints/getteamconstraints', {LeagueID : this.LeagueID, SeasonID : this.SeasonID, ProgramID : this.ProgramID, TeamIDs : this.TeamIDAccess }
					)
					.then(response => {
						if (response.data.result == 'success') {
							this.SortedTeams = (response.data.SortedTeams ? response.data.SortedTeams : []);
							this.AllConstraints = (response.data.AllConstraints ? response.data.AllConstraints : []);
							this.Teams = (response.data.Teams ? response.data.Teams : []);
							this.SeasonID = response.data.SeasonID;
							this.StartDate = response.data.StartDate;
							this.EndDate = response.data.EndDate;
							this.TeamConstraints = (response.data.TeamConstraints ? response.data.TeamConstraints : {});
							this.LeagueConstraints = (response.data.LeagueConstraints ? response.data.LeagueConstraints : []);
						}
						this.loading = false;
					}).catch(response => {
						this.loading = false;
					});
				} else {
					this.loading = false;
				}
			},
			save() {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				var blankDate = false;
				var duplicateDate = false;
				for (const property in this.TeamConstraints) {
					var dates = [];
					for (let i = 0; i < this.TeamConstraints[property].length; i++) {
						if (this.TeamConstraints[property][i].StartDate == '' && this.TeamConstraints[property][i].recordstatus == 'A') {
							blankDate = true;
							this.TeamConstraints[property][i].Error = 'y';
						} else {
							if (dates.indexOf(this.TeamConstraints[property][i].StartDate) !== -1) {
								duplicateDate = true;
								this.TeamConstraints[property][i].Error = 'y';
							} else {
								dates.push(this.TeamConstraints[property][i].StartDate);
								this.TeamConstraints[property][i].Error = 'n';
							}
						}
					}
				}
				if (blankDate && !duplicateDate) {
					this.$alert('Date must be entered for all Blackouts.', 'Warning', {
						dangerouslyUseHTMLString: true,
						customClass: 'errormsg',
						confirmButtonText: 'OK',
						type: 'warning',
						showClose: true,
						showCancelButton: false
					});
					return true;
				} else if (!blankDate && duplicateDate) {
					this.$alert('You have teams that have the same Blackout date multiple times.', 'Warning', {
						dangerouslyUseHTMLString: true,
						customClass: 'errormsg',
						confirmButtonText: 'OK',
						type: 'warning',
						showClose: true,
						showCancelButton: false
					});
					return true;
				} else if (blankDate && duplicateDate) {
					this.$alert('Date must be entered for all Blackouts. You have teams that have the same Blackout date multiple times.', 'Warning', {
						dangerouslyUseHTMLString: true,
						customClass: 'errormsg',
						confirmButtonText: 'OK',
						type: 'warning',
						showClose: true,
						showCancelButton: false
					});
					return true;
				}
				this.loading = true;
				this.currentStatus = STATUS_SAVING;
				axios.post('/analyzer/constraints/saveteamconstraintslots', {TeamConstraints : this.TeamConstraints}
				)
				.then(response => {
					if (response.data.result == 'success') {
						this.currentStatus = STATUS_INITIAL;
						this.$notify({
                                    title: 'Success',
                                    message: "Blackouts have been saved",
                                    type: 'success',
                                    duration: 1500,
                                    showClose: false
                                });
						this.getProgramTeamConstraints();
					} else {
						this.currentStatus = STATUS_FAILED;
						this.displayPopup (response.data.message);
					}
					this.loading = false;
				}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.loading = false;
				});
			},
			deleteTeamConstraint(key, idx) {
				if (this.TeamConstraints[key][idx].ConstraintSlotID > 0) {
					this.loading = true;
					axios.post('/analyzer/constraints/deleteconstraintslot', {ConstraintSlotID : this.TeamConstraints[key][idx].ConstraintSlotID}
					)
					.then(response => {
						if (response.data.result == 'success') {
							this.TeamConstraints[key].splice(idx,1);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
						}
						this.loading = false;
					}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.loading = false;
					});
				} else {
					this.TeamConstraints[key].splice(idx,1);
				}
			},
	        displayPopup (message) {
                this.$alert(message, 'Blackout Error', {
                    confirmButtonText: 'OK',
                });
            }
		},
		mounted () {
			this.LeagueID = (this.currentUser.LeagueID ? this.currentUser.LeagueID  : '');
			this.getInitialData();
		}
	}
</script>

<style>
.mini-sidebar .left-sidebar .tableheader {
	left: 75px;
}
.left-sidebar .tableheader {
	left: 255px;
}
#teamconstraints .el-table__row.deleted-row td, #teamconstraints .el-table--striped .el-table__row--striped.deleted-row td {
    background-color: rgb(220, 53, 69, 0.1);
}
</style>
<style scoped>
#teamconstraints {
	max-width: 920px;
}
.tableheader {
	font-weight: 800;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	height: 50px;
	vertical-align: middle;
	line-height: 50px;
}

.games {
	padding: 10px 0px;
	font-size: 12px;
	vertical-align: middle;
	line-height: 14px;
}
.games:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}
.select {
	margin-top: 7px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
	width: auto;
}
.program-select {
	margin-bottom: 15px;
}
.el-select {
	width: 100%;
	max-width: 300px;
}
.programName {
	display: inline-block;
	font-weight: 800;
	margin-top: 5px;
}
.program-select label {
	position: absolute;
	margin-top: 5px
}
.dropdowns {
	margin-left: 60px;
	position: absolute;
	width: 100%;
}
</style>
