<template></template>

<script>

import axios from 'axios';
import Util from '../assets/js/util.js';

export default {
	name: 'Logout',
	mounted () {
		axios.post('/Logout/user',
		).then(response => {
		    Util.eraseCookie('login');
		    delete localStorage.token;
			delete localStorage.Super;
		    this.$store.dispatch('logout');
		    this.$router.push('/login?msg=logout');
		}).catch(response => {
		    Util.eraseCookie('login');
		    delete localStorage.token;
		    this.$store.dispatch('logout');
		    this.$router.push('/login');
		});
	}
}
</script>
