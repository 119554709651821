<template>
    <div id="reportdisplay">
        <ReportWriter v-if="ReportID || ReportCode" :config="config" embed="y" :DisplayReportCode="ReportCode" :DisplayReportID="ReportID" :parentrefs="parentrefs" />
    </div>
</template>

<script>
    import ReportWriter from './ReportWriter.vue';

    export default {

        name: "ReportDisplay",
        components: {
            ReportWriter
        },
        data() {
            return {
                ReportID : '',
                ReportCode : '',
            }
        },
        props: ["config","parentrefs"],

        mounted() {

            if (this.$route.params.reportid.indexOf('-') === -1) {
                this.ReportID = this.$route.params.reportid;
            } else {
                var pieces = this.$route.params.reportid.split('-');
                this.ReportCode = pieces[0];
            }

        }
    }

</script>

<style>
#app.reportdisplay .page-wrapper {
    margin: 0px;
}
</style>
