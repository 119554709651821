<template>
	<div id="programcontacts" :class="{'isAdminContacts' : isAdminContacts}">
		<div class="row">
			<div class="col-12 fullpage">
				<div class="program-select">
					<label class="mb-1 control-label">{{config.Params.programName}}:</label>
					<el-select v-if="ActivePrograms.length > 1" v-model="ProgramID" :disabled="ProgramIDAccess" class="topDropdown dropdowns" @change="refreshData" :placeholder="'All Contacts or Select ' + config.Params.programName" default-first-option filterable clearable>
						<el-option v-for="type in ActivePrograms"
								   :value="type.ProgramID"
								   :key="type.ProgramID"
								   :label="type.ProgramName">
						</el-option>
					</el-select>
					<div v-else-if="ActivePrograms.length == 1" class="programName dropdowns">&nbsp;{{ActivePrograms[0].ProgramName}}</div>
				</div>
			</div>
		</div>
        <div v-if="availableSeasons.length > 0 && !isAdminContacts" class="row mt-1">
            <div class="col-12 fullpage">
                <div class="program-select">
                    <label  class="mb-1 control-label">Season:&nbsp;&nbsp; </label>
                    <el-select class="topDropdown dropdowns" v-model="SeasonID"  @change="refreshContactData" placeholder="Select" default-first-option filterable>
                        <el-option v-for="season in availableSeasons"
                                   :value="season.SeasonID"
                                   :key="season.SeasonID"
                                   :label="season.SeasonName">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-4 fullpage" :class="{'card' : !isAdminContacts, 'AdminContacts' : isAdminContacts}">
                <el-tabs v-model="activeTab">
                    <el-tab-pane :label="(isAdminContacts ? '' : 'Contacts')" name="Contacts">
                        <div class="row mt-4">
                            <div class="col-12 fullpage">
                                <p v-if="isAdminContacts">A League Rep, President and Treasury contact information must be provided. Items that are Bolded are to be shown on Team Page on the Public Web Site. All other information is only visible to the League office.</p>
                            </div>
                        </div>
                        <div class="row" v-if="!ProgramIDAccess && TeamIDAccess.length == 0">
                            <div class="col-12 mb-4" :class="{'fullpage' : isAdminContacts}">
                                <el-button v-if="createAccess" @click.prevent="newContact()" style="margin-right: 20px;"><i class="fa-solid fa-circle-plus"></i> Add New</el-button>
                                <el-button v-if="$propertyAccess('Exports') && ((adminContacts.length > 0 && isAdminContacts) || (teamContacts.length > 0 && !isAdminContacts))" type="info" class="btn-secondary"  @click="exportCSV">
                                    <i class="fas fa-file-export"></i>  <span>Export CSV</span>
                                </el-button>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12 mb-4">&nbsp;</div>
                        </div>
                        <div v-if="isAdminContacts" class="row">
                            <div class="col-12 fullpage" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
                                unselectable="on"
                                onselectstart="return false;"
                                onmousedown="return false;">
                                <el-table
                                    :data="adminContacts"
                                    stripe border
                                    key="admincontacts"
                                    size="small"
                                    style="width: 100%">
                                    <el-table-column v-if="!ProgramIDAccess && TeamIDAccess.length == 0" type="expand" fixed>
                                        <template #default="props">
                                            <span class="json" v-html="'<pre>'+props.row.ParamsF+'</pre>'"></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            v-if="!ProgramID"
                                            prop="ProgramName"
                                            :label="config.Params.programName"
                                            min-width="150">
                                    </el-table-column>
                                    <el-table-column
                                            prop="Name"
                                            label="Name"
                                            min-width="150">
                                    </el-table-column>
                                    <el-table-column
                                            prop="ContactTypeName"
                                            label="Contact Type"
                                            :filters="ContactTypesFilter"
                                            :filter-method="filterContactType"
                                            filter-placement="bottom-end"
                                            :filtered-value="filterValue"
                                            align="left"
                                            width="150px">
                                    </el-table-column>
                                    <el-table-column
                                            prop="PhoneNumbers"
                                            label="Phone Numbers"
                                            min-width="120">
                                        <template #default="scope">
                                            <div v-html="scope.row.PhoneNumbers"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="EMails"
                                        label="Emails"
                                        min-width="200">
                                        <template #default="scope">
                                            <div v-html="scope.row.EMails"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="Address"
                                        label="Address"
                                        min-width="200">
                                        <template #default="scope">
                                            <div v-html="scope.row.Address"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="ProgramIDAccess || TeamIDAccess.length > 0"
                                        prop="UserParams.LastLogin"
                                        label="TimeStamps"
                                        min-width="200">
                                        <template #default="scope">
                                            <span v-if="scope.row.Params && scope.row.Params.Registration" v-html="'Registration: ' + scope.row.Params.Registration+'<br>'"></span>
                                            <span v-if="scope.row.Params && scope.row.Params.Activation" v-html="'Activation: ' + scope.row.Params.Activation"></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="modifyAccess && TeamIDAccess.length == 0"
                                        label=""
                                        width="140"
                                        align="center">
                                        <template #default="scope">
                                            <button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editContact(scope.$index, scope.row)">
                                                <div class="v-btn__content">
                                                    <i class="far fa-2x fa-edit"></i>
                                                </div>
                                            </button>
                                            <button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteCnfm(scope.$index, scope.row)">
                                                <div class="v-btn__content">
                                                    <i class="far fa-2x fa-trash-alt"></i>
                                                </div>
                                            </button>
                                            <button v-if="scope.row.recordstatus != 'A'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="activateContact(scope.$index, scope.row)">
                                                <div class="v-btn__content">
                                                    <i class="danger fas fa-times-circle"></i>
                                                </div>
                                            </button>
                                            <div v-else style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="success fas fa-check-circle"></i></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-12" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
                                unselectable="on"
                                onselectstart="return false;"
                                onmousedown="return false;">
                                <el-table
                                    :data="teamContacts"
                                    border
                                    key="teamcontacts"
                                    size="small"
                                    :span-method="arraySpanMethod"
                                    :row-class-name="tableRowClassName"
                                    style="width: 100%">
                                    <el-table-column v-if="!ProgramIDAccess && TeamIDAccess.length == 0" type="expand" fixed>
                                        <template #default="props">
                                            <span class="json" v-html="'<pre>'+props.row.ParamsF+'</pre>'"></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            v-if="!ProgramID"
                                            prop="ProgramName"
                                            :label="config.Params.programName"
                                            min-width="150">
                                    </el-table-column>
                                    <el-table-column
                                        prop="Name"
                                        label="Name"
                                        min-width="150">
                                    </el-table-column>
                                    <el-table-column
                                            prop="ContactTypeName"
                                            label="Team Role"
                                            min-width="120">
                                    </el-table-column>
                                    <el-table-column
                                            prop="PhoneNumbers"
                                            label="Phone Numbers"
                                            min-width="120">
                                        <template #default="scope">
                                            <div v-html="scope.row.PhoneNumbers"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="EMails"
                                            label="Emails"
                                            min-width="200">
                                        <template #default="scope">
                                            <div v-html="scope.row.EMails"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="Address"
                                            label="Address"
                                            min-width="200">
                                        <template #default="scope">
                                            <div v-html="scope.row.Address"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="ProgramIDAccess || TeamIDAccess.length > 0"
                                        prop="UserParams.LastLogin"
                                        label="TimeStamps"
                                        min-width="200">
                                        <template #default="scope">
                                            <span v-if="scope.row.Params && scope.row.Params.Registration" v-html="'Registration: ' + scope.row.Params.Registration+'<br>'"></span>
                                            <span v-if="scope.row.Params && scope.row.Params.Activation" v-html="'Activation: ' + scope.row.Params.Activation"></span>
                                            <span v-if="scope.row.recordstatus == 'I'" v-html="'Activation: Pending'"></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-if="modifyAccess"
                                        label=""
                                        width="140"
                                        align="center">
                                        <template #default="scope">
                                            <template v-if="TeamAccess(scope.row) || TeamIDAccess.length == 0">
                                                <button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editContact(scope.$index, scope.row)">
                                                    <div class="v-btn__content">
                                                        <i class="far fa-2x fa-edit"></i>
                                                    </div>
                                                </button>
                                                <button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteCnfm(scope.$index, scope.row)">
                                                    <div class="v-btn__content">
                                                        <i class="far fa-2x fa-trash-alt"></i>
                                                    </div>
                                                </button>
                                                <button v-if="scope.row.recordstatus != 'A'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="activateContact(scope.$index, scope.row)">
                                                    <div class="v-btn__content">
                                                        <i class="danger fas fa-times-circle"></i>
                                                    </div>
                                                </button>
                                                <div v-else style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="success fas fa-check-circle"></i></div>
                                            </template>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="!isAdminContacts && $propertyAccess('Links') && ProgramID && ProgramLeagues.length > 0 && LeagueLinks" :label="(isAdminContacts ? '' : 'Registration Links')" name="Links">
                        <div class="RegistrationLinks">
                            <span v-html="teamcontactregistrationinstructions"></span>
                            <template v-for="League in ProgramLeagues">
                                <template v-if="TeamContactRegistrationSeasonID(League.LeagueID) == SeasonID && TeamContactRegistration(League.LeagueID) != 'n'">
                                    <div class="LeagueTitle mt-2"  style="padding-top: 10px; border-top: 1px solid #ccc;">
                                        <strong>{{League.LeagueName}}</strong>
                                    </div>
                                    <span >
                                        <template v-for="Season in Seasons">
                                            <div v-if="Season.SeasonID == SeasonID && ProgramCode[Season.SeasonID] && TeamContactRegistrationSeasonID(League.LeagueID) == Season.SeasonID">
                                                <div class="mt-1">
                                                    <strong>Season: {{Season.SeasonName}}</strong>
                                                </div>
                                                <div v-if="TeamContactRegistration(League.LeagueID) == 'y'" class="mt-1">
                                                    Team Contact Registration Link:<br><div @focus="$event.target.select()" class="regLink el-input__wrapper" v-html="host+'/'+League.RegistrationSettings.LeagueDomain+'/teamcontactregistration?code='+ProgramCode[Season.SeasonID]" /><br>
                                                </div>
                                                <div v-else-if="TeamContactRegistration(League.LeagueID) == 'c'" class="mt-1 regclosed" v-html="League.RegistrationSettings.Params.ClosedMessage">
                                                </div>
                                                <div v-else class="mt-1">
                                                    No Team Contact Registration
                                                </div>
                                            </div>
                                        </template>
                                    </span>
                                </template>
                            </template>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
		<modal v-if="showModal" @close="showModal = false" @refreshContactData="refreshContactData" top="400px">
			<template v-slot:header>
				<h5 class="modal-title">
					{{modalTitle}}
				</h5>
			</template>
			<template v-slot:body >
				<Profile ref="profile" :contactData="modalData" :config="config" :calling="(adminAccess ? 'UniversalSearch' : (isAdminContacts ? 'ProgramContacts' : 'TeamContacts'))" :ProgramIDAccess="ProgramIDAccess" :TeamIDs="TeamIDAccess" :SeasonID="SeasonID"  @close="showModal = false" @refreshContactData="refreshContactData" />
			</template>
			<template v-slot:footer>
				{{modalFooter}}
			</template>
		</modal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Modal from './global/Modal.vue';
	import axios from 'axios';
	import Profile from './Profile.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'ProgramContacts',
		components: {
			Modal,
			Profile
		},
	    data() {
	        return {
                currentStatus : STATUS_INITIAL,
                activeTab : 'Contacts',
	            modalTitle: 'Edit Contact',
	            modalFooter: '',
	            showModal: false,
		        ProgramID : '',
                ActivePrograms : [],
                user : [],
                adminContacts : [],
                teamContacts : [],
				ContactTypesFilter : [],
				filterValue : [],
                ProgramLeagues: [],
                Seasons : [],
                SeasonID : '',
                teamcontactregistrationinstructions : '',
                host: '',
                ProgramCode: '',
                Params : [],
	        }
	    },
	    props:["config", "parentrefs"],
	    computed: {
	        ...mapGetters({ currentUser: 'currentUser' }),
		    ...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        createAccess () {
	            return this.$createAccess();
	        },
		    ProgramIDAccess () {
			    return this.$ProgramIDAccess();
		    },
		    TeamIDAccess () {
			    return this.$TeamIDAccess();
		    },
            adminAccess() {
				return (this.currentUser.gid >= 500 ? true : false);
			},
            availableSeasons () {
                var SeasonIDs = [];
                var seasons = [];
                if (this.adminAccess) {
                    return this.Seasons;
                }
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (SeasonIDs.indexOf(this.ProgramLeagues[i].ActiveSeasonID) === -1) {
                        SeasonIDs.push(this.ProgramLeagues[i].ActiveSeasonID);
                    }
                    if (this.ProgramLeagues[i].RegistrationSettings.Params && SeasonIDs.indexOf(this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID) === -1) {
                        SeasonIDs.push(this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID);
                    }
                }
                for (let i = 0; i < this.Seasons.length; i++) {
                    if (SeasonIDs.indexOf(this.Seasons[i]['SeasonID']) !== -1) {
                        seasons.push(this.Seasons[i]);
                        if (!this.SeasonID) {
                            this.SeasonID = this.Seasons[i]['SeasonID'];
                        }
                    }
                }
                return seasons;
            },
            isAdminContacts() {
                return (this.$route.fullPath.indexOf('program') !== -1 ? true : false);
            },
            LeagueLinks() {
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (this.TeamContactRegistrationSeasonID(this.ProgramLeagues[i].LeagueID) && this.TeamContactRegistration(this.ProgramLeagues[i].LeagueID) != 'n' && this.ProgramCode[this.SeasonID] && this.TeamContactRegistrationSeasonID(this.ProgramLeagues[i].LeagueID) == this.SeasonID) {
                        return true;
                    }
                }
                return false;
            },
		},
		methods: {
			reset () {
				this.getGames();
			},
            TeamContactRegistration(LeagueID) {
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (this.ProgramLeagues[i].LeagueID == LeagueID) {
                        if (this.ProgramLeagues[i].RegistrationSettings.Params && this.ProgramLeagues[i].RegistrationSettings.Params.AllowRegistration ) {
                            return this.ProgramLeagues[i].RegistrationSettings.Params.AllowRegistration;
                        }
                    }
                }
                return 'n';
            },
            TeamContactRegistrationSeasonID(LeagueID) {
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (this.ProgramLeagues[i].LeagueID == LeagueID) {
                        if (this.ProgramLeagues[i].RegistrationSettings.Params && this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID ) {
                            return this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID ;
                        }
                        return this.ProgramLeagues[i].RegistrationSeasonID;
                    }
                }
                return '';
            },
			filterContactType(value, row) {
				return row.ContactTypeName === value;
			},
			TeamAccess (row) {
				if (row && row.Roles) {
					for (let i = 0; i < row.Roles.length; i++) {
						if (this.TeamIDAccess.indexOf(row.Roles[i].TeamID) >= 0) {
							return true;
						}
					}
				}
				return false;
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if (row.ContactID == 0 || row.ContactID == -1) {
                    let maxCol = 8;
                    if (this.ProgramID) {
                        maxCol--;
                    }
                    if (!this.modifyAccess) {
                        maxCol--;
                    }
                    if (!this.ProgramIDAccess && this.TeamIDAccess.length == 0) {
                        if (columnIndex == 1) {
                            return [1, maxCol]
                        } else {
                            return [0,0];
                        }
                    } else {
                        if (columnIndex == 0) {
                            return [1, maxCol];
                        } else {
                            return [0, 0];
                        }
                    }
                } else {
                    return [1, 1];
                }
			},
			tableRowClassName({row, rowIndex}) {
				if (row.ContactID == 0) {
					return 'team-row';
				} else {
					return '';
				}
			},
			getInitialData () {
				var tables = ['ActivePrograms', 'ContactTypesFilter', 'Seasons'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        this.user = { ...this.currentUser };
                        if (!this.ProgramID) {
	                        if (this.ProgramIDAccess && this.user.ProgramID) {
		                        this.ProgramID = this.user.ProgramID;
	                        } else {
		                        this.ProgramID = this.ActivePrograms[0]['ProgramID'];
	                        }
                        }
                        if (this.ContactTypesFilter) {
                        	this.filterValue = [];
                        	for (let i = 0; i < this.ContactTypesFilter.length; i++) {
		                        this.filterValue.push(this.ContactTypesFilter[i].value);
	                        }
                        }
                        this.getProgramLeagueData();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getProgramLeagueData() {
			    this.SeasonID = '';
				axios.post('/programcontacts/getprogramleaguedata', { ProgramID : this.ProgramID, RegistrationType : 'tc' },
				)
                .then(response => {
                    if (response.data.result == 'success') {

                        this.host = response.data.host;
                        if (response.data.ProgramCode) {
                            this.ProgramCode = response.data.ProgramCode;
                        }
                        if (response.data.ProgramLeagues) {
                            this.ProgramLeagues = (response.data.ProgramLeagues ? response.data.ProgramLeagues : []);
                        }
                        if (response.data.teamcontactregistrationinstructions) {
                            this.teamcontactregistrationinstructions = response.data.teamcontactregistrationinstructions;
                        }
                        if (this.isAdminContacts) {
                            this.getAdminContacts();
                        } else {
                            var SeasonIDs = [];
                            this.SeasonID = '';
                            for (let i = 0; i < this.ProgramLeagues.length; i++) {
                                if (SeasonIDs.indexOf(this.ProgramLeagues[i].ActiveSeasonID) === -1) {
                                    SeasonIDs.push(this.ProgramLeagues[i].ActiveSeasonID);
                                }
                                if (SeasonIDs.indexOf(this.ProgramLeagues[i].RegistrationSeasonID) === -1) {
                                    SeasonIDs.push(this.ProgramLeagues[i].RegistrationSeasonID);
                                }
                            }
                            if (this.availableSeasons.length > 0) {
                                for (let i = 0; i < this.availableSeasons.length; i++) {
                                    if (SeasonIDs.indexOf(this.availableSeasons[i]['SeasonID']) !== -1) {
                                        if (!this.SeasonID) {
                                            this.SeasonID = this.availableSeasons[i]['SeasonID'];
                                        }
                                    }
                                }
                            }
                            this.getTeamContacts();
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
            getAdminContacts() {
                let self = this;
                this.adminContacts = [];
                axios.post('/programcontacts/getAll', { ProgramID : this.ProgramID, AdminContacts : 'y', ContactTypes : "Admin", SeasonID : this.SeasonID },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (!response.data.contacts) {
                                response.data.contacts = [];
                            }
                            for (let i=0; i < response.data.contacts.length; i++) {
                                response.data.contacts[i].ParamsF = JSON.stringify(response.data.contacts[i].Params,null,'\t');
                                this.Params.push(response.data.contacts[i].Params);
                            }
                            this.adminContacts = response.data.contacts;
                            self.$store.dispatch('setID', {'ContactsProgramID': self.ProgramID});
                        } else {
                            this.adminContacts = [];
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getTeamContacts() {
                this.teamContacts = [];
                axios.post('/programcontacts/getAll', { ProgramID : this.ProgramID, AdminContacts : 'n', ContactTypes : "Team", TeamIDs : this.TeamIDAccess, SeasonID : this.SeasonID },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (!response.data.contacts) {
                                response.data.contacts = [];
                            }
                            for (let i=0; i < response.data.contacts.length; i++) {
                                response.data.contacts[i].ParamsF = JSON.stringify(response.data.contacts[i].Params,null,'\t');
                                this.Params.push(response.data.contacts[i].Params);
                            }

                            this.teamContacts = (response.data.contacts ? response.data.contacts : []);
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.teamContacts = [];
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },

            editContact(index, row) {
				this.modalTitle = 'Edit Contact';
				row.UserArea = 'p';
				this.modalData = row;
				this.displayModal();
			},
			newContact() {
				this.modalTitle = 'New Contact';
				var row = {};
				row.ProgramID = this.ProgramID;
                for (let i = 0; i < this.ActivePrograms.length; i++) {
                    if (this.ActivePrograms[i].ProgramID == row.ProgramID) {
                        row.ProgramName = this.ActivePrograms[i].ProgramName;
                        break;
                    }
                }
				row.UserArea = 'p';
				row.Params = {};
				row.Roles = [];
				this.modalData = row;
				this.displayModal();
			},
			deleteCnfm (index,row) {
				this.$confirm('Are you sure you wish to delete this contact?', 'Confirmation', {
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					type: 'warning',
					showClose: false,
					showCancelButton: true
				}).then(() => {
					this.deleteContact(index, row);
				}).catch(() => {
				});

			},
			deleteContact(index, row) {
				if (this.currentUser.ContactID != row.ContactID) {
					axios.post('/contacts/delete', {'ContactID': row.ContactID}
					)
					.then(response => {
						if (response.data.result == 'success') {
							this.refreshContactData();
							this.$notify({
								title: 'Success',
								message: "Contact has been deleted",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
							this.$notify({
								title: 'Error',
								message: "Contact cannot be deleted",
								type: 'error',
								duration: 2000,
								showClose: false
							});
						}
					}).catch(response => {
						this.$notify({
							title: 'Error',
							message: "Contact cannot be deleted",
							type: 'error',
							duration: 2000,
							showClose: false
						});
					});
				} else {
					this.displayPopup ('Sorry, you cannot delete yourself');
				}
			},
            activateContact(index, row) {
                axios.post('/programcontacts/activate', {'ContactID': row.ContactID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.refreshContactData();
                            this.$notify({
                                title: 'Success',
                                message: "Contact has been Activated",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                        } else {
                            this.$notify({
                                title: 'Error',
                                message: "Contactv cannot be Activated",
                                type: 'error',
                                duration: 2000,
                                showClose: false
                            });
                        }
                    }).catch(response => {
                    this.$notify({
                        title: 'Error',
                        message: "Contact cannot be Activated",
                        type: 'error',
                        duration: 2000,
                        showClose: false
                    });
                });
            },
	        displayPopup (message) {
                this.$alert(message, 'Contact Error', {
                    confirmButtonText: 'OK',
                });
            },
            displayModal () {
                this.showModal = true;
            },
            hideModal () {
                this.showModal = false;
            },
            refreshData() {
                this.getProgramLeagueData();
            },
            refreshContactData() {
                if (this.isAdminContacts) {
                    this.getAdminContacts();
                } else {
                    this.getTeamContacts();
                }
            },
			exportCSV () {
			    if (this.isAdminContacts) {
                    var list = JSON.parse(JSON.stringify(this.adminContacts));
                } else {
                    var list = JSON.parse(JSON.stringify(this.teamContacts));
                }

                let columns = [];
                for (let i = 0; i <= this.Params.length; i++) {
                    for (var property in this.Params[i]) {
                        if (this.Params[i].hasOwnProperty(property)) {
                            if (columns.indexOf(property) == -1) {
                                columns.push(property);
                            }
                        }
                    }
                }
                columns.sort();
				//this is a Program specific user and will have limited fields.
				var newList = [];
				for(let i=0; i<list.length; i++) {
					if (list[i].ContactID > 0) {
						if (this.ProgramIDAccess || this.TeamIDAccess.length > 0) {
							delete list[i].UserLoginID;
							delete list[i].ProgramID;
							delete list[i].LoginRoleID;
							delete list[i].Name;
							delete list[i].Roles;
						} else {
                            for (let j=0; j < columns.length; j++) {
                                if (list[i]['Params']) {
                                    if (list[i]['Params'][columns[j]]) {
                                        list[i][columns[j]] = list[i]['Params'][columns[j]];
                                    } else {
                                        list[i][columns[j]] = '';
                                    }
                                }

	                            if (list[i].Roles) {
		                            for (let k=0; k < list[i].Roles.length; k++) {
			                            if (list[i].Roles[k][columns[j]]) {
											if (this.isAdminContacts) {
												if (list[i].Roles[k]['TeamID'] == 0) {
													list[i][columns[j]] = list[i].Roles[k][columns[j]];
													break;
												}
											} else {
												if (list[i]['TeamID'] == list[i].Roles[k]['TeamID']) {
													list[i][columns[j]] = list[i].Roles[k][columns[j]];
													break;
												}
											}
			                            } else {
				                            list[i][columns[j]] = '';
			                            }
		                            }
	                            }
                            }
							list[i].Roles = JSON.stringify(list[i].Roles);
						}

						if (list[i].EMails) {
							list[i].EMails = list[i].EMails.replace('<br>', ' ');
						}
						if (list[i].Address) {
							list[i].Address = list[i].Address.replace('<br>', ' ');
						}
						//do twice because could be 2
						if (list[i].PhoneNumbers) {
							list[i].PhoneNumbers = list[i].PhoneNumbers.replace('<br>', ' ');
							list[i].PhoneNumbers = list[i].PhoneNumbers.replace('<br>', ' ');
						}
						delete list[i].Params;
                        delete list[i].ParamsF;

						newList.push(list[i]);
					}
				}
				var csv = Papa.unparse(newList);
				var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
				var csvURL =  null;
				if (navigator.msSaveBlob)
				{
					csvURL = navigator.msSaveBlob(csvData, 'contacts.csv');
				}
				else
				{
					csvURL = window.URL.createObjectURL(csvData);
				}

				var tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'contacts.csv');
				tempLink.click();
			},
		},
		mounted () {
			if (this.componentIDs.ContactsProgramID && !this.ProgramIDAccess) {
				this.ProgramID = this.componentIDs.ContactsProgramID;
			} else if (this.ProgramIDAccess) {
				this.ProgramID = this.user.ProgramID;
			}
			this.getInitialData();
		},
	}
</script>
<style>
#programcontacts .el-table th {
	font-size: 120%;
	font-weight: 800;
}
#programcontacts .modal-dialog {
	width: 550px;
}
#programcontacts .modal-body {
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}
#programcontacts .modal-footer {
	height: 55px;
}
.programcontacts div.el-table .cell {
	word-break: normal;
}
#programcontacts .el-table__column-filter-trigger i {
	color: #fff;
	font-weight: 800;
}
#programcontacts .el-table th>.cell.highlight {
	color: #fff;
	font-weight: 800;
}
#programcontacts .AdminContacts .el-tabs__nav-wrap::after {
    background-color: transparent;
}
#programcontacts.isAdminContacts .el-tabs__nav-scroll {
	height: 0;
}
#programcontacts.isAdminContacts .el-tabs__header.is-top {
	margin: 0;
}
#programcontacts .el-tabs__header.is-top {
    margin-left:15px;
}
</style>
<style scoped>

#programcontacts .checkbox {
    display: inline-block;
    width: 25px;
    height: 15px;
}
#programcontacts .webnotes {
	width: 90%;
	height: 40px;
	padding: 2px;
}
#programcontacts .heading {
	background-color: #1A719C;
	color: #fff;
	font-size: 14px;
}
#programcontacts .body {
	color: #333;
	font-size: 14px;
	padding: 2px;
}
#programcontacts .body input, #programcontacts .body select, #programcontacts .body textarea {
	color: #333;
	font-size: 14px;
}
#programcontacts .body select {
	padding: 1px;
}

#programcontacts .body.row:nth-child(even){
	background-color: #dcdcdc;
}
#programcontacts .body.row:nth-child(odd){
	background-color: #ffffff;
}
#programcontacts .row.buttons {
	margin-top: 20px;
}
select.not-available {
	width: auto;
	margin-left: 0px   !important;
}
.not-available label {
	margin-top: 4px;
}
.program-select {
	font-weight: 800;
	height: 35px;
}
.options {
	margin: 0px 5px;
}
.el-select {
	width: 100%;
	max-width: 300px;
}
.programName {
	display: inline-block;
	font-weight: 800;
	margin-top: 5px;
}
.regLink {
	font-size: 12px;
	width: 100%;
	margin-bottom: 5px;
	text-align:left;
	justify-content: left;
	line-height: 30px;
	-webkit-user-select: all;
	-moz-user-select: all;
	-ms-user-select: all;
	user-select: all;
}
.regclosed {
    margin-left: 15px;
    font-weight: 800;
}
.RegistrationLinks {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    margin: 20px;
}
i.success {
    color: var(--el-color-success);
}
i.danger {
    color: var(--el-color-danger);
}
label {
	position: absolute;
	margin-top: 5px
}
.dropdowns {
	margin-left: 60px;
	position: absolute;
	width: 100%;
}
i.fas, i.far {
    font-size: 18px;
}
</style>
