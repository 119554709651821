<template>
  <div>

    <div class="pb-3" v-if="hasDescription">

      <span v-html="labels.prefix"></span>

      <el-input
        size="small"
        style="width: 60px;"
        v-model.number="sizeInput"
      ></el-input>

      <span v-html="labels.suffix"></span>

    </div>

    <el-tag
        class="mt-2"
        v-for="time in forecast"
        :key="time.time"
        effect="dark">
        {{timeformat(time)}}
    </el-tag>

  </div>
</template>

<script>
import { Day, Schedule, Functions as fn } from 'dayspan';

export default {

  name: 'dsScheduleForecast',

  props:
  {
    schedule:
    {
      required: true,
      type: Schedule
    },

    day:
    {
      type: Day
    },

    readOnly:
    {
      type: Boolean,
      default: false
    },

    canExclude:
    {
      type: Boolean,
      default() {
        return true;
      }
    },

    hasDescription:
    {
      type: Boolean,
      default() {
        return true;
      }
    },

    defaultSize:
    {
      type: Number,
      default() {
        return 5;
      }
    },

    sizeMax:
    {
      type: Number,
      default() {
        return 100;
      }
    },

    labels:
    {
      validate(x) {
        return this.$dsValidate(x, 'labels');
      },
      default() {
        return {
	        prefix:     'The forecast shows previous & next',
	        suffix:     'slot occurrences within a years time.'
        };
      }
    },
  },

  data: vm => ({
    size: vm.defaultSize
  }),

  computed:
  {
    sizeInput:
    {
      get()
      {
        return this.size;
      },

      set(value)
      {
        this.size = Math.max(1, Math.min(Math.floor(value), this.sizeMax));
      }
    },

    aroundDay()
    {
      return this.day || this.$dayspan.today;
    },

    forecast()
    {

		var forecast = this.schedule.forecast( this.aroundDay, false, this.size, this.size, true, 40 )
				        .map(function(spanDayId) {
				            return spanDayId[1];
				        }).list();

		return forecast;
    },

    isReadOnly()
    {
      return this.readOnly || this.$dayspan.readOnly;
    },
  },

  watch:
  {
    forecast:
    {
      handler: 'triggerChange',
      immediate: true
    }
  },

  methods:
  {
      timeformat(time) {
          return time.format( 'ddd MMM Do YYYY' )
      },
    triggerChange()
    {
      this.$emit('change', this.getEvent('change'));
    },

    exclude(time)
    {
      var ev = this.getEvent('exclude', { time });

      if (!ev.handled && ev.schedule)
      {
        ev.schedule.setExcluded( ev.time );
        ev.handled = true;
      }
    },

    getEvent(type, extra = {})
    {
      return fn.extend({

        type: type,
        schedule: this.schedule,
        size: this.size,
        forecast: this.forecast,
        day: this.day,
        aroundDay: this.aroundDay,
        handled: false,
        $vm: this,
        $element: this.$el

      }, extra);
    }
  }
}
</script>

<style lang="scss">

</style>
