<template>
	<div id="invoicesettings" v-loading="loading">
		<div class="row">
			<div class="col-12 fullpage">
				<el-select v-model="InvoiceSettingID" class="topDropdown" placeholder="Select" @change="getInvoiceSetting()" default-first-option filterable>
					<el-option v-if="createAccess" key="" label="-- Add New --" value=""></el-option>
					<el-option
						v-for="item in InvoiceSettings"
						:label="item.BillingName"
						:value="item.InvoiceSettingID"
						:key="item.InvoiceSettingID">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="row mt-4">
			<div class="card col-12">
				<el-form ref="invoicesettingsForm" label-position="top" :model="invoicesetting" label-width="120px" >
					<el-tabs v-model="activeTab" @tab-click="tabClicked">
						<el-tab-pane label="Details" name="details">
							<div class="row mt-4">
								<div class="col-12 col-md-6">
									<el-form-item label="Billing Name" prop="BillingName"
										:rules="[{ required: true, message: 'Billing Name is required', trigger: 'blur'}]"
									>
										<el-input v-model="invoicesetting.BillingName" />
									</el-form-item>
                                    <el-form-item label="Billing Type" prop="Params.BillingSeasons">
                                        <el-radio-group  v-model="invoicesetting.Params.BillingSeasons">
                                            <el-radio-button label="s">Season Based</el-radio-button>
                                            <el-radio-button label="o">Ongoing</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item v-if="invoicesetting.Params.BillingSeasons == 'o'" label="Billing Start Date" prop="Params.BillingStartDate">
                                        <el-date-picker v-model="invoicesetting.Params.BillingStartDate" default-first-option filterable
                                                        :clearable="false"
                                                        type="date"
                                                        format="MMM D, YYYY"
                                                        value-format="YYYY-MM-DD"
                                                        placeholder="Billing Date">
                                        </el-date-picker>
                                    </el-form-item>
									<div class="row form-group col-md-12">
										<label class="mb-1 control-label" for="Logo">Logo</label>
										<form class="form-horizontal fade-in-effect" novalidate method="post" enctype="multipart/form-data">
											<div class="fileinput-button " style="text-align:center;">
												<div id="Logo" v-bind:class="{ 'success_upload' : isSuccess, 'failed_upload' : isFailed }" class="logo" style="position:relative;" :style="Logo">
													<div id="spinner" style="position:absolute;top:24px;left:24px;" v-if="isFileSaving1"><i class="fas fa-spinner fa-spin fa-2x"></i> </div>
													<input type="file" id="LogoInput" name="Logo" :disabled="isFileSaving1" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*" class="input-file">
													<div v-if="RemoveLogo" class="deleteLogo" @click.prevent="removelogo"><i class="fas fa-times-circle"></i></div>
												</div>
											</div>
										</form>
									</div>
									<el-form-item label="Contact Name" prop="ContactName">
										<el-input v-model="invoicesetting.Params.ContactName" />
									</el-form-item>
									<el-form-item label="Phone" prop="ContactPhoneNumber">
										<div class="el-form-item__content">
											<div class="el-input el-input--default el-input__wrapper">
												<input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="invoicesetting.Params.ContactPhoneNumber" />
											</div>
										</div>
									</el-form-item>
									<el-form-item label="Fax" prop="ContactFaxNumber">
										<div class="el-form-item__content">
											<div class="el-input el-input--default el-input__wrapper">
												<input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="invoicesetting.Params.ContactFaxNumber" />
											</div>
										</div>
									</el-form-item>
									<el-form-item label="Email" prop="ContactEmailAddress">
										<el-input v-model="invoicesetting.Params.ContactEmailAddress" />
									</el-form-item>
								</div>
								<div class="col-12 col-md-6">
									<el-form-item label="Address Line 1" prop="AddressLine1">
										<el-input v-model="invoicesetting.Params.AddressLine1" />
									</el-form-item>
									<el-form-item label="Address Line 2" prop="AddressLine2">
										<el-input v-model="invoicesetting.Params.AddressLine2" />
									</el-form-item>
									<el-form-item label="City" prop="City">
										<el-input v-model="invoicesetting.Params.City" />
									</el-form-item>
									<el-form-item :label="config.Params.state" prop="StateOrProvinceCodeID">
										<el-select v-model="invoicesetting.Params.StateOrProvinceCodeID" placeholder="Select" default-first-option filterable clearable>
											<el-option-group
													v-for="country in StateProvinceCodes"
													:key="country.Country"
													:label="country.Country">
												<el-option
														v-for="state in country.States"
														:key="state.StateProvinceCodeShortName"
														:label="state.StateProvinceCodeName"
														:value="state.StateProvinceCodeShortName">
												</el-option>
											</el-option-group>
										</el-select>
									</el-form-item>
									<el-form-item :label="config.Params.zipcode" prop="PostalCode" class="postalCode">
										<el-input v-model="invoicesetting.Params.PostalCode" />
									</el-form-item>
								</div>
							</div>
                            <div class="row">
                                <div class="col-12" style="max-width: 600px;">
                                    <el-form-item label="Contact Signature (for Contracts)" prop="Signature">
                                        <Vue3Signature v-if="!invoicesetting.Params.Signature || newSignature" class="signature" ref="signature" :sigOption="signatureoptions" :w="'600px'" :h="'200px'" ></Vue3Signature>
                                        <div v-else class="signature" :style="'width: 600px; height: 200px;background-image : url(' + invoicesetting.Params.Signature + ');background-size: contain;'"></div>
                                        <div class="signatureX">X</div>
                                        <div class="signatureLine mb-4">Signature (Sign by using mouse or finger in box)</div>
                                        <div class="signatureLine mb-4">
                                            <el-button v-if="!invoicesetting.Params.Signature || newSignature" type="success" size="small" @click="saveSignature"><i class="fa-solid fa-circle-check"></i> Accept Signature</el-button>
                                            <el-button v-if="invoicesetting.Params.Signature && !newSignature" size="small" @click="clearSignature"><i class="fa-solid fa-circle-xmark"></i> Clear</el-button>
                                            <el-button v-if="!invoicesetting.Params.Signature || newSignature" size="small" @click="undoSignature"><i class="fa-solid fa-rotate-left"></i> Undo</el-button>
                                            <el-button v-if="invoicesetting.Params.Signature && newSignature" type="info" size="small" @click="cancelSignature"><i class="fa-solid fa-circle-xmark"  style="color:#ffffff;"></i> Cancel</el-button>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>
						</el-tab-pane>
						<el-tab-pane v-if="InvoiceSettingID" label="Invoicing/Statements" name="invoicing">
							<el-form-item label="Invoice Due" prop="InvoiceDue">
								<el-input class="smallNumber" v-model="invoicesetting.Params.InvoiceDue" type="number" style="margin-right: 30px;"/>
								<el-radio v-model="invoicesetting.Params.InvoiceDueUnits" label="d">Days</el-radio>
								<el-radio v-model="invoicesetting.Params.InvoiceDueUnits" label="m">Months</el-radio>
							</el-form-item>
							<el-form-item label="Invoice Notes" prop="InvoiceNotes">
								<el-input placeholder="End of Invoice Notes"
										  v-model="invoicesetting.Params.InvoiceNotes"
										  type="textarea"
										  :autosize="{ minRows: 3, maxRows: 10}"/>
							</el-form-item>
							<el-form-item label="Statement Notes (Account has Balance Due)" prop="StatementDueNotes">
								<el-input placeholder="End of Statement Notes"
										  v-model="invoicesetting.Params.StatementDueNotes"
										  type="textarea"
										  :autosize="{ minRows: 3, maxRows: 10}"/>
							</el-form-item>
							<el-form-item label="Statement Notes (Account is in good standing)" prop="StatementNotes">
								<el-input placeholder="End of Statement Notes"
										  v-model="invoicesetting.Params.StatementNotes"
										  type="textarea"
										  :autosize="{ minRows: 3, maxRows: 10}"/>
							</el-form-item>
							<el-button class="mt-4 mb-1" @click.prevent="addInvoiceItem()"><i class="fa-solid fa-circle-plus"></i> Add Additional Invoice Item</el-button>
							<div class="row invoiceItemsHeader odd mt-1 bg-primary" v-if="invoicesetting.Params.InvoiceItems && invoicesetting.Params.InvoiceItems.length > 0">
								<div class="col-3">
									&nbsp;Description
								</div>
								<div class="col-3">
									Quantity Type
								</div>
								<div class="col-3">
									Display
								</div>
								<div class="col-2">
									Default Amount ({{currencySign}})
								</div>
								<div class="col-1 itemDelete">
									&nbsp;
								</div>
							</div>
							<template v-for="(items, index) in invoicesetting.Params.InvoiceItems">
								<div v-if="items.operation != 'delete'" class="row itemList" :class="{odd: index%2 == 1}"  >
									<div class="col-3">
										<el-input style="margin-top: 17px;" v-model="items.Description" />
									</div>
									<div class="col-3">
										<el-select style="margin-top: 12px;" v-model="items.QuantityType" auto-complete=on placeholder="Select" default-first-option filterable>
											<el-option
													v-for="item in QuantityTypes"
													:key="item.type"
													:label="item.label"
													:value="item.type">
											</el-option>
										</el-select>
									</div>
									<div class="col-3">
										<el-select v-if="items.QuantityType == 'BT'" style="margin-top: 12px;" v-model="items.Display" auto-complete=on placeholder="Select" default-first-option filterable>
											<el-option
													v-for="item in Display"
													:key="item.type"
													:label="item.label"
													:value="item.type">
											</el-option>
										</el-select>
									</div>
									<div class="col-2">
										<el-input class="text-align: right;" style="margin-top: 12px;" v-model="items.DefaultAmount" />
									</div>
									<div class="col-1 itemDelete">
										<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteInvoiceItem(index)">
											<div class="v-btn__content">
												<i class="far fa-2x fa-trash-alt"></i>
											</div>
										</button>
									</div>
								</div>
							</template>
						</el-tab-pane>
					</el-tabs>
				</el-form>
			</div>
		</div>
		<div class="row mb-4" v-if="(modifyAccess || (createAccess && InvoiceSettingID))" >
            <div class="col-12 text-center">
                <button class="btn btn-success btn-icon" type="button" @click="saveInvoiceSetting">
                    <i class="fas fa-check"></i> <span> Save Changes</span>
                </button>
                <button v-if="InvoiceSettingID && activeTab == 'details'" type="button" class="btn btn-success btn-icon" @click="saveNew">
                    <i class="fas fa-plus-circle"></i> <span> Save as New</span>
                </button>
                <button v-if="InvoiceSettingID && activeTab == 'details'" type="button" class="btn btn-icon btn-danger delete"  @click="cnfmDeleteInvoiceSetting()">
                    <i class="far fa-trash-alt"></i>  <span>Delete</span>
                </button>
                <button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)">
                    <i class="fas fa-times"></i>  <span>Cancel</span>
                </button>
            </div>
        </div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
    import Util from "../assets/js/util";

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_FILESAVING1 = 4, STATUS_FILESAVING2 = 5;
	export default {
		data () {
			return {
				activeTab : 'details',
				loading : true,
                currentStatus : STATUS_INITIAL,
				invoicesetting : {Params:{}},
				InvoiceSettings : [],
				InvoiceSettingID : null,
                MainLocations : [],
				StateProvinceCodes : [],
				BillingTypes : [{type:'BG', label: 'By Game'},{type:'TR', label: 'Team Registration'},{type:'IU', label: 'By Usage'}],
				QuantityTypes : [{type:'input', label: 'Input Value'},{type:'BT', label: 'By Team/Rental'}],
				Display : [{type:'Ind', label: 'Individual Items'},{type:'Group', label: 'One Summary Item'}],
                signatureoptions:{
                    penColor:"rgb(0, 0, 0)",
                    backgroundColor:"rgb(255,255,255)"
                },
                newSignature : false,
			}
		},
		name: 'InvoiceSettings',
		props:["config", "parentrefs"],
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
            isFileSaving1() {
                return this.currentStatus === STATUS_FILESAVING1;
            },
            isFileSaving2() {
                return this.currentStatus === STATUS_FILESAVING2;
            },
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        createAccess () {
                return this.$createAccess();
            },
	        modifyAccess () {
                return this.$modifyAccess();
            },
			defaultLogo () {
				return require('../images/default_upload.png')
			},
			Logo() {
				let logo = 'backgroundImage: url(' + (this.invoicesetting.Params.Logo ? this.invoicesetting.Params.Logo : this.defaultLogo) + ')';
				return logo;
			},
			RemoveLogo() {
				if (this.invoicesetting.Params.Logo) {
					return true;
				} else {
					return false;
				}
			},
			currencySign () {
                return Util.currencySign(this.config);
            },
		},
		methods: {
            saveSignature(){
                if (this.$refs.signature.isEmpty()) {
                    this.displayPopup('Please Complete your Signature before Accepting');
                    return;
                }
                var png = this.$refs.signature.save("image/png");

                axios.post('/index/savesignature', {signature : png},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.$stopProgressBar(this.parentrefs);
                            this.invoicesetting.Params['Signature'] = response.data.file;
                            this.newSignature = false;
                            this.$refs.signature.clear();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            clearSignature(){
                this.newSignature = true;
            },
            undoSignature(){
                this.$refs.signature.undo();
            },
            cancelSignature () {
                this.newSignature = false;
            },
			tabClicked (tab) {
				this.$store.dispatch('setID', {'InvoiceSettingsActiveTab': this.activeTab});
			},
			addInvoiceItem() {
				if (!this.invoicesetting.Params.InvoiceItems) {
					this.invoicesetting.Params['InvoiceItems'] = [];
				}
				this.invoicesetting.Params.InvoiceItems.unshift(
					{
						Description: '',
						QuantityType: '',
						DefaultAmount : '',
						operation: 'add'
					}
				);
			},
			deleteInvoiceItem(idx) {
				this.$confirm('This will permanently delete this Item (you will also have to Save Changes). Continue?', 'Warning', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}).then(() => {
					this.invoicesetting.Params.InvoiceItems.splice(idx, 1);
				}).catch(() => {
				});
			},
			formatPrice(value) {
				if (value) {
					return Util.formatPrice(this.config, parseFloat(value), '0');
				} else {
					return '';
				}
			},
			reset(showMessage) {
				// reset form to initial state
				this.currentStatus = STATUS_INITIAL;
				this.cancelSignature();
				this.getInitialData();
				if (this.componentIDs.InvoiceSettingsActiveTab) {
					this.activeTab = this.componentIDs.InvoiceSettingsActiveTab;
				} else {
					this.activeTab = 'details';
				}
				if (showMessage) {
					this.$notify({
						title: 'Success',
						message: "Billing Accounts have been reset",
						type: 'success',
						duration: 1500,
						showClose: false
					});
				}
			},
			getInitialData () {
				var tables = ['StateProvinceCodes','MainLocations'];
				axios.post('/index/tableData', {'tables': tables },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let value of tables) {
								this[value] = response.data[value];
							}
							this.getAllInvoiceSettings();
						} else {
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
				});
			},
			getAllInvoiceSettings() {
				axios.post('/finance/getallinvoicesettings', {},
				)
                .then(response => {
                    if (response.data.result == 'success') {
						this.InvoiceSettings = response.data.InvoiceSettings;
						if (!this.InvoiceSettingID && this.InvoiceSettings.length > 0) {
							if (this.componentIDs.InvoiceSettingID) {
								this.InvoiceSettingID = this.componentIDs.InvoiceSettingID;
							} else {
								this.InvoiceSettingID = this.InvoiceSettings[0]['InvoiceSettingID'];
							}
						}
						this.$stopProgressBar(this.parentrefs);
						this.getInvoiceSetting () ;
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getInvoiceSetting () {
				let self = this;
				if (this.InvoiceSettingID) {
					axios.post('/finance/getinvoicesetting', {'InvoiceSettingID' : this.InvoiceSettingID},
					)
	                .then(response => {
	                    if (response.data.result == 'success') {
		                    self.$store.dispatch('setID', {'InvoiceSettingID': self.InvoiceSettingID});
							this.invoicesetting = response.data.invoicesetting;
							this.$stopProgressBar(this.parentrefs);
	                    } else {
	                        this.$failProgressBar(this.parentrefs);
	                    }
		                this.loading = false;
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
						this.loading = false;
	                });
                } else {
                    this.invoicesetting = {'InvoiceSettingID' : 0, Params: {AddressLine1:"", InvoiceItems:[]}};
					this.loading = false;
                }
			},
			uploadLogo(formData) {
				if (this.currentStatus == STATUS_SAVING || this.currentStatus == STATUS_FILESAVING1) {
					return true;
				}
				// upload data to the server
				this.currentStatus = STATUS_FILESAVING1;
				this.$startProgressBar(this.parentrefs);

				axios.post('/settings/uploadlogo', formData,
					{ headers: { 'Content-Type': 'multipart/form-data'} }
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_SUCCESS;
							tthis.invoicesetting.Params['Logo'] = response.data.url;
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
						document.getElementById("LogoInput").value = "";
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
			filesChange(fieldName, fileList) {
                if (this.currentStatus == STATUS_SAVING || this.currentStatus == STATUS_FILESAVING1) {
					return true;
				}
				// handle file changes
				const formData = new FormData();

				if (!fileList.length) return;

				// append the files to FormData
				Array
					.from(Array(fileList.length).keys())
					.map(x => {
						formData.append(fieldName, fileList[x], fileList[x].name);
					});

				// save it
				this.uploadLogo(formData);
			},
			removelogo() {
				this.invoicesetting.Params['Logo'] = '';
			},
            saveNew () {
                this.InvoiceSettingID = '';
                this.invoicesetting.InvoiceSettingID = 0;
                this.saveInvoiceSetting();
            },
			saveInvoiceSetting () {
				this.$refs['invoicesettingsForm'].validate((valid) => {
                    if (valid) {
                    	if (!this.isDuplicate ()) {
		                    if (this.currentStatus == STATUS_SAVING) {
								return true;
							}
							this.currentStatus = STATUS_SAVING;
							this.$startProgressBar(this.parentrefs);

							axios.post('/finance/saveinvoicesettings', {'invoicesetting': this.invoicesetting}
							)
							.then(response => {
								if (response.data.result == 'success') {
									this.currentStatus = STATUS_INITIAL;
									if (response.data.invoicesetting) {
										this.invoicesetting = response.data.invoicesetting;
									}
									if (response.data.InvoiceSettingID) {
										this.InvoiceSettings.push({InvoiceSettingID : response.data.InvoiceSettingID, BillingName : this.invoicesetting.BillingName});
										this.InvoiceSettingID = response.data.InvoiceSettingID;
										this.invoicesetting.InvoiceSettingID = response.data.InvoiceSettingID;
									} else {
										for (let i = 0; i < this.InvoiceSettings.length; i++) {
											if (this.InvoiceSettings[i].InvoiceSettingID == this.invoicesetting.InvoiceSettingID) {
												this.InvoiceSettings[i].BillingName = this.invoicesetting.BillingName;
												break;
											}
										}
									}
									this.$stopProgressBar(this.parentrefs);
									this.$notify({
										title: 'Success',
										message: 'Billing Accounts have been saved.',
										type: 'success',
										duration: 1500,
										showClose: false
									});
								} else {
									this.currentStatus = STATUS_FAILED;
									this.displayPopup (response.data.message);
									this.$failProgressBar(this.parentrefs);
								}
							}).catch(response => {
								this.currentStatus = STATUS_FAILED;
								this.$failProgressBar(this.parentrefs);
							});
	                    } else {
		                    this.displayPopup ('This Billing Name Already Exists.');
	                    }
					}
                });
			},
			isDuplicate () {
				for (let i = 0; i < this.InvoiceSettings.length; i++) {
					if (this.InvoiceSettings[i].InvoiceSettingID != this.invoicesetting.InvoiceSettingID && this.InvoiceSettings[i].BillingName.toLowerCase() == this.invoicesetting.BillingName.toLowerCase().trim()) {
						return true;
					}
				}
				return false;
			},
			cnfmDeleteInvoiceSetting() {
				this.$confirm('This will permanently delete this Billing Account. Continue?', 'Warning', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}).then(() => {
					this.deleteInvoiceSetting();
				}).catch(() => {
				});
			},
			deleteInvoiceSetting () {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				let self = this;
				axios.post('/finance/deleteinvoicesetting', {'InvoiceSettingID': this.InvoiceSettingID}
				)
				.then(response => {
					if (response.data.result == 'success') {
						this.currentStatus = STATUS_INITIAL;

						this.$stopProgressBar(this.parentrefs);
						this.$notify({
							title: 'Success',
							message: "Billing Account has been deleted",
							type: 'success',
							duration: 1500,
							showClose: false
						});
						self.$store.dispatch('setID', {'InvoiceSettingID': ''});
		                this.InvoiceSettingID = '';
		                this.getAllInvoiceSettings();

					} else {
						this.currentStatus = STATUS_FAILED;
						this.displayPopup (response.data.message);
						this.$failProgressBar(this.parentrefs);
					}
				}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},

	        displayPopup (message) {
				this.$alert(message, {
					type: 'error',
					confirmButtonText: 'OK',
				});
	        }
		},
		mounted() {
			this.reset(false);
		},
	}
</script>

<style>
#invoicesettings .signature .canvas {
	width: 600px;
	height: 200px;
}
</style>
<style scoped>

    #invoicesettings .form-horizontal {
        width: 100%;
    }
    #invoicesettings .card {
		padding-top: 0px;
		padding-bottom: 0px;
	}
    .labelDiv label {
        font-size: 14px;
        color: #606266;
        vertical-align: top;
        margin-top: 4px;
    }
	.labelDiv {
		padding: 10px;
		clear:both;
	}
	.labelDiv.odd {
		background-color: #f7f7f7;
	}
	.el-select {
		width: 100%;
	}
	.el-select, .postalCode .el-input {
		width: 50%;
	}
	#Logo {
		min-width: 50%;
		background: #FFFFFF left center no-repeat;
		background-size: contain;
		cursor: pointer;
	}
	#Logo {
		height: 50px;
	}
	#invoicesettings .logo .success_upload {
		border: 4px solid #28a745;
	}
	#invoicesettings .logo .failed_upload {
		border: 4px solid #dc3545;
	}
	.input-file {
		opacity: 0; /* invisible but it's there! */
		width: 100%;
		height: 100%;
		position: absolute;
		cursor: pointer;
		left:0;
	}
	.deleteLogo {
		font-size: 14px;
		cursor:pointer;
		position: absolute;
		top: 0;
		background: #FFF;
		left: 46px;
		color: #666;
		padding: 0px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		line-height: 9px;
	}
	.invoiceItemsHeader {
		font-weight: 600;
		border-bottom: 1px solid #dcdfe6;
		padding-top: 10px;
	}
	.itemDelete {
		padding: 5px 0px;
		font-size: 12px;
		text-align: left;
		white-space: nowrap;
		text-align: center;
	}
	.itemList {
		padding: 10px 0px;
		border-bottom: 1px solid #dcdfe6;
	}
	.odd {
		background-color: #F0F0F0;
	}
    .signatureLine {
        font-size: 10px;
        text-align: center;
        width: 600px;
    }
    .signatureX {
        position:absolute;
        top: 196px;
        left: 4px;
        font-size: 18px;
        color: #999999;
        font-weight: 800;
    }
    .signature {
        border: 1px dotted #dddddd;
        border-bottom: 2px solid #999999;
    }
</style>
