<template>
    <div id="imports" v-loading.fullscreen.lock="loading">
        <el-form ref="importForm" label-position="top" label-width="120px" autocomplete="new-password" >
            <div v-if="!Calling" class="row mt-4">
                <div class="col-12 fullpage">
                    <el-form-item label="Import Area:" prop="importarea">
                        <el-select key="importarea" v-model="ImportArea" placeholder="Select" class="topDropdown" @change="importareachanged" popper-class="Analyzer" default-first-option filterable>
                            <el-option
                                v-for="item in ImportAreas"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div v-if="ImportArea != ''" class="row mt-4">
                <el-form ref="importForm" label-position="top" label-width="120px" >
                    <template v-if="ActiveSeasons.length > 0 && ParameterValid('Season')">
                        <div class="col-12 col-md-6">
                            <el-form-item class="mt-2 mb-2" label="Season:" prop="SeasonID">
                                <el-select key="select4" class="oneHundred" placeholder="All Seasons" size="small" v-model="SeasonID" popper-class="Analyzer" default-first-option filterable>
                                    <el-option
                                        v-for="item in ActiveSeasons"
                                        :key="item.SeasonID"
                                        :label="item.SeasonName"
                                        :value="item.SeasonID">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </template>
                    <template v-if="SeasonID && Schedules.length > 0 && ParameterValid('Schedule')">
                        <div class="col-12 col-md-6">
                            <el-form-item class="mt-2 mb-2" :label="config.Params.schedule + ':'" prop="ScheduleID">
                                <el-select key="selectScheduleID" placeholder="Select" size="small" v-model="ScheduleID" class="oneHundred" popper-class="Analyzer"  default-first-option filterable clearable>
                                    <template v-for="item in Schedules">
		                                <el-option
	                                        v-if="SeasonID == item.SeasonID"
	                                        :key="item.ScheduleID"
	                                        :label="item.ScheduleName"
	                                        :value="item.ScheduleID">
	                                    </el-option>
                                    </template>
                                </el-select>
                            </el-form-item>
                        </div>
                    </template>
                    <template v-if="SeasonID && Leagues.length > 0 && ParameterValid('League')">
                        <div class="col-12 col-md-6">
                            <el-form-item class="mt-2 mb-2" label="League:" prop="ScheduleID">
                                <el-select key="selectLeagueID" placeholder="Select" size="small" v-model="LeagueID" class="oneHundred" popper-class="Analyzer"  default-first-option filterable clearable>
                                    <template v-for="item in Leagues">
		                                <el-option
	                                        v-if="SeasonID == item.SeasonID"
	                                        :key="item.LeagueID"
	                                        :label="item.LeagueName"
	                                        :value="item.LeagueID">
	                                    </el-option>
                                    </template>
                                </el-select>
                            </el-form-item>
                        </div>
                    </template>
                </el-form>
            </div>
            <div class="row">
                <div class="col-12 fullpage" :style="(Calling ? 'overflow: auto;max-height: 700px;' : '')">
                    <div v-if="ImportArea != ''" >
                        <p><strong>Start your import by creating a CSV (comma separated values) file with the columns and rows of data you want to add to or update the database with. Data with IDs will be updated, otherwise new data will be added.</strong></p>
                        <p><strong>A header row with the matching titles from below is required (spaces can be used for readability).</strong></p>
                        <p style="color: #cc0000;"><strong>Order is not important with the following exceptions. Some fields require others to validate. For example, Season is needed to validate the Team, so the Season Column must be before the Team Name column(s). Order is also used when applying defaults. A column applying defaults, like Default Fees from the Organization, the Organization column must be ordered before the field the default is being applied to. When several columns provide defaults, it is the last columns defaults that are used. If you are unsure of order, just use the order below.</strong></p>
                        <p><strong>Create your file then following the workflow below to verify your file, correct errors and update the database.</strong></p>

                        <el-collapse style="max-width: 700px;border: 0;margin-top: 23px;" v-model="collapse">
                            <el-collapse-item title="COLUMNS" name="1">
                                <div v-if="ImportArea == 'Calendar Slots'">
                                    <ul>
                                        <li><strong>Calendar Slot ID</strong> (optional): If non-zero will update the Calendar, otherwise will add</li>
                                        <li><strong>Slot Type</strong> (optional/required): One of : Open, <template v-if="$propertyAccess('Practices') && !$propertyDisabled('Practices')">Practice,</template> {{config.Params.game}}, <template v-if="$propertyAccess('3rdParty') && !$propertyDisabled('3rdParty')">Event</template> </li>
                                        <li><strong>Title</strong> (optional): Calendar Entry Title (if not imported, default will be generated)</li>
                                        <li><strong>Description</strong> (optional): Addition description of the slot</li>
                                        <li><strong>Start Date</strong> (optional/required): YYYY-MM-DD date format</li>
	                                    <li><strong>End Date</strong> (optional): If a date range, this is the end date, if the slot is a single date, then this can be omitted</li>
	                                    <li><strong>Days of Week</strong> (optional): comma separated list: Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday. Only used if there is a date range. Omit will include all days. For example, for all Saturdays and Sundays in January, you would put 2021-01-01 for the start date, 2021-01-31 for the end date and then Saturday,Sunday for Days of Week.</li>
	                                    <li><strong>Start Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM</li>
                                        <li><strong>End Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM</li>
                                        <li><strong>Repeats</strong> (optional): One of: blank, "" = no repeats, "Daily", "Weekly" or "Every Weekday"</li>
                                        <li><strong>{{config.Params.mainlocationName}}</strong> (optional/required): Name of the {{config.Params.mainlocationName}}</li>
                                        <li><strong>{{config.Params.locationName}}</strong> (optional/required): Name of the {{config.Params.locationName}} in the {{config.Params.mainlocationName}}</li>
                                        <li v-if="$propertyAccess('3rdParty') && !$propertyDisabled('3rdParty')"><strong>Organization Name</strong> (optional/required): Required if the Slot Type: Event</li>
                                        <el-collapse class="colorfield" accordian>
                                            <el-collapse-item  name="1">
                                                <template #title>
                                                    <span v-html="'<strong>Color</strong> (optional): One of the supported Hex Number values - <strong>Click to see list</strong>'"></span>
                                                </template>
       
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div v-for="color in colorsa">
                                                            <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div v-for="color in colorsb">
                                                            <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-collapse-item>
                                        </el-collapse>
                                        <li v-if="$propertyAccess('Costs') && !$propertyDisabled('Costs')"><strong>Cost</strong> (optional): Your cost for this calendar slot.  Enter the word "default" to pick up the default cost on the {{config.Params.mainlocationName}} screen.</li>
                                        <li v-if="$propertyAccess('Fees') && !$propertyDisabled('Fees')"><strong>Fee</strong> (optional): The amount to be charge to the organization using the {{config.Params.LocationName}}. Enter the word "default" to pick up the default fee on the {{config.Params.mainlocationName}} screen or Organization Screen.</li>
                                        <li v-if="$propertyAccess('Fees') && !$propertyDisabled('Fees')"><strong>Hourly Flat</strong> (optional): either  h = hourly, f = flat (fixed).  Enter the word "default" to pick up the default fee on the {{config.Params.mainlocationName}} screen or Organization Screen.</li>
                                        <li v-if="$propertyAccess('ScheduleTypes') && !$propertyDisabled('ScheduleTypes')"><strong>{{config.Params.schedule}} Types</strong> (optional): Comma separated list of {{config.Params.schedule}} Types</li>
                                        <li v-if="$propertyAccess('Practices') && !$propertyDisabled('Practices')"><strong>Season</strong> (optional/required): Required if Slot Type: Practice</li>
                                        <li v-if="$propertyAccess('Practices') && !$propertyDisabled('Practices')"><strong>Teams</strong> (optional/required): Comma separated list of Team Names , required and only used for Slot Type: Practice)</li>
                                        <li v-if="$propertyAccess('Tags') && !$propertyDisabled('Tags')"><strong>Tags</strong> (optional): Comma separated list of Tags</li>
                                        <li v-if="$propertyAccess('3rdParty') && !$propertyDisabled('3rdParty')"><strong>Event Max Attendance</strong> (optional): For Slot Type: Event, max number of attendees</li>                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate and overlapping Calendar Entries. If Calendar Slot ID is present, optional/required fields are optional.</p>
                                </div>
                                <div v-if="ImportArea == 'Constraint Slots'">
                                    <ul>
                                        <li><strong>Constraint Slot ID</strong> (optional): If non-zero will update the Calendar, otherwise will add</li>
                                        <li><strong>Title</strong> (optional): Calendar Entry Title (if not imported, default will be generated)</li>
                                        <li><strong>Description</strong> (optional): Addition description of the slot</li>
                                        <li><strong>Start Date</strong> (optional/required): YYYY-MM-DD date format</li>
                                        <li><strong>End Date</strong> (optional): If a date range, this is the end date, if the slot is a single date, then this can be omitted</li>
                                        <li><strong>Repeats</strong> (optional): One of: blank, "" = no repeats, "Daily", "Weekly" or "Every Weekday"</li>
                                        <li><strong>Start Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM</li>
                                        <li><strong>End Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM</li>
                                        <li><strong>Duration</strong> (optional/required): Number of minutes of the entry (use Duration or End Time not both) </li>
                                        <el-collapse class="colorfield">
                                            <el-collapse-item name="1">
                                                <template #title>
                                                    <span v-html="'<strong>Color</strong> (optional): One of the supported Hex Number values - <strong>Click to see list</strong>'"></span>
                                                </template>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div v-for="color in colorsa">
                                                            <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div v-for="color in colorsb">
                                                            <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-collapse-item>
                                        </el-collapse>
                                        <li><strong>Season</strong> (optional/required): Season, required only if Team is being imported</li>
                                        <li><strong>{{config.Params.programName}}</strong> (optional): Optional, applies to all teams in the {{config.Params.programName}}</li>
                                        <li><strong>Team</strong> (optional): Team Name</li>
                                        <li><strong>{{config.Params.mainlocationName}}</strong> (optional/required): Name of the {{config.Params.mainlocationName}}</li>
                                        <li><strong>{{config.Params.locationName}}</strong> (optional/required): Name of the {{config.Params.locationName}} in the {{config.Params.mainlocationName}}</li>
                                        <li><strong>League</strong> (optional/required): League Name. Required if using Division Type.  Also one of {{config.Params.programName}}, Team or League is required.</li>
                                        <li><strong>Division Types</strong> (optional): comma separated list of Division Type Names, requires League</li>
                                    </ul>
                                    <p>The import will check for duplicate and overlapping Constraint Entries. One of {{config.Params.programName}}, Team, League are required.</p>
                                </div>
                                <div v-if="ImportArea == 'Contacts'">
                                    <ul>
                                        <li><strong>Contact ID</strong> (optional): If non-zero will update contact, otherwise will add</li>
                                        <li><strong>{{config.Params.programName}}</strong> (optional/required): If Contact ID, optional, otherwise required</li>
                                        <li><strong>Season</strong> (optional/required): Required if the Contact is associated with a team</li>
                                        <li><strong>Team</strong> (optional): Team Name if the Contact is associated with a team</li>
                                        <li><strong>First Name</strong> (optional/required): First Name of the Player</li>
                                        <li><strong>Last Name</strong> (optional/required): Last Name of the Player</li>
                                        <li><strong>Email Address</strong> (optional/required): Email Address for Player correspondence</li>
                                        <li><strong>Contact Type</strong> (optional/required): Must match an entry in Contact Types</li>
                                        <li><strong>Login Role</strong> (optional): If contact is gaining access to login in the system, this is required</li>
                                        <li><strong>ID Number</strong> (optional): USA No or similar</li>
                                        <li><strong>Registration No</strong> (optional): Unique number contacts use as a reference in the software</li>
                                        <li><strong>Address Line1</strong> (optional): Address Line 1</li>
                                        <li><strong>Address Line2</strong> (optional): Address Line 2</li>
                                        <li><strong>City</strong> (optional): City</li>
                                        <li><strong>{{config.Params.state}}</strong> (optional)</li>
                                        <li><strong>{{config.Params.zipcode}}</strong> (optional): {{config.Params.zipcode}}</li>
                                        <li><strong>Home Phone</strong> (optional): Home Phone Number</li>
                                        <li><strong>Work Phone</strong> (optional): Work Phone Number</li>
                                        <li><strong>Mobile Phone</strong> (optional): Mobile or Cell Phone Number</li>
                                        <li><strong>XXXX</strong> (optional): You can add any other data.  The heading will be the field name. Examples might be Position, School, Doctor, Emergency Contact, etc</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate Contact. If Contact ID is present, optional/required fields are optional.</p>
                                </div>
                                <div v-else-if="ImportArea == 'Divisions'">
                                    <ul>
                                        <li><strong>Division ID</strong> (optional): If non-zero will update division, otherwise will add</li>
                                        <li><strong>Season</strong> (optional/required): One of Division ID or Season is required</li>
                                        <li><strong>League</strong> (optional/required): One of Division ID or League Name is required</li>
                                        <li><strong>Division Group Name</strong> (optional): Used if grouping divisions into something like conferences</li>
                                        <li><strong>Division Name</strong> (optional/required): This is the most used common name for the division</li>
                                        <li><strong>Division Short Name</strong> (optional): Shorter version of the division name typically used for reports</li>
                                        <li><strong>Division Type</strong> (optional): Division Type Name (if it does not exist, will add)</li>
                                        <li><strong>Division Level</strong> (optional): Division Level Name(if it does not exist, will add)</li>
                                        <li><strong>Order</strong> (optional): This is the order that divisions are typically displayed, if not used, alphabetical order is used.</li>
                                    </ul>
                                    <p>The import will check for duplicate division names in the selected season and league.  If Division ID is present, optional/required fields are optional.</p>
                                </div>
                                <div v-if="ImportArea == 'Games'">
                                    <ul>
                                        <li><strong>{{config.Params.game}} ID</strong> (optional): If non-zero will update the {{config.Params.game}}, otherwise will add</li>
                                        <li><strong>Season</strong> (optional/required): Required if no {{config.Params.game}} ID (unless {{config.Params.schedule}} Name is unique)</li>
                                        <li><strong>{{config.Params.schedule}} Name</strong> (optional/required): Required if no {{config.Params.game}} ID</li>
                                        <li><strong>{{config.Params.game}} Date</strong> (optional/required): YYYY-MM-DD date format</li>
                                        <li><strong>Start Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM</li>
                                        <li><strong>End Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM</li>
                                        <li><strong>Duration</strong> (optional/required): Number of minutes of the entry (use Duration or End Time not both) </li>
                                        <li><strong>{{config.Params.mainlocationName}}</strong> (optional/required): Name of the {{config.Params.mainlocationName}}</li>
                                        <li><strong>{{config.Params.locationName}}</strong> (optional/required): Name of the {{config.Params.locationName}} in the {{config.Params.mainlocationName}}</li>
                                        <li><strong>X{{config.Params.playingsurface}} Name</strong> (optional): If {{config.Params.game}} is sharing the {{config.Params.locationName}}, this is the X{{config.Params.playingsurface}} Name given.</li>
	                                    <li><strong>Home {{config.Params.programName}}</strong> (optional): Should be used if the Home Team Name is not unique across {{config.Params.programName}}s</li>
	                                    <li><strong>Home Team</strong> (optional/required): Name of the Home Team</li>
	                                    <li><strong>Visiting {{config.Params.programName}}</strong> (optional): Should be used if the Visiting Team Name is not unique across {{config.Params.programName}}s</li>
	                                    <li><strong>Visiting Team</strong> (optional/required): Name of the Visiting Team</li>
                                        <li><strong>Home Rate</strong> (optional): If present will use, otherwise will be generated.</li>
                                        <li><strong>Visiting Rate</strong> (optional): If present will use, otherwise will be generated.</li>
                                        <li><strong>Home Score</strong> (optional): For completed {{config.Params.games.toLowerCase()}}, score for Home Team.</li>
                                        <li><strong>Visiting Score</strong> (optional): For completed {{config.Params.games.toLowerCase()}}, score for Visiting Team.</li>
                                        <li><strong>{{config.Params.game}} Status</strong> (optional): Long or Short Code from the Status Codes table.  Default of P - Upcoming, will be used if not present</li>
                                        <li><strong>Web Notes</strong> (optional): Web Notes Field</li>
                                        <li><strong>{{config.Params.game}} Notes</strong> (optional): {{config.Params.game}} Notes Field</li>
                                        <li><strong>Internal Notes</strong> (optional): Internal Notes Field</li>
                                        <li><strong>{{config.Params.game}} Tags</strong> (optional): Comma separated list of {{config.Params.game}} Tags</li>
                                    </ul>
                                    <strong>If Adding a New Calendar Slot (software will try to match to an existing open slot), these fields will be put on the new Calendar Entry.</strong>
                                    <el-collapse class="colorfield">
                                        <el-collapse-item name="1">
                                            <template #title>
                                                <span v-html="'<strong>Color</strong> (optional): One of the support Hex Number values - <strong>Click to see list</strong>'"></span>
                                            </template>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div v-for="color in colorsa">
                                                        <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div v-for="color in colorsb">
                                                        <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                    <ul>
                                        <li><strong>Cost</strong> (optional): Your cost for this calendar slot</li>
                                        <li><strong>{{config.Params.schedule}} Types</strong> (optional): Comma separated list of {{config.Params.schedule}} Types</li>
                                        <li><strong>Tags</strong> (optional): Comma separated list of {{config.Params.schedule}} Tags</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate and overlapping Calendar Entries. If Calendar Slot ID is present, optional/required fields are optional.</p>
                                </div>
                                <div v-else-if="ImportArea == 'Leagues'">
                                    <ul>
                                        <li><strong>League ID</strong> (optional): If non-zero will update League, otherwise will add</li>
                                        <li><strong>League Name</strong> (optional/required): Long Name of the League</li>
                                        <li><strong>League Short Name</strong> (optional): Short Name of the League</li>
                                        <li><strong>Address Line1</strong> (optional): Address Line 1</li>
                                        <li><strong>Address Line2</strong> (optional): Address Line 2</li>
                                        <li><strong>City</strong> (optional): City</li>
                                        <li><strong>{{config.Params.state}}</strong> (optional)</li>
                                        <li><strong>{{config.Params.zipcode}}</strong> (optional): {{config.Params.zipcode}}</li>
                                        <li><strong>Phone Number</strong> (optional): Phone Number</li>
                                        <li><strong>Website URL</strong> (optional): Main URL of the website.  Should start with http:// or https://.</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate Leagues. If League ID is present, optional/required fields are optional.</p>
                                </div>
                                <div v-else-if="ImportArea == 'MainLocations'">
                                    <ul>
                                        <li><strong>{{config.Params.mainlocationName}} ID</strong> (optional): If non-zero will update {{config.Params.mainlocationName}}, otherwise will add</li>
                                        <li><strong>{{config.Params.mainlocationName}} Name</strong> (optional/required): Long Name of the {{config.Params.mainlocationName}}</li>
                                        <li><strong>Address Line1</strong> (optional): Address Line 1</li>
                                        <li><strong>Address Line2</strong> (optional): Address Line 2</li>
                                        <li><strong>City</strong> (optional): City</li>
                                        <li><strong>{{config.Params.state}}</strong> (optional)</li>
                                        <li><strong>{{config.Params.zipcode}}</strong> (optional): {{config.Params.zipcode}}</li>
                                        <li><strong>Phone Number</strong> (optional): Phone Number</li>
                                        <li><strong>Contact Name</strong> (optional): {{config.Params.mainlocationName}} contact</li>
                                        <li><strong>Email Address</strong> (optional): Email of the {{config.Params.mainlocationName}} contact</li>
                                        <li><strong>Minutes Between {{config.Params.games}} (Setup Time)</strong> (optional): Minutes after a {{config.Params.game.toLowerCase()}} to prepare for the new {{config.Params.game.toLowerCase()}} (i.e. flood time, set up nets, install new bases)</li>
                                        <li><strong>Costs</strong> (optional): Default cost using the {{config.Params.mainlocationName}} for one {{config.Params.game.toLowerCase()}}</li>
                                        <li><strong>{{config.Params.locationName}} Name</strong> (optional/required): Every {{config.Params.mainlocationName}} contains one or more {{config.Params.locationNames}}.  Enter a unique name for each, separated by a comma. (i.e. {{config.Params.locationNames}}1, {{config.Params.locationNames}}2)</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate {{config.Params.mainlocationNames}}. If {{config.Params.mainlocationName}} ID is present, optional/required fields are optional.</p>
                                </div>

                                <div v-else-if="ImportArea == 'Organizations'">
                                    <ul>
                                        <li><strong>Organization ID</strong> (optional): If non-zero will update organization, otherwise will add</li>
                                        <li><strong>Organization Name</strong> (required): Name of the Organization</li>
                                        <li><strong>Address Line1</strong> (optional): Address Line 1</li>
                                        <li><strong>Address Line2</strong> (optional): Address Line 2</li>
                                        <li><strong>City</strong> (optional): City</li>
                                        <li><strong>{{config.Params.state}}</strong> (optional)</li>>
                                        <li><strong>{{config.Params.zipcode}}</strong> (optional): {{config.Params.zipcode}}</li>
                                        <li><strong>Phone</strong> (optional): Organization Main Phone Number</li>
                                        <li><strong>Contact Name</strong> (optional): Main Contact Name for Invoicing</li>
                                        <li><strong>Contact Email Address</strong> (optional): Contact Email Address</li>
                                        <li><strong>Invoices</strong> (optional): y = email contact invoices, n - do not send</li>
                                        <li><strong>Hourly Flat</strong> (optional): either  h = hourly, f = flat (fixed),</li>
                                        <li><strong>Default Fee</strong> (optional): Default rate for rentals</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate Organization names.</p>
                                </div>
                                <div v-else-if="ImportArea == 'Players'">
                                    <ul>
                                        <li><strong>Player ID</strong> (optional): If non-zero will update player, otherwise will add</li>
                                        <li><strong>Season</strong> (optional/required): Season</li>
                                        <li><strong>{{config.Params.programName}}</strong> (optional/required): {{config.Params.programName}} Name</li>
                                        <li><strong>Team</strong> (optional/required): Team Name</li>
                                        <li><strong>First Name</strong> (optional/required): First Name of the Player</li>
                                        <li><strong>Last Name</strong> (optional/required): Last Name of the Player</li>
                                        <li><strong>Email Address</strong> (optional): Email Address for Player correspondence</li>
                                        <li><strong>Player Type</strong> (optional): Must match an entry in Player Types</li>
                                        <li><strong>ID Number</strong> (optional): USA No or similar</li>
                                        <li><strong>Registration No</strong> (optional): Unique number players use as a reference in the software</li>
                                        <li><strong>Registration League ID</strong> (optional): League this player will compete in</li>
                                        <li><strong>Jersey Number</strong> (optional): Jersey Number</li>
                                        <li><strong>DOB</strong> (optional): Date of Birth : YYYY-MM-DD format</li>
                                        <li><strong>Address Line1</strong> (optional): Address Line 1</li>
                                        <li><strong>Address Line2</strong> (optional): Address Line 2</li>
                                        <li><strong>City</strong> (optional): City</li>
                                        <li><strong>{{config.Params.state}}</strong> (optional)</li>
                                        <li><strong>{{config.Params.zipcode}}</strong> (optional): {{config.Params.zipcode}}</li>
                                        <li><strong>Home Phone</strong> (optional): Home Phone Number</li>
                                        <li><strong>Mobile Phone</strong> (optional): Mobile or Cell Phone Number</li>
                                        <li><strong>Tags</strong> (optional): Comma separated list, will replace all tags, any new tags will be addded.</li>
                                        <li><strong>XXXX</strong> (optional): You can add any other data.  The heading will be the field name. Examples might be Position, School, Doctor, Emergency Contact, etc</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate Players. If Player ID is present, optional/required fields are optional.</p>
                                </div>
                                <div v-else-if="ImportArea == 'Programs'">
                                    <ul>
                                        <li><strong>{{config.Params.programName}} ID</strong> (optional): If non-zero will update {{config.Params.programName}}, otherwise will add</li>
                                        <li><strong>{{config.Params.programName}} Name</strong> (optional/required): Long Name of the {{config.Params.programName}}</li>
                                        <li><strong>{{config.Params.programName}} Short Name</strong> (optional): Short Name of the {{config.Params.programName}}</li>
                                        <li><strong>Address Line1</strong> (optional): Address Line 1</li>
                                        <li><strong>Address Line2</strong> (optional): Address Line 2</li>
                                        <li><strong>City</strong> (optional): City</li>
                                        <li><strong>{{config.Params.state}}</strong> (optional)</li>
                                        <li><strong>{{config.Params.zipcode}}</strong> (optional): {{config.Params.zipcode}}</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate {{config.Params.programName}}s. If {{config.Params.programName}} ID is present, optional/required fields are optional.</p>
                                </div>
                                <div v-else-if="ImportArea == 'Teams'">
                                    <ul>
                                        <li><strong>Team ID</strong> (optional): If non-zero will update team, otherwise will add</li>
                                        <li><strong>Season</strong> (optional/required): One of Team ID or Season is required</li>
                                        <li><strong>Team Name</strong> (optional/required): This is the most used common name for the team</li>
                                        <li><strong>Team Short Name</strong> (optional): Shorter version of the teams name</li>
                                        <li><strong>Team Name3</strong> (optional): A 3rd alternate name</li>
                                        <li><strong>{{config.Params.programName}}</strong> (optional/required): Name of the {{config.Params.programName}} the team is in</li>
                                        <li><strong>League</strong> (optional): Name of the Registered League for this team</li>
                                        <li><strong>Division</strong> (optional): Name of the Division (in the Registered League) the team is in</li>
                                        <li><strong>Division Type</strong> (optional): Registered Division Type (need League if adding team, will add if does not exist)</li>
                                        <li><strong>Division Level</strong> (optional): Registered Division Level (need League if adding team, will add if does not exist)</li>
                                        <li><strong>Registered {{config.Params.games}}</strong> (optional): Number of {{config.Params.games}} this team is registered for</li>
                                    </ul>
                                    <p>The import will check for duplicate team names in the selected season.  If Team ID is present, optional/required fields are optional.</p>
                                    <p>If Division is used, League is required.  Both must be in the software and match the League and Division Names.</p>
                                </div>
                                <div v-if="ImportArea == 'ScheduleGames'">
                                    <ul>
                                        <li><strong>ScheduleTimeSlotID</strong> (optional/required): If included, will link the game to this slot, otherwise otherwise the game is unassigned.</li>
                                        <li><strong>Schedule{{config.Params.game}}ID</strong> (optional/required): Unique ID of the {{config.Params.game}}.  If included, will update, otherwise will add new {{config.Params.game.toLowerCase()}}. If a tournament {{config.Params.schedule.toLowerCase()}}, then adding will not be allowed.</li>
                                        <li><strong>Season</strong> (optional/required): Required if no Schedule{{config.Params.game}}ID and adding {{config.Params.game.toLowerCase()}}</li>
                                        <li><strong>{{config.Params.schedule}} Name</strong> (optional/required): Required if no Schedule{{config.Params.game}} ID and adding {{config.Params.game.toLowerCase()}} </li>
	                                    <li><strong>HomeTeamID</strong> (require/optional): Home Teams Unique ID: This or Home Team name is require if adding game.</li>
	                                    <li><strong>Home Team</strong> (optional): Name of the Home Team</li>
                                        <li><strong>HomeLeagueDivisionID</strong> (optional/required): Home Division, required only if adding new {{config.Params.game.toLowerCase()}}</li>
                                        <li><strong>HomeDivisionName</strong> (optional): Home Division name</li>
	                                    <li><strong>VisitingTeamID</strong> (optional/required): Visiting Teams Unique ID: This or Visiting Team name is require if adding game.</li>
	                                    <li><strong>Visiting Team</strong> (optional): Name of the Visiting Team</li>
                                        <li><strong>VisitingLeagueDivisionID</strong> (optional/required): Visiting Division, required only if adding new {{config.Params.game.toLowerCase()}}</li>
                                        <li><strong>VisitingDivisionName</strong> (optional): Visiting Division name</li>
                                    </ul>
                                    <strong>This imports {{config.Params.games.toLowerCase()}} that will be scheduled.  If a new {{config.Params.games.toLowerCase()}}, it will place it in most appropriate rounds to help scheduling.</strong>
                                </div>
                                <div v-if="ImportArea == 'ScheduleGamesT'">
                                    <ul>
                                        <li><strong>ScheduleTimeSlotID</strong> (optional/required): If included, will link the game to this slot, otherwise otherwise the game is unassigned.</li>
                                        <li><strong>Schedule{{config.Params.game}}ID</strong> (required): Unique ID of the {{config.Params.game}}.  If included, will update, otherwise will add new {{config.Params.game.toLowerCase()}}. If a tournament {{config.Params.schedule.toLowerCase()}}, then adding will not be allowed.</li>
                                    </ul>
                                   <strong>Tournament {{config.Params.games.toLowerCase()}} can only have the ScheduleTimeSlotID added or removed.  No other {{config.Params.game.toLowerCase()}} updates are performed.  If fields are present, they are ignored.</strong>
                                </div>
                                <div v-if="ImportArea == 'Tournament'">
                                    <p>This is used to update Tournament Draws {{config.Params.game.toLowerCase()}} locations only. The file should first be exported from the Tournament screen, modified and then reimported.</p>
                                    <ul>
                                        <li><strong>Tournament{{config.Params.game}}ID</strong> (required): Tournament Draws can only be updated.</li>
                                        <li><strong>{{config.Params.game}}ID</strong> (required): This is the ID that links to a Live {{config.Params.game}}.  It should be left unchanged from the Export.</li>
                                        <li><strong>{{config.Params.game}}LocationID</strong> (optional/required): If included will update, all other location data is ignored.</li>
                                        <li><strong>{{config.Params.game}} Date</strong> (optional/required): YYYY-MM-DD date format.  Required if no {{config.Params.game}}LocationID.</li>
                                        <li><strong>Start Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM.  Required if no {{config.Params.game}}LocationID.</li>
                                        <li><strong>End Time</strong> (optional/required): 15:35:00 24 hour, or 3:35 PM.  Required if no {{config.Params.game}}LocationID.</li>
                                        <li><strong>Duration</strong> (optional/required): Number of minutes of the entry (use Duration or End Time not both).  Required if no {{config.Params.game}}LocationID. </li>
                                        <li><strong>{{config.Params.mainlocationName}}</strong> (optional/required): Name of the {{config.Params.mainlocationName}}.  Required if no {{config.Params.game}}LocationID.</li>
                                        <li><strong>{{config.Params.locationName}}</strong> (optional/required): Name of the {{config.Params.locationName}} in the {{config.Params.mainlocationName}}.  Required if no {{config.Params.game}}LocationID.</li>
                                        <li><strong>X{{config.Params.playingsurface}} Name</strong> (optional): If the {{config.Params.game}} is sharing the {{config.Params.locationName}}, this is the X{{config.Params.playingsurface}} Name given.</li>
                                    </ul>
                                    <br>
                                    <strong>If Adding a New Calendar Slot (software will try to match to an existing open slot), these fields will be put on the new Calendar Entry.</strong>
                                    <el-collapse class="colorfield">
                                        <el-collapse-item name="1">
                                            <template #title>
                                                <span v-html="'<strong>Color</strong> (optional): One of the support Hex Number values - <strong>Click to see list</strong>'"></span>
                                            </template>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div v-for="color in colorsa">
                                                        <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div v-for="color in colorsb">
                                                        <span :style="'color: ' + color.value" v-html="color.value + ' - ' + color.text"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                    <ul>
                                        <li><strong>Cost</strong> (optional): Your cost for this calendar slot</li>
                                        <li><strong>{{config.Params.schedule}} Types</strong> (optional): Comma separated list of {{config.Params.schedule}} Types</li>
                                        <li><strong>Tags</strong> (optional): Comma separated list of {{config.Params.schedule}} Tags</li>
                                    </ul>
                                    <br>
                                    <p>The import will check for duplicate and overlapping Calendar Entries. If {{config.Params.game}}LocationID is present, optional/required fields are optional.</p>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>

                    <div v-if="ImportArea != ''" style="border-top: 1px solid #CCCCCC;padding: 26px 0px;">
                        <h5>The following workflow will ensure that your file is successfully imported.</h5>
                        <el-steps :active="activeStep" finish-status="success" align-center>
                            <el-step title="Upload & Verify"></el-step>
                            <el-step title="Correct Errors"></el-step>
                            <el-step title="Update Database"></el-step>
                        </el-steps>
                        <div class="row">
                            <div class="col-4" style="text-align: center;">
                                <form  class="form-horizontal fade-in-effect" novalidate method="post" enctype="multipart/form-data">
                                    <button style="position: relative;" class="mt-4 btn btn-icon btn-primary">
                                        <input type="file" v-if="uploadReady" id="fileupload" ref="fileupload" name="csvfile" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" class="input-file">
	                                    <i class="fa-solid fa-cloud-arrow-up"></i> <span>Upload and Verify </span>
                                    </button>
                                </form>
                            </div>
                            <div class="col-4" style="text-align: center;" >
                                <div v-if="activeStep == 1" class="errormessage"><span>Review the import file below and scroll through to review {{numErrors}} Error Record{{(numErrors > 1 ?'s':'')}}.  Correct error{{(numErrors > 1 ?'s':'')}} and upload to verify again.</span></div>
                                <div v-if="activeStep == 2 && numWarnings > 0" class="warningmessage"><span>Your import file was verified to be correct, but with some warnings. You should review warnings but you may now Update the Database with your changes.</span></div>
                                <div v-if="activeStep == 2 && numWarnings == 0" class="noerrormessage"><span>Your import file was verified to be correct.  You may now Update the Database with your changes.</span></div>
                            </div>
                            <div class="col-4" style="text-align: center;">
                                <template v-if="$createAccess() && $modifyAccess()">
                                    <button :disabled="UpdateRecords.length == 0 && activeStep != 2"  class="mt-4 btn btn-icon btn-warning" @click.prevent="cnfmMassUpdateDatabase">
                                        <i class="fas fa-file-export"></i>  <span>Update Database</span>
                                    </button>
                                </template>
                                <template v-else>
                                    <div class="errormessage"><span>You do not have access to upload files to the Database. You may still verify files for errors.</span></div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div v-if="OriginalRecords.length > 0">
                        <el-radio-group v-if="numErrors > 0 || numWarnings > 0" v-model="showerrors" size="small" class="mb-2" @change="currentPage = 1;">
                            <el-radio-button selected label="a">All Records</el-radio-button>
                            <el-radio-button v-if="numErrors > 0" label="e">Only {{numErrors}} Error{{(numErrors > 1 ?'s':'')}}</el-radio-button>
                            <el-radio-button v-if="numWarnings > 0" label="w">Only {{numWarnings}} Warning{{(numWarnings > 1 ?'s':'')}}</el-radio-button>
                        </el-radio-group>
                        <div class="guide mt-2 mb-2">
                            <div class="guidecolor error">&nbsp;</div><div class="guidetext"> - Fields with Errors</div>
                            <div class="guidecolor warning">&nbsp;</div><div class="guidetext"> - Fields with Warnings</div>
                            <div class="guidecolor success">&nbsp;</div><div class="guidetext"> - Fields with Corrected data (should be verified)</div>
                        </div>
                        <el-table
                            :data="filteredOriginalRecords"
                            border
                            stripe
                            key="importresults"
                            empty-text="No Import Records"
                            :cell-class-name="tableCellClassName"
                            max-height="600"
                            size="small"
                            style="width: 100%;">
                            <el-table-column
                                label="No."
                                prop="num"
                                fixed="left"
                                align="center"
                                width="50">
                            </el-table-column>
	                        <template v-for="(Heading,index) in OriginalHeadings">
	                            <el-table-column
	                                 v-if="Heading.value != 'importmessages'"
	                                 :key="`cols-${index}`"
	                                 :label="Heading.label"
	                                 :prop="Heading.value"
	                                 align="Left"
	                                 min-width="100"
	                            >
	                                <template #default="scope">
	                                    <div v-if="scope.row[Heading.value]" v-html="scope.row[Heading.value]['value']"></div>
	                                </template>
	                            </el-table-column>
	                        </template>
                            <el-table-column
                                 key="importmessages"
                                 label="Messages"
                                 fixed="right"
                                 prop="importmessages"
                                 align="Left"
                                 min-width="200"
                            >
                                <template #default="scope">
                                    <ul>
                                        <li v-for="(msg,msgindex) in scope.row.importmessages" v-html="msg"></li>
                                    </ul>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="row pagination mt-4">
                            <div class="col-12">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    background
                                    :current-page="currentPage"
                                    :page-sizes="pageSizes"
                                    :page-size="sizePerPage"
                                    layout="prev, pager, next"
                                    :total="count">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Util from "../assets/js/util";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_FILESAVING = 4

export default {
    name: 'Imports',
    data() {
        return {
            loading: false,
            activeStep : 0,
            uploadReady : true,
            currentStatus : STATUS_INITIAL,
            collapse : ['1'],
            showerrors : 'a',
            ActiveSeasons : [],
            Leagues : [],
            Programs : [],
            Schedules : [],
            SeasonID : '',
            LeagueID : '',
            ProgramID : '',
            ScheduleID : '',
            colorsa :
                [
                    { text: 'Red', value: '#F44336' },
                    { text: 'Pink', value: '#E91E63' },
                    { text: 'Purple', value: '#9C27B0' },
                    { text: 'Deep Purple', value: '#673AB7' },
                    { text: 'Indigo', value: '#3F51B5' },
                    { text: 'Blue', value: '#2196F3' },
                    { text: 'Highlight Color', value: '#1976d2' },
                    { text: 'Light Blue', value: '#03A9F4' },
                    { text: 'Cyan', value: '#00BCD4' },
                    { text: 'Teal', value: '#009688' },
                    { text: 'Green', value: '#4CAF50' },
                ],
            colorsb :
                [
                    { text: 'Light Green', value: '#8BC34A' },
                    { text: 'Lime', value: '#CDDC39' },
                    { text: 'Yellow', value: '#FFEB3B' },
                    { text: 'Amber', value: '#FFC107' },
                    { text: 'Orange', value: '#FF9800' },
                    { text: 'Deep Orange', value: '#FF5722' },
                    { text: 'Brown', value: '#795548' },
                    { text: 'Blue Gray', value: '#607D8B' },
                    { text: 'Gray', value: '#9E9E9E' },
                    { text: 'Black', value: '#000000' }
                ],

            ImportAreas: [],
            ImportArea : '',
            ImportParameters: {
                Leagues : ['Season'],
                Divisions : ['Season'],
                Programs : [],
                Teams : ['Season'],
                Contacts : ['Season'],
                Players : ['Season'],
                'Calendar Slots' : [],
                'Constraint Slots' : [],
                'Games' : ['Season', 'Schedule'],
                'Schedule Games' : ['Season', 'Schedule'],
                Organizations : [],
            },
            OriginalHeadings : [],
            OriginalRecords : [],
            ImportFile : [],
            UpdateRecords : [],
            numErrors : 0,
            numWarnings : 0,
            inserts : 0,
            updates : 0,
            currentPage: 1,
            count: 0,
            sizePerPage: 250,
            pageSizes: [250],
        }
    },
    props:["config","Season","Calling", "parentrefs"],
    computed: {
        ...mapGetters({ currentUser: 'currentUser' }),
        ...mapGetters({ componentIDs: 'componentIDs' }),
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
        filteredOriginalRecords() {
            if (this.showerrors == 'e' && this.numErrors > 0) {
                var recs = [];
                for (let i = 0; i < this.OriginalRecords.length; i++) {
                    if (this.OriginalRecords[i].hasError == 'y') {
                        recs.push(this.OriginalRecords[i]);
                    }
                }
            } else if (this.showerrors == 'w' && this.numWarnings > 0) {
                var recs = [];
                for (let i = 0; i < this.OriginalRecords.length; i++) {
                    if (this.OriginalRecords[i].hasWarning == 'y') {
                        recs.push(this.OriginalRecords[i]);
                    }
                }
            } else {
                recs = this.OriginalRecords;
            }
            this.count = recs.length;
            return recs.slice((this.currentPage - 1) * this.sizePerPage, (this.currentPage) * this.sizePerPage);
        },
    },
    methods: {
        handleSizeChange(val) {
            this.sizePerPage = val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        tableCellClassName({row, column, rowIndex, columnIndex}) {
            if (columnIndex == 0) {
                if (this.filteredOriginalRecords[rowIndex].hasError == 'y') {
                        return 'hasError';
                } else if (this.filteredOriginalRecords[rowIndex].hasWarning == 'y') {
                    return 'hasWarning';
                }
            } else {
                if (this.filteredOriginalRecords[rowIndex][column.property] && this.filteredOriginalRecords[rowIndex][column.property].status) {
                    switch (this.filteredOriginalRecords[rowIndex][column.property].status) {
                        case 'E':
                            return 'hasError';
                            break;
                        case 'W':
                            return 'hasWarning';
                            break;
                        case 'G':
                            return 'hasEnhancement';
                            break;
                    }
                }
            }
            return '';
        },
        ParameterValid (param) {
            if (this.ImportArea) {
                if (!this.ImportParameters[this.ImportArea] || this.ImportParameters[this.ImportArea].indexOf(param) === -1) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        getInitialData () {
            var tables = [];
            axios.post('/index/tableData', {tables: tables},
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (!this.SeasonID && this.Seasons.length > 0) {
                            this.SeasonID = this.Seasons[0]['SeasonID'];
                        }
                    }
                }).catch(response => {

            });
        },
        importareachanged () {
            this.UpdateRecords = [];
            this.ImportFile = [];
            this.OriginalHeadings = [];
            this.OriginalRecords = [];
            this.activeStep = 0;
        },
        cnfmMassUpdateDatabase() {
            this.$prompt('Enter Mass Change passcode in the box below and hit OK to confirm importing these records.  There will be: ' + (this.updates > 0 ? this.updates + ' updates' + (this.inserts > 0 ? ', ' : ''): '') + (this.inserts > 0 ? this.inserts + ' additions ' : '') , 'Import Database Update', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
                dangerouslyUseHTMLString: true,
                inputPattern: new RegExp('^'+window.atob(this.config.Params.MassChangePassCode) + '$'),
                inputErrorMessage: 'Invalid Response'
            }).then(({value}) => {
                this.updateDatabase();
            }).catch(() => {

            });
        },
        updateDatabase () {
            if (this.isSaving) {
                return true;
            }
            // upload data to the server
            this.currentStatus = STATUS_SAVING;
            this.$startProgressBar(this.parentrefs);

            Util.createCookie('scheduleRunning', 'y');
            this.loading = true;
            axios.post('/imports/update', {UpdateRecords : this.UpdateRecords, ImportArea : this.ImportArea},

            )
                .then(response => {

                    if (response.data.result == 'success') {
                        this.importareachanged();
                        this.currentStatus = STATUS_SUCCESS;
                        this.$stopProgressBar(this.parentrefs);
                        this.$confirm((response.data.message ? response.data.message : "Database Updates Complete"), 'Success', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: 'OK',
                            type: 'success',
                            showClose: false,
                            showCancelButton: false
                        });
                    } else {
                        this.currentStatus = STATUS_FAILED;
                        this.$alert((response.data.message ? response.data.message : "Database Updates Finished with Severe Errors"), 'Error', {
                            dangerouslyUseHTMLString: true,
                            customClass: 'errormsg',
                            confirmButtonText: 'OK',
                            type:'error',
                            showClose: false,
                            showCancelButton: false
                        });
                        this.$failProgressBar(this.parentrefs);
                    }
                    document.getElementById("fileupload").value = "";
                    Util.eraseCookie('scheduleRunning');
                    this.uploadReady = false;
                    this.$nextTick(() => {
                        this.uploadReady = true;
                    });
                    this.loading = false;
                }).catch(response => {
                    document.getElementById("fileupload").value = "";
                    Util.eraseCookie('scheduleRunning');
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
            });

        },
        uploadImport(formData, fieldName) {
            if (this.isSaving) {
                return true;
            }
            // upload data to the server
            this.currentStatus = STATUS_SAVING;
            this.$startProgressBar(this.parentrefs);

            Util.createCookie('scheduleRunning', 'y');
            this.loading = true;
            this.showerrors = 'a';
            axios.post('/imports/verify', formData,
                { headers: { 'Content-Type': 'multipart/form-data'} }
            )
                .then(response => {

                    this.ImportRecords = (response.data.ImportRecords ? response.data.ImportRecords : []);
                    this.OriginalHeadings = (response.data.OriginalHeadings ? response.data.OriginalHeadings : []);
                    this.OriginalRecords = (response.data.OriginalRecords ? response.data.OriginalRecords : []);
                    this.numErrors = (response.data.numErrors ? response.data.numErrors : 0);
                    this.numWarnings = (response.data.numWarnings ? response.data.numWarnings : 0);
                    this.inserts = (response.data.inserts ? response.data.inserts : 0);
                    this.updates = (response.data.updates ? response.data.updates : 0);
                    this.count = this.OriginalRecords.length;

                    if (response.data.result == 'success') {
                        this.activeStep = 2;
                        this.UpdateRecords = response.data.UpdateRecords;
                        this.currentStatus = STATUS_SUCCESS;
                        this.$stopProgressBar(this.parentrefs);
                        this.$confirm((response.data.message ? response.data.message : "Import Verification Complete"), 'Success', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: 'OK',
                            type: 'success',
                            showClose: false,
                            showCancelButton: false
                        });
                    } else if (response.data.result == 'fail') {
                        this.activeStep = 1;
                        this.UpdateRecords = [];
                        this.currentStatus = STATUS_FAILED;
                        this.$alert((response.data.message ? response.data.message  : "Import Finished with Record Errors"), 'Error', {
                            dangerouslyUseHTMLString: true,
                            customClass: 'errormsg',
                            confirmButtonText: 'OK',
                            type:'warning',
                            showClose: false,
                            showCancelButton: false
                        });
                        this.$failProgressBar(this.parentrefs);
                    } else {
                        this.UpdateRecords = [];
                        this.currentStatus = STATUS_FAILED;
                        this.$alert((response.data.message ? response.data.message  : "Import Finished with Severe Errors, could not read file."), 'Error', {
                            dangerouslyUseHTMLString: true,
                            customClass: 'errormsg',
                            confirmButtonText: 'OK',
                            type:'error',
                            showClose: false,
                            showCancelButton: false
                        });
                        this.$failProgressBar(this.parentrefs);
                    }
                    document.getElementById("fileupload").value = "";
                    Util.eraseCookie('scheduleRunning');
                    this.uploadReady = false;
                    this.$nextTick(() => {
                        this.uploadReady = true;
                    });
                    this.loading = false;
                }).catch(response => {
                    document.getElementById("fileupload").value = "";
                    Util.eraseCookie('scheduleRunning');
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
            });
        },
        filesChange(fieldName, fileList) {
            if (this.isSaving || this.isFileSaving) {
                return true;
            }
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    formData.append(fieldName, fileList[x], fileList[x].name);
                });
            formData.append('ImportArea', this.ImportArea);
            // save it
            this.uploadImport(formData, fieldName);

        },

        displayPopup (message) {
            this.$alert(message, 'Error', {
                confirmButtonText: 'OK',
            });
        }
    },
    mounted () {
        this.ImportAreas = [
            {id : 'MainLocations', label : this.config.Params.mainlocationNames},
            {id : 'Calendar Slots', label : 'Availability Calendar Slots'},
            {id : 'Constraint Slots', label : 'Constraint Calendar Slots'},
            {id : 'Contacts', label : 'Contacts'},
            {id : 'Divisions', label : 'Divisions'},
            {id : 'Leagues', label : 'Leagues'},
            {id : 'Games', label : 'Live ' + this.config.Params.games},
            {id : 'Organizations', label : 'Organizations'},
            {id : 'Players', label : 'Players'},
            {id : 'Programs', label : this.config.Params.programNames},
            {id : 'Teams', label : 'Teams'},
            {id : 'ScheduleGames', label : 'UnPublished ' + this.config.Params.games},
            {id : 'Tournament', label : 'Tournament Draws'},
        ];
         this.ImportAreas.sort(function(a, b){if (a.label < b.label) { return -1;} else if (a.label > b.label) { return 1;} else { return 0;} });

        if (this.Calling) {
            this.ImportArea = this.Calling;
        }
    },
}
</script>
<style>
    #imports .el-table--striped .el-table__body tr.el-table__row--striped td.hasError, #imports td.hasError {
        background-color: rgb(220, 53, 69, 0.4);
    }
    #imports .el-table--striped .el-table__body tr.el-table__row--striped td.hasWarning, #imports td.hasWarning {
        background-color: rgb(255,235,59, 0.4);
    }
    #imports .el-table--striped .el-table__body tr.el-table__row--striped td.hasEnhancement, #imports td.hasEnhancement {
        background-color: rgb(40,167,69, 0.4);
    }
    #imports .el-table li {
        list-style: decimal;
        margin-left: 15px;
    }
    #imports div.el-collapse-item__header {
        margin: 16px 0px;
        padding: 5px;
        border-top: 1px solid #000;
        border-top: 1px solid #000;
        background-color:#666;
        color: #FFF;
        height: 30px;
    }
    #imports .colorfield div.el-collapse-item__header {
        margin: 4px -3px;
        padding: 2px;
        border: 0;
        background-color:#FFF;
        color: #303133;
        height: 20px;
        font-size: 13px;
        font-weight: 400;
    }
    #imports .colorfield.el-collapse {
        border: 0;
    }

</style>
<style scoped>
    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        left:0;
        top:0;
    }

    .guidecolor.error {
        background-color: rgb(220, 53, 69, 0.4);
    }
    .guidecolor.warning {
        background-color: rgb(255,235,59, 0.4);
    }
    .guidecolor.success {
        background-color: rgb(40,167,69, 0.4);
    }
    .errormessage {
        color: #dc3545;
        border: 2px solid #dc3545;
        padding: 5px;
    }
    .warningmessage {
        color: #000000;
        border: 2px solid #ffc107;
        padding: 5px;
    }
    .noerrormessage {
        color: #28a745;
        border: 2px solid #28a745;
        padding: 5px;
    }
    .guidecolor {
        display: inline-block;
        height: 20px;
        width: 40px;
        border: 1px solid #333;
    }
    .guidetext {
        font-size: 12px;
        display: inline-block;
        padding-left: 5px;
        padding-right: 10px;
    }
</style>
