<template>
    <div id="eventattendance" v-loading="loading">
        <el-form ref="eventattendanceForm" label-position="top" label-width="120px" >
            <div class="row">
                <div class="col-12 fullpage">
                    <el-form-item label="Beginning -> End" prop="reportingdates">
                        <el-date-picker
                            v-model="reportingdates"
                            type="daterange"
                            unlink-panels
                            range-separator="To"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            format="MMM D, YYYY"
                            value-format="YYYY-MM-DD">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="config.Params.mainlocationNames" prop="MainLocationIDs">
                        <el-select key="select6b"  :class="{'oneHundred' : !MainLocationIDAccess, 'fifty' : MainLocationIDAccess}" style="min-height: 32px;" v-model="MainLocationIDs" :placeholder="'All ' + config.Params.mainlocationNames" multiple default-first-option filterable clearable>
                            <el-option
                                v-for="item in SiteLocations"
                                :key="item.MainLocationID"
                                :label="item.MainLocationName"
                                :value="item.MainLocationID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="!MainLocationIDAccess" label="Organization" prop="OrganizationID">
                        <el-select key="select2"  v-model="OrganizationID" placeholder="All Organizations" class="oneHundred" default-first-option filterable clearable>
                            <el-option v-for="type in Organizations"
                                       :value="type.OrganizationID"
                                       :key="type.OrganizationID"
                                       :label="type.OrganizationName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="!MainLocationIDAccess" label="Schedule Types" prop="ScheduleTypes">
                        <el-select key="select7" style="width: 100%;min-height: 32px;" placeholder="All ScheduleTypes"   v-model="ScheduleTypeIDs" default-first-option filterable clearable
                                   multiple
                        >
                            <el-option
                                v-for="item in ScheduleTypes"
                                :key="item.ScheduleTypeID"
                                :label="item.ScheduleTypeName"
                                :value="item.ScheduleTypeID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-12 fullpage">
                    <el-button class="mt-1 btn-primary" type="primary" @click="getTimeSlots">
                        <span> Show Usage</span> <i class="fa-solid fa-caret-right"></i>
                    </el-button>
                    <el-button v-if="timeslots != ''" type="info" class="btn-secondary" style="float:right;" @click="viewPDF">
                        <i class="fas fa-print"></i>  <span>Print</span>
                    </el-button>
                    <el-button v-if="timeslots != ''" type="info" class="btn-secondary" style="float:right;margin-right: 20px;" @click="exportCSV">
                        <i class="fas fa-file-export"></i>  <span>Export CSV</span>
                    </el-button>
                </div>
            </div>
        </el-form>
        <div id="eventattendancePrint">
	        <template v-for="(Location, index) in timeslots">
	            <div v-if="timeslots != ''" class="row mt-4">
	                <h5 style="width: 100%;font-weight: 800;">{{index}}</h5>
	                <el-table
	                    :data="Location"
	                    :key="'table2'+index"
	                    :sort-method="sortTimeSlotGameDate"
	                    stripe border
	                    size="small"
	                    style="width: 100%; font-size:14px;padding:0px">
	                    <el-table-column
	                        prop="GameDateF"
	                        label="Date"
	                        width="160"
	                        align="left"
	                        sortable
	                        :sort-method="sortTimeSlotGameDate"
	                    >
	                    </el-table-column>
	                    <el-table-column
	                        prop="StartTime"
	                        label="Time"
	                        width="100"
	                        align="left"
	                        sortable
	                        :sort-method="sortTimeSlotGameTime"
	                    >
	                    </el-table-column>
	                    <el-table-column
	                        prop="Duration"
	                        label="Duration"
	                        width="100"
	                        align="left"
	                    >
	                        <template #default="scope">
	                            <div v-html="scope.row.Duration + ' min.'"></div>
	                        </template>
	                    </el-table-column>
	                    <el-table-column
	                        prop="LocationName"
	                        :label="config.Params.locationName"
	                        width="130"
	                        align="left"
	                        sortable
	                    >
	                    </el-table-column>
	                    <el-table-column
	                        prop="title"
	                        label="Event"
	                        min-width="200"
	                        align="left"
	                    >
	                        <template #default="scope">
	                            <div v-html="scope.row.Title"></div>
	                            <div class="description" v-html="scope.row.Description"></div>
	                        </template>
	                    </el-table-column>
	                    <el-table-column
	                        prop="Attendees"
	                        label="Attendees / Contacts"
	                        align="left"
	                        min-width="150"
	                    >
	                        <template #default="scope">
		                        <template v-if="scope.row.data && scope.row.FormParams">
		                            <template v-if="scope.row.FormDataID > 0 && scope.row.FormParams">
		                                <div style="font-size: 14px;" v-html="scope.row.FormParams.FirstName+' '+scope.row.FormParams.LastName+'<br>'+scope.row.FormParams.Email+(scope.row.FormParams.PhoneDaytime != scope.row.FormParams.PhoneEvening ? '<br>Day: '+scope.row.FormParams.PhoneDaytime+'<br>Evening: '+scope.row.FormParams.PhoneEvening : '<br>'+scope.row.FormParams.PhoneDaytime)"></div>
		                            </template>
		                            <template v-else-if="scope.row.data.Attendees" v-for="attendee in scope.row.data.Attendees">
		                                <div v-if="attendee.FirstName" style="clear:both;margin-bottom: 3px;"><div style="float:left; font-size: 20px; font-weight: 800;">&#9634;</div><div style="float:left;margin-left: 5px; font-size: 14px;" v-html="attendee.FirstName+' '+attendee.LastName"></div></div>
		                            </template>
		                        </template>
	                        </template>
	                    </el-table-column>
	                </el-table>
	            </div>
	        </template>
            <div v-if="searchPerformed" class="mt-4">
                <h5 v-if="timeslots == ''">Unfortunately, no Events with these parameters can be found.</h5>
            </div>
        </div>
        <modal v-if="showPrintModal" @close="showPrintModal = false" width="550px" top="450px">
	        <template v-slot:header>
		        <h5 class="modal-title">
			        Print
		        </h5>
	        </template>
	        <template v-slot:body >
		        <Print ref="print" :data="timeslots" :columns="pdfColumns" printDomID="#eventattendancePrint"  @close="showPrintModal = false"/>
	        </template>
	        <template v-slot:footer>
		        &nbsp;
	        </template>
        </modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Util from "../assets/js/util";
    import Modal from './global/Modal.vue';
    import Print from './global/Print.vue';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
    export default {
        name: 'eventattendance',
        components: {
            Modal,
            Print
        },
        data() {
            return {
                loading: false,
                searchPerformed: false,
                showPrintModal: false,
                currentStatus : STATUS_INITIAL,
                MainLocationIDs : [],
                SiteLocations : [],
                Organizations : [],
                OrganizationID : '',
                ScheduleTypes: [],
                ScheduleTypeIDs : [],
                timeslots : '',
                pdfColumns : [{Data : "GameDateF", Title : "Date", Width : 15, Align : "L", Order: 1},{Data : "StartTime", Title : "Time", Width : 10, Align : "C", Order: 2},{Data : "LocationName", Title : this.config.Params.locationName, Width : 15, Align : "L", Order: 3},{Data : "TitleDescription", Title : "Event", Width : 30, Align : "L", Order: 4},{Data : "Attendees", Title : "Attendees/Contact", Width : 30, Align : "L", Order: 5}],
                export : [],
                reportingdates: [],
                SlotTypes : [{type : 'o', name : 'Open'},{type : 'on', name : 'Open (Never Booked)'},{type : 'e', name : '3rd Party'},{type : 'ol', name : 'Online Booking'},{type : 'g', name : 'Games'},{type : 'p', name : 'Practices'},],
            }
        },
        props:["config", "parentrefs"],
        computed: {
            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            MainLocationIDAccess () {
                return this.$MainLocationIDAccess();
            },
        },
        methods: {
            sortTimeSlotGameDate (a,b) {
                var ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
                if (ret == 0) {
                    ret = parseInt(a.StartSeconds) - parseInt(b.StartSeconds);
                }
                return ret;
            },
            sortTimeSlotGameTime (a,b) {
                if (a.GameDate && b.GameDate) {
                    let ret = parseInt(a.StartSeconds) - parseInt(b.StartSeconds);
                    if (ret == 0) {
                        ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
                    }
                    return ret;
                } else {
                    return 0;
                }
            },
            formatPrice(value) {
	            if (value) {
		            return Util.formatPrice(this.config, parseFloat(value), '0');
	            } else {
		            return '';
	            }
            },
            getInitialData () {
                var tables = ['Locations','Organizations', 'SiteLocations', 'ScheduleTypes'];
                axios.post('/index/tableData', {'tables': tables },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        if (this.componentIDs.OIreportingdates) {
                            this.reportingdates = this.componentIDs.OIreportingdates;
                        }
                        if (this.componentIDs.OIMainLocationIDs) {
                            this.MainLocationIDs = this.componentIDs.OIMainLocationIDs;
                        }
                        if (this.componentIDs.OIOrganizationID) {
                            this.OrganizationID = this.componentIDs.OIOrganizationID;
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },


            getTimeSlots() {
                if (!this.reportingdates || this.reportingdates.length == 0) {
                    this.displayPopup ('Start and End Dates are Required.');
                    return true;
                }
                this.$startProgressBar(this.parentrefs);
                this.loading = true;
                let self = this;
                if (this.MainLocationIDAccess) {
                    this.OrganizationID = '';
                    this.ScheduleTypeIDs = [];
                }
                this.timeslots = '';
                this.searchPerformed = false;
                axios.post('/analyzer/gamemanagement/geticeusage', { ScheduleTypes : this.ScheduleTypeIDs, MainLocationIDs : this.MainLocationIDs, gamedates : this.reportingdates, OrganizationID : this.OrganizationID, SlotTypes : ['e','p'] },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            self.$store.dispatch('setID', {'OIMainLocationIDs': self.MainLocationIDs});
                            self.$store.dispatch('setID', {'OIreportingdates': self.reportingdates});
                            self.$store.dispatch('setID', {'OIOrganizationID': self.OrganizationID});

                            if (response.data.timeslots) {
                                this.timeslots = response.data.timeslots;
                            }
                            this.export = response.data.export;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.searchPerformed = true;
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },
            viewPDF() {
                if (this.timeslots != '') {
                    for (var key in this.timeslots) {
                        for (let i = 0; i < this.timeslots[key].length; i++) {
                            this.timeslots[key][i]['Attendees'] = [];
                            if (this.timeslots[key][i].FormDataID > 0 && this.timeslots[key][i].FormParams) {
                                this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].FormParams.FirstName + ' ' + this.timeslots[key][i].FormParams.LastName);
                                this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].FormParams.Email) ;
                                if (this.timeslots[key][i].FormParams.PhoneDaytime != this.timeslots[key][i].FormParams.PhoneEvening ) {
                                    this.timeslots[key][i]['Attendees'].push("Day: " + this.timeslots[key][i].FormParams.PhoneDaytime);
                                    this.timeslots[key][i]['Attendees'].push("Evening: " + this.timeslots[key][i].FormParams.PhoneEvening);
                                } else {
                                    this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].FormParams.PhoneDaytime);
                                }
                            } else if (this.timeslots[key][i].data.Attendees) {
                                for (let j = 0; j < this.timeslots[key][i].data.Attendees.length; j++) {
                                    if (this.timeslots[key][i].data.Attendees[j].FirstName) {
                                        this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].data.Attendees[j].FirstName + ' ' + this.timeslots[key][i].data.Attendees[j].LastName);
                                    }
                                }
                            }
                            if (this.timeslots[key][i].Description) {
                                this.timeslots[key][i].TitleDescription = [this.timeslots[key][i].Title, this.timeslots[key][i].Description];
                            } else {
                                this.timeslots[key][i].TitleDescription = this.timeslots[key][i].Title;
                            }
                        }
                    }
                    this.showPrintModal = true;
                }
            },
            exportCSV (index) {

                let list = this.export;
                let columns = ['GameDate', 'StartTime', 'Duration', 'MainLocation', 'Location', 'Details', 'Attendees'];

                let csv = Papa.unparse(list, {columns: columns});

                let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                let csvURL =  null;
                if (navigator.msSaveBlob)
                {
                    csvURL = navigator.msSaveBlob(csvData, 'games.csv');
                }
                else
                {
                    csvURL = window.URL.createObjectURL(csvData);
                }

                let tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', 'slots.csv');
                tempLink.click();
            },

            displayPopup (message) {
                this.$alert(message, {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
            }

        },
        mounted () {
            this.getInitialData();
        },

    }
</script>
<style>
    #eventattendance .el-table th {
        font-weight: 400;
    }
    .eventattendance div.el-table .cell {
        word-break: normal;
    }

    #eventattendance .el-table__footer div.cell {
        font-size: 12px;
        font-weight: 800;
        color: #000;
    }
</style>
<style scoped>

    #eventattendance .heading {
        background-color: #1A719C;
        color: #fff;
        font-size: 14px;
    }
    #eventattendance .body {
        color: #333;
        font-size: 14px;
        padding: 2px;
    }
    #eventattendance .body input, #eventattendance .body select, #eventattendance .body textarea {
        color: #333;
        font-size: 14px;
    }
    #eventattendance .body select {
        padding: 1px;
    }

    #eventattendance .body.row:nth-child(even){
        background-color: #dcdcdc;
    }
    #eventattendance .body.row:nth-child(odd){
        background-color: #ffffff;
    }
    #eventattendance .row.buttons {
        margin-top: 20px;
    }
    #eventattendance .el-range-editor--mini.el-input__inner {
        width: 100%;
    }
    @media print {
        .TablePrint {
            margin-left: 40px;
        }
    }
    .description {
        margin-left: 5px;
        font-size: 10px;
        line-height: 12px;
    }
</style>
