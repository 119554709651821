<template>
	<div id="invoicepayments" v-loading="loading">
		<div class="row">
			<div class="col-12 fullpage">
				<FinanceDropdowns @dropdownsChange="dropdownsChange" :config="config" :parentrefs="parentrefs" :calling="'InvoicePayments'" :refreshkey="dropdownKey" :updates="{LeagueID : this.InvoicePayment.LeagueID, SeasonID : this.InvoicePayment.SeasonID , InvoiceSettingID : this.InvoicePayment.InvoiceSettingID}" />
				<div class="mt-2">
                    <el-input
                        class="search"
                        v-model="search"
                        clearable
                        placeholder="Type to search"/>
				<el-button v-if="createAccess" @click.prevent="newPayment()"><i class="fa-solid fa-circle-plus"></i> Add New</el-button></div>
				<div class="mt-1 mb-4" >
					<el-table
                            :data="invoicepayments.filter(data => !search
                                            || data.PaymentAmount == search
                                            || data.PaymentDate.toLowerCase().includes(search.toLowerCase())
                                            || data.Description.toLowerCase().includes(search.toLowerCase())
                                            || data.PaymentStatus.toLowerCase().includes(search.toLowerCase())
                                            )"
							stripe border
							size="small"
                            empty-text="No Payments"
							max-height="500"
							style="width: 100%">
						<el-table-column
								prop="PaymentDate"
								label="Date"
								min-width="100"
								sortable
								align="center">
						</el-table-column>
                        <el-table-column
                            v-if="!ProgramIDAccess"
                            prop="OrganizationName"
                            label="Organization"
                            sortable
                            min-width="150">
                        </el-table-column>
                        <el-table-column
                            v-if="Leagues.length > 0"
                            prop="LeagueName"
                            label="League"
                            sortable
                            min-width="150">
                        </el-table-column>
                        <el-table-column
                            v-if="Leagues.length > 0"
                            prop="TeamName"
                            label="Team"
                            sortable
                            min-width="150">
                        </el-table-column>
						<el-table-column
								prop="Description"
								label="Description"
                                sortable
								min-width="150">
						</el-table-column>
                        <el-table-column
                            v-if="!ProgramIDAccess"
                            prop="BillingName"
                            label="Account"
                            sortable
                            min-width="150">
                        </el-table-column>
                        <el-table-column
								prop="PaymentAmount"
								label="Amount"
								align="right"
								min-width="100">
							<template #default="scope">
								<div v-html="formatPrice(scope.row.PaymentAmount)"></div>
							</template>
						</el-table-column>
						<el-table-column
								prop="PaymentStatus"
								label="Status"
								align="right"
								min-width="100">
							<template #default="scope">
								<div v-html="(scope.row.PaymentStatus == 'A' ? 'Active' : (scope.row.PaymentStatus == 'B' ? 'Returned' : 'Voided'))"></div>
							</template>
						</el-table-column>
						<el-table-column
                                v-if="!ProgramIDAccess"
								prop="PaymentImage"
								label="Attachement"
								align="center"
								min-width="120">
							<template #default="scope">
								<span v-if="scope.row.Params && scope.row.Params.PaymentImage" >
									  <el-image
											  style="width: 100px; height: 100px"
											  fit="cover"
											  :src="scope.row.Params.PaymentImage"
											  :preview-src-list="[scope.row.Params.PaymentImage]"
											  :preview-teleported="true">
  									</el-image>
								</span>
								<span v-else >
									n/a
								</span>
							</template>
						</el-table-column>
						<el-table-column v-if="modifyAccess"
										 label=""
										 width="120"
										 align="center">
							<template #default="scope">
								<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editPayment(scope.$index, scope.row)">
									<div class="v-btn__content">
										<i class="far fa-2x fa-edit"></i>
									</div>
								</button>
								<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteCnfm(scope.$index, scope.row)">
									<div class="v-btn__content">
										<i class="far fa-2x fa-trash-alt"></i>
									</div>
								</button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<modal v-if="showModal" :showMask="(this.config.isMobile ? false : true)" @close="showModal = false">
			<template v-slot:header>
				<h5 class="modal-title">
					{{modalTitle}}
				</h5>
			</template>
			<template v-slot:body>
				<div class="mt-1">
					<label class="control-label">Organization:</label>
					<el-select  v-model="InvoicePayment.OrganizationID" style="width:350px;" :disabled="true" placeholder="Select" default-first-option filterable>
						<el-option v-for="type in Organizations"
								   :value="type.OrganizationID"
								   :key="type.OrganizationID"
								   :label="type.OrganizationName">
						</el-option>
					</el-select>
				</div>
				<div class="date mt-2">
					<label class="control-label" >Date:</label>
					<el-date-picker v-model="InvoicePayment.PaymentDate"  default-first-option filterable
									type="date"
									format="MMM D, YYYY"
									value-format="YYYY-MM-DD"
									placeholder="Date">
					</el-date-picker>
				</div>
                <div v-if="Leagues.length > 0" class="date mt-2">
                    <label class="control-label">League</label>
                    <el-select  v-model="InvoicePayment.LeagueID" style="width:350px;" placeholder="Select" default-first-option filterable clearable>
                        <el-option v-for="type in Leagues"
                                   :value="type.LeagueID"
                                   :key="type.LeagueID"
                                   :label="type.LeagueName">
                        </el-option>
                    </el-select>
                </div>
                <div class="date mt-2">
					<label class="control-label" >Billing Account:</label>
					<el-select  v-model="InvoicePayment.InvoiceSettingID" style="width:350px;" placeholder="Select" default-first-option filterable>
						<el-option v-for="type in InvoiceSettings"
								   :value="type.InvoiceSettingID"
								   :key="type.InvoiceSettingID"
								   :label="type.BillingName">
						</el-option>
					</el-select>
				</div>
                <div v-if="paymentSeasonManagement" class="date mt-2">
                    <label class="control-label" >Season:</label>
                    <el-select  v-model="InvoicePayment.SeasonID" style="width:350px;" placeholder="Select" default-first-option filterable>
                        <el-option v-for="type in Seasons"
                                   :value="type.SeasonID"
                                   :key="type.SeasonID"
                                   :label="type.SeasonName">
                        </el-option>
                    </el-select>
                </div>
                <div v-if="InvoicePayment.LeagueID && InvoicePayment.SeasonID && LeagueTeams[InvoicePayment.SeasonID] && LeagueTeams[InvoicePayment.SeasonID][InvoicePayment.LeagueID] && LeagueTeams[InvoicePayment.SeasonID][InvoicePayment.LeagueID].length > 0 && teamManagement" class="mt-2">
                    <label class="control-label">Team:</label>
                    <el-select  v-model="InvoicePayment.TeamID" style="width:350px;" placeholder="Select" default-first-option filterable clearable>
                        <el-option v-for="type in LeagueTeams[InvoicePayment.SeasonID][InvoicePayment.LeagueID]"
                                   :value="type.TeamID"
                                   :key="type.TeamID"
                                   :label="type.TeamName">
                        </el-option>
                    </el-select>
                </div>
                <div class="itemdetail mt-2 mb-2">
					<label class="control-label">Description:</label>
					<el-input   type="textarea"
								style="width:350px;"
								:autosize="{ minRows: 2, maxRows: 10}"
								class="description"
								select v-model="InvoicePayment.Description"
					>
					</el-input>
				</div>
				<div class="itemdetail mt-2 mb-2">
					<label class="control-label">Amount:</label>
					<el-input  type="number" class="smallCurrency" v-model="InvoicePayment.PaymentAmount">
						<template #prepend><span v-html="currencySign"></span></template>
					</el-input>
				</div>
				<div class="itemdetail mt-2 mb-2">
					<label class="control-label">Status:</label>
					<el-select  v-model="InvoicePayment.PaymentStatus" style="width:350px;" placeholder="Select" default-first-option filterable>
						<el-option v-for="type in PaymentStatus"
								   :value="type.ID"
								   :key="type.ID"
								   :label="type.Name">
						</el-option>
					</el-select>
				</div>
				<div class="itemdetail mt-2 mb-2">
					<label class="mb-1 control-label" for="PaymentImage">Payment Image</label>
					<form class="form-horizontal fade-in-effect" novalidate method="post" enctype="multipart/form-data">
						<div class="fileinput-button " style="text-align:center;">
							<div id="PaymentImage" v-bind:class="{ 'success_upload' : isSuccess, 'failed_upload' : isFailed }" class="logo" style="position:relative;" :style="PaymentImage">
								<div id="spinner" style="position:absolute;top:24px;left:24px;" v-if="isFileSaving1"><i class="fas fa-spinner fa-spin fa-2x"></i> </div>
								<input type="file" id="PaymentImageInput" name="PaymentImage" :disabled="isFileSaving1" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*" class="input-file">
								<div v-if="RemoveImage" class="deleteImage" @click.prevent="removeFile"><i class="fas fa-times-circle"></i></div>
							</div>
						</div>
					</form>
				</div>
			</template>
			<template v-slot:footer>
				<div class="row" v-if="(modifyAccess || createAccess)  && !ProgramIDAccess" >
					<div class="col-12 text-center">
						<button class="btn btn-success btn-icon" type="button" @click="saveInvoicePayment">
							<i class="fas fa-check"></i> <span> Save Payment</span>
						</button>
						<button class="btn btn-icon btn-secondary cancel" type="info"  @click="showModal = false;">
							<i class="fas fa-times"></i>  <span>Cancel</span>
						</button>
					</div>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Modal from './global/Modal.vue';
	import axios from 'axios';
    import Util from "../assets/js/util";
	import FinanceDropdowns from './global/FinanceDropdowns.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_FILESAVING1 = 4, STATUS_FILESAVING2 = 5;

	export default {
		name: 'InvoicePayments',
		components: {
			Modal,
			FinanceDropdowns
		},
	    data() {
	        return {
				loading : false,
                currentStatus : STATUS_INITIAL,
                modalTitle: 'Edit Payment',
                modalFooter: '',
                showModal: false,
                Organizations : [],
                OrganizationID : '',
                SeasonID : '',
                PaymentStatus : [{ID : 'A', Name : 'Active'},{ID : 'B', Name : 'Returned from Bank'},{ID : 'V', Name : 'Void'}],
                search : '',
                InvoiceSettings : [],
                Seasons : [],
                invoicepayments : [],
                InvoicePayment : {InvoicePaymentID : '', OrganizationID : '',  LeagueID : '', TeamID : '', Description : '', PaymentDate : '', SeasonID : '', InvoiceSettingID : '', PaymentStatus: '', PaymentAmount : 0, Params : {PaymentImage : ''}},
                InvoiceSettingID : '',
                SeasonIDs : [],
                Leagues: [],
                LeagueID : '',
                LeagueTeams : [],
				dropdownKey : 0,
	        }
	    },
	    props:["config", "parentrefs"],
	    computed: {
	        ...mapGetters({ currentUser: 'currentUser' }),
		    ...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
            isFileSaving1() {
                return this.currentStatus === STATUS_FILESAVING1;
            },
            isFileSaving2() {
                return this.currentStatus === STATUS_FILESAVING2;
            },
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        createAccess () {
	            return this.$createAccess();
	        },
		    ProgramIDAccess () {
			    return this.$ProgramIDAccess();
		    },
		    defaultLogo () {
			    return require('../images/default_upload.png')
		    },
		    PaymentImage() {
			    let img = 'backgroundImage: url(' + (this.InvoicePayment.Params.PaymentImage ? this.InvoicePayment.Params.PaymentImage : this.defaultLogo) + ')';
			    return img;
		    },
		    RemoveImage() {
			    if (this.InvoicePayment.Params.PaymentImage) {
				    return true;
			    } else {
				    return false;
			    }
		    },
            currencySign () {
                return Util.currencySign(this.config);
            },
            paymentSeasonManagement () {
                if (this.InvoicePayment.InvoiceSettingID) {
                    for (let i = 0; i <  this.InvoiceSettings.length; i++)
                    {
                        if (this.InvoiceSettings[i].InvoiceSettingID == this.InvoicePayment.InvoiceSettingID) {
                            if (this.InvoiceSettings[i].Params.BillingSeasons == 'o') {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    }
                }
                if (this.InvoicePayment.LeagueID) {
                    return true;
                }
                return false;
            },
            teamManagement () {
                if (this.Leagues && this.Leagues.length > 0) {
                    for (let i = 0; i < this.Leagues.length; i++) {
                        if (this.Leagues[i].LeagueID == this.InvoicePayment.LeagueID) {
                            if (this.Leagues[i].Params.LeagueBilling == 'p') {
                                return false;
                            }
                        }
                    }
                    return true;
                }
                return false;
            }
		},
		methods: {
			formatPrice(value) {
				if (value) {
					return Util.formatPrice(this.config, value, '0');
				} else {
					return '';
				}
			},
			newPayment () {
				this.InvoicePayment.InvoicePaymentID = '';
                this.InvoicePayment.OrganizationID = this.OrganizationID;
                this.InvoicePayment.LeagueID = this.LeagueID;
                this.InvoicePayment.TeamID = '';
				this.InvoicePayment.Description = '';
				this.InvoicePayment.PaymentDate = this.todaysDate();
				this.InvoicePayment.PaymentAmount = '';
				this.InvoicePayment.PaymentStatus = 'A';
				this.InvoicePayment.Params.PaymentImage = '';
				this.InvoicePayment.SeasonID = this.SeasonID;
				this.InvoicePayment.InvoiceSettingID = '';
				this.modalTitle = "Add Payment";
				this.showModal = true;
			},
			editPayment (idx, row) {
				this.InvoicePayment.InvoicePaymentID = row.InvoicePaymentID;
				this.InvoicePayment.OrganizationID = row.OrganizationID;
                this.InvoicePayment.LeagueID = row.LeagueID;
                this.InvoicePayment.TeamID = row.TeamID;
                this.InvoicePayment.SeasonID = row.SeasonID;
                this.InvoicePayment.InvoiceSettingID = row.InvoiceSettingID;
                this.InvoicePayment.Description = row.Description;
				this.InvoicePayment.PaymentDate = row.PaymentDate + " 00:00:00 GMT-400";
				this.InvoicePayment.PaymentAmount = row.PaymentAmount;
				this.InvoicePayment.PaymentStatus = row.PaymentStatus;
				this.InvoicePayment.Params.PaymentImage = row.Params.PaymentImage;
				this.modalTitle = "Edit Payment";
				this.showModal = true;
			},
			showPaymentImage (row) {
				console.log(row);
			},
			dropdownsChange (OrganizationID, LeagueID, InvoiceSettingID, SeasonID, TeamID) {
				this.OrganizationID = OrganizationID;
                this.LeagueID = LeagueID;
				this.InvoiceSettingID = InvoiceSettingID;
				this.SeasonID = SeasonID;
				this.getPayments();
			},
			getInitialData () {
				var tables = ['Organizations','Seasons', 'Leagues', 'InvoiceSettings'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
					}
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getPayments() {
				this.invoicepayments = [];
				var self = this;

                if (this.OrganizationID) {
					this.loading = true;
                    axios.post('/finance/getpayments', {
                            OrganizationID: this.OrganizationID,
                            SeasonID: this.SeasonID,
                            InvoiceSettingID: this.InvoiceSettingID,
                            ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : ''),
                            LeagueID: this.LeagueID
                        },
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.invoicepayments = (response.data.invoicepayments ? response.data.invoicepayments : []);
                                this.$stopProgressBar(this.parentrefs);
                            } else {
                                this.$failProgressBar(this.parentrefs);
                            }
							this.loading = false;
                        }).catch(response => {
							this.loading = false;
                        	this.$failProgressBar(this.parentrefs);
                    });
                }
			},
			saveInvoicePayment () {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				if (this.InvoicePayment.PaymentDate != '' && this.InvoicePayment.PaymentAmount != '' && (this.InvoicePayment.SeasonID != '' || !this.paymentSeasonManagement) && this.InvoicePayment.InvoiceSettingID != '')  {
					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
                    if (!this.paymentSeasonManagement) {
                        this.InvoicePayment.SeasonID = 0;
                    }
                    if (this.InvoicePayment.InvoiceSettingID == '') {
                        for (let j = 0; j < this.Leagues.length; j++) {
                            if (this.Leagues[j].LeagueID == this.InvoicePayment.LeagueID) {
                                this.InvoicePayment.InvoiceSettingID = this.Leagues[j].InvoiceSettingID;
                                break;
                            }
                        }
                    }
					this.loading = true;
					axios.post('/finance/saveinvoicepayment', { InvoicePayment: this.InvoicePayment }
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$stopProgressBar(this.parentrefs);

								this.$notify({
									title: 'Success',
									message: "Payment has been saved.",
									type: 'success',
									duration: 2000,
									showClose: false
								});
								this.dropdownKey++;
								this.showModal = false;
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup(response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
							this.loading = false;
						}).catch(response => {
							this.loading = false;
							this.currentStatus = STATUS_FAILED;
							this.$failProgressBar(this.parentrefs);
					});
				} else {
					this.$confirm('All Fields but Team are Required.', 'Error', {
						confirmButtonText: 'OK',
						type: 'error',
						showClose: false,
						showCancelButton: false
					});
				}

			},
			deleteCnfm (index,row) {
				if (row.Params.FormDataID) {
					this.$confirm('This Payment is linked to a Form Payment Submission and can not be deleted.', 'Error', {
						confirmButtonText: 'OK',
						type: 'error',
						showClose: false,
						showCancelButton: false
					});
				} else {
					this.$confirm('Are you sure you wish to delete this payment?', 'Confirmation', {
						confirmButtonText: 'Yes',
						cancelButtonText: 'No',
						type: 'warning',
						showClose: false,
						showCancelButton: true
					}).then(() => {
						this.deletePayment(index, row);
					}).catch(() => {
					});
				}
			},
			deletePayment(index, row) {
				this.loading = true;
				axios.post('/finance/deleteinvoicepayment', {'InvoicePaymentID': row.InvoicePaymentID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.refreshData();
							this.$notify({
								title: 'Success',
								message: "Payment has been Deleted",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
							this.$notify({
								title: 'Error',
								message: "Payment cannot be Deleted",
								type: 'error',
								duration: 2000,
								showClose: false
							});
						}
						this.getPayments();
					}).catch(response => {
					this.$notify({
						title: 'Error',
						message: "Payment cannot be Deleted",
						type: 'error',
						duration: 2000,
						showClose: false
					});
				});

			},
			displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
					type: 'error'
                });
            },
			uploadFile(formData) {
                if (this.currentStatus == STATUS_SAVING || this.currentStatus == STATUS_FILESAVING1) {
					return true;
				}
				// upload data to the server
				this.currentStatus = STATUS_FILESAVING1;
				this.$startProgressBar(this.parentrefs);
				this.loading = true;
				axios.post('/settings/uploadlogo', formData,
					{ headers: { 'Content-Type': 'multipart/form-data'} }
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_SUCCESS;
							this.InvoicePayment.Params['PaymentImage'] = response.data.url;
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
						document.getElementById("PaymentImageInput").value = "";
						this.loading = false;
					}).catch(response => {
						this.loading = false;
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
				});
			},
			filesChange(fieldName, fileList) {
                if (this.currentStatus == STATUS_SAVING || this.currentStatus == STATUS_FILESAVING1) {
					return true;
				}
				// handle file changes
				const formData = new FormData();

				if (!fileList.length) return;

				// append the files to FormData
				Array
					.from(Array(fileList.length).keys())
					.map(x => {
						formData.append(fieldName, fileList[x], fileList[x].name);
					});

				// save it
				this.uploadFile(formData);
			},
			removeFile() {
				this.InvoicePayment.Params['PaymentImage'] = '';
			},
            refreshData() {
                this.getInitialData();
            },
			todaysDate () {
				var now = new Date();
				var y = now.getFullYear();
				var m = now.getMonth() + 1;
				var d = now.getDate();
				var mm = m < 10 ? '0' + m : m;
				var dd = d < 10 ? '0' + d : d;
				return '' + y + '-' + mm + '-' + dd;
			},
		},
		mounted () {
			this.getInitialData();
		},
	}
</script>

<style scoped>
	label {
		width: 100px;
	}
	.itemdetail .description {
		width: 200px;
	}
	.itemdetail .el-input {
		width: 150px;
	}
	.date .el-input {
		width: 200px;
	}
	.el-select {
		width: 200px;
	}
	.el-input-number {
		width: 150px;
	}
	#PaymentImage {
		min-width: 50%;
		background: #FFFFFF left center no-repeat;
		background-size: contain;
		cursor: pointer;
		height: 100px;
	}
	#PaymentImage .image .success_upload {
		border: 4px solid #28a745;
	}
	#PaymentImage .image .failed_upload {
		border: 4px solid #dc3545;
	}
	.input-file {
		opacity: 0; /* invisible but it's there! */
		width: 100%;
		height: 100%;
		position: absolute;
		cursor: pointer;
		left:0;
	}
	.deleteImage {
		font-size: 14px;
		cursor:pointer;
		position: absolute;
		top: 0;
		background: #FFF;
		left: 0px;
		color: #666;
		padding: 0px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		line-height: 9px;
	}
    .search {
        float:right;
        width: 25%;
        margin-bottom: 10px;
    }
</style>
