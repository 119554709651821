<template>
	<div id="profile" v-loading="loading" >
		<div class="row">
			<div class="col-12 col-md-3" v-if="!isContactModal" >
				<!-- User Info Sidebar -->
				<div class="user-info-sidebar">
					<form class="form-horizontal fade-in-effect" novalidate method="post" enctype="multipart/form-data">
						<div class="fileinput-button avatarContact" style="text-align:center;">
							<div id="avatarContact" v-bind:class="{ 'success_upload' : isSuccess, 'failed_upload' : isFailed }" class="avatar img-circle img-responsive" style="position:relative;" :style="{ backgroundImage: 'url(' + (user.Params.avatar ? user.Params.avatar : default_avatar) + ')' }">
								<div id="avatarSpinner" style="position:absolute;top:24px;left:24px;" v-if="isSaving"><i class="fas fa-spinner fa-spin fa-2x"></i> </div>
								<input type="file" multiple id="avatarContactInput" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*" class="input-file">
							</div>
						</div>
					</form>
				</div>
			</div>

			<div class="col-12" :class="{'col-md-9' : !isContactModal}">
				<div class="row">
				<!-- User Contact form -->
				<!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
				<input style="display:none" type="text" name="fakeemailremembered"/>
				<input style="display:none" type="password" name="fakepasswordremembered"/>
				<input name="id" id="id" type="hidden"  v-model="user.id">
				<div class="col-12" :class="{'card' : !isContactModal}">
					<el-form ref="contactForm" label-position="top" :model="contact" :rules="contactrules" label-width="120px" >
						<div class="card-header bg-primary details" v-if="!isContactModal"><h5>Contact Information</h5></div>
						<div :class="{'card-body' : !isContactModal}" >
							<div class="row  associations">
								<div class="select-program">
                                    <div v-if="calling != 'SiteContacts'" class="roles-heading" :class="{'full' : !isContactModal}">
                                        <h5 v-if="isContactModal">Contact Types</h5><el-button v-if="isContactModal" @click.prevent="addRole()">Add Type</el-button>
                                    </div>
									<template v-if="calling != 'SiteContacts'">
										<template v-for="(role, index) in contact.Roles">
											<div class="card">
												<div :class="{'card-body' : !isContactModal}">
													<div :class="{'d-none' : !isContactModal && role.ContactTypeID == ''}">
					                                    <div v-if="!isContactModal || (role.ContactTypeName && calling != 'UniversalSearch' && ((isTeamAllowed(role.ContactTypeID) && calling != 'TeamContacts') || (!isTeamAllowed(role.ContactTypeID) && calling != 'ProgramContacts')))" >
					                                        Title: {{ role.ContactTypeName }}<br>
						                                    {{config.Params.programName}}: {{role.ProgramName}}
					                                    </div>
					                                    <div v-else>
															<div v-if="contact.Roles.length > 1 && isContactModal && ((isTeamAllowed(role.ContactTypeID) && calling == 'TeamContacts' && teamExists) || (!isTeamAllowed(role.ContactTypeID) && calling == 'ProgramContacts') || calling == 'UniversalSearch')" class="deleteRole" @click.prevent="removeRole(index)"><i class="fas fa-times-circle"></i></div>
		                                                    <el-form-item label="Contact Type:" :prop="'Roles.' + index + '.ContactTypeID'"
																		  :rules="[{ required: true, message: 'Contact Type is required', trigger: 'blur'}]"
															>
					                                            <el-select  v-model="role.ContactTypeID" placeholder="Select" @change="CalcAvailableLoginRole" default-first-option filterable>
					                                                <el-option
					                                                    v-for="item in contactRolesAllowed"
					                                                    :key="item.ContactTypeID"
					                                                    :label="item.ContactTypeName"
					                                                    :value="item.ContactTypeID">
					                                                </el-option>
					                                            </el-select>
					                                        </el-form-item>
						                                    <el-form-item :label="config.Params.programName + ':'" :prop="'Roles.' + index + '.ProgramID'"
						                                                  :rules="[{ required: true, message: config.Params.programName + ' is required', trigger: 'blur'}]">
							                                    <el-select  v-model="role.ProgramID" placeholder="Select"    default-first-option filterable>
								                                    <el-option
									                                    v-for="item in Programs"
									                                    :key="item.ProgramID"
									                                    :label="item.ProgramName"
									                                    :value="item.ProgramID">
								                                    </el-option>
							                                    </el-select>
						                                    </el-form-item>
					                                    </div>
													</div>
													<div v-if="isTeamAllowed(role.ContactTypeID) && (!role.TeamID || (role.TeamID && teamExists(role.TeamID))) "  :class="{'d-none' : !isContactModal && role.TeamID == '0' }">
														<div v-if="!isContactModal || (calling != 'TeamContacts' && calling != 'UniversalSearch')">
															Team: {{ role.TeamName }}
														</div>
														<div v-else>
															<el-form-item label="Team:" prop="TeamID">
																<el-select  v-model="role.TeamID" placeholder="Select" default-first-option filterable>
																	<template v-for="item in Teams">
																		<el-option
																				v-if="role.ProgramID == item.ProgramID"
																				:key="item.TeamID"
																				:label="item.SeasonName + ': ' +item.TeamName"
																				:value="item.TeamID">
																		</el-option>
																	</template>
																</el-select>
															</el-form-item>
														</div>
													</div>
													<div v-if="isLocationAllowed(role.ContactTypeID)" :class="{'d-none' : !isContactModal && role.MainLocationID == '0' }">
														<div  v-if="!isContactModal" style="font-weight: 800;">
															Site: {{ role.MainLocationName }}
														</div>
														<div v-else>
															<el-form-item label="Site:" prop="MainLocationID">
																<el-select  v-model="role.MainLocationID" placeholder="Select" default-first-option filterable>
																	<el-option
																			v-for="item in SiteLocations"
																			:key="item.MainLocationID"
																			:label="item.MainLocationName"
																			:value="item.MainLocationID">
																	</el-option>
																</el-select>
															</el-form-item>
														</div>
													</div>
												</div>
											</div>
										</template>
									</template>
									<div v-else-if="calling == 'SiteContacts'">
										<el-form-item label="Site(s)" prop="MainLocationIDs"
										              :rules="[{ required: true, message: 'One Site is required', trigger: 'blur'}]"
										>
											<el-select v-model="contact.MainLocationIDs" multiple auto-complete=on placeholder="Select" default-first-option filterable>
												<el-option
													v-for="item in SiteLocations"
													:key="item.MainLocationID"
													:label="item.MainLocationName"
													:value="item.MainLocationID">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
								</div>
							</div>
                            <template v-if="calling == 'SiteContacts' || (contact.Roles && contact.Roles.length > 0) || !isContactModal">
                                <div class="row">
                                    <el-form-item label="First Name" prop="FirstName"
                                        :rules="[{ required: true, message: 'First Name is required', trigger: 'blur'}]"
                                    >
                                        <el-input  v-model="contact.FirstName">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="Last Name" prop="LastName"
                                        :rules="[{ required: true, message: 'Last Name is required', trigger: 'blur'}]"
                                    >
                                        <el-input v-model="contact.LastName">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="Address Line 1" prop="AddressLine1">
                                        <el-input  v-model="contact.Params.AddressLine1">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="Address Line 2" prop="AddressLine2">
                                        <el-input  v-model="contact.Params.AddressLine2">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="City" prop="City">
                                        <el-input  v-model="contact.Params.City">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item :label="config.Params.state" prop="StateOrProvinceCodeID">
                                        <el-select  v-model="contact.Params.StateOrProvinceCodeID" placeholder="Select" @change="setCountry" default-first-option filterable clearable>
                                            <el-option-group
                                                    v-for="country in StateProvinceCodes"
                                                    :key="country.Country"
                                                    :label="country.Country">
                                                <el-option
                                                        v-for="state in country.States"
                                                        :key="state.StateProvinceCodeShortName"
                                                        :label="state.StateProvinceCodeName"
                                                        :value="state.StateProvinceCodeShortName">
                                                </el-option>
                                            </el-option-group>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item class="postalCode" :label="config.Params.zipcode" prop="Params.PostalCode">
                                        <el-input  v-model="contact.Params.PostalCode">
                                        </el-input>
                                    </el-form-item>
									<el-form-item v-if="calling == 'UniversalSearch'"  class="postalCode" label="Status" prop="recordstatus">
										<el-select v-model="contact.recordstatus" placeholder="Select" default-first-option filterable>
											<el-option
													key="A"
													label="Active"
													value="A">
											</el-option>
											<el-option
												key="I"
												label="Not Confirmed (Inactive)"
												value="I">
											</el-option>
										</el-select>
									</el-form-item> 
                                </div>
                                <div class="row" >
                                    <div class="col-8">
                                        <el-form-item label="Primary Email" prop="EmailAddress"
                                            :rules="[{ required: true, message: 'Primary Email is required', trigger: 'blur'},
                                                     { type: 'email',  message: 'Primary Email is invalid', trigger: 'blur'}]"
                                        >
                                            <el-input  v-model="contact.EmailAddress" @change="emailSync">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4 showpublic">
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.show_EmailAddress" >Show Public</el-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <el-form-item label="Alternate Email" prop="EmailAddress2"
                                            :rules="[{ type: 'email',  message: 'Alternate Email is invalid', trigger: 'blur'}]"
                                        >
                                            <el-input  v-model="contact.Params.EmailAddress2">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4 showpublic">
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.show_EmailAddress2" >Show Public</el-checkbox>
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-8">
                                        <el-form-item label="Home Phone" prop="Params.HomePhoneNumber">
                                            <div class="el-form-item__content">
                                                <div class="el-input el-input--default el-input__wrapper">
                                                    <input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="contact.Params.HomePhoneNumber" />
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4 showpublic">
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.show_HomePhoneNumber" >Show Public</el-checkbox>
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-8">
                                        <el-form-item label="Work Phone" prop="Params.WorkPhoneNumber">
                                            <div class="el-form-item__content">
                                                <div class="el-input el-input--default el-input__wrapper">
                                                    <input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="contact.Params.WorkPhoneNumber" />
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4 showpublic">
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.show_WorkPhoneNumber" >Show Public</el-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <el-form-item label="Mobile" prop="Params.CellPhoneNumber">
                                            <div class="el-form-item__content">
                                                <div class="el-input el-input--default el-input__wrapper">
                                                    <input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="contact.Params.CellPhoneNumber" />
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4 showpublic">
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.show_CellPhoneNumber" >Show Public</el-checkbox>
                                    </div>
                                </div>
                            </template>
							<div v-if="calling == 'UniversalSearch' || ((invoiceAccess || adminAccess) && !isContactModal)" class="row mt-4">
								<div class="col-12" style="padding: 0;">
									<div class="card mt-4" style="width: 100%;">
										<div class="card-header bg-primary" style="margin: -20px -38px 0px -38px;" ><h5>Communications</h5></div>
									</div>
									<span v-if="calling == 'UniversalSearch' || (invoiceAccess && !adminAccess)">
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.emailInvoicing">Receive Invoices by Email</el-checkbox>
									</span>
									<span v-if="adminAccess && !isContactModal">
                                        <strong>Team Registration Submissions</strong><br>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.emailTeamRegistration">Emails</el-checkbox>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.notificationTeamRegistration">Notifications</el-checkbox>
										<br><br>
										<strong>Contact Us Submissions</strong><br>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.emailContactUs">Emails</el-checkbox>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.notificationContactUs">Notifications</el-checkbox>
										<br><br>
										<strong>Game Cancellations</strong><br>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.emailGameCancellation">Emails</el-checkbox>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.reminderGameCancellation">Reminders</el-checkbox>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.notificationGameCancellation">Notifications</el-checkbox>
										<br><br>
										<strong>Game Assignments</strong><br>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.emailAssignGame">Emails</el-checkbox>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.notificationAssignGame">Notifications</el-checkbox>
										<br><br>
										<strong>Game Reporting</strong><br>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.reminderGameReporting">Reminder Email</el-checkbox>
										<br><br>
										<strong>Signed Online 3rd Party Contract</strong><br>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.notificationSignedContract">Notifications</el-checkbox>
										<br><br>
										<strong>Accepted/Declined Game Changes</strong><br>
                                        <el-checkbox true-label="y" false-label="n" v-model="contact.Params.emailGameChanges">Emails</el-checkbox>
										<el-checkbox true-label="y" false-label="n" v-model="contact.Params.notificationGameChanges">Notifications</el-checkbox>
										<br><br>

									</span>
								</div>
							</div>
						</div>
					</el-form>
				</div>

				<div class="card LoginDetails mt-4" style="width: 100%;">
					<div v-if="!isContactModal"  class="card-header bg-primary details"><h5>Login / Change Password</h5></div>
					<div v-else class="card-header bg-primary details"><h5>User Login Credentials</h5></div>
					<div class="card-body">
						<el-form ref="userForm" label-position="top" :model="user" :rules="userrules" label-width="120px" >
							<div class="mb-1">
								<el-form-item label="Primary Email" prop="EmailAddress">
									<el-input key="userEmail1" v-model="user.EmailAddress" :disabled="true">
									</el-input>
								</el-form-item>
							</div>
							<el-form-item v-if="isContactModal && contact.UserArea != 'p'" label="Login Role:" prop="LoginRoleID">
								<el-select key="selectRole1" v-model="user.LoginRoleID" placeholder="Select" :disabled="(isContactModal && canUpdateRole() ? false : true)" default-first-option filterable clearable>
									<el-option
											v-for="item in AvailableLoginRole"
											:key="item.LoginRoleID"
											:label="item.RoleName"
											:value="item.LoginRoleID">
									</el-option>
								</el-select>
							</el-form-item>
							<template v-if="isContactModal">
								<EmailTemplate calling="welcome" v-model="SystemText" BillingType="" :config="config" width="100%" />
								<div v-if="user.LoginRoleID && contact.UserArea != 'p'" class="newLoginText" >
									By Selecting a Welcome Email Template and saving this user, this user will be sent a welcome email to login.
								</div>
								<div v-else-if="contact.UserArea == 'p' && user.UserLoginID == 0 "  class="newLoginText">
									This User has not yet been set up with Access to Login.  By Selecting a Welcome Email Template and saving this user, a Login will be created and an Email sent to the Email Address above. Their login role will be determine by the Contact Type associated to the League(s) they login in as.
								</div>
								<div v-else-if="user.UserLoginID == 0"  class="newLoginText">
									This User has yet to set up with Access to Login.  Selecting a Login Role and Welcome Email Template and saving this user, a Login will be created and an Email sent to the Email Address above.
								</div>
								<div v-else class="newLoginText">
									This User can already login. To resend a Welcome Email, Select a Welcome Email template and save this user.
								</div>
							</template>
							<div v-if="!isContactModal">
								<el-form-item label="Current Password" prop="CurrPassword" >
									<el-input  type="password" v-model="user.CurrPassword"></el-input>
								</el-form-item>
								<el-form-item label="New Password" prop="NewPassword">
									<el-input  type="password" v-model="user.NewPassword"></el-input>
								</el-form-item>
								<el-form-item label="Confirm Password" prop="CnfmPassword">
									<el-input  type="password" v-model="user.CnfmPassword"></el-input>
								</el-form-item>
							</div>
						</el-form>
					</div>
					<div v-if="!isContactModal" class="card">
						<div class="card-body">{{ passwordMessage }}</div>
					</div>
				</div>
			</div>
			<div class="row mb-4" :class="{'modalFooter' : isContactModal}" >
                <div class="col-12 text-center">
                    <button class="btn btn-success btn-icon" type="button" @click="cnfmSaveProfile" >
                        <i class="fas fa-check"></i> <span> Save Changes</span>
                    </button>
                    <button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)" >
                        <i class="fas fa-times"></i>  <span>Cancel</span>
                    </button>
                </div>
            </div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	import EmailTemplate from './global/EmailTemplate.vue';

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
	export default {
		name: 'Profile',
		components: {
			EmailTemplate
        },
		data () {
			var validateCurrPassword = (rule, value, callback) => {
				if (typeof value === 'undefined' || value == '') {
					if (this.user.NewPassword || this.user.CnfmPassword) {
						callback(new Error('Current Password is Required'));
					}
				} else {
					callback();
				}
			};
			var validateNewPassword = (rule, value, callback) => {
				if (typeof value === 'undefined' || value == '') {
					if (this.user.CurrPassword || this.user.CnfmPassword) {
						callback(new Error('New Password is Required'));
					}
				} else {
					if (this.config.Params.passwordMinLength && value.length < this.config.Params.passwordMinLength) {
						callback(new Error('Minimum Password length is ' +this.config.Params.passwordMinLength));
					} else if (this.config.Params.passwordRequireNumber == 'y' && !value.match(/\d+/g)) {
						callback(new Error('Must contain at least one number'));
					} else if (this.config.Params.passwordRequireUpper == 'y' && !value.match(/[A-Z]+/g)) {
						callback(new Error('Must contain at least one Upper Case Letter'));
					} else if (this.user.CnfmPassword !== '') {
				        this.$refs.userForm.validateField('CnfmPassword');
					}
					callback();
				}
			};
			var validateCnfmPassword = (rule, value, callback) => {
				if (typeof value === 'undefined' || value == '') {
					if (this.user.CurrPassword || this.user.NewPassword) {
						callback(new Error('Confirm Password is Required'));
					}
				} else if (value !== this.user.NewPassword) {
					callback(new Error('New and Confirm Passwords must match'));
				} else {
					callback();
				}
			};
			var validatePhoneNumber = (rule, value, callback) => {
				if (value) {
					if (value.length != 14) {
						callback(new Error('Phone Number must be 10 numbers'));
					} else {
						callback();
					}
				} else {
					callback();
				}
			};
			var validateSiteLocations = (rule, value, callback) => {
				if (value) {
					if (value.length < 1) {
						callback(new Error('One Site must be Selected'));
					} else {
						callback();
					}
				} else {
					callback(new Error('One Site must be Selected'));
				}
			};
			var validatePostalCode = (rule, value, callback) => {
				if (value && this.Country) {
					if (this.Country == 'United States') {
						var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
						if (!isValidZip) {
							callback(new Error('Zip Code is invalid'));
						} else {
							callback();
						}
					} else if (this.Country == 'Canada') {
						var isValidZip = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value);
						if (!isValidZip) {
							callback(new Error('Postal Code is invalid'));
						} else {
							callback();
						}
					}
                } else {
                    callback();
                }
            };

			return {
				loading: true,
				uploadedFiles: [],
				uploadError: null,
                currentStatus : STATUS_INITIAL,
				uploadFieldName: 'photos',
				user : {UserLoginID : 0, EmailAddress: '', LoginRoleID: 0, NewPassword: '', CnfmPassword: '', Params : {}},
				contact : {"Params" : {}, "Roles" :[]},
				ContactTypes: [],
				LoginRole: [],
                roletree : [],
                usertree : [],
                AvailableLoginRole: [],
				Leagues: [],
				Programs: [],
				SiteLocations: [],
				Teams: [],
				OrigData: {},
				StateProvinceCodes : [],
				Country : '',
				CustomEmailsGrouping: [],
				SystemText: '',
				contactrules: {
                    'Params.HomePhoneNumber': [
                         { validator: validatePhoneNumber, trigger: 'blur' }
                    ],
                    'Params.WorkPhoneNumber': [
                         { validator: validatePhoneNumber, trigger: 'blur' }
                    ],
                    'Params.CellPhoneNumber': [
                         { validator: validatePhoneNumber, trigger: 'blur' }
                    ],
                    'Params.PostalCode': [
                        { validator: validatePostalCode, trigger: 'blur' }
                    ],
					'MainLocationIDs' : [
						{ validator: validateSiteLocations, trigger: 'blur' }
					],
                },
				userrules: {
                    CurrPassword: [
                        { validator: validateCurrPassword, trigger: 'blur' }
                    ],
                    NewPassword: [
                        { validator: validateNewPassword, trigger: 'blur' }
                    ],
                    CnfmPassword: [
                        { validator: validateCnfmPassword, trigger: 'blur' }
                    ]
                },
			}
		},
	    props: {
	        config : { type: Object },
	        contactData : {
				type: [Object,Array],
				required: false,
				default: () => {}
	        },
	        calling : { type: String,
	                    required: false,
	                    default: 'Profile'
	        },
		    ProgramIDAccess : { type: Boolean,
			    required: false,
			    default: false
		    },
		    MainLocationIDAccess : { type: Boolean,
			    required: false,
			    default: false
		    },
            TeamIDs : { type: [Object,Array],
                required: false,
                default: () => []
            },
            SeasonID : { type: [Number,String],
                required: false,
                default: ''
            }
	    },
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
            default_avatar () {
                return require('../images/default_avatar.png')
            },
            isContactModal () {
                return (this.calling && this.calling != 'Profile' ? true : false);
            },
            isProfile () {
                return (!this.calling || this.calling == 'Profile' ? true : false);
            },
            modalTitle () {
                return (this.isContactModal ? 'Contact Message' : 'Profile Message');
            },
            passwordMessage () {
				var msg = 'Passwords must be at least ' + this.config.Params.passwordMinLength + ' digits long. ';
				if (this.config.Params.passwordRequireUpper == 'y') {
					msg += 'Passwords must contain at least one UPPER case letter. ';
				}
				if (this.config.Params.passwordRequireNumber == 'y') {
					msg += 'Passwords must contain at least one Number. ';
				}
				return msg;
            },
			invoiceAccess() {
				return (this.$readAccess ? this.$readAccess('invoices') : false)
			},
			adminAccess() {
				return (this.currentUser.gid >= 500 ? true : false);
			},
			TeamIDAccess () {
				return this.$TeamIDAccess();
			},
			contactRolesAllowed() {
				let contacts = [];
				for (let i = 0; i < this.ContactTypes.length; i++) {
					if (this.calling == 'TeamContacts') {
                        if (this.ContactTypes[i].Team == 'y') {
                            contacts.push(this.ContactTypes[i]);
                        }
                    } else if (this.calling == 'ProgramContacts') {
                        if (this.ContactTypes[i].Team != 'y') {
                            contacts.push(this.ContactTypes[i]);
                        }
					} else {
						contacts.push(this.ContactTypes[i]);
					}
				}
				return contacts;
			},
		},
		methods: {
            CalcAvailableLoginRole () {
                if (this.isProfile) {
                    this.AvailableLoginRole = this.LoginRole;
                } else {
                    this.AvailableLoginRole = [];
                    for (let i = 0; i < this.LoginRole.length; i++) {
                        if (this.searchRoleTree(this.usertree, this.LoginRole[i].LoginRoleID)) {
                            //now the Contact Role(s) of this person must be on the Login Roles they are allowed
                            if (this.contact.Roles && this.contact.Roles.length > 0) {
                                for (let j = 0; j < this.contact.Roles.length; j++) {
                                    if (this.LoginRole[i].ContactTypeIDs.indexOf(this.contact.Roles[j].ContactTypeID) !== -1) {
                                        this.AvailableLoginRole.push(this.LoginRole[i]);
                                        break;
                                    }
                                }
                            } else {
                                if (this.LoginRole[i].ContactTypeIDs.length == 0 || (this.LoginRole[i].ContactTypeIDs.length == 1 && this.LoginRole[i].ContactTypeIDs[0] == '')) {
                                    this.AvailableLoginRole.push(this.LoginRole[i]);
                                }
                            }
                        }
                    }
                }
            },
            searchRoleTree ( tree, LoginRoleID ) {
                if (tree.id && tree.id == LoginRoleID) {
                    return true;
                }
                if (tree.children != null) {
                    var result = false;
                    for (let i=0; !result && i < tree.children.length; i++) {
                        result = this.searchRoleTree(tree.children[i], LoginRoleID);
                    }
                    return result;
                }
                return false;
            },
            findUserTree (tree, LoginRoleID) {
                if (Array.isArray(tree) ) {
                    var result = false;
                    for (let i = 0; !result && i < tree.length; i++) {
                        result = this.findUserTree(tree[i], LoginRoleID);
                    }
                    return result;
                } else {
                    if (tree.id && tree.id == LoginRoleID) {
                        return tree;
                    }
                    if (tree.children) {
                        var result = false;
                        for (let i = 0; !result && i < tree.children.length; i++) {
                            result = this.findUserTree(tree.children[i], LoginRoleID);
                        }
                        return result;
                    }
                }
                return false;
            },
            teamExists (teamid) {
                for (let i = 0; i < this.Teams.length; i++) {
                    if (this.Teams[i]['TeamID'] == teamid) {
                        return true;
                    }
                }
                return false;
            },
			setCountry(val) {
				if (val) {
					for (let i = 0; i < this.StateProvinceCodes.length; i++) {
						for (let j = 0; j < this.StateProvinceCodes[i].States.length; j++) {
							if (this.StateProvinceCodes[i].States[j].StateProvinceCodeShortName == val) {
								this.Country = this.StateProvinceCodes[i].States[j].Country;
								break;
							}
						}
					}
				} else {
					this.Country = '';
				}
			},
			canUpdateRole () {
				if (this.user.LoginRoleID) {
					for (let i = 0; i < this.LoginRole.length; i++) {
						if (this.user.LoginRoleID == this.LoginRole[i].LoginRoleID) {
							if (this.LoginRole[i].gid < this.currentUser.gid) {
								return true;
							}
						}
					}
					return false;
				} else {
					return true;
				}
			},
			isTeamAllowed (id) {
				for (let i = 0; i < this.ContactTypes.length; i++) {
					if (this.ContactTypes[i].ContactTypeID == id) {
						if (this.ContactTypes[i].Team == 'n') {
							this.ContactTypes[i].TeamID = 0;
							return false;
						} else {
							return true;
						}
					}
				}
				return false;
			},
			isLocationAllowed (id) {
				for (let i = 0; i < this.ContactTypes.length; i++) {
					if (this.ContactTypes[i].ContactTypeID == id && this.ContactTypes[i].Location == 'y') {
						return true;
					}
				}
				return false;
			},
			addRole() {
				if (!this.contact.hasOwnProperty('Roles')) {
					this.contact['Roles'] = [];
				}
				this.contact.Roles.unshift(
					{
						ContactID: this.contact.ContactID,
						ProgramID: this.contact.ProgramID,
						ContactTypeID: '',
						TeamID: '',
						MainLocationID: ''
					}
				);
			},
			removeRole(index) {
				this.contact.Roles.splice(index,1);
			},
			reset(showMessage) {
				if (this.isContactModal && showMessage) {
					this.$emit('refreshContactData');
					this.$emit('close');
				} else {
					// reset form to initial state
					this.currentStatus = STATUS_INITIAL;
					this.uploadedFiles = [];
					this.uploadError = null;
					if (showMessage) {
						this.getProfileData();
						this.$notify({
							title: 'Success',
							message: "Profile has been reset",
							type: 'success',
							duration: 1500,
							showClose: false
						});
	                 }
	            }
			},
			emailSync () {
				this.user.EmailAddress = this.contact.EmailAddress;
			},
			uploadAvatar(formData) {
				if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
				// upload data to the server
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/users/uploadavatar', formData,
					{ headers: { 'Content-Type': 'multipart/form-data'} }
					)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_SUCCESS;
							this.user.Params.avatar = response.data.url;
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
						document.getElementById("avatarContactInput").value = "";
					}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
				});
			},
			filesChange(fieldName, fileList) {
				// handle file changes
				const formData = new FormData();

				if (!fileList.length) return;

				// append the files to FormData
				Array
					.from(Array(fileList.length).keys())
					.map(x => {
						formData.append(fieldName, fileList[x], fileList[x].name);
					});

				// save it
				this.uploadAvatar(formData);
			},
			getInitialData () {
				var tables = ['ContactTypes','Leagues', 'Programs', 'SiteLocations', 'LoginRole', 'roletree', 'StateProvinceCodes'];
				axios.post('/index/tableData', {'tables': tables, calling : this.calling, SeasonID : this.SeasonID },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        this.CalcAvailableLoginRole();
						this.getProfileData();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getCustomEmails () {
				axios.post('/systemtext/getcustomtext', {CustomTextID : 'welcome', Status : 'A'}	)
						.then(response => {
							if (response.data.result == 'success') {
								if (response.data.CustomEmailsGrouping) {
									this.CustomEmailsGrouping = response.data.CustomEmailsGrouping;
								}
								this.$stopProgressBar(this.parentrefs);
							} else {
								this.$failProgressBar(this.parentrefs);
							}
						}).catch(response => {
					this.$failProgressBar(this.parentrefs);
				});
			},
			getTeams () {
				if (this.contact.ProgramID) {
					this.Teams = [];
					var tables = ['Teams'];
					axios.post('/index/tableData', {'tables': tables, 'TeamIDs' : (this.isContactModal ? this.TeamIDs : this.TeamIDAccess), SeasonID : this.SeasonID},
					)
	                .then(response => {
	                    if (response.data.result == 'success') {
	                        for (let value of tables) {
	                            this[value] = response.data[value];
	                        }
	                    } else {
	                        this.$failProgressBar(this.parentrefs);
	                    }
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
	                });
	            }
			},
			getProfileData () {
				if (this.isContactModal) {
					this.contact = JSON.parse(JSON.stringify(this.contactData));
                    this.user.UserLoginID = this.contact.UserLoginID;
                    this.user.LoginRoleID = this.contact.LoginRoleID;
                    this.usertree = this.findUserTree (this.roletree, this.currentUser.LoginRoleID);
                    this.CalcAvailableLoginRole();
					this.emailSync();
					this.setCountry(this.contact.Params.StateOrProvinceCodeID);
					this.getTeams();
					if (!this.contact.hasOwnProperty('Roles') || this.contact.Roles.length == 0) {
						this.addRole();
					}
                    this.loading = false;
				} else {
					axios.post('/users/get', {'UserLoginID': this.currentUser.UserLoginID },
					)
	                .then(response => {
	                    if (response.data.result == 'success') {
							this.user = response.data.user;
							this.contact = response.data.contact;
		                    this.setCountry(this.contact.Params.StateOrProvinceCodeID);
							this.$stopProgressBar(this.parentrefs);
							this.getTeams();
	                    } else {
	                        this.$failProgressBar(this.parentrefs);
	                    }
                        this.loading = false;
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
                        this.loading = false;
	                });
	            }
			},

			cnfmSaveProfile(){
				let self = this;
				this.$refs['contactForm'].validate((valid) => {
					if (valid) {
						if (this.calling == 'ProgramContacts' || this.calling == 'TeamContacts' || this.calling == 'UniversalSearch') {
							this.saveProfile();
						} else {
							if (!this.user.LoginRoleID) {
								this.$confirm('No Login role selected therefore no Email with login credentials will be sent to this user. Continue?', 'Warning', {
									confirmButtonText: 'OK',
									cancelButtonText: 'Cancel',
									type: 'warning'
								}).then(() => {
									if (self.$refs['userForm']) {
										self.$refs['userForm'].validate((valid) => {
											if (valid) {
												this.saveProfile();
											} else {
												setTimeout(() => {
													let el = this.$el.getElementsByClassName('is-error')[0];
													el.scrollIntoView();
												}, 250);
											}
										});
									}
								}).catch(() => {
								});
							} else {
								this.saveProfile();
							}
						}
					} else {
						setTimeout(() => {
							let el = this.$el.getElementsByClassName('is-error')[0];
							el.scrollIntoView();
						}, 250);
					}
				});
			},

			saveProfile () {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                if (this.isContactModal) {
                    var saveUrl = '/contacts/save';
                } else {
                    var saveUrl = '/users/save';
                }
                this.emailSync();
                axios.post(saveUrl, {UserLogin: this.user, Contact: this.contact, SystemText: this.SystemText}
                )
                    .then(response => {
	                    if (response.data.result == 'success') {
		                    this.currentStatus = STATUS_INITIAL;
		                    this.$stopProgressBar(this.parentrefs);
		                    this.$notify({
			                    title: 'Success',
			                    message: (this.isContactModal ? 'Contact' : 'Profile') + " has been saved",
			                    type: 'success',
			                    duration: 1500,
			                    showClose: false
		                    });
		                    if (this.isContactModal) {
			                    this.$emit('refreshContactData');
			                    this.$emit('close');
		                    }
	                    } else {
		                    this.currentStatus = STATUS_FAILED;
		                    this.displayPopup (response.data.message);
		                    this.$failProgressBar(this.parentrefs);
	                    }
                    }).catch(response => {
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                });
			},
	        displayPopup (message) {
                this.$alert(message, (this.isContactModal ? 'Contact' : 'Profile') + ' Error', {
                    confirmButtonText: 'OK',
                });
            },
		},
		mounted() {
			this.reset(false);
			this.getInitialData();
			this.getCustomEmails();
		},
	}
</script>


<style scoped>
	.avatar {
		width: 90px;
		height: 90px;
		background: #FFFFFF center center no-repeat;
		background-size: cover;
		cursor: pointer;
		border: 4px solid #d0d0d0;
	}
	.avatar.success_upload {
		border: 4px solid #28a745;
	}
	.avatar.failed_upload {
        border: 4px solid #dc3545;
    }
	.input-file {
		opacity: 0; /* invisible but it's there! */
		width: 100%;
		height: 90px;
		position: absolute;
		cursor: pointer;
		left:0;
	}
	#profile .card {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.card-header.details {
		margin: -20px -20px 20px -20px;
	}
	.badge {
		cursor: pointer;
		margin-left: 10px;
	}
	.modalFooter {
	    position: relative;
	    bottom: 0;
	    width: 100%;
	    margin: 10px 0px;
	}
	.newLoginText {
		font-size: 12px;
		color: #606266;
		margin: 7px 7px 15px;

	}
	.showpublic {
		margin-top: 20px;
	    font-size: 10px;
        color: #606266;
	}
	.roles-heading {
		margin: 20px 0px;
	}
    .roles-heading h5 {
        float:left;
        font-weight: 600;
        margin-right: 15px;
        font-size: 16px;
        line-height: 30px;
    }
    .roles-heading.full h5 {
        float:none;
    }
    .select-program {
		width: 100%;
		padding-right: 12px
    }
	#app #profile .el-select {
		width: 100%;
	}
	#app .el-form-item.postalCode {
		width: 50%;
	}
	#app #profile .el-form-item {
		margin-bottom: 10px;
	}
	#app #profile .el-form--label-top .el-form-item__label {
		font-size: 12px;
		line-height: 15px;
	}
	#app #profile .el-form-item__content {
		line-height: 20px;
	}
	#app #profile .el-form-item.is-error {
		margin-bottom: 16px;
	}
	#profile .col-8 {
		padding: 0;
	}
	.deleteRole {
		font-size: 14px;
		cursor:pointer;
		position: absolute;
		top: 4px;
		background: #FFF;
		right: 1px;
		color: #666;
		padding: 0px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		line-height: 9px;
	}
	div.row .el-form-item {
		padding-left: 0px;
	}
</style>
