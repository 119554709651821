import { dsMergeOptions, dsMergeValidate, dsValidate, dsDefaults, dsBind } from './functions';
import { default as Component } from './component';
import * as ComponentMap from './components'
import { createApp } from "vue";

export default {
	dsMergeOptions,
	dsMergeValidate,
	dsValidate,
	dsDefaults,
	dsBind,
	ComponentMap,
	Component,
	createApp,
  install(App, options)
  {
    // register all components globally
	for (var componentName in ComponentMap)
	{
		App.component( componentName, ComponentMap[ componentName ] );
	}
	// $dayspan is just another reactive component

	const $dayspan = options.data.dayspan;

	// allow directives to access $dayspan
	App.$dayspan = $dayspan;

	// allow components to access $dayspan
	App.config.globalProperties.$dayspan = $dayspan;

	// allow v-bind="{$scopedSlots}"
	App.config.globalProperties._b = dsBind(App.config.globalProperties._b);

	// Call initialization functions
    App.config.globalProperties.$dayspan.init();
  }

};
