<template>
	<div id="invoicecredits" v-loading="loading">
		<div class="row">
			<div class="col-12 fullpage">
                <FinanceDropdowns @dropdownsChange="dropdownsChange" :config="config" :parentrefs="parentrefs" :calling="'InvoiceCredits'" :refreshkey="dropdownKey" :updates="{LeagueID : this.InvoiceCredit.LeagueID, SeasonID : this.InvoiceCredit.SeasonID , InvoiceSettingID : this.InvoiceCredit.InvoiceSettingID}" />
				<div class="mt-2">
				<el-input
						class="search"
						v-model="search"
						clearable
						placeholder="Type to search"/>
					<el-button v-if="createAccess" style="float:left;" @click.prevent="newCredit()"><i class="fa-solid fa-circle-plus"></i> Add New</el-button></div>
				<div class="mt-1 mb-4" >
					<el-table
							:data="invoicecredits.filter(data => !search
												|| data.OrganizationName.toLowerCase().includes(search.toLowerCase())
												|| data.Description.toLowerCase().includes(search.toLowerCase())
												|| data.CreditStatus.toLowerCase().includes(search.toLowerCase())
												)"
							stripe border
							size="small"
                            empty-text="No Adjustments"
							max-height="500"
							style="width: 100%">
						<el-table-column
								prop="CreditDate"
								label="Date"
								min-width="100"
                                sortable
								align="center">
						</el-table-column>
                        <el-table-column
                            v-if="!ProgramIDAccess"
                            prop="OrganizationName"
                            label="Organization"
                            sortable
                            min-width="150">
                        </el-table-column>
                        <el-table-column
                            prop="LeagueName"
                            label="League"
                            sortable
                            min-width="150">
                        </el-table-column>
                        <el-table-column
                            prop="TeamName"
                            label="Team"
                            sortable
                            min-width="150">
                        </el-table-column>
                        <el-table-column
                            v-if="!ProgramIDAccess"
                            prop="BillingName"
                            label="Account"
                            sortable
                            min-width="150">
                        </el-table-column>
                        <el-table-column
								prop="Description"
								label="Description"
                                sortable
								min-width="150">
						</el-table-column>
						<el-table-column
                                v-if="!ProgramIDAccess"
								prop="CreditBalance"
								label="Opening Balance"
								align="center"
								width="80">
						</el-table-column>
						<el-table-column
								prop="CreditAmount"
								label="Amount"
								align="right"
								width="100">
                            <template #default="scope">
                                <span v-html="formatPrice(scope.row.CreditAmount)"></span>
                            </template>
						</el-table-column>
						<el-table-column
								prop="CreditStatus"
								label="Status"
								align="center"
								width="75">
						</el-table-column>
						<el-table-column v-if="modifyAccess"
										 label=""
										 width="120"
										 align="center">
							<template #default="scope">
								<span v-if="scope.row.InvoiceDetailID == 0" >
									<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editCredit(scope.$index, scope.row)">
										<div class="v-btn__content">
											<i class="far fa-2x fa-edit"></i>
										</div>
									</button>
									<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteCnfm(scope.$index, scope.row)">
										<div class="v-btn__content">
											<i class="far fa-2x fa-trash-alt"></i>
										</div>
									</button>
								</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<modal v-if="showModal" @close="showModal = false">
			<template v-slot:header>
				<h5 class="modal-title">
					{{modalTitle}}
				</h5>
			</template>
			<template v-slot:body>
				<div class="mt-1">
					<label class="control-label">Organization:</label>
					<el-select  v-model="InvoiceCredit.OrganizationID" style="width:350px;" :disabled="true" placeholder="Select" default-first-option filterable>
						<el-option v-for="type in Organizations"
								   :value="type.OrganizationID"
								   :key="type.OrganizationID"
								   :label="type.OrganizationName">
						</el-option>
					</el-select>
				</div>
				<div class="date mt-2">
					<label class="control-label" >Date:</label>
					<el-date-picker v-model="InvoiceCredit.CreditDate"  default-first-option filterable
									type="date"
									format="MMM D, YYYY"
									value-format="YYYY-MM-DD"
									placeholder="Date">
					</el-date-picker>
				</div>
                <div v-if="Leagues.length > 0" class="date mt-2">
                  <label class="control-label">League</label>
                  <el-select  v-model="InvoiceCredit.LeagueID" style="width:350px;" placeholder="Select" default-first-option filterable clearable>
                    <el-option v-for="type in Leagues"
                               :value="type.LeagueID"
                               :key="type.LeagueID"
                               :label="type.LeagueName">
                    </el-option>
                  </el-select>
                </div>
                <div class="date mt-2">
                    <label class="control-label">Billing Account:</label>
                    <el-select  v-model="InvoiceCredit.InvoiceSettingID" style="width:350px;" placeholder="Select" default-first-option filterable>
                        <el-option v-for="type in InvoiceSettings"
                                   :value="type.InvoiceSettingID"
                                   :key="type.InvoiceSettingID"
                                   :label="type.BillingName">
                        </el-option>
                    </el-select>
                </div>
                <div v-if="creditSeasonManagement" class="date mt-2">
                    <label class="control-label" >Season:</label>
                    <el-select  v-model="InvoiceCredit.SeasonID" style="width:350px;" placeholder="Select" default-first-option filterable>
                        <el-option v-for="type in Seasons"
                                   :value="type.SeasonID"
                                   :key="type.SeasonID"
                                   :label="type.SeasonName">
                        </el-option>
                    </el-select>
                </div>
                <div v-if="InvoiceCredit.LeagueID && InvoiceCredit.SeasonID && LeagueTeams[InvoiceCredit.SeasonID] && LeagueTeams[InvoiceCredit.SeasonID][InvoiceCredit.LeagueID] && LeagueTeams[InvoiceCredit.SeasonID][InvoiceCredit.LeagueID].length > 0 && teamManagement" class="mt-2">
                    <label class="control-label">Team:</label>
                    <el-select  v-model="InvoiceCredit.TeamID" style="width:350px;" placeholder="Select" default-first-option filterable clearable>
                        <el-option v-for="type in LeagueTeams[InvoiceCredit.SeasonID][InvoiceCredit.LeagueID]"
                                   :value="type.TeamID"
                                   :key="type.TeamID"
                                   :label="type.TeamName">
                        </el-option>
                    </el-select>
                </div>
                <div class="itemdetail mt-2 mb-2">
                    <label class="control-label">Description:</label>
                    <el-input   type="textarea"
                              style="width:350px;"
                              autosize
                              class="description" select v-model="InvoiceCredit.Description"
                    >
                    </el-input>
                </div>
				<div class="itemdetail mt-2 mb-2">
					<label class="control-label">Amount:</label>
					<el-input  type="number" class="smallCurrency" v-model="InvoiceCredit.CreditAmount">
						<template #prepend><span v-html="currencySign"></span></template>
					</el-input>
				</div>
				<div class="itemdetail mt-2 mb-2">
					<label class="control-label">Do you wish to include this on an Invoice?</label>
                    <el-radio-group v-model="InvoiceCredit.Params.IncludeOnInvoice" >
                        <el-radio-button selected label="y">Yes</el-radio-button>
                        <el-radio-button label="n">No</el-radio-button>
                    </el-radio-group>
				</div>
				<div class="itemdetail mt-2 mb-2">
					<label class="control-label">Is this used for Opening Balances?</label>
                    <el-radio-group v-model="InvoiceCredit.CreditBalance" >
                        <el-radio-button selected label="y">Yes</el-radio-button>
                        <el-radio-button label="n">No</el-radio-button>
                    </el-radio-group>
				</div>
			</template>
			<template v-slot:footer>
				<div class="row" v-if="(modifyAccess || createAccess)  && !ProgramIDAccess" >
					<div class="col-12 text-center">
						<button class="btn btn-success btn-icon" type="button" @click="saveInvoiceCredit">
							<i class="fas fa-check"></i> <span> Save Changes</span>
						</button>
						<button class="btn btn-icon btn-secondary cancel" type="button"  @click="showModal = false;">
							<i class="fas fa-times"></i>  <span>Cancel</span>
						</button>
					</div>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Modal from './global/Modal.vue';
	import axios from 'axios';
    import Util from "../assets/js/util";
    import FinanceDropdowns from './global/FinanceDropdowns.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'InvoiceCredits',
		components: {
			Modal,
			FinanceDropdowns
		},
	    data() {
	        return {
				loading : false,
                currentStatus : STATUS_INITIAL,
                modalTitle: 'Edit Adjustment',
                modalFooter: '',
                showModal: false,
                Organizations : [],
                OrganizationID : '',
                SeasonID : '',
                search : '',
                InvoiceSettings : [],
                Seasons : [],
                invoicecredits : [],
                InvoiceCredit : {InvoiceCreditID : '', OrganizationID : '', LeagueID : '', TeamID : '', Description : '',  SeasonID : '', InvoiceSettingID : '', CreditDate : '', CreditAmount : 0, CreditBalance : 'n'},
                InvoiceSettingID : '',
                Leagues: [],
                LeagueID : '',
                LeagueTeams : [],
                TeamID : '',
                dropdownKey : 0,
	        }
	    },
	    props:["config", "parentrefs"],
	    computed: {
	        ...mapGetters({ currentUser: 'currentUser' }),
		    ...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        createAccess () {
	            return this.$createAccess();
	        },
		    ProgramIDAccess () {
			    return this.$ProgramIDAccess();
		    },
            currencySign () {
                return Util.currencySign(this.config);
            },
            creditSeasonManagement () {
                if (this.InvoiceCredit.InvoiceSettingID) {
                    for (let i = 0; i <  this.InvoiceSettings.length; i++)
                    {
                        if (this.InvoiceSettings[i].InvoiceSettingID == this.InvoiceCredit.InvoiceSettingID) {
                            if (this.InvoiceSettings[i].Params.BillingSeasons == 'o') {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    }
                }
                if (this.InvoiceCredit.LeagueID) {
                    return true;
                }
                return false;
            },
            teamManagement () {
                if (this.Leagues && this.Leagues.length > 0) {
                    for (let i = 0; i < this.Leagues.length; i++) {
                        if (this.Leagues[i].LeagueID == this.InvoiceCredit.LeagueID) {
                            if (this.Leagues[i].Params.LeagueBilling == 'p') {
                                return false;
                            }
                        }
                    }
                    return true;
                }
                return false;
            }
		},
		methods: {
			formatPrice(value) {
				if (value) {
					return Util.formatPrice(this.config, value, '0');
				} else {
					return '';
				}
			},
            filterStatus(value, row) {
                return row.CreditStatus === value;
            },
            newCredit () {
				this.InvoiceCredit.InvoiceCreditID = '';
                this.InvoiceCredit.OrganizationID = this.OrganizationID;
                this.InvoiceCredit.LeagueID = this.LeagueID;
                this.InvoiceCredit.TeamID = '';
                this.InvoiceCredit.Description = '';
				this.InvoiceCredit.CreditDate = this.todaysDate();
				this.InvoiceCredit.CreditAmount = '';
                this.InvoiceCredit.InvoiceSettingID = '';
                this.InvoiceCredit.SeasonID = (this.SeasonID ? this.SeasonID: '');
				this.InvoiceCredit.Params = {IncludeOnInvoice:'n'};
                this.modalTitle = "Add Adjustment";
				this.showModal = true;
			},
			editCredit (idx, row) {
				this.InvoiceCredit.InvoiceCreditID = row.InvoiceCreditID;
				this.InvoiceCredit.OrganizationID = row.OrganizationID;
                this.InvoiceCredit.LeagueID = row.LeagueID;
                this.InvoiceCredit.TeamID = row.TeamID;
				this.InvoiceCredit.Description = row.Description;
				this.InvoiceCredit.CreditDate = row.CreditDate + " 00:00:00 GMT-400";
				this.InvoiceCredit.CreditAmount = row.CreditAmount;
				this.InvoiceCredit.CreditBalance = row.CreditBalance;
                this.InvoiceCredit.InvoiceSettingID = row.InvoiceSettingID;
                this.InvoiceCredit.SeasonID = row.SeasonID;
				this.InvoiceCredit.Params = row.Params;
                this.modalTitle = "Edit Adjustment";
				this.showModal = true;
			},
            dropdownsChange (OrganizationID, LeagueID, InvoiceSettingID, SeasonID, TeamID) {
				this.OrganizationID = OrganizationID;
                this.LeagueID = LeagueID;
				this.InvoiceSettingID = InvoiceSettingID;
				this.SeasonID = SeasonID;
				this.getCredits();
			},
			getInitialData () {
				var tables = ['Organizations','Seasons', 'Leagues', 'InvoiceSettings'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                    }
	                this.user = { ...this.currentUser };
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getCredits() {
				this.invoicecredits = [];
				var self = this;
				if (this.LeagueID) {
                    this.InvoiceSettingID = '';
                }
				if (this.OrganizationID) {
					this.loading = true;
                    axios.post('/finance/getinvoicecredits', {
                            OrganizationID: this.OrganizationID,
                            SeasonID: this.SeasonID,
                            InvoiceSettingID: this.InvoiceSettingID,
                            LeagueID: this.LeagueID,
                            ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : '')
                        },
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.invoicecredits = (response.data.invoicecredits ? response.data.invoicecredits : []);
                                this.$stopProgressBar(this.parentrefs);
                            } else {
                                this.$failProgressBar(this.parentrefs);
                            }
							this.loading = false;
                        }).catch(response => {
							this.loading = false;
                        this.$failProgressBar(this.parentrefs);
                    });
                }
			},

			saveInvoiceCredit () {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				if (!this.creditSeasonManagement) {
                    this.InvoiceCredit.SeasonID = 0;
                }

                if (this.InvoiceCredit.CreditDate != '' && this.InvoiceCredit.CreditDescription != '' && this.InvoiceCredit.CreditAmount != ''  && (this.InvoiceCredit.SeasonID != '' || !this.creditSeasonManagement) && this.InvoiceCredit.InvoiceSettingID != '')  {
					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
                    if (this.InvoiceCredit.InvoiceSettingID == '') {
                        for (let j = 0; j < this.Leagues.length; j++) {
                            if (this.Leagues[j].LeagueID == this.InvoiceCredit.LeagueID) {
                                this.InvoiceCredit.InvoiceSettingID = this.Leagues[j].InvoiceSettingID;
                                break;
                            }
                        }
                    }
					this.loading = true;
					axios.post('/finance/saveinvoicecredit', { InvoiceCredit: this.InvoiceCredit }
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$stopProgressBar(this.parentrefs);

								this.$notify({
									title: 'Success',
									message: "Adjustment has been saved.",
									type: 'success',
									duration: 2000,
									showClose: false
								});
                                this.dropdownKey++;
								this.showModal = false;
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup(response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
							this.loading = false;
						}).catch(response => {
							this.loading = false;
							this.currentStatus = STATUS_FAILED;
							this.$failProgressBar(this.parentrefs);
					});
				} else {
					this.$confirm('All Fields but Team are Required.', 'Error', {
						confirmButtonText: 'OK',
						type: 'error',
						showClose: false,
						showCancelButton: false
					});
				}

			},
			deleteCnfm (index,row) {
				this.$confirm('Are you sure you wish to delete this Adjustment?', 'Confirmation', {
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					type: 'warning',
					showClose: false,
					showCancelButton: true
				}).then(() => {
					this.deleteCredit(index, row);
				}).catch(() => {
				});

			},
			deleteCredit(index, row) {
				this.loading = true;
				axios.post('/finance/deleteinvoicecredit', {'InvoiceCreditID': row.InvoiceCreditID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.refreshData();
							this.$notify({
								title: 'Success',
								message: "Adjustment has been Deleted",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
							this.$notify({
								title: 'Error',
								message: "Adjustment cannot be Deleted",
								type: 'error',
								duration: 2000,
								showClose: false
							});
						}
						this.getCredits();
					}).catch(response => {
					this.$notify({
						title: 'Error',
						message: "Adjustment cannot be Deleted",
						type: 'error',
						duration: 2000,
						showClose: false
					});
				});

			},

			displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
					type: 'error'
                });
            },
            refreshData() {
                this.getInitialData();
            },
            todaysDate () {
                var now = new Date();
                var y = now.getFullYear();
                var m = now.getMonth() + 1;
                var d = now.getDate();
                var mm = m < 10 ? '0' + m : m;
                var dd = d < 10 ? '0' + d : d;
                return '' + y + '-' + mm + '-' + dd;
            },
		},
		mounted () {
			this.getInitialData();
		},
	}
</script>
<style>
    #invoicecredits .el-table__column-filter-trigger i {
        color: #fff;
        font-weight: 800;
    }
    #invoicecredits .el-table th>.cell.highlight {
        color: #fff;
        font-weight: 800;
    }
</style>
<style scoped>
	.control-label {
		width: 100px;
	}
	.itemdetail .description {
		width: 200px;
	}
	.itemdetail .el-input {
		width: 150px;
	}
	.date .el-input {
		width: 200px;
	}
	.el-select {
		width: 200px;
	}
	.el-input-number {
		width: 150px;
	}
    .search {
        float:right;
        width: 25%;
        margin-bottom: 10px;
    }

</style>
