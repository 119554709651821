<template>
	<div id="gamereporting">
		<div class="row mt-2">
			<div class="col-12" style="height: 35px;">
				<label style="width: 65px;" class="mb-1 control-label" >{{config.Params.mainlocationName}}:</label>
				<el-select v-model="MainLocationID" ref="mainlocation" @change="getGameDates" placeholder="Select" class="selectCriteria" default-first-option filterable>
					<el-option v-for="type in SiteLocations"
							   :value="type.MainLocationID"
							   :key="type.MainLocationID"
							   :label="type.MainLocationName">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12" style="height: 35px;">
				<label style="width: 65px;" class="mb-1 control-label" for="GameDate">Dates:</label>
				<el-select v-model="GameDate" ref="gamedate" no-data-text="No Games" @change="getGames" placeholder="Select Date" class="selectCriteria" default-first-option filterable>
					<el-option v-for="type in GameDates"
							   :value="type.GameDate"
							   :key="type.GameDate"
							   :label="type.GameDateF">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="row mt-4">
			<table class="table" v-loading="loading">
				<thead>
					<tr>
						<th scope="col">Game No.</th>
						<th scope="col">Time<br>Div</th>
						<th class="teams" scope="col">Teams</th>
						<th scope="col">Score</th>
						<th scope="col" align="center">Function</th>
						<th scope="col" align="center">Web Notes</th>
					</tr>
				</thead>
				<tbody>
				<template v-for="(Game, index) in Games">
					<tr v-if="Game.LeagueParams.GameReporting == 'y'" :class="{'odd' : (index % 2 != 0),'even' : (index % 2 == 0)}">
						<td scope="row" rowspan="2">{{Game.SlotNumber}}</td>
						<td class="time" rowspan="2">{{Game.StartTime}}<br>{{Game.DivisionShortName}}</td>
						<td class="teams">{{Game.VisitingTeamName}}</td>
						<td rowspan="2" colspan="3" style="font-size: 16px; font-weight: 800;"><span v-html="HomeTeamReportsMessage"></span></td>
					</tr>
					<tr v-if="Game.LeagueParams.GameReporting == 'y'" :class="{'odd' : (index % 2 != 0),'even' : (index % 2 == 0)}">
						<td class="teams">{{Game.HomeTeamName}}</td>
					</tr>
					<tr v-if="Game.LeagueParams.GameReporting != 'y'" :class="{'odd' : (index % 2 != 0),'even' : (index % 2 == 0)}">
						<td scope="row" rowspan="2">{{Game.SlotNumber}}</td>
						<td class="time" rowspan="2">{{Game.StartTime}}<br>{{Game.DivisionShortName}}</td>
						<td class="teams">{{Game.VisitingTeamName}}</td>
						<td ><input v-if="Game.GameStatus != 'x'" type="number" min="0" @focus="$event.target.select()" name="VisitingScore"  @change="dataToSave()" class="form-control scores" v-model="Game.VisitingScore"  /></td>
						<td class="function">
							<el-select v-model="Game.GameStatus" v-if="Game.GameStatus != 'F'" placeholder="Select" @change="dataToSave()" default-first-option filterable>
								<el-option
										value="P"
										key="P"
										label="Pending">
								</el-option>
								<el-option
										value="C"
										key="C"
										label="Completed">
								</el-option>
								<el-option
										value="x"
										key="x"
										label="Not Played">
								</el-option>
							</el-select>
							<div v-else>Forfeit</div>
						</td>
						<td class="notes" rowspan="2"><textarea name="WebNotes" v-model="Game.WebNotes"  @change="dataToSave()" class="form-control webnotes"></textarea></td>
					</tr>
					<tr v-if="Game.LeagueParams.GameReporting !='y'" :class="{'odd' : (index % 2 != 0),'even' : (index % 2 == 0)}">
						<td class="teams">{{Game.HomeTeamName}}</td>
						<td><input v-if="Game.GameStatus != 'x'" type="number" min="0" @focus="$event.target.select()"  @change="dataToSave()" name="HomeScore" class="form-control scores" v-model="Game.HomeScore"  /></td>
						<td class="function checkboxes">
							<el-checkbox true-label="y" false-label = "n" v-model="Game.Params.Suspensions"  @change="dataToSave()">Suspensions?</el-checkbox><br>
							<el-checkbox true-label="1" false-label = "0" v-model="Game.Params.NoOnIceOfficials"  @change="dataToSave()">Single Official?</el-checkbox>
						</td>
					</tr>
				</template>
				</tbody>
			</table>
		</div>
		<div class="row buttons mb-4" v-if="hasGames">
            <div class="col-12 text-center">
                <button class="btn btn-success btn-icon" type="button" @click="saveGames">
                    <i class="fas fa-check"></i> <span> Submit Games</span>
                </button>
                <button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)">
                    <i class="fas fa-times"></i> <span>Cancel</span>
                </button>
            </div>
        </div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	import Modal from './global/Modal.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'GameReporting',
		components: {
			Modal
		},
	    data() {
	        return {
                currentStatus : STATUS_INITIAL,
		        loading : false,
		        SiteLocations : [],
                GameDates : [],
                Games : [],
                GameDate: '',
				PrevGameDate: '',
				MainLocationID : '',
				PrevMainLocationID : '',
		        HomeTeamReportsMessage : ''
	        }
	    },
		props:["config", "parentrefs"],
		beforeRouteLeave (to, from, next) {
			if (this.haveUnsavedData()) {
				let self = this;
				this.$confirm('You have made changes to Games on this screen and if you LEAVE you will lose them.', 'You have Unsaved Data.', {
					distinguishCancelAndClose: true,
					showClose: false,
					confirmButtonText: 'Stay',
					cancelButtonText: 'Leave',
					closeOnClickModal: false,
					closeOnPressEscape: false,
					type: 'warning'
				})
					.then(function () {
						next(false);
					})
					.catch(function () {
						self.$noDataToBeSaved();
						next();
					});
			} else {
				next();
			}
		},
	    computed: {
		    ...mapGetters({ componentIDs: 'componentIDs' }),
	        hasGames () {
	            return (this.Games.length > 0 ? true : false);
	        },
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
		    isMite () {
			    return (this.$route.fullPath.indexOf('mite') !== -1 ? true : false);
		    },
		    ProgramIDAccess () {
			    return this.$ProgramIDAccess();
		    },
		    TeamIDAccess () {
			    return this.$TeamIDAccess();
		    },
		},
		methods: {
			reset () {
				this.getGames();
			},
            haveUnsavedData() {
                return this.$haveUnsavedData();
            },
			getInitialData () {
				var tables = ['SiteLocations'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
	                        if (this.componentIDs.GRMainLocationID) {
		                        this.MainLocationID = this.componentIDs.GRMainLocationID;
	                        }
                            if (!this.MainLocationID) {
                            	this.MainLocationID = this[value][0]['MainLocationID'];
                            }
	                        this.getGameDates();
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			dataToSave() {
				this.$haveDataToBeSaved();
			},
			getGameDates() {
				if (this.haveUnsavedData()) {
					let self = this;
					this.$confirm('You have made changes to the Games on this screen which will be lost changing Sites.', 'You have Unsaved Data.', {
						distinguishCancelAndClose: true,
						showClose: false,
						confirmButtonText: 'Keep Changes',
						cancelButtonText: 'Get Games',
						closeOnClickModal: false,
						closeOnPressEscape: false,
						type: 'warning'
					})
						.then(function () {
							self.MainLocationID = self.PrevMainLocationID;
							self.$refs['mainlocation'].blur();
							return;
						})
						.catch(function () {
							self.$refs['mainlocation'].blur();
							self.getGameDatesCnfm ();
						});
				} else {
					this.getGameDatesCnfm ();
				}
			},
			getGameDatesCnfm() {
				this.$noDataToBeSaved();
				this.Games = [];
				this.GameDate = '';
				if (this.MainLocationID) {
					this.$store.dispatch('setID', {'GRMainLocationID': this.MainLocationID});
					this.PrevMainLocationID = this.MainLocationID;
					var tables = ['GameDates'];
					this.loading = true;
					axios.post('/gamereporting/getDates', {'MainLocationID': this.MainLocationID, 'Mites': (this.isMite ? 'y' : 'n'), TeamIDs : this.TeamIDAccess},
					)
						.then(response => {
							if (response.data.result == 'success') {
								for (let value of tables) {
									this[value] = response.data[value];
								}
								this.$stopProgressBar(this.parentrefs);
							} else {
								this.$failProgressBar(this.parentrefs);
							}
							this.loading = false;
						}).catch(response => {
							this.$failProgressBar(this.parentrefs);
							this.loading = false;
					});
				} else {
					this.GameDates = [];
				}
			},
			getGames() {
				if (this.haveUnsavedData()) {
					let self = this;
					this.$confirm('You have made changes to the Games on this screen which will be lost changing Dates.', 'You have Unsaved Data.', {
						distinguishCancelAndClose: true,
						showClose: false,
						confirmButtonText: 'Keep Changes',
						cancelButtonText: 'Get Games',
						closeOnClickModal: false,
						closeOnPressEscape: false,
						type: 'warning'
					})
						.then(function () {
							self.GameDate = self.PrevGameDate;
							self.$refs['gamedate'].blur();
							return;
						})
						.catch(function () {
							self.$refs['gamedate'].blur();
							self.getGameCnfm ();
						});
				} else {
					this.getGameCnfm ();
				}
			},

			getGameCnfm() {
				this.$noDataToBeSaved();
				if (this.MainLocationID && this.GameDate) {
					this.$store.dispatch('setID', {'GRGameDate': this.GameDate});
					this.PrevGameDate = this.GameDate;
					var tables = ['GameDates'];
					this.loading = true;
					axios.post('/gamereporting/getByDate', {
							'MainLocationID': this.MainLocationID,
							'GameDate': this.GameDate,
                            'Calling' : 'sitesheets',
							'Mites': (this.isMite ? 'y' : 'n'),
							'TeamIDs' : this.TeamIDAccess
						},
					)
						.then(response => {
							if (response.data.result == 'success') {
								for (let value of tables) {
									this.Games = response.data.Games;
								}
							} else {
								this.$failProgressBar(this.parentrefs);
							}
							this.HomeTeamReportsMessage = response.data.hometeamreports;
							this.loading = false;
						}).catch(response => {
						this.$failProgressBar(this.parentrefs);
						this.loading = false;
					});
				}
			},
			saveGames() {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				let self = this;
				this.loading = true;
				axios.post('/gamereporting/saveResults', {Games : this.Games}
				)
				.then(response => {
					if (response.data.result == 'success') {
						self.$noDataToBeSaved();
						this.currentStatus = STATUS_INITIAL;
						this.$notify({
							title: 'Success',
							message: (response.data.message ? response.data.message   : "Games have been saved"),
							type: 'success',
							duration: 1500,
							showClose: false
						});
                          this.$stopProgressBar(this.parentrefs);
					} else {
						this.currentStatus = STATUS_FAILED;
						this.displayModal (response.data.message);
						this.$failProgressBar(this.parentrefs);
					}
					this.loading = false;
				}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
					this.loading = false;
				});
			}
		},
		mounted () {
			this.getInitialData();
		}
	}
</script>


<style scoped>
#gamereporting .scores {
	width: 45px;
    text-align: center;
    padding: 1px;
    margin: 1px 0px;
    height: 30px;
}
#gamereporting .checkbox {
    display: inline-block;
    width: 25px;
    height: 15px;
}
#gamereporting label.checkbox {

}
#gamereporting .webnotes {
	width: 90%;
	height: 60px;
	padding: 2px;
}

#gamereporting  thead tr th:last-child {
	text-align: center;
}
#gamereporting .table {
	border-bottom: 2px solid #dee2e6;
}
#gamereporting table th {
	color: #fff;
	font-size: 16px;
}
#gamereporting .body {
	color: #333;
	font-size: 14px;
	padding: 2px;
}
#gamereporting td input, #gamereporting td select, #gamereporting td textarea {
	color: #333;
	font-size: 14px;
}
#gamereporting td select {
	padding: 1px;
	height: 30px;
}

#gamereporting tr.even {
	background-color: #f0f0f0;
}
#gamereporting tr.odd{
	background-color: #ffffff;
}
#gamereporting .row.buttons {
	margin-top: 20px;
}
#gamereporting td.function {
	min-width: 145px;
}
#gamereporting td.function.checkboxes {
	min-width: 145px;
	padding: 0;
}
#gamereporting td.function label {
	margin: 0;
	height: 24px;
}
#gamereporting td.time {
	min-width: 80px;
}
#gamereporting td.time {
	min-width: 80px;
}
#gamereporting td.notes {
	min-width: 150px;
}
#gamereporting tbody {
	font-size: 14px;
}
ul {
	margin-left: 20px;
	list-style-type: disc;
}
li {
	list-style: disc;
}
.border {
	border-bottom: 1px solid #dedede;
}
.danger {
	color: #dc3545;
}
.form-group label {
	width: 100%;
}
#app .el-select.selectCriteria {
	margin-left: 60px;
	position: absolute;
	width: 300px;
}
label {
	position: absolute;
	margin-top: 5px
}
#app #gamereporting label.el-checkbox {
		border: 0;
}
#app #gamereporting .table>tbody>tr>td, #app #gamereporting .table>tbody>tr>th, #app #gamereporting .table>thead>tr>td, #app #gamereporting .table>thead>tr>th {
    line-height: 25px;
    vertical-align: top;
}
</style>
