import { createApp } from "vue";
import App from './App'
import store from './store'
import router from './router'
import Maska from 'maska'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css';
import DaySpan from './components/calendar/plugin'
import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css'
import './assets/scss/vuetify.css'
import Print from 'vue3-print-nb';
import Vue3Signature from "vue3-signature";

import plugins from './assets/js/plugins.js';
import en from './components/calendar/locales/en';

//used to set common interceptors for all ajax requests and responses
import interceptorsSetup from './backend/helpers/interceptors';
import {default as Component} from "./components/calendar/component";
interceptorsSetup(router);

const $dayspan = createApp(Component);
const vm = $dayspan.mount('#dayspan');

createApp(App)
	.use(router)
	.use(store)
	.use(ElementPlus, { size: 'default', zIndex: 3000 })
	.use(Maska)
	.use(plugins)
	.use(Vue3Signature)
	.use(Print)
	.use(DaySpan, {data: { dayspan : vm,locales: { en } }, methods: { getDefaultEventColor() { return '#1976d2'; } } })
	.mount("#application");


