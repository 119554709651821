import VueJwtDecode from 'vue-jwt-decode';
import Util from '../assets/js/util.js';

export default class User {
    static from (token, Roles, RoleSecurity) {
        try {
            let obj = VueJwtDecode.decode(token);
                obj.Roles = JSON.parse(Roles);
                obj.RoleSecurity = JSON.parse(RoleSecurity);
            return new User(obj);
        } catch (_) {
            return null;
        }
    }

    constructor ({ UserLoginID, ContactID, CustomerID, FirstName, LastName, LoginRoleID, EmailAddress, Params, gid, RoleSecurity, RoleName, ProgramID, Roles, MainLocationIDs, LeagueID }) {
        this.UserLoginID = UserLoginID;
	    this.ContactID = ContactID;
	    this.CustomerID = CustomerID;
	    this.FirstName = FirstName;
	    this.LastName = LastName;
	    this.LoginRoleID = LoginRoleID;
	    this.EmailAddress = EmailAddress;
	    this.Params = (typeof Params === 'string' ? JSON.parse(Params) : Params);
        this.RoleSecurity = RoleSecurity;
        this.MainLocationIDs = MainLocationIDs;
	    this.gid = gid;
	    this.Roles = Roles;
	    this.RoleName = RoleName;
	    this.ProgramID = (ProgramID == 0 ? '' : ProgramID);
        this.LeagueID = (LeagueID == 0 ? '' : LeagueID);
        localStorage.CustomerID = CustomerID;
        Util.createCookie('CustomerID', CustomerID);
    }

}
