import store from '../../store';
export default {
	store,
    install(App, options) {

        App.config.globalProperties.$currentUser = store.getters.currentUser;
        App.config.globalProperties.$dataToBeSaved = {};
        App.config.globalProperties.$Plan = {};
        App.config.globalProperties.$topProgressHide = true;
        App.config.globalProperties.$topProgressColor = '#1989fa';

        App.config.globalProperties.$path = function () {
            let fullPath = this.$route.fullPath.substr(1).split('/');
            return fullPath[0];
        }
        App.config.globalProperties.$readAccess = function () {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if ((this.$currentUser.RoleSecurity[this.$path()].Params.Access && this.$currentUser.RoleSecurity[this.$path()].Params.Access == 'true') || this.$currentUser.RoleSecurity[this.$path()].Params.ReadAccess) {
                    return true;
                }
            }
            return false;
        }
        App.config.globalProperties.$createAccess = function () {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if (this.$currentUser.RoleSecurity[this.$path()].Params.CreateAccess && this.$currentUser.RoleSecurity[this.$path()].Params.CreateAccess) {
                    return true;
                }
            }
            return false;
        }
        App.config.globalProperties.$modifyAccess = function () {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if (this.$currentUser.RoleSecurity[this.$path()].Params.ModifyAccess && this.$currentUser.RoleSecurity[this.$path()].Params.ModifyAccess) {
                    return true;
                }
            }
            return false;
        }
        App.config.globalProperties.$ProgramIDAccess = function () {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if ((this.$currentUser.RoleSecurity[this.$path()].Params.ProgramIDAccess && this.$currentUser.RoleSecurity[this.$path()].Params.ProgramIDAccess)) {
                    return true;
                }
            }
            return false;
        }
        App.config.globalProperties.$MainLocationIDAccess = function () {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if (this.$currentUser.RoleSecurity[this.$path()].Params.MainLocationIDAccess && this.$currentUser.RoleSecurity[this.$path()].Params.MainLocationIDAccess) {
                    return true;
                }
            }
            return false;
        }
        App.config.globalProperties.$TeamIDAccess  = function() {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if (this.$currentUser.RoleSecurity[this.$path()].Params.TeamIDAccess) {
	                //return teams that has access
	                let teams = [0];
	                if (this.$currentUser.Roles[0].TeamIDs && this.$currentUser.Roles[0].TeamIDs.length > 0) {
						return this.$currentUser.Roles[0].TeamIDs;
	                } else {
		                return [0];
	                }
                }
            }
            return [];
        }
        App.config.globalProperties.$propertyAccess = function(propertyName) {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if (this.$currentUser.RoleSecurity[this.$path()].SecurityAreaProperties.indexOf(propertyName) !== -1 && this.$currentUser.RoleSecurity[this.$path()].Params.Properties && this.$currentUser.RoleSecurity[this.$path()].Params.Properties[propertyName]) {
                    return (this.$currentUser.RoleSecurity[this.$path()].Params.Properties[propertyName] == 'X' ? false : true);
                }
            }
            return true;
        }
        App.config.globalProperties.$propertyDisabled = function(propertyName) {
            if (this.$currentUser && this.$currentUser.RoleSecurity[this.$path()]) {
                if (this.$currentUser.RoleSecurity[this.$path()].SecurityAreaProperties.indexOf(propertyName) !== -1 && this.$currentUser.RoleSecurity[this.$path()].Params.Properties && this.$currentUser.RoleSecurity[this.$path()].Params.Properties[propertyName]) {
                    return (this.$currentUser.RoleSecurity[this.$path()].Params.Properties[propertyName] == 'D' ? true : false);
                }
            }
            return false;
        }
        App.config.globalProperties.$startProgressBar = function(parentrefs, trickleSpeed) {
            if (!parentrefs || !parentrefs.topProgress) {return true;}
            App.config.globalProperties.$topProgressColor = '#1989fa';
            App.config.globalProperties.$topProgressHide = false;
        }
        App.config.globalProperties.$stopProgressBar = function(parentrefs) {
            if (!parentrefs || !parentrefs.topProgress) {return true;}
            App.config.globalProperties.$topProgressHide = true;
        }
        App.config.globalProperties.$failProgressBar = function(parentrefs) {
            if (!parentrefs || !parentrefs.topProgress) {return true;}
            App.config.globalProperties.$topProgressColor = '#f56c6c';
        }
        App.config.globalProperties.$haveDataToBeSaved = function(tab) {
            if (tab) {
                App.config.globalProperties.$dataToBeSaved[tab] = 'y';
            } else {
                App.config.globalProperties.$dataToBeSaved['details'] = 'y';
            }
        }
        App.config.globalProperties.$noDataToBeSaved = function(tab) {
            if (tab) {
                App.config.globalProperties.$dataToBeSaved[tab] = '';
            } else {
                App.config.globalProperties.$dataToBeSaved = {};
            }
        }
        App.config.globalProperties.$haveUnsavedData = function(tab) {
            if (tab) {
                if (App.config.globalProperties.$dataToBeSaved[tab] == 'y') {
                    return true;
                } else {
                    return false;
                }
            } else {
                for (let property in App.config.globalProperties.$dataToBeSaved) {
                    if (App.config.globalProperties.$dataToBeSaved[property] == 'y') {
                        return true;
                        break;
                    }
                }
                return false;
            }
        }
        App.config.globalProperties.$setPlan = function(plan) {
            App.config.globalProperties.$Plan = plan;
        }
        App.config.globalProperties.$featureAllowed = function(feature) {
            if (App.config.globalProperties.$Plan && App.config.globalProperties.$Plan.PlanType == 'U') {
                return true;
            } else {
                if (App.config.globalProperties.$Plan && App.config.globalProperties.$Plan.Features && App.config.globalProperties.$Plan.Features.length > 0) {
                    for (let i = 0; i < App.config.globalProperties.$Plan.Features.length; i++) {
                        if (App.config.globalProperties.$Plan.Features[i].Params.FeatureCode == feature) {
                            if (App.config.globalProperties.$Plan.Features[i].Params.FieldType == 'yesno' && App.config.globalProperties.$Plan.Features[i].Params.Value == 'Yes') {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }
    }
}
