<template>
    <div id="features">
        <el-form ref="FeatureForm" label-position="top" :model="Feature" label-width="120px" >
            <div class="row mt-4">
                <div class="col-12 fullpage">
                    <el-form-item label="Feature:" prop="FeatureID">
                        <el-select key="selectfeature" v-model="FeatureID" placeholder="Select" @change="getFeature" class="topDropdown" default-first-option filterable>
                            <el-option v-if="$createAccess()" key="" label="-- Add New --" value=""></el-option>
                            <el-option
                                v-for="item in Features"
                                :key="item.FeatureID"
                                :label="item.FeatureName"
                                :value="item.FeatureID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 fullpage">
                    <el-form-item label="Feature Name:" prop="FeatureName"
                                  :rules="[{ required: true, message: 'Feature Name is Required', trigger: 'blur'}]"
                    >
                        <el-input v-model="Feature.FeatureName">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Feature Code:" prop="Params.FeatureCode"
                                  :rules="[{ required: true, message: 'Feature Code is Required', trigger: 'blur'}]"
                    >
                        <el-input v-model="Feature.Params.FeatureCode">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Security Area:" prop="SecurityAreaID">
                        <el-select v-model="Feature.SecurityAreaID" placeholder="Select" class="topDropdown" default-first-option filterable clearable>
                            <el-option
                                    v-for="item in SecurityAreas"
                                    :key="item.SecurityAreaID"
                                    :label="item.SecurityAreaName + (item.SecurityAreaFolder ? ' (' + item.SecurityAreaFolder + ')' : '')"
                                    :value="item.SecurityAreaID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Sort Order" prop="SortOrder">
                        <el-input v-model="Feature.SortOrder" class="smallNumber" >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Field Type" prop="Params.FieldType">
                        <el-select v-model="Feature.Params.FieldType">
                            <el-option value="text" label="Price"></el-option>
                            <el-option value="input" label="Input"></el-option>
                            <el-option value="select" label="Select"></el-option>
                            <el-option value="radio" label="Radio Select"></el-option>
                            <el-option value="multiple" label="Multi Select"></el-option>
                            <el-option value="multiplecheckbox" label="Multi Checkbox"></el-option>
                            <el-option value="yesno" label="Yes/No"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="Feature.Params.FieldType != 'input'" :label="(Feature.Params.FieldType == 'yesno' ? 'Field Conditions' : 'Field Values')" prop="Values">
                        <el-input type="textarea" :autosize="{ minRows:2, maxRows: 10}" v-model="Feature.Params.Values">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Payment Amount(s)" prop="PaymentAmounts">
                        <el-input type="textarea" :autosize="{ minRows:2, maxRows: 10}" v-model="Feature.Params.PaymentAmounts">
                        </el-input>
                    </el-form-item>
                    <div v-if="$modifyAccess() || $createAccess()" class="mt-4 mb-4" style="text-align: center;">
                        <button class="btn btn-success btn-icon" type="button" @click.prevent="saveFeature">
                            <i class="fas fa-check"></i> <span> Save Changes</span>
                        </button>
                        <button v-if="FeatureID && $modifyAccess()" type="button" class="btn btn-icon btn-danger delete"  @click="deleteCnfm">
                            <i class="far fa-trash-alt"></i>  <span>Delete</span>
                        </button>
                        <button class="btn btn-icon btn-secondary cancel" type="button" @click.prevent="getFeature">
                            <i class="fas fa-times"></i> <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    name: 'Features',
    data() {
        return {
            loading: false,
            currentStatus : STATUS_INITIAL,
            Feature : {Params : {FieldType : ''}, recordstatus : 'A'},
            Features : [],
            FeatureID : '',
            SecurityAreas : [],
        }
    },
    props:["config","Season", "parentrefs"],
    computed: {
        ...mapGetters({ componentIDs: 'componentIDs' }),
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
    },
    methods: {
        getInitialData () {
            var tables = ['SecurityAreas'];
            axios.post('/index/tableData', {tables: tables},
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (!this.FeatureID && this.Features.length > 0) {
                            this.FeatureID = this.Features[0]['FeatureID'];
                        }

                        this.getAllFeatures();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
            });
        },
        getAllFeatures() {
            this.loading = true;
            this.Features = [];
            let self = this;
            axios.post('/plans/getallfeatures', {
                },
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.Features = (response.data.Features ? response.data.Features : {});
                        this.$stopProgressBar(this.parentrefs);
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.loading = false;
                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
                this.loading = false;
            });

        },
        getFeature() {
            this.loading = true;
            let self = this;
            if (this.FeatureID) {
                axios.post('/plans/getfeature', {
                        FeatureID: this.FeatureID,
                        modifyAccess: this.$modifyAccess()
                    },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Feature = (response.data.Feature ? response.data.Feature : {});
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            } else {
	            this.Feature = {Params : {FieldType : ''}, recordstatus : 'A'};
            }
        },
        deleteCnfm (index,row) {
            this.$confirm('Are you sure you wish to delete this Feature?', 'Confirmation', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning',
                showClose: false,
                showCancelButton: true
            }).then(() => {
                this.deletFeature();
            }).catch(() => {
            });

        },
        deletFeature() {
            axios.post('/plans/deletefeature', {FeatureID: this.FeatureID}
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.$notify({
                            title: 'Success',
                            message: "Feature has been deleted",
                            type: 'success',
                            duration: 1500,
                            showClose: false
                        });
                        this.FeatureID = '';
                        this.Feature = {Params : {FieldType : ''}, recordstatus : 'A'};
                        this.getAllFeatures();
                    } else {
                        this.$notify({
                            title: 'Error',
                            message: "Feature cannot be deleted",
                            type: 'error',
                            duration: 2000,
                            showClose: false
                        });
                    }

                }).catch(response => {
                this.$notify({
                    title: 'Error',
                    message: "Feature cannot be deleted",
                    type: 'error',
                    duration: 2000,
                    showClose: false
                });
            });
        },
        saveFeature () {
            this.$refs['FeatureForm'].validate((valid) => {
                if (valid) {
                    if (!this.isDuplicate ()) {
                        if (this.currentStatus == STATUS_SAVING) {
                            return true;
                        }
                        this.currentStatus = STATUS_SAVING;
                        this.$startProgressBar(this.parentrefs);

                        axios.post('/plans/savefeature', {Feature: this.Feature}
                        )
                            .then(response => {
                                if (response.data.result == 'success') {
                                    this.currentStatus = STATUS_INITIAL;
                                    this.$stopProgressBar(this.parentrefs);
                                    this.FeatureID = response.data.FeatureID;
                                    this.getAllFeatures();
                                    this.$notify({
                                        title: 'Success',
                                        message: "Feature has been saved",
                                        type: 'success',
                                        duration: 1500,
                                        showClose: false
                                    });
                                } else {
                                    this.currentStatus = STATUS_FAILED;
                                    this.displayPopup (response.data.message);
                                    this.$failProgressBar(this.parentrefs);
                                }
                            }).catch(response => {
                                this.currentStatus = STATUS_FAILED;
                                this.$failProgressBar(this.parentrefs);
                        });
                    } else {
                        this.displayPopup ('A Feature with this Name Already Exists.');
                    }
                }
            });
        },
        isDuplicate () {
            for (let i = 0; i < this.Features.length; i++) {
                if (this.Features[i].FeatureID != this.Feature.FeatureID &&
                    (this.Features[i].FeatureName.toLowerCase() == this.Feature.FeatureName.toLowerCase().trim()) ){
                    return true;
                }
            }
            return false;
        },
        displayPopup (message) {
            this.$alert(message, 'Error', {
                confirmButtonText: 'OK',
            });
        }
    },
    mounted () {
        this.getInitialData();
    },

}
</script>

<style>

</style>
