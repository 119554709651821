<template>
    <!-- toggle and nav items -->
    <ul class="navbar-nav mr-auto mt-md-0">
        <button type="button" style="margin-top:8px;" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" :class="{'change': (isMobile ? !collapse : collapse)}" @click="handleSidebarToggle">
            <div class="v-btn__content">
	            <div class="barcontainer">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </div>
        </button>
        <!-- Messages -->
        <li
            class="nav-item dropdown mega-dropdown"
            :class="{'show': isMegaDropDown, '': !isMegaDropDown }"
            @click="handleMegaDropDown"
        >
            <a
                class="nav-link dropdown-toggle text-muted"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"><i class="fas fa-th-large"  style="font-size: 24px;"></i>&nbsp;<span v-if="!isMobile" style="font-weight: 800;">Contact Us</span>
            </a>
            <div
                class="dropdown-menu animated zoomIn"
                :class="{'show': isMegaDropDown, '': !isMegaDropDown }"
                @click="handleMegaDropDown"
            >
                <ul class="mega-dropdown-menu row">
					<ContactUs :caller="'topheader'"  @close="isMegaDropDown = !isMegaDropDown;"></ContactUs>
                </ul>
            </div>
        </li>
        <!-- End Messages -->
    </ul>
</template>

<script>
import axios from 'axios';
import Util from '../../assets/js/util.js';
import ContactUs from './ContactUs.vue';
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    export default{
        data() {
            return {
                clickable: true,
                isMegaDropDown: false,
                contactDetails: {},
				showContactUsForm : true,
                collapse : false
            }
        },
        props: {
			isCollapse: {
				type: Boolean,
				required: true,
				default: false
			},
        },
        computed: {
            isMobile() {
                return Util.isMobile();
            }
        },
        components: {
            ContactUs
        },
        watch: {
            isCollapse: {
                 handler(newVal, oldVal) {
                    this.collapse = newVal;
                }
            },
        },
        methods: {
            toggle(e){
                e.target.parentElement.classList.toggle('close')
            },
            handleSidebarToggle(){
                if(this.clickable ){
                    this.clickable = false;
                    document.body.classList.toggle('mini-sidebar');
                    this.collapse = document.body.classList.contains('mini-sidebar');
	                this.$emit('collapse',{isCollapse: this.collapse});
                    this.clickable = true;
                }
            },
            handleMegaDropDown (e) {
              this.isMegaDropDown = !this.isMegaDropDown;
            },
		    submit() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
				this.$refs['contactUsForm'].validate((valid) => {
					if (valid) {
						this.currentStatus = STATUS_SAVING;
						axios.post('/Index/ContactUs', {
							message: this.contactDetails
						}).then(response => {
							this.currentStatus = STATUS_INITIAL;
							if (response.data.result == 'success') {
								this.$notify({
									title: 'Success',
									message: "Message has been sent. Someone will contact you shortly.",
									type: 'success',
									duration: 3000,
									showClose: false
								});
				                this.isMegaDropDown = false;
				                this.contactDetails = {'ContactName': '', 'ContactEmailAddress': '', 'ContactMessage': ''};
			                } else {
								this.$notify({
									title: 'Error',
									message: (response.data.message ? response.data.message : 'Submission could not be processed'),
									type: 'error',
									duration: 2000,
									showClose: false
								});
			                }
						}).catch(response => {
							this.currentStatus = STATUS_FAILED;
						});
                    } else {
                        return false;
                    }
                });
		    },
        },
        mounted() {
            this.collapse = (this.isMobile ? true : false);
        }
    }
</script>
<style>
.form-control {
	width: 100%;
}
textarea.form-control {
	height: 100px;
}
.mega-dropdown-menu .el-form--label-top .el-form-item__label {
	margin:0;
	padding:0;
	line-height: 20px;
}
.barcontainer {
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 25px;
  height: 2px;
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.change .bar1 {
  transform: translate(0, 8px) rotate(-45deg);
}

/* Fade out the second bar */
.change .bar2 {opacity: 0;}

/* Rotate last bar */
.change .bar3 {
  transform: translate(0, -8px) rotate(45deg);
}
</style>
