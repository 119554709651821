<template>
	<div id="gamecancellationresponse">
		<el-form ref="gamecancellationFormDispute" :model="formdetails" :rules="rules" label-position="top" label-width="120px" >
			<div class="row">
				<div class="col-12 page-title bg-primary"><img class="small-logo" :src="config.Params.SmallLogo" alt="Logo"/>&nbsp;Game Cancellation Ice Usage Response</div>
			</div>
			<div class="row page-body">
				<div class="col-1"></div>
				<div class="col-10">
					<h5><div v-html="title"></div></h5>
					<div v-html="message"></div>
				</div>
				<div class="col-1"></div>
			</div>
			<div v-if="usage == 'dispute' && currentStatus != 2" class="row">
				<div class="col-1"></div>
				<div class="col-10">
					<strong>Contact Information</strong>
					<el-form-item label="First Name:" prop="FirstName"
								  :rules="[{ required: true, message: 'First Name is required', trigger: 'blur'}]"
					>
						<el-input v-model="formdetails.FirstName">
						</el-input>
					</el-form-item>
					<el-form-item label="Last Name:" prop="LastName"
								  :rules="[{ required: true, message: 'Last Name is required', trigger: 'blur'}]"
					>
						<el-input v-model="formdetails.LastName">
						</el-input>
					</el-form-item>
					<el-form-item label="Email Address:" prop="EmailAddress"
								  :rules="[{ required: true, message: 'Email Address is required', trigger: 'blur'},
								 { type: 'email',  message: 'Email Address is invalid', trigger: 'blur'}]"
					>
						<el-input v-model="formdetails.EmailAddress">
						</el-input>
					</el-form-item>
					<div class="el-form-item is-required" style="margin-bottom: 0px;">
						<el-form-item label="Phone Number:" prop="PhoneNumber">
							<div class="el-form-item__content">
								<div class="el-input el-input--default el-input__wrapper">
									<input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="formdetails.PhoneNumber" />
								</div>
							</div>
						</el-form-item>
					</div>
					<div class="mt-4">
						<el-form-item label="Reason for Dispute (please be as descriptive as possible):" prop="DisputeMessage"
									  :rules="[{ required: true, message: 'Reason for Dispute is required', trigger: 'blur'}]"
						>
							<el-input type="textarea" :autosize="{ minRows:2, maxRows: 10}" v-model="formdetails.DisputeMessage">
							</el-input>
						</el-form-item>
					</div>
					<div class="row buttons mt-4">
						<div class="col-12 text-center">
							<button class="btn btn-success btn-icon" type="button" @click="processResponse">
								<i class="fas fa-check"></i> <span> Submit</span>
							</button>
						</div>
					</div>
				</div>
				<div class="col-1"></div>
			</div>
			<div class="row">
				<div class="col-12 page-footer bg-primary">{{ pageFooter }}</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	import axios from 'axios';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'GameCancellationResponse',
	    data() {
		    var validatePhoneNumber = (rule, value, callback) => {
			    if (value) {
				    if (value.length != 14) {
					    callback(new Error('Phone Number must be 10 numbers'));
				    } else {
					    callback();
				    }
			    } else {
				    callback(new Error('Phone Number is Required'));
			    }
		    };
	        return {
	        	title : '',
	            message : '',
		        currentStatus : STATUS_INITIAL,
		        formdetails : {FirstName:'',LastName:'',EmailAddress:'',PhoneNumber:'',DisputeMessage:''},
		        rules: {
			        PhoneNumber: [
				        { validator: validatePhoneNumber, trigger: 'blur' }
			        ],
		        }
			}
	    },
		props:["config", "parentrefs"],
		computed: {
			pageFooter() {
				return '© ' + (new Date()).getFullYear() + ' ' + this.config.Params.SiteName;
			},
			usage() {
				return this.$route.query.usage;
			}
		},
		methods: {
			processResponse () {
				if (this.$route.query.ref && this.$route.query.usage) {
					this.$refs['gamecancellationFormDispute'].validate((valid) => {
						if (valid) {
							if (this.currentStatus == STATUS_SAVING) {
								return true;
							}
							this.currentStatus = STATUS_SAVING;
							this.$startProgressBar(this.parentrefs);
							var that = this;
							axios.post('/gamecancellation/updateresponse', {
									ref: this.$route.query.ref,
									usage: this.$route.query.usage,
									details: this.formdetails,
                                    email: this.$route.query.email
								}
							)
								.then(response => {
									if (response.data.result == 'success') {
										if (that.usage == 'no') {
											this.message = 'We have recorded that you will <strong>NOT</strong> be using the ice.';
										} else if (that.usage == 'yesref') {
											this.message = 'We have recorded that you <strong>WILL</strong> be using the Ice with Referees.';
										} else if (that.usage == 'yes') {
											this.message = 'We have recorded that you <strong>WILL</strong> be using the Ice but <strong>WITHOUT</strong> Referees.';
										} else if (that.usage == 'confirm') {
											this.message = 'For Confirming the Game Cancellation.<br><br>The league will attempt to reuse the ice at <strong>' + response.data.slot + '</strong>. This will mark ice open to use.';
										} else if (that.usage == 'dispute') {
											this.$notify({
												title: 'Success',
												message: "Your Dispute Details have successfully been submitted",
												type: 'success',
												duration: 2000,
												showClose: false
											});
											this.title = 'Thank You!';
											this.message = 'Your Dispute will be reviewed and a member of the Valley Administrative Team will be in touch shortly.';
										}
										this.$stopProgressBar(this.parentrefs);
										this.currentStatus = STATUS_SUCCESS;
									} else {
										this.$notify({
											title: 'Error',
											message: "Unfortunately, we were NOT able to accept your response.",
											type: 'error',
											duration: 4000,
											showClose: false
										});
										this.message = (response.data.message ? response.data.message : 'Unfortunately, we were NOT able to record your response.');
										this.title = 'Oh No!';
										this.currentStatus = STATUS_FAILED;
										this.$failProgressBar(this.parentrefs);
									}
								}).catch(response => {
									if (that.usage == 'dispute') {
										this.$notify({
											title: 'Error',
											message: "Unfortunately, we were NOT able to record your response.",
											type: 'error',
											duration: 2000,
											showClose: false
										});
									} else {
										this.message = 'Unfortunately, we were NOT able to record your response.';
									}
									this.title = 'Oh No!';
									this.currentStatus = STATUS_FAILED;
									this.$failProgressBar(this.parentrefs);
							});
						}
					});
				} else {
					this.message = 'Unfortunately, we were NOT able to record your response.';
				}
			},
		},
		mounted () {
			if (this.usage != 'dispute') {
				this.processResponse();
				this.title = 'Thank You!';
			} else {
				this.title = 'Game Cancellation Dispute Information';
				this.message = 'Please enter your contact information and reason for the Dispute.';
			}
		}
	}
</script>
<style>
	#app.gamecancellationresponse .page-wrapper {
		margin: 0px;
	}
</style>
<style scoped>
	.small-logo {
		height: 30px;
		margin-right: 15px;
	}

	.page-title {
		color: #FFF;
		font-size: 20px;
		font-weight: 800;
		height: 42px;
		line-height: 42px;
		position: fixed;
		z-index: 100;
		padding-left: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.page-body {
		margin: 60px 0px;
	}
	.page-footer {
		color: #FFF;
		font-size: 12px;
		font-weight: 600;
		height: 20px;
		line-height: 20px;
		position: fixed;
		bottom: 0;
		z-index: 100;
		text-align: center;
	}
	.el-form-item {
		max-width: 500px;
	}
</style>
