<template>
	<div id="formsubmissionreport" v-loading="loading">
		<div class="row">
			<div class="col-12 fullpage">
				<p>Select a form to display all submissions.</p>
                <div>
                    <el-button v-if="formSubmissions.length > 0" type="info" class="btn-secondary export mb-2"  @click="exportCSV">
                        <i class="fas fa-file-export"></i>  <span>Export CSV</span>
                    </el-button>
                    <el-radio-group class="mt-2" v-model="ActiveForms" @change="ActiveFormsChange">
                        <el-radio-button label="y">Active Forms</el-radio-button>
                        <el-radio-button label="n">Disabled Forms</el-radio-button>
                    </el-radio-group>
                </div>
                <el-cascader
                    class="mb-2"
                    style="width: 100%;"
                    :options="FormNames[ActiveForms]"
                    v-model="FolderFormDefinitionID"
                    :filter-method="filterCascader"
                    @change="getFormData()"
                    placeholder="Select Report"
                    :props="cascaderprops"
                    default-first-option filterable>
				</el-cascader>
                <el-date-picker
                    v-model="DateRange"
                    type="daterange"
                    unlink-panels
                    @change="getFormData()"
                    range-separator="To"
                    start-placeholder="From date"
                    end-placeholder="To date"
                    format="MMM D, YYYY"
                    value-format="YYYY-MM-DD">
                </el-date-picker>
				<el-input
						class="search"
						v-model="search"
						clearable
						placeholder="Type to search">
				</el-input>
			</div>
		</div>
		<div class="row mt-2 mb-4">
			<div class="col-12 table-area fullpage">
				<el-table v-if="formSubmissions.length > 0"
						  stripe
						  :data="searchedFormSubmissions"
							style="width: 100%"
						    size="small"
						  show-summary
						  :summary-method="getSummaries"
							max-height="600">
					<el-table-column
							prop="FormDataID"
							label="ID"
							width="75px"
							align="center">
					</el-table-column>
					<el-table-column
							label="Name"
							width="150px">
						<template #default="scope">
							<span v-html="getName(scope.row)"></span>
						</template>
					</el-table-column>
					<el-table-column
							v-for="(column,index) in columns"
							:label="column.replace(/[A-Z]/g, ' $&').trim()"
							:prop="column"
							:align="(column.indexOf('Amount') != -1 ? 'right' :'left')"
							sortable
							min-width="200px">
						<template #default="scope">
							<span v-if="column.indexOf('Amount') != -1" v-html="formatPrice(scope.row[column])"></span>
							<span v-else-if="column.toLowerCase().indexOf('email') != -1"> 
								<el-input type="text" size="default" v-model="scope.row[column]" @focus="updateFormEntry(scope.row.FormDataID,column);"></el-input>
								<a v-if="scope.row.operation == 'f'" href="#" class="saveicon" @click.prevent="saveFormSubmission(scope.row.FormDataID,column,scope.row[column] );"><i class="fa-2x fa-regular fa-floppy-disk"> </i>Save</a>
							</span>
							<span v-else v-html="scope.row[column]"></span>
						</template>
					</el-table-column>
					<el-table-column
                            v-if="modifyAccess"
							label="Remove"
							width="80px"
							align="center">
						<template #default="scope">
							<button v-if="scope.row.TestPayment == 'y' || scope.row.PaymentAmount == 0" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click.prevent="cnfmDeleteFormSubmission(scope.row.FormDataID)">
								<div class="v-btn__content">
									<i class="far fa-2x fa-trash-alt"></i>
								</div>
							</button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	import Util from "../assets/js/util";
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'FormSubmissions',
		data() {
			return {
				loading : false,
				currentStatus: STATUS_INITIAL,
				FormNames: [],
				FormDefinitionID: '',
				FolderFormDefinitionID: [],
				formSubmissions: [],
				search: '',
				ActiveForms: 'y',
				DateRange: [],
				cascaderprops: {expandTrigger: 'hover'},
				columns : [],
			}
		},
		props:["config", "parentrefs"],
		computed: {
			...mapGetters({componentIDs: 'componentIDs'}),
            modifyAccess () {
                return this.$modifyAccess();
            },
            createAccess () {
                return this.$createAccess();
            },
			searchedFormSubmissions() {
				if (this.search) {
					return this.formSubmissions.filter(o =>
						Object.keys(o).some(k => String(o[k]).toLowerCase().includes(this.search.toLowerCase())));
				} else {
					return this.formSubmissions;
				}
			},
		},
		methods: {
			refreshData() {
				this.$refs.notificationsTable.refresh();
			},
			updateFormEntry(FormDataID,column) {
				var idx = -1;
				for (let i=0; i < this.formSubmissions.length; i++) {
					if (this.formSubmissions[i].FormDataID == FormDataID) {
						idx = i;
						break;
					}
				}
				this.formSubmissions[idx].operation = 'f';
			},
            ActiveFormsChange () {
                this.FolderFormDefinitionID = [];
                this.formSubmissions = [],
                this.$store.dispatch('setID', {'FormDefinitionID': ''});
                this.$store.dispatch('setID', {'FolderFormDefinitionID': this.FolderFormDefinitionID});
            },
			filterCascader(node, keyword) {
				if (node.label.toLowerCase().indexOf(keyword.toLowerCase()) === -1) {
					return false;
				} else {
					return true;
				}
			},
			getSummaries(param) {

				const { columns, data } = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = 'Total';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (index === 1) {
						sums[index] = values.length;
						return;
					}
					if (!values.every(value => isNaN(value))) {
						if (column.property.indexOf('Amount') != -1) {
							sums[index] = this.formatPrice(values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0));
						} else {
							sums[index] = '';
						}
					} else {
						sums[index] = '';
					}
				});
				return sums;
			},
			getName(row) {
				if (row) {
					if (row.PlayerFirstName) {
						return row.PlayerFirstName + ' ' + row.PlayerLastName;
					} else if (row.Team) {
						return row.Team;
					} else if (row.TeamName) {
						return row.TeamName;
					} else if (row['Team Name']) {
						return row['Team Name'];
					} else if (row['Coaches Team']) {
						return row['Coaches Team'];
					} else if (row['CoachesTeamName']) {
						return row['CoachesTeamName'];
					} else if (row['Email']) {
						return row['Email'];
					}
				} else {
					return '';
				}
			},
			formatPrice(value) {
				return Util.formatPrice(this.config, value , '0');
			},
			getInitialData () {
				axios.post('/formdefinitions/getall', {createAccess : 'n'},
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.FormDefinitions = response.data.FormDefinitions;
							this.FormNames = response.data.FormNames;
							if (this.$route.params.id) {
								let parts = this.$route.params.id.split('-');
								this.ActiveForms = parts[0];
								this.FolderFormDefinitionID = [parts[1],parts[2]];
							} else if (this.componentIDs.FolderFormDefinitionID) {
								this.FolderFormDefinitionID = this.componentIDs.FolderFormDefinitionID;
							}
							this.$stopProgressBar(this.parentrefs);
							this.getFormData ();
						} else {
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
				});
			},
			getFormData () {
                this.FormDefinitionID = this.FolderFormDefinitionID[1];
			    if (this.FormDefinitionID) {
                    let self = this;
				    this.columns = [];
				    this.formSubmissions = [];
					this.loading = true;
                    axios.post('/formdata/getformsubmissions', {
                            FormDefinitionID: this.FormDefinitionID,
                            DateRange : this.DateRange
                        },
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                self.$store.dispatch('setID', {'FolderFormDefinitionID': self.FolderFormDefinitionID});
                                this.columns = response.data.columns;
                                this.formSubmissions = response.data.formSubmissions;
								if (this.formSubmissions.length == 200) {
									this.$alert('Please use the date filter to show more Entries (last ' + this.formSubmissions.length + ' displayed)', {
										title: 'Too Many Entries',
										type: 'warning',
										confirmButtonText: 'OK',
									});
								}
                                this.$stopProgressBar(this.parentrefs);
                            } else {
                                this.$failProgressBar(this.parentrefs);
                            }
							this.loading = false;
                        }).catch(response => {
							this.loading = false;
                        	this.$failProgressBar(this.parentrefs);
                    });
                }
			},
			saveFormSubmission (FormDataID, column, value) {
				axios.post('/formdata/save', {FormDataID : FormDataID, column : column, value : value },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let i=0; i < this.formSubmissions.length; i++) {
								if (this.formSubmissions[i].FormDataID == FormDataID) {
									this.formSubmissions[i].operation = '';
									break;
								}
							}
							this.$notify({
								title: 'Success',
								message: "Form Submission has been Saved",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
							this.$notify({
								title: 'Error',
								message: "Form Submission cannot be Saved",
								type: 'warning',
								duration: 2000,
								showClose: false
							});
						}
					}).catch(response => {
						this.$notify({
							title: 'Error',
							message: "Form Submission cannot be Saved",
							type: 'warning',
							duration: 2000,
							showClose: false
						});
				});
			},
			cnfmDeleteFormSubmission(FormDataID) {
				this.$confirm('This will delete this Form Submission. Continue?', 'Warning', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}).then(() => {
					this.deleteFormSubmission(FormDataID);
				}).catch(() => {
				});
			},
			deleteFormSubmission (FormDataID) {
				axios.post('/formdata/delete', {FormDataID : FormDataID },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let i=0; i < this.formSubmissions.length; i++) {
								if (this.formSubmissions[i].FormDataID == FormDataID) {
									this.formSubmissions.splice(i, 1);
									break;
								}
							}
							this.$notify({
								title: 'Success',
								message: "Form Submission has been Deleted",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
							this.$notify({
								title: 'Error',
								message: "Form Submission cannot be Deleted",
								type: 'warning',
								duration: 2000,
								showClose: false
							});
						}
					}).catch(response => {
						this.$notify({
							title: 'Error',
							message: "Form Submission cannot be Deleted",
							type: 'warning',
							duration: 2000,
							showClose: false
						});
				});
			},
			exportCSV () {

				var csv = Papa.unparse(this.formSubmissions,{columns : this.columns});
				var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
				var csvURL =  null;
				if (navigator.msSaveBlob)
				{
					csvURL = navigator.msSaveBlob(csvData, 'download.csv');
				}
				else
				{
					csvURL = window.URL.createObjectURL(csvData);
				}

				var tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'download.csv');
				tempLink.click();
			},
		},
		mounted () {
			this.getInitialData();
		}
	}
</script>
<style>
	#formsubmissionreport .el-table__footer div.cell {
		font-size: 12px;
		font-weight: 800;
		color: #000;
	}
</style>
<style scoped>

	#formsubmissionreport  thead tr th:last-child {
		text-align: center;
	}
	#formsubmissionreport .table {
		border-bottom: 2px solid #dee2e6;
	}
	#formsubmissionreport table th {
		color: #fff;
		font-size: 16px;
	}
	#formsubmissionreport .body {
		color: #333;
		font-size: 14px;
		padding: 2px;
	}
	#formsubmissionreport td input, #gamereporting td select, #gamereporting td textarea {
		color: #333;
		font-size: 14px;
	}
	#formsubmissionreport td select {
		padding: 1px;
		height: 30px;
	}

	#formsubmissionreport tr.even {
		background-color: #f0f0f0;
	}
	#formsubmissionreport tr.odd {
		background-color: #ffffff;
	}
	.json {
		font-size:12px;
		line-height: 12px;
	}
	.search {
		float:right;
		width: 300px;
	}
	#formsubmissionreport .el-select {
		width: auto;
	}
	.export {
		float:right;
	}
    #app .el-date-editor--daterange.el-input__inner {
        max-width: 350px;
    }
	#formsubmissionreport .saveicon i {
		font-size:15px;
		margin-right: 3px;
	}
	#formsubmissionreport .saveicon a {
		font-size:20px;
	}
</style>
