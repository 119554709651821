<template>
	<div id="programplayers">
		<div class="row">
			<div class="col-12 fullpage">
				<div class="program-select">
					<label class="mb-1 control-label">{{config.Params.programName}}:</label>
					<el-select v-if="ActivePrograms.length > 1" v-model="ProgramID" class="topDropdown dropdowns" :disabled="ProgramIDAccess" @change="programChanged" placeholder="Select" default-first-option filterable>
						<el-option v-for="type in ActivePrograms"
								   :value="type.ProgramID"
								   :key="type.ProgramID"
								   :label="type.ProgramName">
						</el-option>
					</el-select>
					<div v-else-if="ActivePrograms.length == 1" class="programName dropdowns">&nbsp;{{ActivePrograms[0].ProgramName}}</div>
				</div>
			</div>
		</div>
        <div v-if="availableSeasons.length > 0" class="row mt-1">
            <div class="col-12 fullpage">
                <div class="program-select">
                    <label class="mb-1 control-label">Season:&nbsp;&nbsp;</label>
                    <el-select class="topDropdown dropdowns" v-model="SeasonID"  @change="seasonChanged" placeholder="Select" default-first-option filterable>
                        <el-option v-for="season in availableSeasons"
                                   :value="season.SeasonID"
                                   :key="season.SeasonID"
                                   :label="season.SeasonName">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="card col-12 fullpage">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Players" name="Players">
                        <div class="row" v-if="!ProgramIDAccess && TeamIDAccess.length == 0">
                            <div class="col-12 mt-4 mb-4">
                                <el-button v-if="createAccess" @click.prevent="newPlayer()"><i class="fa-solid fa-circle-plus"></i> Add New</el-button>
                                <template v-if="$propertyAccess('Exports')">
                                    <el-button v-if="noteamPlayers.length > 0 || teamPlayers.length > 0" type="info" class="btn-secondary"  style="margin-left: 20px;" @click="exportCSV('n')">
                                        <i class="fas fa-file-export"></i>  <span>Export Players</span>
                                    </el-button>
                                    <el-button v-if="csvPlayers.length > 0" type="info" class="btn-secondary" style="float:right;margin-left: 20px;" @click="exportCSV('y')">
                                        <i class="fas fa-file-export"></i>  <span>Export All Players</span>
                                    </el-button>
                                </template>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12 mb-4">&nbsp;</div>
                        </div>

                        <div class="row" v-loading="noteamloading">
                            <div lass="col-12" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
                                unselectable="on"
                                onselectstart="return false;"
                                onmousedown="return false;">
                                <h5 style="float: left;line-height: 32px;">Not Assigned to a Team</h5>
                                <el-select v-if="modifyAccess" class="topDropdown" style="float:left;margin-left: 20px; margin-bottom: 5px;" v-model="AddTeamID" placeholder="Add to Team" default-first-option filterable clearable>
                            <el-option v-for="item in Teams"
                                    :value="item.TeamID"
                                    :key="item.TeamID"
                                    :label="item.TeamName">
                            </el-option>
                        </el-select>
                        <button v-if="modifyAccess && AddTeamID && PlayersSelected.length > 0" type="button" class="btn btn-success btn-icon" style="float:left;margin-left: 20px;height: 32px;padding-top: 4px;margin-bottom: 5px;" @click="movePlayersToTeam">
                            <i class="fas fa-check"></i> <span> Move Players to Team</span>
                        </button>
                                <el-table
                                    :data="noteamPlayers"
                                    stripe border
                                    key="noteamPlayers"
                                    size="small"
                                    max-height="500"
                                    @selection-change="handlePlayerSelected"
                                    style="width: 100%">
                                    <el-table-column v-if="!ProgramIDAccess && TeamIDAccess.length == 0" type="expand" fixed>
                                        <template #default="props">
                                            <span class="json" v-html="'<pre>'+props.row.ParamsF+'</pre>'"></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="modifyAccess"
                                        type="selection"
                                        align="center"
                                        fixed
                                        width="55">
                                    </el-table-column>
                                    <el-table-column
                                            prop="JerseyNumber"
                                            label="No."
                                            width="50"
                                            align="center">
                                    </el-table-column>
                                    <el-table-column
                                            prop="Name"
                                            label="Name"
                                            min-width="180">
                                    </el-table-column>
                                    <el-table-column
                                            v-if="$propertyAccess('EmailAddress')"
                                            prop="EMails"
                                            label="Email"
                                            min-width="200">
                                            <template #default="scope">
                                                <div v-html="scope.row.EMails"></div>
                                            </template>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="$propertyAccess('DOB')"
                                        prop="DOBF"
                                        label="DOB"
                                        min-width="100">
                                    </el-table-column>
                                    <el-table-column
                                        v-if="$propertyAccess('RegistrationApprovalCode')"
                                        prop="RegistrationApprovalCode"
                                        label="Registration No"
                                        min-width="150">
                                    </el-table-column>
                                    <el-table-column
                                        label=""
                                        :width="(modifyAccess ? '140' : '60')"
                                        align="center">
                                        <template #default="scope">
                                            <button v-if="modifyAccess" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editPlayer(scope.$index, scope.row)">
                                                <div class="v-btn__content">
                                                    <i class="far fa-2x fa-edit"></i>
                                                </div>
                                            </button>
                                            <button v-if="modifyAccess" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteCnfm(scope.$index, scope.row)">
                                                <div class="v-btn__content">
                                                    <i class="far fa-2x fa-trash-alt"></i>
                                                </div>
                                            </button>
                                            <template v-if="modifyAccess && $propertyAccess('recordstatus') && !$propertyDisabled('recordstatus')">
                                                <button v-if="scope.row.recordstatus != 'A'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="activatePlayer(scope.$index, scope.row)">
                                                    <div class="v-btn__content">
                                                        <i class="danger fas fa-times-circle"></i>
                                                    </div>
                                                </button>
                                                <div v-else style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="success fas fa-check-circle"></i></div>
                                            </template>
                                            <template v-else>
                                                <div v-if="scope.row.recordstatus != 'A'" style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="danger fas fa-times-circle"></i></div>
                                                <div v-else style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="success fas fa-check-circle"></i></div>
                                            </template>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-4">&nbsp;</div>
                        </div>
                        <div class="row" v-loading="teamloading">
                            <div class="col-12" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
                                unselectable="on"
                                onselectstart="return false;"
                                onmousedown="return false;">
                                <h5>Players on the Following Teams</h5>
                                <el-table
                                    :data="teamPlayers"
                                    border
                                    key="teamPlayers"
                                    max-height="500"
                                    size="small"
                                    :span-method="arraySpanMethod"
                                    :row-class-name="tableRowClassName"
                                    style="width: 100%">
                                    <el-table-column v-if="!ProgramIDAccess && TeamIDAccess.length == 0" type="expand">
                                        <template #default="props">
                                            <span class="json" v-html="'<pre>'+props.row.ParamsF+'</pre>'"></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="JerseyNumber"
                                            label="No."
                                            align="center"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            prop="Name"
                                            label="Name"
                                            min-width="180">
                                    </el-table-column>
                                    <el-table-column
                                        v-if="$propertyAccess('EmailAddress')"
                                        prop="EMails"
                                        label="Email"
                                        min-width="200">
                                        <template #default="scope">
                                            <div v-html="scope.row.EMails"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        v-if="$propertyAccess('DOB')"
                                        prop="DOBF"
                                        label="DOB"
                                        min-width="100">
                                    </el-table-column>
                                    <el-table-column
                                        v-if="$propertyAccess('RegistrationApprovalCode')"
                                        prop="RegistrationApprovalCode"
                                        label="Registration No"
                                        min-width="150">
                                    </el-table-column>
                                    <el-table-column
                                        label=""
                                        :width="(modifyAccess ? '140' : '60')"
                                        align="center">
                                        <template #default="scope">
                                            <template  v-if="TeamAccess(scope.row) && scope.row.PlayerID > 0">
                                                <button v-if="modifyAccess" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editPlayer(scope.$index, scope.row)">
                                                    <div class="v-btn__content">
                                                        <i class="far fa-2x fa-edit"></i>
                                                    </div>
                                                </button>
                                                <button v-if="modifyAccess" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteCnfm(scope.$index, scope.row)">
                                                    <div class="v-btn__content">
                                                        <i class="far fa-2x fa-trash-alt"></i>
                                                    </div>
                                                </button>
                                                <template v-if="modifyAccess && $propertyAccess('recordstatus') && !$propertyDisabled('recordstatus')">
                                                    <button v-if="scope.row.recordstatus != 'A'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="activatePlayer(scope.$index, scope.row)">
                                                        <div class="v-btn__content">
                                                            <i class="danger fas fa-times-circle"></i>
                                                        </div>
                                                    </button>
                                                    <div v-else style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="success fas fa-check-circle"></i></div>
                                                </template>
                                                <template v-else>
                                                    <div v-if="scope.row.recordstatus != 'A'" style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="danger fas fa-times-circle"></i></div>
                                                    <div v-else style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="success fas fa-check-circle"></i></div>
                                                </template>
                                            </template>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="$propertyAccess('Links') && ProgramID && ProgramLeagues.length > 0 && LeagueLinks" label="Registration Links" name="Links">
                        <div class="RegistrationLinks">
                            <span v-html="playerregistrationinstructions"></span>
                            <template v-for="League in ProgramLeagues">
                                <template v-if="PlayerRegistrationSeasonID(League.LeagueID) && PlayerRegistration(League.LeagueID) != 'n' && ProgramCode[SeasonID] && PlayerRegistrationSeasonID(League.LeagueID) == SeasonID">
                                    <div class="LeagueTitle mt-2" style="padding-top: 10px; border-top: 1px solid #ccc;">
                                        <strong>{{League.LeagueName}}</strong>
                                    </div>
                                    <span>
                                        <template v-for="Season in Seasons">
                                            <div v-if="Season.SeasonID == SeasonID && ProgramCode[Season.SeasonID] && PlayerRegistrationSeasonID(League.LeagueID) == Season.SeasonID">
                                                <div class="mt-1">
                                                    <strong>Season: {{Season.SeasonName}}</strong>
                                                </div>
                                                <div v-if="PlayerRegistration(League.LeagueID) == 'y'">
                                                    Player Registration Link:<br><div @focus="$event.target.select()" class="regLink el-input__wrapper" v-html="host+'/'+League.RegistrationSettings.LeagueDomain+'/playerregistration?code='+ProgramCode[Season.SeasonID]" /><br>
                                                </div>
                                                <div v-else-if="PlayerRegistration(League.LeagueID) == 'c'" class="mt-1 regclosed" v-html="League.RegistrationSettings.Params.ClosedMessage">
                                                </div>
                                                <div v-else class="mt-1">
                                                    No Player Registration
                                                </div>
                                            </div>
                                        </template>
                                    </span>
                                </template>
                            </template>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <modal v-if="showModal" @close="showModal = false" top="400px">
			<template v-slot:header>
				<h5 class="modal-title">
					{{modalTitle}}
				</h5>
			</template>
			<template v-slot:body >
				<Player ref="player" :playerData="modalData" :calling="'ProgramPlayers'" :ProgramIDAccess="ProgramIDAccess" :TeamIDs="TeamIDAccess" :createAccess="createAccess" :config="config"  @close="showModal = false" @refreshData="refreshData" />
			</template>
			<template v-slot:footer>
				{{modalFooter}}
			</template>
		</modal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Modal from './global/Modal.vue';
	import axios from 'axios';
	import Player from './Player.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'ProgramPlayers',
		components: {
			Modal,
			Player
		},
	    data() {
	        return {
                currentStatus : STATUS_INITIAL,
                activeTab: 'Players',
                noteamloading: false,
                teamloading : false,
	            modalTitle: 'Edit Player',
	            modalFooter: '',
	            showModal: false,
		        ActivePrograms : [],
				ProgramID : '',
                user : [],
                noteamPlayers : [],
                teamPlayers : [],
		        csvPlayers : [],
                ProgramLeagues: [],
                Seasons : [],
                SeasonID : '',
                playerregistrationinstructions : '',
                host: '',
                ProgramCode: '',
                Params : [],
                Teams : [],
                AddTeamID : '',
                PlayersSelected : [],
	        }
	    },
	    props:["config","Season", "parentrefs"],
	    computed: {
	        ...mapGetters({ currentUser: 'currentUser' }),
		    ...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        createAccess () {
	            return this.$createAccess();
	        },
		    ProgramIDAccess () {
			    return this.$ProgramIDAccess();
		    },
		    TeamIDAccess () {
			    return this.$TeamIDAccess();
		    },
            adminAccess() {
				return (this.currentUser.gid >= 500 ? true : false);
			},
            availableSeasons () {
                var SeasonIDs = [];
                var seasons = [];
                if (this.adminAccess) {
                    return this.Seasons;
                }
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (SeasonIDs.indexOf(this.ProgramLeagues[i].ActiveSeasonID) === -1) {
                        SeasonIDs.push(this.ProgramLeagues[i].ActiveSeasonID);
                    }
                    if (this.ProgramLeagues[i].RegistrationSettings.Params && SeasonIDs.indexOf(this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID) === -1) {
                        SeasonIDs.push(this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID);
                    }
                }
                for (let i = 0; i < this.Seasons.length; i++) {
                    if (SeasonIDs.indexOf(this.Seasons[i]['SeasonID']) !== -1) {
                        seasons.push(this.Seasons[i]);
                        if (!this.SeasonID) {
                            this.SeasonID = this.Seasons[i]['SeasonID'];
                        }
                    }
                }
                return seasons;
            },
            LeagueLinks() {
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (this.PlayerRegistrationSeasonID(this.ProgramLeagues[i].LeagueID) && this.PlayerRegistration(this.ProgramLeagues[i].LeagueID) != 'n' && this.ProgramCode[this.SeasonID] && this.PlayerRegistrationSeasonID(this.ProgramLeagues[i].LeagueID) == this.SeasonID) {
                        return true;
                    }
                }
                return false;
            },
		},
		methods: {
			reset () {
				this.getGames();
			},
            handlePlayerSelected(val) {
                this.PlayersSelected = val;
            },
            PlayerRegistration(LeagueID) {
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (this.ProgramLeagues[i].LeagueID == LeagueID) {
                        if (this.ProgramLeagues[i].RegistrationSettings.Params && this.ProgramLeagues[i].RegistrationSettings.Params.AllowRegistration ) {
                            return this.ProgramLeagues[i].RegistrationSettings.Params.AllowRegistration;
                        }
                    }
                }
                return 'n';
            },
            PlayerRegistrationSeasonID(LeagueID) {
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (this.ProgramLeagues[i].LeagueID == LeagueID) {
                        if (this.ProgramLeagues[i].RegistrationSettings.Params && this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID ) {
                            return this.ProgramLeagues[i].RegistrationSettings.Params.RegistrationSeasonID ;
                        }
                        return this.ProgramLeagues[i].RegistrationSeasonID;
                    }
                }
                return '';
            },
			TeamAccess (row) {
				if (this.TeamIDAccess.length == 0 || this.TeamIDAccess.indexOf(row.TeamID) >= 0) {
					return true;
				} else {
					return false;
				}
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if (row.PlayerID == 0 || row.PlayerID == -1) {
                    if (!this.ProgramIDAccess && this.TeamIDAccess.length == 0) {
                        if (columnIndex == 1) {
                            return [1, 7];
                        } else {
                            return [0,0];
                        }
                    } else {
                        if (columnIndex == 0) {
                            return [1, 6];
                        } else {
                            return [0, 0];
                        }
                    }
				} else {
					return [1, 1];
				}
			},
			tableRowClassName({row, rowIndex}) {
				if (row.PlayerID == 0) {
					return 'team-row';
				} else {
					return '';
				}
			},
            programChanged ()
            {
                this.SeasonID = '';
                this. getProgramLeagueData();
            },
            seasonChanged () {
                this.getNoTeamPlayers();
            },
			getInitialData () {
				var tables = ['ActivePrograms', 'Seasons'];
				axios.post('/index/tableData', {'tables': tables,  },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        this.user = { ...this.currentUser };
	                    if (!this.ProgramID) {
		                    if (this.ProgramIDAccess && this.user.ProgramID) {
			                    this.ProgramID = this.user.ProgramID;
		                    } else {
			                    if (this.componentIDs.PlayersProgramID && !this.ProgramIDAccess) {
				                    this.ProgramID = this.componentIDs.PlayersProgramID;
			                    } else if (this.ProgramIDAccess) {
				                    this.ProgramID = this.user.ProgramID;
			                    } else {
				                    this.ProgramID = this.ActivePrograms[0].ProgramID;
			                    }
		                    }
	                    }
	                    if (this.ProgramID) {
							this.getProgramLeagueData();
						}
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
            refreshData() {
                this.getNoTeamPlayers();
            },
            getProgramLeagueData() {
                axios.post('/programcontacts/getprogramleaguedata', { ProgramID : this.ProgramID, RegistrationType : 'pl' },
                )
                    .then(response => {
                        if (response.data.result == 'success') {

                            this.host = response.data.host;
                            if (response.data.ProgramCode) {
                                this.ProgramCode = response.data.ProgramCode;
                            }
                            if (response.data.ProgramLeagues) {
                                this.ProgramLeagues = response.data.ProgramLeagues;
                            }
                            if (response.data.playerregistrationinstructions) {
                                this.playerregistrationinstructions = (response.data.playerregistrationinstructions ? response.data.playerregistrationinstructions : '');
                            }
                            var SeasonIDs = [];
                            this.SeasonID = '';
                            for (let i = 0; i < this.ProgramLeagues.length; i++) {
                                if (SeasonIDs.indexOf(this.ProgramLeagues[i].ActiveSeasonID) === -1) {
                                    SeasonIDs.push(this.ProgramLeagues[i].ActiveSeasonID);
                                }
                                if (SeasonIDs.indexOf(this.ProgramLeagues[i].RegistrationSeasonID) === -1) {
                                    SeasonIDs.push(this.ProgramLeagues[i].RegistrationSeasonID);
                                }
                            }
                            if (this.availableSeasons.length > 0) {
                                for (let i = 0; i < this.availableSeasons.length; i++) {
                                    if (SeasonIDs.indexOf(this.availableSeasons[i]['SeasonID']) !== -1) {
                                        if (!this.SeasonID) {
                                            this.SeasonID = this.availableSeasons[i]['SeasonID'];
                                        }
                                    }
                                }
                            }
                            this.getNoTeamPlayers();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
			getNoTeamPlayers() {
                this.noteamloading = true;
                //this.noteamPlayers = [];
				axios.post('/programplayers/getAll', { ProgramID : this.ProgramID, TeamPlayers : 'n',  TeamIDs : this.TeamIDAccess, ActiveOnly: 'n', SeasonID : this.SeasonID},
				)
                .then(response => {
                    if (response.data.result == 'success') {

                        if (!response.data.players) {
                            response.data.players = [];
                        }
                        for (let i=0; i < response.data.players.length; i++) {
                            response.data.players[i].ParamsF = JSON.stringify(response.data.players[i].Params,null,'\t');
                            this.Params.push(response.data.players[i].Params);
                        }
                        this.noteamPlayers = response.data.players;
                        this.getTeamPlayers();
                    } else {
                        this.noteamPlayers = [];
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.noteamloading = false;
                }).catch(response => {
                    this.noteamPlayers = [];
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getTeamPlayers() {
                this.teamloading = true;
                //this.teamPlayers = [];
				let self = this;
				axios.post('/programplayers/getAll', { ProgramID : this.ProgramID, TeamPlayers : 'y',  TeamIDs : this.TeamIDAccess, ActiveOnly: 'n', SeasonID : this.SeasonID},
				)
                .then(response => {
                    if (response.data.result == 'success') {
	                    self.$store.dispatch('setID', {'PlayersProgramID': self.ProgramID});
	                    this.csvPlayers = (response.data.csvplayers ? response.data.csvplayers : []);
                        if (!response.data.players) {
                            response.data.players = [];
                        }
                        for (let i=0; i < response.data.players.length; i++) {
                            response.data.players[i].ParamsF = JSON.stringify(response.data.players[i].Params,null,'\t');
                            this.Params.push(response.data.players[i].Params);
                        }
                        this.Teams = response.data.Teams;
                        this.teamPlayers = response.data.players;
                        this.$stopProgressBar(this.parentrefs);
                    } else {
                        this.teamPlayers = [];
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.AddTeamID = '';
                    this.teamloading = false;
                }).catch(response => {
                    this.teamPlayers = [];
                    this.$failProgressBar(this.parentrefs);
                    this.teamloading = false;
                });
			},
			editPlayer(index, row) {
				this.modalTitle = 'Edit Player';
				this.modalData = row;
				this.displayModal();
			},
			newPlayer() {
				this.modalTitle = 'New Player';
				var row = {};
                row.ProgramID = this.ProgramID;
                row.SeasonID = this.SeasonID;
				row.Params = {};
				this.modalData = row;
				this.displayModal();
			},
			deleteCnfm (index,row) {
				this.$confirm('Are you sure you wish to delete this player?', 'Confirmation', {
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					type: 'warning',
					showClose: false,
					showCancelButton: true
				}).then(() => {
					this.deletePlayer(index, row);
				}).catch(() => {
				});

			},
			deletePlayer(index, row) {
				if (this.currentUser.PlayerID != row.PlayerID) {
					axios.post('/programplayers/delete', {'PlayerID': row.PlayerID}
					)
					.then(response => {
						if (response.data.result == 'success') {
							this.getNoTeamPlayers();
							this.$notify({
								title: 'Success',
								message: "Player has been Deleted",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
                            this.displayPopup ((response.data.message ? response.data.message : "Player cannot be Deleted"));
						}
					}).catch(response => {
						this.$notify({
							title: 'Error',
							message: "Player cannot be Deleted",
							type: 'error',
							duration: 2000,
							showClose: false
						});
					});
				} else {
					this.displayPopup ('Sorry, you cannot delete yourself');
				}
			},
			activatePlayer(index, row) {
				axios.post('/programplayers/activate', {'PlayerID': row.PlayerID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.getNoTeamPlayers();
							this.$notify({
								title: 'Success',
								message: "Player has been Activated",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
							this.$notify({
								title: 'Error',
								message: "Player cannot be Activated",
								type: 'error',
								duration: 2000,
								showClose: false
							});
						}
					}).catch(response => {
					this.$notify({
						title: 'Error',
						message: "Player cannot be Activated",
						type: 'error',
						duration: 2000,
						showClose: false
					});
				});
			},
            movePlayersToTeam() {
				axios.post('/programplayers/massSave', {Players : this.PlayersSelected, AddTeamID : this.AddTeamID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.getNoTeamPlayers();
                            this.$notify({
								title: 'Success',
								message: "Player(s) have been moved to Team",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						}
					}).catch(response => {
                        this.$notify({
                            title: 'Error',
                            message: "Player Team cannot be Saved",
                            type: 'error',
                            duration: 2000,
                            showClose: false
                        });
				});
			},
	        displayPopup (message) {
                this.$alert(message, 'Player Error', {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
            },
            displayModal () {
                this.showModal = true;
            },
            hideModal () {
                this.showModal = false;
            },
			exportCSV (all) {
				if (all == 'n') {
					var list = JSON.parse(JSON.stringify( this.noteamPlayers.concat(this.teamPlayers) ));
				} else {
					var list = JSON.parse(JSON.stringify(this.csvPlayers));
				}

				var newList = [];
                let columns = [];
                for (let i = 0; i <= this.Params.length; i++) {
                    for (var property in this.Params[i]) {
                        if (this.Params[i].hasOwnProperty(property)) {
                            if (columns.indexOf(property) == -1) {
                                columns.push(property);
                            }
                        }
                    }
                }
                columns.sort();
                //this is a Program specific user and will have limited fields.
				for(let i=0; i<list.length; i++) {
					if (this.ProgramIDAccess || this.TeamIDAccess.length > 0) {
						delete list[i].ProgramID;
						delete list[i].Name;
						delete list[i].PhoneNumbers;
						delete list[i].EMails;
						delete list[i].DOB;
						delete list[i].Address;
						delete list[i].recordstatus;
						delete list[i].IDNumber;
						delete list[i].createdwhen;
						delete list[i].modifiedwhen;
						delete list[i].RegistrationApprovalCode;
					} else {
                        for (let j=0; j<columns.length; j++) {
                            if (list[i]['Params']) {
                                if (!list[i][columns[j]]) {
                                    if (list[i]['Params'][columns[j]]) {
                                        list[i][columns[j]] = list[i]['Params'][columns[j]];
                                    } else {
                                        list[i][columns[j]] = '';
                                    }
                                }
                            }
                        }
                    }
					delete list[i].Params;
                    delete list[i].ParamsF;
					if (list[i].PlayerID > '0') {
						newList.push(list[i]);
					}
				}

				var csv = Papa.unparse(newList);
				var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
				var csvURL =  null;
				if (navigator.msSaveBlob)
				{
					csvURL = navigator.msSaveBlob(csvData, 'players.csv');
				}
				else
				{
					csvURL = window.URL.createObjectURL(csvData);
				}

				var tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'players.csv');
				tempLink.click();
			},
		},
		mounted () {
			this.getInitialData();
		},
	}
</script>
<style>
#programplayers .el-table th {
	font-size: 120%;
	font-weight: 800;
}
#programplayers .modal-dialog {
	width: 400px;
}
#programplayers .modal-body {
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}
#programplayers .modal-footer {
	height: 55px;
}
.programplayers div.el-table .cell {
	word-break: normal;
}
#programplayers .team-row td {
    text-align: left;
}
#programplayers .el-tabs__header.is-top {
    margin-left:15px;
}
</style>
<style scoped>
#programplayers .scores {
	width: 40px;
    text-align: center;
    padding: 1px;
    margin: 1px 0px;
    height: 23px;
}
#programplayers .checkbox {
    display: inline-block;
    width: 25px;
    height: 15px;
}
#programplayers .webnotes {
	width: 90%;
	height: 40px;
	padding: 2px;
}
#programplayers .heading {
	background-color: #1A719C;
	color: #fff;
	font-size: 14px;
}
#programplayers .body {
	color: #333;
	font-size: 14px;
	padding: 2px;
}
#programplayers .body input, #programplayers .body select, #programplayers .body textarea {
	color: #333;
	font-size: 14px;
}
#programplayers .body select {
	padding: 1px;
}

#programplayers .body.row:nth-child(even){
	background-color: #dcdcdc;
}
#programplayers .body.row:nth-child(odd){
	background-color: #ffffff;
}
#programplayers .row.buttons {
	margin-top: 20px;
}
select.not-available {
	width: auto;
	margin-left: 0px   !important;
}
.not-available label {
	margin-top: 4px;
}
.program-select {
	font-weight: 800;
	height: 35px;
}
.options {
	margin: 0px 5px;
}
.el-select {
	width: 100%;
	max-width: 300px;
}
.programName {
	display: inline-block;
	font-weight: 800;
	margin-top: 5px;
}

.regLink {
	font-size: 12px;
    width: 100%;
    margin-bottom: 5px;
	text-align:left;
	justify-content: left;
	line-height: 30px;
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}
.regclosed {
    margin-left: 15px;
    font-weight: 800;
}
.RegistrationLinks {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    margin: 20px;
}
label {
	position: absolute;
	margin-top: 5px
}
.dropdowns {
	margin-left: 60px;
	position: absolute;
	width: 100%;
}
i.fas, i.far {
    font-size: 18px;
}
</style>
