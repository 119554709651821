<template>
	<div id="organizations" v-loading="loading">
		<div class="row">
			<div class="col-12 fullpage">
				<el-select v-model="OrganizationID" class="topDropdown" placeholder="Select" @change="getOrganization()" default-first-option filterable>
					<el-option v-if="createAccess" key="" label="-- Add New --" value=""></el-option>
					<el-option
						v-for="item in Organizations"
						:label="item.OrganizationName"
						:value="item.OrganizationID"
						:key="item.OrganizationID">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="row mt-4">
			<div class="card col-12">
				<el-form ref="organizationsForm" label-position="top" :model="organization" label-width="120px" >
					<el-tabs v-model="activeTab">
						<el-tab-pane label="Details" name="details">
							<div class="row">
								<div class="col-12 col-md-6">
									<el-form-item label="Organization Name" prop="OrganizationName"
										:rules="[{ required: true, message: 'Organization Name is required', trigger: 'blur'}]"
									>
										<el-input v-model="organization.OrganizationName">
										</el-input>
									</el-form-item>
									<el-form-item class="mt-4" label="Orgnanization Type" prop="OrgnanizationTypeID">
										<el-select v-model="organization.OrganizationTypeID" class="oneHundred" auto-complete=on placeholder="Select" default-first-option filterable clearable>
											<el-option
													v-for="item in OrganizationTypes"
													:key="item.OrganizationTypeID"
													:label="item.OrganizationTypeName"
													:value="item.OrganizationTypeID">
											</el-option>
										</el-select>
									</el-form-item>
                                    <el-form-item :label="config.Params.programName" prop="ProgramID">
                                        <el-select v-model="organization.ProgramID" class="oneHundred" placeholder="Select" default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in availablePrograms"
                                                :key="item.ProgramID"
                                                :label="item.ProgramName"
                                                :value="item.ProgramID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Default Fee" prop="DefaultFee">
                                        <el-input type="number" class="smallCurrency" v-model="organization.Params.DefaultFee">
                                            <template #prepend><span v-html="currencySign"></span></template>
                                        </el-input>
                                    </el-form-item>
									<el-form-item label="Default Hourly or Flat Fee" prop="DefaultFee">
                                        <el-radio-group v-model="organization.Params.HourlyFlat" >
                                            <el-radio-button label="h">Hourly Rate</el-radio-button>
                                            <el-radio-button label="f">Flat Rate</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
									<el-form-item label="Default Color" prop="DefaultColor">
										<el-select v-model="organization.Params.DefaultColor" class="fifty color" :class="colorClass()" placeholder="Select" default-first-option filterable clearable>
											<el-option
													v-for="item in colors"
													:key="item.value"
													:label="item.text"
													:value="item.value">
												<div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.value + ';'">{{ item.text }}</div>
											</el-option>
										</el-select>
									</el-form-item>
								</div>
								<div class="col-12 col-md-6">
									<el-form-item label="Address Line 1" prop="AddressLine1">
										<el-input v-model="organization.Params.AddressLine1">
										</el-input>
									</el-form-item>
									<el-form-item label="Address Line 2" prop="AddressLine2">
										<el-input v-model="organization.Params.AddressLine2">
										</el-input>
									</el-form-item>
									<el-form-item label="City" prop="City">
										<el-input v-model="organization.Params.City">
										</el-input>
									</el-form-item>
									<el-form-item :label="config.Params.state" prop="StateOrProvinceCodeID">
										<el-select v-model="organization.Params.StateOrProvinceCodeID" class="oneHundred" placeholder="Select" default-first-option filterable clearable>
											<el-option-group
													v-for="country in StateProvinceCodes"
													:key="country.Country"
													:label="country.Country">
												<el-option
														v-for="state in country.States"
														:key="state.StateProvinceCodeShortName"
														:label="state.StateProvinceCodeName"
														:value="state.StateProvinceCodeShortName">
												</el-option>
											</el-option-group>
										</el-select>
									</el-form-item>
									<el-form-item :label="config.Params.zipcode" prop="PostalCode" class="postalCode">
										<el-input v-model="organization.Params.PostalCode">
										</el-input>
									</el-form-item>
									<el-form-item label="Phone" prop="ContactPhoneNumber">
										<div class="el-form-item__content">
											<div class="el-input el-input--default el-input__wrapper">
												<input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="organization.Params.ContactPhoneNumber" />
											</div>
										</div>
									</el-form-item>
								</div>
							</div>
							<div v-if="!organization.ProgramID" class="row mb-4" >
								<div class="col-12">
									<h5>Contacts</h5><el-button v-if="modifyAccess || createAccess" @click.prevent="addContact()" ><i class="fa-solid fa-circle-plus"></i> Add Contact</el-button>
									<el-table
											:data="organization.Params.Contacts"
											key="orgcontacts"
											max-height="300"
											border striped
											size="small"
											empty-text="No Contacts"
											style="width: 100%">
										<el-table-column
												prop="selected"
												label="Email Contacts"
												align="center"
												width="120"
										>
											<template #default="scope">
												<el-checkbox true-label="y" false-label="n" :disabled="!modifyAccess" v-model="scope.row.emailInvoicing" />
											</template>
										</el-table-column>
										<el-table-column
												prop="ContactName"
												label="Name"
												align="left"
												min-width="150"
										>
											<template #default="scope">
												<input type="text" class="contactDetails" v-model="scope.row.ContactName" @focus="$event.target.select()" />
											</template>
										</el-table-column>
										<el-table-column
												prop="ContactEmailAddress"
												label="Email Address"
												align="left"
												min-width="200"
										>
											<template #default="scope">
												<input type="text" class="contactDetails" v-model="scope.row.ContactEmailAddress" @focus="$event.target.select()" />
											</template>
										</el-table-column>
										<el-table-column v-if="modifyAccess"
														 label=""
														 width="80"
														 align="center">
											<template #default="scope">
												<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click.prevent="removeContact(scope.$index)">
													<div class="v-btn__content">
														<i class="far fa-2x fa-trash-alt"></i>
													</div>
												</button>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</div>
							<div v-else class="row mb-4" >
								<div class="col-12">
									<h5>Contacts</h5>
									<el-table
											:data="programcontacts"
											key="programcontacts"
											max-height="300"
											border striped
											size="small"
											empty-text="No Contacts"
											style="width: 100%">
										<el-table-column
												prop="selected"
												label="Email Contacts"
												align="center"
												width="120"
										>
											<template #default="scope">
												<template v-if="scope.row.Params">
													<input type="checkbox" true-value="y" false-value="n" :disabled="!modifyAccess" v-model="scope.row.Params.emailInvoicing" />
												</template>
											</template>
										</el-table-column>
										<el-table-column
												prop="ContactName"
												label="Name"
												align="left"
												min-width="150"
										>
											<template #default="scope">
												{{scope.row.FirstName + ' ' + scope.row.LastName + ' (' + scope.row.ContactTypeName + ')'}}
											</template>
										</el-table-column>
										<el-table-column
												prop="EmailAddress"
												label="Email Address"
												align="left"
												min-width="200"
										>
										</el-table-column>
									</el-table>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="Rentals/Contracts" name="rentals" v-if="OrganizationID">
							<div class="mb-4">
                                <h5>Rentals</h5>
                                <el-date-picker
                                    v-model="rentaldates"
                                    style="width: 350px;"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="To"
                                    start-placeholder="Start date"
                                    end-placeholder="End date"
                                    format="MMM D, YYYY"
                                    value-format="YYYY-MM-DD">
                                </el-date-picker>
								<el-table
                                        class="mt-2"
										:data="filteredRentals"
										key="rentalstable"
										max-height="500"
										size="small"
										stripe border
                                        @selection-change="handleSelectionChange"
										style="width: 100%">
                                    <el-table-column
                                        type="selection"
                                        align="center"
                                        width="100">
										<template  #default="scope">
											<div v-if="scope.row.ContractNo" v-html="(scope.row.ContractNo ? scope.row.ContractNo : '')"></div>
										</template>
                                    </el-table-column>
									<el-table-column
											prop="StartDate"
											label="Date"
											align="center"
											width="100"
											sortable
									>
									</el-table-column>
									<el-table-column
											prop="Time"
											label="Time"
											align="center"
											width="150"
									>
										<template #default="scope">
											<div v-html="(scope.row.StartTime ? scope.row.StartTime + ' - ' + scope.row.EndTime : 'All Day')"></div>
										</template>
									</el-table-column>
									<el-table-column
											prop="LocationName"
											label="LocationName"
											align="left"
                                            min-width="130"
											sortable
									>
									</el-table-column>
									<el-table-column
											prop="Title"
											label="Description"
											align="left"
                                            min-width="130"
                                            sortable
									>
									</el-table-column>
                                    <el-table-column
                                        prop=""
                                        label="Hourly Rate"
                                        width="100"
                                        align="right"
                                    >
                                        <template #default="scope">
                                            <div v-html="(scope.row.HourlyRate == 'h' ?  formatPrice(scope.row.HourlyFlat) +'/hr' : 'Fixed')"></div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        prop=""
                                        label="Amount"
                                        width="100"
                                        align="right"
                                    >
                                        <template #default="scope">
                                            <div v-html="formatPrice(scope.row.RinkFee) + (scope.row.HourlyFlat == 'h' ? '/hr' : '') "></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop=""
                                        label="Status"
                                        width="100"
                                        align="right"
                                    >
                                        <template #default="scope">
                                            <div v-if="scope.row.EventStatus == 'A'" v-html="'Active'"></div>
                                            <div v-if="scope.row.EventStatus == 'CP' || scope.row.EventStatus == 'CPO'" v-html="'Cancelled, Will Pay'"></div>
                                            <div v-if="scope.row.EventStatus == 'CN' || scope.row.EventStatus == 'CNO'" v-html="'Cancelled, No Payment'"></div>
                                        </template>
                                    </el-table-column>
								</el-table>
                                <div v-if="Rentals.length > 0" class="mt-4 mb-4">
                                    <el-card class="box-card">
                                        <div class="card-header bg-primary clearfix"><h5>Build/Create Contract</h5></div>
                                        <div class="row rentals">
                                            <div class="col-md-7 col-12">
                                                <label class="control-label">Billing Account:</label><br>
                                                <el-select key="select3" style="width: 350px;" v-model="InvoiceSettingID" placeholder="Select" default-first-option filterable>
                                                    <template v-for="type in InvoiceSettings">
		                                                <el-option v-if="type.BillingType == 'IU'"
		                                                           :value="type.InvoiceSettingID"
		                                                           :key="type.InvoiceSettingID"
		                                                           :label="type.BillingName">
		                                                </el-option>
                                                    </template>
                                                </el-select><br>
                                                <label class="control-label">Contract Top Text:</label><br>
                                                <el-select key="select4" style="width: 350px;" v-model="ContractHeader" placeholder="Select" default-first-option filterable>
                                                    <el-option key="contractheader"
                                                               label="Contract Top Terms"
                                                               value="contractheader">
                                                    </el-option>
													<el-option-group
														v-for="group in CustomEmailsGrouping['contractheader']"
														:key="group.label"
														:label="group.label"
													>
														<el-option
															v-for="item in group.options"
															:key="item.value"
															:label="item.label"
															:value="item.value"
														/>
													</el-option-group>
                                                </el-select>
                                                <br>
                                                <label class="control-label">Contract Terms:</label><br>
                                                <el-select key="select5" style="width: 350px;" v-model="ContractTandCs" placeholder="Select" default-first-option filterable>
                                                    <el-option key="contracttandc"
                                                               label="Default Contract Terms & Conditions"
                                                               value="contracttandc">
                                                    </el-option>
													<el-option-group
														v-for="group in CustomEmailsGrouping['contracttandc']"
														:key="group.label"
														:label="group.label"
													>
														<el-option
															v-for="item in group.options"
															:key="item.value"
															:label="item.label"
															:value="item.value"
														/>
													</el-option-group>
                                                </el-select>
                                            </div>
                                            <div class="col-md-1 col-12">&nbsp;</div>
                                            <div class="col-md-4 col-12" style="text-align: left;">
                                                <el-button v-if="InvoiceSettingID" type="info" class="btn-secondary" style="margin-top: 50px;" @click="viewContract"><i class="fa-solid fa-eye"></i> View Contract PDF</el-button>
                                                <br>
                                                <el-button v-if="InvoiceSettingID" type="success" style="margin-top: 50px;" @click="generateContract"><i class="fa-solid fa-file-circle-plus"></i> Create Contract</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                                <h5 style="width:100%;">Contracts</h5><br>
								<div style="font-weight:800;">Complete these fields when emailing Contracts.</div>
                                <div class="mb-2" style="width: 100%;">
									<EmailTemplate calling="3rdpartycontract" v-model="ContractSystemText" BillingType="" :config="config" width="100%" />
                                </div>
                                <el-table
                                    class="mt-2"
                                    :data="Contracts"
                                    key="contractstable"
                                    max-height="500"
                                    size="small"
                                    stripe border
                                    style="width: 100%">
                                    <el-table-column
                                        prop="AgreementNumber"
                                        label="Contract No"
                                        align="center"
                                        width="120"
                                        sortable
                                    >
                                        <template #default="scope">
                                            <div v-html="'CNT-' + scope.row.AgreementNumber"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="ContractDate"
                                        label="Date"
                                        align="center"
                                        width="100"
                                        sortable
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="Description"
                                        label="Description"
                                        align="left"
                                        min-width="130"
                                        sortable
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="Hours"
                                        label="Hours"
                                        width="100"
                                        align="center"
                                    >
                                    </el-table-column>

                                    <el-table-column
                                        prop="Amount"
                                        label="Amount"
                                        width="100"
                                        align="right"
                                    >
                                        <template #default="scope">
                                            <div v-html="formatPrice(scope.row.Amount)"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop=""
                                        label="Status"
                                        width="130"
                                        align="right"
                                    >
                                        <template #default="scope">
                                            <div v-if="scope.row.ContractStatus == 'C'" v-html="'Created'"></div>
                                            <div v-if="scope.row.ContractStatus == 'A'" v-html="'Active (signed)'"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop=""
                                        label="Download"
                                        align="center"
                                        width="120px">
                                        <template #default="scope">
											<button v-if="scope.row.ContractPDF" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="downloadContract(scope.row)">
												<div class="v-btn__content">
													<i class="fas fa-2x fa-file-download"></i>
												</div>
											</button>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop=""
                                        label="Email"
                                        align="center"
                                        width="80px">
                                        <template #default="scope">
	                                        <template v-if="scope.row.Params">
												<button v-if="modifyAccess" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="emailContract(scope.row);scope.row.Params.ContractEmailed = 'y';">
													<div class="v-btn__content">
														<i class="far fa-2x fa-envelope"></i>
													</div>
												</button><br>
	                                            <span v-if="scope.row.Params && scope.row.Params.ContractEmailed && scope.row.Params.ContractEmailed == 'y'" class="emailsuccess"><i class="emailsuccess fas fa-check-square"></i>&nbsp; Emailed</span>
	                                        </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop=""
                                        label="Remove"
                                        align="center"
                                        width="120px">
                                        <template #default="scope">
											<button v-if="modifyAccess && scope.row.ContractStatus == 'C'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="cnfmDeleteContract(scope.row);">
												<div class="v-btn__content">
													<i class="far fa-2x fa-trash-alt"></i>
												</div>
											</button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>
						</el-tab-pane>
						<el-tab-pane label="Invoices" name="invoices" v-if="OrganizationID">
							<div class="mb-4">
                                <div class="mb-2" style="width: 100%;">
									<EmailTemplate calling="iceusageinvoice" v-model="EmailSystemText" BillingType="" :config="config" width="100%" />
                                </div>
								<el-table
										key="invoicetable"
										:data="Invoices"
										stripe border
										size="small"
										style="width: 100%">
									<el-table-column type="expand">
										<template #default="props">
											<span v-if="props.row.InvoiceType == 'TR'">
												<div class="row detailheader">
													<div class="col-4">Team</div>
													<div class="col-2">Games</div>
													<div class="col-4">Notes</div>
													<div class="col-2">Amount</div>
												</div>
												<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
													<div style="line-height: 35px;" class="col-4">{{item.TeamName}}</div>
													<div style="line-height: 35px;" class="col-2">{{item.RegisteredGames}}</div>
													<div style="line-height: 35px;" class="col-4">{{item.RegisteredNotes}}</div>
													<div style="line-height: 35px;" class="col-2" v-html="formatPrice(item.DetailAmount)"></div>
												</div>
											</span>
											<span v-if="props.row.InvoiceType == 'IU'">
												<div class="row detailheader">
													<div class="col-2">Date</div>
													<div class="col-2">Time</div>
													<div class="col-3">Location</div>
													<div class="col-3">Description</div>
													<div class="col-2">Amount</div>
												</div>
												<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
													<div style="line-height: 35px;" class="col-2">{{item.StartDate}}</div>
													<div style="line-height: 35px;" class="col-2" v-html="(item.type == 'c' ? '' : (item.StartTime ? item.StartTime + ' - ' + item.EndTime : 'All Day'))"></div>
													<div style="line-height: 35px;" class="col-3">{{item.LocationName}}</div>
													<div style="line-height: 35px;" class="col-3">{{(item.type == 'c' ? 'Credit - ' : '') + item.Title}}</div>
													<div style="line-height: 35px;" class="col-2" v-html="formatPrice(item.DetailAmount)"></div>
												</div>
											</span>
											<span v-if="props.row.InvoiceType == 'BG'">
												<div class="row detailheader">
													<div class="col-5">Team</div>
													<div class="col-2" style="text-align:center;">Games</div>
													<div class="col-5" style="text-align:center;">Fee</div>
												</div>
												<div class="row" v-for="(event, index) in props.row.Details" :class="{even : index % 2 == 0}">
													<div style="line-height: 35px;" class="col-5">{{event.TeamName}}</div>
													<div style="line-height: 35px;text-align:center;" class="col-2">{{(event.ToBill == 0 ? 'ALL' : event.ToBill)}}</div>
													<div class="col-5" style="line-height: 35px;text-align:center;" v-html="formatPrice(event.DetailAmount)"></div>
												</div>
											</span>
										</template>
									</el-table-column>
									<el-table-column
											prop="InvoiceID"
											label="#"
											min-width="60px"
											align="center"
									>
									</el-table-column>
									<el-table-column
											prop="InvoiceDate"
											label="Date"
											min-width="70px"
									>
									</el-table-column>
									<el-table-column
											prop="Description"
											label="Description"
											min-width="150px"
									>
										<template #default="scope">
											<div v-html="scope.row.Description"></div>
										</template>
									</el-table-column>
									<el-table-column
											prop="Amount"
											label="Total"
											align="right"
											min-width="120px">
										<template #default="scope">
											<div v-html="formatPrice(scope.row.Amount)"></div>
										</template>

									</el-table-column>
									<el-table-column
											v-if="modifyAccess"
											prop="InvoiceStatus"
											label="Status"
											:filters="[{ text: 'Active', value: 'Pending' }, { text: 'Void', value: 'Void' }]"
											:filter-method="filterStatus"
											filter-placement="bottom-end"
											:filtered-value="['Pending']"
											align="center"
											width="100px">
										<template #default="scope">
											<div v-if="scope.row.InvoiceStatus != 'Void'">Active</div>
											<div v-else class="paid">Void</div>
										</template>
									</el-table-column>
									<el-table-column
											prop=""
											label="Email"
											:filters="[{ text: 'All', value: 'All' }, { text: 'Emailed', value: 'y' }, { text: 'Not-Emailed', value: 'n' }]"
											:filter-method="filterEmail"
											filter-placement="bottom-end"
											:filtered-value="['All']"
											align="center"
											width="120px">
										<template #default="scope">
											<template v-if="scope.row.Params">
												<button v-if="modifyAccess" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="emailInvoice(scope.row);scope.row.Params.InvoiceEmailed = 'y';">
													<div class="v-btn__content">
														<i class="far fa-2x fa-envelope"></i>
													</div>
												</button><br>
												<span v-if="scope.row.Params && scope.row.Params.InvoiceEmailed && scope.row.Params.InvoiceEmailed == 'y'" class="emailsuccess"><i class="emailsuccess fas fa-check-square"></i>&nbsp; Emailed</span>
											</template>
										</template>
									</el-table-column>
									<el-table-column
											prop=""
											label="Download"
											align="center"
											width="120px">
										<template #default="scope">
											<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="downloadInvoice(scope.row)">
												<div class="v-btn__content">
													<i class="fas fa-2x fa-file-download"></i>
												</div>
											</button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-form>
			</div>
		</div>
		<div class="row mb-4" v-if="(modifyAccess || (createAccess && OrganizationID)) && activeTab == 'details'" >
            <div class="col-12 text-center">
                <button class="btn btn-success btn-icon" type="button" @click="saveOrganization">
                    <i class="fas fa-check"></i> <span> Save Changes</span>
                </button>
                <button v-if="activeTab == 'details' && OrganizationID" class="btn btn-icon btn-danger delete" type="button"  @click="cnfmDeleteOrganization()">
                    <i class="far fa-trash-alt"></i>  <span>Delete</span>
                </button>
                <button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)">
                    <i class="fas fa-times"></i>  <span>Cancel</span>
                </button>
            </div>
        </div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	import Util from '../assets/js/util.js';
	import EmailTemplate from './global/EmailTemplate.vue';

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
	export default {
		name: 'Organizations',
		components: {
			EmailTemplate
        },
		data () {
			return {
				loading : true,
                currentStatus : STATUS_INITIAL,
				uploadReady : true,
				activeTab : 'details',
				organization : {Params:{Contacts : []}},
				Organizations : [],
				OrganizationID : null,
				OrganizationTypes : [],
				StateProvinceCodes : [],
				programcontacts : [],
				Invoices : [],
				Rentals : [],
                Contracts : [],
				Programs : [],
				AssignedPrograms : [],
                InvoiceSettings : [],
                InvoiceSettingID : '',
                rentalsSelected : [],
				colors :
				[
					{ text: 'Red', value: '#F44336' },
					{ text: 'Pink', value: '#E91E63' },
					{ text: 'Purple', value: '#9C27B0' },
					{ text: 'Deep Purple', value: '#673AB7' },
					{ text: 'Indigo', value: '#3F51B5' },
					{ text: 'Blue', value: '#2196F3' },
					{ text: 'Highlight Color', value: '#1976d2' },
					{ text: 'Light Blue', value: '#03A9F4' },
					{ text: 'Cyan', value: '#00BCD4' },
					{ text: 'Teal', value: '#009688' },
					{ text: 'Green', value: '#4CAF50' },
					{ text: 'Light Green', value: '#8BC34A' },
					{ text: 'Lime', value: '#CDDC39' },
					{ text: 'Yellow', value: '#FFEB3B' },
					{ text: 'Amber', value: '#FFC107' },
					{ text: 'Orange', value: '#FF9800' },
					{ text: 'Deep Orange', value: '#FF5722' },
					{ text: 'Brown', value: '#795548' },
					{ text: 'Blue Gray', value: '#607D8B' },
					{ text: 'Gray', value: '#9E9E9E' },
					{ text: 'Black', value: '#000000' }
				],
                signatureoptions:{
                    penColor:"rgb(0, 0, 0)",
                    backgroundColor:"rgb(245,245,245)"
                },
                newSignature : false,
                rentaldates : null,
				CustomEmailsGrouping : [],
                ContractHeader : 'contractheader',
                ContractTandCs : 'contracttandc',
				ContractSystemText : '',
                EmailSystemText : '',
			}
		},
		props:["config", "parentrefs"],
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        createAccess () {
                return this.$createAccess();
            },
	        modifyAccess () {
                return this.$modifyAccess();
            },
			availablePrograms () {
				let programs = [];
				for (let i = 0; i < this.Programs.length; i++) {
					if (this.AssignedPrograms.indexOf(this.Programs[i].ProgramID) === -1 || this.Programs[i].ProgramID == this.organization.ProgramID) {
						programs.push(this.Programs[i]);
					}
				}
				return programs;
			},
			haveSignature () {
                return !this.$refs.signature.isEmpty();
            },
            filteredRentals() {
                let rentals = [];
                if (this.rentaldates && this.rentaldates.length > 0) {
                    for (let i = 0; i < this.Rentals.length; i++) {
                         if (this.Rentals[i]['StartDate'] >= this.rentaldates[0] && this.Rentals[i]['StartDate'] <= this.rentaldates[1]) {
                             rentals.push(this.Rentals[i]);
                         }
                    }
                    return rentals;
                } else {
                    return this.Rentals;
                }

            },
            defaultPDF () {
                return require('../assets/images/pdf-download.png');
            },
            defaultImage () {
                return require('../assets/images/signature.png');
            },
            currencySign () {
                return Util.currencySign(this.config);
            },
		},
		methods: {
			colorClass () {
				for (let i = 0; i < this.colors.length; i++) {
					if (this.organization.Params.DefaultColor == this.colors[i].value) {
						return this.colors[i].text.replace(' ','_');
					}
				}
				return 'white';
			},
            fileType(filename) {
                return filename.split('.').pop().toLowerCase();
            },
            SignatureIcon(filename, type) {
                if (type == 'image') {
                    return this.defaultImage;
                } else {
                    return [filename];
                }
            },
            handleSelectionChange(val) {
                this.rentalsSelected = val;
            },
			formatPrice(value) {
				if (value) {
					return Util.formatPrice(this.config, value, '0');
				} else {
					return '';
				}
			},
			filterStatus(value, row) {
				return row.InvoiceStatus === value;
			},
			filterEmail(value, row) {
				if (value == 'All') {
					return true;
				} else if (value == 'y') {
					return row.Params.InvoiceEmailed == 'y';
				} else {
					return row.Params.InvoiceEmailed != 'y';
				}
			},
			addContact() {
				if (!this.organization.Params.hasOwnProperty('Contacts')) {
					this.organization.Params['Contacts'] = [];
				}
				this.organization.Params.Contacts.unshift(
					{
						ContactName: '',
						ContactEmailAddress: ''
					}
				);
			},
			removeContact(index) {
				this.organization.Params.Contacts.splice(index,1);
			},
			reset(showMessage) {
				// reset form to initial state
				this.currentStatus = STATUS_INITIAL;
				this.getInitialData();
				if (showMessage) {
					this.$notify({
						title: 'Success',
						message: "Organization has been reset",
						type: 'success',
						duration: 1500,
						showClose: false
					});
				}
			},
            getCustomTexts (type) {
                this.CustomText = [];
                axios.post('/systemtext/getcustomtext', {CustomTextID : type, Status : 'A'})
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.CustomEmailsGrouping) {
                                this.CustomEmailsGrouping[type] = response.data.CustomEmailsGrouping;
                            }
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            saveSignature(){
			    if (this.$refs.signature.isEmpty()) {
                    this.displayPopup('Please Complete your Signature before Accepting');
                    return;
                }
                var png = this.$refs.signature.save();
                console.log(png);

                axios.post('/index/savesignature', {signature : png},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.$stopProgressBar(this.parentrefs);
                            this.organization.Params.Signature = response.data.file;
                            this.newSignature = false;
                            this.$refs.signature.clear();
                            this.saveOrganization();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                });
            },
            clearSignature(){
                this.newSignature = true;
            },
            undoSignature(){
                this.$refs.signature.undo();
            },
            cancelSignature () {
                this.newSignature = false;
            },
			getInitialData () {
				var tables = ['StateProvinceCodes','OrganizationTypes','Programs','InvoiceSettings'];
				axios.post('/index/tableData', {'tables': tables },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let value of tables) {
								this[value] = response.data[value];
							}
							this.getCustomTexts('contractheader');
							this.getCustomTexts('contracttandc');
							this.getAllOrganizations();
						} else {
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
				});
			},
			getAllOrganizations() {
				axios.post('/organizations/getall', {},
				)
                .then(response => {
                    if (response.data.result == 'success') {
						this.Organizations = response.data.Organizations;
						if (!this.OrganizationID && this.Organizations.length > 0) {
							if (!this.componentIDs.OrganizationID) {
								this.OrganizationID = this.Organizations[0]['OrganizationID'];
							}
						}
						this.AssignedPrograms = [];
						for (let i = 0; i < this.Organizations.length; i++) {
							this.AssignedPrograms.push(this.Organizations[i].ProgramID);
						}
						this.$stopProgressBar(this.parentrefs);
						this.getOrganization () ;
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getOrganization () {
				let self = this;
				this.programcontacts = [];
				if (this.OrganizationID) {
					axios.post('/organizations/get', {'OrganizationID' : this.OrganizationID},
					)
	                .then(response => {
	                    if (response.data.result == 'success') {
		                    self.$store.dispatch('setID', {'OrganizationID': self.OrganizationID});
							this.organization = response.data.organization;
		                    this.Rentals = (response.data.Rentals ? response.data.Rentals : []);
                            this.Contracts = (response.data.Contracts ? response.data.Contracts : []);
		                    this.Invoices = (response.data.Invoices ? response.data.Invoices : []);
		                    this.programcontacts = (response.data.programcontacts ? response.data.programcontacts : []);
                            this.$stopProgressBar(this.parentrefs);
	                    } else {
	                        this.$failProgressBar(this.parentrefs);
	                    }
		                this.loading = false;
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
						this.loading = false;
	                });
                } else {
                    this.organization = {'OrganizationID' : 0, Params: {AddressLine1:"", Contacts:[]}};
					this.loading = false;
					this.activeTab = 'details';
                }
			},
			saveOrganization () {
				this.$refs['organizationsForm'].validate((valid) => {
                    if (valid) {
                    	if (!this.isDuplicate ()) {
		                    if (this.currentStatus == STATUS_SAVING) {
								return true;
							}
		                    for (let i = 0; i < this.organization.Params.Contacts.length; i++) {
		                    	if (this.organization.Params.Contacts[i]['ContactName'] == '' || this.organization.Params.Contacts[i]['ContactEmailAddress'] == '') {
				                    this.displayPopup ('For each contact a name and email address must be entered.');
				                    return true;
			                    }
		                    }
							this.currentStatus = STATUS_SAVING;
							this.$startProgressBar(this.parentrefs);

							axios.post('/organizations/save', {organization : this.organization, programcontacts : this.programcontacts}
							)
							.then(response => {
								if (response.data.result == 'success') {
									this.currentStatus = STATUS_INITIAL;
									if (response.data.organization) {
										this.organization = response.data.organization;
									}

									if (response.data.OrganizationID) {
										this.Organizations.push({OrganizationID : response.data.OrganizationID, OrganizationName : this.organization.OrganizationName, ProgramID : this.organization.ProgramID});
										this.OrganizationID = response.data.OrganizationID;
										this.organization.OrganizationID = response.data.OrganizationID;
									} else {
										for (let i = 0; i < this.Organizations.length; i++) {
											if (this.Organizations[i].OrganizationID == this.organization.OrganizationID) {
												this.Organizations[i].OrganizationName = this.organization.OrganizationName;
												this.Organizations[i].ProgramID = this.organization.ProgramID;
												break;
											}
										}
									}
									this.AssignedPrograms = [];
									for (let i = 0; i < this.Organizations.length; i++) {
										this.AssignedPrograms.push(this.Organizations[i].ProgramID);
									}
									this.$stopProgressBar(this.parentrefs);
									if (!response.data.message) {
										this.$notify({
											title: 'Success',
											message: 'Organization has been saved.',
											type: 'success',
											duration: 1500,
											showClose: false
										});
									} else {
										this.$notify({
											title: 'Warning',
											message: "Organization has been saved. " + (response.data.message ? '- ' + response.data.message : ''),
											type: 'warning',
											duration: 3500,
											showClose: false
										});
									}
								} else {
									this.currentStatus = STATUS_FAILED;
									this.displayPopup (response.data.message);
									this.$failProgressBar(this.parentrefs);
								}
							}).catch(response => {
								this.currentStatus = STATUS_FAILED;
								this.$failProgressBar(this.parentrefs);
							});
	                    } else {
		                    this.displayPopup ('This Organization Name Already Exists.');
	                    }
					}
                });
			},
			isDuplicate () {
				for (let i = 0; i < this.Organizations.length; i++) {
					if (this.Organizations[i].OrganizationID != this.organization.OrganizationID && this.Organizations[i].OrganizationName.toLowerCase() == this.organization.OrganizationName.toLowerCase().trim()) {
						return true;
					}
				}
				return false;
			},
			cnfmDeleteOrganization() {
				this.$confirm('This will permanently delete this Organization. Continue?', 'Warning', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}).then(() => {
					this.deleteOrganization();
				}).catch(() => {
				});
			},
			deleteOrganization () {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				let self = this;
				axios.post('/organizations/delete', {'OrganizationID': this.OrganizationID}
				)
				.then(response => {
					if (response.data.result == 'success') {
						this.currentStatus = STATUS_INITIAL;

						this.$stopProgressBar(this.parentrefs);
						this.$notify({
							title: 'Success',
							message: "Organization has been deleted",
							type: 'success',
							duration: 1500,
							showClose: false
						});
						self.$store.dispatch('setID', {'OrganizationID': ''});
		                this.OrganizationID = '';
		                this.getAllOrganizations();

					} else {
						this.currentStatus = STATUS_FAILED;
						this.displayPopup (response.data.message);
						this.$failProgressBar(this.parentrefs);
					}
				}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
			emailInvoice(row) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				if (this.EmailSystemText && this.EmailSystemText.SystemText) {
					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
					axios.post('/finance/emailinvoice', {InvoiceID: row.InvoiceID, SystemText : this.EmailSystemText}
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$stopProgressBar(this.parentrefs);

								this.$notify({
									title: 'Success',
									message: (response.data.message ? response.data.message : "Invoice has been emailed to the contact on file."),
									type: 'success',
									duration: 4000,
									showClose: true
								});

							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup (response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
						}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
					});
				} else {
					this.displayPopup('Please select an Email Template to send the Invoice with');
				}
			},
            emailContract(row) {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
				if (this.ContractSystemText && this.ContractSystemText.SystemText) {
					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
					axios.post('/organizations/emailcontract', {ContractID: row.ContractID, SystemText : this.ContractSystemText}
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$stopProgressBar(this.parentrefs);

								this.$notify({
									title: 'Success',
									message: (response.data.message ? response.data.message : "Contract has been emailed to the contact on file."),
									type: 'success',
									duration: 4000,
									showClose: true
								});
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup (response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
						}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
					});
				} else {
					this.displayPopup('Please select an Email Template to send the Invoice with');
				}
            },
            cnfmDeleteContract(row) {
                this.$confirm('This will permanently delete this Contract. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deleteContract(row);
                }).catch(() => {
                });
            },

            deleteContract(row) {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                axios.post('/organizations/deletecontract', {OrganizationID : this.OrganizationID, ContractID: row.ContractID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_INITIAL;
                            this.$stopProgressBar(this.parentrefs);
                            this.Contracts = (response.data.Contracts ? response.data.Contracts : []);
							if (response.data.Rentals) {
								this.Rentals = response.data.Rentals;
							}
                            this.$notify({
                                title: 'Success',
                                message: (response.data.message ? response.data.message : "Contract has been deleted."),
                                type: 'success',
                                duration: 4000,
                                showClose: true
                            });
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.displayPopup (response.data.message);
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                });
            },
            viewContract() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (this.rentalsSelected.length > 0) {
                    this.$store.dispatch('setID', {'OrganizationInvoiceSettingID': this.InvoiceSettingID});
                    this.$store.dispatch('setID', {'OrganizationContractHeader': this.ContractHeader});
                    this.$store.dispatch('setID', {'OrganizationContractTandCs': this.ContractTandCs});
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);
                    axios.post('/organizations/viewContract', {
                            OrganizationID: this.OrganizationID,
                            Rentals: this.rentalsSelected,
                            InvoiceSettingID: this.InvoiceSettingID,
                            ContractHeader : this.ContractHeader,
                            ContractTandCs : this.ContractTandCs
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.currentStatus = STATUS_INITIAL;
                                this.$stopProgressBar(this.parentrefs);
                                if (response.data.contractFile) {
                                    var contractFile = response.data.contractFile;

                                    var link = document.createElement('a');
                                    link.href = contractFile;
                                    link.click();
                                } else {
                                    this.displayPopup('Contract could not be viewed');
                                }
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup(response.data.message);
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.displayPopup('At least one Rental must be selected to view a Contract');
                }
            },
            generateContract() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (this.rentalsSelected.length > 0) {
                    this.$store.dispatch('setID', {'OrganizationInvoiceSettingID': this.InvoiceSettingID});
                    this.$store.dispatch('setID', {'OrganizationContractHeader': this.ContractHeader});
                    this.$store.dispatch('setID', {'OrganizationContractTandCs': this.ContractTandCs});
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);
                    axios.post('/organizations/generateContract', {
                        OrganizationID: this.OrganizationID,
                        Rentals: this.rentalsSelected,
                        InvoiceSettingID: this.InvoiceSettingID,
                        ContractHeader : this.ContractHeader,
                        ContractTandCs : this.ContractTandCs
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.currentStatus = STATUS_INITIAL;
                                this.$stopProgressBar(this.parentrefs);
                                if (response.data.Contracts) {
                                    this.Contracts = response.data.Contracts;
                                }
								if (response.data.Rentals) {
                                    this.Rentals = response.data.Rentals;
                                }
								this.$notify({
									title: 'Success',
									message: "Contract has been created and is in the list of Contracts.",
									type: 'success',
									duration: 3000,
									showClose: true
                            	});
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup(response.data.message);
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.displayPopup('At least one Rental must be selected to create a Contract');
                }
            },
			downloadContract (row) {
				var link = document.createElement('a');
				link.href = row.ContractPDF;
				link.click();
			},
			downloadInvoice(row) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/finance/getinvoice', {InvoiceID: row.InvoiceID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							this.$stopProgressBar(this.parentrefs);
							if (response.data.invoiceFile) {
								var invoiceFile = response.data.invoiceFile;

								var link = document.createElement('a');
								link.href = invoiceFile;
								link.click();
							} else {
								this.displayPopup ('Invoice could not be created');
							}
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
			displayPopup (message) {
				this.$alert(message, {
					type: 'error',
					confirmButtonText: 'OK',
				});
	        }
		},
		mounted() {
            if (this.$route.params.organizationid) {
                this.OrganizationID = this.$route.params.organizationid;
                this.activeTab = 'rentals';
            } else if (this.componentIDs.OrganizationID) {
                this.OrganizationID = this.componentIDs.OrganizationID;
            }
            if (this.componentIDs.OrganizationInvoiceSettingID) {
                this.InvoiceSettingID = this.componentIDs.OrganizationInvoiceSettingID;
            }
            if (this.componentIDs.OrganizationContractHeader) {
                this.ContractHeader = this.componentIDs.OrganizationContractHeader;
            }
            if (this.componentIDs.OrganizationContractTandCs) {
                this.ContractTandCs = this.componentIDs.OrganizationContractTandCs;
            }

			this.reset(false);
		},
	}
</script>
<style>
	#organizations .color input, #organizations .color .el-select__caret {
		color: #FFFFFF;
	}
	#organizations .color.White input {
		color: #606266;
	}
	#organizations .White .el-input__wrapper { background-color: #FFFFFF; }
	#organizations .Red .el-input__wrapper { background-color: #F44336; }
	#organizations .Pink .el-input__wrapper { background-color: #E91E63; }
	#organizations .Purple .el-input__wrapper { background-color: #9C27B0; }
	#organizations .Deep_Purple .el-input__wrapper { background-color: #673AB7; }
	#organizations .Indigo .el-input__wrapper { background-color: #3F51B5; }
	#organizations .Blue .el-input__wrapper { background-color: #2196F3; }
	#organizations .Highlight_Color .el-input__wrapper { background-color: #1976d2; }
	#organizations .Light_Blue .el-input__wrapper { background-color:  #03A9F4; }
	#organizations .Cyan .el-input__wrapper { background-color:  #00BCD4; }
	#organizations .Teal .el-input__wrapper { background-color:  #009688; }
	#organizations .Green .el-input__wrapper { background-color:  #4CAF50; }
	#organizations .Light_Green .el-input__wrapper { background-color:  #8BC34A; }
	#organizations .Lime .el-input__wrapper { background-color:  #CDDC39; }
	#organizations .Yellow .el-input__wrapper { background-color:  #FFEB3B; }
	#organizations .Amber .el-input__wrapper { background-color:  #FFC107; }
	#organizations .Orange .el-input__wrapper { background-color:  #FF9800; }
	#organizations .Deep_Orange .el-input__wrapper { background-color:  #FF5722; }
	#organizations .Brown .el-input__wrapper { background-color:  #795548; }
	#organizations .Blue_Gray .el-input__wrapper { background-color:  #607D8B; }
	#organizations .Gray .el-input__wrapper { background-color:  #9E9E9E; }
	#organizations .Black .el-input__wrapper { background-color:  #000000; }

	#organizations .el-table .is-right .cell {
		padding-right: 20px;
	}
	#organizations .el-table__column-filter-trigger i {
		color: #fff;
		font-weight: 800;
	}
	#organizations .el-table th>.cell.highlight {
		color: #fff;
		font-weight: 800;
	}
    #organizations .el-date-editor .el-range-separator {
        width: 30px;
    }
	#organizations .rentals label {
		margin-bottom: 0px;
		margin-top: 10px;
	}
</style>

<style scoped>

    #organizations .form-horizontal {
        width: 100%;
    }
    #organizations .card {
		padding-top: 0px;
		padding-bottom: 0px;
	}
    .labelDiv label {
        font-size: 14px;
        color: #606266;
        vertical-align: top;
        margin-top: 4px;
    }
	.labelDiv {
		padding: 10px;
		clearSignature:both;
	}
	.labelDiv.odd {
		background-color: #f7f7f7;
	}
	.postalCode .el-input {
		width: 120px;
	}
	#organizations .contactDetails {
		width: 100%;
		text-align: left;
		padding: 5px;
		margin: 1px 0px;
		height: 33px;
		border: 1px solid #DCDFE6;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}
	#organizations h5 {
		float:left;
		font-weight: 600;
		margin-right: 15px;
		font-size: 16px;
		line-height: 30px;
	}
	.row.header {
		font-weight: 800;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		height: 50px;
	}
	.odd {
		background-color: #efefef;
	}
	.detailheader {
		border-bottom: 1px solid #e0e0e0;
	}
	.even {
		background-color: #f5f5f5;
	}
	.danger {
		color: #DC3545;
	}
	.emailsuccess {
		color: #4CAF50;
	}
	.pastDue {
		font-weight: 800;
		color: #DC3545;
	}
	a i {
		font-size: 20px;
	}
    .signature {
        border-bottom: 2px solid #cccccc;
    }
    .signatureLine {
        font-size: 10px;
        color: #999999;
        text-align: center;
        width: 600px;
    }
    .signatureX {
        position:absolute;
        top: 177px;
        left: 10px;
        font-size: 16px;
        color: #999999;
        font-weight: 800;
    }
    #organizations .el-date-editor .el-range-separator {
        width: 30px;
    }

</style>
