<template>
	<div id="financedashboard" v-loading="loading">
		<div class="row col-12">
			<FinanceDropdowns @dropdownsChange="dropdownsChange" :config="config" :parentrefs="parentrefs" :calling="(isDashboard ? 'FinanceDashboard' : 'Statements')" />
		</div>
		<template v-if="isDashboard">
			<div class="statementTable mt-4">
				<div class="row heading bg-primary" style="border-bottom: 0px;">
					<div class="col-7">
						SUMMARY
					</div>
					<div class="col-5" style="text-align: right;">
						AMOUNT
					</div>
				</div>
				<div class="row total mt-1">
					<div class="col-7">Opening  Balance</div>
					<div class="col-5" v-html="formatPrice(OpeningBalance)" style="text-align: right;"></div>
				</div>
				<div class="row item mt-1" style="padding-left: 10px;">
					<div class="col-7">Amounts Invoiced</div>
					<div class="col-5" v-html="formatPrice(AmountsInvoiced)" style="text-align: right;"></div>
				</div>
				<div class="row item mt-1" style="padding-left: 10px;">
					<div class="col-7">Less: Payments</div>
					<div class="col-5" v-html="formatPrice(Payments)" style="text-align: right;"></div>
				</div>
				<div class="row item mt-1" style="padding-left: 10px;">
					<div class="col-7">Adjustments</div>
					<div class="col-5" v-html="formatPrice(Adjustments)" style="text-align: right;"></div>
				</div>
				<hr style="width: 110px;float: right;margin-bottom: 5px;margin-top: 5px;">
				<div class="row final mt-1 mb-4" style="font-weight: 800;">
					<div class="col-7 balance">Current Balance</div>
					<div class="col-5 balance" v-html="formatPrice(ClosingBalance)" style="text-align: right;"></div>
				</div>
			</div>
			<div class="mt-4 mb-2">
				<el-radio-group v-model="TotalsOrDetails" @change="changedTotalsOrDetails">
					<el-radio-button label="Totals">Show Totals</el-radio-button>
					<el-radio-button label="Details">Show Details</el-radio-button>
				</el-radio-group>
			</div>
			<div v-if="details.length > 0" :disabled="!OrganizationID || !InvoiceSettingID" class="mt-2 mb-2"><el-button type="success" class="btn-success" @click="downloadStatement" ><i class="fas fa-file-download"></i>&nbsp;Download Statement</el-button></div>
		</template>
		<template v-else>
			<div class="row">
				<el-divider border-style="dashed" />
				<template v-if="BillingSeasons == 'o'">
					<div style="font-weight: 600;">Enter Start and End Date for Statement</div>
					<el-date-picker
						v-model="dateRange"
						type="daterange"
						:clearable="false"
						unlink-panels
						style="max-width: 400px;margin-left: 10px;"
						range-separator="To"
						start-placeholder="Start date"
						end-placeholder="End date"
						format="MMM D, YYYY"
						value-format="YYYY-MM-DD">
					</el-date-picker>
				</template>
				<div v-if="BillingSeasons != 'o' || dateRange[0]" class="mt-2 mb-2"><el-button type="success" class="btn-success" @click="generateStatements" :disabled="!OrganizationID && !InvoiceSettingID"><i class="fas fa-play"></i>&nbsp;Generate Statement(s)</el-button></div>
				<template v-if="details.length > 0">
					<div class="col-12 mt-2">
						<div><strong>Complete these fields when emailing Statements.</strong></div>
						<EmailTemplate calling="financestatement" v-model="SystemText" BillingType="" :config="config" width="100%" />
					</div>
					<div v-if="SystemText.SystemText" class="col-12 mt-2 mb-2">
						<div>
							<el-button class="btn-warning" type="warning" @click="emailStatements" :disabled="!SystemText">
								<i class="far fa-envelope"></i> <span> Email Statements</span>
							</el-button>
						</div>
					</div>
				</template>
			</div>
		</template>
		<template v-if="TotalsOrDetails == 'Totals'">
			<h5 class="mt-4">Invoices</h5>
			<div class="row mt-1">
				<div class="col-12">
					<el-table
							:data="invoices"
							stripe border
							empty-text="No Invoices"
							size="small"
							style="width: 100%">
						<el-table-column type="expand">
							<template #default="props">
								<span v-if="props.row.InvoiceType == 'TR'">
									<div class="row detailheader">
										<div class="col-1">No.</div>
										<div class="col-3">Team</div>
										<div class="col-2" style="text-align:center;">Games</div>
										<div class="col-3">Notes</div>
										<div class="col-3" style="text-align:center;">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
										<div class="col-1" style="line-height: 35px;" v-html="(index+1)"></div>
										<div class="col-3" style="line-height: 35px;">{{item.TeamName}}</div>
										<div class="col-2" style="line-height: 35px;text-align:center;">{{item.RegisteredGames}}</div>
										<div class="col-3" style="line-height: 35px;">{{item.RegisteredNotes}}</div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="formatPrice((item.EventType == 'Balance' ? item.Balance : item.DetailAmount))"></div>
									</div>
								</span>
								<span v-if="props.row.InvoiceType == 'IU'">
									<div class="row detailheader">
										<div class="col-2">Date</div>
										<div class="col-2">Time</div>
										<div class="col-3">Location</div>
										<div class="col-3">Description</div>
										<div class="col-2">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
										<div style="line-height: 35px;" class="col-2">{{item.StartDate}}</div>
										<div style="line-height: 35px;" class="col-2" v-html="(item.type != 'e' ? '' : (item.StartTime ? item.StartTime + ' - ' + item.EndTime : 'All Day'))"></div>
										<div style="line-height: 35px;" class="col-3">{{item.LocationName}}</div>
										<div style="line-height: 35px;" class="col-3">{{(item.type == 'c' ? 'Credit - ' : '') + item.Title}}</div>
										<div style="line-height: 35px;" class="col-2" v-html="formatPrice((item.type == 'x' && item.EventType == 'Balance' ? item.Balance : item.DetailAmount))"></div>
									</div>
								</span>
								<span v-if="props.row.InvoiceType == 'BG'">
									<div class="row detailheader">
										<div class="col-1">No.</div>
										<div class="col-3">Desription</div>
										<div class="col-2" style="text-align:center;">Qty</div>
										<div class="col-3" style="text-align:center;">Rate</div>
										<div class="col-3" style="text-align:center;">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
										<div class="col-1" style="line-height: 35px;" v-html="(index+1)"></div>
										<div class="col-3" style="line-height: 35px;">{{item.TeamName}}</div>
										<div class="col-2" style="line-height: 35px;text-align:center;">{{(item.EventType == 'Balance' ? '' : (item.ToBill == 0 ? 'ALL' : item.ToBill))}}</div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="(item.EventType == 'Balance' ? '' : formatPrice(item.DefaultGameFee))"></div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="formatPrice((item.EventType == 'Balance' ? item.Balance : item.DetailAmount))"></div>
									</div>
								</span>
								<span v-if="props.row.InvoiceType == 'MI'">
									<div class="row detailheader">
										<div class="col-1">No.</div>
										<div class="col-8">Description</div>
										<div class="col-3" style="text-align:center;">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0, pastDue : item.TeamName == 'Past Due'}">
										<div class="col-1" style="line-height: 35px;" v-html="(index+1)"></div>
										<div class="col-8" style="line-height: 35px;" v-html="(item.TeamID ? item.TeamName + ' - ' : '') + item.Description"></div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="formatPrice(item.DetailAmount)"></div>
									</div>
								</span>
							</template>
						</el-table-column>
						<el-table-column
								prop="InvoiceDate"
								label="Date"
								width="100px"
								align="center"
						>
						</el-table-column>
						<el-table-column
								v-if="LeaguesAvailable"
								prop="LeagueName"
								label="League"
								min-width="100">
						</el-table-column>
						<el-table-column
								prop="Description"
								label="Description"
								min-width="200px"
								>
							<template #default="scope">
								<div v-html="(scope.row.LeagueChar ? scope.row.LeagueChar : '') + scope.row.InvoiceNumber + ' - ' + scope.row.Description"></div>
							</template>
						</el-table-column>
						<el-table-column
								prop="Amount"
								label="Amount"
								align="right"
								width="130px">
							<template #default="scope">
								<div v-html="formatPrice(scope.row.Amount)"></div>
							</template>
						</el-table-column>
						<el-table-column
								prop=""
								label="Options"
								align="center"
								width="80px">
							<template #default="scope">
								<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="downloadInvoice(scope.row)">
									<div class="v-btn__content">
										<i class="fas fa-2x fa-file-download"></i>
									</div>
								</button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<h5 class="mt-4">Payments</h5>
			<div class="row mt-1">
				<div class="col-12">
					<el-table
							:data="invoicepayments"
							stripe border
							empty-text="No Payments"
							size="small"
							max-height="500"
							style="width: 100%">
						<el-table-column
								prop="PaymentDate"
								label="Date"
								width="150"
								align="center">
						</el-table-column>
						<el-table-column
								v-if="LeaguesAvailable"
								prop="LeagueName"
								label="League"
								min-width="100">
						</el-table-column>
						<el-table-column
								prop="Description"
								label="Description"
								min-width="200">
						</el-table-column>
						<el-table-column
								prop="PaymentAmount"
								label="Amount"
								align="right"
								width="130px">
							<template #default="scope">
								<div v-html="formatPrice(scope.row.PaymentAmount)"></div>
							</template>
						</el-table-column>
						<el-table-column
								prop=""
								label=""
								align="center"
								width="80px">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<h5 class="mt-4">Balance Items & Adjustments</h5>
			<div class="row mt-1 mb-4">
				<div class="col-12">
					<el-table
							:data="invoicecredits"
							stripe border
							empty-text="No Balance Items"
							size="small"
							max-height="500"
							style="width: 100%">
						<el-table-column
								prop="CreditDate"
								label="Date"
								width="150"
								align="center">
						</el-table-column>
						<el-table-column
								v-if="LeaguesAvailable"
								prop="LeagueName"
								label="League"
								min-width="100">
						</el-table-column>
						<el-table-column
								prop="Description"
								label="Description"
								min-width="200">
						</el-table-column>
						<el-table-column
								prop="CreditAmount"
								label="Amount"
								align="right"
								width="130px">
							<template #default="scope">
								<div v-html="formatPrice(scope.row.CreditAmount)"></div>
							</template>
						</el-table-column>
						<el-table-column
								prop=""
								label=""
								align="center"
								width="80px">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<template v-if="contracts.length > 0" >
				<h5 class="mt-4">Contracts</h5>
				<div class="row mt-1">
					<div class="col-12">
						<el-table
							:data="contracts"
							key="contractstable"
							max-height="500"
							size="small"
							stripe border
							style="width: 100%">
							<el-table-column
								prop="ContractDate"
								label="Date"
								align="center"
								width="150"
							>
							</el-table-column>
							<el-table-column
								prop="AgreementNumber"
								label="Contract No"
								align="center"
								width="100"
							>
								<template #default="scope">
									<div v-html="'CNT-' + scope.row.AgreementNumber"></div>
								</template>
							</el-table-column>
							<el-table-column
								prop="Description"
								label="Description"
								align="left"
								min-width="150"
							>
							</el-table-column>
							<el-table-column
								prop="Amount"
								label="Hours / Amount"
								width="130"
								align="right"
							>
								<template #default="scope">
									<div v-html="scope.row.Hours + 'Hrs<br>' + formatPrice(scope.row.Amount)"></div>
								</template>
							</el-table-column>
							<el-table-column
								prop=""
								label="Status / Download"
								width="130"
								align="center"
							>
								<template #default="scope">
									<div v-if="scope.row.ContractStatus == 'C'" v-html="'Created'"></div>
									<div v-if="scope.row.ContractStatus == 'A'" v-html="'Active (signed)'"></div>
									<button v-if="scope.row.ContractPDF" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="downloadContract(scope.row)">
										<div class="v-btn__content">
											<i class="fas fa-2x fa-file-download"></i>
										</div>
									</button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</template>
		</template>
		<template v-else-if="details.length > 0">
		<div v-if="isStatements" class="row">
			<div class="col-12">
				<el-checkbox v-model="allSelected" false-label="0" true-label="1" @change="selectAll('n')">Select All</el-checkbox>
				<el-checkbox v-model="allSelectedNonZero" false-label="0" true-label="1" @change="selectAll('y')">Select All non-zero Balanced</el-checkbox>
			</div>
		</div>
		<template v-for="(organization, index) in details">
			<div v-if="isDashboard || allSelectedNonZero != '1' || organization.currentbalance > 0" class="row mt-1 mb-4">
				<h5 v-if="isDashboard" class="mt-4">Details</h5>
				<div v-else class="organizationCheckbox">
					<el-checkbox v-model="organization.selected" false-label="0" true-label="1" ><h5 class="mt-4" style="font-weight: 800;" v-html="organization.organization.OrganizationName"></h5></el-checkbox>
				</div>
				<div class="col-12">
					<el-table
							:data="organization.data"
							stripe border
							empty-text="No Details"
							size="small"
							max-height="1000"
							:row-class-name="tableRowClassName"
							:show-summary="true"
							:summary-method="getSummaries"
							style="width: 100%">
						<el-table-column
								prop="Date"
								label="Date"
								width="120"
								sortable
								align="center">
						</el-table-column>
						<el-table-column
								v-if="LeaguesAvailable"
								prop="LeagueName"
								label="League"
								sortable
								min-width="100">
						</el-table-column>
						<el-table-column
								prop="DetailType"
								label="Type"
								sortable
								min-width="120">
						</el-table-column>
						<el-table-column
								prop="Description"
								label="Description"
								min-width="200">
							<template #default="scope">
								<div v-html="scope.row.Description"></div>
								<div v-if="scope.row.Type == 'I'" class="small" v-html="'Invoice #: ' + (scope.row.LeagueChar ? scope.row.LeagueChar : '') + scope.row.InvoiceNumber"></div>
							</template>
						</el-table-column>
						<el-table-column
								prop="Amount"
								label="Amount"
								align="right"
								width="130px">
							<template #default="scope">
								<div v-html="formatPrice(scope.row.Amount)"></div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</template>
		</template>
		<div v-if="isStatements" class="row mt-4 mb-4" >
			<div v-if="details.length > 0" class="col-12 text-center">
				<el-button type="success" @click="downloadStatement" >
					<i class="fas fa-file-download"></i> <span> Download All Statements</span>
				</el-button>
				<el-button type="warning" style="margin-left: 20px;" @click="emailStatements" :disabled="!SystemText">
					<i class="far fa-envelope"></i> <span> Email Statements</span>
				</el-button>
			</div>
		</div>
		<div v-else class="row mt-4 mb-4" >
			&nbsp;
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
    import Util from "../assets/js/util";
	import FinanceDropdowns from './global/FinanceDropdowns.vue';
	import EmailTemplate from './global/EmailTemplate.vue';

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
	export default {
		data () {
			return {
                currentStatus : STATUS_INITIAL,
				TotalsOrDetails : 'Totals',
                loading : false,
				invoices: [],
				invoicecredits : [],
				invoicepayments : [],
                OrganizationID: '',
                SeasonID: '',
				invoicedates: [],
				OpeningBalance: 0,
				AmountsInvoiced : 0,
				Payments : 0,
				Adjustments : 0,
				ClosingBalance : 0,
                InvoiceSettingID : '',
                LeagueID : '',
                TeamID : '',
				details : [],
				LeagueIDs : [],
				Leagues : [],
				InvoiceSettings: [],
				SystemText : '',
				allSelected: 0,
				allSelectedNonZero : 0,
				dateRange : [],
				BillingSeasons : 's',
				contracts : [],
			}
		},
		name: 'FinanceDashboard',
		components: {
            FinanceDropdowns,
			EmailTemplate
        },
        props:["config", "parentrefs"],
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
			modifyAccess () {
				return this.$modifyAccess();
			},
			createAccess () {
				return this.$modifyAccess();
			},
			ProgramIDAccess () {
				return this.$ProgramIDAccess();
			},

            todaysDate () {
                var now = new Date();
                var y = now.getFullYear();
                var m = now.getMonth() + 1;
                var d = now.getDate();
                var mm = m < 10 ? '0' + m : m;
                var dd = d < 10 ? '0' + d : d;
                return '' + y + mm + dd;
            },
			LeaguesAvailable () {
				if (this.LeagueIDs.length > 0) {
					return true;
				} else {
					return false;
				}
			},
			isDashboard () {
                return (this.$route.fullPath.indexOf('dashboard') === -1  ? false : true);
            },
			isStatements () {
                return (this.$route.fullPath.indexOf('statement') === -1  ? false : true);
            },
			isOngoing () {

			},
		},
		methods: {
			formatPrice(value) {
                return Util.formatPrice(this.config, value , '0');
			},
			tableRowClassName({row, rowIndex}) {
				if (row.Type == 'P' || row.Type == 'PL') {
					return 'payment-row';
                } else if (row.Type == 'CR') {
					return 'adjustment-row';
                } else {
					return '';
				}
			},
			dropdownsChange (OrganizationID, LeagueID, InvoiceSettingID, SeasonID, TeamID, LeagueIDs, Leagues, InvoiceSettings, BillingSeasons) {
				this.OrganizationID = OrganizationID;
                this.LeagueID = LeagueID;
				this.LeagueIDs = LeagueIDs;
				this.Leagues = Leagues;
				this.InvoiceSettings = InvoiceSettings;
				this.InvoiceSettingID = InvoiceSettingID;
				this.SeasonID = SeasonID;
                this.TeamID = TeamID;
				this.BillingSeasons = BillingSeasons;
				if (BillingSeasons == 's' || this.isDashboard) {
					this.dateRange = [];
				}
				if (this.isDashboard) {
					this.getDashboardDetails();
				} else {
					this.details = [];
				}
			},
			changedTotalsOrDetails () {
				if (this.isDashboard) {
					this.$store.dispatch('setID', {'FDTotalsOrDetails': this.TotalsOrDetails});
				}
			},
			selectAll (nonzero) {
				for (let i = 0; i < this.details.length; i++) {
					if (nonzero == 'y') {
						if (this.allSelectedNonZero) {
							this.allSelected = 0;
						}
						if (this.details[i].currentbalance > 0) {
							this.details[i].selected = this.allSelectedNonZero;
						} else {
							this.details[i].selected = 0;
						}
					} else {
						this.details[i].selected = this.allSelected;
						if (this.allSelected) {
							this.allSelectedNonZero = 0;
						}
					}
				}
			},
			getSummaries(param) {

				const { columns, data } = param;
				const sums = [];
				if (columns && columns.length > 0) {
					columns.forEach((column, index) => {
						if (index === 0) {
							sums[index] = 'Totals';
							return;
						}
						if (column.property == 'Amount' || index === 1) {
							var values = [];
							for (let i=0; i<data.length; i++) {
								try {
									if (data[i]['Type'] == 'P') {
										values.push(-Number(data[i][column.property].toString().replace(/[$,)]/g, '').replace(/[(]/g, '-')));
									} else {
										values.push(Number(data[i][column.property].toString().replace(/[$,)]/g, '').replace(/[(]/g, '-')));
									}
								}
								catch(err) {
									values.push(0);
								}
							}
							if (index === 1) {
								sums[index] = values.length;
								return;
							}
							if (!values.every(value => isNaN(value))) {
								sums[index] = this.formatPrice(values.reduce((prev, curr) => {
									const value = Number(curr);
									if (!isNaN(value)) {
										return prev + curr;
									} else {
										return prev;
									}
								}, 0));
							}
						}
					});

					return sums;
				}
			},
			generateStatements () {
				this.details = [];
				let self = this;
				this.loading = true;
				axios.post('/finance/getdashboarddetails', {
						OrganizationID: this.OrganizationID,
						LeagueID: this.LeagueID,
						SeasonID: this.SeasonID,
						TeamID: this.TeamID,
						InvoiceSettingID: this.InvoiceSettingID,
						ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : ''),
						ActiveOnly: 'y',
						DateRange : this.dateRange
					}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.details = (response.data.details ? response.data.details : []);
							this.loading = false;
						} else {
							this.$failProgressBar(this.parentrefs);
							this.loading = false;
						}
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
					this.loading = false;
				});
			},
			emailStatements () {
				var OrganizationIDs = [];
				for (let i=0; i < this.details.length; i++) {
					if (this.details[i].selected == '1') {
						OrganizationIDs.push(this.details[i].organization.OrganizationID);
					}
				}
				if (OrganizationIDs.length == 0) {
					this.displayPopup ('At least one Organiztion must be selected');
					return false;
				} else if (this.SystemText && this.SystemText.SystemText) {
					this.loading = true;
					//first check that all organizations being email invoices have an contact to 
					//recieve the invoice on file.
					axios.post('/finance/validateemailaddresses', {
							OrganizationIDs: OrganizationIDs,
							SystemText : this.SystemText,
							LeagueID: this.LeagueID,
							SeasonID: this.SeasonID,
							TeamID: this.TeamID,
							InvoiceSettingID: this.InvoiceSettingID,
							ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : ''),
							ActiveOnly: 'y',
							DateRange : this.dateRange
						}
					)
						.then(response => {
							if (response.data.result == 'success') {
								var Leagues = [];
								for (let i=0; i < this.Leagues.length; i++) {
									if (this.LeagueID) {
										if (this.LeagueID == this.Leagues[i].LeagueID ) {
											Leagues.push(this.Leagues[i]);
										}
									} else if (this.Leagues[i].FinanceType == 'F') {
										Leagues.push(this.Leagues[i]);
									}
								}
								axios.post('/finance/emailstatements', {
										OrganizationIDs: OrganizationIDs,
										SystemText : this.SystemText,
										LeagueID: this.LeagueID,
										SeasonID: this.SeasonID,
										TeamID: this.TeamID,
										InvoiceSettingID: this.InvoiceSettingID,
										ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : ''),
										InvoiceSettings: this.InvoiceSettings,
										ActiveOnly: 'y',
										DateRange : this.dateRange
									}
								)
									.then(response => {
										if (response.data.result == 'success') {
											this.loading = false;
											this.$confirm("Statements have been emailed to the contacts on file.", 'Success', {
												confirmButtonText: 'OK',
												type: 'success',
												showClose: false,
												showCancelButton: false
											});
										} else {
											this.$failProgressBar(this.parentrefs);
											this.displayPopup (response.data.message);
											this.loading = false;
										}
									}).catch(response => {
										this.$failProgressBar(this.parentrefs);
										this.loading = false;
								});
							} else {
								this.$failProgressBar(this.parentrefs);
								this.loading = false;
								this.$confirm(response.data.message, 'Missing Emails for the Following Organizations', {
									dangerouslyUseHTMLString: true,
									confirmButtonText: 'OK',
									customClass: 'financedashboard',
									type: 'error',
									showClose: false,
									showCancelButton: false
								});
								
							}
						}).catch(response => {
							this.$failProgressBar(this.parentrefs);
							this.loading = false;
					});
				} else {
					this.displayPopup('Please select an Email Template to send the Statement with');
				}
			},
            getDashboardDetails () {
				this.OpeningBalance = 0;
				this.AmountsInvoiced = 0;
				this.Payments = 0;
				this.Adjustments = 0;
				this.ClosingBalance = 0;
				this.invoices = [];
				this.invoicecredits = [];
				this.invoicepayments = [];
				this.details = [];
				if (this.OrganizationID && (this.SeasonID || this.InvoiceSettingID)) {
					this.loading = true;
                    axios.post('/finance/getdashboarddetails', {
                            OrganizationID: this.OrganizationID,
                            LeagueID: this.LeagueID,
                            SeasonID: this.SeasonID,
                            TeamID: this.TeamID,
                            InvoiceSettingID: this.InvoiceSettingID,
                            ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : ''),
                            ActiveOnly: 'y',
							DateRange : this.dateRange
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.invoices = (response.data.invoices ? response.data.invoices : []);
								this.invoicecredits = (response.data.invoicecredits ? response.data.invoicecredits : []);
								this.invoicepayments = (response.data.invoicepayments ? response.data.invoicepayments : []);
								this.details = (response.data.details ? response.data.details : []);
								this.contracts = (response.data.contracts ? response.data.contracts : []);
								this.calcAmounts();
								this.loading = false;
                            } else {
                                this.$failProgressBar(this.parentrefs);
                                this.loading = false;
                            }
                        }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                    });
                } else {
					this.loading = false;
				}
			},
			calcAmounts() {
                this.AmountsInvoiced = 0;
                this.Adjustments = 0;
                this.Payments = 0;
                this.OpeningBalance = (isNaN(this.details[0].balance) ? 0 : this.details[0].balance);
				if (this.invoices) {
                    for (let i = 0; i < this.invoices.length; i++) {
                        this.AmountsInvoiced += parseFloat(this.invoices[i]['Amount']);
                    }
                } else {
                    this.AmountsInvoiced = 0;
                }
				if (this.invoicecredits) {
                    for (let i=0; i < this.invoicecredits.length; i++) {
                        if (this.invoicecredits[i]['FromInvoiceID'] == 0 && this.invoicecredits[i]['CreditBalance'] != 'y') {
                            //Did not get created from an invoice or the opening balance, so include
                            this.Adjustments += parseFloat(this.invoicecredits[i]['CreditAmount']);
                        }
                    }
                } else {
                    this.Adjustments = 0;
                }
                if (this.invoicepayments) {
                    for (let i=0; i < this.invoicepayments.length; i++) {
                        this.Payments += parseFloat(this.invoicepayments[i]['PaymentAmount']);
                    }
                } else {
                    this.Payments = 0;
                }

                this.ClosingBalance = this.OpeningBalance + this.AmountsInvoiced - this.Payments + this.Adjustments;

			},
			downloadInvoice(row) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/finance/getinvoice', {InvoiceID: row.InvoiceID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							this.$stopProgressBar(this.parentrefs);
							if (response.data.invoiceFile) {
								var invoiceFile = response.data.invoiceFile;

								var link = document.createElement('a');
								link.href = invoiceFile;
								link.click();
							} else {
								this.displayPopup ('Invoice could not be created');
							}
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
			downloadStatement() {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				var OrganizationIDs = [];
				var Leagues = [];
				if (this.isStatements) {
					for (let i=0; i < this.details.length; i++) {
						if (this.details[i].selected == '1') {
							OrganizationIDs.push(this.details[i].organization.OrganizationID);
						}
					}
					if (OrganizationIDs.length == 0) {
						this.displayPopup ('At least one Organiztion must be selected');
						return false;
					}
				} else {
					for (let i=0; i < this.Leagues.length; i++) {
						if (this.LeagueID) {
							if (this.LeagueID == this.Leagues[i].LeagueID ) {
								Leagues.push(this.Leagues[i]);
							}
						} else if (this.Leagues[i].FinanceType == 'F') {
							Leagues.push(this.Leagues[i]);
						}
					}
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/finance/getstatement', {
							OrganizationID: this.OrganizationID,
							OrganizationIDs: OrganizationIDs,
                            LeagueID: this.LeagueID,
                            SeasonID: this.SeasonID,
                            TeamID: this.TeamID,
                            InvoiceSettingID: this.InvoiceSettingID,
							InvoiceSettings: this.InvoiceSettings,
                            ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : ''),
                            ActiveOnly: 'y',
							DateRange : this.dateRange
                        }
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							this.$stopProgressBar(this.parentrefs);
							if (response.data.statementFile) {
								var statementFile = response.data.statementFile;

								var link = document.createElement('a');
								link.href = statementFile;
								link.click();
							} else {
								this.displayPopup ('Statement could not be created');
							}
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
			downloadContract (row) {
				var link = document.createElement('a');
				link.href = row.ContractPDF;
				link.click();
			},
			displayPopup (message) {
				this.$alert(message, 'Error', {
					confirmButtonText: 'OK',
				});
			}
		},
		mounted() {
			if (this.componentIDs.FDTotalsOrDetails) {
				this.TotalsOrDetails = this.componentIDs.FDTotalsOrDetails;
			}
			if (this.isStatements) {
				this.TotalsOrDetails = 'Details';
			}
		},
	}
</script>

<style>
	#financedashboard .el-table__row {
		font-weight: 800;
	}
	#financedashboard .el-table__column-filter-trigger i {
		color: #fff;
		font-weight: 800;
	}
	#financedashboard .el-table th>.cell.highlight {
		color: #fff;
		font-weight: 800;
	}
	#financedashboard .el-table__footer div.cell {
		font-size: 12px;
		font-weight: 800;
		color: #000;
	}
	#financedashboard .el-table__footer tr td {
		background-color: #dddddd;
	}
	#financedashboard .el-table__row.payment-row td, #financedashboard .el-table--striped .el-table__row--striped.payment-row td {
		background-color: rgb(184, 239, 197);
	}
	#financedashboard .el-table__row.adjustment-row td, #financedashboard .el-table--striped .el-table__row--striped.adjustment-row td {
		background-color: rgb(249, 244, 204);
	}
	#financedashboard .organizationCheckbox .el-checkbox__label {
		margin-bottom: 13px;
		margin-left: 5px;
	}
	div.el-message-box.financedashboard {
    	width: 420px;
	}
</style>
<style scoped>
	.row.header {
		font-weight: 800;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		height: 30px;
	}
	.invoicesTable {
		border: 1px solid #f0f0f0;
	}
	.footer {
	    width: 100%;
	    margin: 10px 0px;
	}
	.odd {
		background-color: #efefef;
	}
	.invoicesTable {
		border: 1px solid #f0f0f0;
		padding: 0px 15px;
	}
	.detailheader {
		border-bottom: 1px solid #e0e0e0;
	}
	.even {
		background-color: #f5f5f5;
	}
	.danger {
		color: #DC3545;
	}
	.success {
		color: #4CAF50;
	}
	.heading {
		font-weight: 800;
        color: #fff;
        font-size: 14px;
		height: 25px;
		margin: 0;
	}
	.row.total, .row.final {
		font-weight: 800;
	}
	.row.final {
		border-bottom: 1px solid #ccc;
	}
	.row {
		margin:0px;
	}
	.heading div {
		line-height: 26px;
	}
	.programLocations:nth-child(odd) {
		background: rgba(0, 0, 0, 0.05);
	}
    .balance {
        font-size: 14px;
        font-weight: 800;
    }
	.small {
		color: #999;
		line-height: 12px;
	}
</style>
