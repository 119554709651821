<template>
	<!-- header header  -->
	<div class="header is_stuck" :class="{'isMobile' : isMobile}" @click="closeNotification">
		<nav class="navbar top-navbar navbar-expand-md navbar-light">
			<!-- Logo -->
			<div class="navbar-header">
				<div v-if="isSuper" class="super">Super User</div>
				<a v-else class="navbar-brand" href="index.html">
					<router-link to="/" class="navbar-brand invisible-xs" exact>
						<img id="small-logo" :src="config.Params.SmallLogo" alt="Logo"/>
						<img id="full-logo" :src="config.Params.MainLogo" alt="MainLogo"/>
					</router-link>
				</a>
			</div>
			<!-- End Logo -->
			<div class="navbar-collapse">
				<sidebar-collapse :isCollapse="isCollapse" @collapse="collapse" ></sidebar-collapse>
                <div v-if="SchedulingServerRunning && navType == 'A'" class="SchedulingServerRunning">
                    <span>
                        <a href="/analyzer/scheduling">Click to Turn off<br>Scheduling Server</a>
                    </span>
                </div>
				<!-- User profile  -->
				<ul class="navbar-nav my-lg-0">
					<!-- Messages -->
					<li		v-if="helpText"
							class="nav-item dropdown"
							:class="{'show': isHelp, '': !isHelp }"
					>
                            <span :class="{helpAvailable : helpText}"
								@click.stop="handleHelp"
								  class="nav-link dropdown-toggle text-muted text-muted"
								  style="cursor:pointer;" id="2"
								  data-toggle="dropdown">
                                <i class="fas fa-question-circle" ></i>
                            </span>
						<div    
								class="dropdown-menu dropdown-menu-right mailbox dropdownhelp animated zoomIn"
								aria-labelledby="2"
								:class="{'show': isHelp, '': !isHelp }"
						>
							<ul>
								<li>
                                    <div class="drop-title">Help <span style="float:right; font-size: 11px;color: #030202;">v{{config.Params.version}}</span></div>
								</li>
								<li>
									<div class="message-center helptext" v-html="(helpText ? helpText : 'No Help Text Available')"></div>
								</li>
							</ul>
						</div>
					</li>
					<!-- End Messages -->
					<!-- Comment -->
					<li
							class="nav-item dropdown"
							:class="{'show': isNotification, '': !isNotification }"
							@click.stop="handleNotification">
                            <span
									class="nav-link dropdown-toggle text-muted text-muted"
									style="cursor:pointer;"
									data-toggle="dropdown"
							>
                                <i class="fas fa-bell" ></i>
                                <div v-if="notifications.length" class="notify">
                                    <span class="heartbit"></span>
                                    <span class="point"></span>
                                </div>
                            </span>
						<div
								class="dropdown-menu dropdown-menu-right mailbox animated zoomIn"
								:class="{'show': isNotification, '': !isNotification }"
								@click.stop="handleNotification"
						>
							<ul>
								<li>
									<div class="drop-title">Notifications</div>
								</li>
								<li>
									<div class="message-center notifications">
										<!-- Message -->
										<span v-for="(item, index) in notifications">
                                            <a href="#" @click.stop="displayNotification(item.NotificationID, item.MessageLink)">
                                                <span v-html="item.MessageIcon"></span>
                                                <div class="mail-contnet">
                                                    <h5><span class="text-primary" v-html="item.MessageTitle"></span></h5> <span class="notification-text" v-html="item.MessageBody"></span>
                                                    <span class="notification-text">{{ item.createdwhen }}</span>
                                                </div>
                                            </a>
                                        </span>
                                        <span v-if="notifications.length == 0" >
                                            <h5><span class="text-primary" v-html="'You have no unread Notifications'"></span></h5>
                                        </span>
									</div>
								</li>
								<li>
									<a class="nav-link text-center" href="/notifications"> <strong>Check all notifications</strong> <i class="fas fa-angle-right" ></i> </a>
								</li>
							</ul>
						</div>
					</li>
					<!-- End Comment -->
					<!-- Profile -->
					<li class="nav-item dropdown nav profile"
						:class="{'show': isUserInfo, '': !isUserInfo }"
						@click.stop="handleUserInfo"
					>
						<a class="nav-link dropdown-toggle text-muted  " href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<div v-if="currentUser.Params.avatar" style="display:inline-block;"><img :src="currentUser.Params.avatar" alt="user" class="profile-pic" /></div>
							<div v-else style="display:inline-block;"><img :src="this.default_avatar" alt="user" class="profile-pic" /><span class="initials" v-html="initials"></span></div>
							<div style="display:inline-block;">
								<span v-if="currentUser.FirstName && !isMobile" style="margin-left: 10px;font-weight:800;" v-html="currentUser.FirstName + ' ' + currentUser.LastName"></span>
							</div>
						</a>
						<div
								class="dropdown-menu dropdown-menu-right animated zoomIn"
								:class="{'show': isUserInfo, '': !isUserInfo }"
						>
							<ul class="dropdown-user">
								<li><router-link to="/profile"><i class="fas fa-user" ></i> Profile</router-link></li>
								<li v-if="Roles.length > 1" @click.stop="changerole"><router-link to=""><i class="fa-solid fa-lock" ></i> Switch Role</router-link></li>
								<li><router-link to="/notifications"><i class="fas fa-bell" ></i> Notifications</router-link></li>
								<li><router-link to="/logout"><i class="fas fa-power-off" ></i> Logout</router-link></li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</nav>
		<modal v-if="showRoleModal" @close="showRoleModal = false" width="400px" :draggable="false" :showClose="true">
			<template v-slot:header>
				<h5 class="modal-title">
					Select a New Role
				</h5>
			</template>
			<template v-slot:body >
				<login-select v-loading="loading" :config="config" :parentrefs="parentrefs" :RoleSelectText="RoleSelectText" :Roles="Roles" :activeRole="activeRole" @roleselected="roleSelected"></login-select>
			</template>
			<template v-slot:footer>
				&nbsp;
			</template>
		</modal>
	</div>
</template>

<script>
	import SidebarCollapse from './SidebarCollapse.vue';
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	import Util from '../../assets/js/util.js';
	import LoginSelect from "../../components/LoginSelect";
	import Modal from "./Modal";

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
	export default{
		data(){
			return{
				currentStatus : STATUS_INITIAL,
				loading : false,
				isOpen: false,
				showRoleModal :  false,
				clickable: true,
				isNotification: false,
				isMessage: false,
				isUserInfo: false,
				notifications: [],
				isHelp: false,
                SchedulingServerRunning : false,
				activeRole : 0,
				responseRole : '',
				Roles : [],
				RoleSelectText : 'Select the League you wish to login to manage',
				tabLinks : [],
    			contentDivs : [],
				tabNames : [],
				nextcall : 30000,
				currentCalls : 0,
			}
		},
		props:["config", "helpText", "navType" ,"parentrefs",  "isCollapse", "page"],
		components:{
			SidebarCollapse,
			LoginSelect,
			Modal
		},
		watch : {
			page (current, prev) {
				this.currentCalls = 0;
			}
		},
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			default_avatar () {
				return require('../../images/default_avatar.png')
			},
			showLogo () {
				if (Util.isMobile()) {
					return false;
				} else {
					return true;
				}
			},
			initials () {
				let name = this.currentUser.FirstName + ' ' + this.currentUser.LastName
				let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

				let initials = [...name.matchAll(rgx)] || [];

				initials = (
					(initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
				).toUpperCase();

				return initials;
			},
			isSuper () {
				return (localStorage.Super == 'y' ? true : false);
			},
			isMobile () {
                return Util.isMobile();
            }
		},
		methods: {
			tabsinit() {
				// Grab the tab links and content divs from the page
				var pretabLinks = JSON.parse(JSON.stringify(this.tabNames));
				if (document.getElementById('helptabs')) {
					var tabListItems = document.getElementById('helptabs').childNodes;
					this.tabNames = [];
					for ( var i = 0; i < tabListItems.length; i++ ) {
						if ( tabListItems[i].nodeName == "LI" ) {
							var tabLink = this.getFirstChildWithTagName( tabListItems[i], 'SPAN' );
							var id = tabLink.getAttribute('ID');
							this.tabLinks[id] = tabLink;
							this.contentDivs[id] = document.getElementById( id + 'content' );
							this.tabNames.push(id);
						}
					}

					var sameTabs = (JSON.stringify(pretabLinks) === JSON.stringify(this.tabNames));
					if (!sameTabs) {
						// Assign onclick events to the tab links, and
						// highlight the first tab
						var i = 0;

						for ( var id in this.tabLinks ) {
							this.tabLinks[id].onclick = this.showTab;
							this.tabLinks[id].onfocus = function() { this.blur() };
							if ( i == 0 ) this.tabLinks[id].className = 'is-active';
							i++;
						}

						// Hide all content divs except the first
						var i = 0;

						for ( var id in this.contentDivs ) {
							if ( i != 0 ) this.contentDivs[id].className = 'tabContent hide';
							i++;
						}
					}
				} else {
					this.tabNames = [];
				}
			},

			showTab(event) {
				var selectedId = event.currentTarget.id;
				// Highlight the selected tab, and dim all others.
				// Also show the selected content div, and hide all others.
				for ( var id in this.contentDivs ) {
					if ( id == selectedId ) {
						this.tabLinks[id].className = 'is-active';
						this.contentDivs[id].className = 'tabContent';
					} else {
						this.tabLinks[id].className = '';
						this.contentDivs[id].className = 'tabContent hide';
					}
				}

				// Stop the browser following the link
				return false;
			},

			getFirstChildWithTagName( element, tagName ) {
				for ( var i = 0; i < element.childNodes.length; i++ ) {
					if ( element.childNodes[i].nodeName == tagName ) return element.childNodes[i];
				}
			},
			changerole () {
				this.showRoleModal = true;
			},
			roleSelected (index) {
				this.loading = true;
				localStorage.activeRole = index;
				this.responseRole = this.Roles[index];
				this.getLoginData ();
			},
			getLoginData() {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				axios.post('/Login/getroledata', {
					username: this.currentUser.EmailAddress,
					RoleSelected : this.responseRole

				}).then(response => {
					this.currentStatus = STATUS_INITIAL;
					if (response.data.result == 'success') {
						this.finishrolechange(response);
					} else {
						this.roleChangeFailed()
						this.displayPopup (response.data.message);
					}
				}).catch(response => {
					this.currentStatus = STATUS_INITIAL;
					this.roleChangeFailed();
				});
			},
			roleChangeFailed () {
				this.currentStatus = STATUS_FAILED;
				this.$store.dispatch('logout');
				delete localStorage.token;
				Util.eraseCookie('login');
				this.logout();
			},
			finishrolechange (response) {
				if (!response.data.token) {
					this.roleChangeFailed()
					return;
				}
				this.currentStatus = STATUS_SUCCESS;
				localStorage.token = response.data.token;

				localStorage.Roles = JSON.stringify(response.data.Roles);
				localStorage.RoleSecurity = JSON.stringify(response.data.RoleSecurity);

				this.loading = false;
				this.showRoleModal = false;
				location.href = '/dashboard';

			},
			toggle(e){
				//this.isOpen = !this.isOpen
				e.target.parentElement.classList.toggle('close')
			},
			handleMessage (e) {
				this.isMessage = !this.isMessage;
			},
			handleHelp (e) {
				this.isHelp = !this.isHelp;
				this.isNotification = false;
				this.isUserInfo = false;
				if (this.isHelp) {
					this.tabsinit();
				}
			},
			handleNotification (e) {
				this.isNotification = !this.isNotification;
				this.isHelp = false;
				this.isUserInfo = false;
			},
			handleUserInfo (e) {
				this.isUserInfo = !this.isUserInfo;
				this.isNotification = false;
				this.isHelp = false;
			},
			closeNotification () {
				this.isUserInfo = false;
				this.isNotification = false;
			},
			logout (e) {
				window.location.href = 'logout';
			},
			getNotifications() {
				if (this.loggedInUser()) {

					//we do this as if we have a long running ajax job and a second call is made
					//the sessions are locked (some sort of phalcon thing) and causes the user to get logged out
					//while the long running ajax job is running...that is not good
					if (!this.longAjaxRunning()) {
						axios.post('/notifications/getunread'
						)
							.then(response => {
								if (response.data.result == 'success') {
									this.notifications = (response.data.notifications ? response.data.notifications : []);
									this.currentCalls++;
								}
							}).catch(response => {
						});
					}

					if (this.currentCalls < 10 ) {
						this.nextcall = 30000;
					} else {
						this.nextcall += 30000;
					}
					setTimeout(() => {
						this.getNotifications()
					}, this.nextcall);
				}
			},
			displayNotification(NotificationID, MessageLink) {
				axios.post('/notifications/read', {"NotificationID" : NotificationID},
				)
					.then(response => {
						if (response.data.result == 'success') {
							window.location.href = (MessageLink ? MessageLink : '/notifications');
						}
					}).catch(response => {
				});
			},
            EC2instances (action) {
                axios.post('/analyzer/scheduling/ec2', {Action : action, deploymentId: this.deploymentId },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            var ec2 = response.data.ec2;
                            if (ec2.length == 0) {
                                this.SchedulingServerRunning = false;
                            } else {
                                this.SchedulingServerRunning = true;
                            }
                        } else {
                            this.SchedulingServerRunning = false;

                        }
                    }).catch(response => {

                });
            },
			getRoleSelectData () {
				axios.post('/systemtext/get', {SystemTextID : 'roleselection'}
				)
					.then(response => {
						if (response.data.result == 'success') {
							if (response.data.SystemText) {
								this.RoleSelectText = response.data.SystemText.SystemText;
							}
						}
					}).catch(response => {

				});
				axios.post('/Login/getrolestoselect', { username: this.currentUser.EmailAddress}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.Roles = (response.data.Roles ? response.data.Roles : []);
						}
					}).catch(response => {

				});

			},
			loggedInUser () {
				if (Util.readCookie('login') && this.currentUser) {
					return true;
				} else {
					return false;
				}
			},
			longAjaxRunning () {
				if (Util.readCookie('scheduleRunning')) {
					return true;
				} else {
					return false;
				}
			},
			collapse(data) {
				this.$emit('collapse', data);
			},
			resetCalls() {
				this.currentCalls = 0;
			},
		},
		mounted() {
			this.activeRole = localStorage.activeRole;
			this.getNotifications();
            this.EC2instances('status');
            this.getRoleSelectData();

			document.addEventListener('click', this.resetCalls)
		},
		updated() {
			this.tabsinit();
		},
	}
</script>

<style lang="scss">
	.header.is_stuck ul#helptabs { list-style-type: none; margin: 0; padding: 0 0 0.3em 0; border-bottom: 1px solid #ccc;height: 31px;}
	.header.is_stuck ul#helptabs li { 
		padding: 0 10px;
		margin: 0;
		height: 20px;
		box-sizing: border-box;
		line-height: 40px;
		float:left;
		list-style: none;
		font-size: 14px;
		font-weight: 500;
		color: var(--el-text-color-primary);
		position: relative; 
	}
	.header.is_stuck ul#helptabs li span { color: #333; cursor:pointer; }
	.header.is_stuck ul#helptabs li span:hover { border-bottom: 2px solid #333; }
	.header.is_stuck ul#helptabs li span.is-active { border-bottom: 2px solid #333; }
	.header.is_stuck div.tabContent.hide { display: none; }

	.form-inline {
		display: block !important;
	}
	.notification-show + .dropdown-menu,
	.message-show + .dropdown-menu ,
	.language-show + .dropdown-menu  {
		display: block;
	}
	.header .top-navbar .navbar-header .navbar-brand {
		height: 35px;
		max-width: 250px;
	}
	#small-logo, #full-logo {
		width:auto;
		height:auto;
		max-height: 48px;
		max-width: 220px;
	}
	.header.is_stuck {
		min-height:54px;
		z-index:999;
	}
	.header.is_stuck .navbar {
		position: fixed;
		width: 100%;
		box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
	}
	.dropdownhelp .message-center li, .header .top-navbar .mailbox.dropdownhelp .message-center ul li {
		list-style: disc;
		margin-left: 17px;
		padding-left:0px;
		line-height: 10px;
		font-size:12px;
	}
	.header .top-navbar .mailbox.dropdownhelp .message-center p {
		margin-bottom: 0px;
		line-height: 54px;
		font-size:12px;
	}
	.header .top-navbar .mailbox.dropdownhelp .message-center.helptext p {
		margin-bottom: 10px;
		line-height: 18px;
		font-size:12px;
	}
	.header .top-navbar .mailbox.dropdownhelp .message-center {
		font-size:12px;
	}
	.dropdownhelp .message-center.helptext {
		min-height: 200px;
		height: auto;
		max-height: 400px;
	}
	.message-center.notifications div.btn {
		height: 33px;
	}
	.helpAvailable i {
		color: #28a745;
	}
	.super {
		border: 2px solid #000;
		padding: 0px;
		line-height: 37px;
		color: #FFF;
		font-weight: 800;
		height: 40px;
		font-size: 15px;
		display: inline-block;
		width: 100px;
		background-color: #DC3545;
	}
	@media (min-width: 500px) {
		.navbar-header {
			min-width: 240px;
		}
		#small-logo {
			display: none;
		}
		#full-logo {
			display: inline;
		}
	}
	@media (max-width: 499px) {
		.navbar-header {
			min-width: 130px;
		}
		#small-logo {
			display: inline;
		}
		#full-logo {
			display: none;
		}
	}
    #app div.message-center.notifications i {
        color: #FCFAFA !important;
    }

    #app div.message-center.notifications span.notification-text {
        color: #333333 !important;
    }

    #app .header .top-navbar .mailbox.dropdownhelp {
        width: 500px;
    }
    #app .header.isMobile .top-navbar .mailbox.dropdownhelp {
        width: 300px;
    }
    #app .navbar-collapse .SchedulingServerRunning {
        border: 1px solid #fff;
        font-size: 12px;
        padding: 3px;
        text-align: center;
    }
	.dropdown-menu-right {
		right: 0;
		left: auto;
	}
	#app .navbar-collapse span.initials {
		position:absolute;
		top: 10px;
		Left: 17px;
		color: #FFF;
		font-weight: 800;
	}
	#app .profile .dropdown-menu {
		margin-top: 54px;
	}
</style>
