<template>
    <div id="gamechangeresponse" v-loading="loading">
        <div class="row">
            <div class="col-12 page-title bg-primary"><img class="small-logo" :src="config.Params.SmallLogo" alt="Logo"/>&nbsp;Game Change Response</div>
        </div>
        <div class="row page-body">
            <div class="col-1"></div>
            <div class="col-10">
                <h5><div v-html="title"></div></h5>
                <div v-html="message"></div>
            </div>
            <div class="col-1"></div>
        </div>
        <div class="row">
            <div class="col-12 page-footer bg-primary" v-html="pageFooter"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    name: 'gamechangeresponse',
    data() {
        return {
            loading : true,
            title : 'We are processing your Response',
            message : '',
            currentStatus : STATUS_INITIAL,
        }
    },
    props:["config", "parentrefs"],
    computed: {
        pageFooter() {
            return '© ' + (new Date()).getFullYear() + ' ' + this.config.Params.SiteName;
        }
    },
    methods: {
        processResponse () {
            if (this.$route.query.game && this.$route.query.action && this.$route.query.teamid) {
                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                var that = this;
                axios.post('/analyzer/gamemanagement/gamechangeresponse', {
                        game: this.$route.query.game,
                        action: this.$route.query.action,
                        teamid: this.$route.query.teamid,
                        data: this.$route.query.data,
                    }
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.title) {
                                this.title = response.data.title;
                            }
                            this.message = response.data.message;
                            this.$stopProgressBar(this.parentrefs);
                            this.currentStatus = STATUS_SUCCESS;
                        } else {
                            this.title = 'Oh No!';
                            if (response.data.title) {
                                this.title = response.data.title;
                            }
                            this.message = (response.data.message ? response.data.message : 'Unfortunately, we were NOT able to record your response.');
                            this.currentStatus = STATUS_FAILED;
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                        this.title = 'Oh No!';
                        this.message = 'Unfortunately, we were NOT able to record your response.';
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                });
            }
        },
    },
    mounted () {
        this.processResponse();
    }
}
</script>
<style>
#app.gamechangeresponse .page-wrapper {
    margin: 0px;
}
#app.gamechangeresponse p {
    margin-top: 15px;
}
</style>
<style scoped>
.small-logo {
    height: 30px;
    margin-right: 15px;
}

.page-title {
    color: #FFF;
    font-size: 20px;
    font-weight: 800;
    height: 42px;
    line-height: 42px;
    position: fixed;
    z-index: 100;
    padding-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.page-body {
    margin: 60px 0px;
}
.page-footer {
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
    position: fixed;
    bottom: 0;
    z-index: 100;
    text-align: center;
}

</style>
