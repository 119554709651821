<template>
	<div id="openice" v-loading.fullscreen.lock="loading">
		<el-form ref="openiceForm" label-position="top" label-width="120px" >
			<div class="row">
				<div class="col-12 fullpage" :class="{'col-md-6' :  !MainLocationIDAccess}">
					<el-form-item label="Beginning -> End" prop="SlotDates">
						<el-date-picker
								v-model="gamedates"
								type="daterange"
								unlink-panels
								range-separator="To"
								start-placeholder="Start date"
								end-placeholder="End date"
								format="MMM D, YYYY"
								value-format="YYYY-MM-DD">
						</el-date-picker>
					</el-form-item>
                    <el-form-item v-if="!isPurchaseOrder" label="Show Details By:" prop="GroupBy">
                        <el-radio-group v-model="GroupBy" @change="timeslots = []; grouptotals = [];">
                            <el-radio-button label="r">{{config.Params.mainlocationNames}}</el-radio-button>
                            <el-radio-button label="l">{{config.Params.locationNames}}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
					<el-form-item v-if="GroupBy == 'l'" :label="config.Params.locationNames" prop="LocationIDs">
						<el-select key="select6a" ref="locations"  :class="{'oneHundred' : !MainLocationIDAccess, 'fifty' : MainLocationIDAccess}" v-model="LocationIDs" :placeholder="'All ' + config.Params.locationNames" @change="multiselected('locations')" multiple default-first-option filterable clearable>
							<template v-for="item in Locations">
								<el-option
	                                    v-if="item.MainLocationID"
										:key="item.LocationID"
										:label="item.LocationName"
										:value="item.LocationID">
								</el-option>
							</template>
						</el-select>
					</el-form-item>
					<el-form-item v-else :label="config.Params.mainlocationNames" prop="MainLocationIDs">
						<el-select key="select6b" ref="mainlocations"  :class="{'oneHundred' : !MainLocationIDAccess, 'fifty' : MainLocationIDAccess}" v-model="MainLocationIDs" :placeholder="'All ' + config.Params.mainlocationNames" @change="multiselected('mainlocations')" multiple default-first-option filterable clearable >
							<el-option
									v-for="item in SiteLocations"
									:key="item.MainLocationID"
									:label="item.MainLocationName"
									:value="item.MainLocationID">
							</el-option>
						</el-select>
					</el-form-item>
					<el-button ref="getslots" class="mt-4 mb-1 btn-primary" type="primary" @click="getTimeSlots">
						<div v-html="(isPurchaseOrder ? 'Get ' + config.Params.playingsurface + ' Slots to Generate PO': ' Show Usage')"></div> <i class="fa-solid fa-caret-right" style="font-size: 16px;"></i>
					</el-button>
				</div>
				<div v-if="!MainLocationIDAccess && !ProgramIDAccess" class="col-12 col-md-6">
                    <el-form-item v-if="!isPurchaseOrder" label="Organization" prop="OrganizationID">
                        <el-select key="select2"  v-model="OrganizationID" placeholder="All Organizations" class="oneHundred" default-first-option filterable clearable>
                            <el-option v-for="type in Organizations"
                                       :value="type.OrganizationID"
                                       :key="type.OrganizationID"
                                       :label="type.OrganizationName">
                            </el-option>
                        </el-select>
                    </el-form-item>
					<el-form-item v-if="!ProgramIDAccess" label="Schedule Types" prop="ScheduleTypes">
						<el-select key="select7" style="width: 100%;" placeholder="All ScheduleTypes"   v-model="ScheduleTypeIDs" default-first-option filterable clearable
								   multiple
						>
							<el-option
									v-for="item in ScheduleTypes"
									:key="item.ScheduleTypeID"
									:label="item.ScheduleTypeName"
									:value="item.ScheduleTypeID">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="Usage Type:" prop="iceusage">
						<el-select key="select8" style="width: 100%;"  placeholder="All Usage" v-model="SelectedSlotTypes" default-first-option filterable clearable
								   multiple
						>
							<el-option
									v-for="item in SlotTypes"
									:key="item.type"
									:label="item.name"
									:value="item.type">
							</el-option>
						</el-select>
					</el-form-item>
					<div v-if="!MainLocationIDAccess && isPurchaseOrder" class="generatePOs">
						<el-form-item label="Billing Account:" prop="InvoiceSettingID">
							<el-select key="select3" style="width: 250px;" v-model="InvoiceSettingID" placeholder="Select" default-first-option filterable>
								<el-option
										v-for="type in InvoiceSettings"
										:value="type.InvoiceSettingID"
										:key="type.InvoiceSettingID"
										:label="type.BillingName">
								</el-option>
							</el-select>
						</el-form-item>
						<el-button v-if="InvoiceSettingID && sortedKeys.length > 0" type="info" class="btn-secondary" @click="viewPurchaseOrders"><i class="fa-solid fa-eye"></i> View Purchase Order PDF</el-button>
						<br>
						<el-button v-if="InvoiceSettingID && sortedKeys.length > 0 && createAccess" style="margin-top: 10px;" type="success" class="btn-success" @click="generatePurchaseOrders"><i class="fa-solid fa-file-circle-plus"></i> Create Purchase Order(s)</el-button>
                    </div>
				</div>
			</div>
 
			<el-select v-if="timeslots != ''" key="selectcolumns" :style="'float:right;margin-top: ' + (isPurchaseOrder ? '40' : '0') + 'px;margin-bottom: 8px;width: 300px;margin-right: 20px;'" multiple collapse-tags v-model="Columns" placeholder="Select Columns" default-first-option filterable>
				<el-option label="Usage" value="Usage" ></el-option>
				<el-option label="Location" value="LocationName" >
				</el-option>
				<el-option label="Date" value="GameDate" >
				</el-option>
				<el-option label="Time" value="StartTime" >
				</el-option>
				<el-option label="Duration" value="Duration" >
				</el-option>
				<el-option v-if="!ProgramIDAccess" label="Costs" value="Cost" >
				</el-option>
				<el-option v-if="!ProgramIDAccess" label="3rd Party Fees" value="Fee" >
				</el-option>
				<el-option v-if="!ProgramIDAccess" label="Game Fees" value="GameRate" >
				</el-option>
				<el-option v-if="!ProgramIDAccess" label="Schedule Types" value="ScheduleTypes" >
				</el-option>
				<el-option v-if="!ProgramIDAccess" label="Reuse" value="ReuseSlot" >
				</el-option>
				<el-option label="Details" value="Details" >
				</el-option>
				<el-option label="Status" value="Status" >
				</el-option>
				<el-option label="Notes" value="Notes" >
				</el-option>
				<el-option label="Attendees" value="Attendees" >
				</el-option>
			</el-select>
			<div id="OpenIcePrint">
				<el-button v-if="isPurchaseOrder && timeslots != ''" class="mt-4" @click.prevent="selectAllLocations()" size="small" ><i class="fa-solid fa-circle-check"></i> <span v-html="(selectAllLocationsChk == 'n' ? 'Select All ' + config.Params.mainlocationName + ' slots' : 'Unselect All ' + config.Params.mainlocationName + ' slots')"></span></el-button>
				<template v-for="(Key, index) in sortedKeys">
					<div v-if="timeslots != ''" class="mt-1 mb-4">
						<h5 style="width: 100%;font-weight: 800;">{{Key}}</h5>
						<el-table
								:data="timeslots[Key]"
								:key="'timeslots'+Key"
								:ref="'timeslots'+Key"
								size="small"
								show-summary
								:summary-method="getSummaries"
								:sort-method="sortTimeSlotGameDate"
								@header-dragend="FacilityUsageWidthChange"
								@select-all="selectAll(Key)"
								stripe border
								style="width: 100%">
							<el-table-column
								v-if="isPurchaseOrder"
								prop="selected"
								type="selection"
								align="center"
								width="100">
								<template #default="scope">
									<span :style="'padding-top: 6px;'">
										<div v-if="scope.row.PONumber" v-html="(scope.row.PONumber ? scope.row.PONumber : '')"></div>
										<el-checkbox v-else v-model="scope.row.selected" false-label="0" true-label="1" ></el-checkbox>
									</span>
								</template>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('Usage') !== -1"
								prop="Usage"
								label="Usage"
								:width="(FacilityUsageWidths.Usage ? FacilityUsageWidths.Usage: 100)"
								align="left"
								sortable
							>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('LocationName') !== -1"
								prop="LocationName"
								:label="config.Params.locationName"
								:width="(FacilityUsageWidths.LocationName ? FacilityUsageWidths.LocationName: 150)"
								align="left"
								sortable
							>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('GameDate') !== -1"
								prop="GameDateF"
								label="Date"
								:width="(FacilityUsageWidths.GameDate ? FacilityUsageWidths.GameDate: 130)"
								align="left"
								sortable
								:sort-method="sortTimeSlotGameDate"
							>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('StartTime') !== -1"
								prop="StartTime"
								label="Time"
								:width="(FacilityUsageWidths.StartTime ? FacilityUsageWidths.StartTime: 80)"
								align="left"
								sortable
								:sort-method="sortTimeSlotGameTime"
							>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('Duration') !== -1"
								prop="Duration"
								label="Duration"
								:width="(FacilityUsageWidths.Duration ? FacilityUsageWidths.Duration: 80)"
								align="left"
							>
								<template #default="scope">
									<div v-html="scope.row.Duration + ' min.'"></div>
								</template>
							</el-table-column>
							<el-table-column
								v-if="!MainLocationIDAccess && !ProgramIDAccess && Columns.indexOf('Cost') !== -1"
								prop="Cost"
								label="Costs"
								align="right"
								:width="(FacilityUsageWidths.Cost ? FacilityUsageWidths.Cost: 100)"
							>
								<template #default="scope">
									<div v-html="formatPrice(scope.row.Cost)"></div>
								</template>
							</el-table-column>
							<el-table-column
								v-if="!MainLocationIDAccess && !ProgramIDAccess && Columns.indexOf('Fee') !== -1"
								prop="Fee"
								label="3rd Party Fees"
								align="right"
								:width="(FacilityUsageWidths.Fee ? FacilityUsageWidths.Fee: 100)"
							>
								<template #default="scope">
									<div v-html="formatPrice(scope.row.Fee)"></div>
								</template>
							</el-table-column>
							<el-table-column
								v-if="!MainLocationIDAccess && !ProgramIDAccess && Columns.indexOf('GameRate') !== -1"
								prop="GameRate"
								label="Game Fees"
								align="right"
								:width="(FacilityUsageWidths.GameRate ? FacilityUsageWidths.GameRate: 100)"
							>
								<template #default="scope">
									<div v-html="formatPrice(scope.row.GameRate)"></div>
								</template>
							</el-table-column>
							<el-table-column
								v-if="!MainLocationIDAccess && !ProgramIDAccess && Columns.indexOf('ScheduleTypes') !== -1"
								prop="ScheduleTypes"
								label="Schedule Types"
								align="center"
								:width="(FacilityUsageWidths.ScheduleTypes ? FacilityUsageWidths.ScheduleTypes: 130)"
								sortable
							>
							</el-table-column>
							<el-table-column
								v-if="!MainLocationIDAccess && !ProgramIDAccess && Columns.indexOf('ReuseSlot') !== -1"
								prop="ReuseSlot"
								label="Reuse"
								align="center"
								:width="(FacilityUsageWidths.ReuseSlot ? FacilityUsageWidths.ReuseSlot: 90)"
								sortable
							>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('Details') !== -1"
								prop="Details"
								label="Details"
								align="left"
								:width="(FacilityUsageWidths.Details ? FacilityUsageWidths.Details: 200)"
							>
								<template #default="scope">
									<div v-html="scope.row.Details"></div>
								</template>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('Status') !== -1"
								prop="StatusCodeName"
								label="Status"
								align="left"
								:width="(FacilityUsageWidths.Status ? FacilityUsageWidths.Status: 100)"
							>
								<template #default="scope">
									<div v-html="scope.row.StatusCodeName"></div>
								</template>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('Notes') !== -1"
								prop="Notes"
								label="Notes"
								align="left"
								:width="(FacilityUsageWidths.Notes ? FacilityUsageWidths.Notes: 200)"
							>
								<template #default="scope">
									<div v-html="scope.row.Notes"></div>
								</template>
							</el-table-column>
							<el-table-column
								v-if="Columns.indexOf('Attendees') !== -1"
								prop="Attendees"
								label="Attendees / Contacts"
								align="left"
								:width="(FacilityUsageWidths.Attendees ? FacilityUsageWidths.Attendees: 200)"
							>
								<template #default="scope">
									<template v-if="scope.row.FormDataID > 0 && scope.row.FormParams">
										<div v-html="scope.row.FormParams.FirstName+' '+scope.row.FormParams.LastName+'<br>'+scope.row.FormParams.Email+(scope.row.FormParams.PhoneDaytime != scope.row.FormParams.PhoneEvening ? '<br>Day: '+scope.row.FormParams.PhoneDaytime+'<br>Evening: '+scope.row.FormParams.PhoneEvening : '<br>'+scope.row.FormParams.PhoneDaytime)"></div>
									</template>
									<template v-else-if="scope.row.data && scope.row.data.Attendees && scope.row.data.Attendees.length > 0" >
										<template v-for="(attendee,index) in scope.row.data.Attendees">
											<div v-if="attendee.FirstName" style="clear:both;margin-bottom: 3px;"><div v-html="attendee.FirstName+' '+attendee.LastName"></div></div>
										</template>
									</template>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</template>
				<div v-if="grouptotals.length > 0" class="mt-4 mb-4" style="padding:0;">
					<h5 style="width: 100%;">Totals</h5>
					<el-table
						:data="grouptotals"
						key="reportkeygrouptotal"
						:show-summary="true"
						:summary-method="getTotalSummaries"
						sum-text="Totals"
						stripe border
						empty-text="no data"
						size="small"
						style="width: 100%;"
					>
						<el-table-column
							v-for="(field, index) in groupcolumns"
							:key="field.column"
							:prop="field.column"
							:label="field.title"
							:min-width="field.width"
							:align="field.align">
							<template #default="scope">
								<div v-if="index > 1" v-html="formatPrice(scope.row[field.column])"></div>
								<div v-else v-html="scope.row[field.column]"></div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div v-if="searchPerformed" class="mt-4 mb-4" style="height: 50px;">
					<h5 v-if="timeslots == ''" v-html="'Unfortunately, no ' + (isPurchaseOrder ? config.Params.playingsurface + ' Slots': 'Events') + ' with these parameters can be found.'" ></h5>
					<template v-else >
						<el-button id="printbutton" type="info" class="btn btn-icon btn-secondary" style="float:right;"  @click="viewPDF">
                        	<i class="fas fa-print"></i>  <span>Print</span>
						</el-button>
						<el-button type="info" class="btn btn-icon btn-secondary" style="float:right;margin-right: 20px;" @click="exportCSV">
							<i class="fas fa-file-export"></i>  <span>Export CSV</span>
						</el-button>
					</template>
				</div>
				<div v-if="isPurchaseOrder && !MainLocationIDAccess && !ProgramIDAccess" class="card mt-4 fullpage">
					<div class="card-header bg-primary details"><h5>Generated Purchase Orders</h5></div>
					<div class="card-body">
						
						<div class="row">
							<div class="col-12 fullpage" style="max-width: 413px;">
								<el-form-item label="Beginning -> End" prop="SlotDates">
									<el-date-picker
											v-model="purchaseorderdates"
											type="daterange"
											unlink-panels
											range-separator="To"
											start-placeholder="Start date"
											end-placeholder="End date"
											format="MMM D, YYYY"
											value-format="YYYY-MM-DD"
											@change="purchaseorderdateChange">
									</el-date-picker>
								</el-form-item>
							</div>
							<div class="col-12 fullpage">
								<div class="mb-2" >
									<EmailTemplate calling="purchaseorder" v-model="POSystemText" BillingType="" :config="config" width="100%" />
								</div>
							</div>
						</div>
						<el-table
							v-if="isPurchaseOrder"
							class="mt-1 mb-2"
							:data="PurchaseOrders"
							key="orderstable"
							max-height="500"
							size="small"
							no-data-text="No Purchase Orders"
							stripe border
							style="width: 100%">
							<el-table-column
								prop="PONumber"
								label="PO Number"
								align="center"
								width="120"
								sortable
							>
								<template #default="scope">
									<div v-html="'PO-' + scope.row.PONumber"></div>
								</template>
							</el-table-column>
							<el-table-column
								prop="PODate"
								label="PO Date"
								align="center"
								width="100"
								sortable
							>
							</el-table-column>
							<el-table-column
								prop="POStartDate"
								label="Start Date"
								align="center"
								width="100"
								sortable
							>
							</el-table-column>
							<el-table-column
								prop="POEndDate"
								label="End Date"
								align="center"
								width="100"
								sortable
							>
							</el-table-column>
							<el-table-column
								prop="MainLocationName"
								:label="config.Params.mainlocation"
								align="left"
								min-width="130"
								sortable
							>
							</el-table-column>
							<el-table-column
								prop="Hours"
								label="Hours"
								width="100"
								align="center"
							>
							</el-table-column>
							<el-table-column
								prop="Amount"
								label="Amount"
								width="100"
								align="right"
							>
								<template #default="scope">
									<div v-html="formatPrice(scope.row.Amount)"></div>
								</template>
							</el-table-column>
							<el-table-column
								prop=""
								label="Status"
								width="130"
								align="right"
							>
								<template #default="scope">
									<div v-if="scope.row.POStatus == 'C'" v-html="'Created'"></div>
									<div v-if="scope.row.POStatus == 'A'" v-html="'Active (signed)'"></div>
								</template>
							</el-table-column>
							<el-table-column
								prop=""
								label="Download"
								align="center"
								width="120px">
								<template #default="scope">
									<button v-if="scope.row.POPDF" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="downloadPurchaseOrder(scope.row)">
										<div class="v-btn__content">
											<i class="fas fa-2x fa-file-download"></i>
										</div>
									</button>
								</template>
							</el-table-column>
							<el-table-column
								prop=""
								label="Email"
								align="center"
								width="80px">
								<template #default="scope">
									<template v-if="scope.row.Params">
										<button v-if="modifyAccess" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="emailPurchaseOrder(scope.row);">
											<div class="v-btn__content">
												<i class="far fa-2x fa-envelope"></i>
											</div>
										</button><br>
										<span v-if="scope.row.Params && scope.row.Params.POEmailed && scope.row.Params.POEmailed == 'y'" class="emailsuccess"><i class="emailsuccess fas fa-check-square"></i>&nbsp; Emailed</span>
									</template>
								</template>
							</el-table-column>
							<el-table-column
								prop=""
								label="Remove"
								align="center"
								width="120px">
								<template #default="scope">
									<button v-if="modifyAccess && scope.row.POStatus == 'C'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="cnfmDeletePurchaseOrder(scope.row);">
										<div class="v-btn__content">
											<i class="far fa-2x fa-trash-alt"></i>
										</div>
									</button>
								</template>
							</el-table-column>
						</el-table>
						<br>
						<el-button v-if="PurchaseOrders.length > 0 && modifyAccess && POSystemText" class="mb-4" type="success" @click="emailPurchaseOrder({PurchaseOrderID : 0})"><i class="far fa-envelope"></i> Email Unsent Purchase Orders</el-button>
					</div>
				</div>
			</div>
			<modal v-if="showPrintModal" @close="showPrintModal = false" width="550px" :top="top+'px'">
				<template v-slot:header>
					<h5 class="modal-title">
						Print
					</h5>
				</template>
				<template v-slot:body >
					<Print ref="print" :data="timeslots" :columns="pdfColumns[GroupBy]"  :groupdata="grouptotals"  :groupcolumns="pdfgroupcolumns" reportName="Facility Usage" totals="y" printDomID="#OpenIcePrint"  @close="showPrintModal = false"/>
				</template>
				<template v-slot:footer>
					&nbsp;
				</template>
			</modal>
		</el-form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
    import Util from "../assets/js/util";
    import Modal from './global/Modal.vue';
    import Print from './global/Print.vue';
	import EmailTemplate from './global/EmailTemplate.vue';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
	export default {
		name: 'FacilityUsage',
        components: {
            Modal,
            Print,
			EmailTemplate
        },
	    data() {
	        return {
		        loading: false,
                searchPerformed: false,
                showPrintModal: false,
                currentStatus : STATUS_INITIAL,
		        LocationIDs : [],
		        Locations : [],
                InvoiceSettings : [],
		        MainLocationIDs : [],
                SiteLocations : [],
		        Organizations : [],
				OrganizationID : '',
                InvoiceSettingID : '',
		        timeslots : '',
				export : [],
		        gamedates: [],
				ScheduleTypes: [],
		        ScheduleTypeIDs : [],
				GameDuration: '0',
		        gametype : 'Live',
				gamestatus : [],
                pdfColumns : {'l' :[{Data : "Usage", Title : "Usage", Width : 10, Align : "C", Order: 1},{Data : "GameDateF", Title : "Date", Width : 20, Align : "L", Order: 2},{Data : "StartTime", Title : "Time", Width : 10, Align : "C", Order: 3},{Data : "DurationF", Title : 'Duration', Width : 10, Align : "C", Order: 4},{Data : "Fee", Title : '3rd Party Fees', Width : 15, Align : "R", Order: 5, Currency : 'y'},{Data : "GameRate", Title : 'Game Fees', Width : 15, Align : "R", Order: 5, Currency : 'y'},{Data : "Cost", Title : 'Cost', Width : 15, Align : "R", Order: 6, Currency : 'y'},{Data : "ScheduleTypes", Title : 'Sched Types', Width : 15, Align : "L", Order: 7},{Data : "Details", Title : "Details", Width : 20, Align : "L", Order: 8},{Data : "StatusCodeName", Title : "Status", Width : 15, Align : "L", Order: 9},{Data : "Notes", Title : "Notes", Width : 20, Align : "L", Order: 10},{Data : "Attendees", Title : "Attendees/Contact", Width : 20, Align : "L", Order: 11}],
                    'r' :[{Data : "Usage", Title : "Usage", Width : 10, Align : "C", Order: 1},{Data : "LocationName", Title : this.config.Params.locationName, Width : 15, Align : "L", Order: 2},{Data : "GameDateF", Title : "Date", Width : 20, Align : "L", Order: 3},{Data : "StartTime", Title : "Time", Width : 10, Align : "C", Order: 4},{Data : "DurationF", Title : 'Duration', Width : 10, Align : "C", Order: 5},{Data : "Fee", Title : '3rd Party Fees', Width : 15, Align : "R", Order: 6, Currency : 'y'},{Data : "GameRate", Title : 'Game Fees', Width : 15, Align : "R", Order: 5, Currency : 'y'},{Data : "Cost", Title : 'Cost', Width : 15, Align : "R", Order: 7, Currency : 'y'},{Data : "ScheduleTypes", Title : 'Sched Types', Width : 15, Align : "L", Order: 8},{Data : "Details", Title : "Details", Width : 20, Align : "L", Order: 9},{Data : "StatusCodeName", Title : "Status", Width : 15, Align : "L", Order: 10},{Data : "Notes", Title : "Notes", Width : 20, Align : "L", Order: 11},{Data : "Attendees", Title : "Attendees/Contact", Width : 20, Align : "L", Order: 12}]},
                SlotTypes : [{type : 'o', name : 'Open'},{type : 'on', name : 'Open (Never Booked)'},{type : 'e', name : '3rd Party'},{type : 'ol', name : 'Online Booking'},{type : 'g', name : 'Games'},{type : 'p', name : 'Practices'},],
		        SelectedSlotTypes : [],
		        GroupBy : 'l',
                FacilityUsageWidths : {},
                Columns : ['Usage', 'LocationName','GameDate','StartTime','Duration','Cost','Fee','GameRate','ScheduleTypes','ReuseSlot','Details','Status','Notes','Attendees'],
                headerCheckbox : [],
                sortedKeys : [],
                showncolumns : [],
                summaries : [],
                grouptotals : [],
                groupcolumns : [{column : 'LocationName', title : 'LocationName', align : 'left', width : '150'}, {column : 'Num', title : 'Num', align : 'center', width : '75'},{column : 'Cost', title : 'Cost', align : 'right', width : '120'},{column : 'Fee', title : '3rd Party Fees', align : 'right', width : '150'},{column : 'GameRate', title : 'Game Fees', align : 'right', width : '150'}],
                pdfgroupcolumns : [{Data : "LocationName", Title : "Location", Width : 20, Align : "L", Order: 1},{Data : "Num", Title : "Num", Width : 10, Align : "C", Order: 2},{Data : "Cost", Title : 'Cost', Width : 15, Align : "R", Order: 6, Currency : 'y'}, {Data : "Fee", Title : '3rd Party Fees', Width : 15, Align : "R", Order: 5, Currency : 'y'}, {Data : "GameRate", Title : 'Game Fees', Width : 15, Align : "R", Order: 5, Currency : 'y'}],
				POSystemText : '',
				PurchaseOrders : [],
				selectAllLocationsChk : 'n',
				purchaseorderdates : [],
	        }
	    },
	    props:["config", "parentrefs"],
	    computed: {
		    ...mapGetters({ currentUser: 'currentUser' }),
		    ...mapGetters({ componentIDs: 'componentIDs' }),
            MainLocationIDAccess () {
                return this.$MainLocationIDAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            isPurchaseOrder() {
                return (this.$route.fullPath.indexOf('purchaseorder') !== -1 ? true : false);
            },
		    top() {
				return (100 + Util.getPosition('printbutton'));
		    },
			createAccess () {
                return this.$createAccess();
            },
	        modifyAccess () {
                return this.$modifyAccess();
            },
			defaultPDF () {
                return require('../assets/images/pdf-download.png');
            },
            currencySign () {
                return Util.currencySign(this.config);
            },
		},
		methods: {
			multiselected (ref) {
				this.$refs[ref].blur();
				if (this.isPurchaseOrder) {
					this.getPurchaseOrders();
				}
			},
            calcgrouptotals () {
                this.grouptotals = [];
                var grouplabel = (this.GroupBy == 'l' ? this.config.Params.locationNames : this.config.Params.mainlocationNames);

                this.groupcolumns[0].title = grouplabel;
                this.pdfgroupcolumns[0].Title = grouplabel;

                var groupsummaries = [];
                var group = '';
                var row = [];
                var j = 0;
                var self = this;
                var groupvalues = []
                for (const property in this.timeslots) {
                    groupvalues.push(property);
                    j++;
                }
                groupvalues.sort();

                if (this.summaries.length == j) {
                    for (let i = 0; i < this.summaries.length; i++) {
                        row = {};
                        row['LocationName'] = groupvalues[i];
	                    row['Num'] = this.summaries[i][1];
	                    row['Cost'] = this.summaries[i][2];
	                    row['Fee'] = this.summaries[i][3];
	                    row['GameRate'] = this.summaries[i][4];

                        groupsummaries.push(row);
                    }
                    this.grouptotals = groupsummaries;
                } else {
                    setTimeout(() => { self.calcgrouptotals(); }, 100);
                }
            },
			purchaseorderdateChange () {
				this.getPurchaseOrders();
			},
			selectAllLocations () {
                this.bracketsSelected = [];
                if (this.selectAllLocationsChk == 'n') {
                    this.selectAllLocationsChk = 'y';
					for (const property in this.timeslots) {
						for (let i = 0; i < this.timeslots[property].length; i++) {
                        	this.timeslots[property][i].selected = "1";
						}
					}
                } else {
                    this.selectAllLocationsChk = 'n';
					for (const property in this.timeslots) {
						for (let i = 0; i < this.timeslots[property].length; i++) {
                        	this.timeslots[property][i].selected = "0";
						}
					}
                }
            },
            selectAll(MainLocationName) {
                if (!this.headerCheckbox.hasOwnProperty(MainLocationName)) {
                    this.headerCheckbox[MainLocationName] = "1";
				}
                var selected = (this.headerCheckbox[MainLocationName] == 1 ? 1 : 0);
                this.headerCheckbox[MainLocationName] = (this.headerCheckbox[MainLocationName] + 1) % 2;
                for (let i = 0; i < this.timeslots[MainLocationName].length; i++) {
                    this.timeslots[MainLocationName][i].selected = selected + '';
                }
            },
            FacilityUsageWidthChange (newWidth, oldWidth, column, event) {
                if (column.property) {
                    this.FacilityUsageWidths[column.property] = newWidth;
                }
            },
			sortTimeSlotGameDate (a,b) {
				var ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
				if (ret == 0) {
					ret = parseInt(a.StartSeconds) - parseInt(b.StartSeconds);
				}
				return ret;
			},
			sortTimeSlotGameTime (a,b) {
				if (a.GameDate && b.GameDate) {
					let ret = parseInt(a.StartSeconds) - parseInt(b.StartSeconds);
					if (ret == 0) {
						ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
					}
					return ret;
				} else {
					return 0;
				}
			},
			getSummaries(param) {
                var startIdx = -1;
                var costIdx = -1;
                var feeIdx = -1;
                var rateIdx = -1;
                if (this.Columns.indexOf('Usage') != -1) {
                    startIdx++;
                }
                if (this.Columns.indexOf('LocationName') != -1) {
                    startIdx++;
                }
                if (this.Columns.indexOf('GameDate') != -1) {
                    startIdx++;
                }
                if (this.Columns.indexOf('StartTime') != -1) {
                    startIdx++;
                }
                if (this.Columns.indexOf('Duration') != -1) {
                    startIdx++;
                }
                if (this.Columns.indexOf('Cost') != -1) {
                    startIdx++;
                    costIdx = startIdx;
                }
                if (this.Columns.indexOf('Fee') != -1) {
                    startIdx++;
                    feeIdx = startIdx;
                }
                if (this.Columns.indexOf('GameRate') != -1) {
                    startIdx++;
                    rateIdx = startIdx;
                }

                const { columns, data } = param;
				const sums = [];
                this.showncolumns = columns;
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = 'Totals';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (index === 1) {
						sums[index] = values.length;
						return;
					}
					if (!values.every(value => isNaN(value))) {
						if (index == costIdx || index == feeIdx || index == rateIdx) {
							sums[index] = this.formatPrice(values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0));
						} else {
							sums[index] = '';
						}
					} else {
						sums[index] = '';
					}
				});
				return sums;
			},
            getTotalSummaries(param) {

                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totals';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));

                    if (!values.every(value => isNaN(value))) {
                        if (index > 1) {
                            sums[index] = this.formatPrice(values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0));
                        } else {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            });
                        }
                    } else {
                        sums[index] = '';
                    }
                });
                return sums;
            },
			formatPrice(value) {
				if (value) {
					return Util.formatPrice(this.config, value, '');
				} else {
					return '';
				}
			},
			getInitialData () {
				var tables = ['Locations','ScheduleTypes','Organizations', 'InvoiceSettings', 'SiteLocations'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (this.componentIDs.OpenIceInvoiceSettingID) {
                            this.InvoiceSettingID = this.componentIDs.OpenIceInvoiceSettingID;
                        }
                        if (this.componentIDs.OIgamedates) {
                            this.gamedates = this.componentIDs.OIgamedates;
                        }
						if (this.componentIDs.OIpurchaseorderdates) {
                            this.purchaseorderdates = this.componentIDs.OIpurchaseorderdates;
                        }
                        if (this.componentIDs.OILocationIDs) {
                            this.LocationIDs = this.componentIDs.OILocationIDs;
                        }
                        if (!this.isPurchaseOrder && this.componentIDs.OIMainLocationIDs) {
                            this.MainLocationIDs = this.componentIDs.OIMainLocationIDs;
                        }
                        if (this.componentIDs.OIGroupBy) {
                            this.GroupBy = this.componentIDs.OIGroupBy;
                        }
                        if (!this.ProgramIDAccess && !this.MainLocationIDAccess) {
                            if (this.componentIDs.OIScheduleTypeIDs) {
                                this.ScheduleTypeIDs = this.componentIDs.OIScheduleTypeIDs;
                            }
                            if (this.componentIDs.OIOrganizationID) {
                                this.OrganizationID = this.componentIDs.OIOrganizationID;
                            }
                            if (!this.isPurchaseOrder && this.componentIDs.OISlotTypes) {
                                this.SelectedSlotTypes = this.componentIDs.OISlotTypes;
                            }
                        }
                        if (this.isPurchaseOrder) {
                            this.GroupBy = 'r';
							this.OrganizationID = '';
							if (this.componentIDs.POMainLocationIDs) {
								this.MainLocationIDs = this.componentIDs.POMainLocationIDs;
							}
							if (this.componentIDs.POSlotTypes) {
                                this.SelectedSlotTypes = this.componentIDs.POSlotTypes;
                            }
							this.getPurchaseOrders();
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.loading = false;
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
					this.loading = false;
                });
			},
			getTimeSlots() {
				if (this.isPurchaseOrder) {
					this.getPurchaseOrders();
				} else {
					if (!this.gamedates || this.gamedates.length == 0) {
						this.displayPopup ('Start and End Dates are Required.');
						return true;
					}
				}
				this.$startProgressBar(this.parentrefs);
                this.summaries = [];
				this.loading = true;
				let self = this;
				if (this.MainLocationIDAccess) {
                    this.SelectedSlotTypes = ['e', 'g', 'p'];
                    this.OrganizationID = '';
                    this.ScheduleTypeIDs = [];
                }
				this.timeslots = '';
                this.searchPerformed = false;
				axios.post('/analyzer/gamemanagement/geticeusage', { ScheduleTypes : this.ScheduleTypeIDs, LocationIDs : this.LocationIDs, MainLocationIDs : this.MainLocationIDs, ScheduleID : this.ScheduleID, gamedates : this.gamedates, GameDuration : this.GameDuration, OrganizationID : this.OrganizationID, gametype : this.gametype, gamestatus : this.gamestatus, SlotTypes : this.SelectedSlotTypes, GroupBy : this.GroupBy },
				)
					.then(response => {
						if (response.data.result == 'success') {
							self.$store.dispatch('setID', {'OILocationIDs': self.LocationIDs});
							
							self.$store.dispatch('setID', {'OIgamedates': self.gamedates});
							self.$store.dispatch('setID', {'OIOrganizationID': self.OrganizationID});
							self.$store.dispatch('setID', {'OIScheduleTypeIDs': self.ScheduleTypeIDs});
							if (self.isPurchaseOrder) {
								self.$store.dispatch('setID', {'POSlotTypes': self.SelectedSlotTypes});
								self.$store.dispatch('setID', {'POMainLocationIDs': self.MainLocationIDs});
							} else {
								self.$store.dispatch('setID', {'OISlotTypes': self.SelectedSlotTypes});
								self.$store.dispatch('setID', {'OIMainLocationIDs': self.MainLocationIDs});
							}
							self.$store.dispatch('setID', {'OIGroupBy': self.GroupBy});

							if (response.data.timeslots) {
								this.sortedKeys = [];
								for (const property in response.data.timeslots) {
									this.sortedKeys.push(property);
								}
								this.sortedKeys.sort();
								for (let i=0; i < this.sortedKeys.length; i++) {
									this.summaries[i] = ['Totals',0,0,0,0];
									for (let j=0; j < response.data.timeslots[this.sortedKeys[i]].length; j++) {
										this.summaries[i][1] += 1;
										this.summaries[i][2] += (isNaN(parseFloat(response.data.timeslots[this.sortedKeys[i]][j]['Cost'])) ? 0 : parseFloat(response.data.timeslots[this.sortedKeys[i]][j]['Cost']));
										this.summaries[i][3] += (isNaN(parseFloat(response.data.timeslots[this.sortedKeys[i]][j]['Fee'])) ? 0 : parseFloat(response.data.timeslots[this.sortedKeys[i]][j]['Fee']));
										this.summaries[i][4] += (isNaN(parseFloat(response.data.timeslots[this.sortedKeys[i]][j]['GameRate'])) ? 0 : parseFloat(response.data.timeslots[this.sortedKeys[i]][j]['GameRate']));
									}
								}
                                this.timeslots = response.data.timeslots;
                            }
							this.export = response.data.export;
							this.$stopProgressBar(this.parentrefs);

                            this.calcgrouptotals();
						} else {
							this.$failProgressBar(this.parentrefs);
						}
                        this.saveSettings();
						this.searchPerformed = true;
						this.loading = false;
					}).catch(response => {
						this.$failProgressBar(this.parentrefs);
						this.loading = false;
				});
			},

			exportCSV (index) {

				let list = this.export;
				let columns = ['Usage', 'GameDate', 'StartTime', 'Duration', 'MainLocation', 'Location', 'Cost', 'Fee', 'GameRate', 'Details', 'ReuseSlot', 'StatusCodeName', 'Notes', 'OrganizationName', 'LeagueName', 'ScheduleTypes', 'Teams', 'Attendees'];

				let csv = Papa.unparse(list, {columns: columns});

				let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
				let csvURL =  null;
				if (navigator.msSaveBlob)
				{
					csvURL = navigator.msSaveBlob(csvData, 'usage.csv');
				}
				else
				{
					csvURL = window.URL.createObjectURL(csvData);
				}

				let tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'usage.csv');
				tempLink.click();
			},
            viewPDF() {
                if (this.timeslots != '') {
                    for (var key in this.timeslots) {
                        for (let i = 0; i < this.timeslots[key].length; i++) {
                            this.timeslots[key][i]['Attendees'] = [];
                            if (this.timeslots[key][i].FormDataID > 0 && this.timeslots[key][i].FormParams) {
                                this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].FormParams.FirstName + ' ' + this.timeslots[key][i].FormParams.LastName);
                                this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].FormParams.Email) ;
                                if (this.timeslots[key][i].FormParams.PhoneDaytime != this.timeslots[key][i].FormParams.PhoneEvening ) {
                                    this.timeslots[key][i]['Attendees'].push("Day: " + this.timeslots[key][i].FormParams.PhoneDaytime);
                                    this.timeslots[key][i]['Attendees'].push("Evening: " + this.timeslots[key][i].FormParams.PhoneEvening);
                                } else {
                                    this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].FormParams.PhoneDaytime);
                                }
                            } else if (this.timeslots[key][i].data.Attendees) {
                                for (let j = 0; j < this.timeslots[key][i].data.Attendees.length; j++) {
                                    if (this.timeslots[key][i].data.Attendees[j].FirstName) {
                                        this.timeslots[key][i]['Attendees'].push(this.timeslots[key][i].data.Attendees[j].FirstName + ' ' + this.timeslots[key][i].data.Attendees[j].LastName);
                                    }
                                }
                            }
                            if (this.timeslots[key][i].Description) {
                                this.timeslots[key][i].TitleDescription = [this.timeslots[key][i].Title, this.timeslots[key][i].Description];
                            } else {
                                this.timeslots[key][i].TitleDescription = this.timeslots[key][i].Title;
                            }
                            this.timeslots[key][i].DurationF = this.timeslots[key][i].Duration + ' min.';
                        }
                    }

                    this.showPrintModal = true;
                }
            },
            getPurchaseOrders() {
				this.$startProgressBar(this.parentrefs);
                this.summaries = [];
				this.loading = true;
				let self = this;
				this.PurchaseOrders = [];
                    axios.post('/finance/getpurchaseorders', { MainLocationIDs : this.MainLocationIDs, purchaseorderdates : this.purchaseorderdates },
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
								self.$store.dispatch('setID', {'POMainLocationIDs': self.MainLocationIDs});
								self.$store.dispatch('setID', {'OIpurchaseorderdates': self.purchaseorderdates});
                                this.currentStatus = STATUS_INITIAL;
                                this.$stopProgressBar(this.parentrefs);
                                if (response.data.PurchaseOrders) {
                                    this.PurchaseOrders = response.data.PurchaseOrders;
                                }
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup('Purchase Orders could not be retrieved');
                                this.$failProgressBar(this.parentrefs);
                            }
							this.loading = false;
                        }).catch(response => {
							this.currentStatus = STATUS_FAILED;
							this.$failProgressBar(this.parentrefs);
							this.loading = true;
                    });
            },
            viewPurchaseOrders() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (Object.keys(this.timeslots).length > 0) {
                    this.$store.dispatch('setID', {'OpenIceInvoiceSettingID': this.InvoiceSettingID});
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);
                    axios.post('/finance/viewpurchaseorder', {
                            MainLocationIDs: this.MainLocationIDs,
                            Timeslots: this.timeslots,
                            InvoiceSettingID: this.InvoiceSettingID
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.currentStatus = STATUS_INITIAL;
                                this.$stopProgressBar(this.parentrefs);
                                if (response.data.poFile) {
                                    var poFile = response.data.poFile;

                                    var link = document.createElement('a');
                                    link.href = poFile;
                                    link.click();
                                } else {
                                    this.displayPopup('Purchase Order could not be viewed');
                                }
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup('No Ice Details Selected');
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.displayPopup('At least one Rental must be selected to view a Purchase Order');
                }
            },
			generatePurchaseOrders() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (Object.keys(this.timeslots).length > 0) {
                    this.$store.dispatch('setID', {'OpenIceInvoiceSettingID': this.InvoiceSettingID});
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);
                    axios.post('/finance/generatepurchaseorders', {
                            MainLocationIDs: this.MainLocationIDs,
                            Timeslots: this.timeslots,
                            InvoiceSettingID: this.InvoiceSettingID
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.currentStatus = STATUS_INITIAL;
                                this.$stopProgressBar(this.parentrefs);
								this.$notify({
									title: 'Success',
									message: "Purchase Order(s) have been created and are in the list of Purchase Orders.",
									type: 'success',
									duration: 3000,
									showClose: true
                            	});
								this.getTimeSlots();
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup('No Ice Details Selected');
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.displayPopup('At least one Rental must be selected to generate a Purchase Order');
                }
            },
			cnfmDeletePurchaseOrder(row) {
                this.$confirm('This will permanently delete this Purchase Order. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deletePurchaseOrder(row);
                }).catch(() => {
                });
            },

            deletePurchaseOrder(row) {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                axios.post('/finance/deletepurchaseorder', {PurchaseOrderID : row.PurchaseOrderID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_INITIAL;
                            this.$stopProgressBar(this.parentrefs);
                            this.getTimeSlots();
                            this.$notify({
                                title: 'Success',
                                message: (response.data.message ? response.data.message : "Purchase Order has been deleted."),
                                type: 'success',
                                duration: 4000,
                                showClose: true
                            });
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.displayPopup (response.data.message);
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                });
            },
			downloadPurchaseOrder (row) {
				var link = document.createElement('a');
				link.href = row.POPDF;
				link.click();
			},
			emailPurchaseOrder(row) {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
				if (this.POSystemText && this.POSystemText.SystemText) {
					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
					axios.post('/finance/emailpurchaseorder', {PurchaseOrderID: row.PurchaseOrderID, SystemText : this.POSystemText, PurchaseOrders : this.PurchaseOrders}
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$stopProgressBar(this.parentrefs);

								this.$notify({
									title: 'Success',
									message: (response.data.message ? response.data.message : "Purchase Order has been emailed to the contact on file."),
									type: 'success',
									duration: 4000,
									showClose: true
								});
								this.getPurchaseOrders();
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup (response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
						}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
					});
				} else {
					this.displayPopup('Please select an Email Template to send the Invoice with');
				}
            },
            saveSettings() {
                axios.post('/users/savesettings', { UsageColumns : this.Columns, FacilityUsageWidths : this.FacilityUsageWidths },
                )
                    .then(response => {

                    }).catch(response => {

                });
            },
            getSettings() {

                axios.post('/users/getsettings', {  },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.user.Params.UsageColumns) {
                                this.Columns = response.data.user.Params.UsageColumns;
                            }
                            this.FacilityUsageWidths = (!Array.isArray(response.data.user.Params.FacilityUsageWidths) && response.data.user.Params.FacilityUsageWidths ? response.data.user.Params.FacilityUsageWidths : {});
                        }

                    }).catch(response => {

                });
            },
            displayPopup (message) {
                this.$alert(message, {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
            }

		},
		mounted () {
			if (this.isPurchaseOrder) {
				this.SlotTypes = [{type : 'o', name : 'Open'},{type : 'on', name : 'Open (Never Booked)'},{type : 'e', name : '3rd Party'},{type : 'ol', name : 'Online Booking'},{type : 'g', name : 'Games'},{type : 'p', name : 'Practices'},{type : 'cost', name : 'Has Cost Associated'},{type : 'npo', name : 'No Purchase Order'},];
			}
            this.getSettings();
			this.getInitialData();
		},
	}
</script>
<style>
#openice .el-table th {
	font-weight: 400;
}
.openice div.el-table .cell {
	word-break: normal;
}

#openice .el-table__footer div.cell {
	font-size: 12px;
	font-weight: 800;
	color: #000;
}
</style>
<style scoped>

#openice .heading {
	background-color: #1A719C;
	color: #fff;
	font-size: 14px;
}
#openice .body {
	color: #333;
	font-size: 14px;
	padding: 2px;
}
#openice .body input, #openice .body select, #openice .body textarea {
	color: #333;
	font-size: 14px;
}
#openice .body select {
	padding: 1px;
}

#openice .body.row:nth-child(even){
	background-color: #dcdcdc;
}
#openice .body.row:nth-child(odd){
	background-color: #ffffff;
}
#openice .row.buttons {
	margin-top: 20px;
}
.leftCol {
	width:49%;
	display: inline-block;
	padding-left: 0px;
	vertical-align: top;
}
.rightCol {
	width:49%;
	display: inline-block;
	padding-left: 20px;
	vertical-align: top;
}
#openice .el-range-editor--mini.el-input__inner {
	width: 100%;
}
@media print {
    div.TablePrint {
        margin-left: 40px;
    }
}
.TablePrint {
        margin-left: 10px;
    }
.generatePOs {
    margin-top: 15px;
}
a i {
		font-size: 20px;
}

.row.header {
	font-weight: 800;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	height: 50px;
}
.odd {
	background-color: #efefef;
}
.detailheader {
	border-bottom: 1px solid #e0e0e0;
}
.even {
	background-color: #f5f5f5;
}
.danger {
	color: #DC3545;
}
.emailsuccess {
	color: #4CAF50;
}
</style>
