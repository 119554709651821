<template>
	<div id="player">
		<div class="row">
			<div class="col-12">
				<!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
				<input style="display:none" type="text" name="fakeemailremembered"/>
				<input style="display:none" type="password" name="fakepasswordremembered"/>
				<el-form ref="playerForm" label-position="top" :model="player" :rules="playerrules" label-width="120px" >
					<div>
						<div v-loading="loading" class="row associations">
							<div class="select-program">
								<el-form-item :label="config.Params.programName + ':'" prop="ProgramID">
									<el-select v-model="player.ProgramID" placeholder="Select" @change="changeTeams" default-first-option filterable>
										<el-option
											v-for="item in Programs"
											:key="item.ProgramID"
											:label="item.ProgramName"
											:value="item.ProgramID">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item v-if="$propertyAccess('TeamID')" label="Team:" prop="TeamID">
									<el-select v-model="player.TeamID" placeholder="Select" @change="teamChanged"  :disabled="$propertyDisabled('TeamID')" default-first-option filterable clearable>
										<el-option
												v-for="item in Teams"
												:key="item.TeamID"
												:label="item.TeamName"
												:value="item.TeamID">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="Jersey No." prop="JerseyNumber">
									<el-input class="smallNumber" v-model="player.Params.JerseyNumber" type="number">
									</el-input>
								</el-form-item>
							</div>
						</div>
						<div>
							<el-form-item v-if="$propertyAccess('FirstName')" label="First Name" prop="FirstName"
								:rules="[{ required: true, message: 'First Name is required', trigger: 'blur'}]"
							>
								<el-input v-model="player.FirstName" :disabled="$propertyDisabled('FirstName')">
								</el-input>
							</el-form-item>
							<el-form-item v-if="$propertyAccess('LastName')" label="Last Name" prop="LastName"
								:rules="[{ required: true, message: 'Last Name is required', trigger: 'blur'}]"
							>
								<el-input v-model="player.LastName" :disabled="$propertyDisabled('LastName')">
								</el-input>
							</el-form-item>
                            <el-form-item v-if="$propertyAccess('PlayerTypeID') && FilterPlayerTypes.length > 0" label="Player Type:" prop="PlayerTypeID"
                                          :rules="[{ required: true, message: 'Player Type is required', trigger: 'blur'}]" >
                                <el-select v-model="player.PlayerTypeID" placeholder="Select" :disabled="$propertyDisabled('PlayerTypeID')" default-first-option filterable clearable>
                                    <el-option
                                        v-for="item in FilterPlayerTypes"
                                        :key="item.PlayerTypeID"
                                        :label="item.PlayerTypeName"
                                        :value="item.PlayerTypeID">
                                    </el-option>
                                </el-select>
                            </el-form-item>
							<el-form-item v-if="$propertyAccess('EmailAddress')" label="Email" prop="EmailAddress"
								              :rules="[{ type: 'email',  message: 'Email is invalid', trigger: 'blur'}]"
							>
								<el-input v-model="player.EmailAddress" :disabled="$propertyDisabled('EmailAddress')">
								</el-input>
							</el-form-item>
							<el-form-item v-if="$propertyAccess('DOB')" label="D.O.B." prop="Params.DOB">
								<form-date v-model="player.Params.DOB" :value="player.Params.DOB" :disableDate="$propertyDisabled('DOB')" />
							</el-form-item>
                            <el-form-item v-if="$propertyAccess('IDNumber')" label="USA #" prop="IDNumber">
                                <el-input v-model="player.IDNumber" :disabled="$propertyDisabled('IDNumber')">
                                </el-input>
                            </el-form-item>
                            <el-form-item v-if="$propertyAccess('RegistrationApprovalCode')" label="Player Registration No." prop="RegistrationApprovalCode">
                                <el-input v-model="player.RegistrationApprovalCode" :disabled="$propertyDisabled('RegistrationApprovalCode')">
                                </el-input>
                            </el-form-item>
							<el-form-item v-if="$propertyAccess('recordstatus')" label="Status" prop="RecordStatus">
								<el-select v-model="player.recordstatus" placeholder="Select" :disabled="$propertyDisabled('recordstatus')" default-first-option filterable>
									<el-option
											key="A"
											label="Active"
											value="A">
									</el-option>
                                    <el-option
                                        key="I"
                                        label="Not Confirmed"
                                        value="I">
                                    </el-option>
                                    <el-option
                                        key="H"
                                        label="Dropped"
                                        value="H">
                                    </el-option>
                                    <el-option
                                        key="D"
                                        label="Deleted"
                                        value="D">
                                    </el-option>
								</el-select>
							</el-form-item>
							<template v-if="$propertyAccess('Address')" >
								<el-form-item label="Address Line 1" prop="AddressLine1">
									<el-input v-model="player.Params.AddressLine1" :disabled="$propertyDisabled('Address')">
									</el-input>
								</el-form-item>
								<el-form-item label="Address Line 2" prop="AddressLine2">
									<el-input v-model="player.Params.AddressLine2" :disabled="$propertyDisabled('Address')">
									</el-input>
								</el-form-item>
								<el-form-item label="City" prop="City">
									<el-input v-model="player.Params.City" :disabled="$propertyDisabled('Address')">
									</el-input>
								</el-form-item>
								<el-form-item :label="config.Params.state" prop="StateOrProvinceCodeID">
									<el-select v-model="player.Params.StateOrProvinceCodeID" placeholder="Select" @change="setCountry"  :disabled="$propertyDisabled('Address')" default-first-option filterable clearable>
										<el-option-group
												v-for="country in StateProvinceCodes"
												:key="country.Country"
												:label="country.Country">
											<el-option
													v-for="state in country.States"
													:key="state.StateProvinceCodeShortName"
													:label="state.StateProvinceCodeName"
													:value="state.StateProvinceCodeShortName">
											</el-option>
										</el-option-group>
									</el-select>
								</el-form-item>
								<el-form-item :label="config.Params.zipcode" prop="Params.PostalCode">
									<el-input v-model="player.Params.PostalCode" :disabled="$propertyDisabled('Address')">
									</el-input>
								</el-form-item>
							</template>
							<template v-if="$propertyAccess('Phonenumbers')" >
								<el-form-item label="Home Phone" prop="Params.HomePhoneNumber">
									<div class="el-form-item__content">
										<div class="el-input el-input--small el-input__wrapper">
											<input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="player.Params.HomePhoneNumber" :disabled="$propertyDisabled('Phonenumbers')" />
										</div>
									</div>
								</el-form-item>
								<el-form-item label="Mobile" prop="Params.CellPhoneNumber">
									<div class="el-form-item__content">
										<div class="el-input el-input--small el-input__wrapper">
											<input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="player.Params.CellPhoneNumber" :disabled="$propertyDisabled('Phonenumbers')" />
										</div>
									</div>
								</el-form-item>
							</template>
							<template v-if="$propertyAccess('Tags')" >
								<el-form-item label="Player Tags" prop="Tags">
									<div class="tags el-input__inner" style="padding: 7px; height: auto;">
										<el-tag
											v-for="tag in player.Params.taglinks"
											:key="tag.TagID"
											closable
											effect="dark"
											:type="tag.TagType"
											:disable-transitions="false"
											:disabled="$propertyDisabled('Tags')" 
											@close="handleCloseTag(tag)"
										>
											{{tag.TagName}}
										</el-tag>
										<el-select v-if="tagSelectVisible && !$propertyDisabled('Tags')" key="selecttags" v-model="newTagID" ref="saveTagInput" style="width: auto;" @change="handleNewTag" default-first-option clearable filterable>
											<el-option
												v-for="item in availableTags"
												:key="item.TagID"
												:label="item.TagName"
												:value="item.TagID"
											>
												<div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

											</el-option>
										</el-select>
										<el-button v-else-if="availableTags.length > 0 && !$propertyDisabled('Tags')" class="button-new-tag" @click="showTagSelect">+ New Tag</el-button>
									</div>
								</el-form-item>
								<el-form-item label="Notes" prop="Params.Notes">
									<el-input v-model="player.Params.Notes"
											type="textarea"
											:disabled="$propertyDisabled('Tags')" 
											:autosize="{ minRows: 4, maxRows: 8}"
									>
									</el-input>
								</el-form-item>
							</template>
							<template  v-for="(value, name) in player.Params">
	                            <template v-if="paramsfieldsused.indexOf(name) === -1">
	                                <el-form-item :label="name" :prop="name">
	                                    <el-input v-model="player.Params[name]">
	                                    </el-input>
	                                </el-form-item>
	                            </template>
							</template>
                        </div>
					</div>
				</el-form>
			</div>
			<div class="row modalFooter mb-4" >
                <div class="col-12 text-center">
                    <button class="btn btn-success btn-icon" type="button" @click="savePlayer">
                        <i class="fas fa-check"></i> <span> Save Changes</span>
                    </button>
                    <button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)" >
                        <i class="fas fa-times"></i>  <span>Cancel</span>
                    </button>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	import FormDate from './global/FormDate.vue';

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
	export default {
		data () {
			var validatePhoneNumber = (rule, value, callback) => {
				if (value) {
	                if (value.length != 14) {
	                    callback(new Error('Phone Number must be 10 numbers'));
	                } else {
	                    callback();
	                }
                } else {
                    callback();
                }
            };
			var validatePostalCode = (rule, value, callback) => {
				if (value && this.Country) {
					if (this.Country == 'United States') {
						var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
						if (!isValidZip) {
							callback(new Error('Zip Code is invalid'));
						} else {
							callback();
						}
					} else if (this.Country == 'Canada') {
						var isValidZip = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value);
						if (!isValidZip) {
							callback(new Error('Postal Code is invalid'));
						} else {
							callback();
						}
					}
				} else {
					callback();
				}
			};
			var validateDOB = (rule, value, callback) => {
				if (value) {
					var date = new Date();
					var parts = value.split('-');
					let validDate = true;
					let validDays = 0;
                    if (parts[0].length !=4 || parts[1].length > 2 || parts[2].length > 2) {
                        validDate = false;
                    }
					if (parts[0] < 1900 || parts[0] > date.getFullYear()) {
						validDate = false;
					}
					if (parts[1] < 1 || parts[1] > 12) {
						validDate = false;
					}

					switch (parseInt(parts[1])) {
						case 2 :
							validDays = (parts[0] % 4 == 0 && parts[0] % 100) || parts[0] % 400 == 0 ? 29 : 28;
							break;
						case 4 : case 6 : case 9 : case 11 :
							validDays =  30;
							break;
						default :
							validDays =  31;
							break;
					}
					if (parts[2] < 1 || parts[2] > validDays) {
						validDate = false;
					}
					if (!validDate ) {
						callback(new Error("D.O.B. is invalid"));
					} else {
						callback();
					}
				} else {
					callback();
				}
			};
			return {
				loading: true,
                currentStatus : STATUS_INITIAL,
				player : {Params: {DOB : '', PostalCode : '', HomePhoneNumber : '', CellPhoneNumber : '' }, PlayerID : '', FirstName : '', LastName : '', TeamID : '', EmailAddress : '', PlayerTypeID : '', IDNumber : '', RegistrationApprovalCode : ''},
				Programs: [],
                PlayerTypes : [],
                FilterPlayerTypes : [],
				Teams: [],
                Tags: [],
                newTagID: '',
                tagSelectVisible: false,
                DivisionTypes : [],
				OrigData: {},
				StateProvinceCodes : [],
				Country : '',
                paramsfieldsused :['JerseyNumber','DOB', 'AddressLine1','AddressLine2','City','StateOrProvinceCodeID','PostalCode','HomePhoneNumber','CellPhoneNumber','taglinks','Notes', 'waiver','Activation','Registration','ActivationTS','RegistrationTS','Tags'],
				playerrules: {
					'Params.HomePhoneNumber': [
                         { validator: validatePhoneNumber, trigger: 'blur' }
                    ],
					'Params.CellPhoneNumber': [
                         { validator: validatePhoneNumber, trigger: 'blur' }
                    ],
					'Params.PostalCode': [
						{ validator: validatePostalCode, trigger: 'blur' }
					],
					'Params.DOB': [
						{ validator: validateDOB, trigger: 'blur' }
					],
                }
			}
		},
		name: 'Player',
		components: {
			FormDate
		},
	    props: {
	        config : { type: Object },
	        playerData : {
				type: [Object,Array],
				required: false,
				default: () => {}
	        },
	        calling : { type: String,
	                    required: false,
	                    default: 'Profile'
	        },
		    ProgramIDAccess : { type: Boolean,
			    required: false,
			    default: false
		    },
		    TeamIDs : { type: [Object,Array],
			    required: false,
			    default: []
		    },
		    createAccess : { type: Boolean,
			    required: false,
			    default: false
		    }
	    },
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
            modalTitle () {
                return 'Player Message';
            },
            availableTags () {
                var tags = [];
                var tagOk = true;
                for (let i = 0; i < this.Tags.length; i++) {
                    tagOk = true;
                    if (this.player.Params.taglinks) {
                        for (let j = 0; j < this.player.Params.taglinks.length; j++) {
                            if (this.Tags[i].TagID == this.player.Params.taglinks[j].TagID) {
                                tagOk = false;
                                break;
                            }
                        }
                    } else {
                        this.player.Params['taglinks'] = [];
                    }
                    if (this.Tags[i].TagArea != 'PL') {
                        tagOk = false;
                    }
                    if (tagOk) {
                        tags.push(this.Tags[i]);
                    }
                }
                return tags;
            },

		},
		methods: {
            handleNewTag() {
                let newTagID = this.newTagID;
                if (!this.player.Params.taglinks) {
	                this.player.Params['taglinks'] = [];
                }
                if (newTagID) {
                    for (let i = 0; i < this.Tags.length; i++) {
                        if (this.Tags[i].TagID == newTagID) {
                            this.player.Params.taglinks.push(this.Tags[i]);
                            break;
                        }
                    }
                }
                this.tagSelectVisible = false;
                this.newTagID = '';
            },
            handleCloseTag(tag) {
                this.player.Params.taglinks.splice(this.player.Params.taglinks.indexOf(tag), 1);
            },
            showTagSelect() {
                this.tagSelectVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.focus();
                });
            },

            reset(showMessage) {
				if (showMessage) {
					this.$emit('refreshData');
					this.$emit('close');
				} else {
					// reset form to initial state
					this.currentStatus = STATUS_INITIAL;
					this.uploadedFiles = [];
					this.uploadError = null;
					if (showMessage) {
						this.getPlayerData();
						this.$notify({
							title: 'Success',
							message: "Player has been reset",
							type: 'success',
							duration: 1500,
							showClose: false
						});
	                 }
	            }
			},
			setCountry(val) {
				if (val) {
					for (let i = 0; i < this.StateProvinceCodes.length; i++) {
						for (let j = 0; j < this.StateProvinceCodes[i].States.length; j++) {
							if (this.StateProvinceCodes[i].States[j].StateProvinceCodeShortName == val) {
								this.Country = this.StateProvinceCodes[i].States[j].Country;
								break;
							}
						}
					}
				} else {
					this.Country = '';
				}
			},
            teamChanged () {
                this.FilterPlayerTypes = [];
                if (!this.setFilterPlayerTypes(this.player.PlayerTypeID)) {
                    this.player.PlayerTypeID = '';
                }
            },
            setFilterPlayerTypes (PlayerTypeID) {
                var playertypefound = false;
                if (this.player.TeamID) {
                    //we need to find the division type of the team selected and then see what
                    //player types allowed;
                    var TeamIdx = -1;
                    for (let i = 0; i < this.Teams.length; i++) {
                        if (this.Teams[i]['TeamID'] == this.player.TeamID) {
                            TeamIdx = i;
                            break;
                        }
                    }
                    var playertypeids = [];
                    var types = this.DivisionTypes[this.Teams[TeamIdx]['RegisteredLeagueID']];
                    for (let i = 0; i < types.length; i++) {
                        if (types[i]['DivisionTypeID'] == this.Teams[TeamIdx]['RegisteredDivisionTypeID']) {
                            if (types[i]['Params']['Amounts']) {
                                for (let j = 0; j < types[i]['Params']['Amounts'].length; j++) {
                                    if (types[i]['Params']['Amounts'][j]['PlayerTypeID']) {
                                        playertypeids.push(types[i]['Params']['Amounts'][j]['PlayerTypeID']);
                                        if (PlayerTypeID && PlayerTypeID == types[i]['Params']['Amounts'][j]['PlayerTypeID']) {
                                            playertypefound = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (playertypeids.length > 0) {
                        for (let i = 0; i < this.PlayerTypes.length; i++) {
                            if (playertypeids.indexOf(this.PlayerTypes[i]['PlayerTypeID']) !== -1) {
                                this.FilterPlayerTypes.push(this.PlayerTypes[i]);
                            }
                        }
                    } else {
                        this.FilterPlayerTypes = [];
                    }
                }
                return playertypefound;
            },
			getInitialData () {
				var tables = ['Programs','StateProvinceCodes', 'PlayerTypes','Tags'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
						this.getPlayerData();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getTeams () {
				if (this.player.ProgramID) {
					this.Teams = [];
					var tables = ['Teams', 'DivisionTypes'];
					axios.post('/index/tableData', {'tables': tables, 'ProgramID' : this.player.ProgramID,  'SeasonID' : this.player.SeasonID,  'TeamIDs' : this.TeamIDs},
					)
	                .then(response => {
	                    if (response.data.result == 'success') {
	                        for (let value of tables) {
	                            this[value] = response.data[value];
	                        }
	                    } else {
	                        this.$failProgressBar(this.parentrefs);
	                    }
	                    this.setFilterPlayerTypes();
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
	                });
	            }
			},
			getPlayerData () {
                if (this.playerData.PlayerID) {
                    this.player = Object.assign({}, this.playerData);
                } else {
                    this.player.SeasonID = this.playerData.SeasonID;
                    this.player.ProgramID = this.playerData.ProgramID;
                }
				if (!this.player.Params.DOB) {
					this.player.Params.DOB = '';
				}
				this.getTeams();
				this.setCountry(this.player.Params.StateOrProvinceCodeID);
	            this.loading = false;
			},
			savePlayer () {
				var execSave = false;
				this.$refs['playerForm'].validate((valid) => {
					if (valid) {

						if (this.currentStatus == STATUS_SAVING) {
							return true;
						}
						this.currentStatus = STATUS_SAVING;
						this.$startProgressBar(this.parentrefs);

						axios.post('programplayers/save', {'Player': this.player}
						)
							.then(response => {
								if (response.data.result == 'success') {
									this.currentStatus = STATUS_INITIAL;
									this.$stopProgressBar(this.parentrefs);
									this.$notify({
										title: 'Success',
										message: "Player has been saved",
										type: 'success',
										duration: 1500,
										showClose: false
									});
									this.$emit('refreshData');
									this.$emit('close');
								} else {
									this.currentStatus = STATUS_FAILED;
									this.displayPopup(response.data.message);
									this.$failProgressBar(this.parentrefs);
								}
							}).catch(response => {
							this.currentStatus = STATUS_FAILED;
							this.$failProgressBar(this.parentrefs);
						});
					} else {
						//scroll to errors
						setTimeout(() => {
							document.querySelector("#player .is-error").scrollIntoView();
						}, 250);
					}
				});
			},
            changeTeams(){
                this.getTeams();
                this.player.TeamID = '';
            },
	        displayPopup (message) {
                this.$alert(message, 'Player Error', {
                    confirmButtonText: 'OK',
                });
            },
		},
		mounted() {
			this.reset(false);
			this.getInitialData();
		},

	}
</script>


<style>
	#player .card {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.badge {
		cursor: pointer;
		margin-left: 10px;
	}
	.modalFooter {
	    position: relative;
	    bottom: 0;
	    width: 100%;
	    margin: 10px 0px;
	}
    div.select-program {
		width: 100%;
	    padding: 0;
    }
	.jerseynumber {
		width: 75px;
	}
	#app #player .el-select {
		width: 100%;
	}
	#player .el-form--label-top .el-form-item__label {
		font-size: 12px;
		line-height: 15px;
	}
	#player .el-form-item__content {
		line-height: 20px;
	}
	#player .el-form-item.is-error {
		margin-bottom: 16px;
	}
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .associations .el-select {
		width: 100%;
	}
	#player .FormDate {
		height: 32px;;
	}
</style>
