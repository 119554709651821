<template>
	<div id="apitest" v-loading="loading">
		<div class="row">
			<div class="col-12">
                <el-form ref="apitest" label-position="top" label-width="120px" >
                    <div class="row form-group col-12 mt-4">
                        <div class="card" v-loading="loading">
                            <div class="card-header bg-primary details"><h5>API Testing</h5></div>
                            <div class="card-body">
                                 <div class="row mt-4">
                                    <div class="col-12">
                                        <el-form-item label="API Key:" prop="APIKey">
                                            <el-select v-if="secure" v-model="APIKey" class="oneHundred" @change="getAPIData" default-first-option filterable>
                                                <template v-for="type in APIKeys" :key="type.APIKey">
                                                    <el-option 
                                                            v-if="type.APIKeyID > 0"
                                                            :value="type.APIKey"
                                                            :label="type.APIName + ' - ' + type.APIKey">
                                                    </el-option>
                                                </template>
                                            </el-select>
                                            <el-input v-else v-model="APIKey" class="oneHundred" @change="getAPIData"></el-input>
                                        </el-form-item>
                                        <div class="mt-4" style="display: inline;">API Endpoint: </div><el-input readonly="readonly" @focus="$event.target.select()" v-model="apiurl" />
                                    </div>
                                    <div class="col-6 mt-2">
                                        <el-form-item label="GET/POST: (initially, only GET will be available)" prop="RequestType">
                                            <el-select v-model="RequestType" default-first-option filterable>
                                                <el-option v-for="type in RequestTypes"
                                                        :value="type"
                                                        :key="type"
                                                        :label="type">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col-6 mt-2">
                                        <el-form-item label="Data:" prop="RequestData">
                                            <el-select v-model="RequestData" default-first-option filterable @change="setParams">
                                                <el-option v-for="type in APIAreas"
                                                        :value="type"
                                                        :key="type"
                                                        :label="type">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-body">
                                            <el-button v-if="paramsRemaining != 0" @click.prevent="newParam()"><i class="fa-solid fa-circle-plus"></i> Add New Key/Value Pair</el-button>
                                            <template v-if="paramsRemaining > 0 || APIParams.length > 0">
                                                <div class="row keys-header mt-1">
                                                    <div class="col-5" style="text-align:center;">
                                                        Key
                                                    </div>
                                                    <div class="col-5" style="text-align:center;">
                                                        Value
                                                    </div>
                                                    <div class="col-2">
                                                        
                                                    </div>
                                                </div>
                                                <div v-for="(data, index) in APIParams" class="row keys">
                                                    <div class="col-5 key-name" >
                                                        <el-select v-model="data.key" class="oneHundred" default-first-option filterable>
                                                        <el-option v-for="type in paramKeys[APIKeyAreasIDs[RequestData]]"
                                                                :value="type.keyID"
                                                                :key="type.keyID"
                                                                :label="type.keyLabel">
                                                        </el-option>
                                                    </el-select>
                                                    </div>
                                                    <div class="col-5 key-name">
                                                        <template v-if="dataType(data.key) == 'select' && dropdownData(data.key)">
                                                            <el-select v-model="data.value" class="oneHundred" default-first-option filterable :clearable="dropdownData(data.key).length > 1">
                                                                <el-option v-for="type in dropdownData(data.key)"
                                                                        :value="type[data.key]"
                                                                        :key="type[data.key]"
                                                                        :label="type[data.key.replace('ID','Name')]">
                                                                </el-option>
                                                            </el-select>
                                                        </template>
                                                        <template v-else-if="dataType(data.key) == 'multiselect' && dropdownData(data.key)">
                                                            <el-select v-model="data.multivalue" class="oneHundred" default-first-option filterable multiple clearable>
                                                                <el-option v-for="type in dropdownData(data.key)"
                                                                        :value="type[data.key.replace('IDs','ID')]"
                                                                        :key="type[data.key.replace('IDs','ID')]"
                                                                        :label="type[data.key.replace('IDs','Name')]">
                                                                </el-option>
                                                            </el-select>
                                                        </template>
                                                        <template v-else-if="dataType(data.key) == 'date'">
                                                            <el-date-picker v-model="data.value" default-first-option filterable
                                                                    type="date"
                                                                    style="width: 100%"
                                                                    format="MMM D, YYYY"
                                                                    value-format="YYYY-MM-DD"
                                                                    placeholder="Date">
                                                                </el-date-picker>
                                                            </template>
                                                        <template v-else-if="dataType(data.key) == 'text'">
                                                            <el-input class="oneHundred" v-model="data.value" ></el-input>
                                                        </template>
                                                        <div v-else-if="data.key">** Other Parameters need to be completed before this one will show values **</div>
                                                    </div>
                                                    <div class="col-2" style="padding-top: 5px;">
                                                        <button v-if="!data.required" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteParam(index)">
                                                            <div class="v-btn__content">
                                                                <i class="far fa-2x fa-trash-alt"></i>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <el-button v-if="APIKey && RequestData" style="margin-left: 17px;" class="mt-4 mb-4" type="success" @click="submitAPITest"> Submit API Request</el-button>
                                <h5 class="mt-2">Request Params</h5>
                                <el-input
                                    style="width: 100%;"
                                    v-model="URLParams"
                                    :autosize="{ minRows: 5, maxRows: 50 }"
                                    type="textarea"
                                />
                                <h5 class="mt-2">Response</h5>
                                <el-input 
                                    style="width: 100%;"
                                    v-model="apiResponse"
                                    :autosize="{ minRows: 5, maxRows: 50 }"
                                    type="textarea"
                                />
                                <h5 class="mt-4">Data</h5>
                                <el-table
                                    :data="apidata"
                                    border
                                    stripe
                                    key="datatable"
                                    empty-text="No Data"
                                    max-height="600"
                                    size="small"
                                    >
                                    <el-table-column type="index" width="50" align="center" />
                                    <el-table-column v-for="(keys,index) in apidatakeys"
                                        :key="`cols-${index}`"
                                        :prop="keys"
                                        :label="apidatakeys[index]"
                                        align="left"
                                        min-width="150"
                                        >
                                        <template #default="scope">
                                            <div v-html="(typeof scope.row[keys] === 'object' ? JSON.stringify(scope.row[keys]) : scope.row[keys])"></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
    import Util from '../assets/js/util.js'
import { setTransitionHooks } from 'vue';

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_FILESAVING1 = 4, STATUS_FILESAVING2 = 5, STATUS_FILESAVING3 = 6;
	export default {
		data () {
			return {
                loading : false,
                currentStatus : STATUS_INITIAL,
                APIKeys : [],
				APISeasons : [],
                APILeagues : [],
                APIPrograms : [],
                APITeams : [],
                APIKeyAreas : [],
                APIKeyAreasIDs : {},
                APIKeyAreasLabels : {},
                RequestTypes : ['GET'],
                RequestType : 'GET',
                RequestData : '',
                APIKey : '',
                APIParams : [],
                URLParams : '',
                apiResponse : '',
                apidata : [],
                apidatakeys : [],
                paramKeys : [],
                apiurl : '',
                SeasonID : '',
                LeagueID : '',
                CustomerID : '',
                requiredParams : {Teams : ['SeasonID', 'LeagueID'], Divisions : ['SeasonID', 'LeagueID'], Contacts : ['SeasonID', 'LeagueID'], Players : ['SeasonID', 'LeagueID'], Games : ['SeasonID', 'LeagueID'], Programs : ['SeasonID', 'LeagueID'], Leagues : ['SeasonID']},
			}
		},
		name: 'APITest',
		props: {
			config: {
				type: Object,
				required: true,
				default: () => {}
			},
            secure : {
                type: Boolean,
			    required: false,
                default : false
            },
            SettingsCustomerID : {
                type: String,
			    required: false,
                default : false
            }
		},
        watch: {
            SettingsCustomerID: {
                handler(newVal, oldVal) {
                    this.CustomerID = newVal;
                    this.getInitialData ();
                },
                deep: true
            },
        },
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
            APIAreas () {
                var validAreas = [];
                if (this.APIKey) {
                    for (let i = 0; i < this.APIKeys.length; i++) {
                        if (this.APIKeys[i].APIKey == this.APIKey) {
                            for (let j = 0; j < this.APIKeyAreas.length; j++) {
                                var propertyID = this.APIKeyAreasIDs[this.APIKeyAreas[j]];
                                if (!this.APIKeys[i].Params.Properties[propertyID] || this.APIKeys[i].Params.Properties[propertyID] == 'A') {
                                    validAreas.push(this.APIKeyAreasLabels[propertyID]);
                                }
                            }
                        }
                    }
                }
                validAreas.sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });

                return validAreas;
            },
            paramsRemaining() {
                if (!this.RequestData) { return 0; }
                var RequestDataID = this.APIKeyAreasIDs[this.RequestData];
                var paramsleft = 0;
                if (this.paramKeys[RequestDataID]) {
                    paramsleft = this.paramKeys[RequestDataID].length;
                    for (let i=0; i < this.paramKeys[RequestDataID].length; i++) {
                        for (let j=0; j < this.APIParams.length; j++) {
                            if (this.APIParams[j].key == this.paramKeys[RequestDataID][i].keyID) {
                                paramsleft--;
                                break;
                            }
                        }
                    }
                }
                return paramsleft;
            }
		},
		methods: {
            dropdownData (Param) {
                var SeasonID = '';
                var LeagueID = '';
                var ProgramID = '';
                var paramIDX = -1;
                for (let i = 0; i < this.APIParams.length; i++) {
                    if (this.APIParams[i].key == 'SeasonID'){
                        SeasonID = this.APIParams[i].value;
                    }
                    if (this.APIParams[i].key == 'LeagueID'){
                        LeagueID = this.APIParams[i].value;
                    }
                    if (this.APIParams[i].key == 'ProgramID'){
                        ProgramID = this.APIParams[i].value;
                    }
                    if (this.APIParams[i].key == Param) {
                        paramIDX = i;
                    }
                }
                if (Param == 'SeasonID') {
                    if (this.APISeasons.length == 1) {
                        this.APIParams[paramIDX].value = this.APISeasons[0].SeasonID;
                    }
                    return this.APISeasons;
                } else if (Param == 'LeagueID') {
                    if (this.APILeagues[SeasonID].length == 1) {
                        this.APIParams[paramIDX].value = this.APILeagues[SeasonID][0].LeagueID;
                    }
                    return this.APILeagues[SeasonID];
                } else if (Param == 'ProgramID') {
                    if (this.APIPrograms[SeasonID][LeagueID].length == 1) {
                        this.APIParams[paramIDX].value = this.APIPrograms[SeasonID][LeagueID][0].ProgramID;
                    }
                    return this.APIPrograms[SeasonID][LeagueID];
                } else if (Param == 'TeamIDs') {
                    return this.APITeams[SeasonID][LeagueID][ProgramID];
                }
                return '';
            },
            dataType (Param) {
                if (this.RequestData) {
                    var RequestDataID = this.APIKeyAreasIDs[this.RequestData];
                    var key = this.paramKeys[RequestDataID];
                    for (let i = 0; i < key.length; i++) {
                        if (key[i].keyID == Param) {
                            return key[i].fieldtype;
                        }
                    }
                    return '';
                }
            },
            newParam () {
                this.APIParams.push({key : '', value : '', multivalue : [], required : false});
            },
            deleteParam (idx) {
                this.APIParams.splice(idx,1);
            },
            setParams () {
                var RequestDataID = this.APIKeyAreasIDs[this.RequestData];
                if (this.requiredParams[RequestDataID] ) {
                    for (let i=0; i < this.requiredParams[RequestDataID].length; i++) {
                        var paramfound = false;
                        for (let j=0; j < this.APIParams.length; j++) {
                            if (this.APIParams[j].key == this.requiredParams[RequestDataID][i]) {
                                paramfound = true;
                                break;
                            }
                        }
                        if (!paramfound) {
                            if (!this.APIParams) {
                                this.APIParams = [];
                            }
                            this.APIParams.push({key : this.requiredParams[RequestDataID][i], value : '', multivalue : [], required : true});
                        }
                    }
                } else {
                    this.APIParams = [];
                }
                //now remove any existing params that are no longer supported
                for (let j = (this.APIParams.length-1); j >= 0; j--) {
                    var paramfound = false;
                    for (let i=0; i < this.paramKeys[RequestDataID].length; i++) {
                        if (this.APIParams[j].key == this.paramKeys[RequestDataID][i].keyID) {
                            paramfound = true;
                            break;
                        }
                    }
                    if (!paramfound) {
                        console.log('Remove: ' + this.APIParams[j].key);
                        this.APIParams.splice(j,1);
                    }
                }
            },
			getInitialData () {
                this.APIKey = '';
				var tables = ['APIKeys'];
				axios.post('/index/tableData', { tables : tables, CustomerID : this.CustomerID},
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getAPIData () {
				var tables = ['APISeasons', 'APILeagues', 'APIPrograms', 'APITeams'];
				axios.post('/settings/apiData', {APIKey : this.APIKey},
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        this.apiurl = response.data.apiurl;
                        this.APIKeys[0] = response.data.APIKeyData;
                    } else {
                        this.$notify({
                                title: 'Error',
                                message: 'API Key is invalid',
                                type: 'error',
                                duration: 3000,
                                showClose: false
                            });
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
            submitAPITest () {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                } 
                this.currentStatus = STATUS_SAVING;
                this.loading = true;
                this.apiResponse = '';
                this.apidata = [],
                this.apidatakeys = [],
				axios.post('/settings/apitest', {RequestType : this.RequestType, RequestData : this.RequestData, APIKey : this.APIKey, APIParams : this.APIParams},
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        if (response.data.APIresp.Status == 'success') {
                            this.apiResponse = JSON.stringify(response.data.APIresp);
                            this.URLParams = JSON.stringify(response.data.URLParams);
                            for (let i = 0; i < response.data.APIresp.Data.length; i++) {
                                var row = response.data.APIresp.Data[i];
                                for (const property in row) {
                                    if (this.apidatakeys.indexOf(`${property}`) == -1) {
                                        this.apidatakeys.push(`${property}`);
                                    }
                                }
                            }
                            this.apidata = response.data.APIresp.Data;
                            this.$notify({
                                title: 'Success',
                                message: "See Returned API Data",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                        } else {
                            this.$notify({
                                title: 'Error',
                                message: response.data.APIresp.Message,
                                type: 'warning',
                                duration: 4500,
                                showClose: false
                            });
                            this.apiResponse = JSON.stringify(response.data.APIresp);
                            this.URLParams = JSON.stringify(response.data.URLParams);
                        }
                    } else {
                        this.$notify({
							title: 'Error',
							message: 'API Request could not be processed',
							type: 'warning',
							duration: 2500,
							showClose: false
						});
                        this.apiResponse = JSON.stringify(response.data.APIresp);
                        this.URLParams = JSON.stringify(response.data.URLParams);
                    }
                    this.currentStatus = STATUS_INITIAL;
                    this.loading = false;
                }).catch(response => {
                    this.loading = false;
                    this.currentStatus = STATUS_INITIAL;
                    this.$notify({
							title: 'Error',
							message: 'API Request could not be processed',
							type: 'warning',
							duration: 2500,
							showClose: false
						});
                });

            },
	        displayPopup (message) {
				this.$alert(message, 'Settings Error', {
					confirmButtonText: 'OK',
				});
	        }
		},
		mounted() {
            if (this.SettingsCustomerID != '') {
                this.CustomerID = this.SettingsCustomerID;
            } else {
                this.CustomerID = this.currentUser.CustomerID;
            }
            if (this.secure) {
			    this.getInitialData();
            }
            //'Availability Calendar', 
            //'Constraint Calendar', 
            this.APIKeyAreas = [
                this.config.Params.mainlocationNames,
                this.config.Params.locationNames,
                'Contacts', 
                'Divisions', 
                'Leagues', 
                'Live ' + this.config.Params.games, 
                'Organizations', 
                'Players', 
                this.config.Params.programNames, 
                'Teams',
                'Seasons', 
            ];
            this.APIKeyAreas.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            this.APIKeyAreasIDs = {
                [this.config.Params.mainlocationNames] : 'MainLocations', 
                [this.config.Params.locationNames] : 'Locations',
                'Availability Calendar' : 'CalendarSlots',
                'Constraint Calendar' : 'ConstraintSlots',
                'Contacts' : 'Contacts',
                'Divisions' : 'Divisions',
                'Leagues' : 'Leagues',
                ['Live ' + this.config.Params.games] : 'Games',
                'Organizations' : 'Organizations',
                'Players' : 'Players',
                [this.config.Params.programNames] : 'Programs',
                'Teams' : 'Teams',
                'Seasons' : 'Seasons',
            };
            this.APIKeyAreasLabels = {
                'MainLocations' : this.config.Params.mainlocationNames, 
                'Locations' : this.config.Params.locationNames,
                'CalendarSlots' : 'Availability Calendar',
                'ConstraintSlots' : 'Constraint Calendar',
                'Contacts' : 'Contacts',
                'Divisions' : 'Divisions',
                'Leagues' : 'Leagues',
                'Games' : 'Live ' + this.config.Params.games,
                'Organizations' : 'Organizations',
                'Players' : 'Players',
                'Programs' : this.config.Params.programNames,
                'Teams' : 'Teams',
                'Seasons' : 'Seasons',
            };

            this.paramKeys['Seasons'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}];
            this.paramKeys['MainLocations'] = [];
            this.paramKeys['Locations'] = [];
            this.paramKeys['Organizations'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'},{keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}];
            this.paramKeys['Games'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'GameID', keyLabel : 'GameID', fieldtype : 'text'}, {keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}, {keyID : 'TeamIDs', keyLabel : 'Teams', fieldtype : 'multiselect'},  {keyID : 'FromDate', keyLabel : 'FromDate', fieldtype : 'date'}, {keyID : 'ToDate', keyLabel : 'ToDate', fieldtype : 'date'}, {keyID : 'Start', keyLabel : 'Start', fieldtype : 'text'}, {keyID : 'End', keyLabel : 'End', fieldtype : 'text'}];
            this.paramKeys['CalendarSlots'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}, {keyID : 'TeamIDs', keyLabel : 'Teams', fieldtype : 'multiselect'},  {keyID : 'FromDate', keyLabel : 'FromDate', fieldtype : 'date'}, {keyID : 'ToDate', keyLabel : 'ToDate', fieldtype : 'date'}, {keyID : 'Start', keyLabel : 'Start', fieldtype : 'text'}, {keyID : 'End', keyLabel : 'End', fieldtype : 'text'}];
            this.paramKeys['ConstraintSlots'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}, {keyID : 'TeamIDs', keyLabel : 'Teams', fieldtype : 'multiselect'},  {keyID : 'FromDate', keyLabel : 'FromDate', fieldtype : 'date'}, {keyID : 'ToDate', keyLabel : 'ToDate', fieldtype : 'date'}, {keyID : 'Start', keyLabel : 'Start', fieldtype : 'text'}, {keyID : 'End', keyLabel : 'End', fieldtype : 'text'}];
            this.paramKeys['Contacts'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}, {keyID : 'TeamIDs', keyLabel : 'Teams', fieldtype : 'multiselect'}, {keyID : 'ContactID', keyLabel : 'ContactID', fieldtype : 'text'}, {keyID : 'Start', keyLabel : 'Start', fieldtype : 'text'}, {keyID : 'End', keyLabel : 'End', fieldtype : 'text'}];
            this.paramKeys['Players'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}, {keyID : 'PlayerID', keyLabel : 'PlayerID', fieldtype : 'text'},{keyID : 'TeamIDs', keyLabel : 'Teams', fieldtype : 'multiselect'}, {keyID : 'Start', keyLabel : 'Start', fieldtype : 'text'}, {keyID : 'End', keyLabel : 'End', fieldtype : 'text'}];
            this.paramKeys['Leagues'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}];
            this.paramKeys['Divisions'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'LeagueDivisionID', keyLabel : 'LeagueDivisionID', fieldtype : 'text'}];
            this.paramKeys['Teams'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}, {keyID : 'TeamID', keyLabel : 'TeamID', fieldtype : 'text'}];
            this.paramKeys['Programs'] = [{keyID : 'SeasonID', keyLabel : 'Season', fieldtype : 'select'}, {keyID : 'LeagueID', keyLabel : 'League', fieldtype : 'select'}, {keyID : 'ProgramID', keyLabel : this.config.Params.programName, fieldtype : 'select'}];

    		},
	}
</script>
<style>
    #app.apitest .page-wrapper {
        margin: 0px;
    }

    #app.apitest .container-fluid {
        padding-left: 0;
    }
</style>
<style scoped>
    #apitest .form-horizontal {
        width: 100%;
    }
    .data-properties .el-button--mini.is-circle {
        float: right;
        padding: 3px;
        font-size: 10px;
        line-height: 7px;
        margin-top: 3px;
        float:right;
    }
    .odd {
        background-color: #f6f6f6;
    }
    .col-3.data-properties {
        font-size: 12px;
    }
    #apitest .card {
		padding-top: 0px;
		padding-bottom: 0px;
	}     
    .keys-header {
		border: 1px solid #ced4da;
		margin-bottom: 0;
		padding: 15px;
		background-color: #ced4da;
		font-weight: 800;
	}
	.keys {
		border: 1px solid #ced4da;
		border-top: 0;
		margin-bottom: 0;
	}
	.keys, .data-access {
		border-right: 1px solid #ced4da;
		padding: 5px;
	}
	.key-name {
		font-weight: 600;
		border-right: 1px solid #ced4da;
		vertical-align: middle;
	}
	.security-access {
		padding-left: 15px;
	}
</style>
