import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/components/Dashboard'
import CustomerDashboard from '@/components/analyzer/CustomerDashboard'

import Login from '@/components/Login'
import Logout from '@/components/Logout'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/ResetPassword'

import APITest from '@/components/APITest'
import Profile from '@/components/Profile'
import GameReporting from '@/components/GameReporting'
import GameScoreReporting from '@/components/GameScoreReporting'
import MobileGameReporting from '@/components/MobileGameReporting'
const FormDisplay  = () =>  import(/* webpackChunkName: "formdisplay" */ '@/components/FormDisplay')
import ReportDisplay from '@/components/ReportDisplay'
import FormSubmissionReport from '@/components/FormSubmissionReport'
import Notifications from '@/components/Notifications'
import GameCancellation from '@/components/GameCancellation'
import GameCancellationReport from '@/components/GameCancellationReport'
import GameCancellationResponse from '@/components/GameCancellationResponse'
import GameChangeResponse from '@/components/GameChangeResponse'
const Gamesheets  = () =>  import(/* webpackChunkName: "gamesheet" */ '@/components/Gamesheets')
const PlayerRegistration  = () =>  import(/* webpackChunkName: "playerregistration" */ '@/components/PlayerRegistration')
import TeamPlayerPayments from '@/components/TeamPlayerPayments'
import ProgramContacts from '@/components/ProgramContacts'
import SiteContacts from '@/components/SiteContacts'
import ProgramPlayers from '@/components/ProgramPlayers'
import ProgramTeamRegistration from '@/components/ProgramTeamRegistration'
const LinkedTeamRegistration  = () =>  import(/* webpackChunkName: "linkedteamregistration" */ '@/components/LinkedTeamRegistration')

import FacilityUsage from '@/components/FacilityUsage'
import FinanceDashboard from '@/components/FinanceDashboard'
import TeamsReport from '@/components/TeamsReport'
import ContractApproval from '@/components/ContractApproval';
import EventAttendance from '@/components/EventAttendance';
import ReportWriter from '@/components/ReportWriter';
import GameReport from '@/components/GameReport';
import AssignGames from '@/components/AssignGames';
import TeamConstraints from '@/components/TeamConstraints'; 

import Billings from '@/components/Billings'
import Invoices from '@/components/Invoices'
import InvoiceCredits from '@/components/InvoiceCredits'
import InvoicePayments from '@/components/InvoicePayments'
import InvoiceBalances from '@/components/InvoiceBalances'
import InvoiceSettings from '@/components/InvoiceSettings'
import TeamsBillingReport from '@/components/TeamsBillingReport'
import BankAccounts from '@/components/BankAccounts'
import PlayerPayments from '@/components/PlayerPayments'
import Organizations from '@/components/Organizations'
import Features from '@/components/Features'
//import Features from '@/components/FeaturesComp'
import Plans from '@/components/Plans'

const ProgramLocations = () => import(/* webpackChunkName: "admin" */ '@/components/ProgramLocations')
const FormDefinitions = () => import(/* webpackChunkName: "admin" */ '@/components/FormDefinitions')
const SystemText = () => import(/* webpackChunkName: "admin" */ '@/components/SystemText')
const EmailCampaign = () => import(/* webpackChunkName: "admin" */ '@/components/EmailCampaign')
const LiveBarnLocations = () => import(/* webpackChunkName: "admin" */ '@/components/LiveBarnLocations')
const LoginRoles = () => import(/* webpackChunkName: "admin" */ '@/components/LoginRoles')
const SecurityAreas = () => import(/* webpackChunkName: "admin" */ '@/components/SecurityAreas')
const Settings = () => import(/* webpackChunkName: "admin" */ '@/components/Settings')
const SuperUserPassword = () => import(/* webpackChunkName: "admin" */ '@/components/SuperUserPassword')
const Search = () => import(/* webpackChunkName: "admin" */ '@/components/Search')
const Suspensions = () => import(/* webpackChunkName: "admin" */ '@/components/Suspensions')
const Users = () => import(/* webpackChunkName: "admin" */ '@/components/Users')
const WidgetBuilder = () => import(/* webpackChunkName: "admin" */ '@/components/WidgetBuilder')
const WidgetFormats = () => import(/* webpackChunkName: "admin" */ '@/components/WidgetFormats')

const AnalyzerDashboard = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/AnalyzerDashboard')
const AnalyzerCalendarSlots = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/CalendarSlots')
const AnalyzerLeagues = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Leagues')
const AnalyzerGameManagement = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/GameManagement')
const AnalyzerHorizonManagement = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/HorizonManagement')
const AnalyzerLocations = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Locations')
const AnalyzerPrograms = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Programs')
const AnalyzerRegistrationSettings = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/RegistrationSettings')
const AnalyzerReferenceData = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/ReferenceData')
const AnalyzerSeasons = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Seasons')
const AnalyzerScheduling = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Scheduling')
const SwapSchedules = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/SwapSchedules')
const AnalyzerScheduleListing = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/ScheduleListing')
const AnalyzerScheduleSettings = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/ScheduleSettings')
const AnalyzerStandingsProfiles = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/StandingsProfiles')
const AnalyzerSports = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Sports')
const AnalyzerTeams = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Teams')
const Tags = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Tags')
const AnalyzerGenerateSchedules = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/GenerateSchedules')
const Tournaments = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Tournaments')
const Trash = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/Trash')
const Imports = () => import(/* webpackChunkName: "analyzer" */ '@/components/Imports')
const AnalyzerLeagueRoles = () => import(/* webpackChunkName: "analyzer" */ '@/components/analyzer/LeagueRoles')

const routes =  [
	{
		path: '/',
		name: 'Login ',
		meta: {
			requiresAuth: false
		},
		component: Login,
		alias: '/login'
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			requiresAuth: false
		},
		component: Login,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		meta: {
			requiresAuth: true
		},
		component: Dashboard,
		alias: ['/index.php', '/index.html']
	},
	{
		path: '/profile',
		name: 'Profile',
		meta: {
			requiresAuth: true
		},
		component: Profile
	},
	{
		path: '/forgotpassword',
		name: 'Forgot Password',
		meta: {
			requiresAuth: false
		},
		component: ForgotPassword
	},
	{
		path: '/formdefinitions',
		name: 'Form Builder',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: FormDefinitions
	},
	{
		path: '/formsubmissionreport',
		name: 'Form Submissions',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: FormSubmissionReport
	},
	{
		path: '/formsubmissionreport/:id',
		name: 'Form Submission',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: FormSubmissionReport
	},
	{
		path: '/financedashboard',
		name: 'Finance Dashboard',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: FinanceDashboard
	},
	{
		path: '/emailstatements',
		name: 'Email Statements',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: FinanceDashboard
	},

	{
		path: '/generateinvoices',
		name: 'Generate Invoices',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Billings
	},
	{
		path: '/adjustments',
		name: 'Adjustments',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: InvoiceCredits
	},
	{
		path: '/invoicePayments',
		name: 'Payments',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: InvoicePayments
	},
	{
		path: '/bankaccounts',
		name: 'BankAccounts',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: BankAccounts
	},
	{
		path: '/invoicebalances',
		name: 'Balances',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: InvoiceBalances
	},
	{
		path: '/billingaccounts',
		name: 'Billing Accounts',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: InvoiceSettings
	},
	{
		path: '/emailcampaign',
		name: 'Email/SMS/Export',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: EmailCampaign
	},
	{
		path: '/livebarnlocations',
		name: 'LiveBarn LOCATION_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: LiveBarnLocations
	},
	{
		path: '/resetpassword',
		name: 'Reset Password',
		meta: {
			requiresAuth: false
		},
		component: ResetPassword,
		alias: ['/activate']
	},
	{
		path: '/assigngames',
		name: 'Assign GAME_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: AssignGames
	},
	{
		path: '/teamblackouts',
		name: 'Team Blackouts',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: TeamConstraints
	},
	{
		path: '/form/:formname',
		name: 'FormDisplay',
		meta: {
			requiresAuth: false
		},
		component: FormDisplay,
		alias: ['/payment/:formname']
	},
	{
		path: '/apitest',
		name: 'APITest',
		meta: {
			requiresAuth: false
		},
		component: APITest,

	},
	{
		path: '/report/:reportid',
		name: 'Report Display',
		meta: {
			requiresAuth: false
		},
		component: ReportDisplay
	},
	{
		path: '/gamecancellation',
		name: 'GAME Cancellation',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: GameCancellation
	},
	{
		path: '/gamecancellationreport/:id',
		name: 'GAME Cancellation Report Item',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			seasonDropdown: false
		},
		component: GameCancellationReport
	},
	{
		path: '/gamecancellationreport',
		name: 'GAME Cancellation Report',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			seasonDropdown: false
		},
		component: GameCancellationReport
	},
	{
		path: '/gamecancellationresponse',
		name: 'GAME Cancellation Response',
		meta: {
			requiresAuth: false,
			requiresSecurity: false
		},
		component: GameCancellationResponse
	},
	{
		path: '/gamechangeresponse',
		name: 'GAME Change Response',
		meta: {
			requiresAuth: false,
			requiresSecurity: false
		},
		component: GameChangeResponse
	},
	{
		path: '/features',
		name: 'Features',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Features
	},
	{
		path: '/plans',
		name: 'Plans',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Plans
	},
	{
		path: '/customerdashboard',
		name: 'Customer Dashboard',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: CustomerDashboard
	},
	{
		path: '/organizations',
		name: 'Organizations',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Organizations
	},
	{
		path: '/organizations/:organizationid',
		name: 'Organizations ',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Organizations
	},
	{
		path: '/facilityusage',
		name: 'Facility Usage Report',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: FacilityUsage
	},
	{
		path: '/purchaseorder',
		name: 'Purchase Orders',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: FacilityUsage
	},
	{
		path: '/eventattendance',
		name: 'Event Attendance',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: EventAttendance
	},
	{
		path: '/reportwriter',
		name: 'Report Writer',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: ReportWriter
	},
	{
		path: '/reportwriter/:id',
		name: 'Report',
		meta: {
			requiresAuth: true,
			requiresSecurity: false,
			seasonDropdown: false
		},
		component: ReportWriter
	},
	{
		path: '/forfeitreport',
		name: 'Forfeit Report',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: GameReport
	},
	{
		path: '/analyzer/imports',
		name: 'Import Data',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
		},
		component: Imports,
	},
	{
		path: '/playerpayments',
		name: 'Player League Balances',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: PlayerPayments,
		alias : '/playerleaguebalances'
	},
	{
		path: '/teamplayerpayments',
		name: 'Player Payments',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: TeamPlayerPayments
	},
	{
		path: '/search',
		name: 'Universal Search',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Search
	},
	{
		path: '/sitecontacts',
		name: 'Site Contacts',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			seasonDropdown: false
		},
		component: SiteContacts
	},
	{
		path: '/suspensions',
		name: 'Suspensions',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			seasonDropdown: false
		},
		component: Suspensions
	},
	{
		path: '/programcontacts',
		name: 'PROGRAM Contacts',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			seasonDropdown: false
		},
		component: ProgramContacts
	},
	{
		path: '/teamcontacts',
		name: 'Team Contacts',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			seasonDropdown: false
		},
		component: ProgramContacts
	},
	{
		path: '/programplayers',
		name: 'PROGRAM Players',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			seasonDropdown: false
		},
		component: ProgramPlayers
	},
	{
		path: '/programlocations',
		name: 'PROGRAM LOCATION_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: ProgramLocations
	},
	{
		path: '/programteamregistration',
		name: 'Team Registration',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: ProgramTeamRegistration
	},
	{
		path: '/teamsreport',
		name: 'Teams Report',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: TeamsReport
	},
	{
		path: '/teamsbillingreport',
		name: 'Season Audit Report',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: TeamsBillingReport
	},
	{
		path: '/users',
		name: 'User Logins',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Users
	},
	{
		path: '/gamescorereporting',
		name: 'GAME Score Reporting',
		meta: {
			requiresAuth: false,
			requiresSecurity: false
		},
		component: GameScoreReporting
	},
	{
		path: '/contractapproval/:contractcode',
		name: 'Contract Approval',
		meta: {
			requiresAuth: false,
			requiresSecurity: false
		},
		component: ContractApproval
	},
	{
		path: '/purchaseorderapproval/:pocode',
		name: 'Purchase Order Approval',
		meta: {
			requiresAuth: false,
			requiresSecurity: false
		},
		component: ContractApproval
	},
	{
		path: '/gamesheets',
		name: 'GameSheet',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Gamesheets
	},
	{
		path: '/gamereporting',
		name: 'GAME Reporting',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: GameReporting
	},
	{
		path: '/mitegamereporting',
		name: 'Mite GAME Reporting',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: GameReporting
	},
	{
		path: '/invoices',
		name: 'Invoices',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Invoices
	},
	{
		path: '/invoices/:id',
		name: 'Invoice Report Item',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Invoices
	},
	{
		path: '/mobilegamereporting',
		name: 'Mobile GAME Reporting',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: MobileGameReporting
	},
	{
		path: '/mitemobilegamereporting',
		name: 'Mite Mobile GAME Reporting',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: MobileGameReporting
	},
	{
		path: '/loginroles',
		name: 'Login Roles',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: LoginRoles
	},
	{
		path: '/securityareas',
		name: 'Security Areas',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: SecurityAreas
	},
	{
		path: '/notifications',
		name: 'Notifications',
		meta: {
			requiresAuth: true
		},
		component: Notifications
	},
	{
		path: '/logout',
		name: 'Logout',
		meta: {
			requiresAuth: false
		},
		component: Logout
	},
	{
		path: '/helptext',
		name: 'Help Text',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: SystemText
	},
	{
		path: '/systemtext',
		name: 'System Text',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: SystemText
	},
	{
		path: '/settings',
		name: 'Settings',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: Settings
	},
	{
		path: '/analyzer/sitesettings',
		name: 'Site Settings',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
		},
		component: Settings
	},
	{
		path: '/superuserpassword',
		name: 'Super User Password',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: SuperUserPassword
	},
	{
		path: '/widgetbuilder',
		name: 'Widget Builder',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: WidgetBuilder
	},
	{
		path: '/widgetformats',
		name: 'Widget Formats',
		meta: {
			requiresAuth: true,
			requiresSecurity: true
		},
		component: WidgetFormats
	},
	{
		path: '/analyzer/',
		name: 'Analyzer Home',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: AnalyzerDashboard
	},
	{
		path: '/analyzer/dashboard',
		name: 'Analyzer Dashboard',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: AnalyzerDashboard
	},
	{
		path: '/analyzer/leagues',
		name: 'Leagues',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerLeagues,
	},
	{
		path: '/analyzer/leagueroles',
		name: 'League Login Roles',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerLeagueRoles,
	},
	{
		path: '/analyzer/locations',
		name: 'MAINLOCATION_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerLocations,
	},
	{
		path: '/locations',
		name: 'LOCATION_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: false
		},
		component: AnalyzerLocations,
	},
	{
		path: '/analyzer/calendar',
		name: 'Availability Calendar',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: AnalyzerCalendarSlots,
	},
	{
		path: '/locationcalendar',
		name: 'LOCATION Calendar',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: false,
			seasonDropdown: false
		},
		component: AnalyzerCalendarSlots
	},
	{
		path: '/programcalendar',
		name: 'PROGRAM Calendar',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: false,
			seasonDropdown: false
		},
		component: AnalyzerCalendarSlots
	},
	{
		path: '/analyzer/constraints',
		name: 'Constraints',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: AnalyzerCalendarSlots,
	},
	{
		path: '/analyzer/programs',
		name: 'PROGRAM_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerPrograms,
	},
	{
		path: '/analyzer/registrationsettings',
		name: 'Registration Settings',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerRegistrationSettings,
	},
	{
		path: '/analyzer/referencedata',
		name: 'Reference Data',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
		},
		component: AnalyzerReferenceData,
	},
	{
		path: '/analyzer/seasons',
		name: 'Seasons',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerSeasons,
	},
	{
		path: '/analyzer/schedulelisting',
		name: 'SCHEDULE Listing',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerScheduleListing,
	},
	{
		path: '/analyzer/schedulesettings',
		name: 'SCHEDULE Settings',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerScheduleSettings,
	},
	{
		path: '/analyzer/generategames',
		name: 'Generate GAME_S to Schedule',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerGenerateSchedules,
	},
	{
		path: '/analyzer/scheduling',
		name: 'Schedule GAME_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerScheduling,
	},
	{
		path: '/analyzer/scheduling/:id',
		name: 'Schedule GAME_S ',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: AnalyzerScheduling
	},
	{
		path: '/analyzer/tournaments/:id',
		name: 'Tournaments ',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: Tournaments
	},
	{
		path: '/analyzer/trash',
		name: 'Trash ',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: Trash
	},
	{
		path: '/analyzer/recyclebin',
		name: 'Recycle Bin',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: Trash
	},
	{
		path: '/analyzer/gamedetails',
		name: 'GAME Updates',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerGameManagement,
	},
	{
		path: '/analyzer/schedulemanagement',
		name: 'SCHEDULE Management',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerGameManagement,
	},
	{
		path: '/analyzer/horizonmanagement',
		name: 'Horizon Management',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerHorizonManagement,
	},
	{
		path: '/analyzer/swapschedules',
		name: 'Swap SCHEDULE_S',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: SwapSchedules,
	},
	{
		path: '/analyzer/schedulemanagement/:id',
		name: 'SCHEDULE Management ',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true,
			seasonDropdown: false
		},
		component: AnalyzerGameManagement
	},
	{
		path: '/analyzer/sports',
		name: 'Sports',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerSports,
	},

	{
		path: '/analyzer/standingsprofiles',
		name: 'STANDING_S Profiles',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerStandingsProfiles,
	},
	{
		path: '/analyzer/teams',
		name: 'Teams',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: AnalyzerTeams,
	},
	{
		path: '/analyzer/tournaments',
		name: 'Tournaments',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: Tournaments,
	},
	{
		path: '/analyzer/tags',
		name: 'Tags',
		meta: {
			requiresAuth: true,
			requiresSecurity: true,
			isAnalyzer: true
		},
		component: Tags,
	},
	{
		path: '/:domain/playerregistration',
		name: 'Player Registration',
		meta: {
			requiresAuth: false
		},
		component: PlayerRegistration
	},
	{
		path: '/:domain/coachregistration',
		name: 'Coach Registration',
		meta: {
			requiresAuth: false
		},
		component: PlayerRegistration
	},
	{
		path: '/:domain/teamcontactregistration',
		name: 'Team Contact Registration',
		meta: {
			requiresAuth: false
		},
		component: PlayerRegistration
	},
	{
		path: '/:domain/linkedteamregistration',
		name: 'Linked Team Registration',
		meta: {
			requiresAuth: false
		},
		component: LinkedTeamRegistration
	},
	{
		path: '/contactupdate',
		name: 'Contact Update',
		meta: {
			requiresAuth: false
		},
		component: PlayerRegistration
	},
	{
		path: '/playerupdate',
		name: 'Player Update',
		meta: {
			requiresAuth: false
		},
		component: PlayerRegistration
	}
];
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
