<template>
    <div v-if="$featureAllowed('reportwriter')" id="reportwriter" :class="{'navReport' : navReport}" v-loading="loading">
        <el-form ref="reportwriterForm" label-position="top" :model="report" label-width="120px" >
            <div class="row">
                <div v-if="!navReport" class="col-12 col-md-9 mb-4 fullpage">
                    <div v-if="$createAccess() && $modifyAccess()">
                        <el-radio-group class="mt-4 mb-4"  v-model="PrivateShared" @change="PrivateSharedChanged">
                            <el-radio-button label="p">My Reports</el-radio-button>
                            <el-radio-button label="s">Shared Reports</el-radio-button>
                        </el-radio-group>
                    </div>
                    <el-cascader
                        placeholder="Select Report"
                        style="width: 100%;"
                        :options="ReportNames[PrivateShared]"
                        v-model="FolderReportID"
                        :filter-method="filterCascader"
                        @change="getReport()"
                        :props="cascaderprops"
                        default-first-option filterable>
                    </el-cascader>
                </div>
                <template v-else >
                    <h3 style="text-align: center;width: 100%;">{{report.ReportName}}</h3>
                    <div v-if="report.Params.Description" class="reportdescription" v-html="report.Params.Description"></div>
                </template>
                <div v-if="$modifyAccess()" class="card col-12">
                    <div class="row d-flex">
                        <div class="col-12 col-md-6">
                                <el-form-item class="mt-2 mb-2" label="Report Name" prop="ReportName"
                                              :rules="[{ required: true, message: 'Report Name is required', trigger: 'blur'}]"
                                >
                                    <el-input class="oneHundred" v-model="report.ReportName"  >
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="mt-2 mb-2" label="Folder (Select or Enter New)" prop="FolderName"
                                              :rules="[{ required: true, message: 'Folder is required', trigger: 'blur'}]"
                                >
                                    <el-select v-model="report.FolderName" placeholder="Select" class="oneHundred"  default-first-option filterable clearable allow-create>
                                        <el-option
                                            v-for="item in ReportFolderName"
                                            :key="item.FolderName"
                                            :label="item.FolderName"
                                            :value="item.FolderName">
                                        </el-option>
                                    </el-select>
                                    <div class="small" style="width: 100%;font-size: 10px;line-height:16px;">Add ">>>" to indicate the next string is a sub folder (i.e. Spring League>>>Squirts)</div>
                                </el-form-item>
                                <el-form-item class="mt-2 mb-2" label="Report Type" prop="Params.ReportType"
                                              :rules="[{ required: true, message: 'Report Type is required', trigger: 'blur'}]"
                                >
                                    <el-select key="selectreporttype" placeholder="Select" v-model="report.Params.ReportType" class="oneHundred" @change="ReportChange" default-first-option filterable >
                                        <el-option-group
                                            v-for="group in ReportTypes"
                                            :key="group.label"
                                            :label="group.label">
                                            <el-option
                                                v-for="item in group.options"
                                                :key="item.key"
                                                :label="item.label"
                                                :value="item.key">
                                            </el-option>
                                        </el-option-group>
                                    </el-select>
                                </el-form-item>
                        </div>
                        <div class="col-12 col-md-6">
                            <el-form-item class="mt-2 mb-2" label="Report Owner:">
                                <el-input class="oneHundred" v-model="reportOwner" disabled ></el-input>
                            </el-form-item>
                            <el-form-item label="Description (Sharing Text)">
                                <el-input type="textarea" :autosize="{ minRows:4, maxRows: 15}" v-model="report.Params.Description">
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
            <el-collapse v-if="report.Params.ReportType" v-model="activePanels" @change="collapseChange">
                <el-collapse-item v-if="$modifyAccess() && report.MySharingParams.Params.AllowEdit == 'y'" title="Sharing Parameters" name="1">
                    <div class="card">
                        <div class="row d-flex">
                            <el-select key="selectreportsharing" placeholder="Select"  v-model="report.ReportSharing" :disabled="(ReportID == '' && report.createdby != currentUser.ContactID)" class="oneHundred" default-first-option>
                                <el-option
                                    v-for="item in ReportSharingOptions"
                                    :key="item.key"
                                    :label="item.label"
                                    :value="item.key">
                                </el-option>
                            </el-select>
                            <div v-if="report.ReportSharing == 's'" class="mt-2" style="width: 100%;">
                                <label class="mb-1 control-label help" style="width: 100%;">Specify Email Addresses (multiple, separated by a comma) to receive this report:</label>
                                <el-table
                                    :data="report.EmailSharingParams"
                                    size="small"
                                    stripe border
                                    style="width: 100%">
                                    <el-table-column
                                        prop="EmailAddress"
                                        label="Email Address"
                                        min-width="200px"
                                    >
                                        <template #default="scope">
                                            <el-autocomplete
                                                v-model="scope.row.EmailAddress"
                                                style="width:100%;"
                                                clearable
                                                :fetch-suggestions="searchEmail"
                                                placeholder="Type name or email to search"
                                                :trigger-on-focus="true"
                                                @select="handleSelect"
                                                :debounce="300"
                                                :highlight-first-item="true">
                                            </el-autocomplete>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="AllowEdit"
                                        label="Allow Edit"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
                                            <el-checkbox v-if="scope.row.Params && scope.row.EmailAddress != ''" v-model="scope.row.Params.AllowEdit" false-label="n" true-label="y"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="ShowParameters"
                                        label="Show Parameters"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
                                            <el-checkbox v-if="scope.row.Params && scope.row.EmailAddress != ''" v-model="scope.row.Params.ShowParameters" false-label="n" true-label="y"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="datarestrictions"
                                        label="Data Restrictions"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
	                                        <template v-if="scope.row.Params">
	                                            <div v-if="scope.row.EmailAddress != ''" class="tableCheckBox"><el-checkbox v-model="scope.row.Params.ProgramIDAccess" false-label="n" true-label="y" @change="scope.row.Params.TeamIDAccess = 'n'">{{config.Params.programName}}</el-checkbox></div>
	                                            <div v-if="scope.row.EmailAddress != ''" class="tableCheckBox"><el-checkbox v-model="scope.row.Params.TeamIDAccess" false-label="n" true-label="y" @change="scope.row.Params.ProgramIDAccess = 'n'">Team</el-checkbox></div>
	                                            <div v-if="scope.row.EmailAddress != ''" class="tableCheckBox"><el-checkbox v-model="scope.row.Params.MainLocationIDAccess" false-label="n" true-label="y">{{config.Params.mainlocationName}}</el-checkbox></div>
	                                        </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="Navigation"
                                        label="Show in Navigation"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
	                                        <template v-if="scope.row.Params">
                                                <el-checkbox v-if="scope.row.EmailAddress != ''" v-model="scope.row.Params.Navigation" false-label="n" true-label="y"></el-checkbox>
	                                        </template>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-button class="mt-2 mb-2" @click.prevent="addEmailSharing()" size="small"><i class="fa-solid fa-circle-plus"></i> Add New</el-button>
                                <label class="mb-1 control-label" style="width: 100%;">Specify Role(s) below to also get access to this report:</label>
                                <el-table
                                    :data="report.SharingParams"
                                    max-height="500"
                                    size="small"
                                    stripe border
                                    style="width: 100%">
                                    <el-table-column
                                        prop="RoleName"
                                        label="Role"
                                        min-width="200px"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="RoleSharing"
                                        label="Share Report"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
                                            <el-checkbox v-model="scope.row.RoleSharing" false-label="n" true-label="y"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="AllowEdit"
                                        label="Allow Edit"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
                                            <el-checkbox v-if="scope.row.RoleSharing == 'y'" v-model="scope.row.Params.AllowEdit" false-label="n" true-label="y"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="ShowParameters"
                                        label="Show Parameters"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
                                            <el-checkbox v-if="scope.row.RoleSharing == 'y'" v-model="scope.row.Params.ShowParameters" false-label="n" true-label="y"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="datarestrictions"
                                        label="Data Restrictions"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
	                                        <template v-if="scope.row.Params">
	                                            <div v-if="scope.row.RoleSharing == 'y' && scope.row.UserArea == 'p'" class="tableCheckBox"><el-checkbox v-model="scope.row.Params.ProgramIDAccess" false-label="n" true-label="y" @change="scope.row.Params.TeamIDAccess = 'n'">{{config.Params.programName}}</el-checkbox></div>
	                                            <div v-if="scope.row.RoleSharing == 'y' && scope.row.UserArea == 'p'" class="tableCheckBox"><el-checkbox v-model="scope.row.Params.TeamIDAccess" false-label="n" true-label="y" @change="scope.row.Params.ProgramIDAccess = 'n'">Team</el-checkbox></div>
	                                            <div v-if="scope.row.RoleSharing == 'y' && scope.row.UserArea == 'l'" class="tableCheckBox"><el-checkbox v-model="scope.row.Params.MainLocationIDAccess" :disabled="true" false-label="n" true-label="y">{{config.Params.mainlocationName}}</el-checkbox></div>
	                                        </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="Navigation"
                                        label="Show in Navigation"
                                        width="100"
                                        align="center">
                                        <template #default="scope">
                                            <el-checkbox v-if="scope.row.Params && scope.row.RoleSharing == 'y'" v-model="scope.row.Params.Navigation" false-label="n" true-label="y"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                </el-table>
                             </div>
                        </div>
                    </div>
                </el-collapse-item>
	            <el-collapse-item v-if="report.MySharingParams.Params.ShowParameters =='y' && (!navReport || (navReport && paramsToShow))" title="Data Parameters" name="2">
	                <div class="card">
                        <div class="row d-flex">
                            <p v-if="report.ReportSharing == 's' && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="col-12 parameterShown" style="float:left;margin: 0px 10px 10px 10px;">If you are sharing and the Email Address or Role has "Show Parameters" selected in the grid above, then the parameters below that have the "Show" checkbox selected will be available for that Role.  Other parameters will use the defaults you enter but cannot be changed.</p>
                            <div v-if="(!navReport || report.Params.gamedatesSharing == 'y') && ParameterValid('FromToDate')" class="col-12 col-md-6 daterange" :class="{'parameterShown' : !navReport && report.Params.gamedatesSharing == 'y'}" style="height: 73px; float: left;">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.gamedatesSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="Beginning -> End" prop="gamedates">
                                     <el-date-picker
                                        v-model="report.Params.gamedates"
                                        type="daterange"
                                        unlink-panels
                                        format="MMM D, YYYY"
                                        range-separator="To"
                                        start-placeholder="Start date"
                                        end-placeholder="End date"
                                        value-format="YYYY-MM-DD">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <template v-if="ParameterValid('Forms') && (!navReport || report.Params.ActiveFormsDSharing =='y')">
                                <div class="col-12" :class="{'parameterShown' : !navReport && report.Params.ActiveFormsDSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ActiveFormsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-radio-group class="mt-2" v-model="report.Params.ActiveForms" @change="activeformsChange">
                                        <el-radio-button label="y">Active Forms</el-radio-button>
                                        <el-radio-button label="n">Disabled Forms</el-radio-button>
                                    </el-radio-group>
                                    <br>
                                    <el-cascader
                                        v-if="report.Params.ActiveForms"
                                        class="mb-2"
                                        style="width: 50%;"
                                        :options="FormNames[report.Params.ActiveForms]"
                                        v-model="report.Params.FolderFormDefinitionID"
                                        :filter-method="filterCascader"
                                        :props="cascaderprops"
                                        @change="showCustomFields"
                                        default-first-option filterable>
                                    </el-cascader>
                                </div>
                            </template>
                            <div v-if="ParameterValid('Sites') && (!navReport || report.Params.MainLocationIDSharing =='y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.MainLocationIDSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.MainLocationIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item :label="config.Params.mainlocationNames" prop="MainLocationIDs">
                                    <el-select key="select"  style="width: 100%;" v-model="report.Params.MainLocationIDs" :placeholder="'All ' + config.Params.mainlocationNames" multiple default-first-option filterable clearable>
                                        <el-option
                                            v-for="item in SiteLocations"
                                            :key="item.MainLocationID"
                                            :label="item.MainLocationName"
                                            :value="item.MainLocationID">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="ParameterValid('SlotTypes') && (!navReport || report.Params.SlotTypesSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.SlotTypesSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.SlotTypesSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="Usage Type:" prop="iceusage">
                                    <el-select key="select1" style="width: 100%;"  placeholder="All Usage" v-model="report.Params.SlotTypes" default-first-option filterable clearable
                                               multiple
                                    >
                                        <el-option
                                                v-for="item in SlotTypes"
                                                :key="item.type"
                                                :label="item.label"
                                                :value="item.type">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="ParameterValid('Seasons') && (!navReport || report.Params.SeasonIDSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.SeasonIDSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.SeasonIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="Season" prop="SeasonID">
                                    <el-select key="select4" class="oneHundred" placeholder="All Seasons"  v-model="report.Params.SeasonID" @change="seasonChange" default-first-option filterable clearable>
                                        <el-option
                                            v-for="item in ActiveSeasons"
                                            :key="item.SeasonID"
                                            :label="item.SeasonName"
                                            :value="item.SeasonID">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="ParameterValid('Organizations') && (!navReport || report.Params.OrganizationIDSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.OrganizationIDSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.OrganizationIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="Organization" prop="OrganizationID">
                                    <el-select key="select2"  v-model="report.Params.OrganizationID" placeholder="All Organizations" class="oneHundred" default-first-option filterable clearable>
                                        <el-option v-for="type in Organizations"
                                                   :value="type.OrganizationID"
                                                   :key="type.OrganizationID"
                                                   :label="type.OrganizationName">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="ParameterValid('ScheduleTypes') && (!navReport || report.Params.ScheduleTypeIDsSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.ScheduleTypeIDsSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ScheduleTypeIDsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="Schedule Types" prop="ScheduleTypes">
                                     <el-select key="select3" style="width: 100%;" placeholder="All ScheduleTypes"   v-model="report.Params.ScheduleTypeIDs" default-first-option filterable clearable
                                               multiple
                                    >
                                        <el-option
                                            v-for="item in ScheduleTypes"
                                            :key="item.ScheduleTypeID"
                                            :label="item.ScheduleTypeName"
                                            :value="item.ScheduleTypeID">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="ParameterValid('Programs') && (!navReport || report.Params.ProgramIDSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.ProgramIDSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ProgramIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item :label="config.Params.programName + ':'" prop="ProgramID">
                                    <el-select key="select5" class="oneHundred" :placeholder="'All ' + config.Params.programName +'s'"  v-model="report.Params.ProgramID" @change="programChange" default-first-option filterable clearable>
                                        <el-option
                                            v-for="item in Programs"
                                            :key="item.ProgramID"
                                            :label="item.ProgramName"
                                            :value="item.ProgramID">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-if="ParameterValid('Leagues') && (!navReport || report.Params.LeagueIDSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.LeagueIDSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport" class="sharing" v-model="report.Params.LeagueIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="League" prop="LeagueID">
                                    <el-select key="select6" class="oneHundred" placeholder="All Leagues" v-model="report.Params.LeagueID" default-first-option filterable clearable>
                                        <el-option
                                            v-for="item in Leagues"
                                            :key="item.LeagueID"
                                            :label="item.LeagueName"
                                            :value="item.LeagueID">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-if="report.Params.LeagueIDSharing == 'x'" label="Show Data from All Leagues" prop="ShowAllLeagues">
                                    <el-radio-group v-model="report.Params.ShowAllLeagues" size="small">
                                        <el-radio-button label="y">Yes</el-radio-button>
                                        <el-radio-button label="n">No</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div v-if="ParameterValid('BillingAccount') && (!navReport || report.Params.InvoiceSettingIDSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.InvoiceSettingIDSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.InvoiceSettingIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="Billing Account:" prop="InvoiceSettingID">
                                    <el-select key="selectInvoiceSettingID" class="oneHundred" placeholder="Select"  v-model="report.Params.InvoiceSettingID" default-first-option filterable clearable>
                                        <el-option
                                            v-for="item in InvoiceSettings"
                                            :key="item.InvoiceSettingID"
                                            :label="item.BillingName"
                                            :value="item.InvoiceSettingID">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <template v-if="ParameterValid('seasonLeagueProgram')">
                                <div v-if="!navReport || report.Params.SeasonIDSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.SeasonIDSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.SeasonIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Season:" prop="SeasonID">
                                        <el-select key="select7" class="oneHundred" placeholder="All Seasons"  v-model="report.Params.SeasonID" @change="seasonChange" default-first-option filterable>
                                            <el-option
                                                v-for="item in ActiveSeasons"
                                                :key="item.SeasonID"
                                                :label="item.SeasonName"
                                                :value="item.SeasonID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <template v-if="report.Params.SeasonID != ''">
                                    <div v-if="!navReport || report.Params.LeagueIDSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.LeagueIDSharing == 'y'}">
                                        <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.LeagueIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                        <el-form-item label="League:" prop="LeagueID">
                                            <el-select key="select8" class="oneHundred" placeholder="All Leagues"  :disabled="report.Params.ScheduleIDs.length > 0" v-model="report.Params.LeagueID" @change="leagueChange" default-first-option filterable clearable>
                                                <el-option
                                                    v-for="item in Leagues"
                                                    :key="item.LeagueID"
                                                    :label="item.LeagueName"
                                                    :value="item.LeagueID">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item v-if="report.Params.LeagueIDSharing == 'x'" label="Show Data from All Leagues" prop="ShowAllLeagues">
                                            <el-radio-group v-model="report.Params.ShowAllLeagues" size="small">
                                                <el-radio-button label="y">Yes</el-radio-button>
                                                <el-radio-button label="n">No</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div v-if="!navReport || report.Params.ProgramIDSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.ProgramIDSharing == 'y'}">
                                        <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ProgramIDSharing" false-label="n" true-label="y">Show</el-checkbox>
                                        <el-form-item :label="config.Params.programName + ':'" prop="ProgramID">
                                            <el-select key="select9" class="oneHundred" :placeholder="'All ' + config.Params.programName +'s'"  v-model="report.Params.ProgramID" @change="programChange" default-first-option filterable clearable>
                                                <el-option
                                                    v-for="item in Programs"
                                                    :key="item.ProgramID"
                                                    :label="item.ProgramName"
                                                    :value="item.ProgramID">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </template>
                            </template>
                            <template v-if="ParameterValid('Schedules') && report.Params.SeasonID != ''">
                                <div v-if="!navReport || report.Params.ScheduleIDsSharing == 'y'" class="col-12" :class="{'parameterShown' : !navReport && report.Params.ScheduleIDsSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ScheduleIDsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-radio-group v-model="report.Params.gametype" size="small" @change="gametypeChange">
                                        <el-radio-button size="small" label="Live" >Live Games</el-radio-button>
                                        <el-radio-button size="small" label="Scheduled">unPublished</el-radio-button>
                                    </el-radio-group>
                                    <el-form-item v-if="report.Params.gametype != 'Live'" class="mt-2 mb-2" label="Schedule(s):" prop="ScheduleIDs">
                                        <el-select key="selectScheduleIDs1" placeholder="Select"   v-model="report.Params.ScheduleIDs" class="oneHundred" @change="scheduleChange" multiple default-first-option filterable clearable>
                                            <el-option-group
                                                v-for="group in AllSchedules"
                                                :key="group.label"
                                                :label="group.label">
	                                            <template v-for="item in group.options">
	                                                <el-option
	                                                    v-if="item.ScheduleRunID > 0"
	                                                    :key="item.ScheduleID + item.ScheduleRunID"
	                                                    :label="item.ScheduleName + (item.ScheduleRunID ? ' (Run: ' + item.ScheduleRunID + ')' : '')"
	                                                    :value="item.ScheduleID">
	                                                </el-option>
	                                            </template>
                                            </el-option-group>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item v-else class="mt-2 mb-2" label="Schedule(s):" prop="ScheduleIDs">
                                        <el-select key="selectScheduleIDs2" placeholder="Select"   v-model="report.Params.ScheduleIDs" class="oneHundred" @change="scheduleChange" multiple default-first-option filterable clearable>
                                            <el-option-group
                                                v-for="group in AllSchedules"
                                                :key="group.label"
                                                :label="group.label">
	                                            <template v-for="item in group.options">
	                                                <el-option
	                                                    v-if="item.ScheduleRunID == '' && report.Params.SeasonID == item.SeasonID"
	                                                    :key="item.ScheduleID"
	                                                    :label="item.ScheduleName"
	                                                    :value="item.ScheduleID">
	                                                </el-option>
	                                            </template>
                                            </el-option-group>
                                        </el-select>
                                    </el-form-item>

                                </div>
                            </template>
                            <template v-if="ParameterValid('InvoiceStatus')">
                                <div v-if="!navReport || report.Params.InvoiceStatusSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.InvoiceStatusSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.InvoiceStatusSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item class="mt-2 mb-2" label="Invoice Status" prop="InvoiceStatus">
                                        <el-select key="selectinvoicestatus" placeholder="Select"   v-model="report.Params.InvoiceStatus" class="oneHundred" multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in InvoiceStatus"
                                                :key="item.key"
                                                :label="item.label"
                                                :value="item.key">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </template>
                            <template v-if="ParameterValid('PaymentStatus')">
                                <div v-if="!navReport || report.Params.PaymentStatusSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.PaymentStatusSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.PaymentStatusSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item class="mt-2 mb-2" label="Invoice Status" prop="PaymentStatus">
                                        <el-select key="selectPaymentStatus" placeholder="Select"   v-model="report.Params.PaymentStatus" class="oneHundred" multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in PaymentStatus"
                                                :key="item.key"
                                                :label="item.label"
                                                :value="item.key">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </template>
                            <template v-if="ParameterValid('CreditStatus')">
                                <div v-if="!navReport || report.Params.CreditStatusSharing == 'y'" class="col-6 col-md-6" :class="{'parameterShown' : !navReport && report.Params.CreditStatusSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.CreditStatusSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item class="mt-2 mb-2" label="Adjustment Status" prop="CreditStatus">
                                        <el-radio-group v-model="report.Params.CreditStatus" size="small">
                                            <el-radio-button selected label="">All</el-radio-button>
                                            <el-radio-button label="Invoiced">Invoiced</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="col-6 col-md-6">&nbsp;</div>
                            </template>
                            <template v-if="ParameterValid('InvoiceType')">
                                <div v-if="!navReport || report.Params.InvoiceTypeSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.InvoiceTypeSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.InvoiceTypeSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item class="mt-2 mb-2" label="Report Type" prop="InoviceType">
                                        <el-select key="selectinvoicetype" placeholder="Select" v-model="report.Params.InvoiceType" class="oneHundred" multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in InvoiceTypes"
                                                :key="item.key"
                                                :label="item.label"
                                                :value="item.key">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </template>
                            <template v-if="ParameterValid('teamSelect') && report.Params.SeasonID != ''">
                                <div v-if="!navReport || report.Params.TeamIDsSharing == 'y'" class="col-12 teamids" :class="{'parameterShown' : !navReport && report.Params.TeamIDsSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.TeamIDsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Team(s):" prop="TeamIDs">
                                        <el-select key="selectiteamids" placeholder="Select"   v-model="report.Params.TeamIDs" class="oneHundred" multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in ProgramTeams"
                                                :key="item.TeamID"
                                                :label="item.TeamName"
                                                :value="item.TeamID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
	                                <el-checkbox v-if="!navReport" class="activeonly" v-model="report.Params.ActiveOnlyTeams" @change="programChange" false-label="n" true-label="y">Only Active Teams</el-checkbox>
                                </div>
                            </template>
                            <template v-if="ParameterValid('PlayerTypes')">
                                <div v-if="!navReport || report.Params.PlayerTypeIDsSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.PlayerTypeIDsSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ContactTypeIDsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Player Type(s):" prop="PlayerTypeID">
                                        <el-select key="select11" class="oneHundred" placeholder="All Types"  v-model="report.Params.PlayerTypeIDs"  multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in PlayerTypes"
                                                :key="item.PlayerTypeID"
                                                :label="item.PlayerTypeName"
                                                :value="item.PlayerTypeID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </template>
                            <template v-if="ParameterValid('contactType')">
                                <div v-if="!navReport || report.Params.ContactTypeIDsSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.ContactTypeIDsSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ContactTypeIDsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Contact Type(s):" prop="ContactTypeID">
                                        <el-select key="select11" class="oneHundred" placeholder="All Types"  v-model="report.Params.ContactTypeIDs"  multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in sortContactType"
                                                :key="item.ContactTypeID"
                                                :label="item.ContactTypeName"
                                                :value="item.ContactTypeID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </template>
	                        <div v-if="ParameterValid('tags') && (!navReport || report.Params.taglinksSharing == 'y')" class="row">
	                            <div class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.taglinksSharing == 'y'}">
	                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.taglinksSharing" false-label="n" true-label="y">Show</el-checkbox>
	                                <el-form-item label="Player Tags" prop="Tags">
	                                    <div class="tags el-input__inner" style="padding: 0px; height: auto;min-height:31px;">
	                                        <el-tag
	                                            v-for="tag in report.Params.taglinks"
	                                            :key="tag.TagID"
	                                            closable
	                                            effect="dark"
	                                            :type="tag.TagType"
	                                            :disable-transitions="false"
	                                            @close="handleCloseTag(tag)"
	                                        >
	                                            {{tag.TagName}}
	                                        </el-tag>
	                                        <el-select v-if="tagSelectVisible" key="selecttags" v-model="newTagID" ref="saveTagInput" size="small" style="width: auto;" @change="handleNewTag" default-first-option clearable filterable>
	                                            <el-option
	                                                v-for="item in availablePlayerTags"
	                                                :key="item.TagID"
	                                                :label="item.TagName"
	                                                :value="item.TagID"
	                                            >
	                                                <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

	                                            </el-option>
	                                        </el-select>
	                                        <el-button v-else-if="availablePlayerTags.length > 0" class="button-new-tag" size="small" @click="showTagSelect">+ New Tag</el-button>
	                                    </div>
	                                </el-form-item>
	                            </div>
		                        <div v-if="0==1" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.excltaglinksSharing == 'y'}">
			                        <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.excltaglinksSharing" false-label="n" true-label="y">Show</el-checkbox>
			                        <el-form-item label="Exclude Player Tags" prop="Tags">
				                        <div class="tags el-input__inner" style="padding: 0px; height: auto;min-height:31px;">
					                        <el-tag
						                        v-for="tag in report.Params.excltaglinks"
						                        :key="tag.TagID"
						                        closable
						                        effect="dark"
						                        :type="tag.TagType"
						                        :disable-transitions="false"
						                        @close="handleCloseExclTag(tag)"
					                        >
						                        {{tag.TagName}}
					                        </el-tag>
					                        <el-select v-if="excltagSelectVisible" key="selecttags" v-model="newExclTagID" ref="saveExclTagInput" size="small" style="width: auto;margin-left: 5px;" @change="handleNewExclTag" default-first-option clearable filterable>
						                        <el-option
							                        v-for="item in availablePlayerTags"
							                        :key="item.TagID"
							                        :label="item.TagName"
							                        :value="item.TagID"
						                        >
							                        <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

						                        </el-option>
					                        </el-select>
					                        <el-button v-else-if="availableTags.length > 0" class="button-new-tag" size="small" @click="showExclTagSelect">+ New Tag</el-button>
				                        </div>
			                        </el-form-item>
		                        </div>
	                        </div>
                            <template v-if="ParameterValid('LoginRole')">
                                <div v-if="!navReport || report.Params.LoginRoleIDsSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.LoginRoleIDsSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.LoginRoleIDsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Login Role(s):" prop="LoginRoleIDs">
                                        <el-select key="select11b" class="oneHundred" placeholder="All Roles"  v-model="report.Params.LoginRoleIDs"  multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in LoginRole"
                                                :key="item.LoginRoleID"
                                                :label="item.RoleName"
                                                :value="item.LoginRoleID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </template>
                            <template v-if="ParameterValid('MainOrLocation')">
                                <div v-if="!navReport || report.Params.GroupBySharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.GroupBySharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.GroupBySharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Select Records By:" prop="GroupBy">
                                        <el-radio-group v-model="report.Params.GroupBy" size="small">
                                            <el-radio-button selected label="r">{{config.Params.mainlocationNames}}</el-radio-button>
                                            <el-radio-button label="l">{{config.Params.locationNames}}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item v-if="report.Params.GroupBy == 'l'" :label="config.Params.locationNames" prop="LocationIDs">
                                         <el-select key="select12a"  style="width: 100%;" v-model="report.Params.LocationIDs" :placeholder="'All ' + config.Params.locationNames" multiple default-first-option filterable clearable>
                                            <template  v-for="item in Locations">
		                                         <el-option
	                                                v-if="item.MainLocationID"
	                                                :key="item.LocationID"
	                                                :label="item.LocationName"
	                                                :value="item.LocationID">
	                                            </el-option>
                                            </template>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item v-if="report.Params.GroupBy == 'r'" :label="config.Params.mainlocationNames" prop="MainLocationIDs">
                                       <el-select key="select12b"  style="width: 100%;" v-model="report.Params.MainLocationIDs" :placeholder="'All ' + config.Params.mainlocationNames" multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in SiteLocations"
                                                :key="item.MainLocationID"
                                                :label="item.MainLocationName"
                                                :value="item.MainLocationID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </template>
                            <template v-if="ParameterValid('GameParams')">
                                <div v-if="!navReport || report.Params.gamestatusSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.gamestatusSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.gamestatusSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Game Status" prop="gamestatus">
                                        <el-select key="gamestatus" class="oneHundred"   v-model="report.Params.gamestatus" placeholder="Select Status" multiple default-first-option filterable clearable>
                                            <el-option
                                                v-for="item in StatusCodes"
                                                :key="item.StatusCode"
                                                :label="item.StatusCodeName"
                                                :value="item.StatusCode">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div v-if="!navReport || report.Params.showunassignedSharing == 'y'" class="col-12" :class="{'parameterShown' : !navReport && report.Params.showunassignedSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.showunassignedSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Show Unassigned Games" prop="showunassigned">
                                        <el-radio-group v-model="report.Params.showunassigned" >
                                            <el-radio-button label="y">With Assigned</el-radio-button>
                                            <el-radio-button label="o">Only Unassigned</el-radio-button>
                                            <el-radio-button label="n">No Unassigned</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div v-if="!navReport || report.Params.ForfeitsSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.ForfeitsSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ForfeitsSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Show Forfeits" prop="Forfeits">
                                        <el-radio-group v-model="report.Params.Forfeits" size="small">
                                            <el-radio-button label="A">All</el-radio-button>
                                            <el-radio-button label="W">Wins</el-radio-button>
                                            <el-radio-button label="L">Losses</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div v-if="!navReport || report.Params.ReuseSlotSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.ReuseSlotSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.ReuseSlotSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Reuse Slots" prop="ReuseSlot">
                                        <el-radio-group v-model="report.Params.ReuseSlot" size="small">
                                            <el-radio-button label="">All</el-radio-button>
                                            <el-radio-button label="y">Yes</el-radio-button>
                                            <el-radio-button label="n">No</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div v-if="!navReport || report.Params.taglinksSharing == 'y'" class="col-12" :class="{'parameterShown' : !navReport && report.Params.taglinksSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.taglinksSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Schedule Tags" prop="Tags">
                                        <div class="tags el-input__inner" style="padding: 0px; height: auto;min-height:31px;">
                                            <el-tag
                                                v-for="tag in report.Params.taglinks"
                                                :key="tag.TagID"
                                                closable
                                                effect="dark"
                                                :type="tag.TagType"
                                                :disable-transitions="false"
                                                @close="handleCloseTag(tag)"
                                            >
                                                {{tag.TagName}}
                                            </el-tag>
                                            <el-select v-if="tagSelectVisible" key="selecttags" v-model="newTagID" ref="saveTagInput" size="small" style="width: auto;margin-left: 5px;" @change="handleNewTag" default-first-option clearable filterable>
                                                <el-option
                                                    v-for="item in availableTags"
                                                    :key="item.TagID"
                                                    :label="item.TagName"
                                                    :value="item.TagID"
                                                >
                                                    <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

                                                </el-option>
                                            </el-select>
                                            <el-button v-else-if="availableTags.length > 0" class="button-new-tag" size="small" @click="showTagSelect">+ New Tag</el-button>
                                        </div>
                                    </el-form-item>
                                </div>
	                            <div v-if="0==1 && (!navReport || report.Params.excltaglinksSharing == 'y')" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.excltaglinksSharing == 'y'}">
		                            <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.excltaglinksSharing" false-label="n" true-label="y">Show</el-checkbox>
		                            <el-form-item label="Exclude Schedule Tags" prop="Tags">
			                            <div class="tags el-input__inner" style="padding: 0px; height: auto;min-height:31px;">
				                            <el-tag
					                            v-for="tag in report.Params.excltaglinks"
					                            :key="tag.TagID"
					                            closable
					                            effect="dark"
					                            :type="tag.TagType"
					                            :disable-transitions="false"
					                            @close="handleCloseExclTag(tag)"
				                            >
					                            {{tag.TagName}}
				                            </el-tag>
				                            <el-select v-if="excltagSelectVisible" key="selecttags" v-model="newExclTagID" ref="saveExclTagInput" size="small" style="width: auto;margin-left: 5px;" @change="handleNewExclTag" default-first-option clearable filterable>
					                            <el-option
						                            v-for="item in availableTags"
						                            :key="item.TagID"
						                            :label="item.TagName"
						                            :value="item.TagID"
					                            >
						                            <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

					                            </el-option>
				                            </el-select>
				                            <el-button v-else-if="availableTags.length > 0" class="button-new-tag" size="small" @click="showExclTagSelect">+ New Tag</el-button>
			                            </div>
		                            </el-form-item>
	                            </div>
                                <div v-if="!navReport || report.Params.gametaglinksSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.gametaglinksSharing == 'y'}">
                                    <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.gametaglinksSharing" false-label="n" true-label="y">Show</el-checkbox>
                                    <el-form-item label="Game Tags" prop="Tags">
                                        <div class="tags el-input__inner" style="padding: 0px; height: auto;min-height:31px;">
                                            <el-tag
                                                v-for="tag in report.Params.gametaglinks"
                                                :key="tag.TagID"
                                                closable
                                                effect="dark"
                                                :type="tag.TagType"
                                                :disable-transitions="false"
                                                @close="handleCloseGameTag(tag)"
                                            >
                                                {{tag.TagName}}
                                            </el-tag>
                                            <el-select v-if="gametagSelectVisible" key="selectgametags" v-model="newGameTagID" ref="saveGameTagInput" size="small" style="width: auto;margin-left: 5px;" @change="handleNewGameTag" default-first-option clearable filterable>
                                                <el-option
                                                    v-for="item in availableGameTags"
                                                    :key="item.TagID"
                                                    :label="item.TagName"
                                                    :value="item.TagID"
                                                >
                                                    <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

                                                </el-option>
                                            </el-select>
                                            <el-button v-else-if="availableGameTags.length > 0" class="button-new-tag" size="small" @click="showGameTagSelect">+ New Tag</el-button>
                                        </div>
                                    </el-form-item>
                                </div>
	                            <div v-if="!navReport || report.Params.exclgametaglinksSharing == 'y'" class="col-12 col-md-6" :class="{'parameterShown' : !navReport && report.Params.exclgametaglinksSharing == 'y'}">
		                            <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.exclgametaglinksSharing" false-label="n" true-label="y">Show</el-checkbox>
		                            <el-form-item label="Exclude Game Tags" prop="Tags">
			                            <div class="tags el-input__inner" style="padding: 0px; height: auto;min-height:31px;">
				                            <el-tag
					                            v-for="tag in report.Params.exclgametaglinks"
					                            :key="tag.TagID"
					                            closable
					                            effect="dark"
					                            :type="tag.TagType"
					                            :disable-transitions="false"
					                            @close="handleCloseExclGameTag(tag)"
				                            >
					                            {{tag.TagName}}
				                            </el-tag>
				                            <el-select v-if="exclgametagSelectVisible" key="selecttags" v-model="newExclGameTagID" ref="saveExclGameTagInput" size="small" style="width: auto;margin-left: 5px;" @change="handleNewExclGameTag" default-first-option clearable filterable>
					                            <el-option
						                            v-for="item in availableGameTags"
						                            :key="item.TagID"
						                            :label="item.TagName"
						                            :value="item.TagID"
					                            >
						                            <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

					                            </el-option>
				                            </el-select>
				                            <el-button v-else-if="availableGameTags.length > 0" class="button-new-tag" size="small" @click="showExclGameTagSelect">+ New Tag</el-button>
			                            </div>
		                            </el-form-item>
	                            </div>
                            </template>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item v-if="$modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" title="Report Columns" name="3">
                    <div class="card">
                        <div class="row">
                            <p style="float:left; margin-top: 5px;">Check the columns to add. Move to position and add your own title, width and alignment.</p>
                            <div class="col-12">
                                <el-checkbox v-model="selectAllColumns" false-label="n" true-label="y" @change="selectColumns">Select All</el-checkbox>
                            </div>
                            <el-button v-if="report.Params.ReportType == 'ProgramContact' || report.Params.ReportType == 'TeamContact' || report.Params.ReportType == 'Player' || report.Params.ReportType == 'Forms'" @click.prevent="addField()" size="small"  style="width: 120px;margin: 0px 0px 10px 10px;"><i class="fa-solid fa-circle-plus"></i> Add Field</el-button>
                            <el-form-item label="" prop="columns">
                                <div class="columnsHeader row" >
                                    <div class="col-1 text-center" style="padding: 0px;">
                                        Drag
                                    </div>
                                    <div class="col-1 text-center" style="padding: 0px;">
                                        Sel/Join
                                    </div>
                                    <div class="col-3 text-left" style="padding: 0px;">
                                        Column
                                    </div>
                                    <div class="col-3 text-center" style="padding: 0px;">
                                        Title
                                    </div>
                                    <div class="col-2 text-center" style="padding: 0px;">
                                        Width
                                    </div>
                                    <div class="col-2 text-center" style="padding: 0px;">
                                        Align
                                    </div>
                                </div>
                                <draggable element="span" v-model="report.Params.Columns" :options="dragOptions" :move="onFieldMove" style="width: 100%;">
                                    <div v-for="(cols, index) in report.Params.Columns" class="row list-group-item" :key="'c'+index" >
                                        <div class="col-1" style="padding: 0px; text-align: center;">
                                            <i class="fas fa-bars bars"></i>
                                        </div>
                                        <div class="col-1" style="padding: 0px 5px;text-align: center;">
                                            <el-checkbox v-model="cols.selected" true-label="y" false-label="n" style="margin-right: 5px;" @change="unJoin(index)"></el-checkbox>
                                            <el-checkbox v-model="cols.join" :disabled="cols.selected=='n' || index == 0" true-label="y" false-label="n"></el-checkbox>
                                        </div>
                                        <div v-if="cols.custom && cols.custom == 'y' " class="col-3">
                                            <el-input placeholder="Column Key" size="small" v-model="cols.column" style="width: 80%; font-weight: bolder;"></el-input>
                                            <a style="margin-left: 15px;" href="javascript:;" @click.prevent="deleteField(index)"><i class="far fa-2x fa-trash-alt"></i></a>
                                        </div>
                                        <div v-else class="col-3">
                                            <template v-if="cols.join == 'y'">
                                                <div style="padding-left: 16px;">
                                                    <i class="fas fa-level-up-alt"></i>
                                                    <strong> {{cols.name}}</strong>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <strong>{{cols.name}}</strong>
                                            </template>
                                        </div>
                                        <div class="col-3">
                                            <el-input :disabled="cols.selected=='n'||cols.join=='y'" placeholder="" size="small" v-model="cols.title"></el-input>
                                        </div>
                                        <div class="col-2">
                                            <el-input :disabled="cols.selected=='n'||cols.join=='y'" placeholder="" size="small" v-model="cols.width"></el-input>
                                        </div>
                                        <div class="col-2">
                                            <el-select :disabled="cols.selected=='n'||cols.join=='y'" size="small" v-model="cols.align">
                                                <el-option key="Left" value="left" label="Left"></el-option>
                                                <el-option key="Right" value="right" label="Right"></el-option>
                                                <el-option key="Center" value="center" label="Center"></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </draggable>
                            </el-form-item>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item v-if="(!navReport || report.Params.groupSharing == 'y' || report.Params.sortSharing == 'y')" title="Grouping/Sorting" name="4">
                    <div class="card">
                        <div v-if="report.Params.ReportType" class="row">
                            <div v-if="!navReport || report.Params.groupSharing == 'y'" class="col-12" :class="{'parameterShown' : !navReport && report.Params.groupSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.groupSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item class="col-12 col-md-8" label="Group By Column:" prop="Group">
                                    <el-select v-model="report.Params.group" placeholder="Select" class="oneHundred" key="select13a"  auto-complete=on default-first-option filterable clearable>
                                        <el-option
                                            v-for="cols in groupItems"
                                            :key="cols.column"
                                            :label="cols.name"
                                            :value="cols.column">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <template v-if="report.Params.group">
                                    <el-form-item v-if="report.Params.group.length > 0" key="select13b" class="col-12 col-sm-12 col-md-6" label="Group Order:">
                                        <el-radio-group v-model="report.Params.groupdirection" size="small">
                                            <el-radio-button selected label="asc">Asc</el-radio-button>
                                            <el-radio-button label="desc">Desc</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </template>
                                <el-form-item key="totals" class="col-12 col-sm-12 col-md-6" label="Show Group/Report Totals">
                                    <el-radio-group v-model="report.Params.groupreporttotals" size="small">
                                        <el-radio-button selected label="y">Yes</el-radio-button>
                                        <el-radio-button label="n">No</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div v-if="!navReport || report.Params.sortSharing == 'y'" class="col-12" :class="{'parameterShown' : !navReport && report.Params.sortSharing == 'y'}">
                                <el-checkbox v-if="report.ReportSharing == 's' && !navReport && $modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="sharing" v-model="report.Params.sortSharing" false-label="n" true-label="y">Show</el-checkbox>
                                <el-form-item label="Sort By Columns:" prop="sort">
                                    <el-select v-model="report.Params.sort" placeholder="Select" class="oneHundred" key="select14a"  multiple auto-complete=on default-first-option filterable clearable>
                                        <el-option
                                            v-for="items in sortItems"
                                            :key="items.column"
                                            :label="items.name"
                                            :value="items.column">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <template v-if="report.Params.sort">
                                    <template v-for="index in report.Params.sort.length">
                                        <el-form-item v-if="report.Params.sort.length > 0" key="select14b" :key="'sortd' + index" class="col-12 col-sm-12 col-md-6" :label="sortName(report.Params.sort[index-1]) + ':'" style=" display: inline-block; width: 150px; padding: 0px;">
                                            <el-radio-group v-model="report.Params.sortdirection[index-1]" size="small">
                                                <el-radio-button selected label="asc">Asc</el-radio-button>
                                                <el-radio-button label="desc">Desc</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </template>
                                </template>
                            </div>
                            <div v-if="!navReport">
                                <el-form-item label="Custom CSS" prop="CSS">
                                    <el-input type="textarea" :autosize="{ minRows:2, maxRows: 2000}" v-model="report.Params.CSS">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-form>

       <div v-if="$modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" class="row mt-4 mb-4">
           <div class="col-12 text-center">
               <button v-if="(ReportID && (report.createdby == currentUser.ContactID || report.ReportSharing == 's'))  || (!ReportID && $createAccess())" class="btn btn-success btn-icon" @click="saveReport">
                   <i class="fas fa-check"></i> <span> Save Changes</span>
               </button>
               <button v-if="ReportID" class="btn btn-success btn-icon" @click="saveNewReport">
                <i class="fa-solid fa-circle-plus"></i> <span> Save As New</span>
               </button>
               <button v-if="ReportID && (report.createdby == currentUser.ContactID || report.ReportSharing == 's')" class="btn btn-icon btn-danger delete"  @click="cnfmDeleteReport()">
                   <i class="far fa-trash-alt"></i>  <span>Delete</span>
               </button>
               <button v-if="ReportID" class="btn btn-icon btn-secondary cancel"  @click="reset">
                   <i class="fas fa-times"></i>  <span>Cancel</span>
               </button>
           </div>
       </div>

       <div class="card" style="min-height: 200px">
           <div v-if="report.Params.ReportType">

               <div v-if="report.Params.ReportType && showButtons" style="height: 40px; margin-bottom: 10px;">
                   <el-button v-if="!((this.report.MySharingParams.Params.AllowEdit == 'n' && this.report.MySharingParams.Params.ShowParameters =='n') || (navReport && !paramsToShow))" class="mt-2 btn-primary" type="primary" @click="runReport" style="float:left;width: 200px;">
                       <span> Run Report</span> <i class="fa-solid fa-caret-right"></i>
                   </el-button>
                   <el-button v-if="reportRecords != '' || Object.keys(recordsGrouped).length !== 0" id="printbutton" class="mt-2 btn-secondary" type="info" style="float:right;" @click="viewPDF">
                       <i class="fas fa-print"></i>  <span>Print</span>
                   </el-button>
	               <template v-if="$propertyAccess('Exports')" >
	                   <el-button v-if="reportRecords != '' || Object.keys(recordsGrouped).length !== 0" id="exportbutton" class="mt-2 btn-secondary" type="info" style="float:right;margin-right: 20px;" @click="exportCSV">
	                       <i class="fas fa-file-export"></i>  <span>Export CSV</span>
	                   </el-button>
	               </template>
               </div>
               <div class="row" style="border-top: 1px solid #EBEEF5;"></div>

               <div class="TablePrint row" id="reportwriterPrint">
                   <span v-if="loadingTable == true" v-loading="loadingTable" class="col-12 align-center" style="margin-top: 60px;"></span>
                   <div v-if="loadingTable == true" class="reportTitle row col-12 align-center">
                       <h5>Report is Generating</h5>
                   </div>
                   <div v-else-if="!navReport" class="reportTitle row col-12 align-center">
                       <h5 >{{report.ReportName}}</h5>
                   </div>
                   <template v-if="reportRecords != ''">
                       <div class="mt-1 mb-1">
                           <el-button v-if="$modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" size="small" class="btn btn-light btn-icon" @click="clearReport">
                               <span> Clear</span> <i class="fa-solid fa-arrow-rotate-left"></i>
                           </el-button>
                       </div>
                       <div class="row noGrouping col-12 mt-4" style="padding:0;">
                           <el-table
                               :data="reportRecords"
                               :key="reportkey"
                               :show-summary="(report.Params.groupreporttotals == 'y' ? true : false)"
                               :summary-method="getSummaries"
                               sum-text="Totals"
                               stripe border
                               empty-text="No Data to Report"
                               size="small"
                               @header-dragend="WidthChange"
                           >
                               <el-table-column
                                   v-for="(field, index) in joinItems"
                                   :key="field.column"
                                   :prop="field.column"
                                   :label="field.title"
                                   :min-width="field.width"
                                   :align="field.align">
                                   <template #default="scope">
                                       <template v-for="(col, colIndex) in field.columnArray">
                                            <div v-html="scope.row[col]"></div>
                                       </template>
                                   </template>
                               </el-table-column>
                           </el-table>
                        </div>
                   </template>

                   <template v-else-if="Object.keys(recordsGrouped).length !== 0">
                       <div class="mt-1 mb-1">
                           <el-button v-if="$modifyAccess() && report.MySharingParams.Params.AllowEdit =='y'" size="small" class="btn btn-light btn-icon" @click="clearReport">
                               <span> Clear</span> <i class="fa-solid fa-arrow-rotate-left"></i>
                           </el-button>
                       </div>
                       <div class="row withGrouping col-12 mt-4 mb-2" v-for="(group, index) in recordsGrouped" style="padding:0;">
                           <h5 style="width: 100%;">{{index}}</h5>
                           <el-table
                               :data="group"
                               :key="reportkey+index"
                               :show-summary="(report.Params.groupreporttotals == 'y' ? true : false)"
                               :summary-method="getSummaries"
                               sum-text="Totals"
                               stripe border
                               empty-text="No Data to Report"
                               size="small"
                               @header-dragend="WidthChange"
                           >
                               <el-table-column
                                   v-for="(field, index) in joinItems"
                                   :key="field.column"
                                   :prop="field.column"
                                   :label="field.title"
                                   :min-width="field.width"
                                   :align="field.align">
                                   <template #default="scope">
                                       <template v-for="(col, colIndex) in field.columnArray">
                                           <div v-html="scope.row[col]"></div>
                                       </template>
                                   </template>
                               </el-table-column>
                           </el-table>
                       </div>
                       <div v-if="report.Params.groupreporttotals == 'y' && grouptotals.length > 0" class="row col-12 mt-4 mb-2" style="padding:0;">
                           <h5 style="width: 100%;">Totals</h5>
                           <el-table
                               :data="grouptotals"
                               key="reportkeygrouptotal"
                               :show-summary="true"
                               :summary-method="getSummaries"
                               sum-text="Totals"
                               stripe border
                               empty-text="No Data to Report"
                               size="small"
                           >
                               <el-table-column
                                   v-for="(field, index) in groupcolumns"
                                   :key="field.column"
                                   :prop="field.column"
                                   :label="field.title"
                                   :min-width="field.width"
                                   :align="field.align">
                                   <template #default="scope">
                                       <div v-html="scope.row[field.column]"></div>
                                   </template>
                               </el-table-column>
                           </el-table>
                       </div>
                   </template>
                   <template v-else-if="noData == true" style="text-align: center;">
                       <p>No Data</p>
                   </template>
               </div>
           </div>
       </div>
       <modal v-if="showPrintModal" @close="showPrintModal = false" width="550px" :top="getPosition +'px'" :draggable="false">
	       <template v-slot:header>
		       <h5 class="modal-title">
			       Print
		       </h5>
	       </template>
	       <template v-slot:body>
               <ReportPrinter ref="reportprinter" :data="pdfRecords" :columns="pdfColumns"  :groupdata="grouptotals"  :groupcolumns="pdfgroupcolumns" :reportName="report.ReportName" :totals="report.Params.groupreporttotals" printDomID="#reportwriterPrint" @close="showPrintModal = false"/>
           </template>
           <template v-slot:footer>
           </template>
       </modal>
    </div>
    <div v-else >
        <alert title="Report Writer is not Available in this Plan" description="Your current plan does not include Report Writer functionality." >
        </alert>
    </div>
</template>

   <script>
       import { mapGetters } from 'vuex';
       import axios from 'axios';
       import Util from "../assets/js/util";
       import Modal from './global/Modal.vue';
       import ReportPrinter from './global/ReportPrinter.vue';
       import { VueDraggableNext } from 'vue-draggable-next';
       import Alert from './global/Alert.vue';

       const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
       export default {
           name: 'reportwriter',
           components: {
               Modal,
               ReportPrinter,
	           draggable: VueDraggableNext,
               Alert,
           },
           data() {
               return {
                   loading: false,
                   loadingTable: false,
                   searchPerformed: false,
                   showPrintModal: false,
                   isDragging: false,
                   delayedDragging: false,
                   editable: true,
                   noData: false,
                   currentStatus : STATUS_INITIAL,
                   activePanels:['1','2','3'],
                   PrivateShared : 'p',
                   reportkey: 1,
                   export : [],
                   ReportNames : [],
                   FolderReportID : [],
                   ReportID : '',
                   ReportFolderName : '',
                   reportRecords : [],
                   recordsGrouped : {},
                   report :  {ReportID : 0, ReportName : '', FolderName : '', ReportSharing: 's', createdby : '', Params: { ReportType: '', Columns : [], GroupBy : 'r', ContactTypeIDs : [], PlayerTypeIDs : [], group : '', groupdirection: 'asc',  sort : [], sortdirection : [], TeamIDs : [], taglinks : [], excltaglinks : [], gametaglinks : [], exclgametaglinks : [] }, SharingParams : [], EmailSharingParams : [], MySharingParams : {RoleSharing : 'n', Params : {AllowEdit : 'y', ShowParameters : 'y', ProgramIDAccess : 'n', TeamIDAccess :'n', MainLocationIDAccess :'n', Navigation : 'n'}}},
                   MainLocationIDs : [],
                   Locations : [],
                   SiteLocations : [],
                   Organizations : [],
                   Leagues : [],
                   Programs : [],
                   ActiveSeasons : [],
                   ScheduleTypes: [],
                   ScheduleRunID : '',
                   AllSchedules : [],
                   InvoiceSettings: [],
                   Tournaments : [],
                   Players : [],
                   ContactTypes : [],
                   PlayerTypes : [],
                   ProgramTeams : [],
                   FormNames : [],
                   FormDefinitions : [],
                   StatusCodes : [],
                   Tags : [],
                   LoginRole : [],
	               newTagID: '',
	               newExclTagID: '',
	               newGameTagID: '',
	               newExclGameTagID: '',
	               tagSelectVisible: false,
	               excltagSelectVisible: false,
                   gametagSelectVisible: false,
	               exclgametagSelectVisible: false,
	               cascaderprops: {expandTrigger: 'hover'},
                   pdfColumns : [],
                   pdf : [],
                   pdfRecords : [],
                   summaries : [],
                   grouptotals : [],
                   groupcolumns : [],
                   pdfgroupcolumns : [],
                   selectAllColumns : 'n',
                   InvoiceStatus : [{key : 'a', label : 'Active'}, {key : 'v', label : 'Void'}],
                   PaymentStatus : [{key : 'A', label : 'Active'}, {key : 'B', label : 'Returned from Bank'}, {key : 'v', label : 'Void'}],
                   InvoiceTypes : [{key : 'IU', label : 'Ice Usage'}, {key : 'BG', label : 'Games'}, {key : 'TR', label : 'Registration'}, {key : 'MI', label : 'Manual'}],
                   ReportTypes : [{label : 'Personnel', options : [{key : "ProgramContact" , label : this.config.Params.programName + " Contacts"}, {key : "TeamContact" , label : "Team Contacts"}, {key : "SiteContact" , label : "Site Contacts"}, {key : "Player" , label : "Players"}, {key : "PlayerSuspensions" , label : "Player Suspensions"}]},
                       {label : 'Finances', options : [{key : "Credits" , label : "Adjustments"}, {key : "Contracts" , label : "Contracts"}, {key : "ContractDetails" , label : "Contract Details"}, {key : "Invoices" , label : "Invoices"},  {key : "Organization" , label : "Organizations"}, {key : "Payments" , label : "Payments"}, {key : "PlayerCredits" , label : "Player Adjustments"}, {key : "PlayerPayments" , label : "Player Payments"}, {key : "PurchaseOrders" , label : "Purchase Orders"}, {key : "PurchaseOrderDetails" , label : "Purchase Order Details"}]},
                       {label : 'Scheduling', options : [{ key: 'ScheduleTeamScoring', label : 'Team Scoring'},{ key: 'ScheduleGameScoring', label : 'Game Scoring'},{key : 'GameCancellations', label : 'Game Cancellations'},{key : 'Constraints', label : 'Scheduling Constraints'},{key : 'FacilityUsage', label : 'Facility Usage'},{key : 'Games', label : 'Games'}]},
                       {label : 'Other', options : [{key : "Team" , label : "Teams"}, {key : "RegisteredTeam" , label : "Registered Teams"}, {key : "Forms" , label : "Forms"}]}],
                   ReportSharingOptions : [ {key : "p", label : "Private"}, {key : "s", label : "Share Report"}],
                   ReportParameters : {Team : ['seasonLeagueProgram'], RegisteredTeam : ['seasonLeagueProgram'], Player : ['seasonLeagueProgram', 'teamSelect', 'tags', 'PlayerTypes'], PlayerSuspensions : ['seasonLeagueProgram'], TeamContact : ['seasonLeagueProgram', 'teamSelect','contactType', 'LoginRole'], ProgramContact : ['Programs', 'contactType', 'LoginRole'], SiteContact : ['Sites', 'LoginRole'], Organization : ['Seasons', 'Organizations', 'Leagues', 'BillingAccount'],  Invoices : ['Seasons', 'Organizations', 'Leagues', 'BillingAccount', 'InvoiceStatus', 'InvoiceType'], Payments : ['Seasons', 'Organizations', 'Leagues', 'BillingAccount', 'PaymentStatus'], PlayerPayments : ['seasonLeagueProgram','teamSelect'], Credits : ['Seasons', 'Organizations', 'Leagues', 'BillingAccount', 'CreditStatus'], PlayerCredits : ['seasonLeagueProgram','teamSelect'], PurchaseOrders : ['FromToDate', 'Sites'],PurchaseOrderDetails : ['FromToDate', 'Sites'], Contracts : ['FromToDate', 'Organizations'],ContractDetails : ['FromToDate', 'Organizations'], Constraints : ['FromToDate', 'seasonLeagueProgram', 'teamSelect'], FacilityUsage : ['Programs', 'FromToDate', 'MainOrLocation', 'SlotTypes', 'ScheduleTypes', 'Organization'], MainLocations : [], Programs : [], GameCancellations : ['FromToDate', 'Sites', 'seasonLeagueProgram', 'teamSelect'], Games : ['FromToDate', 'MainOrLocation', 'seasonLeagueProgram', 'Schedules', 'teamSelect', 'GameParams'], ScheduleTeamScoring : ['Seasons', 'Schedules'], ScheduleGameScoring : ['Seasons', 'Schedules'],Forms : ['FromToDate','Forms']},
                   SlotTypes : [{type : 'o', label : 'Open'},{type : 'on', label : 'Open (Never Booked)'},{type : 'e', label : '3rd Party'},{type : 'ol', label : 'Online Booking'},{type : 'g', label : 'Games'},{type : 'p', label : 'Practices'},],

                   Columns : {
					   Team : [],
	                   RegisteredTeam : [],
	                   Organization : [],
	                   TeamContact : [],
	                   SiteContact : [],
	                   ProgramContact : [],
	                   Player : [],
	                   PlayerSuspensions : [],
	                   PlayerPayments : [],
	                   Invoices : [],
	                   Payments : [],
                       Credits : [],
                       PlayerCredits : [],
	                   Forms : [],
	                   ScheduleTeamScoring : [],
	                   FacilityUsage : [],
	                   MainLocations : [],
	                   Games : [],
	                   GameCancellations : [],
	                   ScheduleStats : [],
                   },
               }
           },
           props:["config","embed","DisplayReportCode","DisplayReportID","parentrefs"],
           computed: {
               ...mapGetters({ currentUser: 'currentUser' }),
               ...mapGetters({ componentIDs: 'componentIDs' }),
               dragOptions() {
                   return {
                       animation: 0,
                       group: "description",
                       disabled: !this.editable,
                       ghostClass: "ghost"
                   };
               },
               isInitial() {
                   return this.currentStatus === STATUS_INITIAL;
               },
               isSaving() {
                   return this.currentStatus === STATUS_SAVING;
               },
               isSuccess() {
                   return this.currentStatus === STATUS_SUCCESS;
               },
               isFailed() {
                   return this.currentStatus === STATUS_FAILED;
               },
               reportOwner () {
                   if (!this.ReportID) {
                       return this.currentUser.FirstName + ' ' + this.currentUser.LastName;
                   } else if (this.report.FirstName) {
                       return this.report.FirstName + ' ' + this.report.LastName;
                   } else {
                       return '';
                   }
               },
               joinItems() {
                   var col = [];
                   if(this.report.Params.Columns[0].join == 'y'){
                       this.report.Params.Columns[0].join = '';
                   }

                   for (let i = 0; i < this.report.Params.Columns.length; i++) {
                       if (this.report.Params.Columns[i].selected == 'y' && this.report.Params.Columns[i].join != 'y') {
                           this.report.Params.Columns[i].columnArray = [this.report.Params.Columns[i].column]
                           for (let j = i+1; j < this.report.Params.Columns.length; j++) {
                               if (this.report.Params.Columns[j].selected == 'y' && this.report.Params.Columns[j].join != 'y') {
                                   break;
                               } else if (this.report.Params.Columns[j].selected == 'y' && this.report.Params.Columns[j].join == 'y') {
                                   this.report.Params.Columns[i].columnArray.push(this.report.Params.Columns[j].column);
                               }
                           }
                           col.push(this.report.Params.Columns[i]);
                       }
                   }
                   this.pdf = col;

                   return col;
               },
               sortItems() {
                   var col = [];

                   for (let i = 0; i < this.report.Params.Columns.length; i++) {
                       if (this.report.Params.Columns[i].sortable) {
                           col.push(this.report.Params.Columns[i]);
                       }
                   }
                   for (let i = 0; i < col.length; i++) {
                       if(this.report.Params.sortdirection[i] != 'asc' && this.report.Params.sortdirection[i] != 'desc'){
                           this.report.Params.sortdirection[i] = 'asc';
                       }
                   }
                   return col;
               },
               groupItems() {
                   var col = [];

                   for (let i = 0; i < this.report.Params.Columns.length; i++) {
                       if (this.report.Params.Columns[i].groupable) {
                           col.push(this.report.Params.Columns[i]);
                       }
                   }
                   return col;
               },
               sortContactType() {
                   var col = [];

                   for (let i = 0; i < this.ContactTypes.length; i++) {
                       if (this.ContactTypes[i].Team == 'y' && this.report.Params.ReportType == 'TeamContact') {
                           col.push(this.ContactTypes[i]);
                       } else if (this.ContactTypes[i].Team == 'n' && this.report.Params.ReportType == 'ProgramContact') {
                           col.push(this.ContactTypes[i]);
                       }
                   }
                   return col;
               },
               showButtons(){
                   var boxChecked = false;

                   for (let i = 0; i < this.report.Params.Columns.length; i++) {
                       if (this.report.Params.Columns[i].selected == 'y' && this.report.Params.Columns[i].join != 'y') {
                           boxChecked = true;
                       }
                   }
                   return boxChecked;
               },
               availableTags () {
                   var tags = [];
                   var tagOk = true;
                   for (let i = 0; i < this.Tags.length; i++) {
                       tagOk = true;
                       if (this.report.Params.taglinks) {
                           for (let j = 0; j < this.report.Params.taglinks.length; j++) {
                               if (this.Tags[i].TagID == this.report.Params.taglinks[j].TagID) {
                                   tagOk = false;
                                   break;
                               }
                           }
                       }
                       if (tagOk) {
	                       if (this.report.Params.excltaglinks) {
		                       for (let j = 0; j < this.report.Params.excltaglinks.length; j++) {
			                       if (this.Tags[i].TagID == this.report.Params.excltaglinks[j].TagID) {
				                       tagOk = false;
				                       break;
			                       }
		                       }
	                       }
                       }
                       if (this.Tags[i].TagArea != 'SC') {
                           tagOk = false;
                       }
                       if (tagOk) {
                           tags.push(this.Tags[i]);
                       }
                   }
                   return tags;
               },
               availablePlayerTags () {
                   var tags = [];
                   var tagOk = true;
                   for (let i = 0; i < this.Tags.length; i++) {
                       tagOk = true;
                       if (this.report.Params.taglinks) {
                           for (let j = 0; j < this.report.Params.taglinks.length; j++) {
                               if (this.Tags[i].TagID == this.report.Params.taglinks[j].TagID) {
                                   tagOk = false;
                                   break;
                               }
                           }
                       }
	                   if (tagOk) {
		                   if (this.report.Params.excltaglinks) {
			                   for (let j = 0; j < this.report.Params.excltaglinks.length; j++) {
				                   if (this.Tags[i].TagID == this.report.Params.excltaglinks[j].TagID) {
					                   tagOk = false;
					                   break;
				                   }
			                   }
		                   }
	                   }
                       if (this.Tags[i].TagArea != 'PL') {
                           tagOk = false;
                       }
                       if (tagOk) {
                           tags.push(this.Tags[i]);
                       }
                   }
                   return tags;
               },
               availableGameTags () {
                   var tags = [];
                   var tagOk = true;
                   for (let i = 0; i < this.Tags.length; i++) {
                       tagOk = true;
                       if (this.report.Params.gametaglinks) {
                           for (let j = 0; j < this.report.Params.gametaglinks.length; j++) {
                               if (this.Tags[i].TagID == this.report.Params.gametaglinks[j].TagID) {
                                   tagOk = false;
                                   break;
                               }
                           }
                       }
	                   if (tagOk) {
		                   if (this.report.Params.exclgametaglinks) {
			                   for (let j = 0; j < this.report.Params.exclgametaglinks.length; j++) {
				                   if (this.Tags[i].TagID == this.report.Params.exclgametaglinks[j].TagID) {
					                   tagOk = false;
					                   break;
				                   }
			                   }
		                   }
	                   }
                       if (this.Tags[i].TagArea != 'GM') {
                           tagOk = false;
                       }
                       if (tagOk) {
                           tags.push(this.Tags[i]);
                       }
                   }
                   return tags;
               },
	           getPosition() {
		           return Util.getPosition('printbutton');
	           },
	           paramsToShow () {
				   if (this.report.Params) {
					   if (this.report.Params.gamedatesSharing == 'y' ||
						   this.report.Params.ActiveFormsSharing == 'y' ||
						   this.report.Params.MainLocationIDSharing == 'y' ||
						   this.report.Params.SeasonIDSharing == 'y' ||
						   this.report.Params.OrganizationIDSharing == 'y' ||
						   this.report.Params.ScheduleTypeIDsSharing == 'y' ||
						   this.report.Params.LeagueIDSharing == 'y' ||
						   this.report.Params.InvoiceSettingIDSharing == 'y' ||
						   this.report.Params.ScheduleIDsSharing == 'y' ||
						   this.report.Params.InvoiceStatusSharing == 'y' ||
						   this.report.Params.PaymentStatusSharing == 'y' ||
                           this.report.Params.CreditStatusSharing == 'y' ||
						   this.report.Params.InvoiceTypeSharing == 'y' ||
						   this.report.Params.PlayerTypeIDsSharing == 'y' ||
						   this.report.Params.ContactTypeIDsSharing == 'y' ||
						   this.report.Params.taglinksSharing == 'y' ||
						   this.report.Params.excltaglinksSharing == 'y' ||
						   this.report.Params.LoginRoleIDsSharing == 'y' ||
						   this.report.Params.GroupBySharing == 'y' ||
						   this.report.Params.gamestatusSharing == 'y' ||
						   this.report.Params.showunassignedSharing == 'y' ||
						   this.report.Params.ForfeitsSharing == 'y' ||
						   this.report.Params.ReuseSlotSharing == 'y' ||
						   this.report.Params.gametaglinksSharing == 'y' ||
						   this.report.Params.exclgametaglinksSharing == 'y') {
						   return true;
					   }
				   }
		           return false;
	           },
	           navReport () {
		           if (this.$route.params.id || this.DisplayReportCode) {
			           return true;
		           }
		           return false;
	           },
           },
           methods: {
			    sortName (column) {
					for (let i = 0; i < this.sortItems.length; i++) {
						if (this.sortItems[i].column == column) {
							return this.sortItems[i].name;
						}
					}
					return '';
			    },
                calcgrouptotals () {
                    this.grouptotals = [];
                    var grouplabel = '';
                    for (let i = 0; i < this.groupItems.length; i++) {
                        if (this.report.Params.group == this.groupItems[i].column) {
                            grouplabel = this.groupItems[i].title;
                            break;
                        }
                    }
                    var currencyColumns = [];
                    for (let j=0; j < this.report.Params.Columns.length; j++) {
                        if (this.report.Params.Columns[j].selected == 'y' && this.report.Params.Columns[j].currency) {
                            currencyColumns.push(this.report.Params.Columns[j]);
                        }
                    }
                    this.groupcolumns = [JSON.parse(JSON.stringify(this.joinItems[0])), JSON.parse(JSON.stringify(this.joinItems[1]))];
                    this.groupcolumns[0].column = this.report.Params.group;
                    this.groupcolumns[0].name = this.report.Params.group;
                    this.groupcolumns[0].title = grouplabel;
                    this.groupcolumns[0].width = 150;
                    this.groupcolumns[0].align = 'left';
                    this.groupcolumns[1].column = 'Num';
                    this.groupcolumns[1].name = 'Num';
                    this.groupcolumns[1].title = 'Num';
                    this.groupcolumns[1].width = 50;
                    this.groupcolumns[1].align = 'center';
                    for (let k=0; k < currencyColumns.length; k++) {
                        this.groupcolumns[2+k] = {};
                        this.groupcolumns[2+k].column = currencyColumns[k].column;
                        this.groupcolumns[2+k].name = currencyColumns[k].name;
                        this.groupcolumns[2+k].title = currencyColumns[k].title;
                        this.groupcolumns[2+k].width = 100;
                        this.groupcolumns[2+k].align = 'center';
                    }
                    var groupsummaries = [];
                    var group = '';
                    var row = [];
                    var j = 0;
                    var self = this;
                    var groupvalues = []
                    for (const property in this.recordsGrouped) {
                        groupvalues.push(property);
                        j++;
                    }
                    if (this.summaries.length == j) {
                        for (let i = 0; i < this.summaries.length; i++) {
                            row = {};
                            row[this.report.Params.group] = groupvalues[i];
                            row['Num'] = this.summaries[i][1];
                            for (let k = 2; k < this.summaries[i].length; k++) {
                            if (this.summaries[i][k]) {
                                row[this.groupcolumns[k].column] = this.summaries[i][k];
                            }
                        }
                        groupsummaries.push(row);
                        }
                        this.grouptotals = groupsummaries;
                    } else {
                        setTimeout(() => { self.calcgrouptotals(); }, 100);
                    }
               },
               addEmailSharing() {
                   this.report.EmailSharingParams.push({EmailAddress : '', RoleSharing : 'y', Params : { AllowEdit : 'n',ShowParameters : 'n',ProgramIDAccess : 'n', TeamIDAccess : 'n', MainLocationIDAccess : 'n', Navigation : 'n' }});
               },
               searchEmail(queryString, callback) {
                   if (queryString.length < 3) {
                       callback([]);
                       return;
                   }
                   var self = this;
                   this.contacts = [];

                   var url = 'getcontactbyemail';

                   axios.post('/Universalsearch/' + url, {
                           EmailAddress: queryString,
                           Active : 'y'
                       },
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               this.contacts = response.data.contacts;
                               callback(this.contacts);
                           }
                       }).catch(response => {
                       console.log('fetch error');
                   });

               },
               handleSelect(item) {
                   console.log( item.EmailAddress );
               },
               selectColumns () {
                   for (let i = 0; i < this.report.Params.Columns.length; i++) {
                       this.report.Params.Columns[i]['selected'] = this.selectAllColumns;
                   }
               },
               WidthChange (newWidth, oldWidth, column, event) {
                   if (column.property) {
                       for (let i = 0; i < this.report.Params.Columns.length; i++) {
                           if (this.report.Params.Columns[i].column == column.property) {
                               this.report.Params.Columns[i].width = newWidth;
                           }
                       }
                   }
               },
               unJoin(idx) {
                   if( this.report.Params.Columns[idx].selected != 'y') {
                       this.report.Params.Columns[idx].join = '';
                   }
               },
               getSummaries(param) {
                   if (!this.report.Params.Columns || this.report.Params.Columns.length == 0) {
                       return;
                   }

                   const { columns, data } = param;
                   const sums = [];
                   var dollarcolumn;
                   if (columns && columns.length > 0) {
                       columns.forEach((column, index) => {
                           if (index === 0) {
                               sums[index] = 'Totals';
                               return;
                           }
                           if (data[0][column.property]) {
                               try {
                                   if (data[0][column.property] && data[0][column.property].toString().indexOf('$') !== -1) {
                                       dollarcolumn = true;
                                   } else {
                                       dollarcolumn = false;
                                   }
                               }
                               catch(err) {

                               }

                               //const values = data.map(item => Number(item[column.property].toString().replace(/[$,)]/g, '').replace(/[(]/g, '-')));
                               var values = [];
                               for(let i=0; i<data.length; i++) {
                                   try {
                                       values.push(Number(data[i][column.property].toString().replace(/[$,)]/g, '').replace(/[(]/g, '-')));
                                   }
                                   catch(err) {
                                       values.push(0);
                                   }
                               }

                               if (index === 1 && column.property != 'Num') {
                                   sums[index] = values.length;
                                   return;
                               }

                               if (!values.every(value => isNaN(value))) {
                                   if (dollarcolumn) {
                                       sums[index] = this.formatPrice(values.reduce((prev, curr) => {
                                           const value = Number(curr);
                                           if (!isNaN(value)) {
                                               return prev + curr;
                                           } else {
                                               return prev;
                                           }
                                       }, 0));
                                   } else if (index === 1 && column.property == 'Num') {

                                        sums[index] = values.reduce((prev, curr) => {
                                               const value = Number(curr);
                                               if (!isNaN(value)) {
                                                   return prev + curr;
                                               } else {
                                                   return prev;
                                               }
                                       });
                                   }
                               }
                           }
                        });

                        return sums;
                   }
               },
               collapseChange () {
                   localStorage.setItem('reportpanels', JSON.stringify(this.activePanels));
               },
               PrivateSharedChanged () {
                   this.reportRecords = [];
                   this.recordsGrouped = {};
                   this.FolderReportID = [];
                   this.ReportID = '';
                   this.report = {ReportID : 0, ReportName : '', FolderName : '', ReportSharing: 'p', createdby: this.currentUser.ContactID, Params: { ReportType: '', Columns : [], GroupBy : 'r', ContactTypeIDs : [], PlayerTypeIDs : [], group : '', groupdirection: 'asc', sort : [], sortdirection : [], TeamIDs : [], gametype : 'Live', ScheduleIDs : [], ProgramID : '', LeagueID : ''}, SharingParams : [], EmailSharingParams : []};
                   this.report.SharingParams = this.buildSharedSecurity(this.report);
                   this.report.EmailSharingParams = []
                   this.report.MySharingParams = {RoleSharing: 'n' , Params : {AllowEdit : 'y', ShowParameters : 'y', ProgramIDAccess : 'n', TeamIDAccess :'n', MainLocationIDAccess :'n', Navigation : 'n'}};

                   this.$store.dispatch('setID', {'FolderReportID': this.FolderReportID});
                   this.$store.dispatch('setID', {'PrivateShared': this.PrivateShared});

               },
               ReportChange () {
                   this.reportRecords = '';
                   this.export = '';
	               this.report.Params.taglinks = [];
	               this.report.Params.excltaglinks = [];
	               this.report.Params.gametaglinks = [];
	               this.report.Params.exclgametaglinks = [];
				   this.report.Params.sort = [];
                   this.report.Params.sortdirection = [];
                   this.report.Params.group = '';
                   this.report.Params.Columns = this.Columns[this.report.Params.ReportType];

                   this.seasonChange();
                   this.ReportTypeChanged();
               },
               ReportTypeChanged () {
                   this.clearReport();
                   this.selectAllColumns = 'n';
                   if (this.report.MySharingParams.Params.ProgramIDAccess == 'y') {
                       this.report.Params.ProgramID = this.currentUser.ProgramID;
                   }
                    var allColumns = this.Columns[this.report.Params.ReportType];
                    var usedColumns = [];

                    if (!this.report.Params.SeasonID) {
                       this.report.Params.SeasonID = this.ActiveSeasons[0].SeasonID;
                    }
                    this.getLeagues();
                    if (this.report.Params.LeagueID) {
                        this.getPrograms();
                    }
                    if (this.report.Params.LeagueID || this.report.Params.ProgramID) {
                       this.getTeams();
                    }
                    if (!this.report.Params.Columns) {
                        this.report.Params['Columns'] = [];
                    }
               },
               addField() {
                   this.report.Params.Columns.unshift(
                       {
                           title: '',
                           name : '',
                           join: '',
                           column: '',
                           width : '',
                           selected : 'y',
                           sortable : true,
                           groupable: true,
                           align : 'left',
                           fixed: false,
                           custom : 'y'

                       }
                   );
               },
               deleteField (idx) {
                   for (let i = 0; i < this.report.Params.sort.length; i++) {
                       if(this.report.Params.Columns[idx].column == this.report.Params.sort[i]){
                           this.report.Params.sort.splice(i, 1);
                           this.report.Params.sortdirection.splice(i, 1);
                       }
                   }
                   if(this.report.Params.Columns[idx].column == this.report.Params.group){
                       this.report.Params.group = '';
                   }
                   this.report.Params.Columns.splice(idx, 1);
               },
               onFieldMove({ relatedContext, draggedContext }) {
                   const relatedElement = relatedContext.element;
                   const draggedElement = draggedContext.element;
                   return (
                       (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
                   );
               },
               handleNewTag() {
                   let newTagID = this.newTagID;
                   if (newTagID) {
                       for (let i = 0; i < this.Tags.length; i++) {
                           if (this.Tags[i].TagID == newTagID) {
                               if (!this.report.Params.taglinks) {
                                   this.report.Params['taglinks'] = [];
                               }
                               this.report.Params.taglinks.push(this.Tags[i]);
                               break;
                           }
                       }
                   }
                   this.tagSelectVisible = false;
                   this.newTagID = '';
               },
               handleCloseTag(tag) {
                   this.report.Params.taglinks.splice(this.report.Params.taglinks.indexOf(tag), 1);
               },
               showTagSelect() {
                   this.tagSelectVisible = true;
                   this.$nextTick(_ => {
                       this.$refs.saveTagInput.focus();
                   });
               },
	           handleNewExclTag() {
		           let newTagID = this.newExclTagID;
		           if (newTagID) {
			           for (let i = 0; i < this.Tags.length; i++) {
				           if (this.Tags[i].TagID == newTagID) {
					           if (!this.report.Params.excltaglinks) {
						           this.report.Params['excltaglinks'] = [];
					           }
					           this.report.Params.excltaglinks.push(this.Tags[i]);
					           break;
				           }
			           }
		           }
		           this.excltagSelectVisible = false;
		           this.newExclTagID = '';
	           },
	           handleCloseExclTag(tag) {
		           this.report.Params.excltaglinks.splice(this.report.Params.excltaglinks.indexOf(tag), 1);
	           },
	           showExclTagSelect() {
		           this.excltagSelectVisible = true;
		           this.$nextTick(_ => {
			           this.$refs.saveExclTagInput.focus();
		           });
	           },
               handleNewGameTag() {
                   let newTagID = this.newGameTagID;
                   if (newTagID) {
                       for (let i = 0; i < this.Tags.length; i++) {
                           if (this.Tags[i].TagID == newTagID) {
                               if (!this.report.Params.gametaglinks) {
                                   this.report.Params['gametaglinks'] = [];
                               }
                               this.report.Params.gametaglinks.push(this.Tags[i]);
                               break;
                           }
                       }
                   }
                   this.gametagSelectVisible = false;
                   this.newGameTagID = '';
               },
               handleCloseGameTag(tag) {
                   this.report.Params.gametaglinks.splice(this.report.Params.gametaglinks.indexOf(tag), 1);
               },
               showGameTagSelect() {
                   this.gametagSelectVisible = true;
                   this.$nextTick(_ => {
                       this.$refs.saveGameTagInput.focus();
                   });
               },
	           handleNewExclGameTag() {
		           let newTagID = this.newExclGameTagID;
		           if (newTagID) {
			           for (let i = 0; i < this.Tags.length; i++) {
				           if (this.Tags[i].TagID == newTagID) {
					           if (!this.report.Params.exclgametaglinks) {
						           this.report.Params['exclgametaglinks'] = [];
					           }
					           this.report.Params.exclgametaglinks.push(this.Tags[i]);
					           break;
				           }
			           }
		           }
		           this.exclgametagSelectVisible = false;
		           this.newExclGameTagID = '';
	           },
	           handleCloseExclGameTag(tag) {
		           this.report.Params.exclgametaglinks.splice(this.report.Params.exclgametaglinks.indexOf(tag), 1);
	           },
	           showExclGameTagSelect() {
		           this.exclgametagSelectVisible = true;
		           this.$nextTick(_ => {
			           this.$refs.saveExclGameTagInput.focus();
		           });
	           },

	           ParameterValid (param) {
                   if (this.report.Params.ReportType) {
                       if (!this.ReportParameters[this.report.Params.ReportType] || this.ReportParameters[this.report.Params.ReportType].indexOf(param) === -1) {
                           return false;
                       } else {
                           return true;
                       }
                   } else {
                       return false;
                   }
               },
               filterCascader(node, keyword) {
                   if (node.label.toLowerCase().indexOf(keyword.toLowerCase()) === -1) {
                       return false;
                   } else {
                       return true;
                   }
               },
               formatPrice(value) {
	               if (value) {
		               return Util.formatPrice(this.config, parseFloat(value), '0');
	               } else {
		               return '';
	               }
               },
               formatGameColumns() {
                    this.Columns.Games = [
                        {title:"Game ID",join:"",name:"Game ID",column:"GameID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
                        {title:"No.",join:"",name:"Game No.",column:"SlotNumber",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "center"},
                        {title:"Date",join:"",name:"Date",column:"GameDate",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Date Formatted",join:"",name:"Date Formatted",column:"GameDateF",width:"170",selected:"n", order: 3, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Start Time",join:"",name:"Start Time",column:"StartTime",width:"100",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"End Time",join:"",name:"End Time",column:"EndTime",width:"100",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Duration",join:"",name:"Duration",column:"Duration",width:"100",selected:"n", order: 6, fixed: false, sortable: true, groupable: false, align: "center"},
                        {title:this.config.Params.mainlocationName,join:"",name:this.config.Params.mainlocationName,column:"MainLocationName",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:this.config.Params.locationName,join:"",name:this.config.Params.locationName,column:"LocationName",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Reuse Slot",join:"",name:"Reuse Slot",column:"ReuseSlot",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                        {title:"League",join:"",name:"League",column:"LeagueName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Schedule",join:"",name:"Schedule",column:"ScheduleName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Division Name",join:"",name:"Division Name",column:"DivisionName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Division",join:"",name:"Division Short Name",column:"DivisionShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Home " + this.config.Params.programName,join:"",name:"Home " + this.config.Params.programName + " Name",column:"HomeProgramName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Home Team",join:"",name:"Home Team Name",column:"HomeTeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Home Team",join:"",name:"Home Team Short Name",column:"HomeTeamShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Home Team 3",join:"",name:"Home Team Name 3",column:"HomeTeamName3",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Away " + this.config.Params.programName,join:"",name:"Away " + this.config.Params.programName + " Name",column:"VisitingProgramName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Away Team Name",join:"",name:"Away Team Name",column:"VisitingTeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Away Team",join:"",name:"Away Team Short Name",column:"VisitingTeamShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Away Team 3",join:"",name:"Away Team Name 3",column:"VisitingTeamName3",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Home Score",join:"",name:"Home Score",column:"HomeScore",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "center"},
                        {title:"Away Score",join:"",name:"Away Score",column:"VisitingScore",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "center"},
                        {title:this.config.Params.programName,join:"",name:this.config.Params.programName + " Name",column:"ProgramName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Team",join:"",name:this.config.Params.programName + " Team",column:"TeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Score",join:"",name:this.config.Params.programName + " Team Score",column:"TeamScore",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "center"},
                        {title:"Opponent",join:"",name:"Opponent Team",column:"OpponentName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Score",join:"",name:"Opponent Team Score",column:"OpponentScore",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "center"},
                        {title:this.config.Params.programName + " Forfeit W/L",join:"",name:this.config.Params.programName + " Forfeit W/L",column:"Forfeit",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "center"},
                        {title:"Costs",join:"",name:"Costs",column:"Cost",width:"75",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
                        {title:"Game Fees",join:"",name:"Game Fees",column:"GameRate",width:"75",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
                        {title:"Home Fee",join:"",name:"Home Fee",column:"HomeGameRate",width:"75",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
                        {title:"Away Fee",join:"",name:"Away Fee",column:"VisitingGameRate",width:"75",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
                        {title:"Status",join:"",name:"Game Status",column:"StatusCodeName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Tags",join:"",name:"Tags",column:"Tags",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Schedule Types",join:"",name:"Schedule Types",column:"ScheduleTypes",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Web Notes",join:"",name:"Web Notes",column:"WebNotes",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Game Notes",join:"",name:"Game Notes",column:"GameNotes",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Internal Notes",join:"",name:"Internal Notes",column:"InternalNotes",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                  ];
                   this.Columns.ScheduleGameScoring = [
                       {title:"Date",join:"",name:"Date",column:"GameDate",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"Date Formatted",column:"GameDateF",width:"170",selected:"n", order: 3, fixed: false, sortable: false, groupable: true, align: "left"},
                       {title:"Start Time",join:"",name:"Start Time",column:"StartTime",width:"100",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Game Type",join:"",name:"Game Type",column:"GameType",width:"100",selected:"n", order: 4, fixed: false, sortable: false, groupable: false, align: "center"},
                       {title:this.config.Params.locationName,join:"",name:this.config.Params.locationName,column:"LocationName",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"League",join:"",name:"League",column:"LeagueName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Schedule",join:"",name:"Schedule",column:"ScheduleName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Division Name",join:"",name:"Division Name",column:"DivisionName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Division",join:"",name:"Division Short Name",column:"DivisionShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Home/Away",join:"",name:"Home/Away",column:"HomeAway",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "center"},
                       {title:"Team",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Team",join:"",name:"Team Short Name",column:"TeamShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Team 3",join:"",name:"Team Name 3",column:"TeamName3",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Opponent Team Name",join:"",name:"Opponent Team Name",column:"OpponentTeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                       {title:"Opponent Team",join:"",name:"Opponent Team Short Name",column:"OpponentTeamShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                       {title:"Opponent Team 3",join:"",name:"Opponent Team Name 3",column:"OpponentTeamName3",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                       {title:"Travel Time",join:"",name:"Travel Time",column:"TravelTime",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Travel Score",join:"",name:"Travel Score",column:"TravelScore",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Opponent Travel Time",join:"",name:"Opponent Travel Time",column:"OpponentTravelTime",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Opponent Travel Score",join:"",name:"Opponent Travel Score",column:"OpponentTravelScore",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Game Travel Time",join:"",name:"Game Travel Time",column:"GameTravelTime",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Game Min Travel Time",join:"",name:"Game Min Travel Time",column:"GameMinTravelTime",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Game Travel Raw Score",join:"",name:"Game Travel Raw Score",column:"GameTravelTimeScore",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Game Travel Score",join:"",name:"Game Travel Score",column:"GameTravelScore",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Start Time Score",join:"",name:"Start Time Score",column:"StartTimeScore",width:"75",selected:"n", order: 12, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Gap Score",join:"",name:"Gap Score",column:"GapScore",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Opponent Gap Score",join:"",name:"Opponent Gap Score",column:"OpponentGapScore",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                     ];
                   this.Columns.GameCancellations = [
                        {title:"Date",join:"",name:"Date",column:"GameDate",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Date Formatted",join:"",name:"Date Formatted",column:"GameDateF",width:"170",selected:"n", order: 3, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Start Time",join:"",name:"Start Time",column:"StartTime",width:"100",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:this.config.Params.locationNames,join:"",name:this.config.Params.locationNames,column:"LocationName",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"League",join:"",name:"League",column:"LeagueName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Division Name",join:"",name:"Division Name",column:"DivisionName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                        {title:"Home Team",join:"",name:"Home Team Name",column:"HomeTeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Away Team Name",join:"",name:"Away Team Name",column:"VisitingTeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Submission Date",join:"",name:"Submission Date",column:"submittedwhen",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Submitter First Name",join:"",name:"Submitter First Name",column:"SubmitterFirstName",width:"120",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Submitter Last Name",join:"",name:"Submitter Last Name",column:"SubmitterLastName",width:"120",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Submitter Email Address",join:"",name:"Submitter Email Address",column:"SubmitterEmailAddress",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Submitter Phone Number",join:"",name:"Submitter Phone Number",column:"SubmitterPhoneNumber",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Submitter Team Name",join:"",name:"Submitter Team Name",column:"SubmitterTeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Who Cancelled",join:"",name:"Who Cancelled",column:"WhoCancelled",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                        {title:"Reason",join:"",name:"Reason",column:"Reason",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Submitter Ice Usage",join:"",name:"Submitter Ice Usage",column:"MyTeamUsage",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Other Team Response Date",join:"",name:"Other Team Response Date",column:"OtherTeamResponseDate",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Other Team Response Email",join:"",name:"Other Team Response Email",column:"OtherTeamResponseEmailAddress",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                        {title:"Other Team Ice Usage",join:"",name:"Other Team Ice Usage",column:"OtherTeamUsage",width:"180",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
                    ];
                   this.Columns.Constraints = [
                       {title:"Constraint ID",join:"",name:"Constraint ID",column:"ConstraintSlotID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Title",join:"",name: "Title", column: "Title",width:"180",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "left"},
                       {title:"Description",join:"",name: "Description", column: "Description",width:"180",selected:"n", order: 11, fixed: false, sortable: false, groupable: false, align: "left"},
                       {title:"Start Date",join:"",name:"Start Dates",column:"StartDate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"End Date",join:"",name:"End Dates",column:"EndDate",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Repeats",join:"",name:"Repeats",column:"Repeats",width:"100",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Start Time",join:"",name:"Start Time",column:"StartTime",width:"100",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"End Time",join:"",name:"End Time",column:"EndTime",width:"100",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Duration",join:"",name:"Duration",column:"Duration",width:"100",selected:"n", order: 5, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Game Time Compare",join:"",name:"Game Time Compare",column:"CompareStartEndTime",width:"100",selected:"n", order: 5, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Apply to",join:"",name:"Apply to",column:"Applyto",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"League",join:"",name:"League",column:"LeagueName",width:"180",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Division Types",join:"",name:"DivisionTypes",column:"DivisionTypes",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"180",selected:"n", order: 8, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Team",join:"",name:"Team",column:"TeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.mainlocationNames,join:"",name:this.config.Params.mainlocationNames,column:"MainLocationName",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.locationNames,join:"",name:this.config.Params.locationNames,column:"LocationName",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                   ];
                   this.Columns.FacilityUsage = [
                       {title:"Usage",join:"",name:"Usage",column:"Usage",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date",join:"",name:"Date",column:"GameDate",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"Date Formatted",column:"GameDateF",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Week No",join:"",name:"Week No",column:"WeekNo",width:"100",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:"Month",join:"",name:"Month",column:"Month",width:"100",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Month No",join:"",name:"Month No",column:"MonthNo",width:"100",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:"Start Time",join:"",name:"Start Time",column:"StartTime",width:"100",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Duration",join:"",name:"Duration",column:"Duration",width:"100",selected:"n", order: 6, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:this.config.Params.locationName,join:"",name:this.config.Params.locationName,column:"Location",width:"180",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.mainlocationName,join:"",name:this.config.Params.mainlocationName,column:"MainLocationName",width:"180",selected:"n", order:8, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.locationName,join:"",name:this.config.Params.locationName + ' + (' + 'X' + this.config.Params.playingsurface + ')',column:"LocationName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Details",join:"",name:"Details",column:"Details",width:"180",selected:"n", order: 10, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"League",join:"",name:"League",column:"LeagueName",width:"180",selected:"n", order: 11, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Schedule Types",join:"",name:"Schedule Types",column:"ScheduleTypes",width:"180",selected:"n", order: 12, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Attendees/Contact",join:"",name:"Attendees/Contact",column:"Attendees",width:"180",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "left"},
                       {title:"Costs",join:"",name:"Costs",column:"Cost",width:"75",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
                       {title:"3rd Party Fees",join:"",name:"3rd Party Fees",column:"Fee",width:"75",selected:"n", order: 15, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
                       {title:"Game Fees",join:"",name:"Game Fees",column:"GameRate",width:"75",selected:"n", order: 16, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
                       {title:"Status",join:"",name:"Status",column:"StatusCodeName",width:"125",selected:"n", order: 17, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Game Notes",join:"",name:"Game Notes",column:"Notes",width:"155",selected:"n", order: 18, fixed: false, sortable: false, groupable: false, align: "left"},
                   ];
                   this.Columns.PurchaseOrders = [
                       {title:"Date",join:"",name:"PO Date",column:"PODate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"PO Date Formatted",column:"PODateF",width:"120",selected:"n", order: 2, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"PO Number",join:"",name:"PO Number",column:"PONumberS",width:"75",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:"Emailed",join:"",name:"Emailed",column:"POEmailed",width:"75",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:this.config.Params.mainlocationName,join:"",name:this.config.Params.mainlocationName,column:"MainLocationName",width:"120",selected:"n", order:8, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"No of Slots",join:"",name:"No of Slots",column:"DetailNumber",width:"60",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "center", currency: false},
                       {title:"Order Total",join:"",name:"Order Total",column:"DetailAmount",width:"100",selected:"n", order: 14, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
                   ];
                   this.Columns.PurchaseOrderDetails = [
                       {title:"PO Date",join:"",name:"Date",column:"PODate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"Date Formatted",column:"PODateF",width:"120",selected:"n", order: 2, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"PO Number",join:"",name:"PO Number",column:"PONumberS",width:"75",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:this.config.Params.mainlocationName,join:"",name:this.config.Params.mainlocationName,column:"MainLocationName",width:"120",selected:"n", order:4, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.locationName,join:"",name:this.config.Params.locationName,column:"LocationName",width:"120",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Slot Date",join:"",name:"Slot Date",column:"GameDate",width:"100",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"Slot Date Formatted",column:"GameDateF",width:"120",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Start Time",join:"",name:"Start Time",column:"StartTime",width:"100",selected:"n", order: 8, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Duration",join:"",name:"Duration",column:"Duration",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Cost",join:"",name:"Cost",column:"DetailAmount",width:"100",selected:"n", order: 10, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
                   ];
                   this.Columns.Contracts = [
                       {title:"Date",join:"",name:"Contract Date",column:"ContractDate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"Contract Date Formatted",column:"ContractDateF",width:"120",selected:"n", order: 2, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Contract Number",join:"",name:"Contract Number",column:"ContractNumber",width:"75",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:"Emailed",join:"",name:"Emailed",column:"ContractEmailed",width:"75",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:"Signed",join:"",name:"Signed",column:"ContractSigned",width:"75",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:"Signed By",join:"",name:"Signed By",column:"PrintName",width:"125",selected:"n", order: 3, fixed: false, sortable: false, groupable: false, align: "left"},
                       {title:"Signed On",join:"",name:"Signed On",column:"ContractApprovalDate",width:"100",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Organization",join:"",name:"Organization",column:"OrganizationName",width:"120",selected:"n", order:8, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"No of Slots",join:"",name:"No of Slots",column:"DetailNumber",width:"60",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "center", currency: false},
                       {title:"Order Total",join:"",name:"Order Total",column:"DetailAmount",width:"100",selected:"n", order: 14, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
                   ];
                   this.Columns.ContractDetails = [
                       {title:"Contract Date",join:"",name:"Contract Date",column:"ContractDate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"Contract Date Formatted",column:"ContractDateF",width:"120",selected:"n", order: 2, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Contract Number",join:"",name:"Contract Number",column:"ContractNumber",width:"75",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "center"},
                       {title:"Organization",join:"",name:"Organization",column:"OrganizationName",width:"120",selected:"n", order:8, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.mainlocationName,join:"",name:this.config.Params.mainlocationName,column:"MainLocationName",width:"120",selected:"n", order:4, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:this.config.Params.locationName,join:"",name:this.config.Params.locationName,column:"LocationName",width:"120",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Slot Date",join:"",name:"Slot Date",column:"StartDate",width:"100",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Date Formatted",join:"",name:"Slot Date Formatted",column:"StartDateF",width:"120",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Start Time",join:"",name:"Start Time",column:"StartTime",width:"100",selected:"n", order: 8, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Duration",join:"",name:"Duration",column:"Duration",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Fee",join:"",name:"Fee",column:"DetailAmount",width:"100",selected:"n", order: 10, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
                   ];
	               this.Columns.Team = [
		               {title:"Team ID",join:"",name:"Team ID",column:"TeamID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Team Name",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Team Short Name",join:"",name:"Team Short Name",column:"TeamShortName",width:"150",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Team Name 3",join:"",name:"Team Name 3",column:"TeamName3",width:"150",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name: this.config.Params.programName, column: "ProgramName",width:"170",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name: "League", column: "LeagueName",width:"130",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Division Name",join:"",name: "Division Name", column: "DivisionName",width:"140",selected:"n", order: 10, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Division Type",join:"",name: "Division Type", column: "DivisionType",width:"120",selected:"n", order: 8, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Division Level",join:"",name: "Division Level", column: "DivisionLevel",width:"90",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Registered League",join:"",name: "Registered League", column: "RegisteredLeagueName",width:"130",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Registered Number",join:"",name: "Registered Number", column: "RegisteredNumberv",width:"70",selected:"n", order: 7, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Registered Games",join:"",name: "Registered Games", column: "RegisteredGames",width:"70",selected:"n", order: 7, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Status",join:"",name: "Registration Status", column: "Status",width:"70",selected:"n", order: 11, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Registration Notes",join:"",name: "Registration Notes", column: "RegisteredNotes",width:"120",selected:"n", order: 12, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Allow Doubleheaders",join:"",name: "Allow Doubleheaders", column: "DoubleHeaders",width:"120",selected:"n", order: 13, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Reg Fees",join:"",name:"Reg Fees",column:"RegFees",width:"100",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Game Fees",join:"",name:"Game Fees",column:"GameFees",width:"100",selected:"n", order: 15, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Reg Fees Status",join:"",name:"Registration Fees Status",column:"RegisteredStatus",width:"130",selected:"n", order: 16, fixed: false, sortable: true, groupable: true, align: "left"},                     
	               ];
	               this.Columns.RegisteredTeam = [
		               {title:"Team ID",join:"",name:"Team ID",column:"TeamID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Team Name",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Team Short Name",join:"",name:"Team Short Name",column:"TeamShortName",width:"150",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Team Name 3",join:"",name:"Team Name 3",column:"TeamName3",width:"150",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name: this.config.Params.programName, column: "ProgramName",width:"170",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name: "League", column: "LeagueName",width:"130",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Registered Number",join:"",name: "Registered Number", column: "RegisteredNumberv",width:"70",selected:"n", order: 7, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Registered Games",join:"",name: "Registered Games", column: "RegisteredGames",width:"70",selected:"n", order: 7, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Division Type",join:"",name: "Registered Division Type", column: "DivisionType",width:"120",selected:"n", order: 8, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Division Level",join:"",name: "Registered Division Level", column: "DivisionLevel",width:"90",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Division Name",join:"",name: "Actual Division Name", column: "DivisionName",width:"140",selected:"n", order: 10, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Status",join:"",name: "Registration Status", column: "Status",width:"70",selected:"n", order: 11, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Registration Notes",join:"",name: "Registration Notes", column: "RegisteredNotes",width:"120",selected:"n", order: 12, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Reg Fees",join:"",name:"Reg Fees",column:"RegFees",width:"100",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Game Fees",join:"",name:"Game Fees",column:"GameFees",width:"100",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Who Added",join:"",name: "Who Added", column: "WhoAdded",width:"100",selected:"n", order: 14, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Date Added",join:"",name: "Date Added", column: "DateAdded",width:"70",selected:"n", order: 15, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Who Dropped",join:"",name: "Who Dropped", column: "WhoDropped",width:"100",selected:"n", order: 16, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Date Dropped",join:"",name: "Date Dropped", column: "DateDropped",width:"70",selected:"n", order: 17, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Allow Doubleheaders",join:"",name: "Allow Doubleheaders", column: "DoubleHeaders",width:"120",selected:"n", order: 18, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Reg Fees Status",join:"",name:"Registration Fees Status",column:"RegisteredStatus",width:"130",selected:"n", order: 19, fixed: false, sortable: true, groupable: true, align: "left"},                     

	               ];
                   this.Columns.MainLocations = [
		               {title:this.config.Params.mainlocationName + " ID",join:"",name:this.config.Params.mainlocationName + " ID",column:"MainLocationID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:this.config.Params.mainlocationName + " Name",join:"",name:this.config.Params.mainlocationName + " Name",column:"MainLocationName",width:"180",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Contact",join:"",name:"Contact",column:"ContactName",width:"150",selected:"n", order: 8, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Contact Email",join:"",name:"Contact Email",column:"EmailAddress",width:"150",selected:"n", order: 8, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Address",join:"",name:"Full Address",column:"Address",width:"300",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Address Line",column:"AddressLine1",width:"170",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"City",join:"",name:"City",column:"City",width:"130",selected:"n", order: 5, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:this.config.Params.state,join:"",name:this.config.Params.state,column:"StateOrProvinceCodeID",width:"130",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.zipcode,join:"",name:this.config.Params.zipcode,column:"PostalCode",width:"100",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Setup Time (min.)",join:"",name:"Setup Time",column:"GameSetup",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center", currency: false},
		               {title:"Default Cost",join:"",name:"Default Cost",column:"DefaultCost",width:"100",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Default Fee",join:"",name:"Default Fee",column:"DefaultFee",width:"100",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:this.config.Params.locationNames,join:"",name:this.config.Params.locationNames,column:"LocationNames",width:"200",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "left", currency: false},
	               ];
	               this.Columns.Organization = [
		               {title:"Organization ID",join:"",name:"Organization ID",column:"OrganizationID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Organization Name",join:"",name:"Organization Name",column:"OrganizationName",width:"180",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:this.config.Params.programName + " Name",join:"",name:this.config.Params.programName + " Name",column:"ProgramName",width:"180",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Full Address",column:"Address",width:"300",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Address Line",column:"AddressLine1",width:"170",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"City",join:"",name:"City",column:"City",width:"130",selected:"n", order: 5, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:this.config.Params.state,join:"",name:this.config.Params.state,column:"StateOrProvinceCodeID",width:"130",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.zipcode,join:"",name:this.config.Params.zipcode,column:"PostalCode",width:"100",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Billing Account",join:"",name:"BillingAccount",column:"BillingName",width:"150",selected:"n", order: 8, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Opening Balance",join:"",name:"OpeningBalance",column:"OpeningBalance",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Invoices",join:"",name:"Invoices",column:"Invoices",width:"100",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Tuition",join:"",name:"Tuition",column:"Tuition",width:"100",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Payments",join:"",name:"Payments",column:"Payments",width:"100",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Player Payments",join:"",name:"Player Payments",column:"PlayerPayments",width:"130",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Adjustments",join:"",name:"Adjustments",column:"Adjustments",width:"100",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Current Balance",join:"",name:"CurrentBalance",column:"CurrentBalance",width:"100",selected:"n", order: 15, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
	               ];
                   this.Columns.Programs = [
		               {title:this.config.Params.programName + " ID",join:"",name:this.config.Params.programName + " ID",column:"ProgramID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:this.config.Params.programName + " Name",join:"",name:this.config.Params.programName + " Name",column:"ProgramName",width:"180",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:this.config.Params.programName + " Short Name",join:"",name:this.config.Params.programName + " Short Name",column:"ProgramShortName",width:"180",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
                       {title:"Address",join:"",name:"Full Address",column:"Address",width:"300",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Address Line",column:"AddressLine1",width:"170",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"City",join:"",name:"City",column:"City",width:"130",selected:"n", order: 5, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:this.config.Params.state,join:"",name:this.config.Params.state,column:"StateOrProvinceCodeID",width:"130",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.zipcode,join:"",name:this.config.Params.zipcode,column:"PostalCode",width:"100",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
	               ];
	               this.Columns.TeamContact = [
		               {title:"Contact ID",join:"",name:"Contact ID",column:"ContactID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Full Name",join:"",name:"Full Name",column:"Name",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Contact Type",join:"",name:"Contact Type",column:"ContactTypeName",width:"140",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Login Role",join:"",name:"Login Role",column:"RoleName",width:"140",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Team",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"170",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name: "League", column: "LeagueName",width:"130",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Email",join:"",name:"Email Address",column:"EMails",width:"250",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Phone Numbers",join:"",name:"Phone Numbers",column:"PhoneNumbers",width:"120",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Home",join:"",name:"Home Phone",column:"HomePhoneNumber",width:"110",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Work",join:"",name:"Work Phone",column:"WorkPhoneNumber",width:"110",selected:"n", order: 11, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Mobile",join:"",name:"Mobile Phone",column:"CellPhoneNumber",width:"110",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Full Address",column:"Address",width:"300",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Address Line",column:"AddressLine1",width:"170",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"City",join:"",name:"City",column:"City",width:"130",selected:"n", order: 15, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.state,join:"",name:this.config.Params.state,column:"StateOrProvinceCodeID",width:"60",selected:"n", order: 16, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.zipcode,join:"",name:this.config.Params.zipcode,column:"PostalCode",width:"60",selected:"n", order: 17, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"USA ID",join:"",name:"USA ID",column:"IDNumber",width:"130",selected:"n", order: 18, fixed: false, sortable: false, groupable: false, align: "left"},
	               ];
	               this.Columns.SiteContact = [
		               {title:"Contact ID",join:"",name:"Contact ID",column:"ContactID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Full Name",join:"",name:"Full Name",column:"Name",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Sites",join:"",name:"Sites",column:"MainLocations",width:"180",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Login Role",join:"",name:"Login Role",column:"RoleName",width:"140",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Email",join:"",name:"Email Address",column:"EMails",width:"250",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Phone Numbers",join:"",name:"Phone Numbers",column:"PhoneNumbers",width:"120",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Home",join:"",name:"Home Phone",column:"HomePhoneNumber",width:"110",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Work",join:"",name:"Work Phone",column:"WorkPhoneNumber",width:"110",selected:"n", order: 11, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Mobile",join:"",name:"Mobile Phone",column:"CellPhoneNumber",width:"110",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Full Address",column:"Address",width:"300",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Address Line",column:"AddressLine1",width:"170",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"City",join:"",name:"City",column:"City",width:"130",selected:"n", order: 15, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.state,join:"",name:this.config.Params.state,column:"StateOrProvinceCodeID",width:"60",selected:"n", order: 16, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.zipcode,join:"",name:this.config.Params.zipcode,column:"PostalCode",width:"60",selected:"n", order: 17, fixed: false, sortable: false, groupable: false, align: "left"},
	               ];
	               this.Columns.ProgramContact = [
		               {title:"Contact ID",join:"",name:"Contact ID",column:"ContactID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Full Name",join:"",name:"Full Name",column:"Name",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Contact Type",join:"",name:"Contact Type",column:"ContactTypeName",width:"140",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Login Role",join:"",name:"Login Role",column:"RoleName",width:"140",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"170",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Email",join:"",name:"Email Address",column:"EMails",width:"250",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Phone Numbers",join:"",name:"Phone Numbers",column:"PhoneNumbers",width:"120",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Home",join:"",name:"Home Phone",column:"HomePhoneNumber",width:"110",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Work",join:"",name:"Work Phone",column:"WorkPhoneNumber",width:"110",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Mobile",join:"",name:"Mobile Phone",column:"CellPhoneNumber",width:"110",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Full Address",column:"Address",width:"300",selected:"n", order: 11, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Address Line",column:"AddressLine1",width:"170",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"City",join:"",name:"City",column:"City",width:"130",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.state,join:"",name:this.config.Params.state,column:"StateOrProvinceCodeID",width:"60",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.zipcode,join:"",name:this.config.Params.zipcode,column:"PostalCode",width:"60",selected:"n", order: 15, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"USA ID",join:"",name:"USA ID",column:"IDNumber",width:"130",selected:"n", order: 16, fixed: false, sortable: false, groupable: false, align: "left"},
	               ];
	               this.Columns.Player = [
		               {title:"Player ID",join:"",name:"Player ID",column:"PlayerID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Full Name",join:"",name:"Full Name",column:"Name",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Team",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"170",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name: "League", column: "LeagueName",width:"130",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"No.",join:"",name:"Jersey Number",column:"JerseyNumber",width:"50",selected:"n", order: 7, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"USA ID",join:"",name:"USA ID",column:"IDNumber",width:"130",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Registration Code",join:"",name:"Registration Code",column:"RegistrationApprovalCode",width:"130",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"DOB",join:"",name:"D.O.B.",column:"DOB",width:"100",selected:"n", order: 9, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Player Type",join:"",name:"Player Type",column:"PlayerTypeName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Email",join:"",name:"Email Address",column:"EmailAddress",width:"250",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Phone Numbers",join:"",name:"Phone Numbers",column:"PhoneNumbers",width:"120",selected:"n", order: 11, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Home",join:"",name:"Home Phone",column:"HomePhoneNumber",width:"150",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Work",join:"",name:"Work Phone",column:"WorkPhoneNumber",width:"150",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Mobile",join:"",name:"Mobile Phone",column:"CellPhoneNumber",width:"150",selected:"n", order: 14, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Full Address",column:"Address",width:"300",selected:"n", order: 15, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Address",join:"",name:"Address Line",column:"AddressLine1",width:"170",selected:"n", order: 16, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"City",join:"",name:"City",column:"City",width:"130",selected:"n", order: 17, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.state,join:"",name:this.config.Params.state,column:"StateOrProvinceCodeID",width:"130",selected:"n", order: 18, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:this.config.Params.zipcode,join:"",name:this.config.Params.zipcode,column:"PostalCode",width:"100",selected:"n", order: 19, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Notes",join:"",name:"Notes",column:"Notes",width:"100",selected:"n", order: 20, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Tags",join:"",name:"Tags",column:"Tags",width:"100",selected:"n", order: 21, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Tuition",join:"",name:"Tuition",column:"Tuition",width:"100",selected:"n", order: 22, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
		               {title:"Credits",join:"",name:"Credits",column:"TotalCreditAmount",width:"100",selected:"n", order: 23, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
		               {title:"Payments",join:"",name:"Payments",column:"TotalPaymentAmount",width:"100",selected:"n", order: 24, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
		               {title:"Balance",join:"",name:"Balance",column:"Balance",width:"100",selected:"n", order: 25, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
	               ];
	               this.Columns.PlayerSuspensions = [
		               {title:"Player ID",join:"",name:"Player ID",column:"PlayerID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Full Name",join:"",name:"Full Name",column:"Name",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Team",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"170",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name: "League", column: "LeagueName",width:"130",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"No.",join:"",name:"Jersey Number",column:"JerseyNumber",width:"50",selected:"n", order: 7, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Offense",join:"",name:"Offense",column:"OffenseName",width:"100",selected:"n", order: 8, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"No. of Games",join:"",name:"Games",column:"NoOfGames",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Start Date",join:"",name:"StartDate",column:"StartDate",width:"100",selected:"n", order: 10, fixed: false, sortable: false, groupable: false, align: "center"},
		               {title:"Return Date",join:"",name:"ReturnDate",column:"ReturnDate",width:"100",selected:"n", order: 11, fixed: false, sortable: false, groupable: false, align: "center"},
		               {title:"Notes",join:"",name:"Notes",column:"Note",width:"100",selected:"n", order: 12, fixed: false, sortable: false, groupable: false, align: "left"},
	               ];
	               this.Columns.PlayerPayments = [
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Full Name",join:"",name:"Full Name",column:"Name",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Registration Code",join:"",name:"Registration Code",column:"RegistrationApprovalCode",width:"130",selected:"n", order: 4, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Team",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"170",selected:"n", order: 7, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name: "League", column: "LeagueName",width:"130",selected:"n", order: 8, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Payment Date",join:"",name:"PaymentDate",column:"PaymentDate",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Payment Title",join:"",name:"PaymentTitle",column:"PaymentTitle",width:"100",selected:"n", order: 10, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Payment Amount",join:"",name:"PaymentAmount",column:"PaymentAmount",width:"100",selected:"n", order: 11, fixed: false, sortable: true, groupable: false, align: "right", currency: true},
	               ];
	               this.Columns.Invoices = [
		               {title:"No.",join:"",name:"Invoice Number",column:"InvoiceID",width:"70",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Date",join:"",name:"Invoice Date",column:"InvoiceDate",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Date Formatted",join:"",name:"Invoice Date Formatted",column:"InvoiceDateF",width:"170",selected:"n", order: 3, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Organization",join:"",name:"Organization",column:"OrganizationName",width:"180",selected:"n", order: 4, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name:"League Name",column:"LeagueName",width:"180",selected:"n", order: 5, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Account",join:"",name:"Billing Account",column:"BillingName",width:"180",selected:"n", order: 6, fixed: false, sortable: true, price : true, groupable: true, align: "left"},
		               {title:"Description",join:"",name: "Description", column: "Description",width:"180",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Amount",join:"",name:"Amount",column:"DetailAmount",width:"100",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Status",join:"",name:"Invoice Status",column:"InvoiceStatus",width:"130",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Emailed",join:"",name:"Emailed",column:"InvoiceEmailed",width:"75",selected:"n", order: 10, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Type",join:"",name:"Invoice Type",column:"InvoiceType",width:"90",selected:"n", order: 11, fixed: false, sortable: false, groupable: false, align: "center"},
	               ];
	               this.Columns.Payments = [
		               {title:"Date",join:"",name:"Payment Date",column:"PaymentDate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Date Formatted",join:"",name:"Payment Date Formatted",column:"PaymentDateF",width:"170",selected:"n", order: 2, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Organization",join:"",name:"Organization",column:"OrganizationName",width:"180",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Account",join:"",name:"Billing Account",column:"BillingName",width:"180",selected:"n", order: 4, fixed: false, sortable: true, price : true, groupable: true, align: "left"},
		               {title:"Description",join:"",name: "Description", column: "Description",width:"180",selected:"n", order: 5, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Amount",join:"",name:"Amount",column:"PaymentAmount",width:"100",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Status",join:"",name:"Payment Status",column:"PaymentStatus",width:"130",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
	               ];
                   this.Columns.Credits = [
		               {title:"Date",join:"",name:"Adjustment Date",column:"CreditDate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Date Formatted",join:"",name:"Adjustment Date Formatted",column:"CreditDateF",width:"170",selected:"n", order: 2, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Organization",join:"",name:"Organization",column:"OrganizationName",width:"180",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Account",join:"",name:"Billing Account",column:"BillingName",width:"180",selected:"n", order: 4, fixed: false, sortable: true, price : true, groupable: true, align: "left"},
		               {title:"League",join:"",name:"League Name",column:"LeagueName",width:"180",selected:"n", order: 4, fixed: false, sortable: true, price : true, groupable: true, align: "left"},
		               {title:"Description",join:"",name: "Description", column: "Description",width:"180",selected:"n", order: 5, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Amount",join:"",name:"Amount",column:"CreditAmount",width:"100",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
		               {title:"Balancing",join:"",name:"Balancing",column:"CreditBalance",width:"100",selected:"n", order: 6, fixed: false, sortable: true, groupable: true, align: "center", currency: false},
		               {title:"Status",join:"",name:"Invoice Status",column:"CreditStatus",width:"130",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
	               ];
                   this.Columns.PlayerCredits = [
		               {title:"Date",join:"",name:"Adjustment Date",column:"CreditDate",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Date Formatted",join:"",name:"Adjustment Date Formatted",column:"CreditDateF",width:"170",selected:"n", order: 2, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Season",join:"",name:"Season",column:"SeasonName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name:"League Name",column:"LeagueName",width:"180",selected:"n", order: 4, fixed: false, sortable: true, price : true, groupable: true, align: "left"},
                       {title:"Team",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 1, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Full Name",join:"",name:"Full Name",column:"Name",width:"170",selected:"n", order: 3, fixed: false, sortable: true, groupable: true, align: "left"},
                       {title:"Description",join:"",name: "Description", column: "Description",width:"180",selected:"n", order: 5, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Amount",join:"",name:"Amount",column:"CreditAmount",width:"100",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
	               ];
	               this.Columns.Forms = [
                       {title:"Form Data ID",join:"",name:"Form Data ID",column:"FormDataID",width:"100",selected:"n", order: 0, fixed: false, sortable: true, groupable: false, align: "center"},
                       {title:"Form Name",join:"",name:"Form Name",column:"FormName",width:"150",selected:"n", order: 1, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Date",join:"",name:"Date",column:"SubmissionDate",width:"100",selected:"n", order: 2, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"First Name",join:"",name:"First Name",column:"FirstName",width:"100",selected:"n", order: 3, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Last Name",join:"",name:"Last Name",column:"LastName",width:"100",selected:"n", order: 4, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Email",join:"",name:"Email",column:"Email",width:"150",selected:"n", order: 5, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"DOB",join:"",name:"DOB",column:"PlayerDOB",width:"100",selected:"n", order: 6, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Daytime Phone",join:"",name:"Daytime Phone",column:"PhoneDaytime",width:"100",selected:"n", order: 7, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Evening Phone",join:"",name:"Evening Phone",column:"PhoneEvening",width:"100",selected:"n", order: 8, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"League",join:"",name:"League",column:"LeagueName",width:"120",selected:"n", order: 10, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"120",selected:"n", order: 11, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Team",join:"",name:"Team",column:"Team",width:"120",selected:"n", order: 11, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Bank Account",join:"",name:"Bank Account",column:"BankAccount",width:"120",selected:"n", order: 12, fixed: false, sortable: true, groupable: false, align: "left"},
		               {title:"Payment",join:"",name:"Payment",column:"PaymentAmount",width:"75",selected:"n", order: 13, fixed: false, sortable: false, groupable: false, align: "right", currency: true},
 		               {title:"Payment Address",join:"",name:"Payment Address",column:"PaymentStreet",width:"170",selected:"n", order: 16, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Payment City",join:"",name:"Payment  City",column:"PaymentCity",width:"130",selected:"n", order: 17, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Payment " + this.config.Params.state,join:"",name:"Payment " + this.config.Params.state,column:"PaymentState",width:"100",selected:"n", order: 18, fixed: false, sortable: false, groupable: false, align: "left"},
		               {title:"Payment " + this.config.Params.zipcode,join:"",name:"Payment " + this.config.Params.zipcode,column:"PaymentZipCode",width:"100",selected:"n", order: 19, fixed: false, sortable: false, groupable: false, align: "left"},

	               ];
	               this.Columns.ScheduleTeamScoring = [
		               {title:"Team Name",join:"",name:"Team Name",column:"TeamName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Team",join:"",name:"Team Short Name",column:"TeamShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Team 3",join:"",name:"Team Name 3",column:"TeamName3",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:this.config.Params.programName,join:"",name:this.config.Params.programName,column:"ProgramName",width:"120",selected:"n", order: 11, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Schedule",join:"",name:"Schedule",column:"ScheduleName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"League",join:"",name:"League",column:"LeagueName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Division Name",join:"",name:"Division Name",column:"DivisionName",width:"180",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Division",join:"",name:"Division Short Name",column:"DivisionShortName",width:"100",selected:"n", order: 9, fixed: false, sortable: true, groupable: true, align: "left"},
		               {title:"Games To Schedule",join:"",name:"Games To Schedule",column:"TotalGames",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Home Games",join:"",name:"Home Games",column:"HomeGames",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Away Games",join:"",name:"Away Games",column:"VisitingGames",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Actual Games",join:"",name:"Actual Scheduled",column:"TotalActualGames",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Actual Home Games",join:"",name:"Actual Home Games",column:"ActualHomeGames",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Actual Away Games",join:"",name:"Actual Away Games",column:"ActualVisitingGames",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Weekday Games",join:"",name:"Weekday Games",column:"noOfWeekdayGames",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Avg Travel Time",join:"",name:"Travel Time (min)",column:"ActTravel",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Travel Score",join:"",name:"Travel Score",column:"TeamScoreTravel",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Start Time Score",join:"",name:"Start Time Score",column:"TeamScoreStartTime",width:"75",selected:"n", order: 12, fixed: true, sortable: true, groupable: false, align: "center"},
		               {title:"Gap Score",join:"",name:"Gap Score",column:"TeamScoreGap",width:"75",selected:"n", order: 9, fixed: false, sortable: true, groupable: false, align: "center"},
		               {title:"Overall Score",join:"",name:"Overall Score",column:"TeamScore",width:"75",selected:"n", order: 13, fixed: false, sortable: true, groupable: false, align: "center"},
	               ];
			   },
               buildSharedSecurity (report) {
                   //read all login roles, then map the data from sharing
                   var SharingParams = [];
                   var row;
                   for (let i = 0; i < this.LoginRole.length; i++) {
                       row = {};
                       row.ReportSharingID = 0;
                       row.ReportID = this.ReportID;
                       row.LoginRoleID = this.LoginRole[i].LoginRoleID;
                       row.RoleName = this.LoginRole[i].RoleName;
                       row.UserArea = this.LoginRole[i].UserArea;
                       row.RoleSharing = 'n';
                       row.Params = {};
                       row.Params.AllowEdit = 'n';
                       row.Params.ShowParameters = 'n';
                       row.Params.ProgramIDAccess = 'n';
                       row.Params.TeamIDAccess = 'n';
                       row.Params.MainLocationIDAccess = 'n'
                       if (row.UserArea == 'l') {
                           row.Params.MainLocationIDAccess = 'y';
                       }
                       if (row.UserArea == 'p') {
                           row.Params.ProgramIDAccess = 'y';
                       }
                       row.Params.Navigation = 'n';

                       if (report.SharingParams) {
                           for (let j = 0; j < report.SharingParams.length; j++) {
                               if (row.LoginRoleID == report.SharingParams[j].LoginRoleID) {
                                   row.ReportSharingID = report.SharingParams[j].ReportSharingID;
                                   row.ReportID = this.ReportID;
                                   row.RoleSharing = report.SharingParams[j].RoleSharing;
                                   row.Params.AllowEdit = report.SharingParams[j].Params.AllowEdit;
                                   row.Params.ShowParameters = report.SharingParams[j].Params.ShowParameters
                                   row.Params.ProgramIDAccess = report.SharingParams[j].Params.ProgramIDAccess;
                                   row.Params.TeamIDAccess = report.SharingParams[j].Params.TeamIDAccess;
                                   row.Params.MainLocationIDAccess = report.SharingParams[j].Params.MainLocationIDAccess;
                                   row.Params.Navigation = report.SharingParams[j].Params.Navigation;
                                   break;
                               }
                           }
                       }
                       SharingParams.push(row);
                   }
                   return SharingParams;
               },
               getInitialData () {
                   if (this.report.MySharingParams.Params.ProgramIDAccess == 'y') {
                       this.report.Params.ProgramID = this.currentUser.ProgramID;
                   }
                   var tables = ['Locations', 'Organizations', 'SiteLocations', 'ScheduleTypes', 'AllSchedules', 'Tournaments', 'ReportFolderName', 'Programs', 'ActiveSeasons', 'ContactTypes', 'InvoiceSettings', 'FormDefinitions', 'StatusCodes', 'Tags', 'LoginRole', 'Leagues', 'Programs', 'PlayerTypes'];
                   axios.post('/index/tableData', {tables : tables, calling : 'ReportWriter', SeasonID: this.report.Params.SeasonID, ProgramIDAccess : this.report.MySharingParams.Params.ProgramIDAccess, TeamIDAccess : this.report.MySharingParams.Params.TeamIDAccess, MainLocationIDAccess : this.report.MySharingParams.Params.MainLocationIDAccess  },
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               for (let value of tables) {
                                   this[value] = response.data[value];
                               }
                           } else {
                               this.$failProgressBar(this.parentrefs);
                           }
                           this.loading = false;
                           if (!this.navReport) {
                               this.getAllReports();
                               this.getFormNames();
                           }
                       }).catch(response => {
                       this.$failProgressBar(this.parentrefs);
                       this.loading = false;
                   });
               },
               getAllReports () {
                   axios.post('/reportwriter/getall', {createAccess : (this.$createAccess() && this.$modifyAccess() ? 'y' : 'n')},
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               this.Reports = response.data.Reports;
                               this.ReportNames = response.data.ReportNames;
                               if (this.$createAccess() && this.$modifyAccess()) {
                                   if (this.componentIDs.FolderReportID) {
                                       if (this.componentIDs.FolderReportID[0] && this.componentIDs.FolderReportID[1]) {
                                           this.FolderReportID = this.componentIDs.FolderReportID;
                                       }
                                   }
                                   if (this.componentIDs.PrivateShared && this.$createAccess() && this.$modifyAccess()) {
                                       this.PrivateShared = this.componentIDs.PrivateShared;
                                   }
                               }
                               this.$stopProgressBar(this.parentrefs);
                               this.getReport() ;
                           } else {
                               this.$failProgressBar(this.parentrefs);
                           }
                       }).catch(response => {
                       this.$failProgressBar(this.parentrefs);
                   });
               },
               getReport () {
                   this.ReportID = (this.FolderReportID[this.FolderReportID.length - 1] ? this.FolderReportID[this.FolderReportID.length - 1] : '');
                   this.reportRecords = [];
                   this.recordsGrouped = {};
                   this.noData = false;
                   if ((this.ReportID != '0' && this.ReportID != '') || this.DisplayReportCode) {
                       let self = this;
                       axios.post('/reportwriter/get', {'ReportID' : (this.DisplayReportCode ? '' : this.ReportID), 'ReportCode' : (this.DisplayReportCode ? this.DisplayReportCode : ''),  },
                       )
                           .then(response => {
                               if (response.data.result == 'success') {
                                   let report = response.data.report;
                                   if (report) {
                                       //set ReportID in case we are calling by the Code
                                       this.ReportID = report.ReportID;
                                       this.FolderReportID[this.FolderReportID.length - 1]  = this.ReportID;

                                       //need to go through code columns and remove those not used or add new ones
                                       report.SharingParams = this.buildSharedSecurity(report);
                                       if (!report.Params.gametype) {
                                           report.Params.gametype = 'Live';
                                       }
                                       if (!report.EmailSharingParams) {
                                           report.EmailSharingParams = [{EmailAddress : '', RoleSharing : 'y', Params : { AllowEdit : 'n',ShowParameters : 'n',ProgramIDAccess : 'n', TeamIDAccess : 'n', MainLocationIDAccess : 'n', Navigation : 'n' }}];
                                       }
                                       let reportcolumns = [];
                                       let columns = this.Columns[report.Params.ReportType];

                                       //first find any columns removed
                                       for (let i = 0; i < report.Params.Columns.length; i++) {
                                           if (report.Params.Columns[i]['custom'] == 'y' || report.Params.Columns[i]['custom'] == 'ya') {
                                               //include any custom fields
                                               reportcolumns.push(report.Params.Columns[i]);
                                           } else {
                                               for (let j = 0; j < columns.length; j++) {
                                                   if (columns[j]['column'] == report.Params.Columns[i]['column']) {
                                                       //update sorting and grouping
                                                       report.Params.Columns[i]['sortable'] = columns[j]['sortable'];
                                                       report.Params.Columns[i]['groupable'] = columns[j]['groupable'];
                                                       reportcolumns.push(report.Params.Columns[i]);
                                                       break;
                                                   }
                                               }
                                           }
                                       }
                                       //next add any new columns
                                       for (let j = 0; j < columns.length; j++) {
                                           var columnFound = false;
                                           for (let i = 0; i < report.Params.Columns.length; i++) {
                                               if (columns[j]['column'] == report.Params.Columns[i]['column']) {
                                                   columnFound = true;
                                                   break;
                                               }
                                           }
                                           if (!columnFound) {
                                               reportcolumns.push(columns[j]);
                                           }
                                       }
                                       report.Params.Columns = reportcolumns;
                                       if (report.Params.ScheduleIDs.length > 0) {
                                           for (var i = 0; i < this.AllSchedules.length; i++) {
                                               for (var j = 0; j < this.AllSchedules[i].options.length; j++) {
                                                   for (var k = 0; k < report.Params.ScheduleIDs.length; k++) {
                                                       if (this.AllSchedules[i].options[j]['ScheduleID'] == report.Params.ScheduleIDs[k]) {
                                                           this.ScheduleRunID = this.AllSchedules[i].options[j]['ScheduleRunID'];
                                                       }
                                                   }
                                               }
                                           }
                                       }
                                       //if embed and css, then add style tag
                                       if (this.embed == 'y' && report.Params.CSS) {
                                            let style = document.createElement('style');
                                            style.setAttribute("id", "reportwritercss");
                                            document.head.append(style);
                                            style.innerText = report.Params.CSS.replace(/(\r\n\t|\n|\r\t)/gm,'').replace(/ +(?= )/g,'');
                                       }
                                       this.report = report;
                                   } else {
                                       this.ReportID = '';
                                       this.report = {ReportID : 0, ReportName : '', FolderName : '', ReportSharing: 'p', createdby: this.currentUser.ContactID, Params: { ReportType: '', Columns : [], GroupBy : 'r', ContactTypeIDs : [], PlayerTypeIDs : [], group : '', groupdirection: 'asc', sort : [], sortdirection : [], TeamIDs : [], gametype : 'Live', ScheduleIDs : [], ProgramID : '', LeagueID : ''}, SharingParams : []};
                                       this.report.SharingParams = this.buildSharedSecurity(this.report);
                                       this.report.MySharingParams = {RoleSahring : 'n', Params:{AllowEdit : 'y', ShowParameters : 'y', ProgramIDAccess : 'n', TeamIDAccess :'n', MainLocationIDAccess :'n', Navigation : 'n'}};
                                   }

                                   this.ReportTypeChanged();
                                   if (this.navReport && this.report.MySharingParams.Params.ShowParameters == 'y' && this.ActiveSeasons.length == 0) {
                                       //get the data for dropdown now as we need security settings
                                       this.getInitialData();
                                   }
                                   if (!this.navReport) {
                                       self.$store.dispatch('setID', {'FolderReportID': this.FolderReportID});
                                   }

                                   //if we cannot edit this report, let' just run it
                                   if ((this.report.MySharingParams.Params.AllowEdit == 'n' && this.report.MySharingParams.Params.ShowParameters =='n') || (this.navReport && !this.paramsToShow)) {
                                       this.runReport();
                                   }
                                   this.$stopProgressBar(this.parentrefs);
                               } else {
                                   this.report = response.data.report;
                                   this.$failProgressBar(this.parentrefs);
                               }
                           }).catch(response => {
                           this.$failProgressBar(this.parentrefs);
                       });
                   } else {
                       this.ReportID = '';
                       this.report = {ReportID : 0, ReportName : '', FolderName : '', ReportSharing: 'p', createdby: this.currentUser.ContactID, Params: { ReportType: '', Columns : [], GroupBy : 'r', ContactTypeIDs : [], PlayerTypeIDs : [], group : '', groupdirection: 'asc', sort : [], sortdirection : [], TeamIDs : [], gametype : 'Live', ScheduleIDs : [], ProgramID : '', LeagueID : ''}, SharingParams : []};
                       this.report.SharingParams = this.buildSharedSecurity(this.report);
                       this.report.MySharingParams = {RoleSahring : 'n', Params:{AllowEdit : 'y', ShowParameters : 'y', ProgramIDAccess : 'n', TeamIDAccess :'n', MainLocationIDAccess :'n', Navigation : 'n'}};
                   }
               },
               isDuplicate () {
                   for (let i = 0; i < this.Reports.length; i++) {
                       if (this.Reports[i].ReportID != this.report.ReportID && this.Reports[i].ReportName.toLowerCase() == this.report.ReportName.toLowerCase().trim()) {
                           return true;
                       }
                   }
                   return false;
               },
               saveReport () {
                    let self = this;
                    this.$refs['reportwriterForm'].validate((valid) => {
                       if (valid) {
                           if (this.currentStatus == STATUS_SAVING) {
                               return true;
                           }
                           if (!this.isDuplicate()) {
                               this.currentStatus = STATUS_SAVING;
                               this.$startProgressBar(this.parentrefs);
                               let self = this;
                               axios.post('/reportwriter/save', {'report': this.report}
                               )
                                   .then(response => {
                                       if (response.data.result == 'success') {
                                           this.currentStatus = STATUS_INITIAL;
                                           if (response.data.ReportID) {
                                               self.$store.dispatch('setID', {'ReportID': response.data.ReportID});
                                               this.ReportID = response.data.ReportID;
                                               this.report.ReportID = response.data.ReportID;
                                           }
                                           this.FolderReportID = [(self.report.FolderName ? self.report.FolderName : 'No Folder'), self.report.ReportID];
                                           self.$store.dispatch('setID', {'FolderReportID': this.FolderReportID});

                                           this.$stopProgressBar(this.parentrefs);
                                           this.$notify({
                                               title: 'Success',
                                               message: "Report has been saved",
                                               type: 'success',
                                               duration: 1500,
                                               showClose: false
                                           });
                                           this.getInitialData ();
                                       } else {
                                           this.currentStatus = STATUS_FAILED;
                                           this.displayPopup (response.data.message);
                                           this.$failProgressBar(this.parentrefs);
                                       }
                                   }).catch(response => {
                                       this.currentStatus = STATUS_FAILED;
                                       this.$failProgressBar(this.parentrefs);
                               });

                           } else {
                               this.displayPopup ('This Report Name Already Exists.');
                           }
                       } else {
                           //scroll to errors

                           setTimeout(() => { var element = document.querySelector(".is-error"); self.$scrollTo(element); }, 250);
                       }
                   });
               },
               saveNewReport () {
                   this.report.ReportID = 0;
                   this.ReportID = '';
                   this.saveReport ();
               },
               reset() {
                   this.currentStatus = STATUS_INITIAL;
                   this.getReport();
                   window.scrollTo(0,0);
                   this.$notify({
                       title: 'Success',
                       message: "Report has been reset",
                       type: 'success',
                       duration: 1500,
                       showClose: false
                   });
               },
               cnfmDeleteReport() {
                   this.$confirm('This will permanently delete this Report. Continue?', 'Warning', {
                       confirmButtonText: 'OK',
                       cancelButtonText: 'Cancel',
                       type: 'warning'
                   }).then(() => {
                       this.deleteReport();
                   }).catch(() => {
                   });
               },
               deleteReport () {
                   if (this.currentStatus == STATUS_SAVING) {
                       return true;
                   }
                   this.currentStatus = STATUS_SAVING;
                   this.$startProgressBar(this.parentrefs);
                   let self = this;

                   axios.post('/reportwriter/delete', {'ReportID': this.ReportID}
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               self.$store.dispatch('setID', {'FolderReportID': ''});

                               this.currentStatus = STATUS_INITIAL;
                               this.$stopProgressBar(this.parentrefs);
                               this.$notify({
                                   title: 'Success',
                                   message: "Report has been deleted",
                                   type: 'success',
                                   duration: 1500,
                                   showClose: false
                               });

                               this.PrivateSharedChanged();
                               this.getInitialData();

                           } else {
                               this.currentStatus = STATUS_FAILED;
                               this.displayPopup (response.data.message);
                               this.$failProgressBar(this.parentrefs);
                           }
                       }).catch(response => {
                           this.currentStatus = STATUS_FAILED;
                           this.$failProgressBar(this.parentrefs);
                   });
               },
               clearReport() {
                   this.reportRecords = [];
                   this.recordsGrouped = {};
                   this.export = [];
               },
               runReport () {
                   this.summaries = [];
                   this.reportkey++;
                   this.loadingTable = true;
                   this.clearReport();
                   this.noData = false;
                   this.$startProgressBar(this.parentrefs);
                   let self = this;
                   this.searchPerformed = false;
                   axios.post('/reportwriter/runreport', {Report: this.report},
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               if (response.data.reportRecords) {
                                   this.reportRecords = response.data.reportRecords;
                                   this.export = (response.data.export ? response.data.export : []);
                               } else if (response.data.recordsGrouped) {
	                               var sortedKeys = [];
	                               for (const property in response.data.recordsGrouped) {
		                               sortedKeys.push(property);
	                               }

	                               for (let i=0; i < sortedKeys.length; i++) {
                                        var currencyColumns = []; 
                                        for (let j=0; j < this.report.Params.Columns.length; j++) {
                                            if (this.report.Params.Columns[j].selected == 'y' && this.report.Params.Columns[j].currency) {
                                                currencyColumns.push(this.report.Params.Columns[j].column);
                                            }
                                        }

		                               this.summaries[i] = ['Totals',0];
                                       for (let k=0; k < currencyColumns.length; k++) {
                                                this.summaries[i][2+k] = 0;
                                           }
		                               for (let j=0; j < response.data.recordsGrouped[sortedKeys[i]].length; j++) {
			                               this.summaries[i][1] += 1;
                                           for (let k=0; k < currencyColumns.length; k++) {
                                                var amount = Number(response.data.recordsGrouped[sortedKeys[i]][j][currencyColumns[k]].toString().replace(/[$,)]/g, '').replace(/[(]/g, '-'));
                                                this.summaries[i][2+k] += amount;
                                           }
		                               }
                                       for (let k=0; k < currencyColumns.length; k++) {
                                            this.summaries[i][2+k] = this.formatPrice(this.summaries[i][2+k]);
                                        }
	                               }
	                               this.recordsGrouped = response.data.recordsGrouped;
	                               this.export = (response.data.export ? response.data.export : []);
                               }
                               this.export = response.data.export;
                               if(this.export.length == 0){
                                   this.noData = true;
                               }
                               this.$stopProgressBar(this.parentrefs);
                               //first remove existing style and then going to add new
                               var element = document.getElementById("reportwritercss");
                               if (element) {
                                    element.parentNode.removeChild(element);
                               }
                               if (this.report.Params.CSS) {
                                    let style = document.createElement('style');
                                    style.setAttribute("id", "reportwritercss")
                                    document.head.append(style);
                                    style.innerText = '#reportwriter * { ' + this.report.Params.CSS.replace(/(\r\n\t|\n|\r\t)/gm,'').replace(/ +(?= )/g,'') + ' }';
                                }
                           } else {
                               this.$failProgressBar(this.parentrefs);
                           }
                           if (this.report.Params.groupreporttotals == 'y') {
                                this.calcgrouptotals();
                            }
                           this.searchPerformed = true;
                           this.loading = false;
                           this.loadingTable = false;
                       }).catch(response => {
                           this.$failProgressBar(this.parentrefs);
                           this.loading = false;
                           this.loadingTable = false;
                   });
               },
               seasonChange() {
                   this.report.Params.LeagueID = '';
                   this.report.Params.ProgramID = '';
                   this.report.Params.TeamIDs = [];
                   this.report.Params.ScheduleIDs = [];
                   this.getLeagues();
               },
               gametypeChange() {
                   this.report.Params.gamestatus = [];
                   this.report.Params.ScheduleIDs = [];
                   this.report.Params.LeagueID = '';
               },
               scheduleChange () {
                   this.report.Params.LeagueID = '';
                   this.report.Params.TeamIDs = [];
                   this.ScheduleRunID = '';
                   if (this.report.Params.ScheduleIDs.length > 0) {
                       for (var i = 0; i < this.AllSchedules.length; i++) {
                           for (var j = 0; j < this.AllSchedules[i].options.length; j++) {
                               for (var k = 0; k < this.report.Params.ScheduleIDs.length; k++) {
                                   if (this.AllSchedules[i].options[j]['ScheduleID'] == this.report.Params.ScheduleIDs[k]) {
                                       this.ScheduleRunID = this.AllSchedules[i].options[j]['ScheduleRunID'];
                                   }
                               }
                           }
                       }
                   }
               },
               leagueChange (){
                   if (this.report.Params.LeagueID != '') {
                       this.report.Params.ProgramID = '';
                       this.report.Params.TeamIDs = [];
                   }
                   this.getPrograms();
               },
               programChange(){
                   if (this.report.Params.ReportType != "ProgramContact" && this.report.Params.ReportType != "Invoices" && this.report.Params.ReportType != "Payments"){
                       this.report.Params.TeamIDs = [];
                       this.getTeams();
                   }
               },
               activeformsChange () {
                   this.report.Params.FolderFormDefinitionID = [];
               },
               getLeagues () {
                   this.Leagues = [];

                   var tables = ['Leagues'];
                   axios.post('/index/tableData', {tables : tables, calling : 'ReportWriter', SeasonID: this.report.Params.SeasonID, ProgramIDAccess : this.report.MySharingParams.Params.ProgramIDAccess, TeamIDAccess : this.report.MySharingParams.Params.TeamIDAccess, MainLocationIDAccess : this.report.MySharingParams.Params.MainLocationIDAccess },
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               for (let value of tables) {
                                   this[value] = response.data[value];
                               }
                           } else {
                               this.$failProgressBar(this.parentrefs);
                           }
                           this.loading = false;
                       }).catch(response => {
                           this.$failProgressBar(this.parentrefs);
                           this.loading = false;
                   });
               },
               getPrograms () {
                   this.Programs = [];

                   var tables = ['Programs'];
                   axios.post('/index/tableData', {tables : tables, calling : 'ReportWriter', LeagueID: this.report.Params.LeagueID, SeasonID: this.report.Params.SeasonID, ProgramIDAccess : this.report.MySharingParams.Params.ProgramIDAccess, TeamIDAccess : this.report.MySharingParams.Params.TeamIDAccess, MainLocationIDAccess : this.report.MySharingParams.Params.MainLocationIDAccess },
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               for (let value of tables) {
                                   this[value] = response.data[value];
                               }
                           } else {
                               this.$failProgressBar(this.parentrefs);
                           }
                           this.loading = false;
                       }).catch(response => {
                           this.$failProgressBar(this.parentrefs);
                           this.loading = false;
                   });
               },
               getFormNames () {
                   axios.post('/formdefinitions/getall', {createAccess : 'n'},
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               this.FormNames = response.data.FormNames;
                           }
                       }).catch(response => {
                   });
               },
               getTeams () {
                   var tables = ['ProgramTeams'];
                   axios.post('/index/tableData', {tables : tables, calling : 'ReportWriter', ActiveOnly : this.report.Params.ActiveOnlyTeams, SeasonID: this.report.Params.SeasonID, ProgramID: this.report.Params.ProgramID, LeagueID: this.report.Params.LeagueID, ProgramIDAccess : this.report.MySharingParams.Params.ProgramIDAccess, TeamIDAccess : this.report.MySharingParams.Params.TeamIDAccess, MainLocationIDAccess : this.report.MySharingParams.Params.MainLocationIDAccess },
                   )
                       .then(response => {
                           if (response.data.result == 'success') {
                               for (let value of tables) {
                                   this[value] = response.data[value];
                               }
                           } else {
                               this.$failProgressBar(this.parentrefs);
                           }
                           this.loading = false;
                       }).catch(response => {
                       this.$failProgressBar(this.parentrefs);
                       this.loading = false;
                   });
               },

               showCustomFields() {
                    var FormDefinitionID = this.report.Params.FolderFormDefinitionID[1];
                    //first remove previous custom fields
                    for (let j = this.report.Params.Columns.length - 1; j >= 0; j--) {
                       if (this.report.Params.Columns[j]['custom'] == 'ya') {
                           this.report.Params.Columns.splice(j, 1);
                       }
                    }
                    if (FormDefinitionID == 0) {
                        this.report.Params.Columns.push(
                            {
                                title: 'Tuition Amount',
                                name: 'Tuition Amount',
                                join: '',
                                column: 'TuitionAmount',
                                width: '',
                                selected: 'n',
                                sortable: false,
                                groupable: false,
                                align: 'right',
                                fixed: false,
                                custom: 'ya'
                            });
                        this.report.Params.Columns.push(
                            {
                                title: 'USA ID',
                                name: 'USA ID',
                                column: 'IDNumber',
                                width: '',
                                selected: 'n',
                                sortable: false,
                                groupable: false,
                                align: 'left',
                                fixed: false,
                                custom: 'ya'
                            });
                    } else {
                        for (let i = 0; i < this.FormDefinitions.length; i++) {
                            if (this.FormDefinitions[i]['FormDefinitionID'] == FormDefinitionID) {
                                //add these custom fields to the bottom of the list...if they do not exist

                                for (let j = 0; j < this.FormDefinitions[i]['CustomFields'].length; j++) {
                                    this.report.Params.Columns.push(
                                        {
                                            title: this.FormDefinitions[i]['CustomFields'][j]['Label'],
                                            name: this.FormDefinitions[i]['CustomFields'][j]['Label'],
                                            join: '',
                                            column: this.FormDefinitions[i]['CustomFields'][j]['Value'],
                                            width: '',
                                            selected: 'n',
                                            sortable: true,
                                            groupable: true,
                                            align: 'left',
                                            fixed: false,
                                            custom: 'ya'
                                        });
                                }
                                break;
                            }
                        }
                    }
               },

               viewPDF() {
                   this.pdfColumns = [];
                   this.pdfgroupcolumns = [];
                   if(this.report.Params.group.length > 0){
                       this.pdfRecords = {};
                       this.pdfRecords = this.recordsGrouped;

                       if (Object.keys(this.recordsGrouped).length !== 0 && this.pdf.length > 0 ) {
                           for (let i = 0; i < this.pdf.length; i++) {
                               var AlignPDF = '';
                               if(this.pdf[i].align == 'right'){
                                   AlignPDF = 'R';
                               }else if (this.pdf[i].align == 'center'){
                                   AlignPDF = 'C';
                               } else {
                                   AlignPDF = 'L';
                               }

                               let DataJoin = '';
                               if(this.pdf[i].columnArray.length > 1){ //show item linked
                                   //DataJoin = this.pdf[i].columnArray.toString();
                                   DataJoin = this.pdf[i].column;
                               } else {
                                   DataJoin = this.pdf[i].column;
                               }

                               this.pdfColumns.push({Data : DataJoin, Title : this.pdf[i].title, Width : this.pdf[i].width, Align : AlignPDF, Order: i+1});

                               if(this.pdf[i].columnArray.length > 1) {
                                   for(const [key, value] of Object.entries(this.recordsGrouped)){
                                       for (let j = 0; j < value.length; j++) {
                                           for (let k = 1; k < this.pdf[i].columnArray.length; k++) {
                                               this.pdfRecords[key][j][this.pdf[i].columnArray[0]] = this.pdfRecords[key][j][this.pdf[i].columnArray[0]] + '\n' + this.recordsGrouped[key][j][this.pdf[i].columnArray[k]];
                                           }
                                       }
                                   }
                               }
                           }
                           for (let i = 0; i < this.groupcolumns.length; i++) {
                               var AlignPDF = '';
                               if(this.groupcolumns[i].align == 'right'){
                                   AlignPDF = 'R';
                               }else if (this.groupcolumns[i].align == 'center'){
                                   AlignPDF = 'C';
                               } else {
                                   AlignPDF = 'L';
                               }

                               let DataJoin = this.groupcolumns[i].column;

                               this.pdfgroupcolumns.push({Data : DataJoin, Title : this.groupcolumns[i].title, Width : this.groupcolumns[i].width, Align : AlignPDF, Order: i+1});

                           }
                       }
                   } else if (this.reportRecords != '' && this.pdf.length > 0 ) {
                       this.pdfRecords = this.reportRecords;

                       if (this.reportRecords != '' && this.pdf.length > 0 ) {
                           for (let i = 0; i < this.pdf.length; i++) {
                               var AlignPDF = '';
                               if(this.pdf[i].align == 'right'){
                                   AlignPDF = 'R';
                               }else if (this.pdf[i].align == 'center'){
                                   AlignPDF = 'C';
                               } else {
                                   AlignPDF = 'L';
                               }

                               let DataJoin = '';
                               if(this.pdf[i].columnArray.length > 1){ //show item linked
                                   //DataJoin = this.pdf[i].columnArray.toString();
                                   DataJoin = this.pdf[i].column;
                               } else {
                                   DataJoin = this.pdf[i].column;
                               }

                               this.pdfColumns.push({Data : DataJoin, Title : this.pdf[i].title, Width : this.pdf[i].width, Align : AlignPDF, Order: i+1});

                               if(this.pdf[i].columnArray.length > 1) {
                                   for (let j = 0; j < this.reportRecords.length; j++) {
                                       for (let k = 1; k < this.pdf[i].columnArray.length; k++) {
                                           this.pdfRecords[j][this.pdf[i].columnArray[0]] = this.pdfRecords[j][this.pdf[i].columnArray[0]] + '\n' + this.reportRecords[j][this.pdf[i].columnArray[k]];
                                       }
                                   }
                               }
                           }
                       }
                   }
                   this.showPrintModal = true;
               },
               exportCSV (index) {
                   let list = this.export;
                   let columns = [];

                   for (let i = 0; i < this.report.Params.Columns.length; i++) {
                       if (this.report.Params.Columns[i].selected == 'y') {
                           columns.push(this.report.Params.Columns[i].column);
                       }
                   }

                   let csv = Papa.unparse(list, {columns: columns});
                   var name = this.report.ReportName;
                   name = name.replace(' ' ,'');
                   let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                   let csvURL = null;
                   if (navigator.msSaveBlob) {
                       csvURL = navigator.msSaveBlob(csvData, name + '.csv');
                   } else {
                       csvURL = window.URL.createObjectURL(csvData);
                   }

                   let tempLink = document.createElement('a');
                   tempLink.href = csvURL;
                   tempLink.setAttribute('download', name + '.csv');
                   tempLink.click();
               },
               displayPopup (message) {
                   this.$alert(message, {
                       type: 'error',
                       confirmButtonText: 'OK',
                   });
               }

           },
           mounted () {
               this.formatGameColumns();

               var panels = JSON.parse(localStorage.getItem('reportpanels'));
               if (panels) {
                   this.activePanels = panels;
               }
               this.ReportTypes[3].options.push({key : "MainLocations" , label : this.config.Params.mainlocationNames});
               this.ReportTypes[3].options.push({key : "Programs" , label : this.config.Params.programNamess});
               if (!this.$modifyAccess()) {
                   this.activePanels = ['2'];
               }
               if (!this.$createAccess() || !this.$modifyAccess()) {
                   this.PrivateShared = 's';
               }
               if (this.$route.params.id) {
                   this.FolderReportID[0] = '';
                   this.FolderReportID[1] = this.$route.params.id;
                   this.getReport();
               } else if (this.DisplayReportCode) {
                   this.FolderReportID[0] = '';
                   this.getReport();
               } else {
                   this.getInitialData();
               }
           },

       }
   </script>
   <style>
	   #app #reportwriter .tags.el-input__inner {
			line-height: 26px;
	   }
       #reportwriter .reportTitle {
           margin: 16px auto 0px auto;
           justify-content: center;
           text-align: center;
       }
       #reportwriter .reportTitle h5 {
           padding: 0px 10px 0px 10px;
           border-bottom: 1px solid #EBEEF5;
           font-weight: 600;
       }
       .el-table th {
           font-weight: 400;
       }
       .reportwriter div.el-table .cell {
           word-break: normal;
       }
       .reportwriter .el-form-item {
           margin-bottom: 5px;
       }
       #reportwriter .el-table__footer div.cell {
           font-size: 12px;
           font-weight: 800;
           color: #000;
       }
       #reportwriter .el-range-editor--medium .el-range-separator {
           width: 30px;
       }
       #app #reportwriter .el-date-editor--daterange.el-input__inner {
           width: 100%;
       }
       #app #reportwriter div.el-collapse-item__header {
           color: #fff;
           font-size: 16px;
           background: #666;
           height: 30px;
           padding-left: 5px;
       }
       #app #reportwriter .el-collapse {
           border: 0;
       }
       #app #reportwriter.navReport div.el-collapse-item__header {
           display: none;
       }
       #app #reportwriter .el-collapse-item__wrap {
           border: 0;
       }
       #app #reportwriter .el-collapse-item__content {
           padding-bottom: 0px;
       }
       #app #reportwriter .el-collapse-item {
           margin-bottom: 15px;
       }
       #app #reportwriter .el-collapse-item .card {
           margin: 0px;
       }
       #app #reportwriter .tableCheckBox .el-checkbox__label {
           font-size: 12px;
       }
       #app #reportwriter .sharing .el-checkbox__label {
           font-size: 12px;
       }
       #app #reportwriter .el-checkbox.sharing {
           position:absolute;
           right:0;
	       top: -5px;
           margin-right: 15px;
       }
	   #app #reportwriter .el-checkbox.activeonly .el-checkbox__label {
		   font-size: 12px;
		   font-weight: 400;
	   }
	   #app #reportwriter .teamids .el-form-item {
			margin-bottom: 0px;
	   }
       #app #reportwriter .tableCheckBox {
           line-height: 15px;
           text-align:left;
       }
	   #app #reportwriter .daterange .el-form-item__content {
		   background-color: #FFFFFF;
	   }
       #app .el-checkbox.sharing span.el-checkbox__inner {
            height: 15px;
            width: 15px;
       }
       #app .el-checkbox.sharing .el-checkbox__input.is-checked span.el-checkbox__inner::after, #app .el-checkbox.sharing span.el-checkbox__inner::after {
            height: 9px;
            width: 4px;
            margin-left: 0px;
            margin-top: 0px;
        }
   </style>
   <style scoped>
       #reportwriter .heading {
           background-color: #1A719C;
           color: #fff;
           font-size: 14px;
       }
       #reportwriter .body {
           color: #333;
           font-size: 14px;
           padding: 2px;
       }
       #reportwriter .body input, #reportwriter .body select, #reportwriter .body textarea {
           color: #333;
           font-size: 14px;
       }
       #reportwriter .body select {
           padding: 1px;
       }

       #reportwriter .body.row:nth-child(even){
           background-color: #dcdcdc;
       }
       #reportwriter .body.row:nth-child(odd){
           background-color: #ffffff;
       }
       #reportwriter .row.buttons {
           margin-top: 20px;
       }
       #reportwriter .el-range-editor--mini.el-input__inner, .el-cascader.oneHundred {
           width: 100%;
       }
       #reportwriter .el-form-item {
           margin-bottom: 10px;
       }
       @media print {
           .TablePrint {
               margin: 30px;
           }
       }
       .description {
           margin-left: 5px;
           font-size: 10px;
           line-height: 12px;
       }
       .columns {
           border-bottom: 1px solid #dedede;
           padding: 5px 0px;
       }
       .columnsHeader {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        background: var(--el-color-primary);
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
    }
       .flip-list-move {
           transition: transform 0.5s;
       }
       .no-move {
           transition: transform 0s;
       }
       .ghost {
           opacity: 0.5;
           background: #c8ebfb;
       }
       span .list-group {
           margin: 10px 0px;
       }
       .list-group-item {
           cursor: move;
           display: flex;
           padding:5px 0px 0px 0px;
           line-height: 30px;
       }
       .list-group-item i.fa-bars {
           cursor: pointer;
           padding:8px 0px 0px 0px;
       }
       .el-tag {
           margin-left: 10px;
       }
	   span.el-tag {
		   margin-right: 0px;
	   }
       .button-new-tag {
           margin-left: 10px;
           height: 25px;
           line-height: 25px;
           padding-top: 0;
           padding-bottom: 0;
       }
       .input-new-tag {
           width: 90px;
           margin-left: 10px;
           vertical-align: bottom;
       }
       .tableCheckBox label {
           margin-bottom: 0px;
       }
       .parameterShown {
		   position:relative;
           background-color: rgb(40, 167, 69, 0.1);
           border: 1px solid #666;
           margin-top: 2px;
           padding-top: 2px;
       }
       #reportwriter .card {
	       padding: 20px 12px;
       }
       .reportdescription {
            font-size: 14px;
            text-align: center;
            color: #000;
            background-color: rgb(40, 167, 69, 0.1);
            margin: 0px auto 10px auto;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            width: 100%;
       }
       .col-12, .col-6, .col-md-6 {
		   position: relative;
	   }
       .el-table--fit {
            padding: 0px;
       }
   </style>
