<template>
	<div>
		<modal v-if="open" :showMask="true" @close="cancel" top="400px" width="760px">
			<template v-slot:header>
				<h5 class="modal-title">
					{{modalTitle}}
				</h5>
			</template>
			<template v-slot:body>
				<ds-event
				:target-details="details"
				:target-schedule="schedule"
				:calendar="calendar"
				:config="config"
				:calendar-event="calendarEvent"
				:Data="Data"
				:day="day"
				:read-only="readOnly"
				:isConstraints="isConstraints"
				@saved="saved"
				@cancel="cancel"
				@actioned="actioned"
				@event-create="eventCreate"
				@event-update="eventUpdate"
				@event-remove="eventRemove"
				@remove="eventRemove"
				></ds-event>
			</template>
			<template v-slot:footer>
				&nbsp;
			</template>
		</modal>
	</div>
</template>

<script>
import { Calendar, Schedule, Identifier, Functions as fn } from 'dayspan';
import Modal from '../../global/Modal.vue';

export default {

	name: 'dsEventDialog',

	components: {
		Modal
	},
	props: {
		calendar:
		{
			type: Calendar
		},
		config:
		{
			type: Object,
			default() {
				{};
			}
		},
		dialogProps:
		{
			validate(x) {
				return this.$dsValidate(x, 'dialogProps');
			},
			default() {
				return {
					maxWidth: '600px',
					persistent: true,
					lazy: true
				};
			}
		},

		readOnly:
		{
			type: Boolean,
			default: false
		},
		Data:
		{
			type: Object,
			default() {
				{};
			}
		},
		isConstraints:
		{
			type: Boolean,
			default: false
		},
		ProgramIDAccess:
		{
			type: Boolean,
			default: false
		},
		MainLocationIDAccess:
		{
			type: Boolean,
			default: false
		},
		TeamIDAccess:
		{
			type:
			Array,
			default() {
				[];
			}
		},
	},

	data: vm => ({
		open: false,
		showModal : true,
		calendarEvent: null,
		details: null,
		schedule: null,
		day: null
	}),

	computed: {
		modalTitle () {
			if (this.isConstraints) {
				if (this.details.ConstraintSlotID) {
					return 'Edit Constraint';
				} else {
					return 'Add New Constraint';
				}
			} else {
				if (this.details.CalendarSlotID) {
					return 'Edit Calendar Slot';
				} else {
					return 'Add New Calendar Slot';
				}
			}
		}
	},

	methods: {
		addToday()
		{
			this.add( this.$dayspan.today );
		},
		add(day, days)
		{
			this.addSchedule( day, Schedule.forDay( day, days ) );
		},

		addAt(day, hour)
		{
			this.addSchedule( day, Schedule.forTime( day, hour ) );
		},

		addSpan(span)
		{
			this.addSchedule( span.start, Schedule.forSpan( span ) );
		},

		addPlaceholder(placeholder, details)
		{
			this.addSchedule( placeholder.start, placeholder.schedule, details || placeholder.event.data );
		},

		addSchedule(day, schedule, details) {
			let lastSaved = JSON.parse(localStorage.getItem('lastSavedDetail'+(this.isConstraints ? 'Constraint' : 'Calendar')));
			if (lastSaved) {
				details = lastSaved;
				if (details.SlotType == 'e') {
					details.Attendees = [];
					details.eventCurrentAttendance = 0;
				}
			}
			this.day = day;
			this.calendarEvent = null;
			this.details = details || this.$dayspan.getDefaultEventDetails();
			if (details && !details.SeasonID) {
				details.SeasonID = '';
			}
			for (let i=0; i < this.Data.Organizations.length; i++) {
				if (this.Data.Organizations[i]['OrganizationID'] == this.details.OrganizationID) {
					if (this.Data.Organizations[i]['DefaultFee']) {
						this.details.RinkFee = this.Data.Organizations[i]['DefaultFee'];
					}
					if (this.Data.Organizations[i]['HourlyFlat']) {
						this.details.HourlyFlat = this.Data.Organizations[i]['HourlyFlat'];
					} else {
						this.details.HourlyFlat = 'f';
					}
					if (this.Data.Organizations[i]['DefaultColor']) {
						this.details.color = this.Data.Organizations[i]['DefaultColor'];
					}
				break;
				}
			}
			//convert for calendar not starting at midnight
			if (schedule.durationUnit != 'minutes') {
				schedule.duration = 60;
				schedule.durationUnit = 'minutes';
				schedule.times[0] = ["8"]
			} else {
			schedule.durationInDays = 0;
				var startminute = parseInt((this.config.Params.calendarStartHour ? this.config.Params.calendarStartHour * 60 : 0));
				//var hour = (schedule.times[0].hour == 12 ? 0 : schedule.times[0].hour);
				var hour = schedule.times[0].hour;
				var minute = schedule.times[0].minute;
				var minutes = (minute + (hour * 60)) * (4 / 5);
				var realminutes = minutes + startminute;
				hour = parseInt(realminutes / 60);
				minute = realminutes - (hour * 60);
				minute = (minute % 5) >= 2.5 ? parseInt(minute / 5) * 5 + 5 : parseInt(minute / 5) * 5;

				schedule.times[0].hour = hour;
				schedule.times[0].minute = minute;
				schedule.duration = schedule.duration * (schedule.durationUnit == 'hours' ? 60 : 1) * (4 / 5);
				schedule.duration = (schedule.duration % 5) >= 2.5 ? parseInt(schedule.duration / 5) * 5 + 5 : parseInt(schedule.duration / 5) * 5;
			}
			this.schedule = schedule;

			this.finishOpen();
		},

		edit(calendarEvent) {
			this.day = calendarEvent.start;
			this.calendarEvent = calendarEvent;
			this.details = calendarEvent.event.data || this.$dayspan.getDefaultEventDetails();
			this.schedule = calendarEvent.schedule;

			this.finishOpen();
		},

		finishOpen() {
			var ev = this.getEvent('open');

			this.$emit('open', ev);

			if (ev.open) {
				this.open = true;
			}
		},

		eventCreate(ev) {
			this.$emit('event-create', ev);
		},

		eventUpdate(ev) {
			this.$emit('event-update', ev);
		},

		eventRemove(ev) {
			this.$emit('event-remove', ev);
			ev.hide = true;
			this.$emit('cancel', ev);
			this.finishClose( ev );
		},

		actioned(ev) {
			ev.hide = true;
			this.$emit('actioned', ev);
			this.finishClose( ev );
		},

		cancel(ev) {
			if (!ev) {
				ev = this.getEvent('cancel');
			}
			ev.hide = true;
			this.$emit('cancel', ev);
			this.finishClose( ev );
		},

		saved(ev) {
			ev.hide = true;
			this.$emit('saved', ev);
			this.finishClose( ev );
		},
		create(ev) {
			ev.hide = true;
			this.$emit('saved', ev);
			this.finishClose( ev );
		},
		update(ev) {
			ev.hide = true;
			this.$emit('saved', ev);
			this.finishClose( ev );
		},
		finishClose(ev) {
			if (ev.hide) {
				this.open = false;
				this.$emit('close', ev);
			}

			this.schedule = null;
			this.details = null;
		},

		getEvent(type, extra = {}) {
			return fn.extend({
				type: type,
				day: this.day,
				schedule: this.schedule,
				calendar: this.calendar,
				calendarEvent: this.calendarEvent,
				handled: false,
				open: true,
				$vm: this,
				$element: this.$el
			}, extra);
		}
	}
}
</script>

<style lang="scss">
#app .modal-dialog {
	width: 750px;
}
#app.constraints .modal-dialog {
	width: 600px;
}
</style>
