export default {
	items: [
		{
			name: 'Return',
			url: '/',
			security: false,
			icon: 'fas fa-arrow-alt-circle-left'
		},
        {
            name: 'Analyzer Dashboard',
            url: '/analyzer/dashboard',
            security: false,
            icon: 'fas fa-tachometer-alt'
        },
		{
			name: 'Structure',
			icon: 'fa fa-project-diagram',
			children: [
				{
					name: 'Leagues',
					url: '/analyzer/leagues',
					security: true,
					icon: 'fas fa-trophy'
				},
                {
                    name: 'Registration Settings',
                    url: '/analyzer/registrationsettings',
                    security: true,
                    icon: 'fas fa-child',
                },
				{
					name: 'League Login Roles',
					url: '/analyzer/leagueroles',
					security: true,
					icon: 'fa-solid fa-lock'
				},
				{
					name: 'PROGRAM_S',
					url: '/analyzer/programs',
					security: true,
					program: true,
					icon: 'fas fa-building',
				},
				{
					name: 'Teams',
					url: '/analyzer/teams',
					security: true,
					icon: 'fas fa-shield-alt'
				},
				{
					name: 'MAINLOCATION_S',
					url: '/analyzer/locations',
					security: true,
					icon: 'fas fa-map-marked'
				},
				{
					name: 'Availability Calendar',
					url: '/analyzer/calendar',
					security: true,
					icon: 'far fa-clock'
				},
			]
		},
		{
			name: 'Scheduling',
			icon: 'far fa-calendar-alt',
			children: [
				{
					name: 'Constraints',
					url: '/analyzer/constraints',
					security: true,
					icon: 'fas fa-clock'
				},
				{
					name: 'SCHEDULE Settings',
					url: '/analyzer/schedulesettings',
					security: true,
					icon: 'fas fa-sliders-h'
				},
				{
					name: 'SCHEDULE Listing',
					url: '/analyzer/schedulelisting',
					security: true,
					icon: 'far fa-list-alt'
				},
                {
                    name: 'Generate GAME_S',
                    url: '/analyzer/generategames',
                    security: true,
                    icon: 'fas fa-plus-circle'
                },
                {
                    name: 'Schedule GAME_S',
                    url: '/analyzer/scheduling',
                    security: true,
                    icon: 'far fa-play-circle'
                },
				{
					name: 'SCHEDULE Management',
					url: '/analyzer/schedulemanagement',
					security: true,
					icon: 'far fa-calendar-alt',
				},
				{
					name: 'GAME Updates',
					url: '/analyzer/gamedetails',
					security: true,
					icon: 'fas fa-edit',
				},
				{
					name: 'Horizon Management',
					url: '/analyzer/horizonmanagement',
					security: true,
					icon: 'fa-solid fa-stopwatch',
				},
                {
                    name: 'Swap SCHEDULE_S',
                    url: '/analyzer/swapschedules',
                    security: true,
                    icon: 'fas fa-random',
                },
                {
                    name: 'Tournaments',
                    url: '/analyzer/tournaments',
                    security: true,
                    icon: 'fas fa-medal',
                },
			]
		},
		{
			name: 'Administrative',
			icon: 'fa fa-cogs',
			children: [
                {
                    name: 'Import Data',
                    url: '/analyzer/imports',
                    security: true,
                    icon: 'fas fa-file-import'
                },
				{
					name: 'Seasons',
					url: '/analyzer/seasons',
					security: true,
					icon: 'fas fa-calendar-alt'
				},
				{
					name: 'STANDING_S Profiles',
					url: '/analyzer/standingsprofiles',
					security: true,
					icon: 'fas fa-list-ol'
				},
				{
					name: 'Reference Data',
					url: '/analyzer/referencedata',
					security: true,
					icon: 'fas fa-cog'
				},
				{
					name: 'Settings',
					url: '/analyzer/sitesettings',
					security: true,
					icon: 'fas fa-wrench'
				},
                {
                    name: 'Tags',
                    url: '/analyzer/tags',
                    security: true,
                    icon: 'fas fa-tags'
                },
                {
                    name: 'Recycle Bin',
                    url: '/analyzer/recyclebin',
                    security: true,
                    icon: 'fas fa-recycle'
                },
			]
		},
	]
}
