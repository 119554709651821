<template>

  <div class="ds-week">

    <template v-for="(day, i) in days" :key="i">

      <ds-day
        :day="day"
        :congig="config"
        :calendar="calendar"
        :placeholder="placeholder"
        :locationid="locationid"
        :placeholder-for-create="placeholderForCreate"
        @edit="edit"
        @add="add"
      ></ds-day>

    </template>

  </div>

</template>

<script>
import { Calendar, CalendarEvent } from 'dayspan';


export default {

  name: 'dsDayRow',

  props:
  {
    days:
    {
      required: true,
      type: Array
    },

    calendar:
    {
      required: true,
      type: Calendar
    },
	config:
	{
	  type: Object,
	  default() {
		  {};
	  }
	},
    placeholder:
    {
      type: CalendarEvent
    },

    placeholderForCreate:
    {
      type: Boolean,
      default: false
    },
    locationid:
    {
      type: Number,
      default: 0
    },
  },

  methods:
  {
	  edit(calendarEvent)
	  {
		  this.$emit('edit', calendarEvent);
	  },
	  add(day)
	  {
		  this.$emit('add', day);
	  },
  }
}
</script>

<style scoped lang="scss">

.ds-week {
  display: flex;
  flex: 1;
}

</style>
