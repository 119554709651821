<template>
    <div class="FinanceDropdowns">
        <el-form ref="FinanceDropdowns" label-position="top" >
            <div v-if="!ProgramIDAccess" style="width: 100%; margin-top: 10px;">
                <el-form-item label="Organization:" prop="OrganizationID">
                    <el-select v-model="OrganizationID" class="topDropdown" placeholder="All Organizations" @change="getFinanceSettings('o')" default-first-option filterable :clearable="this.calling == 'Invoices' || this.calling == 'InvoiceBalances' || this.calling == 'Statements'">
                        <el-option
                                v-for="item in (this.calling == 'InvoiceBalances' ? BalanceOrganizations : Organizations)"
                                :label="item.OrganizationName"
                                :value="item.OrganizationID"
                                :key="item.OrganizationID">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div v-if="validInvoiceSettings.length > 0" style="width: 100%;">
                <el-form-item label="Account:" prop="InvoiceSettingID">
                    <el-select key="select3" class="topDropdown" v-model="InvoiceSettingID" placeholder="All Accounts" @change="dropdownChanged('InvoiceSettings')" default-first-option filterable :clearable="(validInvoiceSettings.length > 1)">
                        <el-option
                            v-for="type in validInvoiceSettings"
                        :value="type.InvoiceSettingID"
                        :key="type.InvoiceSettingID"
                        :label="type.BillingName">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div v-if="validLeagues.length > 0 && 0==1" style="width: 100%;">
                <el-form-item label="League:" prop="LeagueID">
                    <el-select key="selectLeague"  class="topDropdown" v-model="LeagueID" placeholder="All Leagues" @change="dropdownChanged('League')" default-first-option filterable :clearable="(validLeagues.length > 1)">
                        <el-option
                            v-for="type in validLeagues"
                            :value="type.LeagueID"
                            :key="type.LeagueID"
                            :label="type.LeagueName">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>

            <div v-if="seasonManagement" style="width: 100%;">
                <el-form-item label="Season:" prop="SeasonID">
                    <el-select key="selectseason" v-model="SeasonID" placeholder="Select Season" @change="dropdownChanged('Season')" style="width: 200px;" default-first-option filterable>
                        <el-option
                            v-for="item in FinanceSeasons"
                            :key="item.SeasonID"
                            :label="item.SeasonName"
                            :value="item.SeasonID">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div v-if="LeagueID && SeasonID && LeagueTeams[SeasonID] && LeagueTeams[SeasonID][LeagueID] && LeagueTeams[SeasonID][LeagueID].length > 0 && teamManagement"  style="width: 100%;">
                <el-form-item label="Team:" prop="TeamID">
                    <el-select key="selectteam" v-model="TeamID" class="topDropdown" placeholder="All Teams"  @change="dropdownChanged('Team')"  default-first-option filterable clearable>
                        <el-option
                            v-for="item in LeagueTeams[SeasonID][LeagueID]"
                            :key="item.TeamID"
                            :label="item.TeamName"
                            :value="item.TeamID">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
		data () {
			return {
                loading : true,
                Organizations : [],
                BalanceOrganizations : [],
                Seasons : [],
                OrganizationID: '',
                SeasonID: '',
                InvoiceSettings : [],
                InvoiceSettingID : '',
                InvoiceSettingIDs : [],
                SeasonIDs : [],
                Leagues: [],
                LeagueID : '',
				LeagueIDs : [],
                TeamID : '',
                LeagueTeams : [],
			}
		},
		name: 'FinanceDropdowns',
        props:["config", "parentrefs", "calling", "refreshkey", "updates"],
        watch: {
			refreshkey: {
                handler:function(newValue) {
                    if (newValue > 0) {
                        this.getFinanceSettings('y');
                    }
                },
			},
		},
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			...mapGetters({ componentIDs: 'componentIDs' }),
			ProgramIDAccess () {
				return this.$ProgramIDAccess();
			},
			validLeagues () {
                var leagues = [];
                var notFound = true;
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.InvoiceSettingID != '' && this.Leagues[i].InvoiceSettingID != 0 && this.InvoiceSettingID != this.Leagues[i].InvoiceSettingID) {
                        continue;
                    }
                    if (this.Leagues[i].FinanceType == 'F') {
                        leagues.push(this.Leagues[i]);
                        if (this.LeagueID && this.LeagueID == this.Leagues[i].LeagueID) {
                            notFound = false;
                        }
                    }
                }
                return leagues;
            },
            validInvoiceSettings () {
                var settings = [];
                var notFound = true;
                for (let i = 0; i < this.InvoiceSettings.length; i++) {
                    if (this.InvoiceSettingIDs.indexOf(this.InvoiceSettings[i]['InvoiceSettingID']) !== -1) {
                        settings.push(this.InvoiceSettings[i]);
                        if (this.InvoiceSettingID && this.InvoiceSettingID == this.InvoiceSettings[i]['InvoiceSettingID']) {
                            notFound = false;
                        }
                    }
                }
                return settings;
            },
            FinanceSeasons () {
                var seasons = [];
                var notFound = true;
                for (let i = 0; i < this.Seasons.length; i++) {
                    if (this.SeasonIDs.indexOf(this.Seasons[i]['SeasonID']) !== -1) {
                        seasons.push(this.Seasons[i]);
                        if (this.SeasonID && this.SeasonID == this.Seasons[i]['SeasonID']) {
                            notFound = false;
                        }
                    }
                }
                if (seasons.length == 1 || (notFound && seasons.length > 0)) {
                    this.SeasonID = seasons[0]['SeasonID'];
					for (let  i = 0; i < seasons.length; i++) {
						if (seasons[i]['Params']['DefaultSeason'] == 'y') {
							this.SeasonID = seasons[i]['SeasonID'];
							break;
						}
					}
                }
                this.dropdownChanged('Season');
                return seasons;
            },
            seasonManagement () {
                if (this.InvoiceSettingID) {
                    for (let i = 0; i <  this.InvoiceSettings.length; i++)
                    {
                        if (this.InvoiceSettings[i].InvoiceSettingID == this.InvoiceSettingID) {
                            if (this.InvoiceSettings[i].Params.BillingSeasons == 'o') {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    }
                } else {
                    for (let i = 0; i <  this.InvoiceSettings.length; i++)
                    {
                        //if one account is by season, then say season management since no account selected.
                        if (this.InvoiceSettingIDs.indexOf(this.InvoiceSettings[i].InvoiceSettingID) !== -1) {
                            if (this.InvoiceSettings[i].Params.BillingSeasons == 's') {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            teamManagement () {
                if (this.Leagues && this.Leagues.length > 0) {
                    for (let i = 0; i < this.Leagues.length; i++) {
                        if (this.Leagues[i].LeagueID == this.LeagueID) {
                            if (this.Leagues[i].Params.LeagueBilling == 'p') {
                                this.TeamID = '';
                                return false;
                            }
                        }
                    }
                    return true;
                }
                this.TeamID = '';
                return false;
            }
		},
		methods: {
			dropdownChanged (Area) {
                var BillingSeasons = 's';
                if (Area == 'InvoiceSettings') {
                    this.LeagueID = '';
                }

                if (this.InvoiceSettingID == '') {
                    for (let i = 0; i < this.Leagues.length; i++) {
                        if (this.LeagueID == this.Leagues[i].LeagueID){
                            this.InvoiceSettingID = this.Leagues[i].InvoiceSettingID;
                            break;
                        }
                    }
                }
				var found = false;
				for (let i = 0; i < this.validInvoiceSettings.length; i++) {
					if (this.InvoiceSettingID == this.validInvoiceSettings[i].InvoiceSettingID) {
						found = true;
                        BillingSeasons = this.validInvoiceSettings[i].Params.BillingSeasons;
						break;
					}
				}
				if (!found && this.LeagueID) {
					this.InvoiceSettingID = '';
				}
				this.$emit('dropdownsChange', this.OrganizationID, this.LeagueID, this.InvoiceSettingID, (this.seasonManagement ? this.SeasonID : ''), this.TeamID, this.LeagueIDs, this.Leagues, this.validInvoiceSettings, BillingSeasons);
			},
			getInitialData () {
				var tables = ['Organizations','BalanceOrganizations','Seasons','InvoiceSettings'];
				axios.post('/index/tableData', {'tables': tables },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let value of tables) {
								this[value] = response.data[value];
							}
                            if (this.componentIDs[this.calling + 'OrganizationID']) {
                                this.OrganizationID = this.componentIDs[this.calling + 'OrganizationID'];
                            }
                            if (!this.ProgramIDAccess && !this.OrganizationID && this.componentIDs[this.calling + 'OrganizationID']) {
                                this.OrganizationID = this.componentIDs[this.calling + 'OrganizationID'];
                            }
                            if (this.ProgramIDAccess || !this.OrganizationID) {
                                this.OrganizationID = this.Organizations[0]['OrganizationID'];
                            }
                            this.getFinanceSettings('n');
						} else {
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
				});
			},
            getFinanceSettings (refreshonly) {
                this.loading = true;
                this.Leagues = [];
                this.LeagueTeams = [];
                if (refreshonly == 'n' || refreshonly == 'o') {
                    this.LeagueID = '';
                    this.InvoiceSettingID = '';
                    this.TeamID = '';
                }
                let self = this;
                axios.post('/finance/getfinancesettings', {
                        OrganizationID: this.OrganizationID,
                        calling : this.calling
                    }
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.SeasonIDs = (response.data.SeasonIDs ? response.data.SeasonIDs : []);
                            this.InvoiceSettingIDs = (response.data.InvoiceSettingIDs ? response.data.InvoiceSettingIDs : []);
							this.LeagueIDs = (response.data.LeagueIDs ? response.data.LeagueIDs : []);
                            this.Leagues = (response.data.Leagues ? response.data.Leagues : []);
                            this.LeagueTeams = (response.data.teams ? response.data.teams : []);
                            var refreshArea = '';
                            if (refreshonly == 'n' || refreshonly == 'o') {
                                var field = this.calling + 'OrganizationID';
                                self.$store.dispatch('setID', {[field] : self.OrganizationID});
                                this.SeasonID = this.Seasons[0]['SeasonID'];
                                for (let  i = 0; i < this.Seasons.length; i++) {
                                    if (this.Seasons[i]['Params']['DefaultSeason'] == 'y') {
                                        this.SeasonID = this.Seasons[i]['SeasonID'];
                                        break;
                                    }
                                }
                                if (this.LeagueIDs.length == 0 && this.InvoiceSettingIDs.length == 1) {
                                    this.InvoiceSettingID = this.InvoiceSettingIDs[0];
                                } else {
                                    if (this.validLeagues.length == 1) {
                                        this.LeagueID = this.validLeagues[0]['LeagueID'];
                                        refreshArea = 'League';
                                    }
                                }
                                var BillingSeasons = 's';
                                var found = false;
                                for (let i = 0; i < this.validInvoiceSettings.length; i++) {
                                    if (this.InvoiceSettingID == this.validInvoiceSettings[i].InvoiceSettingID) {
                                        BillingSeasons = this.validInvoiceSettings[i].Params.BillingSeasons;
                                        found = true
                                        break;
                                    }
                                }
                                if (!found) {
                                    this.InvoiceSettingID = '';
                                }

                                if (refreshonly == 'o' || BillingSeasons == 'o') {
                                    this.dropdownChanged (refreshArea);
                                }
                            } else {
                                if (this.LeagueID != '' && this.updates.LeagueID) {
                                    this.LeagueID = this.updates.LeagueID;
                                }
                                if (this.InvoiceSettingID != '' && this.updates.InvoiceSettingID) {
                                    this.InvoiceSettingID = this.updates.InvoiceSettingID;
                                }
                                if (this.SeasonID != '' && this.updates.SeasonID) {
                                    this.SeasonID = this.updates.SeasonID;
                                }
                            }

                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
						this.loading = false;
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                });
            },

			displayPopup (message) {
				this.$alert(message, 'Error', {
					confirmButtonText: 'OK',
				});
			}
		},
		mounted() {
			this.getInitialData();
		},
	}
</script>

<style>
	#app .FinanceDropdowns .el-select.topDropdown {
		width: 75%;
	}
</style>
<style scoped>
	
</style>
