<template>
	<div id="notifications">
		<div class="row">
			<div class="col-12 fullpage">
				<p>The following are all of your Notifications</p>
				<el-input
						class="search"
						v-model="search"
						clearable
						placeholder="Type to search">
				</el-input>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-12 fullpage">
				<el-table v-if="Notifications.length > 0"
						  stripe
						  :data="Notifications.filter(data => !search
						  							|| (data.MessageTitle ? data.MessageTitle.toLowerCase().includes(search.toLowerCase()) :'')
						  							|| (data.MessageBody ? data.MessageBody.toLowerCase().includes(search.toLowerCase()) :'')
					  							)"
						  style="width: 100%"
						  size="small"
						  max-height="600">
					<el-table-column
							prop="NotificationID"
							label="ID"
							width="75px"
							align="center">
					</el-table-column>
					<el-table-column
							label="Notification"
							min-width="200px">
						<template #default="scope">
							<div v-if="scope.row.MessageLink">
								<a href="javascript:;" style="font-size: 14px;font-weight: 800;" v-on:click.stop="gotoLink(scope.row.MessageLink)" >{{scope.row.MessageTitle}}</a>
							</div>
							<div v-else><strong>{{scope.row.MessageTitle}}</strong></div>
							<div v-html="scope.row.MessageBody"></div>
							<div v-if="scope.row.Reply == 'y'" >
								<el-input
										type="textarea"
										:autosize="{ minRows: 2, maxRows: 15}"
										placeholder="Reply Message"
										v-model="scope.row.MessageReply">
								</el-input>
                                <el-button class="mt-1 btn-warning" type="warning" @click="sendReply(scope.row)">
                                    <i class="far fa-envelope"></i> <span> Send</span>
                                </el-button>
                                <el-button class="mt-1 btn-success" type="success" @click="markReply(scope.row)">
                                    <i class="fas fa-reply"></i> <span> Mark Replied</span>
                                </el-button>
							</div>
							<div v-if="scope.row.Reply == 'x'  && scope.row.MessageReply">
								<strong>Reply:</strong><br>
								<div class="messageReply" v-html="scope.row.MessageReply"></div>
								<em>by: {{scope.row.replyby}} - {{scope.row.replywhen}}</em>
							</div>
						</template>
					</el-table-column>
					<el-table-column
							prop="MessageStatus"
							label="Status"
							width="90px"
							align="center">
						<template #default="scope">
							<div>{{scope.row.MessageStatus}}</div>
							<div v-if="scope.row.Reply != 'x' && scope.row.MessageTitle == 'Contact Us Request'" style="height: 55px;" >
								<el-button type="success" class="btn-success circle" circle @click="scope.row.Reply = 'y'"><i class="fas fa-reply" ></i></el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column
							prop="createdwhen"
							label="Timestamp"
							width="140px"
							align="center">
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'Notifications',
		data() {
			return {
                currentStatus : STATUS_INITIAL,
				search: '',
				Notifications : [],
			}
		},
		props:["config", "parentrefs"],

		methods: {
			getNotifications () {
				this.Notifications = [];
				axios.post('/notifications/getall', {},
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.Notifications = response.data.Notifications;
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
						this.$failProgressBar(this.parentrefs);
				});
			},
			sendReply(row) {
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/notifications/sendreply', {Notification : row },
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							this.$stopProgressBar(this.parentrefs);
							this.$notify({
								title: 'Success',
								message: "Your Email Reply has been sent.",
								type: 'success',
								duration: 2000,
								showClose: false
							});
							for (let i = 0; i < this.Notifications.length; i++) {
								if (this.Notifications[i].NotificationID == row.NotificationID) {
									this.Notifications[i] = response.data.Notification;
									break;
								}
							}
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
            markReply(row) {
                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                axios.post('/notifications/markreply', {Notification : row },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_INITIAL;
                            this.$stopProgressBar(this.parentrefs);
                            this.$notify({
                                title: 'Success',
                                message: "This Notification has been marked as replied to.",
                                type: 'success',
                                duration: 2000,
                                showClose: false
                            });
                            for (let i = 0; i < this.Notifications.length; i++) {
                                if (this.Notifications[i].NotificationID == row.NotificationID) {
                                    this.Notifications[i] = response.data.Notification;
                                    break;
                                }
                            }
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.displayPopup (response.data.message);
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                });
            },
			gotoLink (link) {
				this.$router.replace(link);
			},
		},
		mounted () {
			this.getNotifications ();
		}
	}
</script>

<style>
    #notifications .el-table--mini td, .el-table--mini th {
        padding: 3px 0;
    }
</style>
<style scoped>

	.messageReply {
		border: 1px solid #ccc;
		border-radius: 4px;
		padding: 5px;
		background-color: #fff;
		line-height: 16px;
	}
	.messageReply br {
		height: 2px;
	}
	#app #notifications button.btn.circle i, #app #notifications button.el-button.circle i {
		margin : 0;
	}
</style>
