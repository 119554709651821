<template>
	<div id="gamescorereporting">
		<el-form ref="gamescorereporting" :model="formdetails" label-position="top" label-width="120px" >
			<div class="row">
				<div class="col-12 page-title bg-primary"><img class="small-logo" :src="logo" alt="Logo"/>&nbsp;Game Score Reporting</div>
			</div>
			<div class="row page-body" style="margin-bottom: 15px;">
				<div class="col-1"></div>
				<div class="col-10">
					<h5><div v-html="title"></div></h5>
					<div v-html="message"></div>
				</div>
				<div class="col-1"></div>
			</div>
			<div v-if="Game.HomeTeamName" class="row">
				<div class="col-1"></div>
				<div class="col-10">
					<el-form-item :label="Game.VisitingTeamName" prop="VisitingScore"
								  :rules="[{ required: true, message: 'Visitors Score is required', trigger: 'blur'}]"
					>
						<el-input  :disabled="Game.GameStatus != 'P'" class="smallNumber" v-model="formdetails.VisitingScore"></el-input>
					</el-form-item>
					<div class="mb-4"><strong>AT</strong></div>
					<el-form-item :label="Game.HomeTeamName" prop="HomeScore"
								  :rules="[{ required: true, message: 'Home Score is Required', trigger: 'blur'}]"
					>
						<el-input  :disabled="Game.GameStatus != 'P'" class="smallNumber" v-model="formdetails.HomeScore"></el-input>
					</el-form-item>
					<div v-if="Game.LeagueParams.GameSheetSubmission != 'n'" class="itemdetail mt-4 mb-4">
						<el-form-item label="Upload Gamesheet Image" prop="Params.GamesheetImage"
									  :rules="[{ required: (Game.LeagueParams.GameSheetSubmission == 'r' ? true : false), message: 'Gamesheet must be uploaded', trigger: 'blur'}]"
						>
							<form class="form-horizontal fade-in-effect" novalidate method="post" enctype="multipart/form-data">
								<div class="fileinput-button " style="text-align:center;">
									<div id="GamesheetImage" v-bind:class="{ 'success_upload' : isSuccess, 'failed_upload' : isFailed }" class="logo" style="position:relative;" :style="GamesheetImage">
										<div id="spinner" style="position:absolute;top:20px;left:74px;" v-if="isSaving"><i class="fas fa-spinner fa-spin fa-2x"></i> </div>

										<input v-if="Game.GameStatus == 'P'" type="file" id="GamesheetImageInput" name="GamesheetImage" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="application/pdf,image/*" class="input-file">
										<div v-if="RemoveImage" class="deleteImage" @click.prevent="removeFile"><i class="fas fa-times-circle"></i></div>
									</div>
								</div>
							</form>
						</el-form-item>
					</div>

					<div v-if="Game.GameStatus == 'P'" class="row buttons" style="margin-top: 30px;">
						<div>
							<button class="btn btn-success btn-icon" type="button" @click="submitScore">
								<i class="fas fa-check"></i> <span> Submit Game Score</span>
							</button>
						</div>
					</div>
				</div>
				<div class="col-1"></div>
			</div>
			<div class="row">
				<div class="col-12 page-footer bg-primary">{{ pageFooter }}</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	import axios from 'axios';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'GameScoreReporting',
	    data() {
	        return {
	        	title: '',
				message : '',
		        currentStatus : STATUS_INITIAL,
		        formdetails : {HomeScore:'',VisitingScore:'',Params : {}},
		        Game : {HomeTeamName:'', VisitingTeamName : '', HomeScore:'', VisitingScore:'', LeagueParams : {}, GameStatus : 'X', Params : {}}
			}
	    },
		props:["config", "parentrefs"],
		computed: {
			pageFooter() {
				return '© ' + (new Date()).getFullYear() + ' ' + this.config.Params.SiteName;
			},
			gameCode() {
				return this.$route.query.game;
			},
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
			logo () {
				return (this.Game.LeagueParams.LeagueLogo ? this.Game.LeagueParams.LeagueLogo : this.defaultLogo);
			},
			defaultLogo () {
				return this.config.Params.SmallLogo;
			},
			defaultGamesheet () {
				return require('../assets/images/usahockeyscoresheet_small.png')
			},
			GamesheetImage() {
				let img = 'backgroundImage: url(' + (this.formdetails.Params.GamesheetImage ? this.formdetails.Params.GamesheetImage : this.defaultGamesheet) + ')';
				return img;
			},
			RemoveImage() {
				if (this.formdetails.Params.GamesheetImage && this.Game.GameStatus == 'P') {
					return true;
				} else {
					return false;
				}
			},
		},
		methods: {
			getGame () {
				this.$startProgressBar(this.parentrefs);
				axios.post('/gamereporting/getgame', { gameCode: this.gameCode},
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.Game = response.data.Game;
							if (this.Game.GameStatus != 'P') {
								this.title = 'Game Score Not Necessary';
								this.message = 'Score Reporting for this game is no longer necessary';
								this.formdetails.HomeScore = this.Game.HomeScore;
								this.formdetails.VisitingScore = this.Game.VisitingScore;
								this.formdetails.Params = this.Game.Params;
							} else {
								this.title = "Please enter the score of the game at " + this.Game.LocationName + ' on ' + this.Game.GameDate + ' @ ' + this.Game.StartTime;
								this.message = '';
							}
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.$failProgressBar(this.parentrefs);
							this.title = '<span class="bad">Sorry!</span>';
							this.message = response.data.message;
						}
					}).catch(response => {
						this.$failProgressBar(this.parentrefs);
						this.title = '<span class="bad">Sorry!</span>';
						this.message = 'Unfortunately, the game score cannot be entered at this time.';
				});
			},
			submitScore () {
				if (this.gameCode) {
					this.$refs['gamescorereporting'].validate((valid) => {
						if (valid) {
							if (this.currentStatus == STATUS_SAVING) {
								return true;
							}
							this.currentStatus = STATUS_SAVING;
							this.$startProgressBar(this.parentrefs);
							var that = this;
							axios.post('/gamereporting/postscore', {
								 gameCode: this.gameCode,
								  details: this.formdetails

							}).then(response => {
									if (response.data.result == 'success') {
										this.$notify({
											title: 'Success',
											message: "Thank you for submitting your game score.",
											type: 'success',
											duration: 2000,
											showClose: false
										});
										this.title = '<span class="success">Thank You!</span>';
										this.message = 'For submitting your game score.';
										this.$stopProgressBar(this.parentrefs);
										this.currentStatus = STATUS_SUCCESS;
										this.Game.GameStatus = 'C';
									} else {
										this.$notify({
											title: 'Error',
											message: "Unfortunately, we were NOT able to record your game score.",
											type: 'error',
											duration: 2000,
											showClose: false
										});
										this.title = '<span class="bad">Oh No, we have an error on our end!</span>';
										this.message = (response.data.message ? response.data.message : 'Unfortunately, we were NOT able to record your game score.');
										this.currentStatus = STATUS_FAILED;
										this.$failProgressBar(this.parentrefs);
									}

								}).catch(response => {
									this.$notify({
										title: 'Error',
										message: "Unfortunately, we were NOT able to record your game score.",
										type: 'error',
										duration: 2000,
										showClose: false
									});
									this.title = '<span class="bad">Oh No, we have an error on our end!</span>';
									this.message = 'Unfortunately, we were NOT able to record your game score.';

									this.currentStatus = STATUS_FAILED;
									this.$failProgressBar(this.parentrefs);
							});
						}
					});
				} else {
					this.title = '<span class="bad">Oh No, we have an error on our end!</span>';
					this.message = 'Unfortunately, we were NOT able to record your game score.';
				}
			},
			uploadFile(formData) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				// upload data to the server
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);

				axios.post('/gamereporting/uploadgamesheet', formData,
					{ headers: { 'Content-Type': 'multipart/form-data'} }
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_SUCCESS;
							this.formdetails.Params['GamesheetImage'] = response.data.url;
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
						document.getElementById("GamesheetImageInput").value = "";
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
			filesChange(fieldName, fileList) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				// handle file changes
				const formData = new FormData();

				if (!fileList.length) return;

				// append the files to FormData
				Array
					.from(Array(fileList.length).keys())
					.map(x => {
						formData.append(fieldName, fileList[x], fileList[x].name);
					});

				// save it
				this.uploadFile(formData);
			},
			removeFile() {
				this.formdetails.Params['GamesheetImage'] = '';
			},

		},
		mounted () {
			this.getGame();
		}
	}
</script>
<style>
	#app.gamescorereporting .page-wrapper {
		margin: 0px;
	}
	#app.gamescorereporting .container-fluid {
		padding-left: 0px;
	}
</style>
<style scoped>
	.small-logo {
		height: 30px;
		margin-right: 15px;
	}

	.page-title {
		color: #FFF;
		font-size: 20px;
		font-weight: 800;
		height: 42px;
		line-height: 42px;
		position: fixed;
		z-index: 100;
		padding-left: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		top: 0px;
	}
	.page-body {
		margin: 60px 0px;
	}
	.page-footer {
		color: #FFF;
		font-size: 12px;
		font-weight: 600;
		height: 20px;
		line-height: 20px;
		position: fixed;
		bottom: 0;
		z-index: 100;
		text-align: center;
	}
	.el-form-item {
		max-width: 500px;
	}
	.bad {
		color: #dc3545;
	}
	.success {
		color: #4CAF50;
	}
	#GamesheetImage {
		width: 250px;
		background: #FFFFFF left center no-repeat;
		background-size: contain;
		cursor: pointer;
		height: 100px;
	}
	#GamesheetImage .image .success_upload {
		border: 4px solid #28a745;
	}
	#GamesheetImage .image .failed_upload {
		border: 4px solid #dc3545;
	}
	.input-file {
		opacity: 0; /* invisible but it's there! */
		width: 250px;
		height: 100%;
		position: absolute;
		cursor: pointer;
		left:0;
	}
	.deleteImage {
		font-size: 14px;
		cursor:pointer;
		position: absolute;
		top: 0;
		background: #FFF;
		left: 0px;
		color: #666;
		padding: 0px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		line-height: 9px;
	}
	#GamesheetImage .el-form-item__error {
		padding-top: 2px;
	}
</style>
