<template>
	<div id="billings" v-loading.fullscreen.lock="loading">
		<el-form ref="financeForm" label-position="top" label-width="120px">
			<div class="row">
				<div class="col-12 fullpage">
					<el-radio-group v-if="!ProgramIDAccess" class="mt-2 mb-2"  v-model="BillingType" @change="billingtypeChange">
						<el-radio-button label="IU">{{config.Params.playingsurface}} Usage</el-radio-button>
						<el-radio-button label="TR">Team Registration</el-radio-button>
						<el-radio-button label="BG">{{config.Params.games}} Billing</el-radio-button>
						<el-radio-button label="MI">Manual Invoices</el-radio-button>
					</el-radio-group>
					<div v-if="BillingType != 'MI' && BillingType != 'IU' && ((BillingType == 'TR' || BillingType == 'BG')) && SeasonID">
						<el-form-item label="League Name" prop="LeagueName">
							<el-select  v-model="LeagueID" placeholder="Select" @change="leagueChange" clearable default-first-option filterable>
								<el-option
										v-for="item in BillingLeagues"
										:key="item.LeagueID"
										:label="item.LeagueName"
										:value="item.LeagueID">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="Division Type:" prop="BillingDivision">
							<el-radio-group v-model="BillingDivision" :disabled="BillingType == 'TR'">
								<el-radio-button label="T">Team Registration</el-radio-button>
								<el-radio-button label="D">Current Division</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</div>
					<div v-if="Organizations.length > 0 && (BillingType == 'IU' || BillingType == 'MI' || LeagueID) && !ProgramIDAccess" >
						<el-form-item label="Organization:" prop="OrganizationID">
							<el-select key="select2" v-model="OrganizationID" placeholder="Select or All Organizations" default-first-option filterable clearable>
								<el-option v-for="type in (BillingType == 'IU' || BillingType == 'MI' ? Organizations : (BillingType == 'TR' ? RegisteredLeagueOrganizations : LeagueOrganizations))"
										:value="type.OrganizationID"
										:key="type.OrganizationID"
										:label="type.OrganizationName">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div v-if="BillingType == 'MI'">
						<el-form-item label="League Name" prop="LeagueName">
							<el-select  v-model="LeagueID" placeholder="Select" @change="leagueChange" clearable default-first-option filterable>
								<el-option
										v-for="item in BillingLeagues"
										:key="item.LeagueID"
										:label="item.LeagueName"
										:value="item.LeagueID">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div v-if="InvoiceSettings.length > 0 && Organizations.length > 0 && (BillingType == 'IU' || BillingType == 'MI' || LeagueID)" >
						<div class="row">
							<div class="col-12 col-lg-8" style="padding: 0;">
								<div v-if="!ProgramIDAccess">
									<el-form-item label="Billing Account:" prop="InvoiceSettingID">
										<el-select key="select3" v-model="InvoiceSettingID" placeholder="Select" :disabled="(BillingType != 'IU' && BillingType != 'MI')" default-first-option filterable @change="showAdditionalInvoiceItems">
											<el-option v-for="type in InvoiceSettings"
													:value="type.InvoiceSettingID"
													:key="type.InvoiceSettingID"
													:label="type.BillingName">
											</el-option>
										</el-select>
									</el-form-item>
								</div>
								<div v-if="seasonManagement">
									<el-form-item label="Season:" prop="SeasonID">
										<el-select key="selectseason" v-model="SeasonID" placeholder="Select"  @change="seasonChange" default-first-option filterable>
											<el-option
												v-for="item in ActiveSeasons"
												:key="item.SeasonID"
												:label="item.SeasonName"
												:value="item.SeasonID">
											</el-option>
										</el-select>
									</el-form-item>	
								</div>
								<div v-if="LeagueID || BillingType == 'IU'" class="month-select">
									<el-form-item label="Billing Date:" prop="BillingDate">
										<el-date-picker v-model="BillingDate"  default-first-option filterable
														:clearable="false"
														type="date"
														format="MMM D, YYYY"
														value-format="YYYY-MM-DD"
														placeholder="Billing Date">
										</el-date-picker>
									</el-form-item>
								</div>
								<div v-if="modifyAccess && !(BillingDate == ''  && BillingType == 'IU' && OrganizationID)" class="customEmailDropdown"  >
									<div>
										<el-form-item label="Description:" prop="Description">
											<el-input v-model="BillingDescription" class="description" >
											</el-input>
										</el-form-item>
									</div>
									<div v-if="!ProgramIDAccess">
										<EmailTemplate calling="invoices" v-model="SystemText" :BillingType="BillingType" :config="config" width="512px" />
										<div v-if="SystemText" style="width:100%;">
											<el-checkbox v-model="EmailStatements" false-label="n" true-label="y" ><strong>Include Statement in Email</strong></el-checkbox>
										</div>
									</div>
								</div>
							</div>
							<div v-if="InvoiceSettings[InvoiceSettingsIndex] && InvoiceSettings[InvoiceSettingsIndex].Params.InvoiceItems" class="col-12 col-lg-4" style="max-width:350px;">
								<div style="border: 1px solid #000; padding: 5px;flex:none;">
									<label class="control-label" style="width: 100%;">Select Additional Invoice Items:</label>
									<div v-for="item in InvoiceSettings[InvoiceSettingsIndex].Params.InvoiceItems">
										<el-checkbox v-model="item.selected" false-label="0" true-label="1" >{{item.Description}}</el-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>

					<el-button v-if="InvoiceSettingID && (BillingType == 'IU' || LeagueID != '') && BillingDate"  class="mt-4 mb-4 btn-primary" type="primary" @click="getBillings">
						<span v-html="'Generate ' + (BillingType == 'MI' ? 'Billings' : 'Billings')"></span><i class="fa-solid fa-caret-right"></i>
					</el-button>
					<div v-if="BillingType == 'BG' && OrganizationBillings.length > 0 && LeagueID" class="fill-bill mt-4 row">
						<div class="col-12 col-md-6 fullpage">
							<h5>Enter {{config.Params.games}} to Bill</h5>
							<div class="col-12" style="border: 1px solid #000; padding: 5px;">
								<p>Enter a value of {{config.Params.games.toLowerCase()}} to bill for each Team that registered for the number of {{config.Params.games.toLowerCase()}} below.  Click the Apply button when complete.</p>
								<div class="row">
									<div class="col-4 pb-1 pt-1" style="text-align: center; font-weight: 800;padding: 0;border-bottom: 1px solid #000;">
										Reg. {{config.Params.games}}
									</div>
									<div class="col-8 pb-1 pt-1" style="text-align: center; font-weight: 800;padding: 0;border-bottom: 1px solid #000;">
										{{config.Params.games}} to Bill
									</div>
								</div>

								<div v-for="items in DivisionGamesToBill" class="row">
									<div class="col-4 pb-1 pt-1" style="text-align: center; font-weight: 800;border-bottom: 1px dotted #ccc;">
										<span v-html="(items.Games == 0 ? 'All' : items.Games)" ></span>
									</div>
									<div class="col-8 pb-1 pt-1" style="text-align: center; font-weight: 800;border-bottom: 1px dotted #ccc;">
										<el-input v-model="items.ToBill"  class="smallNumber"
										>
										</el-input>
									</div>
								</div>

								<el-button  class="mt-1 mb-1 btn-primary apply" type="primary" @click="fillToBill" style="margin-right: 50px;">
									<span> Apply </span><i class="fa-solid fa-caret-right"></i>
								</el-button>
							</div>
						</div>
						<div class="col-12 col-md-6">
							<h5>- OR - Bill Remaining {{config.Params.games}}</h5>
							<div class="col-12" style="border: 1px solid #000; padding: 5px;">
								<p>Bill Remaining will take the target {{config.Params.games.toLowerCase()}} and based on what has been invoiced to date, will bill for the remaining balance.</p>
								<el-button  class="mb-1 btn-primary" type="primary" @click="billRemaining">
									<span> Bill All Remaining </span><i class="fa-solid fa-caret-right"></i>
								</el-button>
							</div>
							<br><br>
							<h5>- OR - Reconciliation</h5>
							<div class="col-12" style="border: 1px solid #000; padding: 5px;">
							<p>End of Season Reconciliation will take the balance of what teams owe based on the {{config.Params.games.toLowerCase()}} rates on their {{config.Params.games.toLowerCase()}} and then compare to the number of {{config.Params.games.toLowerCase()}} the team was billed.  The difference or balance will be calculcated.</p>
							<el-button  class="mb-1 btn-primary" type="primary" @click="billBalance">
								<span> Calc Reconciliation </span><i class="fa-solid fa-caret-right"></i>
							</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-form>
		<div v-if="noBillings" class="noBillings">There are no Billings to Invoice for these Parameters</div>
		<div v-if="OrganizationBillings.length > 0 && BillingType == 'MI'" class="row mt-4">
			<div class="mb-4 col-12">
				<div class="byGameHeader col-12">
                    <el-checkbox v-model="allSelected" false-label="0" true-label="1" @change="selectAll()">SELECT ALL ORGANIZATIONS FOR INVOICING</el-checkbox>
                </div>
				<template v-for="(Organization, index) in OrganizationBillings">
                    <el-checkbox v-model="Organization.selected" false-label="0" true-label="1" ><h4>{{Organization.OrganizationName}}</h4></el-checkbox>
					<div class="row">
						<div class="col-1 manualHeader center"></div>
						<div class="col-4 manualHeader">Organization/Team</div>
						<div class="col-4 manualHeader">Description</div>
						<div class="col-2 manualHeader">Amount</div>
						<div class="col-1 manualHeader"></div>
					</div>
					<template v-for="(billingitem, index2) in Organization.Billings ">
						<div class="row">
							<template v-if="billingitem.EventType == 'Manual'" >
								<div class="col-1 manualItem center"><el-checkbox type="primary" v-model="billingitem.selected" false-label="0" true-label="1"/></div>
								<div class="col-4 manualItem" >	
									<el-select v-model="billingitem.TeamID" placeholder="Select" style="width:95%;" default-first-option filterable>
										<el-option
												v-for="item in Teams[Organization.OrganizationID]"
												:key="item.TeamID"
												:label="item.TeamName"
												:value="item.TeamID">
										</el-option>
									</el-select>
								</div>
								<div class="col-4 manualItem"><el-input v-model="billingitem.Description" autosize type="textarea" style="width:95%;" :disabled="billingitem.TeamID == '' " /></div>
								<div class="col-2 manualItem center">
									<el-input style="width: 100%; text-align:center;" v-model="billingitem.InvoiceAmount" @change="recalcbillings(index)" :disabled="billingitem.TeamID == '' ">
										<template #prepend><span v-html="currencySign"></span></template>
									</el-input>
								</div>
								<div class="col-1 manualItem center">
									<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteInvoiceItem(index, index2)">
										<div class="v-btn__content">
											<i class="far fa-2x fa-trash-alt"></i>
										</div>
									</button>
								</div>
							</template>
							<template v-else>
								<div class="col-1 manualItem center autogenerated"><el-checkbox type="primary" v-model="billingitem.selected" @change="recalcbillings(index)" false-label="0" true-label="1"/></div>
								<div class="col-4 manualItem autogenerated" v-html="billingitem.TeamName" ></div>
								<div class="col-4 manualItem autogenerated" v-html="billingitem.Description" ></div>
								<div class="col-2 manualItem center autogenerated" v-html="formatPrice(billingitem.InvoiceAmount)"></div>
								<div class="col-1 manualItem center"></div>
							</template>
						</div>
					</template>
					<div class="row">
						<div class="col-1 manualFooter center autogenerated"></div>
						<div class="col-4 manualFooter autogenerated"></div>
						<div class="col-4 manualFooter autogenerated"></div>
						<div class="col-2 manualFooter center autogenerated" v-html="formatPrice(Organization.InvoiceTotal)"></div>
						<div class="col-1 manualFooter center"></div>

					</div>
					<el-button v-if="createAccess" class="mt-2" @click.prevent="addInvoiceItem(index)"><i class="fa-solid fa-circle-plus"></i> Add Invoice Item</el-button>
				</template>
			</div>
		</div>
		<div v-if="OrganizationBillings.length > 0 && BillingType == 'IU'" class="row mt-4">
			<div class="mb-4 col-12">
				<el-table
						:data="OrganizationBillings"
						max-height="500"
						size="small"
						key="table_ui"
						stripe border
                        @select-all="selectAll"
						style="width: 100%">
					<el-table-column
							prop="selected"
							type="selection"
							align="center"
							width="55">
						<template #default="scope">
							<span v-if="scope.row.Invoiced == 'n'" :style="'padding-top: 6px;'">
								<el-checkbox type="primary" v-model="scope.row.selected"/>
							</span>
						</template>
					</el-table-column>
					<el-table-column type="expand">
						<template #default="props">
							<div class="row detailheader">
								<div class="col-2">Date</div>
								<div class="col-2">Time</div>
								<div class="col-6">Description</div>
								<div class="col-1">Hourly</div>
								<div class="col-1">Amount</div>
							</div>
							<div class="row" v-for="(item, index) in props.row.Billings" :class="{even : index % 2 == 0}">
								<div style="line-height: 35px;" class="col-2">{{item.StartDate}}</div>
								<div style="line-height: 35px;" class="col-2" v-html="(item.type != 'e' ? '' : (item.StartTime ? item.StartTime + ' - ' + item.EndTime : 'All Day'))"></div>
								<div style="line-height: 35px;" class="col-6">{{(item.type == 'c' ? 'Credit - ' : '') + item.Title + (item.LocationName ? ' - ' + item.LocationName : '')}}</div>
								<div style="line-height: 35px;" class="col-1" v-html="(item.HourlyRate ? formatPrice(item.HourlyRate) : '')"></div>
								<div style="line-height: 35px;" class="col-1" v-html="formatPrice((item.EventType == 'Balance' ? item.Balance : item.RinkFee))"></div>
							</div>
						</template>
					</el-table-column>
					<el-table-column
							type="index"
							align="center"
							min-width="40"
					>
					</el-table-column>
					<el-table-column
							prop="OrganizationName"
							label="Organization"
							align="left"
							min-width="150"
							sortable
					>
					</el-table-column>
					<el-table-column
							prop="TotalItems"
							label="Total Items"
							align="center"
							min-width="130"
					>
					</el-table-column>
					<el-table-column
							prop="TotalFee"
							label="Total Amount"
							align="right"
							min-width="130"
					>
						<template #default="scope">
							<div v-html="formatPrice(scope.row.TotalFee)"></div>
						</template>
					</el-table-column>
					<el-table-column
							prop="Invoiced"
							label="Status"
							align="right"
							min-width="130"
							sortable
					>
						<template #default="scope">
							<div v-html="(scope.row.Invoiced == 'n' ? 'Not Invoiced' : (scope.row.InvoiceStatus == 'Pending' ? 'Invoiced - Not Paid' : 'Invoiced - Paid'))"></div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div v-if="OrganizationBillings.length > 0 && BillingType == 'TR'" class="row">
			<div class="mb-4 col-12 BillingTable">
				<el-table
						:data="OrganizationBillings"
						size="small"
						key="table_tr"
                        @select-all="selectAll"
						stripe border
						style="width: 100%">
					<el-table-column
							prop="selected"
							type="selection"
							align="center"
							width="55">
						<template #default="scope">
							<span v-if="scope.row.TeamsBilled > 0" :style="'padding-top: 6px;'">
								<el-checkbox type="primary" v-model="scope.row.selected"/>
							</span>
						</template>
					</el-table-column>
					<el-table-column type="expand">
						<template #default="props">
							<div class="row detailheader">
								<div class="col-4">Team</div>
								<div class="col-2">{{config.Params.games}}</div>
								<div class="col-3">Fee</div>
								<div class="col-3">Status</div>
							</div>
							<div class="row" v-for="(item, index) in props.row.Billings" :class="{even : index % 2 == 0}">
								<div style="line-height: 35px;" class="col-4">{{item.TeamName + (item.recordstatus == 'I' ? ' (Dropped)':'')}}</div>
								<div style="line-height: 35px;" class="col-2" v-html="(item.RegisteredGames == 0 ? '' : item.RegisteredGames)"></div>
								<div style="line-height: 35px;" class="col-3" v-html="formatPrice(item.RegFee)"></div>
								<div style="line-height: 35px;" class="col-3">{{item.InvoiceStatus}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column
							type="index"
							align="center"
							min-width="40"
					>
					</el-table-column>
					<el-table-column
							prop="OrganizationName"
							label="Organization"
							align="left"
							min-width="150"
							sortable
					>
						<template #default="scope">
							<div v-html="scope.row.OrganizationName"></div>
						</template>
					</el-table-column>
					<el-table-column
							prop="TotalItems"
							label="Teams to Invoice"
							align="center"
							min-width="130"
					>
					</el-table-column>
					<el-table-column
							prop="
"
							label="Total Fees"
							align="right"
							min-width="130"
					>
						<template #default="scope">
							<div v-html="formatPrice(scope.row.TotalFee)"></div>
						</template>
					</el-table-column>
					<el-table-column
							prop="Invoiced"
							label="Status"
							align="right"
							min-width="130"
							sortable
					>
						<template #default="scope">
							<div v-html="(scope.row.TeamsBilled == 0 || scope.row.Invoiced == 'y'  ? 'Invoiced' : (scope.row.TeamsBilled > 0 ? 'Not Invoiced' : 'Invoice Not Reqd'))"></div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div v-if="OrganizationBillings.length > 0 && BillingType == 'BG'" class="el-table row mt-4 mb-4 billingtables">
            <div v-if="OrganizationBillings.length > 0" class="mt-4" style="width: 100%;">
                <el-radio-group v-model="showDetailsToggle"  @change="changeView">
                    <el-radio-button selected label="y">Show Full Details</el-radio-button>
                    <el-radio-button label="n">Show Summaries Only</el-radio-button>
                </el-radio-group>
                <el-radio-group v-model="hideZerosToggle"  style="float:right;">
                    <el-radio-button selected label="y">Hide Teams with Zero Balance</el-radio-button>
                    <el-radio-button label="n">Show All</el-radio-button>
                </el-radio-group>
            </div>
            <template v-if="showFullDetails == 'y'" >
                <div class="byGameHeader col-12 bg-primary">
                    <el-checkbox v-model="allSelected" false-label="0" true-label="1" @change="selectAll()"></el-checkbox>
                </div>
	            <template v-for="(Organization, index) in OrganizationBillings">
	                <div v-if="billingview == 'YE' || (billingview == 'R' && (hideZerosToggle == 'n' || (hideZerosToggle == 'y' && (Organization.AmountToBill)) ))" class="mb-4 col-12 BillingTable" >
	                    <el-checkbox v-model="Organization.selected" false-label="0" true-label="1" ><h4>{{Organization.OrganizationName}}</h4></el-checkbox>
	                    <el-table
	                            :data="organizationalSummaries(Organization)"
	                            show-summary
	                            :summary-method="getSummaries"
	                            size="small"
	                            :key="'table_'+index"
	                            :row-class-name="tableRowClassName"
	                            stripe border
	                            style="width: 100%">
	                        <el-table-column
	                                type="index"
	                                align="center"
	                                min-width="40"
	                                fixed
	                        >
	                        </el-table-column>
	                        <el-table-column
	                                prop="TeamName"
	                                label="Team"
	                                align="left"
	                                min-width="200"
	                                fixed
	                        >
	                        <template #default="scope">
	                          <div v-html="scope.row.TeamName + (scope.row.recordstatus == 'I' ? ' (Dropped)':'')"></div>
	                        </template>
	                        </el-table-column>
	                        <el-table-column :label="config.Params.games" align="center">
	                        <el-table-column
	                              prop="RegisteredGames"
	                              label="Regd"
	                              align="center"
	                              width="80"
	                        >
	                        </el-table-column>
	                        <el-table-column
	                              prop="TargetGames"
	                              label="Target"
	                              align="center"
	                              width="80"
	                        >
	                        </el-table-column>
	                            <el-table-column
	                                    prop="Games"
	                                    label="Actual"
	                                    align="center"
	                                    width="80"
	                            >
	                            </el-table-column>
	                            <el-table-column
	                                    prop="Billed"
	                                    label="Billed"
	                                    align="center"
	                                    width="80"
	                            >
	                            </el-table-column>
	                            <el-table-column
	                                    prop="Outstanding"
	                                    label="Balance"
	                                    align="center"
	                                    width="90"
	                            >
	                            </el-table-column>
	                            <el-table-column
	                                    prop="ToBill"
	                                    align="center"
	                                    label="To Bill"
	                                    width="100"
	                            >
	                                <template #default="scope">
	                                    <span v-if="scope.row.SingleBill == 'y' || (scope.row.EventType && scope.row.EventType == 'Balance')">{{(scope.row.AmountToBill == 0 || (scope.row.EventType && scope.row.EventType == 'Balance') ? scope.row.ToBill : 'ALL')}}</span>
	                                    <el-input v-else  v-model="scope.row.ToBill" @change="calcToBill(Organization.OrganizationID)"></el-input>
	                                </template>
	                            </el-table-column>
	                        </el-table-column>
	                        <el-table-column label="Amounts" align="center">
	                            <el-table-column
	                                    prop="DefaultGameFee"
	                                    align="center"
	                                    :label="config.Params.game + ' Rate'"
	                                    width="120"
	                            >
	                                <template #default="scope">
	                                    <span v-if="scope.row.SingleBill == 'y' || (scope.row.EventType && scope.row.EventType == 'Balance')"></span>
	                                    <div v-else v-html="formatPrice(scope.row.DefaultGameFee)"></div>
	                                </template>
	                            </el-table-column>
	                            <el-table-column
	                                    prop="AmountToBill"
	                                    label="Bill Now"
	                                    width="120"
	                                    align="right"
	                            >
	                                <template #default="scope">
	                                    <div v-html="formatPrice(scope.row.AmountToBill)"></div>
	                                </template>
	                            </el-table-column>
	                            <el-table-column
	                                    prop="AmountBilled"
	                                    label="Prev Billed"
	                                    width="120"
	                                    align="right"
	                            >
	                                <template #default="scope">
	                                    <template v-if="!scope.row.EventType">
	                                        <div v-html="formatPrice(scope.row.AmountBilled)"></div>
	                                    </template>
	                                </template>
	                            </el-table-column>
	                            <el-table-column
	                                    prop="DetailAmount"
										:label="'By ' + config.Params.game"
	                                    min-width="100"
	                                    align="right"
	                            >
	                                <template #default="scope">
	                                    <template v-if="!scope.row.EventType">
	                                        <div v-html="formatPrice(scope.row.DetailAmount)"></div>
	                                    </template>
	                                </template>
	                            </el-table-column>
	                            <el-table-column
	                                    prop="Balance"
	                                    label="Y/E Balance"
	                                    min-width="100"
	                                    align="right"
	                            >
	                                <template #default="scope">
	                                    <div :class="{'negative' : scope.row.Balance < 0}" v-html="formatPrice(scope.row.Balance)"></div>
	                                </template>
	                            </el-table-column>
	                        </el-table-column>
	                    </el-table>
	                </div>
	            </template>
            </template>
            <template v-else-if="showFullDetails == 'm'" >
                <div class="card">
                    ** Please be Patient, it takes a little bit to display all the details **
                </div>
            </template>
            <template v-else>
                <el-table
                    :data="fullDetailsRecords"
                    show-summary
                    :summary-method="getSummariesAll"
                    size="small"
                    key="table_BG_Summary"
                    @select-all="selectAll"
                    stripe border
                    style="width: 100%">
                    <el-table-column
                        prop="selected"
                        type="selection"
                        align="center"
                        width="55">
                        <template #default="scope">
							<span :style="'padding-top: 6px;'">
								<el-checkbox type="primary" v-model="scope.row.selected"/>
							</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        type="index"
                        align="center"
                        min-width="40"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="OrganizationName"
                        label="Organization"
                        align="left"
                        min-width="150"
                        sortable
                    >
                        <template #default="scope">
                            <a href="javascript:;" @click="showDetails(scope.row.OrganizationID, scope.$index)"><span v-html="scope.row.OrganizationName"></span></a>
                        </template>
                    </el-table-column>
                    <el-table-column :label="config.Params.games" align="center">
                        <el-table-column
                            prop="RegisteredGames"
                            label="Regd"
                            align="center"
                            width="80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="TargetGames"
                            label="Target"
                            align="center"
                            width="80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Games"
                            label="Actual"
                            align="center"
                            width="80"
                        >
                            <template #default="scope">
                                <div v-html="(billingview == 'YE' ? scope.row.Games : scope.row.Games - scope.row.GamesF)"></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Billed"
                            label="Billed"
                            align="center"
                            width="80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Outstanding"
                            label="Balance"
                            align="center"
                            width="90"
                        >
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="Amounts" align="center">
                        <el-table-column
                            prop="AmountToBill"
                            label="Bill Now"
                            width="120"
                            align="right"
                        >
                            <template #default="scope">
                                <div v-html="formatPrice(scope.row.AmountToBill)"></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="AmountBilled"
                            label="Prev Billed"
                            width="120"
                            align="right"
                        >
                            <template #default="scope">
                                <template v-if="!scope.row.EventType">
                                    <div v-html="formatPrice(scope.row.AmountBilled)"></div>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="DetailAmount"
                            :label="'By ' + config.Params.game"
                            min-width="100"
                            align="right"
                        >
                            <template #default="scope">
                                <template v-if="!scope.row.EventType">
                                    <div v-html="formatPrice(scope.row.DetailAmount)"></div>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Balance"
                            label="Y/E Balance"
                            min-width="100"
                            align="right"
                        >
                            <template #default="scope">
                                <div :class="{'negative' : scope.row.Balance < 0}" v-html="formatPrice(scope.row.Balance)"></div>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
			</template>
		</div>
        <modal v-if="showModal" :showMask="false" @close="showModal = false" width="90%" :top="modalTop">
	        <template v-slot:header>
		        <h5 v-if="OrganizationBillings[DetailIdx]" class="modal-title">
			        Organization Billing Details - {{OrganizationBillings[DetailIdx].OrganizationName}}
		        </h5>
	        </template>
	        <template v-slot:body >
	            <div class="BillingTable" >
	                <el-table v-if="OrganizationBillings[DetailIdx]"
	                    :data="OrganizationBillings[DetailIdx].Billings.filter(data => billingview == 'YE' || (billingview == 'R' && data.SingleBill != 'FW' && data.SingleBill != 'FL'))"
	                    show-summary
	                    :summary-method="getSummaries"
	                    size="small"
	                    key="table_detailskey"
	                    ref="table_details"
	                    :row-class-name="tableRowClassName"
	                    stripe border
	                    style="width: 100%">
	                    <el-table-column
	                        type="index"
	                        align="center"
	                        min-width="40"
	                        fixed
	                    >
	                    </el-table-column>
	                    <el-table-column
	                        prop="TeamName"
	                        label="Team"
	                        align="left"
	                        min-width="200"
	                        fixed
	                    >
	                        <template #default="scope">
	                            <div v-html="scope.row.TeamName + (scope.row.recordstatus == 'I' ? ' (Dropped)':'')"></div>
	                        </template>
	                    </el-table-column>
	                    <el-table-column :label="config.Params.games" align="center">
	                        <el-table-column
	                            prop="RegisteredGames"
	                            label="Regd"
	                            align="center"
	                            width="80"
	                        >
	                        </el-table-column>
	                        <el-table-column
	                            prop="TargetGames"
	                            label="Target"
	                            align="center"
	                            width="80"
	                        >
	                        </el-table-column>
	                        <el-table-column
	                            prop="Games"
	                            label="Actual"
	                            align="center"
	                            width="80"
	                        >
	                        </el-table-column>
	                        <el-table-column
	                            prop="Billed"
	                            label="Billed"
	                            align="center"
	                            width="80"
	                        >
	                        </el-table-column>
	                        <el-table-column
	                            prop="Outstanding"
	                            label="Balance"
	                            align="center"
	                            width="90"
	                        >
	                        </el-table-column>
	                        <el-table-column
	                            prop="ToBill"
	                            align="center"
	                            label="To Bill"
	                            width="100"
	                        >
	                            <template #default="scope">
	                                <span v-if="scope.row.SingleBill == 'y' || (scope.row.EventType && scope.row.EventType == 'Balance')">{{(scope.row.AmountToBill == 0 || (scope.row.EventType && scope.row.EventType == 'Balance') ? '' : 'ALL')}}</span>
	                                <el-input v-else  v-model="scope.row.ToBill" @change="calcToBill(Organization.OrganizationID)"></el-input>
	                            </template>
	                        </el-table-column>
	                    </el-table-column>
	                    <el-table-column label="Amounts" align="center">
	                        <el-table-column
	                            prop="DefaultGameFee"
	                            align="center"
	                            :label="config.Params.game + ' Rate'"
	                            width="120"
	                        >
	                            <template #default="scope">
	                                <span v-if="scope.row.SingleBill == 'y' || (scope.row.EventType && scope.row.EventType == 'Balance')"></span>
	                                <div v-else v-html="formatPrice(scope.row.DefaultGameFee)"></div>
	                            </template>
	                        </el-table-column>
	                        <el-table-column
	                            prop="AmountToBill"
	                            label="Bill Now"
	                            width="120"
	                            align="right"
	                        >
	                            <template #default="scope">
	                                <div v-html="formatPrice(scope.row.AmountToBill)"></div>
	                            </template>
	                        </el-table-column>
	                        <el-table-column
	                            prop="AmountBilled"
	                            label="Prev Billed"
	                            width="120"
	                            align="right"
	                        >
	                            <template #default="scope">
	                                <template v-if="!scope.row.EventType">
	                                    <div v-html="formatPrice(scope.row.AmountBilled)"></div>
	                                </template>
	                            </template>
	                        </el-table-column>
	                        <el-table-column
	                            prop="DetailAmount"
	                            :label="'By ' + config.Params.game"
	                            min-width="100"
	                            align="right"
	                        >
	                            <template #default="scope">
	                                <template v-if="!scope.row.EventType">
	                                    <div v-html="formatPrice(scope.row.DetailAmount)"></div>
	                                </template>
	                            </template>
	                        </el-table-column>
	                        <el-table-column
	                            prop="Balance"
	                            label="Y/E Balance"
	                            min-width="100"
	                            align="right"
	                        >
	                            <template #default="scope">
	                                <div :class="{'negative' : scope.row.Balance < 0}" v-html="formatPrice(scope.row.Balance)"></div>
	                            </template>
	                        </el-table-column>
	                    </el-table-column>
	                </el-table>
	            </div>
	        </template>
	        <template v-slot:footer>
		        &nbsp;
	        </template>
        </modal>
		<div class="row mt-4 mb-4" v-if="(modifyAccess || createAccess) && InvoicingAvailable()" >
			<div class="col-12 text-center">
				<button class="btn btn-success btn-icon" type="button" @click="generateInvoice('n')">
					<i class="fas fa-plus-square"></i> <span> Generate Invoice(s) </span>
				</button>
				<button class="btn btn-warning btn-icon" type="button" @click="generateInvoice('y')">
					<i class="far fa-envelope"></i> <span> Generate & Email Invoice(s) </span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
    import Modal from './global/Modal.vue';
    import Profile from "./Profile";
    import Util from "../assets/js/util";
	import EmailTemplate from './global/EmailTemplate.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'Billing',
        components: {
            Modal,
			EmailTemplate
        },
	    data() {
	        return {
                loading: false,
                currentStatus : STATUS_INITIAL,
                showModal: false,
                BillingType : '',
                Programs : [],
                Organizations : [],
                LeagueOrganizations: [],
                RegisteredLeagueOrganizations : [],
                Leagues : [],
                ActiveSeasons : [],
                InvoiceSettings : [],
                InvoiceSettingsIndex : '',
                AllowedInvoiceSettings : [],
                LeagueID : '',
                SeasonID : '',
                ProgramID : '',
                OrganizationID : '',
                InvoiceSettingID : '',
                OrganizationBillings : [],
                BillingDate : '',
                Invoiced : '',
                InvoiceStatus : '',
                ToBill : '',
                allSelected: 0,
                SystemText : '',
                CustomEmailsGrouping : [],
                DivisionTypes : [],
                DivisionGamesToBill :[{Games:0,ToBill:0}],
                noBillings : false,
                BillingDescription : '',
                showFullDetails : 'n',
                showDetailsToggle : 'n',
                hideZerosToggle : 'n',
                DetailIdx : 0,
                modalTop : "650px",
                billingview : 'R',
                search : '',
                BillingDivision : 'T',
				Teams : [],
				EmailStatements : 'n',
	        }
	    },
	    props:["config", "parentrefs"],
	    computed: {
	        ...mapGetters({ currentUser: 'currentUser' }),
		    ...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        createAccess () {
	            return this.$createAccess();
	        },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            TeamIDAccess () {
                return this.$TeamIDAccess();
            },
            seasonManagement () {
                if (this.InvoiceSettingID) {
                    for (let i = 0; i <  this.InvoiceSettings.length; i++)
                    {
                        if (this.InvoiceSettings[i].InvoiceSettingID == this.InvoiceSettingID) {
                            if (this.InvoiceSettings[i].Params.BillingSeasons == 'o') {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            BillingLeagues () {
	            var leagues = [];
                for (let i = 0; i <  this.Leagues.length; i++) {
                    if (this.Leagues[i].Params && this.Leagues[i].Params.LeagueBilling == 'p') {
                        leagues.push(this.Leagues[i]);
                    }
                }
                return leagues;
            },

		    fullDetailsRecords () {
				let self = this;
			    var newArray = this.OrganizationBillings.filter(function (data) {
				    return self.billingview == 'YE' ||
					    (self.billingview == 'R' &&
						    (self.hideZerosToggle == 'n' || (self.hideZerosToggle == 'y' && data.AmountToBill)));
			    });
			    return newArray;
		    },
			currencySign () {
                return Util.currencySign(this.config);
            },
	    },
		methods: {
			organizationalSummaries (Organization) {
				let self = this;
				var newArray = Organization.Billings.filter(function (data) {
					return self.billingview == 'YE' ||
						(self.billingview == 'R' && data.SingleBill != 'FW' && data.SingleBill != 'FL' && (self.hideZerosToggle == 'n' || (self.hideZerosToggle == 'y' && (data.Outstanding || data.ToBill))));
				});
				return newArray;
			},
			addInvoiceItem(index) {
				this.OrganizationBillings[index].Billings.push({TeamID : '', Description : '', InvoiceAmount : '', selected : "1", EventType : 'Manual'});
			},
			deleteInvoiceItem(index, index2) {
				this.OrganizationBillings[index].Billings.splice(index2, 1);
				this.recalcbillings (index);
			},
			recalcbillings (index) { 
				this.OrganizationBillings[index].InvoiceTotal = 0;
				for (let i=0; i < this.OrganizationBillings[index].Billings.length; i++) {
					if (this.OrganizationBillings[index].Billings[i].selected == '1') {
						this.OrganizationBillings[index].InvoiceTotal -= -(isNaN(this.OrganizationBillings[index].Billings[i].InvoiceAmount) ? 0 : this.OrganizationBillings[index].Billings[i].InvoiceAmount);
					}
				}
			},
			detailRecord (index) {
				let self = this;
				var newArray = this.OrganizationBillings[index].Billings.filter(function (data) {
					return self.billingview == 'YE' ||
						(self.billingview == 'R' && data.SingleBill != 'FW' && data.SingleBill != 'FL');
				});
				return newArray;
			},
            showDetails(OrganizationID, idx) {
                for (let i=0; i < this.OrganizationBillings.length; i++) {
                    if (this.OrganizationBillings[i]['OrganizationID'] == OrganizationID) {
                        this.DetailIdx = i;
                        break;
                    }
                }

                this.showModal = true;
            },
            changeView() {

                this.showFullDetails = 'm';
                let self = this;
                setTimeout(() => { self.showFullDetails = self.showDetailsToggle; }, 250);

            },
            changeZeros() {

                this.showFullDetails = 'm';
                let self = this;
                setTimeout(() => { self.showFullDetails = self.showDetailsToggle; }, 250);

            },
            tableRowClassName({row, rowIndex}) {
		        if (row['SingleBill'] == 'FW' || row['SingleBill'] == 'FL') {
		            if (this.billingview == 'R') {
		                return 'hide-row';
                    } else {
                        return 'forfeit-row';
                    }
                } else if (row['TargetGames'] != row['RegisteredGames'] && row['recordstatus'] == 'A') {
                    return 'warning-row';
                } else {
                    return '';
                }
            },
			selectAll (val) {
                if (val !== undefined) {
                    var selected = false;
                    if (val && val.length > 0) {
                        selected = true;
                    }
                    for (let i=0; i < this.OrganizationBillings.length; i++) {
                        this.OrganizationBillings[i].selected = selected;
                    }
                } else {
                    for (let i = 0; i < this.OrganizationBillings.length; i++) {
                        this.OrganizationBillings[i].selected = this.allSelected;
                    }
                }
			},
			formatPrice(value) {
                return Util.formatPrice(this.config, value , '0');
			},
			InvoicingAvailable () {

				if (this.OrganizationBillings) {
					for (let i = 0; i < this.OrganizationBillings.length; i++) {
						if (this.OrganizationBillings[i]['selected'] == '1') {
							return true;
						}
					}
				}

				return false;
			},
			showAdditionalInvoiceItems () {
				for (let i = 0; i < this.InvoiceSettings.length; i++) {
					if (this.InvoiceSettings[i].InvoiceSettingID == this.InvoiceSettingID) {
						this.InvoiceSettingsIndex = i;
						return;
					}
				}
				this.InvoiceSettingsIndex = '';
			},
			generateDivisionGames () {
				this.DivisionGamesToBill = [];
				let games = [];
				for (let i = 0; i < this.DivisionTypes.length; i++) {
					if (this.DivisionTypes[i].Params.Amounts) {
						for (let j = 0; j < this.DivisionTypes[i].Params.Amounts.length; j++) {
							if (this.DivisionTypes[i].Params.Amounts[j].Games && games.indexOf(this.DivisionTypes[i].Params.Amounts[j].Games) === -1 && (this.DivisionTypes[i].Params.Amounts[j].SingleFee == '' || this.DivisionTypes[i].Params.Amounts[j].SingleFee == '0')) {
								games.push(this.DivisionTypes[i].Params.Amounts[j].Games);
							}
						}
					}
				}
				games.sort();
				if (games.length == 0) {
					this.DivisionGamesToBill = [{Games:0,ToBill:0}];
				} else {
					for (let i = 0; i < games.length; i++) {
						this.DivisionGamesToBill.push({Games: games[i], ToBill: 0});
					}
				}
			},
			fillToBill () {
                this.loading = true;
                this.billingview = 'R';
				for (let i = 0; i < this.OrganizationBillings.length; i++) {
					for (let j = 0; j < this.OrganizationBillings[i].Billings.length; j++) {
						if (this.OrganizationBillings[i].Billings[j].SingleBill == 'n' && !this.OrganizationBillings[i].Billings[j].EventType && this.OrganizationBillings[i].Billings[j].recordstatus == 'A' ) {
							for (let k = 0; k < this.DivisionGamesToBill.length; k++) {
								if (this.DivisionGamesToBill[k].Games == this.OrganizationBillings[i].Billings[j].RegisteredGames) {
									if (this.OrganizationBillings[i].Billings[j].RegisteredGames - this.OrganizationBillings[i].Billings[j].Billed < this.DivisionGamesToBill[k].ToBill) {
										this.OrganizationBillings[i].Billings[j].ToBill = this.OrganizationBillings[i].Billings[j].RegisteredGames - this.OrganizationBillings[i].Billings[j].Billed;
									} else {
										this.OrganizationBillings[i].Billings[j].ToBill = this.DivisionGamesToBill[k].ToBill;
									}
								}
							}

						}
					}
				}
				this.$store.dispatch('setID', {'ToBill': this.ToBill});
				this.calcToBill();
                this.loading = false;
			},
			billBalance () {
                this.loading = true;
                this.billingview = 'YE';
				for (let i = 0; i < this.OrganizationBillings.length; i++) {
					for (let j = 0; j < this.OrganizationBillings[i].Billings.length; j++) {
						if (this.OrganizationBillings[i].Billings[j].SingleBill != 'y' && !this.OrganizationBillings[i].Billings[j].EventType) {
                            var billamount = this.OrganizationBillings[i].Billings[j].DetailAmount - this.OrganizationBillings[i].Billings[j].AmountBilled;
                            var tobill = this.OrganizationBillings[i].Billings[j].Games - this.OrganizationBillings[i].Billings[j].Billed;
							if (tobill == 0) {
								this.OrganizationBillings[i].Billings[j].DefaultGameFee = 0;
							} else {
                            	this.OrganizationBillings[i].Billings[j].DefaultGameFee = Math.round(billamount * 100 / tobill) / 100;
							}
                            this.OrganizationBillings[i].Billings[j].ToBill = tobill;
                        } else if (this.OrganizationBillings[i].Billings[j].EventType =='Credit') {
                            this.OrganizationBillings[i].Billings[j].ToBill = 1;
						} else if (this.OrganizationBillings[i].Billings[j].SingleBill != 'B') {
                            this.OrganizationBillings[i].Billings[j].ToBill = 0;
                        }
					}
				}
				this.calcToBill();
                this.loading = false;
			},
            billRemaining () {
                this.loading = true;
                this.billingview = 'R';
                for (let i = 0; i < this.OrganizationBillings.length; i++) {
                    for (let j = 0; j < this.OrganizationBillings[i].Billings.length; j++) {
                        if (this.OrganizationBillings[i].Billings[j].SingleBill =='n' && !this.OrganizationBillings[i].Billings[j].EventType) {
                            if (this.OrganizationBillings[i].Billings[j].TargetGames - this.OrganizationBillings[i].Billings[j].Billed > 0 && this.OrganizationBillings[i].Billings[j].recordstatus == 'A') {
                                this.OrganizationBillings[i].Billings[j].ToBill = this.OrganizationBillings[i].Billings[j].TargetGames - this.OrganizationBillings[i].Billings[j].Billed;
                            } else {
                                this.OrganizationBillings[i].Billings[j].ToBill = 0;
                            }
                        } else if (this.OrganizationBillings[i].Billings[j].SingleBill != 'B') {
                            this.OrganizationBillings[i].Billings[j].ToBill = 0;
                        }
                    }
                }
                this.calcToBill();
                this.loading = false;
            },
			calcToBill (OrganizationID) {
				for (let i = 0; i < this.OrganizationBillings.length; i++) {
                    this.OrganizationBillings[i].AmountToBill = 0;
                    this.OrganizationBillings[i].Balance = 0;
				    if (!OrganizationID || OrganizationID == this.OrganizationBillings[i]['OrganizationID']) {
                        for (let j = 0; j < this.OrganizationBillings[i].Billings.length; j++) {
                            if (this.OrganizationBillings[i].Billings[j].SingleBill != 'y') {
                                if (this.OrganizationBillings[i].Billings[j].EventType && this.OrganizationBillings[i].Billings[j].EventType == 'Balance') { }  else {
                                    this.OrganizationBillings[i].Billings[j].AmountToBill = (isNaN(this.OrganizationBillings[i].Billings[j].ToBill) || !this.OrganizationBillings[i].Billings[j].ToBill ? 0 : parseInt(this.OrganizationBillings[i].Billings[j].ToBill) * this.OrganizationBillings[i].Billings[j].DefaultGameFee);
                                    this.OrganizationBillings[i].AmountToBill += this.OrganizationBillings[i].Billings[j].AmountToBill;
                                }
                            }
                            if (this.OrganizationBillings[i].Billings[j].EventType) {
                                if (this.OrganizationBillings[i].Billings[j].EventType != 'Balance') {
                                    this.OrganizationBillings[i].Billings[j].Balance = this.OrganizationBillings[i].Billings[j].DefaultGameFee - this.OrganizationBillings[i].Billings[j].AmountToBill;
                                }
                            } else {
                                this.OrganizationBillings[i].Billings[j].Balance = this.OrganizationBillings[i].Billings[j].DetailAmount - this.OrganizationBillings[i].Billings[j].AmountBilled - this.OrganizationBillings[i].Billings[j].AmountToBill;
                            }
                            this.OrganizationBillings[i].Balance += this.OrganizationBillings[i].Billings[j].Balance;
                        }
                    }
				}
			},
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 1) {
						sums[index] = 'Total Billing';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						if ((index >= 9 && this.BillingType == 'BG') || (index >= 3 && this.BillingType == 'TR') ) {
							sums[index] = this.formatPrice(values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0));
						} else {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr);
                                if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0);
						}
					} else {
						sums[index] = '';
					}
				});
				if (sums.length >= 8) {
                  sums[8] = ''
                  sums[7] = sums[7];
                }
				return sums;
			},
            getSummariesAll(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 2) {
                        sums[index] = 'Total Billing';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        if ((index >= 8 && this.BillingType == 'BG') || (index >= 3 && this.BillingType == 'TR') ) {
                            sums[index] = this.formatPrice(values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0));
                        } else {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);
                        }
                    } else {
                        sums[index] = '';
                    }
                });

                return sums;
            },
			billingtypeChange () {
				this.OrganizationBillings = [];
				this.noBillings = false;
				this.InvoiceSettingID = '';

				if (this.BillingType == 'IU') {

				} else if (this.BillingType == 'TR') {
					this.SeasonID = '';
					this.BillingDivision = 'T';
				} else if (this.BillingType == 'BG') {
					if (!this.SeasonID) {
						this.LeagueID = '';
						this.OrganizationID = '';
						this.Leagues = [];
						this.Organizations = [];
					}
				}
				this.getInitialData ();
			},
			seasonChange() {
				this.OrganizationBillings = [];
				this.noBillings = false;
				this.getInitialData();
			},
			leagueChange() {
				this.OrganizationBillings = [];
				this.noBillings = false;
				this.getInitialData();
				this.$store.dispatch('setID', {'BillingOrganizationID': ''});
				if (this.BillingType != 'MI') {
					this.OrganizationID = '';
				}
                for (let i = 0; i <  this.Leagues.length; i++) {
                    if (this.Leagues[i].LeagueID == this.LeagueID) {
                        this.InvoiceSettingID = this.Leagues[i].InvoiceSettingID
                        this.showAdditionalInvoiceItems();
                        break;
                    }
                }
			},
			getInitialData () {
                if (this.ProgramIDAccess) {
                    this.BillingType = 'BG';
                }

				if (this.BillingType == 'IU') {
					this.$store.dispatch('setID', {'BillingSeasonID': ''});
					this.LeagueID = '';
					this.$store.dispatch('setID', {'BillingLeagueID': ''});
				}
                var SeasonID = '';
				if (!this.SeasonID) {
                    if (this.componentIDs.BillingSeasonID) {
                        SeasonID = this.componentIDs.BillingSeasonID;
                    }
                } else {
                    SeasonID = this.SeasonID;
                }
                var LeagueID = '';
				if (!this.LeagueID) {
                    if (this.componentIDs.BillingLeagueID) {
                        LeagueID = this.componentIDs.BillingLeagueID;
                    }
                } else {
                    LeagueID = this.LeagueID;
                }
				var tables = ['Organizations', 'LeagueOrganizations', 'RegisteredLeagueOrganizations', 'InvoiceSettings', 'Leagues', 'ActiveSeasons', 'DivisionTypes'];
				axios.post('/index/tableData', {'tables': tables, LeagueID : LeagueID, SeasonID : SeasonID },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (!this.SeasonID && this.componentIDs.BillingSeasonID) {
                            this.SeasonID = this.componentIDs.BillingSeasonID;
                        }
                        if (!this.LeagueID && this.componentIDs.BillingLeagueID) {
                            this.LeagueID = this.componentIDs.BillingLeagueID;
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
	                this.user = { ...this.currentUser };
                    if (!this.SeasonID) {
                        this.SeasonID = this.ActiveSeasons[0]['SeasonID'];
	                    for (let  i = 0; i < this.ActiveSeasons.length; i++) {
		                    if (this.ActiveSeasons[i]['Params']['DefaultSeason'] == 'y') {
			                    this.SeasonID = this.ActiveSeasons[i]['SeasonID'];
			                    break;
		                    }
	                    }
                    }
	                if (this.componentIDs.BillingOrganizationID) {
		                this.OrganizationID = this.componentIDs.BillingOrganizationID;
	                }
                    if (this.LeagueID) {
                        for (let i = 0; i <  this.Leagues.length; i++) {
                            if (this.Leagues[i].LeagueID == this.LeagueID) {
                                this.InvoiceSettingID = this.Leagues[i].InvoiceSettingID
                                this.showAdditionalInvoiceItems();
                                break;
                            }
                        }
                    }
	                this.generateDivisionGames();
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getBillings() {
                this.billingview = 'R';
				if (this.BillingType == 'IU' || this.LeagueID != '') {
				    this.showModal = false;
					this.OrganizationBillings = [];
					this.noBillings = false;
					let AddlInvoiceItems = [];
					for (let i = 0; i < this.InvoiceSettings.length; i++) {
						if (this.InvoiceSettings[i].InvoiceSettingID == this.InvoiceSettingID) {
							AddlInvoiceItems = this.InvoiceSettings[this.InvoiceSettingsIndex].Params.InvoiceItems;
							break;
						}
					}
					var self = this;
					this.loading = true;
					axios.post('/finance/getbillings', {
                          BillingType: this.BillingType,
                          BillingDate: this.BillingDate,
                          InvoiceSettingID: this.InvoiceSettingID,
                          OrganizationID: this.OrganizationID,
                          SeasonID: this.SeasonID,
                          LeagueID: this.LeagueID,
                          BillingDivision : this.BillingDivision,
                          AddlInvoiceItems : AddlInvoiceItems
						},
					)
                    .then(response => {
                        if (response.data.result == 'success') {
                            self.$store.dispatch('setID', {'BillingDate': self.BillingDate});
                            self.$store.dispatch('setID', {'BillingType': self.BillingType});

                            self.$store.dispatch('setID', {'BillingOrganizationID': self.OrganizationID});
                            self.$store.dispatch('setID', {'BillingSeasonID': self.SeasonID});
                            self.$store.dispatch('setID', {'BillingLeagueID': self.LeagueID});

                            this.OrganizationBillings = (response.data.OrganizationBillings ? response.data.OrganizationBillings : []);
                            if (this.OrganizationBillings.length == 0) {
                                this.noBillings = true;
                            }
							this.Teams = (response.data.Teams ? response.data.Teams : []);
                            this.calcToBill();
                            this.$stopProgressBar(this.parentrefs);
							if (document.querySelector(".apply")) {
								setTimeout(() => { document.querySelector(".apply").scrollIntoView(); }, (this.OrganizationID ? 250 : 2000));
							}
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
					});
				}
			},
			generateInvoice (EmailInvoice) {
				if (EmailInvoice == 'y' && !this.SystemText) {
					this.displayPopup ('An Email Template must be selected');
					return;
				}
					
				let billingsToInvoice = 0;
				let self = this;
				if (this.OrganizationBillings) {
					for (let i = 0; i < this.OrganizationBillings.length; i++) {
						if (this.OrganizationBillings[i]['selected'] == '1') {
							billingsToInvoice++;
						}
					}
					if (EmailInvoice == 'y') {
							this.$prompt('Enter Mass Change passcode in the box below and hit OK to confirm generating ' + billingsToInvoice + ' of ' + this.OrganizationBillings.length + ' Organizations to Invoice and Emailing to contacts on file.', 'Generating Invoices', {
							confirmButtonText: 'OK, Generate & Email',
							cancelButtonText: 'NO, Not Yet',
							type: 'warning',
							customclass: 'generateinvoices',
							dangerouslyUseHTMLString: true,
							inputPattern: new RegExp('^'+window.atob(this.config.Params.MassChangePassCode) + '$'),
							inputErrorMessage: 'Invalid Response'
						}).then(({value}) => {
							self.generateInvoiceCnfm ('y')
						}).catch(() => {

						});
					} else {
						if (billingsToInvoice != this.OrganizationBillings.length) {
							let self = this;
							this.$confirm('You have selcted ' + billingsToInvoice + ' of ' + this.OrganizationBillings.length + ' Organizations to Invoice.  Are you sure?', 'Generating Invoices', {
								distinguishCancelAndClose: true,
								showClose: false,
								confirmButtonText: 'YES, Generate',
								cancelButtonText: 'NO, Not Yet',
								closeOnClickModal: false,
								closeOnPressEscape: false,
								type: 'warning'
							})
								.then(function () {
									self.generateInvoiceCnfm(EmailInvoice);
								})
								.catch(function () {
									return;
								});
						} else {
							this.generateInvoiceCnfm(EmailInvoice);
						}
					}
				}
			},
			generateInvoiceCnfm (EmailInvoice) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
                this.$store.dispatch('setID', {'BillingDescription': this.BillingDescription});
				axios.post('/finance/generateinvoice', { BillingView : this.billingview,  BillingType : this.BillingType, BillingDate : this.BillingDate, OrganizationBillings : this.OrganizationBillings, InvoiceSettingID : this.InvoiceSettingID, EmailInvoice : EmailInvoice, SeasonID : this.SeasonID, LeagueID : this.LeagueID, SystemText : this.SystemText, BillingDescription : this.BillingDescription, EmailStatements : this.EmailStatements}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							this.$stopProgressBar(this.parentrefs);
							this.Invoiced = 'y';
							this.InvoiceStatus = 'Pending';
							this.$confirm((response.data.message ? response.data.message : "Invoice has been created" + (EmailInvoice == 'y' ? " and emailed to the contact on file." : ".")), 'Success', {
								confirmButtonText: 'OK',
								type: 'success',
								showClose: false,
								showCancelButton: false
							});
							//get billings again to reflect new invoice
							this.getBillings();
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});

			},
	        displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
					type: 'error'
                });
            },
            refreshData() {
                this.getInitialData();
            },
		},
		mounted () {

            if (this.componentIDs.BillingDescription) {
                this.BillingDescription = this.componentIDs.BillingDescription;
            }
			if (this.componentIDs.BillingType) {
				this.BillingType = this.componentIDs.BillingType;
			}
			if (this.componentIDs.BillingDate) {
				this.BillingDate = this.componentIDs.BillingDate;
			} else {
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();

				today = yyyy + '-' +mm + '-' + dd;
				this.BillingDate = today;
			}
			if (this.componentIDs.ToBill) {
				this.ToBill = this.componentIDs.ToBill;
			}

			this.getInitialData();
		},
	}
</script>
<style>
	#billings .el-table th {
		font-size: 120%;
		font-weight: 800;
	}
	.billings div.el-table .cell {
		word-break: normal;
	}
	#billings .BillingTable input {
		text-align: center;
	}
    #billings .el-table__row.warning-row, #billings .el-table__row.el-table__row--striped.warning-row, #billings .el-table--striped .el-table__body tr.el-table__row--striped.warning-row td {

        background-color: #FEFFE8;
    }
    #billings .el-table__row.forfeit-row TD, #billings .el-table__row.el-table__row--striped.forfeit-row TD, #billings .el-table--striped .el-table__body tr.el-table__row--striped.forfeit-row td {
        background-color: #FFDED6    !important;
    }
    #billings .el-table__row.hide-row, #billings .el-table__row.el-table__row--striped.hide-row, #billings .el-table--striped .el-table__body tr.el-table__row--striped.hide-row td {
        display: none;
    }
    #billings .el-table__fixed-footer-wrapper tbody td, #billings .el-table__footer-wrapper tbody td, #billings  .el-table__header-wrapper tbody td {
        font-weight: 600;
    }
    #billings .el-table__row.hide-row {
        display:none;
    }
	#app #billings .el-select, #app #billings .el-date-editor.el-input, #app #billings .description.el-input {
		width: 100%;
		max-width: 512px;
	}
	#app #billings .manualItem .el-checkbox span.el-checkbox__inner {
		height: 15px;
		width: 15px;
	}
	#app  #billings .manualItem .el-checkbox.el-checkbox__input.is-checked span.el-checkbox__inner::after, #app .manualItem .el-checkbox span.el-checkbox__inner::after {
		height: 9px;
		width: 4px;
		margin-left: 0px;
		margin-top: 0px;
	}
	#app  #billings .el-input-group__prepend {
		padding-left: 10px;
		padding-right: 10px;
		font-weight: 800;
	}
	#app  #billings .manualItem.center input {
		text-align: center;
	}
</style>
<style scoped>
	#billings .row.buttons {
		margin-top: 20px;
	}
	.options {
		margin: 0px 5px;
	}
	table .el-select, table .el-input {
		width: 100%;
	}
	.el-select {
		width: 300px;
	}
	.month-select .el-select, .month-select .el-input {
		font-weight: 800;
	}
	.fill-bill .el-input {
		font-weight: 800;
		width: 105px;
	}
	.invoiced {
		font-weight: 600;
		font-size: 13px;
	}
	.negative {
		color: var(--el-color-danger);
	}
	input.el-input__inner {
		text-align: center;
	}
	.row.header {
		font-weight: 800;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		height: 50px;
	}
	.footer {
		width: 100%;
		margin: 10px 0px;
	}
	.odd {
		background-color: #efefef;
	}
	.detailheader {
		border-bottom: 1px solid #e0e0e0;
		font-weight: 800;
	}
	.even {
		background-color: #f5f5f5;
	}
	#app h4 {
		margin-bottom: 0;
	}
	.byGameHeader {
		height: 34px;
		line-height: 40px;
		width: 100%;
		margin-bottom: 15px;
	}
	.noBillings {
		font-size: 14px;
		font-weight: 800;
		color: var(--el-color-danger);
		margin: 15px;
		padding: 15px;
		border: 1px solid #000;
		width: auto;
	}
	.generateinvoices .btn-primary .el-message-box__btns {
		background-color: var(--el-color-success);
	}
	.manualHeader {
		font-size: 15px;
		font-weight: 800;
		line-height: 33px;
		color: #ffffff;
		background-color: var(--el-color-primary);
		height: 35px;
		border: 1px solid #ffffff;
	}
	.manualFooter {
		font-size: 15px;
		font-weight: 800;
		line-height: 33px;
		color: var(--el-color-primary);
		background-color: #dfdfdf;
		height: 35px;
		border-top: 1px solid #333333;
		border-bottom: 1px solid #333333;
	}
	.manualItem {
		font-size: 14px;
		line-height: 20px;
		min-height: 43px;
		border-bottom: 1px solid #333333;
		padding-top: 5px;
	}
	.manualItem.autogenerated {
		line-height: 25px;
		min-height: 30px;
	}
	.manualHeader.center, .manualItem.center, .manualFooter.center  {
		text-align: center;
	}
	.manualHeader.right, .manualItem.right  {
		text-align: right;
	}

</style>
