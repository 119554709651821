<template>
	<div id="resetpassword" class="app flex-row  text-center">
		<img class="logo" :src="config.Params.MainLogo"><br>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-10">
					<div class="card-group">
						<div class="card p-4">
							<div class="card-body">
								<h1>{{ pageTitle }}</h1>
								<p class="text-muted">Enter your new and confirm Password to Sign In to your {{ SiteName }} Administrative Account</p>
								<el-form ref="setpasswordForm" label-position="top" :model="user" :rules="rules" label-width="120px" class="text-left">
									<el-form-item
										label="New Password"
                                        prop="NewPassword"
                                    >
	                                    <el-input type="password" v-model="user.NewPassword"></el-input>
	                                </el-form-item>
									<el-form-item
										label="Confirm Password"
                                        prop="CnfmPassword"
                                    >
	                                    <el-input type="password" v-model="user.CnfmPassword"></el-input>
	                                </el-form-item>
	                            </el-form>
							</div>
							<div class="row">
								<div class="col-6">
									<el-button class="btn-success px-4 loginButton" type="success" style="width: 250px;margin-bottom: 10px;" v-on:click="submit()">{{ submitLabel }}</el-button>
								</div>
							</div>
						</div>
						<div class="card text-white bg-primary py-5 d-md-down-none" style="width: 44%;">
							<div class="card-body text-center">
								<div>
									<h2>Instructions</h2>
									<p v-html="passwordMessage"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
export default {
	name: 'ResetPassword',
	data() {
		var validateNewPassword = (rule, value, callback) => {
			if (!value) {
			    callback(new Error('New Password is Required'));
			} else {
				if (this.config.Params.passwordMinLength && value.length < this.config.Params.passwordMinLength) {
					callback(new Error('Minimum Password length is ' +this.config.Params.passwordMinLength));
				} else if (this.config.Params.passwordRequireNumber == 'y' && !value.match(/\d+/g)) {
					callback(new Error('Must contain at least one number'));
				} else if (this.config.Params.passwordRequireUpper == 'y' && !value.match(/[A-Z]+/g)) {
					callback(new Error('Must contain at least one Upper Case Letter'));
				} else if (this.user.CnfmPassword !== '') {
			        this.$refs.setpasswordForm.validateField('CnfmPassword');
				}
				callback();
			}
		};
		var validateCnfmPassword = (rule, value, callback) => {
			if (!value) {
				callback(new Error('Confirm Password is Required'));
			} else if (value !== this.user.NewPassword) {
				callback(new Error('New and Confirm Passwords must match'));
			} else {
				callback();
			}
		};
		return {
			user: {NewPassword: '', CnfmPassword: ''},
            currentStatus : STATUS_INITIAL,
			rules: {
				NewPassword: [
					{ validator: validateNewPassword, trigger: 'blur' }
				],
				CnfmPassword: [
					{ validator: validateCnfmPassword, trigger: 'blur' }
				]
			}
		}
	},
	props:["config", "parentrefs"],
	computed: {
		SiteName() {
			return this.config.Params.SiteName
		},
		passwordMessage () {
			if (this.isActivate) {
				var msg = 'To Activate your account, please set your Password.  You will then be able to login.<br><br>';
			} else {
				var msg = '';
			}
			msg += 'Passwords must be at least ' + this.config.Params.passwordMinLength + ' digits long. ';
			if (this.config.Params.passwordRequireUpper == 'y') {
				msg += 'Passwords must contain at least one UPPER case letter. ';
			}
			if (this.config.Params.passwordRequireNumber == 'y') {
				msg += 'Passwords must contain at least one Number. ';
			}
			return msg;
		},
		isReset () {
			return (this.$route.fullPath.indexOf('reset') !== -1 ? true : false);
		},
		isActivate () {
			return (this.$route.fullPath.indexOf('activate') !== -1 ? true : false);
		},
		pageTitle () {
			if (this.isReset) {
				return 'Reset Password';
			} else {
				return 'Account Activation';
			}
		},
		submitLabel () {
			if (this.isReset) {
				return 'Update Password';
			} else {
				return 'Set Password';
			}
		}
	},
	methods: {
		initialize() {
			this.user.code = this.$route.query.code;
		},
	    submit() {
	        this.$refs['setpasswordForm'].validate((valid) => {
	            if (valid) {
					if (this.currentStatus == STATUS_SAVING) {
						return true;
					}
					this.errors = {};
					this.currentStatus = STATUS_SAVING;
					axios.post('/Login/ResetPassword', {
						ResetUser: this.user, reset: (this.isReset ? 'y' : 'n')
					}).then(response => {
						this.currentStatus = STATUS_INITIAL;
						if (response.data.result == 'success') {
							this.$notify({
								title: 'Success',
								message: "Password has been set, Please login.",
								type: 'success',
								duration: 1500,
								showClose: false
							});
			                let self = this;
			                setTimeout(() => { self.$router.replace('/login'); }, 1500);
		                } else {
							this.displayPopup (response.data.message);
							if (response.data.errors) {
								this.errors = response.data.errors;
							}
		                }
					}).catch(response => {
						this.currentStatus = STATUS_FAILED;
					});
				}
			});
	    },
        displayPopup (message) {
			this.$alert(message, this.pageTitle + ' Error', {
				confirmButtonText: 'OK',
			});
        }
	},
	mounted() {
		this.initialize();
	}
}
</script>
<style>
#app.resetpassword .page-wrapper {
      margin-left: 0px;
      margin-top: 30px;
}
</style>
<style scoped>

@media (max-width: 991.98px) {
	.d-md-down-none {
        display: none!important;
    }
}
#app {
  margin-top: 0px;
}

.logo {
	max-width: 50%;
	max-height: 250px;
}

.card-body p, .card-body h2 {
	color: #fff;
}
.align-items-center {
	text-align:center;
}
</style>
