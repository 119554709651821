<template>
    <div id="teamsreport" v-loading="loading">
        <el-form ref="registrationForm" :label-position="LabelPosition" label-width="80px" >
            <div class="row">
                <div class="col-12">
                    <el-form-item v-if="Programs.length > 1" :label="config.Params.programName + ':'" prop="ProgramID">
                        <el-select v-model="ProgramID" ref="program" placeholder="Select" class="topDropdown" @change="programChange" default-first-option filterable>
                            <el-option
                                v-for="item in Programs"
                                :label="item.ProgramName"
                                :value="item.ProgramID"
                                :key="item.ProgramID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="program mb-2" v-else-if="Programs.length == 1">{{config.Params.programName}}:  <span class="programName">{{Programs[0].ProgramName}}</span></div>
                </div>
            </div>
            <div class="row">
                <div v-if="ProgramID && Programs.length > 0" class="col-12">
                    <el-form-item v-if="Leagues.length > 1" label="League:" prop="LeagueID">
                        <el-select v-model="LeagueID" ref="league" placeholder="Select" class="topDropdown" @change="getDivisionTypes" default-first-option filterable>
                            <template v-for="item in Leagues">
                                <el-option
                                    v-if="item.Params.AllowRegistration == 'y'"
                                    :label="item.LeagueName"
                                    :value="item.LeagueID"
                                    :key="item.LeagueID">
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <div v-else-if="Leagues.length == 1" class="program mb-4">League:  <span class="programName">{{Leagues[0].LeagueName}}</span></div>
                    <div v-else class="program">League:  <span class="leagueName">No Leagues for this {{config.Params.programName}} are Registering Teams</span></div>
                </div>
            </div>
            <div class="row">
                <div v-if="ProgramID && Programs.length > 0" class="col-12">
                    <el-form-item v-if="LeagueID" label="Season:" prop="SeasonID">
                        <el-select class="topDropdown" v-model="SeasonID"  @change="seasonChange" placeholder="Select" default-first-option filterable>
                            <el-option v-for="season in availableSeasons"
                                       :value="season.SeasonID"
                                       :key="season.SeasonID"
                                       :label="season.SeasonName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div v-if="Leagues.length > 0" class="row mb-4">
                <div class="col-12">
                    <el-table
                        :data="Teams"
                        border
                        key="registeredteams"
                        size="small"
                        empty-text="No Teams Registered"
                        :span-method="arraySpanMethod"
                        :row-class-name="tableRowClassName"
                        style="width: 100%">
                        <el-table-column
                            prop="TeamName"
                            label="Team"
                            min-width="140"
                        >
                            <template #default="scope">
                                <span v-html="(scope.row.Header == 'y' ? scope.row.DivisionTypeName : (scope.row.LeagueDivisionID > 0 ? scope.row.TeamName + (scope.row.recordstatus == 'I' ? ' - Dropped' : '') : scope.row.recordstatus == 'A' ? 'Team #' + scope.row.RegisteredNumber : 'Team Dropped'))"></span>
                            </template>
                        </el-table-column>


                        <el-table-column
                            v-if="hasGames == 'y'"
                            prop="RegisteredGames"
                            label="Reg Games"
                            align="center"
                            width="90"
                        >
                        </el-table-column>
                        <el-table-column
                            v-if="hasLevels == 'y'"
                            prop="RegisteredDivisionLevel"
                            label="Level / Division"
                            align="center"
                            min-width="160"
                        >
                            <template #default="scope">
                                <span v-html="levelName(scope.row)"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="RegisteredStatus"
                            label="Status"
                            align="center"
                            width="100"
                        >
                            <template #default="scope">
                                <span v-html="(scope.row.recordstatus == 'A' ? 'Active' : (scope.row.recordstatus == 'I' ? 'Dropped' : ''))"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Notes"
                            label="Notes"
                            align="left"
                            min-width="200"
                        >
                            <template #default="scope">
                                <span v-html="scope.row.Notes"></span>
                            </template>
                        </el-table-column>

                    </el-table>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Util from '../assets/js/util.js';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
    export default {
        data () {
            return {
                loading: false,
                currentStatus : STATUS_INITIAL,
                DivisionTypes: [],
                DivisionLevels: [],
                Leagues: [],
                LeagueID: '',
                Programs: [],
                ProgramID: '',
                Teams: [],
                league : {},
                hasGames : 'n',
                hasLevels : 'n',
                Seasons : [],
                SeasonID : ''

            }
        },
		name: "TeamsReport",
        props:["config", "parentrefs"],
        computed: {

            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            createAccess () {
                return this.$createAccess();
            },
            modifyAccess () {
                return this.$modifyAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            LabelPosition () {
                return (Util.isMobile() ? 'top' : 'left');
            },
            availableSeasons () {
                var seasons = [];
                if (this.league.RegistrationSeasonID || this.league.ActiveSeasonID) {
                    for (let i = 0; i < this.Seasons.length; i++) {
                        if (this.Seasons[i]['SeasonID'] == this.league.RegistrationSeasonID || this.Seasons[i]['SeasonID'] == this.league.ActiveSeasonID) {
                            seasons.push(this.Seasons[i]);
                            if (!this.SeasonID) {
                                this.SeasonID = this.Seasons[i]['SeasonID'];
                            }
                        }
                    }
                }
                return seasons;
            },
        },
        methods: {
            seasonChange() {
                this.$store.dispatch('setID', {'TeamsReportSeasonID': this.SeasonID});
                this.getTeams();

            },
            programChange () {
                this.getAllLeagues();
            },
            arraySpanMethod({ row, column, rowIndex, columnIndex }) {
                if (row.Header == 'y') {
                    if (columnIndex == 0) {
                        return [1, 6];
                    } else {
                        return [0,0];
                    }
                } else {
                    return [1, 1];
                }
            },
            tableRowClassName({row, rowIndex}) {
                if (row.Header == 'y') {
                    return 'team-row';
                } else {
                    return '';
                }
            },
            levelName(row) {
                var level = '';
                if (row.DivisionName) {
                    level = row.DivisionName;
                } else {
                    if (row.Params && row.Params.RegisteredDivisionLevel) {
                        level = row.Params.RegisteredDivisionLevel;
                        var parts = level.split('-');
                        if (parts.length == 1) {
                            level = row.DivisionLevelName;
                        } else {
                            level = row.DivisionLevelName + ' - ' + parts[parts.length - 1];
                        }
                    } else {
                        level = row.DivisionLevelName;
                    }
                }
                return level;
            },
            SubLevels(DivisionTypeID) {
                let levels = [];
                var allLevels = [];
                for (let i=0; i < this.DivisionTypes[this.SeasonID].length; i++) {
                    if (this.DivisionTypes[this.SeasonID][i]['DivisionTypeID'] == DivisionTypeID) {
                        if (this.DivisionTypes[this.SeasonID][i]['DivisionLevels'].length > 0) {
                            allLevels = this.DivisionTypes[this.SeasonID][i]['DivisionLevels'];
                        } else {
                            allLevels = this.DivisionLevels[this.SeasonID];
                        }
                        break;
                    }
                }

                for (let i=0; i < allLevels.length; i++) {
                    if (allLevels[i]['Params']['SubLevels'] && allLevels[i]['Params']['SubLevels'].length > 0) {
                        for (let j=0; j < allLevels[i]['Params']['SubLevels'].length; j++) {
                            let key = allLevels[i].DivisionLevelID + '-' + allLevels[i]['Params']['SubLevels'][j]['SubLevel'];
                            levels.push({key: key, label: allLevels[i]['DivisionLevelName'] + ' - '+ allLevels[i]['Params']['SubLevels'][j]['SubLevel']});
                        }
                    } else {
                        let key = allLevels[i].DivisionLevelID;
                        levels.push({key: key, label: allLevels[i]['DivisionLevelName']});
                    }
                }
                return levels;
            },
            sortOrder(a,b) {
                if(a.ReportSortOrder < b.ReportSortOrder) { return -1; }
                if(a.ReportSortOrder > b.ReportSortOrder) { return 1; }
                return 0;
            },
            sortTeams() {
                for (let i = 0; i < this.Teams.length; i++) {
                    var sublevels = this.SubLevels(this.Teams[i].RegisteredDivisionTypeID);
                    var DivisionLevelID = '';
                    var SubSort = 0;
                    for (let k = 0; k < sublevels.length; k++) {
                        if (sublevels[k].key == this.Teams[i].Params.RegisteredDivisionLevel) {
                            DivisionLevelID = sublevels[k].key.split('-')[0];
                            SubSort = k + '';
                            break;
                        }
                    }
                    for (let k = 0; k < this.DivisionLevels[this.SeasonID].length; k++) {
                        if (this.DivisionLevels[this.SeasonID][k].DivisionLevelID == DivisionLevelID) {
                            this.Teams[i].ReportSortOrder += SubSort.padStart(3, 0);
                            break;
                        }
                    }
                }
                this.Teams.sort(this.sortOrder);

            },
            getInitialData () {
                var tables = ['Seasons'];
                axios.post('/index/tableData', {'tables': tables },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                            this.getPrograms();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getPrograms () {
                axios.post('/analyzer/programs/getallregistration', {SeasonID : this.SeasonID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Programs = response.data.Programs;
                            this.user = { ...this.currentUser };
                            if (!this.ProgramID) {
                                if (this.ProgramIDAccess) {
                                    this.ProgramID = this.user.ProgramID;
                                } else {
                                    this.ProgramID = this.Programs[0]['ProgramID'];
                                }
                            }
                            this.getAllLeagues();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },
            getAllLeagues () {
                this.DivisionTypes = [];
                this.DivisionLevels = [];
                this.Leagues = [];
                this.Teams = [];
                let self = this;
                axios.post('/analyzer/leagues/getall', {AllowRegistration : 'y', ProgramID: this.ProgramID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            self.$store.dispatch('setID', {'TeamsReportProgramID': self.ProgramID});
                            this.Leagues = response.data.Leagues;
                            if (this.Leagues.length > 0) {
                                var leaguefound = false;
                                for (let i = 0; i < this.Leagues.length; i++) {
                                    if (this.LeagueID == this.Leagues[i]['LeagueID']) {
                                        leaguefound = true;
                                        break;
                                    }
                                }
                                if (!this.LeagueID || !leaguefound) {
                                    this.LeagueID = this.Leagues[0]['LeagueID'];
                                }

                                this.getDivisionTypes();
                            }

                            this.$stopProgressBar(this.parentrefs);
                            this.loading = false;
                            this.$emit('updateHelpID', this.LeagueID);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                });
            },
            getDivisionTypes() {
                let self = this;
                this.DivisionTypes = [];
                this.DivisionLevels = [];
                this.Teams = [];

                axios.post('/analyzer/leagues/get', {LeagueID: this.LeagueID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.league) {
                                this.league = response.data.league;
                            }
                            if (response.data.DivisionTypes) {
                                this.DivisionTypes = (response.data.DivisionTypes ? response.data.DivisionTypes: []);
                            }
                            if (response.data.DivisionLevels) {
                                this.DivisionLevels = (response.data.DivisionLevels ? response.data.DivisionLevels : []);
                            }
                            this.getTeams();
                            this.$stopProgressBar(this.parentrefs);
                            this.$emit('updateHelpID', this.LeagueID);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },
            getTeams() {
                var seasons = this.availableSeasons;
                var seasonfound = false;
                for (let i = 0; i < seasons.length; i++) {
                    if (this.SeasonID == seasons[i]['SeasonID']) {
                        seasonfound = true;
                        break;
                    }
                }
                if (!seasonfound) {
                    if (seasons.length > 0) {
                        this.SeasonID = seasons[0]['SeasonID'];
                    } else {
                        this.SeasonID = '';
                    }
                }
                axios.post('/analyzer/teams/getregistered', {ProgramID: this.ProgramID, LeagueID: this.LeagueID, SeasonID : this.SeasonID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Teams = response.data.TeamsReport;
                            this.hasGames = response.data.hasGames;
                            this.hasLevels = response.data.hasLevels;
                            this.sortTeams();
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },

            displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
                });
            }
        },
        mounted() {
            if (this.componentIDs.TeamsReportProgramID) {
                this.ProgramID = this.componentIDs.TeamsReportProgramID;
            }
            this.getInitialData();
        },
    }
</script>

<style>
    #teamsreport .team-row, #teamsreport th div.cell {
        font-weight: 800;
        font-size: 14px;
    }
</style>
<style scoped>

    .programteamregistration .form-horizontal {
        width: 100%;
    }

    .el-select {
        width: 100%;
        max-width: 300px;
    }

    .el-select.level {
        width: 100%;
    }

    .el-select.games {
        width: 70px;
        text-align:center;
    }

    table thead tr th:last-child {
        text-align: center;
    }

    .program {
        font-size: 14px;
        color: #606266;
    }
    .programName {
        font-weight: 600;
        margin-left: 25px;
    }
	.leagueName {
		font-weight: 600;
		margin-left: 32px;
	}
    .teamDelete {
        padding: 5px 0px;
        text-align: center;
    }
    .divisionHeader {
        margin-top: 10px;
        height: 40px;
        line-height: 40px;
        background-color: #f0f0f0;
        width: 100%;
        border: 1px solid #cccccc;
    }
    h5 {
        display: inline-block;
        margin-right: 40px;
        font-size: 14px;
    }
    .teamRow {
        border-bottom: 1px solid #F0f0f0;
        line-height: 26px;
        padding-top: 6px;
        vertical-align: middle;
        font-size: 14px;
    }
    .el-textarea {
        margin-bottom: 6px;
    }
    .addTeam {
        float:right;
        margin: 6px;
    }
    .row.col-12 div {
        padding-left: 5px;
        padding-right: 0px;
    }
    .danger {
        color: #dc3545;
    }
    .col-10 {
        margin-top: 7px;
    }
    .col-2 {
        margin-top: 7px;
    }
    @media (min-width: 768px) {
        .col-md-3 {
            margin-top: 0px;
        }
        .col-md-2 {
            margin-top: 0px;
        }
    }
    .deleted {
        background-color: rgba(220, 53, 69, 0.1);
    }
    .el-form-item {
        margin-bottom: 10px;
    }
</style>

