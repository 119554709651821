<template>
    <transition name="fade">
        <div id="programteamregistration" v-loading="loading">
            <el-form ref="registrationForm" :label-position="LabelPosition" label-width="80px" >
                <div class="row">
                    <div class="col-12">
                        <div>
                            <el-form-item :label="config.Params.programName + ':'" prop="ProgramID" v-if="Programs.length > 1">
                                <el-select v-model="ProgramID" ref="program" placeholder="Select" class="topDropdown" @change="programChange" default-first-option filterable>
                                    <el-option
                                        v-for="item in Programs"
                                        :label="item.ProgramName"
                                        :value="item.ProgramID"
                                        :key="item.ProgramID">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div class="program mb-2" v-else-if="Programs.length == 1">{{config.Params.programName}}:  <span class="programName">{{Programs[0].ProgramName}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <el-form-item label="League:" prop="LeagueID" v-if="Leagues.length > 1">
                            <el-select v-model="LeagueID" ref="league" placeholder="Select" class="topDropdown" @change="leagueChange" default-first-option filterable>
                                <template v-for="item in Leagues">
                                    <el-option
                                        v-if="item.Params.AllowRegistration == 'y'"
                                        :label="item.LeagueName + ' (' + item.SeasonName + ')'"
                                        :value="item.LeagueID"
                                        :key="item.LeagueID">
                                    </el-option>
                                </template>
                            </el-select>
                        </el-form-item>
                        <div class="program" v-else-if="Leagues.length == 1">League:  <span class="programName">{{Leagues[0].LeagueName}}</span></div>
                        <div class="program" v-else>League: <span class="leagueName">No Leagues for this {{config.Params.programName}} to Register in.</span></div>
                    </div>
                </div>
                <div v-if="RegistrationLocked" class="row" >
                    <div class="col-12 mt-4">
                        <span v-if="ProgramIDAccess" class="danger locked" v-html="(TeamRegistrationMessage ? TeamRegistrationMessage : teamregistrationlocked)"></span>
                        <span v-else class="warning" v-html="'<strong>'+(TeamRegistrationMessage ? TeamRegistrationMessage : teamregistrationlocked) + ' As an Administrator, you MAY still be allowed to make changes.</strong>'"></span>
                    </div>
                </div>
                <div v-else class="row" >
                    <div class="col-12 mt-4">
                        <span class="info" v-html="TeamRegistrationMessage"></span>
                    </div>
                </div>
                <div class="mt-4">
                    <div v-for="(type, index) in DivisionTypes">
                        <div class="row mt-4 divisionHeader">
                            <div class="col-3 col-md-2">
                                <h5 style="white-space: nowrap;">{{type.DivisionTypeName}}</h5>
                            </div>
                            <div class="col-3 col-md-2" style="text-align: center;" v-html="(hasGames(type.DivisionTypeID) ? 'Games' : '&nbsp;')"></div>
                            <div class="col-2 col-md-3" style="text-align: center;" v-html="(SubLevels(type.DivisionTypeID).length > 0 ? 'Level' : '&nbsp;')"></div>
                            <div class="col-4 col-md-5" style="padding-right: 0;">
                                <el-button v-if="RegistrationOpen && createAccess && !RegistrationAddsLocked" class="addTeam" @click.prevent="addTeam('D' + type.DivisionTypeID)" size="small"><i class="fa-solid fa-circle-plus"></i> Add Team</el-button>
                            </div>
                        </div>
                        <div class="row teamRow" v-for="(team, index) in DivisionTeams('D'+type.DivisionTypeID)" :class="{'deleted' : team.operation == 'delete' || team.operation == 'drop'}">
                            <div class="col-3 col-md-2" style="font-weight: 800;">
                                Team #{{ index + 1 }}
                            </div>
                            <div class="col-3 col-md-2" style="text-align: center;">
                                <template v-if="hasGames(type.DivisionTypeID)">
                                    <template v-if="RegistrationOpen && type.Params.Amounts && type.Params.Amounts.length > 1 && (!RegistrationChangesLocked || !team.TeamID)">
                                        <el-select v-if="(!RegistrationChangesLocked || !team.TeamID)" v-model="team.RegisteredGames" placeholder="#" class="games" size="small" @change="updateTeam('D'+ type.DivisionTypeID, index, 'update')" default-first-option filterable>
                                            <el-option
                                                v-for="item in type.Params.Amounts"
                                                :label="item.Games"
                                                :value="item.Games"
                                                :key="item.Games">
                                            </el-option>
                                        </el-select>
                                        <div v-else v-html="type.Params.Amounts[0].Games"></div>
                                    </template>
                                    <div v-else-if="RegistrationChangesLocked && team.TeamID" v-html="team.RegisteredGames"></div>
                                    <div v-else v-html="type.Params.Amounts[0].Games"></div>
                                </template>
                                <template v-else>&nbsp;</template>
                            </div>
                            <div class="col-md-3 col-6" style="text-align: center;">
                                <template v-if="SubLevels(type.DivisionTypeID).length > 1" >
                                    <el-select v-if="RegistrationOpen && (!RegistrationChangesLocked || !team.TeamID)" v-model="team.Params.RegisteredDivisionLevel" placeholder="Select" class="level" size="small" @change="updateTeam('D'+ type.DivisionTypeID, index, 'update')" default-first-option filterable>
                                        <el-option
                                            v-for="item in SubLevels(type.DivisionTypeID)"
                                            :label="item.label"
                                            :value="item.key"
                                            :key="item.key">
                                        </el-option>
                                    </el-select>
                                    <div v-else v-html="sublevelname(team.DivisionLevelName,team.Params.RegisteredDivisionLevel)"></div>
                                </template>
                                <template v-else-if="SubLevels(type.DivisionTypeID).length == 1" >
                                    <span v-html="SubLevels(type.DivisionTypeID)[0].label"></span>
                                </template>
                                <template v-else>&nbsp;</template>
                            </div>
                            <div class="col-md-3 col-10">
                                <el-input  v-if="RegistrationOpen"
                                           @change="updateTeam('D'+ type.DivisionTypeID, index, 'update')"
                                           type="textarea"
                                           size="small"
                                           autosize
                                           placeholder="Additional Notes"
                                           v-model="team.Params.RegisteredNotes">
                                </el-input>
                                <div v-else v-html="team.Params.RegisteredNotes"></div>
                            </div>
                            <span v-if="RegistrationOpen && modifyAccess && (!RegistrationDropsLocked || !team.TeamID)" class="col-2 col-md-2" style="padding-left: 5px;">
								<div v-if="team.InvoiceDetailID != 0 && team.operation != 'drop'" style="text-align: right;">
                                    <el-button @click.prevent="updateTeam('D' + type.DivisionTypeID, index, 'drop')" type="danger" class="btn-danger" size="small"><i class="fa-solid fa-circle-minus"></i> Drop</el-button>
								</div>
								<div v-else-if="team.InvoiceDetailID == '0' && team.operation != 'delete'" style="text-align: right;" >
                                    <el-button @click.prevent="updateTeam('D' + type.DivisionTypeID, index, 'delete')" type="danger" class="btn-danger" size="small"><i class="fa-solid fa-circle-minus"></i> Remove</el-button>
								</div>
							</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-4 mb-4" v-if="((modifyAccess || createAccess) && DivisionTypes && DivisionTypes.length > 0 && RegistrationOpen)" >
                    <div class="col-12 text-center">
                        <button class="btn btn-success btn-icon" type="button" @click="save">
                            <i class="fas fa-check"></i> <span> Save Changes</span>
                        </button>
                        <button class="btn btn-icon btn-secondary cancel" type="button"  @click="getPrograms">
                            <i class="fas fa-times"></i>  <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </el-form>
        </div>
    </transition>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Util from '../assets/js/util.js';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
    export default {
        data () {
            return {
                loading: false,
                currentStatus : STATUS_INITIAL,
                DivisionTypes: [],
                DivisionLevels: [],
                Leagues: [],
                LeagueID: '',
                PrevLeagueID : '',
                Programs: [],
                ProgramID: '',
                PrevProgramID : '',
                Teams: {},
                league : {},
                DroppedTeam: false,
                teamregistrationlocked : '<strong>** Registration for this League is now LOCKED.  You are responsible for the Teams below.  If you wish to make any changes, please contact Valley Associates.</strong>',
            }
        },
        name: 'TeamRegistration',
        props:["config", "parentrefs"],
        beforeRouteLeave (to, from, next) {
            if (this.haveUnsavedData()) {
                let self = this;
                this.$confirm('You have made changes to the data on this screen and if you LEAVE you will lose them.', 'You have Unsaved Data.', {
                    distinguishCancelAndClose: true,
                    showClose: false,
                    confirmButtonText: 'Stay',
                    cancelButtonText: 'Leave',
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    type: 'warning'
                })
                    .then(function () {
                        next(false);
                    })
                    .catch(function () {
                        self.$noDataToBeSaved();
                        next();
                    });
            } else {
                next();
            }
        },
        computed: {

            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            createAccess () {
                return this.$createAccess();
            },
            modifyAccess () {
                return this.$modifyAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            LabelPosition () {
                return (Util.isMobile() ? 'top' : 'left');
            },
            RegistrationOpen () {
                if (!this.ProgramIDAccess) {
                    return true;
                }
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.LeagueID == this.Leagues[i].LeagueID) {
                        if (this.Leagues[i].Params.LockRegistration && this.Leagues[i].Params.LockRegistration == 'y') {
                            return false;
                        }
                        break;
                    }
                }
                return true;
            },
            RegistrationLocked () {
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.LeagueID == this.Leagues[i].LeagueID) {
                        if (this.Leagues[i].Params.LockRegistration && this.Leagues[i].Params.LockRegistration == 'y') {
                            return true;
                        }
                        break;
                    }
                }
                return false;
            },
            RegistrationDropsLocked () {
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.LeagueID == this.Leagues[i].LeagueID) {
                        if (this.Leagues[i].Params.LockRegistrationDrops && this.Leagues[i].Params.LockRegistrationDrops == 'y') {
                            return true;
                        }
                        break;
                    }
                }
                return false;
            },
            RegistrationAddsLocked () {
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.LeagueID == this.Leagues[i].LeagueID) {
                        if (this.Leagues[i].Params.LockRegistrationAdds && this.Leagues[i].Params.LockRegistrationAdds == 'y') {
                            return true;
                        }
                        break;
                    }
                }
                return false;
            },
            RegistrationChangesLocked () {
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.LeagueID == this.Leagues[i].LeagueID) {
                        if (this.Leagues[i].Params.LockRegistrationChanges && this.Leagues[i].Params.LockRegistrationChanges == 'y') {
                            return true;
                        }
                        break;
                    }
                }
                return false;
            },
            TeamRegistrationMessage () {
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.LeagueID == this.Leagues[i].LeagueID) {
                        if (this.Leagues[i].Params.TeamRegistrationMessage) {
                            return this.Leagues[i].Params.TeamRegistrationMessage;
                        }
                        break;
                    }
                }
                return '';
            }
        },
        methods: {
            haveUnsavedData() {
                return this.$haveUnsavedData();
            },
            sublevelname(DivisionLevelName,RegisteredDivisionLevel)
            {
                var levels = RegisteredDivisionLevel.split('-');
                if (levels.length > 1) {
                    return DivisionLevelName + ' - ' + levels[1];
                }
                return DivisionLevelName;

            },
            programChange () {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Previous ' + this.config.Params.programName + ' which have not been saved and will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Get New',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            self.ProgramID = self.PrevProgramID;
                            self.$refs['program'].blur();
                            return;
                        })
                        .catch(function () {
                            self.$refs['program'].blur();
                            self.getAllLeagues ();

                        });
                } else {
                    this.$emit('updateHelpID', this.LeagueID);
                    this.getAllLeagues();
                }

            },
            leagueChange () {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Previous League which have not been saved and will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Get New League',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            self.LeagueID = self.PrevLeagueID;
                            self.$emit('updateHelpID', self.LeagueID);
                            self.$refs['league'].blur();
                            return;
                        })
                        .catch(function () {
                            self.$refs['league'].blur();
                            self.getDivisionTypes ();

                        });
                } else {
                    this.$emit('updateHelpID', this.LeagueID);
                    this.getDivisionTypes ();
                }
            },
            SubLevels(DivisionTypeID) {
                let levels = [];
                var allLevels = [];
                for (let i=0; i < this.DivisionTypes.length; i++) {
                    if (this.DivisionTypes[i]['DivisionTypeID'] == DivisionTypeID) {
                        if (this.DivisionTypes[i]['DivisionLevels'].length > 0) {
                            allLevels = this.DivisionTypes[i]['DivisionLevels'];
                        } else {
                            allLevels = this.DivisionLevels;
                        }
                        break;
                    }
                }

                for (let i=0; i < allLevels.length; i++) {
                    if (allLevels[i]['Params']['SubLevels'] && allLevels[i]['Params']['SubLevels'].length > 0) {
                        for (let j=0; j < allLevels[i]['Params']['SubLevels'].length; j++) {
                            let key = allLevels[i].DivisionLevelID + '-' + allLevels[i]['Params']['SubLevels'][j]['SubLevel'];
                            levels.push({key: key, label: allLevels[i]['DivisionLevelName'] + ' - '+ allLevels[i]['Params']['SubLevels'][j]['SubLevel']});
                        }
                    } else {
                        let key = allLevels[i].DivisionLevelID;
                        levels.push({key: key, label: allLevels[i]['DivisionLevelName']});
                    }
                }
                return levels;
            },
            SelectDivisionLevels(DivisionTypeID) {
                var specificLevels = false;
                var levels = [];
                for (let j=0; j< this.DivisionLevels.length; j++) {
                    if (this.DivisionLevels[j].DivisionTypeID == DivisionTypeID) {
                        specificLevels = true;
                        break;
                    }
                }
                if (specificLevels) {
                    for (let j=0; j< this.DivisionLevels.length; j++) {
                        if (this.DivisionLevels[j].DivisionTypeID == DivisionTypeID) {
                            levels.push(this.DivisionLevels[j]);
                        }
                    }
                } else {
                    for (let j=0; j< this.DivisionLevels.length; j++) {
                        if (this.DivisionLevels[j].DivisionTypeID == 0) {
                            levels.push(this.DivisionLevels[j]);
                        }
                    }
                }
                return levels;
            },
            DivisionTeams(ID) {
                var teams = [];
                if (this.Teams[ID]) {
                    for (let i = 0; i < this.Teams[ID].length; i++) {
                        //if (this.Teams[ID][i].operation != 'delete' && this.Teams[ID][i].operation != 'drop') {
                        teams.push(this.Teams[ID][i]);
                        //}
                    }
                }
                return teams;
            },
            hasGames(ID) {
                for (let i=0; i < this.DivisionTypes.length; i++) {
                    if (this.DivisionTypes[i]['DivisionTypeID'] == ID) {
                        if (this.DivisionTypes[i].Params.Amounts && this.DivisionTypes[i].Params.Amounts.length > 0) {
                            if (this.DivisionTypes[i].Params.Amounts[0].Games) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            addTeam(ID) {
                this.$haveDataToBeSaved();
                var games = '';
                var DivisionTypeID = ID.replace('D','');

                for (let i=0; i < this.DivisionTypes.length; i++) {
                    if (this.DivisionTypes[i].DivisionTypeID == DivisionTypeID) {
                        if (this.DivisionTypes[i].Params.Amounts) {
                            if (this.DivisionTypes[i].Params.Amounts.length == 1) {
                                games = this.DivisionTypes[i].Params.Amounts[0].Games;
                            }
                        }
                    }
                }
                if (!this.Teams[ID]) {
                    this.Teams[ID] = [];
                }
                var sublevels = this.SubLevels(DivisionTypeID);
                if (sublevels.length == 1) {
                    var sublevel = sublevels[0].key;
                    var RegisteredDivisionLevelID = sublevel.split('-')[0];
                } else {
                    var sublevel = '';
                    var RegisteredDivisionLevelID = '';
                }

                this.Teams[ID].push(
                    {
                        InvoiceDetailID: 0,
                        TeamID: 0,
                        TeamName: '',
                        ProgramID: this.ProgramID,
                        RegisteredLeagueID: this.LeagueID,
                        RegisteredDivisionTypeID: DivisionTypeID,
                        RegisteredGames: games,
                        RegisteredDivisionLevelID: RegisteredDivisionLevelID,
                        Params: {RegisteredNotes: '', RegisteredStatus: 'Pending', RegisteredFee: 0, RegisteredDivisionLevel : sublevel},
                        SortOrder: 99,
                        operation: 'add',
                     }
                );
            },
            updateTeam(ID, idx, type) {
                this.$haveDataToBeSaved();
                for (let i = 0; i < this.Teams[ID].length; i++) {
                    //ingore if already deleted or dropped
                    if (this.Teams[ID][i].operation != 'delete' && this.Teams[ID][i].operation != 'drop' ) {
                        if (idx == i) {
                            this.Teams[ID][i].operation = type;
                            if (this.Teams[ID][i].TeamID == 0 && type == 'delete') {
                                this.Teams[ID].splice(i, 1);
                            } else if (type == 'drop') {
                                this.DroppedTeam = true;
                            } else {
                                var sublevels = this.SubLevels(this.Teams[ID][i].RegisteredDivisionTypeID);
                                var DivisionLevelID = '';
                                var SubSort = 0;
                                for (let j = 0; j < sublevels.length; j++) {
                                    if (sublevels[j].key == this.Teams[ID][i].Params.RegisteredDivisionLevel) {
                                        DivisionLevelID = sublevels[j].key.split('-')[0];
                                        SubSort = j+'';
                                        break;
                                    }
                                }
                                this.Teams[ID][i].RegisteredDivisionLevelID = DivisionLevelID;
                                for (let j = 0; j < this.DivisionLevels.length; j++) {
                                    if (this.DivisionLevels[j].DivisionLevelID == DivisionLevelID) {
                                        this.Teams[ID][i].SortOrder = this.DivisionLevels[j].SortOrder + '-' + SubSort.padStart(3,0);
                                        break;
                                    }
                                }
                            }
                            break;
                        }
                    }
                }
            },
            sortOrder(a,b) {
                if (a.SortOrder && b.SortOrder) {
                    return parseInt(a.SortOrder) - parseInt(b.SortOrder);
                } else {
                    return 0;
                }
            },
            sortTeams() {
                for (let j = 0; j < this.DivisionTypes.length; j++) {
                    var ID = 'D' + this.DivisionTypes[j].DivisionTypeID;
                    if (this.Teams.hasOwnProperty(ID)) {
                        for (let i = 0; i < this.Teams[ID].length; i++) {
                            var sublevels = this.SubLevels(this.Teams[ID][i].RegisteredDivisionTypeID);
                            var DivisionLevelID = '';
                            var SubSort = 0;
                            for (let k = 0; k < sublevels.length; k++) {
                                if (sublevels[k].key == this.Teams[ID][i].Params.RegisteredDivisionLevel) {
                                    DivisionLevelID = sublevels[k].key.split('-')[0];
                                    SubSort = k + '';
                                    break;
                                }
                            }
                            for (let k = 0; k < this.DivisionLevels.length; k++) {
                                if (this.DivisionLevels[k].DivisionLevelID == DivisionLevelID) {
                                    this.Teams[ID][i].SortOrder = this.DivisionLevels[k].SortOrder + '' + SubSort.padStart(3, 0);
                                    break;
                                }
                            }
                        }
                        this.Teams['D' + this.DivisionTypes[j].DivisionTypeID].sort(this.sortOrder);
                    }
                }
            },
            getPrograms () {
                axios.post('/analyzer/programs/getallregistration', { },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Programs = response.data.Programs;
                            if (response.data.teamregistrationlocked) {
                                this.teamregistrationlocked = response.data.teamregistrationlocked;
                            }
                            this.user = { ...this.currentUser };
                            if (!this.ProgramID) {
                                if (this.ProgramIDAccess) {
                                    this.ProgramID = this.user.ProgramID;
                                } else {
                                    if (this.componentIDs.PTRProgramID) {
                                        this.ProgramID = this.componentIDs.PTRProgramID;
                                    } else {
                                        this.ProgramID = this.Programs[0]['ProgramID'];
                                    }
                                }
                            }
                            this.getAllLeagues();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },
            getAllLeagues () {
                this.DivisionTypes = [];
                this.DivisionLevels = [];
                this.Leagues = [];
                this.Teams = [];
                this.PrevProgramID = this.ProgramID;
                let self = this;
                axios.post('/analyzer/leagues/getall', {AllowRegistration : 'y', ProgramID: this.ProgramID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            self.$store.dispatch('setID', {'PTRProgramID': self.ProgramID});
                            this.Leagues = response.data.Leagues;
                            if (this.Leagues.length > 0) {
                                var leaguefound = false;
                                for (let i = 0; i < this.Leagues.length; i++) {
                                    if (this.LeagueID == this.Leagues[i]['LeagueID']) {
                                        leaguefound = true;
                                        break;
                                    }
                                }
                                if (!this.LeagueID || !leaguefound) {
                                    this.LeagueID = this.Leagues[0]['LeagueID'];
                                }
                                this.getDivisionTypes();
                            }

                            this.$stopProgressBar(this.parentrefs);
                            this.loading = false;
                            this.$emit('updateHelpID', this.LeagueID);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                });
            },
            getDivisionTypes() {
                let self = this;
                this.DivisionTypes = [];
                this.DivisionLevels = [];
                this.Teams = [];
                this.PrevLeagueID = this.LeagueID;
                axios.post('/analyzer/leagues/get', {LeagueID: this.LeagueID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            self.$store.dispatch('setID', {'PTRLeagueID': self.LeagueID});
                            if (response.data.league) {
                                this.league = response.data.league;
                            }
                            if (response.data.DivisionTypes) {
                                this.DivisionTypes = (response.data.DivisionTypes[this.league.RegistrationSeasonID] ? response.data.DivisionTypes[this.league.RegistrationSeasonID]: []);
                            }
                            if (response.data.DivisionLevels) {
                                this.DivisionLevels = (response.data.DivisionLevels[this.league.RegistrationSeasonID] ? response.data.DivisionLevels[this.league.RegistrationSeasonID] : []);
                            }
                            this.getTeams();
                            this.$stopProgressBar(this.parentrefs);
                            this.$emit('updateHelpID', this.LeagueID);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },
            getTeams() {
                this.DroppedTeam = false;
                this.$noDataToBeSaved();
                axios.post('/analyzer/teams/getregistered', {ProgramID: this.ProgramID, LeagueID: this.LeagueID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Teams = response.data.Teams;
                            this.sortTeams();
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },
            save () {
                var errorMsg = this.validateTeams();
                if (!errorMsg) {
                    this.sortTeams();
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);

                    axios.post('/analyzer/teams/saveregistered', {
                            ProgramID: this.ProgramID,
                            LeagueID: this.LeagueID,
                            DivisionTypes: this.DivisionTypes,
                            DivisionLevels: this.DivisionLevels,
                            Teams: this.Teams
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.$noDataToBeSaved();
                                this.currentStatus = STATUS_INITIAL;
                                var message = 'Team Registration has been saved.' + (this.DroppedTeam ? ' You have droped one or more teams that you were invoiced for. An Invoice credit has been created.' : '');
                                if(response.data.message) {
                                    message += ' ' + response.data.message;
                                }
                                this.$notify({
                                    title: 'Success',
                                    message: message,
                                    type: 'success',
                                    duration: 3000,
                                    showClose: false
                                });
                                this.DroppedTeam = false;
                                this.generateTeamNames();
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup(response.data.message);
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.displayPopup(errorMsg);
                }
            },
            generateTeamNames () {
                this.currentStatus = STATUS_SAVING;
                let divtypes = {};
                divtypes[this.league.RegistrationSeasonID] = this.DivisionTypes;
                let divlevels = {};
                divlevels[this.league.RegistrationSeasonID] = this.DivisionLevels;
                axios.post('/analyzer/leagues/generateteamnames', {LeagueID : this.LeagueID, DivisionTypes : divtypes, DivisionLevels : divlevels, ProgramID : this.ProgramID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_INITIAL;
	                        this.getTeams();
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.displayPopup (response.data.message);
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                });
            },
            validateTeams() {
                var gameerror = false;
                var levelerror = false;
                for (let i=0; i < this.DivisionTypes.length; i++) {
                    var ID = 'D'+this.DivisionTypes[i].DivisionTypeID;
                    if (this.Teams[ID]) {
                        for (let j = 0; j < this.Teams[ID].length; j++) {
                            if (this.Teams[ID][j].operation != 'delete' && this.Teams[ID][j].operation != 'drop' && ((!this.Teams[ID][j].RegisteredGames && this.hasGames(this.DivisionTypes[i].DivisionTypeID)))) {
                                gameerror = true;
                            }
                            if (this.Teams[ID][j].operation != 'delete' && this.Teams[ID][j].operation != 'drop' && ((!this.Teams[ID][j].RegisteredDivisionLevelID && this.DivisionLevels.length > 0))) {
                                levelerror = true;
                            }
                        }
                    }
                }

                if (gameerror || levelerror) {
                    return 'Each Team must have ' + (gameerror ? 'the Number of Games' + (levelerror ? ' and Level' : 'the Level entered') : 'the Level') + ' entered';
                } else {
                    return '';
                }
            },
            displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
                });
            }
        },
        mounted() {
            this.getPrograms();
        },
    }
</script>

<style>
    #programteamregistration .teamRow .el-select input {
        text-align: center;
    }
</style>
<style scoped>

    .programteamregistration .form-horizontal {
        width: 100%;
    }

    .el-select {
        width: 100%;
        max-width: 300px;
    }

    .el-select.level {
        width: 100%;
    }

    .el-select.games {
        width: 70px;
        text-align:center;
    }

    table thead tr th:last-child {
        text-align: center;
    }

    .program {
        font-size: 14px;
        color: #606266;
    }
    .programName {
        font-weight: 600;
        margin-left: 25px;
    }
    .leagueName {
		font-weight: 600;
		margin-left: 32px;
	}
    .teamDelete {
        padding: 5px 0px;
        text-align: center;
    }
    .divisionHeader {
        margin-top: 10px;
        height: 40px;
        line-height: 40px;
        background-color: #f0f0f0;
        width: 100%;
        border: 1px solid #cccccc;
    }
    h5 {
        display: inline-block;
        margin-right: 40px;
        font-size: 14px;
    }
    .teamRow {
        border-bottom: 1px solid #F0f0f0;
        line-height: 26px;
        padding-top: 6px;
        vertical-align: middle;
        font-size: 14px;
    }
    .el-textarea {
        margin-bottom: 6px;
    }
    .addTeam {
        float:right;
        margin: 6px;
    }
    .row.col-12 div {
        padding-left: 5px;
        padding-right: 0px;
    }
    .danger {
        color: #dc3545;
    }
    .col-10 {
        margin-top: 7px;
    }
    .col-2 {
        margin-top: 7px;
    }
    @media (min-width: 768px) {
        .col-md-3 {
            margin-top: 0px;
        }
        .col-md-2 {
            margin-top: 0px;
        }
    }
    .deleted {
        background-color: rgba(220, 53, 69, 0.1);
    }
</style>
