<template>
    <transition name="print">
        <div class="print-dialog" role="document">
            <div class="print-content">
                <h5>PDF ONLY Parameters</h5>
                <div style="float:left; width: 45%;">
                Print Orientation:<br>
                <el-radio-group v-model="orientation" size="small">
                    <el-radio-button selected label="P">Portrait</el-radio-button>
                    <el-radio-button label="L">Landscape</el-radio-button>
                </el-radio-group>
                </div>
                <div style="float:left; width: 45%;">
                    Page Break on Groups:<br>
                    <el-radio-group v-model="pagebreak" size="small">
                        <el-radio-button selected label="y">Yes</el-radio-button>
                        <el-radio-button label="n">No</el-radio-button>
                    </el-radio-group>
                </div>
                <br>
                Columns: (Order = 0, not printed)<br>
                <el-table
                    :data="columns"
                    key="coltable"
                    stripe border
                    size="small"
                    style="width: 100%">
                    <el-table-column
                        prop="Data"
                        label="Data"
                        width="100"
                        align="left"
                    >
                    </el-table-column>
                    <el-table-column
                        label="Title"
                        min-width="150"
                        align="center"
                    >
                        <template #default="scope">
                            <el-input  size="small" v-model="scope.row.Title"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="Width (%)"
                        width="80"
                        align="center"
                    >
                        <template #default="scope">
                            <el-input size="small" v-model="scope.row.Width"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="Align"
                        width="110"
                        align="center"
                    >
                        <template #default="scope">
                            <el-select  size="small" v-model="scope.row.Align">
                                <el-option key="Left" value="L" label="Left"></el-option>
                                <el-option key="Right" value="R" label="Right"></el-option>
                                <el-option key="Center" value="C" label="Center"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="Order"
                        width="80"
                        align="center"
                    >
                        <template #default="scope">
                            <el-input size="small" v-model="scope.row.Order"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <br>
                <div class="print-footer">
                    <el-button v-if="printDomID" type="info" class="btn-secondary" style="float:right;" v-print="printDomID">
                        <i class="fas fa-print"></i> <span>Print Page Content</span>
                    </el-button>
                    <el-button type="primary" class="btn-primary" style="float:left;" @click.stop="viewPDF">
                        <i class="far fa-file-pdf"></i>  <span>Generate PDF</span>
                    </el-button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'print',
        data() {
            return {
                orientation: 'P',
                pagebreak: 'y',
            }
        },
        props: {
            data : {
                type: [Object,Array],
                required: true,
                default: () => {}
            },
            columns : {
                type: [Object,Array],
                required: true,
                default: () => {}
            },
            groupdata : {
                type: [Object,Array],
                required: false,
                default: () => {}
            },
            groupcolumns : {
                type: [Object,Array],
                required: false,
                default: () => {}
            },
            printDomID : {
                type: String,
                required: false,
                default: ''
            },
            reportName : {
                type: String,
                required: false,
                default: ''
            },
            totals : {
                type: String,
                required: false,
                default: 'y'
            }
        },
        methods: {
            viewPDF() {
                if (this.data) {
                    axios.post('/index/printpdf', {
                            data: this.data,
                            columns: this.columns,
                            groupdata: this.groupdata,
                            groupcolumns: this.groupcolumns,
                            orientation: this.orientation,
                            pagebreak : this.pagebreak,
                            reportName : this.reportName,
                            totals : this.totals
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                if (response.data.File) {
                                    var File = response.data.File;

                                    var link = document.createElement('a');
                                    link.href = File;
                                    link.click();
                                } else {
                                    this.displayPopup('Report could not be generated');
                                }
                            } else {
                                this.displayPopup(response.data.message);
                            }
                            this.$emit('close');
                        }).catch(response => {
                            this.displayPopup('Report could not be generated');
                            this.$emit('close');
                    });
                }
            },
            displayPopup (message) {
                this.$alert(message, 'Print Error', {
                    confirmButtonText: 'OK',
                });
            },
        }
    }
</script>
<style>


</style>
