<template>
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-12 col-md-8 align-self-center">
            <h3 class="text-navbackground">{{ route }}</h3> </div>
        </div>
</template>

<script>
	export default {

		props: {
			route: {
				type: String,
				required: true,
			},
		},
	}
</script>
<style scoped>
	h3 {
		white-space: nowrap;
	}
    .row.page-titles {
        z-index: 100;
		margin-top: 54px;
    }
	.availabilitycalendar .row.page-titles, .constraints .row.page-titles {
		z-index: 1;
	}
</style>
