<template>
	<div transition name="modal" :class="class">
		<div v-if="showMask" class="modal-mask">&nbsp;</div>
        <div v-if="showMask" class="modal-dialog" role="document" :style="(toppx ? 'top:' + toppx +';' : '') + (width ? 'max-width:' + width +';' : '')">
            <div class="modal-content" >
                <div class="modal-header">
					<button type="button" class="close v-toolbar__side-icon v-btn v-btn--icon theme--light"  @click.prevent="$emit('close')">
            			<div class="v-btn__content">
                    		<i v-if="showClose" class="fas fa-times"></i>
						</div>
					</button>
                    <slot name="header"></slot>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button class="modal-default-button" @click.prevent="$emit('close')">
                            OK
                        </button>
                    </slot>
                </div>
            </div>
        </div>

        <div v-else class="modal-dialog" role="document" :style="'z-index: 999  !important;' + (toppx ? 'top:' + toppx +';' : '') + (width ? 'max-width:' + width +';' : '')">
            <div class="modal-content">
                <div class="modal-header">
                    <i v-if="showClose" class="fas fa-times close" @click.prevent="$emit('close')"></i>
                    <slot name="header"></slot>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button class="modal-default-button" @click.prevent="$emit('close')">
                            OK
                        </button>
                    </slot>
                </div>
            </div>
        </div>
	</div>
</template>
<script>

export default {
    props: {
	    showModal : {
	    	type: Boolean,
		    required: false
		},
	    showMask : {
		    type: Boolean,
		    required: false,
		    default: true
	    },
	    showClose : {
		    type: Boolean,
		    required: false,
		    default: true
	    },
	    top : {
		    type: String,
		    required :false,
		    default: ''
	    },
        width : {
            type: String,
            required :false,
            default: ''
        },
        draggable : {
            type: Boolean,
            required :false,
            default: true
        },
        class : {
		    type: String,
		    required :false,
		    default: ''
        }
    },
	computed: {
    	toppx () {
		    var top  = window.pageYOffset || document.documentElement.scrollTop;
		    return (top - (-100)) + 'px';
	    }

	}
}
</script>
<style>
.modal-dialog .modal-content {
	border: 2px solid rgba(0, 0, 0, 0.2);
	background-color: #fff;
}
.modal-mask {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	transition: opacity .3s ease;
	display: block
}

div.modal-dialog {
	position: absolute;
    z-index: 2000;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
.modal-header {
	background-color: #eaeaea;
	max-height: 60px;
}
.modal-header i {
	cursor: pointer;
}
div.modal-dialog .close {
	position:absolute;
	top: 6px;
	right: 10px;
	font-size: 22px;
}

</style>
