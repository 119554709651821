<template>
	<div id="gamecancellationreport" v-loading="loading">
        <div class="row">
            <div class="col-12">
                <el-select  v-if="Programs.length > 1 && !MainLocationIDAccess" v-model="ProgramID"  placeholder="All Programs" class="topDropdown" @change="programChange" default-first-option filterable clearable>
                    <el-option
                        v-for="item in Programs"
                        :label="item.ProgramName"
                        :value="item.ProgramID"
                        :key="item.ProgramID">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-1">
                <el-select v-if="SiteLocations.length > 1 && !ProgramIDAccess" v-model="MainLocationIDs" ref="mainlocation" @change="locationChange" :placeholder="'All ' + config.Params.locationNames" class="topDropdown" multiple default-first-option filterable clearable>
                    <el-option v-for="type in SiteLocations"
                               :value="type.MainLocationID"
                               :key="type.MainLocationID"
                               :label="type.MainLocationName">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-if="ActiveSeasons.length > 1" class="row mt-1">
            <div class="col-12">
                <el-select class="topDropdown" v-model="SeasonID"  @change="seasonChange" placeholder="Select" default-first-option filterable>
                    <el-option v-for="season in ActiveSeasons"
                               :value="season.SeasonID"
                               :key="season.SeasonID"
                               :label="season.SeasonName">
                    </el-option>
                </el-select>
            </div>
        </div>
		<div class="row">
			<div class="col-12">
					<el-input
                            v-if="cancellations.length > 0"
							class="search"
							v-model="search"
							clearable
							placeholder="Type to search by date, team, or location">
					</el-input>
				<el-table v-if="cancellations.length > 0"
						  stripe border
						  :data="filteredCancellations"
						  style="width: 100%"
						  size="small"
						  max-height="600">
					<el-table-column
							prop="GameDateTime"
							label="Game Time"
							width="120px"
							align="left"
							sortable
							:sort-method="sortTimeSlotGameDate"
					>
					</el-table-column>
					<el-table-column
							prop="LocationName"
							label="Game/Location"
							min-width="130px"
							sortable>
						<template #default="scope">
							<div v-html="'<b>' + scope.row.GameDesc + '</b>'+ '<br>' + scope.row.LocationName "></div>
						</template>
					</el-table-column>
					<el-table-column
							prop="submittedwhen"
							label="Submission"
							min-width="130px"
							sortable
							:sort-method="sortSubmittedWhen">
                        <template #default="scope">
                            <div v-if="scope.row.Params" v-html="scope.row.Params.FirstName + ' ' + scope.row.Params.LastName + '<br>' + scope.row.Params.EmailAddress + '<br>' + scope.row.submittedwhen"></div>
                        </template>
					</el-table-column>
					<el-table-column
							prop="SubmitterTeamName"
							label="Submitting Team"
							sortable
							min-width="120px">
					</el-table-column>
					<el-table-column
							prop="WhoCancelled"
							label="Who Cancelled"
							sortable
							min-width="120px">
                        <template #default="scope">
                            <div v-if="scope.row.Params" v-html="(scope.row.WhoCancelled == 'Both teams' ? 'Both teams' : (scope.row.WhoCancelled == 'My team' ? (scope.row.Params.TeamID == scope.row.HomeTeamID ? scope.row.HomeTeamName : scope.row.VisitingTeamName) : (scope.row.Params.TeamID == scope.row.HomeTeamID ? scope.row.VisitingTeamName : scope.row.HomeTeamName)))"></div>
                        </template>
					</el-table-column>
					<el-table-column
							prop="MyTeamUsage"
							label="Submitting Team Usage"
							sortable
							width="130px">
					</el-table-column>
					<el-table-column
							prop="OtherTeamUsage"
							column-key="OtherTeamUsage"
							label="Other Team Usage"
							sortable
							width="130px">

						<template #default="scope">
							<template v-if="scope.row.operation == 'update'">
								<el-select style="width: 100%;min-height: 30px;" v-model="scope.row.OtherTeamUsage" placeholder="Select" size="small" default-first-option filterable clearable >
									<el-option
											v-for="item in teamUsage"
											:key="item"
											:label="item"
											:value="item">
									</el-option>
								</el-select>
							</template>
							<span v-else v-html="scope.row.OtherTeamUsage"></span>
						</template>
					</el-table-column>
                    <el-table-column
                        v-if="!ProgramIDAccess"
                        prop="Params.ReasonUsage"
                        label="Email Responses"
                        align="left"
                        white-space = "nowrap"
                        min-width="240px">
                        <template #default="scope">
                            <div v-if="scope.row.Params && scope.row.Params.ResponseUsage">
                                <div v-for="(time, index) in scope.row.Params.ResponseTime">
                                    <div class="responsetime">Time: {{time}}</div>
                                    <div class="responseusage">Usage: {{scope.row.Params.ResponseUsage[index]}}</div>
                                    <div class="responseemailaddress">Who: {{scope.row.Params.ResponseEmailAddress[index]}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="!ProgramIDAccess"
							prop="Params.Reason"
							label="Reason"
							align="left"
							white-space = "nowrap"
							min-width="240px">
					</el-table-column>
					<el-table-column
                        v-if="modifyAccess"
                             label="Edit"
                             width="130"
                             align="center">
						<template #default="scope">
							<button v-if="scope.row.operation == ''" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click.prevent=" scope.row.operation = 'update';">
								<div class="v-btn__content">
									<i class="far fa-2x fa-edit"></i>
								</div>
							</button>
							<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click.prevent="deleteCnfm(scope.$index, scope.row)">
								<div class="v-btn__content">
									<i class="far fa-2x fa-trash-alt"></i>
								</div>
							</button>
						</template>
					</el-table-column>
				</el-table>
                <div v-else-if="!loading" style="margin-top:10px; background: #F9f9f9;padding:10px; border: 1px solid #ccc;"><strong>No Game Cancellations</strong></div>
			</div>
		</div>
		<div class="row mt-4 mb-4" v-if="modifyAccess && cancellations.length > 0" >
			<div class="col-12 text-center">
				<button class="btn btn-success btn-icon" type="button" @click="save">
					<i class="fas fa-check"></i> <span> Save Changes</span>
				</button>
				<button class="btn btn-icon btn-secondary cancel" type="button"  @click="refreshData">
					<i class="fas fa-times"></i>  <span>Cancel</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'GameCancellationReport',
	    data() {
	        return {
                loading : true,
                currentStatus : STATUS_INITIAL,
                ProgramID : '',
                MainLocationIDs : [],
                SeasonID : '',
                Programs : [],
                ActiveSeasons : [],
                SiteLocations : [],
		        FormGameCancellationID : '',
		        search:'',
		        cancellations : [],
				teamUsage : ["Will not use ice","Will use Ice but do not need refs","Will use ice and needs refs", "Dispute"],
		        }
	    },
		props:["config", "parentrefs"],
	    computed: {
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
            modifyAccess () {
                return this.$modifyAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            MainLocationIDAccess () {
                return this.$MainLocationIDAccess();
            },
            TeamIDAccess () {
                return this.$TeamIDAccess();
            },
		    filteredCancellations () {
				var records = [];
				if (this.cancellations.length == 0 ) { return []; }
			    if (!this.search ) { return this.cancellations; }

				for (let i=0; i < this.cancellations.length; i++) {
					if ((this.cancellations[i].GameDateTime && this.cancellations[i].GameDateTime.toLowerCase().includes(this.search.toLowerCase())) ||
						(this.cancellations[i].LocationName && this.cancellations[i].LocationName.toLowerCase().includes(this.search.toLowerCase())) ||
						(this.cancellations[i].GameDesc && this.cancellations[i].GameDesc.toLowerCase().includes(this.search.toLowerCase())) ||
						(this.cancellations[i].SubmitterTeamName && this.cancellations[i].SubmitterTeamName.toLowerCase().includes(this.search.toLowerCase()))) {
						records.push(this.cancellations[i]);
					}

				}
				return records;
		    }
		},
		methods: {
			refreshData() {
				this.getCancellationData();
			},
            programChange () {
                this.getCancellationData();
            },
            seasonChange () {
                this.getCancellationData();
            },
            locationChange () {
                this.getCancellationData();
            },
			sortTimeSlotGameDate (a,b) {
				var ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
				if (ret == 0) {
					ret = parseInt(a.StartSeconds) - parseInt(b.StartSeconds);
				}
				return ret;
			},
			sortSubmittedWhen (a,b) {
				return parseInt(a.submittedwhen) - parseInt(b.submittedwhen);
			},
            getInitialData () {
			    this.loading = true;
                var tables = ['Programs', 'ActiveSeasons', 'SiteLocations'];
                axios.post('/index/tableData', {'tables': tables },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                            if (!this.SeasonID) {
                                this.SeasonID = this.ActiveSeasons[0]['SeasonID'];
	                            for (let  i = 0; i < this.ActiveSeasons.length; i++) {
		                            if (this.ActiveSeasons[i]['Params']['DefaultSeason'] == 'y') {
			                            this.SeasonID = this.ActiveSeasons[i]['SeasonID'];
			                            break;
		                            }
	                            }
                            }
                            this.getCancellationData();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
			getCancellationData () {
				let self = this;
				var dt = new Date();
				axios.post('/gamecancellation/getall', { id : this.FormGameCancellationID, ProgramID : this.ProgramID, SeasonID : this.SeasonID, MainLocationIDs : this.MainLocationIDs },
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.cancellations = response.data.cancellations;
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.$failProgressBar(this.parentrefs);
						}
                        this.loading = false;
					}).catch(response => {
					    this.$failProgressBar(this.parentrefs);
                        this.loading = false;
				});

			},
			deleteCnfm (index,row) {
				this.$confirm('Are you sure you wish to delete this cancellation?', 'Confirmation', {
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					type: 'warning',
					showClose: false,
					showCancelButton: true
				}).then(() => {
					this.deleteCancellation(index, row);
				}).catch(() => {
				});

			},
			deleteCancellation(index, row) {
				axios.post('/gamecancellation/delete', {FormGameCancellationID: row.FormGameCancellationID}
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.refreshData();
								this.$notify({
									title: 'Success',
									message: "Cancellation has been deleted",
									type: 'success',
									duration: 1500,
									showClose: false
								});
							} else {
								this.$notify({
									title: 'Error',
									message: "Cancellation cannot be deleted",
									type: 'error',
									duration: 2000,
									showClose: false
								});
							}
						}).catch(response => {
						this.$notify({
							title: 'Error',
							message: "Cancellation cannot be deleted",
							type: 'error',
							duration: 2000,
							showClose: false
						});
					});
				},
            save () {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                axios.post('/gamecancellation/save', {cancellations: this.cancellations}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_INITIAL;
                            this.$stopProgressBar(this.parentrefs);
                            this.refreshData();
                            this.$notify({
                                title: 'Success',
                                message: "Cancellation changes have been saved.",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.$failProgressBar(this.parentrefs);
                            this.$notify({
                                title: 'Error',
                                message: "No Cancellations saved.",
                                type: 'error',
                                duration: 2000,
                                showClose: false
                            });
                        }
                    }).catch(response => {
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                    this.$notify({
                        title: 'Error',
                        message: "Cancellation cannot be saved",
                        type: 'error',
                        duration: 2000,
                        showClose: false
                    });
                });

            },
			},
		mounted() {
			if (this.$route.params.id) {
				this.FormGameCancellationID = this.$route.params.id;
			}
			this.getInitialData();
		}
	}
</script>
<style>
	#gamecancellationreport .el-table .cell{
		word-break: keep-all;
	}
</style>

<style scoped>
	.search {
		float:right;
		width: 25%;
		margin-bottom: 15px;
	}
</style>
