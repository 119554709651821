<template>
	<div class="ds-schedule-times" style="position:relative;">
		<div v-if="$propertyAccess('AllowHours')" class="ds-time-row" style="height: 90px;">
			<div v-if="isConstraints" class="ds-time-cell">
				<el-checkbox
						ref="allDayCheckbox"
						class="ma-2 allday"
						style="width: 80px;"
						:readonly="isReadOnly || SlotType == 'g'"
						v-model="allDay"
				>{{labels.all}}</el-checkbox>
			</div>
			<div v-if="!allDay" class="ds-time-cell" :class="{'notAllDay' : !isConstraints}" >
				<el-select class="time start" v-model="starttime" :disabled="readOnly" @change="starttimeChange" placeholder="Start Time" popper-class="Analyzer" default-first-option filterable>
					<el-option
					  v-for="item in times"
					  :key="item.minutes"
					  :label="item.time"
					  :value="item.minutes">
					</el-option>
				</el-select>
			</div>
			<div v-if="!allDay" class="time-to" :class="{'notAllDay' : !isConstraints}" >to</div>
			<div v-if="!allDay" class="ds-time-cell"  :class="{'notAllDay' : !isConstraints}" >
				<el-select class="time" v-model="endtime" :disabled="readOnly" placeholder="End Time" popper-class="Analyzer" default-first-option filterable>
                    <template v-for="item in times">
					    <el-option
                          v-if="item.minutes > starttime"
						  :key="item.minutes"
						  :label="item.time"
						  :value="item.minutes">
					    </el-option>
                    </template>
				</el-select>
			</div>
			<div v-if="!allDay && !readOnly && !isConstraints && SlotType != 'g' && $propertyAccess('SplitSlots')" class="ds-time-cell-split" >
				<el-select class="time" v-model="splitSlotMinutes" placeholder="#" :disabled="$propertyDisabled('SplitSlots')" popper-class="Analyzer" default-first-option filterable clearable>
					<el-option
							v-for="item in slotsplits"
							:key="item"
							:label="item"
							:value="item">
					</el-option>
				</el-select><div class="text">When saving, split this slot<br>into separate entries of this<br>number of Minutes.</div>
			</div>
			<div class="ds-time-cell-split" v-else>&nbsp;</div>
		</div>
		<div v-if="!allDay" class="ds-time-row" style="position:absolute; top: 55px;">
            <template v-if="isConstraints">
                <div class="compareStartEndTimeLabel">Compare Games:</div>
                <div class="CompareStartEndTime">
                    <el-radio-group v-if="!allDay"  v-model="CompareStartEndTime">
                        <el-radio-button label="s">Start Time</el-radio-button>
                        <el-radio-button label="e">End Time</el-radio-button>
                    </el-radio-group>
                </div>
            </template>
            <template v-else>
                <div class="ds-time-cell">&nbsp;</div>
                <div class="ds-time-cell duration" :class="{'notAllDay' : !isConstraints}">
                    <div v-html="'Duration: ' + schedule.duration + ' Minutes'"></div>
                    <div v-html="'Setup Time: ' + (isNaN(GameSetup) ? 0 : GameSetup) + ' Minutes'"></div>
                </div>
                <div class="ds-time-cell">&nbsp;</div>
            </template>
		</div>
	</div>
</template>

<script>
import { Time, Schedule, Functions as fn } from 'dayspan';

export default {

    name: 'dsScheduleTimes',

    props:
    {
        schedule:
        {
            required: true,
            type: Schedule
        },

        details:
        {
            required: true,
            type: Object
        },

        readOnly:
        {
            type: Boolean,
            default: false
        },

        labels:
        {
            validate(x) {
                return this.$dsValidate(x, 'labels');
            },
            default() {
                return {
	                all:        'All day',
	                minute:     'minute',
	                minutes:    'minutes',
	                hour:       'hour',
	                hours:      'hours',
	                day:        'day',
	                days:       'days',
	                week:       'week',
	                weeks:      'weeks',
	                month:      'month',
	                months:     'months',
	                second:     'second',
	                seconds:    'seconds'
                };
            }
        },

        defaultTime:
        {
            type: String,
            default() {
                return '08:00';
            }
        },
        isConstraints:
        {
            type: Boolean,
            default: false
        },
        GameSetup:
        {
            type: Number,
            default: 0
        },
        SlotType:
        {
            type: String,
            default: ''
        },
  },

  computed:
  {
    durationOptions()
    {
		var singular = this.schedule.duration === 1;

		var duringDay = [
			{ text: singular ? this.labels.minute : this.labels.minutes, value: 'minutes' }
		];
		var allDay = [
			{ text: singular ? this.labels.day : this.labels.days, value: 'days' },
			{ text: singular ? this.labels.week : this.labels.weeks, value: 'weeks' },
			{ text: singular ? this.labels.month : this.labels.months, value: 'months' }
		];

		return this.allDay ? allDay : duringDay.concat( allDay );
    },
    isReadOnly()
    {
		return this.readOnly || this.$dayspan.readOnly;
    },
	times() {
		var x = 5; //minutes interval
		var tt = (this.$dayspan.startHour*60); // start time
		var ap = ['AM', 'PM']; // AM-PM
		var myTimes = [];

		for (var i=0;tt<24*60; i++) {
			var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
			var mm = (tt%60); // getting minutes of the hour in 0-55 format
			myTimes.push({minutes : tt, time : ("" + (hh == 12 ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh/12)]});
			tt = tt + x;
		}
		return myTimes;
	},
	  slotsplits() {
		  var mySlots = [];

		  for (var i=2;i<this.schedule.duration; i++) {
			  var slots = Math.floor(this.schedule.duration/i);
			  if (slots * i == this.schedule.duration) {
				//even number of divisions
				  mySlots.push(slots);
			  }
		  }
		  return mySlots;
	  }
  },

  data: vm => ({
    allDay: true,
	  endtime: 99999,
	  starttime: 0,
	  duration: '',
	  splitSlotMinutes:'',
      CompareStartEndTime : 's',
  }),

  watch:
  {
    schedule: {
      handler: 'updateAllDay',
      immediate: true
    },
        allDay: 'updateScheduleAllDay',
	  starttime: 'updateDuration',
	  endtime: 'updateDuration',
	  splitSlotMinutes : 'updateSplitSlotMinutes',
      CompareStartEndTime : 'updateCompareStartEndTime',
  },

  methods:
  {
	  starttimeChange(val) {
	  	let duration = this.schedule.duration;
		//let keep the same duration
		this.endtime = this.starttime + duration;

	  },
    updateAllDay()
    {
	    this.allDay = (this.$propertyAccess('AllowHours') ? this.schedule.isFullDay() : true);
	    if (this.allDay) {
			this.startTime = '';
			this.endTime = '';
			this.updateDuration();
	    }
    },

	  formatTime(time)
	  {
		  return  (time.hour <= 12 ? time.hour : (time.hour - 12)) + ':' + (time.minute < 10 ? "0" + time.minute :time.minute) + ' ' + (time.hour < 12 ? 'AM' : 'PM');
	  },
	  timeToMinutes(time)
	  {
		  return  (time.hour * 60) + time.minute;
	  },
	  minutesToTime(minutes)
	  {
		  var hours = parseInt(minutes / 60);
		  var min = minutes - (hours*60);
		  return {hour : hours, minute : (min < 10 ? "0" + min : min), millisecond: 0, second: 0};
	  },
	parseTime( t ) {
		var time = t.match( /(\d+)(?::(\d\d))?\s*(P?)/ );
		var hours =  parseInt( time[1]) + (time[3] && time[1] != 12 ? 12 : 0) ;
		var minutes = ( parseInt( time[2]) || 0 );
		return {hour : hours, minute : minutes, millisecond: 0, second: 0};
	},
	setTime()
	{
		var hours = parseInt(this.starttime / 60);
		var min = this.starttime - (hours*60);
		var parsed = Time.parse(hours+':'+(min < 10 ? "0" + min : min));

		if (parsed)
		{
			var ev = this.getTimeEvent('change', { next: parsed });
			this.changeTime(ev);
			if (!ev.handled)
			{
			  if (ev.mutate)
			  {
				  ev.time.set( ev.next );
				  parsed = ev.next;
			  }
			  this.$emit('input', parsed);
			}
		}
	},
    updateScheduleAllDay(allDay)
    {
      if (this.schedule.isFullDay() !== allDay)
      {
	      this.schedule.setFullDay( allDay );
	      var end = this.minutesToTime( this.endtime )
	      if (!allDay && end.hour) {
		      this.schedule.duration = this.endtime - this.starttime;
		      this.updateDuration()
	      }
      }
    },
	updateDuration() {
    	if (!this.allDay) {
		    this.schedule.duration = this.endtime - this.starttime;
		    this.schedule.durationUnit = 'minute';
		    this.splitSlotMinutes = '';
		    this.updateSplitSlotMinutes();
		    this.setTime();
		    this.triggerChange();
	    } else {
            this.schedule.duration = 1;
            this.schedule.durationUnit = 'days';
            this.setTime();
		    this.triggerChange();
	    }
	},
	updateSplitSlotMinutes() {
		var ev = this.getEvent('save');
		ev.details.splitSlotMinutes = this.splitSlotMinutes;
		this.$emit('save', ev);
	},
      updateCompareStartEndTime() {
          var ev = this.getEvent('save');
          ev.details.CompareStartEndTime = this.CompareStartEndTime;
          this.$emit('save', ev);
      },
    changeTime(ev)
    {
      ev.schedule = this.schedule;
      ev.updated = false;

      this.$emit('update', ev);

      if (!ev.handled && ev.schedule)
      {
        ev.updated = ev.schedule.moveTime( ev.time, ev.next );
        ev.handled = true;
      }

      this.$emit('change', ev);
    },

    triggerChange()
    {
      this.$emit('change', this.getEvent('change'));
    },

    getEvent(type, extra = {})
    {
      return fn.extend({

        type: type,
        schedule: this.schedule,
		details: this.details,
        handled: false,
        $vm: this,
        $element: this.$el

      }, extra);
    },
	  getTimeEvent(type, extra = {})
	  {
		  return fn.extend({

			  type: type,
			  time: this.schedule.times[0],
			  index: this.index,
			  mutate: this.mutate,
			  handled: false,
			  $vm: this,
			  $element: this.$el

		  }, extra);
	  }

  },
    mounted() {
  		if (this.schedule.times[ 0 ]) {
		    this.starttime = this.timeToMinutes(this.schedule.times[0]);
		    this.endtime = this.starttime + this.schedule.duration * (this.schedule.durationUnit == 'hours' ? 60 : 1);
		    if (this.schedule.durationUnit == 'hours') {
			    this.updateDuration();
		    }
	    } else {
		    this.starttime = (60*8);
		    this.endtime = this.starttime + 60;
		    durationUnit: "minute";
	    }
  		this.CompareStartEndTime = (this.details.CompareStartEndTime ? this.details.CompareStartEndTime  : 's');
    }
}
</script>

<style>
	.ma-2 label {
		margin-bottom:0;
	}
	.ds-time-row .v-label {
		white-space: nowrap;
	}
	 #app .el-select.time .el-input__inner {
		 border: 0;
		 border-radius: 0;
		 height : 35px;
		 color: #000;
		 font-size: 14px;
		 padding-left: 4px;
	 }
	#app .el-select.time .el-input .el-select__caret {
		color: #000;
	}
	#app .el-select.time {
		margin-left: 0px;
		width: 110px;
	}
	#app .el-select.time.start {
		margin-left: 30px;
	}
	#app .notAllDay .el-select.time {
		margin-left: 0px;
	}
</style>
<style scoped lang="scss">


.ds-schedule-times {
  margin-top: 7px;

  .ds-time-row {
    display: flex;

    .ds-time-cell {

      &.double {
        padding-right: 16px;
        flex: 2 0 0px;
      }
    }
	  .ds-time-cell.notAllDay {
		  flex: none;
	  }
	  label {
		  white-space: nowrap;
	  }
	  .ds-time-cell-split {
		  width: 325px;
		  padding-right: 8px;
		  padding-left: 24px;
	  }
	  .ds-time-cell-split .el-select {
		  width: 80px;
		  display: inline-block;
		  vertical-align: top;
		  margin-left: 8px;
	  }
	  .ds-time-cell-split div.text {
		  width: 225px;
		  display: inline-block;
		  padding-left: 8px;
		  font-size: 12px;
		  color: #000;
		  opacity: .54;
	  }
  }
	.el-input__wrapper {
		background-color: #F5F5F5;
	}
}
.ds-time-cell.duration {
    margin-left: 80px;
    width: 420px;
    font-weight: 800;
}
.compareStartEndTimeLabel {
    float:left;
    width: 125px;
    font-size: 15px;
    margin-top: 7px;
    margin-left: 8px;
}
.CompareStartEndTime {
    float:left;
     margin-left: 0px;
     width: 420px;
 }
	.time-to {
		width: 55px;
		display: inline-block;
		line-height: 33px;
		text-align: center;
	}
	.time-to.notAllDay {
		width: 46px;
	}
</style>
