<template>
	<div class="ds-expand ds-calendar-app">
		<ds-event-dialog ref="eventDialog"
		                 :calendar="calendar"
		                 :read-only="readOnly"
		                 :isConstraints="isConstraints"
		                 :config="config"
		                 :ProgramIDAccess="ProgramIDAccess"
		                 :TeamIDAccess="TeamIDAccess"
		                 :MainLocationIDAccess="MainLocationIDAccess"
		                 :Data="Data"
		                 @cancel="cancel"
		                 @saved="eventFinish"
		                 @event-update="eventUpdate"
		                 @event-remove="eventRemove"
		                 @event-create="eventCreate"
		                 @actioned="eventFinish"
		></ds-event-dialog>
		<div
			class="ds-app-calendar-toolbar"
			style="background-color: #FFFFFF;height: 52px;">
			<div style="float:left;">
				<button
					type="button"
					style="margin-top:8px;margin-left: 8px;"
					class="v-btn v-btn--depressed theme--light btn-secondary"
					@click="setToday">

					Today

				</button>

				<span v-if="currentType.id == 'D'" name="prevweek" >
					<button
						class="v-toolbar__side-icon v-btn v-btn--icon theme--light"
						@click="prevweek">
						<i class="fas fa-angle-double-left fa-2x"></i>
					</button>
				</span>

				<span name="prev">
					<button
						class="v-toolbar__side-icon v-btn v-btn--icon theme--light"
						@click="prev" >
					<i class="fas fa-angle-left fa-2x"></i>
					</button>
				</span>
				<span name="next" >
					<button
						class="v-toolbar__side-icon v-btn v-btn--icon theme--light"
						@click="next" >
					<i class="fas fa-angle-right fa-2x"></i>
					</button>
				</span>

				<span v-if="currentType.id == 'D'" name="nextweek" >
					<button
						class="v-toolbar__side-icon v-btn v-btn--icon theme--light"
						@click="nextweek">
					<i class="fas fa-angle-double-right fa-2x"></i>
					</button>
				</span>
			</div>

			<span style="float:left;" :style="'margin-top: ' + (filterDesc ? '10px;' :'17px;' ) ">
				<h5 class="title" style="margin-bottom: 0px;">
				<div style="line-height: 15px;">{{ summary }}</div>
				<div style="color: #dc3545; font-size: 11px; margin-top: 4px;" v-html="filterDesc"></div>
				</h5>
			</span>

			<div class="view" style="float:right;margin-top: 7px; margin-right: 7px;">
				<el-select v-model="currentTypeID" popper-class="Analyzer" class="type">
					<el-option v-for="type in types"
					           :key="type.id"
					           :value="type.id"
					           :label="type.label"
					>
					</el-option>
				</el-select>
			</div>

		</div>

		<div class="ds-expand" :style="'padding-left: 305px; height:' + (height - 25) + 'px;'" v-loading="loading">
			<div class="container ds-calendar-container fluid fill-height" style="box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);">
				<slot name="calendarAppCalendar" v-bind="{calendar, add, addAt, edit, viewDay, handleAdd, handleMove}">

					<ds-calendar ref="calendar"
					             :calendar="calendar"
					             :config="config"
					             :read-only="readOnly"
					             :cancelPlaceholder="cancelPlaceholder"
					             :locationids="(Splits == 'y' && LocationIDs.length > 0 ? LocationIDs : [1])"
								 :filterlocationids="LocationIDs"
					             :splits="(Splits == 'y' && LocationIDs.length > 0 ? 'y' : 'n')"
					             :Data="Data"
					             @add="add"
					             @add-at="addAt"
					             @edit="edit"
					             @view-day="viewDay"
					             @added="handleAdd"
					             @moved="handleMove"
					             @cancel-done="canceldone"
					></ds-calendar>

				</slot>
				<el-button v-if="!readOnly && createAccess" type="success" round size="large" class="btn-success ds-add-event-today" @click="addToday">+</el-button>
			</div>
		</div>
		<div class="navigation-drawer" >
			<el-collapse v-model="activePanels" @change="collapseChange">
				<el-collapse-item title="Calendar" name="1">
					<div class="pa-3" v-if="calendar">
						<ds-day-picker :span="calendar.span" @picked="rebuildtrigger"></ds-day-picker>
					</div>
				</el-collapse-item>
				<el-collapse-item title="Filters" name="2">
					<el-select v-if="AllLocations.length > 0" v-model="LocationIDs" :placeholder="'Select ' + config.Params.locationNames"
					           multiple filterable popper-class="Analyzer" default-first-option size="small" clearable
					>
						<el-option
							v-for="item in AllLocations"
							:key="item.LocationID"
							:label="item.LocationName"
							:value="item.LocationID">
						</el-option>
					</el-select>
					<el-select v-if="Data.Organizations.length > 0 && adminAccess" v-model="OrganizationID" placeholder="Select Organization"
					           filterable popper-class="Analyzer" default-first-option size="small" clearable
					           key="filterOrganization"
					>
						<el-option
							v-for="item in Data.Organizations"
							:key="item.OrganizationID"
							:label="item.OrganizationName"
							:value="item.OrganizationID">
						</el-option>
					</el-select>
					<el-select v-if="Data.ScheduleTypes && Data.ScheduleTypes.length > 0 && !isConstraints && adminAccess" v-model="ScheduleTypeIDs" placeholder="Select Schedule Type(s)"
					           multiple filterable popper-class="Analyzer" default-first-option size="small" clearable
					           key="filterScheduleTypes"
					>
						<el-option
							v-for="item in Data.ScheduleTypes"
							:key="item.ScheduleTypeID"
							:label="item.ScheduleTypeName"
							:value="item.ScheduleTypeID">
						</el-option>
					</el-select>
					<el-select v-if="Data.Programs && Data.Programs.length > 0  && adminAccess" v-model="ProgramID" :placeholder="'Select ' + config.Params.programName"
					           filterable popper-class="Analyzer" default-first-option size="small" clearable @change="programChange"
					           key="filterPrograms"
					>
						<el-option
							v-for="item in Data.Programs"
							:key="item.ProgramID"
							:label="item.ProgramName"
							:value="item.ProgramID">
						</el-option>
					</el-select>
					<el-select v-if="Data.Programs && Data.Teams  && !MainLocationIDAccess" v-model="TeamID" placeholder="Select Team"
					           filterable popper-class="Analyzer" default-first-option size="small" clearable
					           key="filterTeams"
					>
						<el-option
							v-for="item in programTeams"
							:key="item.TeamID"
							:label="item.TeamName"
							:value="item.TeamID">
						</el-option>
					</el-select>
					<el-select v-if="Data.Leagues && Data.Leagues.length > 0 && isConstraints && adminAccess" v-model="LeagueID" placeholder="Select League"
					           filterable popper-class="Analyzer" default-first-option size="small" clearable
					           @change="leagueChange"
					           key="filterLeagues"
					>
						<el-option
							v-for="item in Data.Leagues"
							:key="item.LeagueID"
							:label="item.LeagueName"
							:value="item.LeagueID">
						</el-option>
					</el-select>
					<el-select v-if="Data.DivisionTypes[LeagueID] && Data.DivisionTypes[LeagueID][this.currentSeasonID] && isConstraints && adminAccess" v-model="DivisionTypeIDs" placeholder="Select Division Type(s)"
					           multiple filterable popper-class="Analyzer" default-first-option size="small" clearable
					           key="filterDivisionTypes"
					>
						<el-option
							v-for="item in Data.DivisionTypes[LeagueID][this.currentSeasonID]"
							:key="item.DivisionTypeID"
							:label="item.DivisionTypeName"
							:value="item.DivisionTypeID">
						</el-option>
					</el-select>
					<el-select v-if="$propertyAccess('Locations') && Data.MainLocations && Data.MainLocations.length > 0 && isConstraints" v-model="MainLocationIDs" :placeholder="'Select ' + config.Params.mainlocationNames"
					           filterable popper-class="Analyzer" default-first-option size="small" clearable multiple
					           key="MainLocations"
					>
						<el-option
							v-for="item in Data.MainLocations"
							:key="item.MainLocationID"
							:label="item.MainLocationName"
							:value="item.MainLocationID">
						</el-option>
					</el-select>

					<span v-if="!isConstraints && adminAccess">
                    <div class="el-input__inner" style="padding: 0px; min-height: 35px; height: auto; line-height: 33px;width: 90%;margin-left: 5px;padding-left: 5px;">
                        <el-tag
	                        v-for="tag in TagLinks"
	                        :key="tag.TagID"
	                        closable
	                        effect="dark"
	                        :type="tag.TagType"
	                        :disable-transitions="false"
	                        @close="handleCloseTag(tag)"
                        >
                            {{tag.TagName}}
                        </el-tag>
                        <el-select v-if="tagSelectVisible" key="selecttags" v-model="newTagID" ref="saveTagInput" size="small" style="width: auto;margin:0px" @change="handleNewTag" popper-class="Analyzer" default-first-option clearable filterable>
                            <el-option
	                            v-for="item in availableTags"
	                            :key="item.TagID"
	                            :label="item.TagName"
	                            :value="item.TagID"
                            >
                                <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

                            </el-option>
                        </el-select>
                        <el-button v-else-if="availableTags.length > 0" class="button-new-tag" size="small" @click="showTagSelect">+ Add Tag</el-button>
                    </div>
                </span>
					<span v-if="!isConstraints">
                    <el-checkbox true-label="y" false-label="n" v-model="ShowOpenSlots">Show Open Slots</el-checkbox>
                    <el-checkbox true-label="y" false-label="n" v-model="ShowGames">Show Games</el-checkbox>
                    <el-checkbox v-if="$propertyAccess('Practices') && !$propertyDisabled('Practices')" true-label="y" false-label="n" v-model="ShowPractices">Show Practices/Skills</el-checkbox>
                    <el-checkbox v-if="$propertyAccess('3rdParty') && !$propertyDisabled('3rdParty')" true-label="y" false-label="n" v-model="ShowEvents">Show 3rd Party Events</el-checkbox>
                    <el-checkbox true-label="y" false-label="n" v-model="Splits">Separate Calendar for Each {{config.Params.locationName}}</el-checkbox>
                </span>
					<div v-if="$propertyAccess('Export')" style="padding: 6px;width: 275px;">
						<span class="el-checkbox__label">Export Date Range</span>
						<el-date-picker
							v-model="SlotDates"
							style="width: 270px;"
							type="daterange"
							unlink-panels
							size="small"
							range-separator="To"
							start-placeholder="Start date"
							end-placeholder="End date"
							format="MMM D, YYYY"
							value-format="YYYY-MM-DD">
						</el-date-picker>
					</div>
					<div style="margin-left: 6px;margin-top: 10px;">
						<el-button type="primary" class="btn-primary" style="position: relative;" @click="triggerFilterChange"><i class="fas fa-sync-alt"></i> Refresh Calendar</el-button>
					</div>

				</el-collapse-item>
				<el-collapse-item v-if="!readOnly && $propertyAccess('MassChanges')" title="Mass Changes" name="3">
					<el-select v-model="MassColor" class="fifty color mt-2" :class="colorClass()" placeholder="Select Color" size="small" popper-class="Analyzer" default-first-option filterable clearable>
						<el-option
							v-for="item in colors"
							:key="item.value"
							:label="item.text"
							:value="item.value">
							<div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.value + ';'">{{ item.text }}</div>
						</el-option>
					</el-select>
					<span v-if="!isConstraints && $propertyAccess('Tags') && !$propertyDisabled('Tags')">
                    <div class="el-input__inner" style="padding: 0px; min-height: 35px; height: auto; line-height: 33px;width: 90%;margin-left: 5px;padding-left: 5px;">
                        <el-tag
	                        v-for="tag in MassTagLinks"
	                        :key="'M'+tag.TagID"
	                        closable
	                        effect="dark"
	                        :type="tag.TagType"
	                        :disable-transitions="false"
	                        @close="handleCloseMassTag(tag)"
                        >
                            {{tag.TagName}}
                        </el-tag>
                        <el-select v-if="tagMassSelectVisible" key="selectmasstags" v-model="newMassTagID" ref="saveMassTagInput" size="small" style="width: auto;margin:0px" @change="handleNewMassTag" popper-class="Analyzer" default-first-option clearable filterable>
                            <el-option
	                            v-for="item in availableMassTags"
	                            :key="item.TagID"
	                            :label="item.TagName"
	                            :value="item.TagID"
                            >
                                <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

                            </el-option>
                        </el-select>
                        <el-button v-else-if="availableMassTags.length > 0" class="button-new-tag" size="small" @click="showMassTagSelect">+ Add Tag</el-button>
                    </div>

                    <el-input v-if="$propertyAccess('Costs') && !$propertyDisabled('Costs')" type="number" class="smallMassCurrency" placeholder="Cost" v-model="MassLocationCost" size="small" style="float:left;width: 100px;margin-left: 5px;margin-top: 5px;">
                    </el-input>
                    <el-input v-if="$propertyAccess('Fees') && !$propertyDisabled('Fees')" type="number" class="smallMassCurrency" placeholder="Fee" v-model="MassRinkFee" size="small" style="float:left;width: 100px;margin-left: 5px;margin-top: 5px;">
                    </el-input>
                    <el-radio-group v-if="$propertyAccess('Fees') && !$propertyDisabled('Fees')" v-model="MassHourlyFlat" size="small" style="float:left; margin-left: 5px;margin-top: 5px;" >
                        <el-radio-button label="h">Hourly</el-radio-button>
                        <el-radio-button label="f">Flat</el-radio-button>
                    </el-radio-group>
                </span>
					<el-select v-if="Data.ScheduleTypes && Data.ScheduleTypes.length > 0 && !isConstraints && $propertyAccess('ScheduleTypes') && !$propertyDisabled('ScheduleTypes')" v-model="MassScheduleTypeIDs" placeholder="Select Schedule Type(s)"
					           multiple filterable popper-class="Analyzer" default-first-option size="small" clearable
					           key="filterScheduleTypes"
					           style="margin-top: 0px;"
					>
						<el-option
							v-for="item in Data.ScheduleTypes"
							:key="item.ScheduleTypeID"
							:label="item.ScheduleTypeName"
							:value="item.ScheduleTypeID">
						</el-option>
					</el-select>
					<span v-if="!isConstraints && $propertyAccess('Tags') && !$propertyDisabled('Tags')">
                    <el-checkbox true-label="y" false-label="n" v-model="MassRemoveAllTags">Remove All Tags</el-checkbox>
                    <el-checkbox true-label="y" false-label="n" v-model="MassRemoveAllTypes">Remove All Schedule Types</el-checkbox>
                </span>
					<span v-if="!isConstraints">
                    <el-radio-group style="margin-left: 5px;" size="small" v-model="MassReplace" >
                        <el-radio-button label="R">Replace Values</el-radio-button>
                        <el-radio-button label="A">Add to Values</el-radio-button>
                    </el-radio-group>
                </span>
					<el-button  style="margin-left: 5px;" class="mt-4 btn-warning" type="warning" @click="cnfmMassChange('update')">
						<i class="fas fa-save"></i>  <span>MASS Change all Visible Slots</span>
					</el-button>
					<el-button  style="margin-left: 5px;" class="mt-4 btn-danger" type="danger"  @click="cnfmMassChange('delete')">
						<i class="fas fa-times-circle"></i>  <span> Delete All Visible Slots</span>
					</el-button>
					<div v-if="!isConstraints" style="font-size: 11px;line-height: 13px;padding:5px;">Important: Slots attached to Games cannot be deleted.  Game must be unassigned from the Slot first.</div>
				</el-collapse-item>
			</el-collapse>
			<div class="mt-1 mb-4">
				<el-button v-if="createAccess && $propertyAccess('Import')" style="position: relative;margin-left: 6px;" :disabled="$propertyDisabled('Import')" class="mt-4 btn-success" type="success" @click="showModal = true;">
					<i class="fas fa-file-import"></i>  <span>Import </span>
				</el-button>

				<el-button v-if="Data.AllSlots.length > 0 && $propertyAccess('Export')"  style="float:right;margin-right: 16px;" :disabled="$propertyDisabled('Export')" class="mt-4 btn-secondary exportButton" type="info"  @click="exportCSV()">
					<span>Export</span> <i class="fas fa-file-export"></i>
				</el-button>
			</div>

		</div>
		<modal v-if="showModal" :showMask="false" @close="showModal = false" top="400px" width="700px">
			<template v-slot:header>
				<h5 class="modal-title">
					<span v-html="(isConstraints ? 'Import Constraints' : 'Import Calendar Slots')"></span>
				</h5>
			</template>
			<template v-slot:body>
				<Imports :config="config" :Calling="(isConstraints ? 'Constraint Slots' : 'Calendar Slots')" @refresh="triggerFilterChange" />
			</template>
			<template v-slot:footer>
				&nbsp;
			</template>
		</modal>
	</div>
</template>

<script>
import { Constants, Sorts, Calendar, Day, Units, Weekday, Month, DaySpan, PatternMap, Time, Op } from 'dayspan';
import axios from 'axios';
import Util from '../../../assets/js/util.js';
import Modal from '../../global/Modal.vue';
import Imports from '../../Imports.vue';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {

	data: vm => ({
		loading: false,
		showModal :  false,
		cancelPlaceholder : false,
		drawer: null,
		promptVisible: false,
		promptQuestion: '',
		promptCallback: null,
		LocationIDs: [],
		MainLocationIDs: [],
		ScheduleTypeIDs : [],
		LeagueID : '',
		OrganizationID : '',
		ProgramID : '',
		TeamID : '',
		DivisionTypeIDs : [],
		ShowGames: 'n',
		ShowPractices: 'n',
		ShowEvents: 'n',
		ShowOpenSlots: 'y',
		uploadReady : true,
		Splits : 'n',
		SlotDates : [],
		TagLinks : [],
		newTagID: '',
		tagSelectVisible: false,
		MassScheduleTypeIDs : [],
		MassTagLinks : [],
		newMassTagID: '',
		tagMassSelectVisible: false,
		MassColor : '',
		MassHourlyFlat : 'f',
		MassRinkFee : '',
		MassLocationCost : '',
		MassReplace : 'A',
		MassRemoveAllTags : 'n',
		MassRemoveAllTypes : 'n',
		MassSetDefaultToZero : 'n',
		activePanels:['1','2'],
		colors :
			[
				{ text: 'Red', value: '#F44336' },
				{ text: 'Pink', value: '#E91E63' },
				{ text: 'Purple', value: '#9C27B0' },
				{ text: 'Deep Purple', value: '#673AB7' },
				{ text: 'Indigo', value: '#3F51B5' },
				{ text: 'Blue', value: '#2196F3' },
				{ text: 'Highlight Color', value: '#1976d2' },
				{ text: 'Light Blue', value: '#03A9F4' },
				{ text: 'Cyan', value: '#00BCD4' },
				{ text: 'Teal', value: '#009688' },
				{ text: 'Green', value: '#4CAF50' },
				{ text: 'Light Green', value: '#8BC34A' },
				{ text: 'Lime', value: '#CDDC39' },
				{ text: 'Yellow', value: '#FFEB3B' },
				{ text: 'Amber', value: '#FFC107' },
				{ text: 'Orange', value: '#FF9800' },
				{ text: 'Deep Orange', value: '#FF5722' },
				{ text: 'Brown', value: '#795548' },
				{ text: 'Blue Gray', value: '#607D8B' },
				{ text: 'Gray', value: '#9E9E9E' },
				{ text: 'Black', value: '#000000' }
			],
		currentTypeID : ''
	}),
	name: 'dsCalendarApp',
	components: {
		Modal,
		Imports
	},
	props:
		{
			events:
				{
					type: Array
				},
			calendar:
				{
					type: Calendar,
					default() {
						return Calendar.months();
					}
				},
			readOnly:
				{
					type: Boolean,
					default: false
				},
			height: {
				type: Number,
				default() {
					return 300;
				}
			},
			types:
				{
					type: Array,
					default() {
						if (this) {
							return this.$dsDefaults().dsCalendarApp.types;
						} else { return [
							{
								id: 'D',
								label: 'Day',
								shortcut: 'D',
								type: Units.DAY,
								size: 1,
								focus: 0.4999,
								repeat: true,
								listTimes: true,
								updateRows: true,
								schedule: false
							},
							{
								id: 'W',
								label: 'Week',
								shortcut: 'W',
								type: Units.WEEK,
								size: 1,
								focus: 0.4999,
								repeat: true,
								listTimes: true,
								updateRows: true,
								schedule: false
							},
							{
								id: 'M',
								label: 'Month',
								shortcut: 'M',
								type: Units.MONTH,
								size: 1,
								focus: 0.4999,
								repeat: true,
								listTimes: false,
								updateRows: true,
								schedule: false
							},
							{
								id: 'X',
								label: '4 Days',
								shortcut: 'X',
								type: Units.DAY,
								size: 4,
								focus: 0.4999,
								repeat: true,
								listTimes: true,
								updateRows: true,
								schedule: false
							}
						]; }
					}
				},
			allowsAddToday:
				{
					type: Boolean,
					default() {
						return true;
					}
				},
			formats:
				{
					validate(x) {
						if (this) {
							return this.$dsValidate(x, 'formats');
						} else { return {}; }
					},
					default() {
						if (this) {
							return this.$dsDefaults().dsCalendarApp.formats;
						} else { return {}; }
					}
				},
			labels:
				{
					validate(x) {
						if (this) {
							return this.$dsValidate(x, 'labels');
						} else { return {}; }
					},
					default() {
						if (this) {
							return this.$dsDefaults().dsCalendarApp.labels;
						} else { return {}; }
					}
				},
			styles:
				{
					validate(x) {
						if (this) {
							return this.$dsValidate(x, 'styles');
						} else { return {}; }
					},
					default() {
						if (this) {
							return this.$dsDefaults().dsCalendarApp.styles;
						} else { return {}; }
					}
				},
			promptDialog:
				{
					validate(x) {
						if (this) {
							return this.$dsValidate(x, 'promptDialog');
						} else { return {}; }
					},
					default() {
						if (this) {
							return this.$dsDefaults().dsCalendarApp.promptDialog;
						} else { return {}; }
					}
				},
			ProgramIDAccess:
				{
					type: Boolean,
					default: false
				},
			MainLocationIDAccess:
				{
					type: Boolean,
					default: false
				},
			TeamIDAccess:
				{
					type:
					Array,
					default() {
						[];
					}
				},
			createAccess:
				{
					type: Boolean,
					default: false
				},
			modifyAccess:
				{
					type: Boolean,
					default: false
				},

			AllLocations:
				{
					type: Array,
					default() {
						[];
					}
				},
			Data:
				{
					type: Object,
					default() {
						{};
					}
				},
			isConstraints:
				{
					type: Boolean,
					default: false
				},
			config:
				{
					type: Object,
					default() {
						{};
					}
				},
			parentrefs:
				{
					type: Object,
					default() {
						{};
					}
				},
		},

	watch:
		{
			'events': 'applyEvents',
			'calendar': 'applyEvents',
			height: {
				handler(newVal, oldVal) {
					var x = document.getElementsByClassName("navigation-drawer");
					x[0].style.height = (newVal-20) + 'px';
				},
				deep: true
			},
			Data : function(newVal, oldVal) {
				if (this.ProgramIDAccess && this.Data.Programs[0]) {
					this.ProgramID = this.Data.Programs[0].ProgramID;
				}
				if (this.Data.Leagues.length == 1) {
					this.LeagueID = this.Data.Leagues[0].LeagueID;
				}
				if (this.Data.Programs.length == 1) {
					this.ProgramID = this.Data.Programs[0].ProgramID;
				}
				if (this.ProgramID && this.Data.Teams && this.Data.Teams[this.ProgramID] && this.Data.Teams[this.ProgramID].length == 1) {
					this.TeamID = this.Data.Teams[this.ProgramID][0].TeamID;
				}
			},
			currentTypeID : function(newVal, oldVal) {
				for (let i = 0; i < this.types.length; i++) {
					if (this.types[i].id == newVal) {
						this.currentType = this.types[i];
						break;
					}
				}
			},
		},

	computed:
		{
			currentType:
				{
					get()
					{
						var currenttype = this.types.find((type) =>
							type.type === this.calendar.type &&
							type.size === this.calendar.size
						) || this.types[0];
						this.currentTypeID = currenttype.id;
						return currenttype;
					},
					set(type)
					{
						this.currentTypeID = type.id;
						this.rebuild(undefined, true, type);
						this.triggerChange();
					},
				},
			programTeams () {
				let teams = [];
				if (this.isConstraints) {
					if (this.currentSeasonID) {
						if (this.ProgramID && this.ProgramID > 0) {
							for (let i = 0; i < this.Data.Teams[this.ProgramID].length; i++) {
								if (this.Data.Teams[this.ProgramID][i]['SeasonID'] == this.currentSeasonID) {
									teams.push(this.Data.Teams[this.ProgramID][i]);
								}
							}
						} else {
							//this.ProgramID = this.Data.Programs[0]['ProgramID'];
							if (this.TeamIDAccess.length > 0 && this.Data.Teams[this.ProgramID]) {
								for (let i = 0; i < this.Data.Teams[this.ProgramID].length; i++) {
									if (this.TeamIDAccess.indexOf(this.Data.Teams[this.ProgramID][i]['TeamID']) !== -1) {
										teams.push(this.Data.Teams[this.ProgramID][i]);
									}
								}
							}
						}
					}
				} else {
					if (this.currentSeasonID) {
						if (this.ProgramID && this.ProgramID > 0) {
							if (this.Data.Teams && this.Data.Teams[this.currentSeasonID]) {
								for (let i = 0; i < this.Data.Teams[this.currentSeasonID].length; i++) {
									if (this.Data.Teams[this.currentSeasonID][i]['ProgramID'] == this.ProgramID) {
										teams.push(this.Data.Teams[this.currentSeasonID][i]);
									}
								}
							}
						} else if (this.TeamIDAccess) {
							if (this.Data.Teams && this.Data.Teams[this.currentSeasonID]) {
								for (let i = 0; i < this.Data.Teams[this.currentSeasonID].length; i++) {
									if (this.TeamIDAccess.indexOf(this.Data.Teams[this.currentSeasonID][i]['TeamID']) !== -1) {
										teams.push(this.Data.Teams[this.currentSeasonID][i]);
									}
								}
							}
						}
					}
				}
				return teams;
			},
			summary()
			{
				let small = Util.isMobile();

				if (small)
				{
					return this.calendar.start.format( this.formats.xs );
				}

				return this.calendar.summary(false, false, false, false);
			},
			filterDesc ()
			{
				var desc = '';
				if (this.LocationIDs.length > 0) {
					desc += '<strong><u>' + this.config.Params.locationName + '(s)</u>:</strong>';
					for (let j = 0; j < this.LocationIDs.length; j++) {
						for (let i = 0; i < this.AllLocations.length; i++) {
							if (this.LocationIDs[j] == this.AllLocations[i]['LocationID']) {
								desc += ' ' + this.AllLocations[i]['LocationName'] + ',';
							}
						}
					}
				}
				desc = desc.replace(/,\s*$/, "");

				if (this.TeamID) {
					for (let i = 0; i < this.Data.Teams.length; i++) {
						if (this.Data.Teams[i]['TeamID'] == this.TeamID) {
							desc += (desc ? ' | ' : ' ') +'<strong><u>Team</u>:</strong> ';
							desc += ' ' + this.Data.Teams[i]['TeamName'] + ',';
						}
					}
				}
				desc = desc.replace(/,\s*$/, "");

				if (this.ProgramID && this.ProgramID > 0) {
					for (let i = 0; i < this.Data.Programs.length; i++) {
						if (this.Data.Programs[i]['ProgramID'] == this.ProgramID) {
							desc += (desc ? ' | ' : ' ') +'<strong><u>' + this.config.Params.programName + '</u>:</strong> ';
							desc += ' ' + this.Data.Programs[i]['ProgramName'] + ',';
						}
					}
				}
				desc = desc.replace(/,\s*$/, "");

				if (this.LeagueID && this.LeagueID > 0) {
					for (let i = 0; i < this.Data.Leagues.length; i++) {
						if (this.Data.Leagues[i]['LeagueID'] == this.LeagueID) {
							desc += (desc ? ' | ' : ' ') +'<strong><u>League</u>:</strong> ';
							desc += ' ' + this.Data.Leagues[i]['LeagueName'] + ',';
						}
					}
				}
				desc = desc.replace(/,\s*$/, "");

				if (this.OrganizationID && this.OrganizationID > 0) {
					for (let i = 0; i < this.Data.Organizations.length; i++) {
						if (this.Data.Organizations[i]['OrganizationID'] == this.OrganizationID) {
							desc += (desc ? ' | ' : ' ') +'<strong><u>Org</u>:</strong> ';
							desc += ' ' + this.Data.Organizations[i]['OrganizationName'] + ',';
						}
					}
				}
				desc = desc.replace(/,\s*$/, "");

				if (this.ScheduleTypeIDs.length > 0) {
					desc += (desc ? ' | ' : ' ') +'<strong><u>Sched Types</u>:</strong> ';
					for (let i = 0; i < this.Data.ScheduleTypes.length; i++) {
						if (this.ScheduleTypeIDs.indexOf(this.Data.ScheduleTypes[i]['ScheduleTypeID']) != -1) {
							desc += ' ' + this.Data.ScheduleTypes[i]['ScheduleTypeName'] + ',';
						}
					}
				}
				desc = desc.replace(/,\s*$/, "");

				if (this.DivisionTypeIDs.length > 0 && this.Data.DivisionTypes[this.LeagueID] && this.Data.DivisionTypes[this.LeagueID][this.currentSeasonID]) {
					desc += (desc ? ' | ' : ' ') +'<strong><u>Div Types</u>:</strong> ';
					for (let i = 0; i < this.Data.DivisionTypes[this.LeagueID][this.currentSeasonID].length; i++) {
						if (this.DivisionTypeIDs.indexOf(this.Data.DivisionTypes[this.LeagueID][this.currentSeasonID][i]['DivisionTypeID']) != -1) {
							desc += ' ' + this.Data.DivisionTypes[this.LeagueID][this.currentSeasonID][i]['DivisionTypeName'] + ',';
						}
					}
				}
				desc = desc.replace(/,\s*$/, "");

				return desc;

			},
			todayDate()
			{
				return this.$dayspan.today.format( this.formats.today );
			},

			toolbarStyle()
			{
				let large = (!Util.isMobile());

				return large ? this.styles.toolbar.large : this.styles.toolbar.small;
			},

			hasCreatePopover()
			{
				return false;
			},

			canAddDay()
			{
				return this.$dayspan.features.addDay && !this.readOnly && !this.$dayspan.readOnly;
			},

			canAddTime()
			{
				return this.$dayspan.features.addTime && !this.readOnly && !this.$dayspan.readOnly;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
			availableTags () {
				var tags = [];
				var tagOk = true;
				for (let i = 0; i < this.Data.Tags.length; i++) {
					tagOk = true;
					for (let j = 0; j < this.TagLinks.length; j++) {
						if (this.Data.Tags[i].TagID == this.TagLinks[j].TagID) {
							tagOk = false;
							break;
						}
					}
					if (this.Data.Tags[i].TagArea != 'SC') {
						tagOk = false;
					}
					if (tagOk) {
						tags.push(this.Data.Tags[i]);
					}
				}
				return tags;
			},
			availableMassTags () {
				var tags = [];
				var tagOk = true;

				for (let i = 0; i < this.Data.Tags.length; i++) {
					tagOk = true;
					for (let j = 0; j < this.MassTagLinks.length; j++) {
						if (this.Data.Tags[i].TagID == this.MassTagLinks[j].TagID) {
							tagOk = false;
							break;
						}
					}
					if (this.Data.Tags[i].TagArea != 'SC') {
						tagOk = false;
					}
					if (tagOk) {
						tags.push(this.Data.Tags[i]);
					}
				}
				return tags;

			},
			adminAccess () {
				return (this.ProgramIDAccess || this.MainLocationIDAccess || this.TeamIDAccess.length > 0 ? false : true);
			},
			currentSeasonID () {
				var earlydate = this.calendar.days[0].dayIdentifier;
				for (let i = 0; i < this.Data.Seasons.length; i++) {
					if (this.Data.Seasons[i].SeasonStartDate.replace(/-/g,'') <= earlydate && this.Data.Seasons[i].SeasonEndDate.replace(/-/g,'') >= earlydate) {
						return this.Data.Seasons[i].SeasonID;
					}
				}
			}
		},

	mounted()
	{

		if (!this.$dayspan.promptOpen)
		{
			this.$dayspan.promptOpen = (question, callback) => {
				this.promptVisible = false;
				this.promptQuestion = question;
				this.promptCallback = callback;
				this.promptVisible = true;
			};
		}
		var filters = JSON.parse(localStorage.getItem('filters'+(this.isConstraints ? 'CST' : '')));
		if (filters) {
			this.LocationIDs = (filters.locations ? filters.locations : []);
			this.MainLocationIDs = (filters.mainlocations ? filters.mainlocations : []);
			this.ScheduleTypeIDs = (filters.scheduletypes ? filters.scheduletypes : []);
			this.OrganizationID = (filters.organizationid ? filters.organizationid : '');
			this.ProgramID = (filters.programid ? filters.programid : '');
			this.LeagueID = (filters.leagueid ? filters.leagueid : '');
			this.TeamID = (filters.teamid ? filters.teamid : '');
			this.DivisionTypeIDs = (filters.divisiontype ? filters.divisiontypes : []);
			this.ShowGames = (filters.showgames ? filters.showgames : 'n');
			this.Splits = (filters.splits ? filters.splits : 'n');
			this.ShowPractices = (filters.showpractices ? filters.showpractices : 'n');
			this.ShowEvents = (filters.showevents ? filters.showevents : 'n');
			this.ShowOpenSlots = (filters.showopenslots ? filters.showopenslots : 'n');
			this.TagLinks = (filters.taglinks ? filters.taglinks : []);
		}

		var panels = JSON.parse(localStorage.getItem('calendarpanels'));
		if (panels) {
			this.activePanels = panels;
		}
		this.currentTypeID = this.currentType.id;
	},

	methods:
		{
			collapseChange () {
				localStorage.setItem('calendarpanels', JSON.stringify(this.activePanels));
			},
			colorClass () {
				for (let i = 0; i < this.colors.length; i++) {
					if (this.MassColor == this.colors[i].value) {
						return this.colors[i].text.replace(' ','_');
					}
				}
				return 'white';
			},
			handleNewTag() {
				let newTagID = this.newTagID;
				if (newTagID) {
					for (let i = 0; i < this.Data.Tags.length; i++) {
						if (this.Data.Tags[i].TagID == newTagID) {
							this.TagLinks.push(this.Data.Tags[i]);
							break;
						}
					}
				}
				this.tagSelectVisible = false;
				this.newTagID = '';
			},
			handleCloseTag(tag) {
				this.TagLinks.splice(this.TagLinks.indexOf(tag), 1);
			},
			showTagSelect() {
				this.tagSelectVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.focus();
				});
			},
			handleNewMassTag() {
				let newTagID = this.newMassTagID;
				if (newTagID) {
					for (let i = 0; i < this.Data.Tags.length; i++) {
						if (this.Data.Tags[i].TagID == newTagID) {
							this.MassTagLinks.push(this.Data.Tags[i]);
							break;
						}
					}
				}
				this.tagMassSelectVisible = false;
				this.newMassTagID = '';
			},
			handleCloseMassTag(tag) {
				this.MassTagLinks.splice(this.MassTagLinks.indexOf(tag), 1);
			},
			showMassTagSelect() {
				this.tagMassSelectVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveMassTagInput.focus();
				});
			},
			setState(state)
			{
				state.eventSorter = state.listTimes
					? Sorts.List([Sorts.FullDay, Sorts.Start])
					: Sorts.Start;

				this.calendar.set( state );
				if (state.hasOwnProperty('around') && state.around) {
					this.calendar.refresh(Day.parse(state.around));
				}
			},
			programChange() {
				this.TeamID = '';
			},
			leagueChange() {
				this.DivisionTypeIDs = [];
			},

			applyEvents()
			{
				if (this.events)
				{
					this.calendar.removeEvents();
					this.calendar.addEvents(this.events);
				}
			},

			isType(type, aroundDay)
			{
				let cal = this.calendar;

				return (cal.type === type.type && cal.size === type.size &&
					(!aroundDay || cal.span.matchesDay(aroundDay)));
			},
			rebuildtrigger(aroundDay) {
				this.rebuild(aroundDay);
				this.triggerChange();
			},
			rebuild (aroundDay, force, forceType)
			{
				let type = forceType || this.currentType || this.types[ 2 ];

				if (this.isType( type, aroundDay ) && !force)
				{
					return;
				}

				let input = {
					type: type.type,
					size: type.size,
					around: aroundDay,
					eventsOutside: true,
					preferToday: false,
					listTimes: type.listTimes,
					updateRows: type.updateRows,
					updateColumns: type.listTimes,
					fill: !type.listTimes,
					otherwiseFocus: type.focus,
					repeatCovers: type.repeat
				};

				this.setState( input );
			},

			next()
			{
				this.rebuild(undefined, true, this.currentType);

				this.calendar.unselect().next();

				this.triggerChange();
			},

			prev()
			{
				this.rebuild(undefined, true, this.currentType);

				this.calendar.unselect().prev();

				this.triggerChange();
			},
			nextweek()
			{
				this.rebuild(undefined, true, this.currentType);

				this.calendar.unselect().next();
				this.calendar.unselect().next();
				this.calendar.unselect().next();
				this.calendar.unselect().next();
				this.calendar.unselect().next();
				this.calendar.unselect().next();
				this.calendar.unselect().next();

				this.triggerChange();
			},
			prevweek()
			{
				this.rebuild(undefined, true, this.currentType);

				this.calendar.unselect().prev();
				this.calendar.unselect().prev();
				this.calendar.unselect().prev();
				this.calendar.unselect().prev();
				this.calendar.unselect().prev();
				this.calendar.unselect().prev();
				this.calendar.unselect().prev();

				this.triggerChange();
			},

			setToday()
			{
				this.rebuild( this.$dayspan.today );
				this.triggerChange();
			},

			viewDay(day)
			{
				this.rebuild( day, false, this.types[ 0 ] );
			},

			edit(calendarEvent)
			{
				let eventDialog = this.$refs.eventDialog;
				if (!calendarEvent.data.readOnly) {
					eventDialog.edit(calendarEvent);
				}
			},

			editPlaceholder(createEdit)
			{
				let placeholder = createEdit.calendarEvent;
				let details = createEdit.details;
				let eventDialog = this.$refs.eventDialog;
				let calendar = this.$refs.calendar;

				eventDialog.addPlaceholder( placeholder, details );
			},

			add(day)
			{
				if (!this.canAddDay)
				{
					return;
				}

				let eventDialog = this.$refs.eventDialog;
				let calendar = this.$refs.calendar;
				let useDialog = !this.hasCreatePopover;

				calendar.addPlaceholder( day, true, useDialog );

				if (useDialog)
				{
					eventDialog.add(day);
				}
			},

			addAt(dayHour)
			{
				if (!this.canAddTime)
				{
					return;
				}

				let eventDialog = this.$refs.eventDialog;
				let calendar = this.$refs.calendar;
				let useDialog = !this.hasCreatePopover;
				let at = dayHour.day.withHour( dayHour.hour );

				calendar.addPlaceholder( at, false, useDialog );

				if (useDialog)
				{
					eventDialog.addAt(dayHour.day, dayHour.hour);
				}
			},

			addToday()
			{
				if (!this.canAddDay)
				{
					return;
				}

				let eventDialog = this.$refs.eventDialog;
				let calendar = this.$refs.calendar;
				let useDialog = !this.hasCreatePopover || !calendar;

				let day = this.$dayspan.today;

				if (!this.calendar.filled.matchesDay( day ))
				{
					let first = this.calendar.days[ 0 ];
					let last = this.calendar.days[ this.calendar.days.length - 1 ];
					let firstDistance = Math.abs( first.currentOffset );
					let lastDistance = Math.abs( last.currentOffset );

					day = firstDistance < lastDistance ? first: last;
				}

				calendar && calendar.addPlaceholder( day, true, useDialog );

				if (useDialog)
				{
					eventDialog.add( day );
;
				}
			},

			handleAdd(addEvent)
			{
				let eventDialog = this.$refs.eventDialog;
				let calendar = this.$refs.calendar;

				addEvent.handled = true;

				if (!this.hasCreatePopover)
				{
					if (addEvent.placeholder.fullDay)
					{
						eventDialog.add(addEvent.span.start, addEvent.span.days(Op.UP));
					}
					else
					{
						eventDialog.addSpan(addEvent.span);
					}
				}
				else
				{
					calendar.placeholderForCreate = true;
				}
			},

			handleMove(moveEvent)
			{
				let calendarEvent = moveEvent.calendarEvent;
				let target = moveEvent.target;
				let targetStart = target.start;
				let sourceStart = calendarEvent.time.start;
				let schedule = calendarEvent.schedule;

				moveEvent.handled = true;

				let callbacks = {
					cancel: () => {
						moveEvent.clearPlaceholder()
					},
					single: () => {
						calendarEvent.move( targetStart );
						this.eventsRefresh();
						moveEvent.clearPlaceholder();

						calendarEvent.event.data.operation = 'update';
						this.$emit('event-update', calendarEvent.event);
					},
					instance: () => {
						calendarEvent.move( targetStart );
						this.eventsRefresh();
						moveEvent.clearPlaceholder();

						calendarEvent.event.data.operation = 'update';
						this.$emit('event-update', calendarEvent.event);
					},
					duplicate: () => {
						schedule.setExcluded( targetStart, false );
						this.eventsRefresh();
						moveEvent.clearPlaceholder();

						calendarEvent.event.data.operation = 'update';
						this.$emit('event-update', calendarEvent.event);
					},
					all: () => {
						schedule.moveTime( sourceStart.asTime(), targetStart.asTime() );
						this.eventsRefresh();
						moveEvent.clearPlaceholder();

						calendarEvent.event.data.operation = 'update';
						this.$emit('event-update', calendarEvent.event);
					}
				};

			},

			choosePrompt(yes)
			{
				this.promptCallback( yes );
				this.promptVisible = false;
			},

			eventFinish(ev)
			{
				if (ev.type != 'save') {
					this.triggerChange();
				}
				this.cancelPlaceholder = true;
			},
			eventCreate(ev) {
				this.$emit('event-create', ev);
			},
			eventUpdate(ev) {
				this.$emit('event-update', ev);
			},
			eventRemove(ev) {
				this.$emit('event-remove', ev);
			},
			save(ev) {
				this.$emit('event-update', ev);
			},
			eventsRefresh()
			{
				this.calendar.refreshEvents();
				this.triggerChange();
			},
			canceldone() {
				this.cancelPlaceholder = false;
			},
			cancel() {
				this.cancelPlaceholder = true;
			},
			triggerChange()
			{
				this.$emit('reloadSlots', {
					calendar: this.calendar
				});
			},
			triggerFilterChange(param) {
				if (param !== true) {
					this.$emit('filterChange',
						{locations : this.LocationIDs, mainlocations : this.MainLocationIDs, scheduletypes : this.ScheduleTypeIDs, programid : this.ProgramID, organizationid : this.OrganizationID, leagueid : this.LeagueID, teamid : this.TeamID, divisiontypes : this.DivisionTypeIDs, showgames : this.ShowGames, splits : this.Splits, showpractices : this.ShowPractices, showevents : this.ShowEvents, showopenslots : this.ShowOpenSlots, slotdates : this.SlotDates, taglinks : this.TagLinks}
					);
				}
			},
			exportCSV () {

				let list = this.Data.AllSlots;
				if (this.isConstraints) {
					if (this.adminAccess) {
						var columns = ['ConstraintSlotID', 'Title', 'Description', 'StartDate', 'EndDate', 'Repeats', 'StartTime', 'EndTime', 'Duration', 'Color', 'Season', 'ProgramName', 'TeamName', 'MainLocationName', 'LocationName', 'LeagueName', 'DivisionTypes'];
					} else {
						var columns = ['Title', 'Description', 'StartDate', 'EndDate', 'Repeats', 'StartTime', 'EndTime', 'Duration', 'Color', 'Season', 'ProgramName', 'TeamName', 'MainLocationName', 'LocationName', 'LeagueName', 'DivisionTypes'];

					}
				} else {
					var columns = ['CalendarSlotID', 'SlotType', 'Title', 'Description', 'StartDate', 'EndDate', 'StartTime', 'EndTime', 'Duration', 'MainLocationID', 'MainLocationName', 'LocationID', 'LocationName', 'Color', 'HourlyFlat', 'LocationCost', 'RinkFee', 'ScheduleTypes', 'OrganizationName', 'TeamNames', 'Tags', 'EventMaxAttendance'];
				}
				var csv = Papa.unparse(list, {columns: columns});

				var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
				var csvURL =  null;
				if (navigator.msSaveBlob)
				{
					csvURL = navigator.msSaveBlob(csvData, 'slots.csv');
				}
				else
				{
					csvURL = window.URL.createObjectURL(csvData);
				}

				var tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'slots.csv');
				tempLink.click();
			},
			cnfmMassChange(action) {
				var slots = this.calendar.toInput(true);
				var calendardates = 0;
				var games = false;
				if (slots && slots.events && slots.events.length > 0) {
					for (let i = 0; i < slots.events.length; i++) {
						if (slots.events[i].data.SlotType == 'g') {
							games = true;
						}
						if (!slots.events[i].data.CalendarSlotID) {
							calendardates++;
						}
					}
					if (action == 'delete' && games) {
						this.$confirm('Mass Delete cannot remove Slots attached to Games. Please display without Games or disconnect Game from Slot first.', {
							confirmButtonText: 'OK',
							type: 'warning',
							showClose: false,
							showCancelButton: false
						});
						return false;
					}
					if (this.config.Params.MassChangePassCode) {
						let self = this;
						var message = 'This will ' + (action == 'delete' ? 'Delete' : 'make Changes to') + ' <strong>' + (slots.events.length - calendardates) + '</strong> Slots.';
						this.$prompt(message + '<br>Enter Mass Change passcode in the box below and hit OK to confirm the ' + (action == 'delete' ? 'Delete' : 'Changes') +'.', 'Warning', {
							confirmButtonText: (action == 'delete' ? 'Delete' : 'Mass Change'),
							cancelButtonText: 'Cancel',
							type: (action == 'delete' ? 'error' : 'warning'),
							secondaryouslyUseHTMLString: true,
							inputPattern: new RegExp('^'+window.atob(this.config.Params.MassChangePassCode) + '$'),
							inputErrorMessage: 'Invalid Response'
						}).then(({value}) => {
							self.MassChange(action);
						}).catch(() => {

						});
					} else {
						this.MassChange(action);
					}
				} else {
					this.$alert('There are no Slots displayed to Change', 'Error', {
						customClass: 'errormsg',
						confirmButtonText: 'OK',
						type:'error',
						showClose: false,
						showCancelButton: false
					});
				}

			},
			MassChange (action) {
				if (this.isSaving) {
					return true;
				}
				let slots = this.calendar.toInput(true);

				this.type = slots.type;
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				this.loading = true;
				if (action == 'delete') {
					var url = (this.isConstraints ? 'constraints/deletemassconstraintslots' : 'locations/deletemasscalendarslots');
				} else {
					var url = (this.isConstraints ? 'constraints/savemassconstraintslots' : 'locations/savemasscalendarslots');
				}
				axios.post('/analyzer/' + url, {
						slots: slots,
						timezone: (Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : ''),
						Color: this.MassColor,
						ScheduleTypeIDs: this.MassScheduleTypeIDs,
						HourlyFlat: this.MassHourlyFlat,
						LocationCost: this.MassLocationCost,
						RinkFee: this.MassRinkFee,
						TagLinks: this.MassTagLinks,
						MassReplace: this.MassReplace,
						MassRemoveAllTags: this.MassRemoveAllTags,
						MassRemoveAllTypes: this.MassRemoveAllTypes
					}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_SUCCESS;
							this.$stopProgressBar(this.parentrefs);
							this.$emit('reloadSlots');
							this.$confirm('Mass ' + (action == 'delete' ? 'Delete' : 'Update') + ' Completed Successfully. A total of ' + response.data.updates  + (action == 'delete' ? ' Deletes' : ' Updates') + ' were made.', {
								confirmButtonText: 'OK',
								type: 'success',
								showClose: false,
								showCancelButton: false
							});
						} else {
							this.currentStatus = STATUS_FAILED;
							this.$alert('Mass ' + (action == 'delete' ? 'Delete' : 'Update') + ' Encountered an Error', 'Error', {
								customClass: 'errormsg',
								confirmButtonText: 'OK',
								type: 'error',
								showClose: false,
								showCancelButton: false
							});
							this.$failProgressBar(this.parentrefs);
						}
						this.loading = false;

					}).catch(response => {
					this.loading = false;
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
		}
}
</script>

<style lang="scss">

.ds-skinny-button {
	margin-left: 2px !important;
	margin-right: 2px !important;
}

.ds-expand {
	position: relative;
	width: 100%;
	height: 100%;
}

.ds-calendar-container {
	padding: 0px !important;
	position: relative;
}
#app .el-select.type .el-input__inner {
	border: 0;
	border-radius: 0;
	height : 35px;
	font-size: 18px;
}
#app #calendar .view .el-select.type input 
{
	border: 0;
}
#app .ds-calendar-app .el-select.type {
	width: 115px;
}
#app .ds-add-event-today {
	position: fixed;
	bottom: 0;
	right: 0;
	margin-bottom: 50px;
	margin-right: 30px;
	padding-left: 14px;
	width: 50px;
	height: 50px;
	line-height: 23px;
	border-radius: 50%;
	font-size: 30px;
	font-weight: 800;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0,0.12);
}
#app .ds-add-event-today span {
    float: left;
    margin-left: 6px;
    margin-top: -4px;
}
div.navigation-drawer {
	position: absolute;
	overflow: scroll;
	width: 300px;
	top:50px;
	left: 0px;
	box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}
.el-drawer__body {
	width: 300px;
}
.navigation-drawer .el-select {
	width:97%;
	margin:5px;
}
.navigation-drawer .el-checkbox {
	margin:5px;
}
.input-file {
	opacity: 0; /* invisible but it's there! */
	width: 100%;
	height: 100%;
	position: absolute;
	cursor: pointer;
	left:0;
	top:0;
}
#app .ds-calendar-app .el-select {
	width: 90%;
}
#app .ds-calendar-app h1.title {
	float:none;
	display: inline-block;
	margin-top: 7px;
	margin-right: 7px;
}
div.el-message-box.errormsg {
	width: 500px;
}
#app h5.title {
	color: var(--el-color-primary);
	font-weight: 800;
}
#app .el-checkbox__label {
	color: #606266;
	font-weight: 500;
	white-space: normal;
}
#app #calendar .view .el-input__wrapper {
	background-color: #F5F5F5;
}
#app #calendar .view input {
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
}
.el-tag {
	margin-right: 10px;
}
.button-new-tag {
	margin-left: 0px;
	margin-top: 0px;
	height: 25px;
	line-height: 25px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 0px;
	vertical-align: bottom;
}
#app #calendar .el-collapse-item__header {
	color: #fff;
	font-size: 16px;
	background: #666;
	height: 30px;
	padding-left: 5px;
}
#calendar .color input {
	color: #FFFFFF;
}
#calendar .color.White input {
	color: #606266;
}
#app #calendar .smallMassCurrency input {
	width: 100px;
	text-align: center;
}

#calendar .White .el-input__wrapper { background-color: #FFFFFF; }
#calendar .Red .el-input__wrapper { background-color: #F44336; }
#calendar .Pink .el-input__wrapper { background-color: #E91E63; }
#calendar .Purple .el-input__wrapper { background-color: #9C27B0; }
#calendar .Deep_Purple .el-input__wrapper { background-color: #673AB7; }
#calendar .Indigo .el-input__wrapper { background-color: #3F51B5; }
#calendar .Blue .el-input__wrapper { background-color: #2196F3; }
#calendar .Highlight_Color .el-input__wrapper { background-color: #1976d2; }
#calendar .Light_Blue .el-input__wrapper { background-color:  #03A9F4; }
#calendar .Cyan .el-input__wrapper { background-color:  #00BCD4; }
#calendar .Teal .el-input__wrapper { background-color:  #009688; }
#calendar .Green .el-input__wrapper { background-color:  #4CAF50; }
#calendar .Light_Green .el-input__wrapper { background-color:  #8BC34A; }
#calendar .Lime .el-input__wrapper { background-color:  #CDDC39; }
#calendar .Yellow .el-input__wrapper { background-color:  #FFEB3B; color: #000000;}
#calendar .Amber .el-input__wrapper { background-color:  #FFC107; }
#calendar .Orange .el-input__wrapper { background-color:  #FF9800; }
#calendar .Deep_Orange .el-input__wrapper { background-color:  #FF5722; }
#calendar .Brown .el-input__wrapper { background-color:  #795548; }
#calendar .Blue_Gray .el-input__wrapper { background-color:  #607D8B; }
#calendar .Gray .el-input__wrapper { background-color:  #9E9E9E; }
#calendar .Black .el-input__wrapper { background-color:  #000000; }

#app .v-text-field.v-text-field--solo .v-input__control {
	min-height: 35px;
}
#app .ds-button-tall {
	height: 35px;
}
#app #calendar main.v-content {
	padding-top: 0px !important;
}
</style>
