<template>
    <div id="plans" v-loading="loading">
        <div v-if="Customers.length > 1" class="row mb-2">
			<div class="col-12 fullpage">
				<el-select v-model="CustomerID" placeholder="Select Customer" class="topDropdown" @change="customerChanged()" default-first-option filterable>
					<el-option
					  v-for="item in Customers"
					  :label="item.CustomerName"
					  :value="item.CustomerID"
					  :key="item.CustomerName">
					</el-option>
				</el-select>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 fullpage">
                <div class="card">
                    <div class="card-header bg-primary details"><h5>Plans</h5></div>
                    <div class="card-body-no mt-4">
                        <div v-if="$Plan.PlanType == 'X'" class="mb-4">
                            <el-alert v-if="paymentMethod.billing_details" title="Your Trial Has Expired" type="warning" effect="dark" show-icon :closable="false" description="Your Trial has expired. If you wish to continue using Analyzer Software, you will need to add a paid for plan."  />
                            <el-alert v-else-if="CurrentPlans.length == 0" title="Your Trial Has Expired" type="warning" effect="dark" show-icon :closable="false" description="Your Trial has expired. If you wish to continue using Analyzer Software, you will need to add a Payment Method and then add a paid for plan."  />
                            <el-alert v-else title="Please logout" type="success" effect="dark" show-icon :closable="false" description="Thank you for signing up for a new plan.  Please Logout and Log back in again to see the full Dashboard of functions."  />
                        </div>
                        <div style="font-weight: 800;font-size: 14px;">Current Plan</div>
                        <el-button v-if="CurrentPlans.length == 0" @click.prevent="addPlan()" style="margin-right: 20px; width: 150px;" size="small"><i class="fa-solid fa-circle-plus"></i> Add A New Plan</el-button>
                        <template v-for="(CurrentPlan,index) in CurrentPlans" >
                            <div v-if="CurrentPlan.PlanType" class="currentPlan row">
                                <div class="plantype col-3">
                                    <div v-html="'Type: ' + CurrentPlan.PlanTypeF"></div>
                                    <el-button v-if="modifyAccess && index == 0" class="mt-2 btn-success" type="success" size="small" @click.prevent="changePlan(index, 'Change')">
                                        <i class="fa-regular fa-pen-to-square"></i>  <span>Change</span>
                                    </el-button>
                                    <el-button v-else class="mt-2 btn-success" type="success" size="small" @click.prevent="changePlan(index,'View')">
                                        <i class="fa-regular fa-pen-to-square"></i>  <span>View Details</span>
                                    </el-button>
                                </div>
                                <div class="dates col-4">
                                    Start Date: {{CurrentPlan.StartDateF}}<br>
                                    End Date: {{CurrentPlan.EndDateF}}
                                </div>
                                <div class="price col-3">
                                    <span class="repricing" >Recurring Monthly Fee:</span><br>{{CurrentPlan.PlanPrice}}
                                </div>
                                <div class="cancel col-2">
                                    <el-button v-if="$propertyAccess('Cancel') && CurrentPlan.EndDateF == 'Ongoing'" @click.prevent="showCancelCnfm(index)" class="btn-danger" type="danger" size="small"><i class="fa-regular fa-circle-xmark"></i> Cancel</el-button>
                                </div>
                            </div>
                        </template>
                        <template v-if="PreviousPlans.length > 0">
                            <div class="previousPlans mt-4">
                                <div style="font-weight: 800;font-size: 14px;">Older Plans</div>
                                <el-table
                                    :data="PreviousPlans"
                                    stripe border
                                    key="PreviousPlans"
                                    size="small"
                                    style="width: 100%">
                                    <el-table-column 
                                            type="expand" 
                                            label="Details" 
                                            width="80"
                                            fixed>
                                        <template #default="props">
                                            <div style="padding: 10px;font-size: 12px;">
                                                <div class="row" style="font-weight: 800; color: #fff; background-color: #ccc;">
                                                    <div class="col-5">
                                                        <span v-html="'Feature Name'"></span>
                                                    </div>
                                                    <div class="col-4">   
                                                        <span v-html="'Value'"></span>  
                                                    </div>
                                                    <div class="col-3" style="text-align: right;" v-html="'Monthly Fee'">
                                                    </div>
                                                </div>
                                                <template v-for="(feature, index) in props.row.Features">
                                                    <template v-if="feature.SortOrder > 0">
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <span v-html="feature.FeatureName"></span>
                                                            </div>
                                                            <div class="col-5">   
                                                                <span v-html="feature.Params.Value"></span>  
                                                            </div>
                                                            <div class="col-2" style="text-align: right;" v-html="formatPrice(feature.Params.PaymentAmount)">
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="PlanType"
                                            label="Plan Type"
                                            min-width="150">
                                        <template #default="scope">
                                            <span v-html="scope.row.PlanTypeF" ></span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="Start/End Dates"
                                            min-width="150">
                                        <template #default="scope">
                                            Start Date: {{scope.row.StartDateF}}<br>
                                            End Date: {{scope.row.EndDateF}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="MonthlyFee"
                                            label="Monthly Fee"
                                            align="right"
                                            width="150px">
                                        <template #default="scope">
                                            <div v-if="scope.row.EndDateF == 'Ongoing'" >
                                                {{scope.row.PlanPrice}}
                                            </div>
                                            <div v-else>
                                                {{scope.row.PlanPrice}}
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 fullpage">
                <div class="card">
                    <div class="card-header bg-primary details"><h5>Payment Method</h5></div>
                    <div class="card-body-no">
                        <template v-if="paymentMethod.billing_details">
                            <div class="row">
                                <div class="col-2">Name on Card:</div><div class="col-10" style="font-weight: 800;" v-html="paymentMethod.billing_details.name"></div>
                            </div>
                            <div class="row">
                                <div class="col-2">Card Type:</div><div class="col-10" style="font-weight: 800;" v-html="cardBrand"></div>
                            </div>
                            <div class="row">
                                <div class="col-2">Last 4 Digits:</div><div class="col-10" style="font-weight: 800;" v-html="'...&nbsp;' + paymentMethod.card.last4"></div>
                            </div>
                            <div class="row">
                                <div class="col-2">Expiry:</div><div class="col-10" style="font-weight: 800;" v-html="lpad(paymentMethod.card.exp_month.toString(), 2) + ' / ' + paymentMethod.card.exp_year"></div>
                            </div>
                            <el-button class="mt-2 btn-primary" type="primary" @click="editCardDetails">
                                <i class="far fa-edit"></i> <span>&nbsp;Add New Card</span>
                            </el-button>
                        </template>
                        <template v-if="newPaymentMethod">
                            <el-divider v-if="paymentMethod.billing_details" content-position="center" class="mt-4 mb-2">New Card Details</el-divider>
                            <el-alert v-else-if="CurrentPlans.length > 0" title="If you would like to move from a Trial to a paid for plan, you must first have a payment method on file" type="success" effect="dark" :closable="false" show-icon />
                            <el-alert v-else title="If you would like to start a new plan, you must have a payment method on file" type="success" effect="dark" :closable="false" show-icon />
                            <el-form ref="CardForm" label-position="top" label-width="120px" :model="cardDetails" class="mt-2" style="width: 420px;" >
                                <el-form-item label="Cardholder Name:" prop="cardholderName" class="mb-3"
                                            :rules="[{ required: true, message: 'Cardholder Name is Required', trigger: 'blur'}]"
                                >
                                    <el-input v-model="cardDetails.cardholderName"></el-input>
                                </el-form-item>
                                <div class="cardnumber el-form-item is-required" :class="{'is-error' : cardError}">
                                    <el-form-item label="Card Details:" class="cardnumber" prop="cardnumber">
                                        <!-- placeholder for Elements -->
                                        <div ref="card"></div>
                                        <div style="width: 100%;">
                                            <div class="cardResult el-form-item__error" v-html="cardResult"></div>
                                        </div>
                                    </el-form-item>
                                    <el-button id="card-button" class="mt-2 btn-success" type="success">
                                        <i class="fas fa-check"></i> <span>&nbsp;Save Card</span>
                                    </el-button>
                                </div>
                            </el-form>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 fullpage">
                <div class="card">
                    <div class="card-header bg-primary details"><h5>Payments</h5></div>
                    <div class="card-body-no">
                        <el-table
                            :data="Payments"
                            stripe border
                            key="Payments"
                            empty-text="No Payments"
                            size="small"
                            style="width: 100%">
                            <el-table-column
                                    prop="PaymentDateF"
                                    label="Date"
                                    min-width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="Description"
                                    label="Description"
                                    min-width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="PaymentAmount"
                                    label="Payment Amount"
                                    align="right"
                                    min-width="100">
                                <template #default="scope">
                                    <span v-html="formatPrice(scope.row.PaymentAmount)"></span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <modal v-if="showModal" :showMask="(this.config.isMobile ? false : true)" @close="closePlan"  >
			<template v-slot:header>
				<h5 class="modal-title" v-html="(modalType == 'Add' ? 'Add A New Plan' : (modalType == 'Change' ? 'Change Your Plan' : 'View Your Plan'))"></h5>
			</template>
			<template v-slot:body>
                <template v-if="ChangedPlan.PlanType != 'T' && CurrentPlans[modalIDX] && CurrentPlans[modalIDX].PlanType != 'T' && ChangedPlan.EndDateF != 'Ongoing' && modalType == 'Change' && modalIDX == 0">
                    <div class="cancelled">
                        Your plan has been cancelled.  If you would like to convert to an ongoing plan where you can manage your features, click the button below.<br>
                        <el-button class="mt-2 btn-success" type="success" size="small" @click="planFunctions('reactivated')">
                            <i class="fa-solid fa-ban"></i>  <span>I do not want to Cancel</span>
                        </el-button>
                    </div>
                </template>
                <template v-else>
                    <div v-if="isAdmin" class="row planmodal">
                        <div class="col-6" style="padding-left: 0px;">
                            <el-date-picker v-model="StartDate"  default-first-option filterable
                                :clearable="true"
                                :disabled-date="disabledDates"
                                type="date"
                                format="MMM D, YYYY"
                                value-format="YYYY-MM-DD"
                                @change="calcPaymentAmount('y')"
                                placeholder="Plan Start Date"
                                class="mb-2">
                            </el-date-picker>
                            <el-date-picker v-model="EndDate"  default-first-option filterable
                                :clearable="true"
                                type="date"
                                format="MMM D, YYYY"
                                value-format="YYYY-MM-DD"
                                @change="calcPaymentAmount('y')"
                                placeholder="Plan End Date"
                                class="mb-2">
                            </el-date-picker>
                        </div>
                        <div class="col-6">
                            Charge the Difference if Higher Price:<br>
                            <el-radio-group v-model="ChargeDifference">
                                <el-radio-button label="n">No</el-radio-button>
                                <el-radio-button label="y">Yes</el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                    <div v-if="(modalType == 'Add' || modalType == 'Change') && ChangedPlan.FuturePlan == 'n'" class="row">
                        <span style="display:inline-block;font-weight: 800;width: 120px;line-height: 30px;padding-left: 0px;">Changes to Start: </span>
                        <el-radio-group v-model="StartPeriod" style="display:inline-block;width: 300px;" :disabled="ChangedPlan.Params.PlanPrice != ChangePrice">
                            <el-radio-button label="i">Immediately</el-radio-button>
                            <el-radio-button label="n">Next Month</el-radio-button>
                        </el-radio-group>

                    </div>
                    <template v-for="(feature, index) in ChangedPlan.CurrentPlanFeatures">
                        <template v-if="feature.SortOrder == 0">
                            <el-alert v-if="!paymentMethod.billing_details" title="Until Payment Information is added, only the Trial Plan is available." type="warning" effect="dark" show-icon :closable="false"  />
                            <el-radio-group class="mt-2 mb-2" v-model="feature.PlanParams.Value" @change="planTypeChanged(index)">
                                <template v-for="item in feature.Params.Values" >
                                    <el-radio-button v-if="(item != 'Trial' || PreviousPlans.length == 0)" :label="item" :disabled="(item != 'Trial' && !paymentMethod.billing_details) || !modifyAccess || modalType == 'View' "><span v-html="item"></span> Plan</el-radio-button>
                                </template>
                            </el-radio-group>
                        </template>
                        <template v-else>
                            <template v-if="index == 1" >
                                <div class="row planmodaltotal">
                                    <div class="col-5">
                                        Feature
                                    </div>
                                    <div class="col-3">
                                        Value
                                    </div>
                                    <template v-if="isAdmin">
                                        <div class="col-2" style="text-align: center;">
                                            Feature<br>Price
                                        </div>
                                        <div class="col-2" style="text-align: center;">
                                            Override<br>Price
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="col-4" style="text-align: right;">
                                            Feature Price
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <div class="row planmodal">
                                <div class="col-5">
                                    <span v-html="feature.FeatureName"></span>
                                </div>
                                <div class="col-3">
                                    <template v-if="feature.Params.FieldType == 'select' || feature.Params.FieldType == 'multiple'" >
                                        <el-select v-model="feature.PlanParams.Value" placeholder="Select" size="small" @change="calcPaymentAmount('y')" default-first-option filterable :multiple="(feature.Params.FieldType == 'multiple' ? true : false)" :disabled="(ChangedPlan.PlanType == 'T' && !isAdmin) || modalIDX != 0 || (StartPeriod == 'i' && ChangedPlan.FuturePlan == 'n') || modalType == 'View'">
                                            <el-option
                                                    v-for="(item,index) in feature.Params.Values "
                                                    :key="item"
                                                    :label="item"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </template>
                                    <template v-if="feature.Params.FieldType == 'input'">
                                        <el-input v-model="feature.PlanParams.Value" size="small" @change="calcPaymentAmount('y')" :disabled="(ChangedPlan.PlanType == 'T' && !isAdmin) || modalIDX != 0 || (StartPeriod == 'i' && ChangedPlan.FuturePlan == 'n') || modalType == 'View'">
                                        </el-input>
                                    </template>
                                    <template v-if="feature.Params.FieldType == 'yesno'" >
                                        <el-radio-group v-model="feature.PlanParams.Value" :disabled="ChangedPlan.PlanType == 'U' || (ChangedPlan.PlanType == 'T' && !isAdmin) || (ChangedPlan.PastPlanFeatures[index].PlanParams.Value == 'y' && (StartPeriod == 'i' && ChangedPlan.FuturePlan == 'n')) || modalType == 'View'" size="small" @change="calcPaymentAmount('y')">
                                            <el-radio-button selected label="y">Yes</el-radio-button>
                                            <el-radio-button label="n">No</el-radio-button>
                                        </el-radio-group>
                                    </template>
                                    <template v-if="feature.Params.FieldType == 'multiplecheckbox'" >
                                        <el-checkbox-group v-model="feature.PlanParams.Value" size="small">
                                            <el-checkbox v-for="item in feature.Params.Values" size="small" :label="item" :value="item" :key="item" @change="calcPaymentAmount('y')" :disabled="(ChangedPlan.PlanType == 'T' && !isAdmin) || modalIDX != 0 || (StartPeriod == 'i' && ChangedPlan.FuturePlan == 'n') || modalType == 'View'"></el-checkbox>
                                        </el-checkbox-group>
                                    </template>
                                </div>
                                <template v-if="isAdmin">
                                    <div class="col-2" style="text-align: right;">
                                        <span v-html="formatPrice(feature.PlanParams.PaymentAmount)"></span>
                                    </div>
                                    <div class="col-2" style="text-align: right;">
                                        <div v-if="feature.Params.FieldType == 'select' || feature.Params.FieldType == 'multiple' || feature.Params.FieldType == 'multiplecheckbox' || ChangePlanType != 'Unlimited'" :class="{'el-form-item__content' : modalIDX == 0}">
                                            <div v-if="modalIDX == 0" class="el-input el-input--default el-input__wrapper">
                                                <strong>$</strong>&nbsp;<input autocomplete="off" class="el-input__inner" type="text" v-maska="'########'" v-model="feature.PlanParams.PaymentOverrideAmount"  @change="calcPaymentAmount('y')"  :disabled="modalIDX != 0" />
                                            </div>
                                            <div v-else style="text-align: right;" v-html="formatPrice(feature.PlanParams.PaymentOverrideAmount)"></div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-4" style="text-align: right;">
                                        <span v-html="formatPrice(!feature.PlanParams.PaymentOverrideAmount || feature.PlanParams.PaymentOverrideAmount == '' ? feature.PlanParams.PaymentAmount : feature.PlanParams.PaymentOverrideAmount)"></span>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                    <div class="row planmodaltotal">
                        <div class="col-5">
                            <span v-html="'Total Monthly Fee'"></span>
                        </div>
                        <div class="col-4">
                            &nbsp;
                        </div>
                        <div class="col-3" style="text-align: right;">
                            <div v-html="formatPrice(ChangePrice)"></div>
                        </div>
                    </div>
                    <div v-if="ChangedPlan.Params.PlanPrice != ChangePrice" class="row planmodaldiff">
                        <div class="col-5">
                            <span v-html="'Difference'"></span>
                        </div>
                        <div class="col-4">
                            &nbsp;
                        </div>
                        <div class="col-3" style="text-align: right;">
                            <div v-html="formatPrice(ChangePrice - ChangedPlan.Params.PlanPrice)"></div>
                        </div>
                    </div>
                </template>
			</template>
			<template v-slot:footer>
				<div class="row">
					<div class="col-12 text-center">
						<button v-if="((ChangedPlan.Params.PlanPrice != ChangePrice || StartPeriod == 'n' || ChangedPlan.PlanType != ChangedPlan.PrevPlanType) && modalIDX == 0) && modalType != 'View'" class="btn btn-success btn-icon" type="button" @click="savePlan">
							<i class="fas fa-check"></i> <span> Save Plan</span>
						</button>
						<button class="btn btn-icon btn-secondary cancel" type="info" @click="closePlan">
							<i class="fas fa-times"></i>  <span v-if="modifyAccess && modalIDX == 0">Cancel</span><span v-else>Close</span>
						</button>
					</div>
				</div>
			</template>
		</modal>
        <modal v-if="showCancelModal" :showMask="(this.config.isMobile ? false : true)" @close="showCancelModal = false;">
			<template v-slot:header>
				<h5 class="modal-title">
					Cancel Plan
				</h5>
			</template>
			<template v-slot:body>
                <div v-if="CurrentPlans.length == 1" class="deleteModel mt-1">
                    <span>We are sorry to see you go.  Just a few comments before you cancel.<br><br>
                        <ol type="1">
                            <li>Your plan will still be live until the end of the month, at which time the cancellation will be processed.</li>
                            <li>When the cancellation is processed all payment information will be removed.</li>
                            <li>After that time, you will no longer have access to any of your data.</li>
                            <li>All widgets you have placed on websites will no longer show any of your data.</li>
                        </ol>
                        <span>If this is not what you want, click "Close" and you can Change your plan to better suit your needs, otherwise click the "Cancel" button to terminate your plan.</span>
                    </span>
                </div>
                <div v-else>
                    <span>We are sorry you wish to cancel this plan.  You have 2 options to decide upon since this plan has not yet taken effect.<br><br>
                        <ol type="1">
                            <li><strong>1. Your plan will still be live until the end of the month. You can then let it expire and you will no longer will a plan with Analyzer Software.</strong></li>
                            <li>When the cancellation is processed all payment information will be removed.</li>
                            <li>After the end of the month, you will no longer have access to any of your data.</li>
                            <li>All widgets you have placed on websites will no longer show any of your data.</li>
                            <li><strong>2. Convert your previous plan to an ongoing one and continue forward with that plan. You may then alter that plan.</strong></li>
                        </ol>
                        <span>Click the "Continue" button to continue with your previous plan or click the "Cancel" button to terminate all your plans. If none of this is what you want, click "Close" and you can instead Change your plan to better suit your needs.</span>
                    </span>
                </div>
			</template>
			<template v-slot:footer>
                <div class="col-12 text-center">
                    <button v-if="CurrentPlans.length > 1" class="btn btn-icon btn-success success" type="button" @click="planFunctions('continued')">
                        <i class="fas fa-check"></i>  <span>Continue</span>
                    </button>                    
                    <button class="btn btn-icon btn-danger delete" type="button" @click="planFunctions('cancelled')">
                        <i class="fa-regular fa-circle-xmark"></i>  <span>Cancel</span>
                    </button>
                    <button class="btn btn-icon btn-secondary cancel" type="info"  @click="showCancelModal = false;">
                        <i class="fas fa-times"></i>  <span>Close</span>
                    </button>
                </div>
			</template>
		</modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Util from "../assets/js/util";
import Modal from './global/Modal.vue';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    name: 'Plans',
    components: {
        Modal,
    },
    data() {
        return {
            loading: false,
            newPaymentMethod : false,
            showModal : false,
            modalIDX : 0,
            showCancelModal : false,
            modalType : 'Add',
            currentStatus : STATUS_INITIAL,
            Customers : [],
            CustomerID : '', 
            Plan : {},
            ChangePrice : '0.00',
            ChangedPlan : {},
            CurrentPlans : [],
            PreviousPlans : [],
            CurrentPlanID : '',
            Features : [],
            clientSecret : '',
            session_id : '',
            cardDetails : {cardholderName : ''},
            token: null,
            paymentMethodID : '',
            cardResult : '',
            cardError : false,
            cardElement : '',
            paymentMethod : {},
            planChanged: false,
            StartDate : '',
            EndDate : '',
            ChargeDifference : 'n',
            StartPeriod : 'i',
            Payments : [],
        }
    },
    props:["config","Season", "parentrefs"],
    computed: {
        ...mapGetters({ componentIDs: 'componentIDs' }),
        ...mapGetters({ currentUser: 'currentUser' }),
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
        isAdmin () {
            return (this.currentUser.CustomerID == 0 ? true : false);
        },
        modifyAccess () {
            return this.$modifyAccess();
        },
        createAccess () {
            return this.$createAccess();
        },
        cardBrand () {
            if (this.paymentMethod.card && this.paymentMethod.card.brand) {
                if (this.paymentMethod.card.brand == 'visa') {
                    return '<i class="fab fa-2x fa-cc-visa"></i>';
                } else if (this.paymentMethod.card.brand == 'mastercard') {
                    return '<i class="fab fa-2x fa-cc-mastercard"></i>';
                } else if (this.paymentMethod.card.brand == 'amex') {
                    return '<i class="fab fa-2x fa-cc-amex"></i>';
                } else if (this.paymentMethod.card.brand == 'discover') {
                    return '<i class="fab fa-2x fa-cc-discover"></i>';
                } else {
                    return this.paymentMethod.card.brand;
                }   
            } else {
                return '';
            }
        },
    },
    methods: {
        getInitialData () {
            var tables = ['Customers', 'Features'];
            axios.post('/index/tableData', {tables: tables},
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (this.currentUser.CustomerID > 0) {
                            this.CustomerID = this.currentUser.CustomerID;
                        } else {
                            this.CustomerID = this.Customers[0].CustomerID;
                        }
                        this.getAllPlans();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
            });
        },
        lpad (str, length) {
            var padString = '0';
            while (str.length < length)
                str = padString + str;
            return str;
        },
        editCardDetails () {
            this.newPaymentMethod = true;
            let self = this;
            setTimeout(() => { self.setupStripe(); }, 250);
        },
        formatPrice(value) {
            if (value == 'n/a') {
                return 'n/a';
            } else {
                return Util.formatPrice(this.config, value , '0.00');
            }
        },
        disabledDates(date1) {
            if (this.isAdmin) { 
                return false;
            }
            var cmpDate = date1.toISOString().split('T')[0];
            var now = new Date();
            var nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
            if (date1 >= nextMonth) {
                return false;
            } else {
                return true;
            }

        },
        planTypeChanged (idx) {
            this.ChangedPlan.PlanType = this.ChangedPlan.CurrentPlanFeatures[idx].PlanParams.Value.charAt(0);
            if (this.ChangedPlan.PlanType == 'T' || this.ChangedPlan.PlanType == 'U' ) {
                for (let i = 0; i < this.ChangedPlan.CurrentPlanFeatures.length; i++) {
                    if (this.ChangedPlan.CurrentPlanFeatures[i].Params.FieldType == 'yesno') {
                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value = 'y';
                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = '';
                    }
                }
            }
            this.calcPaymentAmount('y');
        },
        customerChanged () {
            this.getAllPlans();
        },
        addPlan () {
            this.modalIDX = 0;
            this.modalType = 'Add';
            if (this.PreviousPlans.length == 0) {
                var planType  = 'T'
            } else {
                var planType = 'F';
            }
            this.ChangedPlan = {Params : {PlanPrice : 0}, PlanFeatures : [], CurrentPlanFeatures : [], PlanType : planType, PrevPlanType : planType, PlanPrice : 0, PlanStatus : 'A', CustomerID : this.CustomerID};
            this.calcPlanFeatures();
            this.ChangedPlan.CurrentPlanFeatures[0].PlanParams.Value = this.ChangedPlan.CurrentPlanFeatures[0].Params.Values[(this.PreviousPlans.length == 0 ? 0 : 1)];
            this.calcPaymentAmount('y');
            this.planChanged = true;
            this.showModal = true;
        },
        changePlan (idx, type) {
            this.modalIDX = idx;
            this.modalType = type;
            this.StartPeriod = 'i';
            this.CurrentPlanID = this.CurrentPlans[idx].PlanID;
            this.ChangedPlan = JSON.parse(JSON.stringify(this.CurrentPlans[idx]));
            this.ChangedPlan.PrevPlanType = this.ChangedPlan.PlanType;
            this.calcPlanFeatures();
            this.calcPaymentAmount('i');
            this.planChanged = false;
            this.showModal = true;
        },
        closePlan () {
            this.showModal = false;
            //this.getAllPlans();
        },

        setupStripe () {
            var self = this;
            
            var clientSecret = this.clientSecret;
            var stripe = Stripe(this.config.Params.publishableKey);
            var stripeElements = stripe.elements({});
            
            var cardElement = stripeElements.create('card', {
                        style: {
                            base: {
                            iconColor: '#CCCCCC',
                            color: '#606266',
                            fontWeight: '500',
                            fontFamily: '"Chakra Petch", Avenir, Helvetica, Arial, sans-serif',
                            fontSize: '14px',
                            fontSmoothing: 'antialiased',
                            ':-webkit-autofill': {
                                color: '#fce883',
                            },
                            '::placeholder': {
                                color: '#CCCCCC',
                            },
                            },
                            invalid: {
                                iconColor: '#dc3545',
                                color: '#dc3545',
                            },
                        },
                        disableLink: true,
                    });
                    
            cardElement.mount(self.$refs.card);

            const cardButton = document.getElementById('card-button');

            cardButton.addEventListener('click', async (ev) => {
                self.$refs['CardForm'].validate(async (valid) => {
                    if (valid) {
                        const {paymentMethod, error} = await stripe.createPaymentMethod({
                            type: 'card',
                            card: cardElement,
                            billing_details: {
                                name: self.cardDetails.cardholderName,
                            },
                            }
                        );

                        if (error) {
                            // Display error.message in your UI.
                            self.cardResult = error.message;
                            this.cardError = true;
                        } else {
                            this.cardError = false;
                            // You have successfully created a new PaymentMethod
                            self.paymentMethodID = paymentMethod.id;
                            self.savePaymentDetails();
                        }
                    }
                });
            });
        },
        savePaymentDetails () {
            if (this.currentStatus == STATUS_SAVING) {
                return true;
            }
            this.loading = true;
            this.currentStatus = STATUS_SAVING;
            this.$startProgressBar(this.parentrefs);

            axios.post('/plans/savepayment', {paymentMethodID : this.paymentMethodID}
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.currentStatus = STATUS_INITIAL;
                        this.$stopProgressBar(this.parentrefs);
                        this.$notify({
                            title: 'Success',
                            message: "Payment method has been saved",
                            type: 'success',
                            duration: 1500,
                            showClose: false
                        });
                        this.getAllPlans();
                    } else {
                        this.currentStatus = STATUS_FAILED;
                        this.displayPopup (response.data.message);
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.loading = false;
                }).catch(response => {
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
            });
        },
        calcPlanFeatures () {
            var features = this.Features;
            for (let i = 0; i < features.length; i++) {
                features[i].PlanParams = {Value : '', PaymentAmount : '0.00'};
                if (this.ChangedPlan && this.ChangedPlan.PlanFeatures) {
                    for (let j = 0; j < this.ChangedPlan.PlanFeatures.length; j++) {
                        if (features[i].FeatureID == this.ChangedPlan.PlanFeatures[j].FeatureID) {
                            features[i].PlanParams = this.ChangedPlan.PlanFeatures[j].Params;
                            break;
                        }
                    }
                }
                if (features[i].PlanParams.Value == '') {
                    //let's set default values
                    if (features[i].Params.FieldType == 'yesno') {
                        features[i].PlanParams.Value = 'n';
                    } else if (features[i].Params.FieldType == 'multiple' || features[i].Params.FieldType == 'multiplecheckbox') {
                        //form value needs to start as array
                        features[i].PlanParams.Value = [];
                    } else if (features[i].Params.FieldType == 'input') {
                        features[i].PlanParams.Value = '';
                    } else if (features[i].Params.Values && features[i].Params.Values.length > 0) {
                        features[i].PlanParams.Value = features[i].Params.Values[0];
                    }
                }
            }
            this.ChangedPlan.CurrentPlanFeatures = JSON.parse(JSON.stringify(features));
            this.ChangedPlan.PastPlanFeatures = JSON.parse(JSON.stringify(features));
        },
        calcPaymentAmount (itemChanged) {
            var totalPrice = 0;
            var i, j, k;
            var variables = [];
            for ( i = 0; i < this.ChangedPlan.CurrentPlanFeatures.length; i++) {
                variables[this.ChangedPlan.CurrentPlanFeatures[i].Params.FeatureCode] = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value;
            }
            for ( i = 0; i < this.ChangedPlan.CurrentPlanFeatures.length; i++) {
                if (this.ChangedPlan.CurrentPlanFeatures[i].Params.FieldType == 'select') {
                    for (j = 0; j < this.ChangedPlan.CurrentPlanFeatures[i].Params.Values.length; j++) {
                        if (this.ChangedPlan.CurrentPlanFeatures[i].Params.Values[j] == this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value) {                           
                            if (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j])) {
                                for (k = 0; k < this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts.length; k++) {
                                    for (const property in this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k]) {
                                        var fieldval = variables[property];
                                        if (this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k][property] == fieldval) {
                                            var payments = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k].Values;
                                            break;
                                        }
                                    }
                                }
                                if (itemChanged == 'i') {
                                    //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                    if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != payments[j] && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                    }
                                }
                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = payments[j];
                                break;
                            } else {
                                if (itemChanged == 'i') {
                                    //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                    if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j] && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                    }
                                }
                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j];
                                break;
                            }
                        }
                    }
                } else if (this.ChangedPlan.CurrentPlanFeatures[i].Params.FieldType == 'yesno') {
                    if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value == 'y') {
                        if (!this.ChangedPlan.CurrentPlanFeatures[i].Params.Values || this.ChangedPlan.CurrentPlanFeatures[i].Params.Values.length == 0) { 
                            j = 0;
                            if (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j])) {
                                for (k = 0; k < this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts.length; k++) {
                                    for (const property in this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k]) {
                                        var fieldval = variables[property];
                                        if (this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k][property] == fieldval) {
                                            var payments = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k].Values;
                                            break;
                                        }
                                    }
                                }
                                if (itemChanged == 'i') {
                                    //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                    if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != payments[j] && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                    }
                                }
                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = payments[j];
                            } else {
                                if (itemChanged == 'i') {
                                    //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                    if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j] && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                    }
                                }
                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j];
                            }
                        } else {
                            for (j = 0; j < this.ChangedPlan.CurrentPlanFeatures[i].Params.Values.length; j++) {
                                var condition = this.ChangedPlan.CurrentPlanFeatures[i].Params.Values[j].replace(/(\w+)/g, (match,key)=>variables[key]||match);
                                if (eval(condition)) {
                                    if (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j])) {
                                        for (k = 0; k < this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts.length; k++) {
                                            for (const property in this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k]) {
                                                var fieldval = variables[property];
                                                if (this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k][property] == fieldval) {
                                                    var payments = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k].Values;
                                                    break;
                                                }
                                            }
                                        }
                                        if (itemChanged == 'i') {
                                            //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                            if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != payments[j] && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                            }
                                        }
                                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = payments[j];
                                    } else {
                                        if (itemChanged == 'i') {
                                            //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                            if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j] && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                            }
                                        }
                                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j];                              
                                    }
                                }
                            }
                        }
                    } else {
                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = 0;
                        this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = '';
                    }
                } else if (this.ChangedPlan.CurrentPlanFeatures[i].Params.FieldType == 'input') {
                    if (!this.ChangedPlan.CurrentPlanFeatures[i].Params.Values || this.ChangedPlan.CurrentPlanFeatures[i].Params.Values.length == 0) { 
                        if (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j])) {
                            for (k = 0; k < this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts.length; k++) {
                                for (const property in this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k]) {
                                    var fieldval = variables[property];
                                    if (this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k][property] == fieldval) {
                                        var payments = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k].Values;
                                        break;
                                    }
                                }
                            }
                            var featurePrice = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value * payments[0];
                            if (itemChanged == 'i') {
                                //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != featurePrice && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                    this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                }
                            }
                            this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = featurePrice;
                        } else {
                            var featurePrice = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value * this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[0];
                            if (itemChanged == 'i') {
                                //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != featurePrice && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                    this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                }
                            }
                            this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = featurePrice;
                        }
                    } else {
                        for (j = 0; j < this.ChangedPlan.CurrentPlanFeatures[i].Params.Values.length; j++) {
                            var condition = this.ChangedPlan.CurrentPlanFeatures[i].Params.Values[j].replace(/(\w+)/g, (match,key)=>variables[key]||match);
                            if (eval(condition)) {
                                if (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j])) {
                                    for (k = 0; k < this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts.length; k++) {
                                        for (const property in this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k]) {
                                            var fieldval = variables[property];
                                            if (this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k][property] == fieldval) {
                                                var payments = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k].Values;
                                                break;
                                            }
                                        }
                                    }
                                    var featurePrice = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value * payments[0];
                                    if (itemChanged == 'i') {
                                        //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                        if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != featurePrice && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                            this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                        }
                                    }
                                    this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = featurePrice;
                                } else {
                                    if (itemChanged == 'i') {
                                        //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                                        if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount != this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j] && (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                                            this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                                        }
                                    }
                                    this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[j];                              
                                }
                            }
                        }
                    }
                } else if (Array.isArray(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value)) {
                    //multi-select, go through them all
                    if (itemChanged == 'i') {
                        //if initial, then if the plan payment amounts have changed, then move the saved to the override amount
                        if ((isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '')) {
                            this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount;
                        }
                    }
                    this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount = 0;
                    for (let j = 0; j < this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value.length; j++) {
                        var index = this.ChangedPlan.CurrentPlanFeatures[i].Params.Values.indexOf(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.Value[j]);
                        if (index >= 0) {
                            if (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[index])) {
                                for (k = 0; k < this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts.length; k++) {
                                    for (const property in this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k]) {
                                        var fieldval = variables[property];
                                        if (this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k][property] == fieldval) {
                                            var payments = this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[k].Values;
                                            break;
                                        }
                                    }
                                }
                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount += -(-payments[index]);
                            } else {
                                this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount += -(-this.ChangedPlan.CurrentPlanFeatures[i].Params.PaymentAmounts[index]);
                            }
                        }
                    }
                }
            }
            for ( i = 0; i < this.ChangedPlan.CurrentPlanFeatures.length; i++) {
                if (this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount) {
                    this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount = '';
                }
                if (isNaN(this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount) || this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount == '') {
                    totalPrice += -(-this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentAmount);
                } else {
                    totalPrice += -(-this.ChangedPlan.CurrentPlanFeatures[i].PlanParams.PaymentOverrideAmount)
                }
            }
            this.ChangePrice = totalPrice;
            if (itemChanged == 'y') {
                this.planChanged = true;
            } else {
                this.planChanged = false;
            }
        },
        getAllPlans() {
            this.loading = true;
            this.Plans = [];
            this.CurrentPlans = {};
            this.PreviousPlans = [];
            this.StartDate = '';
            this.EndDate = '';
            this.ChargeDifference = 'n';
            let self = this;
            axios.post('/plans/getall', { CustomerID :  this.CustomerID },
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.CurrentPlans = (response.data.CurrentPlans ? response.data.CurrentPlans : []);
                        this.PreviousPlans = (response.data.PreviousPlans ? response.data.PreviousPlans : []);
                        this.clientSecret = response.data.clientSecret;
                        this.paymentMethod = (response.data.paymentMethod ? response.data.paymentMethod : {});
                        if (this.paymentMethod.billing_details) {
                            this.newPaymentMethod = false;
                        } else {
                            this.newPaymentMethod = true;
                            setTimeout(() => { self.setupStripe(); }, 250);
                        }
                        this.Payments = (response.data.Payments ? response.data.Payments : []);
                    }
                    this.loading = false;
                }).catch(response => {
                this.loading = false;
            });

        },
        savePlan () {
            if (this.currentStatus == STATUS_SAVING) {
                return true;
            }
            this.loading = true;
            this.currentStatus = STATUS_SAVING;
            this.$startProgressBar(this.parentrefs);

            axios.post('/plans/save', {mode : this.modalType, CurrentPlan : this.ChangedPlan, ChangePrice : this.ChangePrice, CustomerID : this.CustomerID, clientSecret : this.clientSecret, StartDate : this.StartDate, EndDate : this.EndDate, ChargeDifference : this.ChargeDifference, PriceDifference : this.PriceDifference, StartPeriod : this.StartPeriod}
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.currentStatus = STATUS_INITIAL;
                        this.$stopProgressBar(this.parentrefs);
                        this.getAllPlans();
                        this.$notify({
                            title: 'Success',
                            message: "Plan has been saved",
                            type: 'success',
                            duration: 1500,
                            showClose: false
                        });
                        this.showModal = false;
                        this.StartDate = '';
                        this.EndDate = '';
                        this.ChargeDifference = 'n';
                    } else {
                        this.currentStatus = STATUS_FAILED;
                        this.displayPopup (response.data.message);
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.loading = false;
                }).catch(response => {
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
            });
        },
        showCancelCnfm (idx) {
            this.CurrentPlanID = this.CurrentPlans[idx].PlanID;
            this.modalIDX = idx;
            this.showCancelModal = true;
        },
        planFunctions(func) {
            this.loading = true;
            var msgtext = (func == 'cancel' ? 'cancelled' : (func == 'reactivated' ? 'cancelled' : 'reactivated'));
            axios.post('/plans/functions', {PlanID : this.CurrentPlanID, PreviousPlanID : (this.CurrentPlans[1] ? this.CurrentPlans[1].PlanID : 0), function : func}
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.$notify({
                            title: 'Success',
                            message: "Plan has been " + msgtext,
                            type: 'success',
                            duration: 1500,
                            showClose: false
                        });
                        this.getAllPlans();
                    } else {
                        this.$notify({
                            title: 'Error',
                            message: "Plan cannot be " + msgtext,
                            type: 'error',
                            duration: 2000,
                            showClose: false
                        });
                    }
                    this.showCancelModal = false;
                    this.showModal = false;
                    this.loading = false;
                }).catch(response => {
                    this.$notify({
                        title: 'Error',
                        message: "Plan cannot be " + msgtext,
                        type: 'error',
                        duration: 2000,
                        showClose: false
                    });
                    this.showCancelModal = false;
                    this.showModal = false;
                    this.loading = false;
            });
        },
        displayPopup (message) {
            this.$alert(message, 'Error', {
                confirmButtonText: 'OK',
            });
        },
        paymentsubmit () {
            // this will trigger the process
            this.$refs.elementRef.submit();
        },
        getSecretToken() {
            return this.clientSecret;
        },
    },
    mounted () {
        this.getInitialData();
        if (this.$route.params.session_id) {
            this.session_id = this.$route.params.session_id;
        }
    },

}
</script>
<style>
    #plans .modal-content {
        width: 700px;
    }
    #plans .modal-content .el-radio-group {
        display:block;
    }
    #plans .StripeElement {
        width: 420px !important;
        height: 31px !important;
        padding: 6px !important;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
        cursor: text;
    }
    #plans .StripeElement.StripeElement--invalid {
        box-shadow: 0 0 0 1px var(--el-color-error) inset;
    }
</style>
<style scoped>
    #plans .card {
        margin-left: 0;
    }
    #plans .currentPlan { 
        border-top : 1px solid #666;
        border-bottom : 1px solid #666;
        padding: 10px;
        font-size: 14px;
    }
    #plans .currentPlan h5, #plans .currentPlan .price {
        color: var(--el-color-success);
        font-weight: 800;
        font-size: 16px;
    }
    #plans .deleteModel li {
        list-style-type: decimal;
    }
    #plans .row.planmodal {
        padding: 3px;
        border-bottom: 1px dotted #cccccc;
        font-size: 15px;
        line-height: 27px;
    }
    #plans .row.planmodal input {
        text-align: right;
        font-size: 15px;
    }
    #plans .repricing {
        color : var(--bs-body-color);
        font-size: 14px;
        font-weight: 400;
    }
    #plans .previousPlans {
        border-bottom: 1px dotted #cccccc;
        margin-bottom: 10px;
    }
    #plans .planmodaltotal {
        font-size: 16px;
        font-weight: 800;
        padding: 10px 0;
        background-color: #f0f0f0;
        line-height: 19px;
        border-bottom: 1px solid #ccc;
    }
    #plans .planmodaldiff {
        font-size: 16px;
        font-weight: 800;
        padding: 10px 0;
        background-color: #ffffff;
        color: var(--el-color-error);
        line-height: 19px;
        border-bottom: 1px solid #ccc;
    }
    #plans label.el-checkbox {
        margin-right: 10px;
    }
    #plans .cardResult {
        color: var(--el-color-error);
    }
    #plans .far.fa-edit {
        color: #ffffff;
    }
</style>
