<template>
	<div id="sitecontacts">
		<div class="row" v-if="createAccess">
			<div class="col-12 mb-4 fullpage">
				<el-button @click.prevent="newContact()"><i class="fa-solid fa-circle-plus"></i> Add New</el-button>
				<el-button v-if="siteContacts.length > 0" type="info" class="btn-secondary" style="margin-left: 20px;" @click="exportCSV">
					<i class="fas fa-file-export"></i>  <span>Export CSV</span>
				</el-button>
			</div>
		</div>
		<div class="row" v-else>
            <div class="col-12 mb-4">&nbsp;</div>
        </div>
		<div class="row mb-4">
			<div class="col-12 fullpage">
			<h5>Site Contacts</h5>
				<el-table
					:data="siteContacts"
					border
					size="small"
					max-height="500"
					:span-method="arraySpanMethod"
					:row-class-name="tableRowClassName"
					style="width: 100%">
					<el-table-column
						prop="Name"
						label="Name"
						min-width="100">
					</el-table-column>
					<el-table-column
							prop="PhoneNumbers"
							label="Phone Numbers"
							width="120">
						<template #default="scope">
							<div v-html="scope.row.PhoneNumbers"></div>
						</template>
					</el-table-column>
					<el-table-column
							prop="EMails"
							label="Emails"
							min-width="150">
						<template #default="scope">
							<div v-html="scope.row.EMails"></div>
						</template>
					</el-table-column>
					<el-table-column
							prop="Address"
							label="Address"
							min-width="100">
						<template #default="scope">
							<div v-html="scope.row.Address"></div>
						</template>
					</el-table-column>
					<el-table-column v-if="modifyAccess"
						label=""
						width="120"
						align="center">
						<template #default="scope">
							<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editContact(scope.$index, scope.row)">
								<div class="v-btn__content">
									<i class="far fa-2x fa-edit"></i>
								</div>
							</button>
							<button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="deleteCnfm(scope.$index, scope.row)">
								<div class="v-btn__content">
									<i class="far fa-2x fa-trash-alt"></i>
								</div>
							</button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

        <modal v-if="showModal" @close="showModal = false" width="450px">
	        <template v-slot:header>
		        <h5 class="modal-title">
			        {{modalTitle}}
		        </h5>
	        </template>
	        <template v-slot:body>
                <Profile ref="profile" :contactData="modalData" :config="config" :calling="'SiteContacts'" :MainLocationIDAccess="MainLocationIDAccess" @close="showModal = false" @refreshContactData="refreshData" />
            </template>
            <template v-slot:footer>
                {{modalFooter}}
            </template>
        </modal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Modal from './global/Modal.vue';
	import axios from 'axios';
	import Profile from './Profile.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'SiteContacts',
		components: {
			Modal,
			Profile
		},
	    data() {
	        return {
                currentStatus : STATUS_INITIAL,
	            modalTitle: 'Edit Contact',
	            modalFooter: '',
	            showModal: false,
		        MainLocationID : '',
                MainLocations : [],
                user : [],
                siteContacts : []
	        }
	    },
	    props:["config", "parentrefs"],
	    computed: {
	        ...mapGetters({ currentUser: 'currentUser' }),
		    ...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        createAccess () {
	            return this.$createAccess();
	        },
	        MainLocationIDAccess () {
                return this.$MainLocationIDAccess();
            }
		},
		methods: {
			reset () {
				this.getGames();
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if (row.ContactID == 0) {
					if (columnIndex == 0) {
						return [1,5]
					} else {
						return [0,0];
					}
				} else {
					return [1, 1];
				}
			},
			tableRowClassName({row, rowIndex}) {
				if (row.ContactID == 0) {
					return 'team-row';
				} else {
					return '';
				}
			},
			getInitialData () {
				var tables = ['MainLocations'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        this.user = { ...this.currentUser };
						if (this.MainLocationIDAccess && this.user.MainLocationID) {
							this.MainLocationID = this.user.MainLocationID;
						} else {
							this.MainLocationID = 0;
						}
						this.getSiteContacts();

                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getSiteContacts() {
				axios.post('/contacts/getallsitecontacts', { MainLocationID : this.MainLocationID},
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.siteContacts = (response.data.contacts ? response.data.contacts : []);
                        this.$stopProgressBar(this.parentrefs);
                    } else {
                        this.siteContacts = [];
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			editContact(index, row) {
				this.modalTitle = 'Edit Contact';
				row.contactArea = 'pc';
				this.modalData = row;
				this.displayModal();
			},
			newContact() {
				this.modalTitle = 'New Contact';
				var row = {};
				row.MainLocationID = this.MainLocationID;
				row.contactArea = 'pc';
				row.Params = {};
				row.Roles = [];
				this.modalData = row;
				this.displayModal();
			},
			deleteCnfm (index,row) {
				this.$confirm('Are you sure you wish to delete this contact?', 'Confirmation', {
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					type: 'warning',
					showClose: false,
					showCancelButton: true
				}).then(() => {
					this.deleteContact(index, row);
				}).catch(() => {
				});

			},
			deleteContact(index, row) {
				if (this.currentUser.ContactID != row.ContactID) {
					axios.post('/contacts/delete', {'ContactID': row.ContactID}
					)
					.then(response => {
						if (response.data.result == 'success') {
							this.refreshData();
							this.$notify({
								title: 'Success',
								message: "Contact has been deleted",
								type: 'success',
								duration: 1500,
								showClose: false
							});
						} else {
							this.$notify({
								title: 'Error',
								message: "Contact cannot be deleted",
								type: 'error',
								duration: 2000,
								showClose: false
							});
						}
					}).catch(response => {
						this.$notify({
							title: 'Error',
							message: "Contact cannot be deleted",
							type: 'error',
							duration: 2000,
							showClose: false
						});
					});
				} else {
					this.displayPopup ('Sorry, you cannot delete yourself');
				}
			},
	        displayPopup (message) {
                this.$alert(message, 'Contact Error', {
                    confirmButtonText: 'OK',
                });
            },
            displayModal () {
                this.showModal = true;
            },
            hideModal () {
                this.showModal = false;
            },
            refreshData() {
                this.getSiteContacts();
            },
			exportCSV () {
				var list = this.teamContacts;

				//this is a Program specific user and will have limited fields.
				var newList = [];
				for(let i=0; i<list.length; i++) {
					if (list[i].ContactID != '0') {
						if (this.MainLocationIDAccess) {
							delete list[i].UserLoginID;
							delete list[i].MainLocationID;
							delete list[i].LoginRoleID;
							delete list[i].Name;
							delete list[i].Roles;
						} else {
							list[i].Roles = JSON.stringify(list[i].Roles);
						}
						if (list[i].EMails) {
							list[i].EMails = list[i].EMails.replace('<br>', ' ');
						}
						if (list[i].Address) {
							list[i].Address = list[i].Address.replace('<br>', ' ');
						}
						//do twice because could be 2
						if (list[i].PhoneNumbers) {
							list[i].PhoneNumbers = list[i].PhoneNumbers.replace('<br>', ' ');
							list[i].PhoneNumbers = list[i].PhoneNumbers.replace('<br>', ' ');
						}
						delete list[i].Params;

						newList.push(list[i]);
					}
				}
				var csv = Papa.unparse(newList);
				var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
				var csvURL =  null;
				if (navigator.msSaveBlob)
				{
					csvURL = navigator.msSaveBlob(csvData, 'contacts.csv');
				}
				else
				{
					csvURL = window.URL.createObjectURL(csvData);
				}

				var tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'contacts.csv');
				tempLink.click();
			},
		},
		mounted () {
			this.getInitialData();
		},
	}
</script>
<style>
#sitecontacts .el-table th {
	font-size: 120%;
	font-weight: 800;
}
#sitecontacts .modal-dialog {
	width: 450px;
}
#sitecontacts .modal-body {
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}
#sitecontacts .modal-footer {
	height: 55px;
}
.sitecontacts div.el-table .cell {
	word-break: normal;
}
</style>
<style scoped>
#sitecontacts .scores {
	width: 40px;
    text-align: center;
    padding: 1px;
    margin: 1px 0px;
    height: 23px;
}
#sitecontacts .checkbox {
    display: inline-block;
    width: 25px;
    height: 15px;
}
#sitecontacts .webnotes {
	width: 90%;
	height: 40px;
	padding: 2px;
}
#sitecontacts .heading {
	background-color: #1A719C;
	color: #fff;
	font-size: 14px;
}
#sitecontacts .body {
	color: #333;
	font-size: 14px;
	padding: 2px;
}
#sitecontacts .body input, #sitecontacts .body select, #sitecontacts .body textarea {
	color: #333;
	font-size: 14px;
}
#sitecontacts .body select {
	padding: 1px;
}

#sitecontacts .body.row:nth-child(even){
	background-color: #dcdcdc;
}
#sitecontacts .body.row:nth-child(odd){
	background-color: #ffffff;
}
#sitecontacts .row.buttons {
	margin-top: 20px;
}
select.not-available {
	width: auto;
	margin-left: 0px   !important;
}
.not-available label {
	margin-top: 4px;
}
.program-select {
	font-weight: 800;
	font-size: 110%;
}
.options {
	margin: 0px 5px;
}
.el-select {
	width: 100%;
	max-width: 300px;
}
.mainlocationName {
	display: inline-block;
	font-weight: 800;
}
i.fas, i.far {
    font-size: 18px;
}
</style>
