<template>
    <div id="playerpayments" v-loading="loading">
        <el-form ref="leagueForm" label-position="top" label-width="120px">
            <div class="row">
                <div class="col-12 fullpage">
                    <el-form-item label="League Name:" prop="LeagueName">
                        <el-select v-model="LeagueID" ref="league" class="topDropdown" @change="leagueChange" placeholder="Select" default-first-option filterable>
                            <el-option v-for="type in PaymentLeagues"
                                    :value="type.LeagueID"
                                    :key="type.LeagueID"
                                    :label="type.LeagueName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <template v-if="LeagueID && !ProgramIDAccess" >
                        <el-form-item :label="config.Params.programName+':'" prop="ProgramID">
                            <el-select v-model="ProgramID" ref="program" class="topDropdown" :disabled="ProgramIDAccess" @change="programChange" placeholder="Select" default-first-option filterable clearable>
                                <el-option v-for="type in LeaguePrograms[LeagueID]"
                                        :value="type.ProgramID"
                                        :key="type.ProgramID"
                                        :label="type.ProgramName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                    <el-form-item label="Season:" prop="SeasonID">
                        <el-select key="selectseason" ref="season" v-model="SeasonID" placeholder="Select Season" @change="seasonChange" default-first-option filterable>
                            <el-option
                                v-for="item in ActiveSeasons"
                                :key="item.SeasonID"
                                :label="item.SeasonName"
                                :value="item.SeasonID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Team(s):" prop="selectedTeamNames">
                        <el-select v-model="selectedTeamNames" class="topDropdown" style="max-width: 1200px;" @change="currentPage = 1" placeholder="Select" default-first-option filterable clearable multiple>
                            <el-option v-for="name in teamNames"
                                    :value="name"
                                    :key="name"
                                    :label="name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Player Type:" prop="selectedPlayerTypeID">
                        <el-select v-model="selectedPlayerTypeID" placeholder="Select Team" @change="currentPage = 1" default-first-option filterable clearable>
                            <el-option
                                v-for="item in PlayerTypes"
                                :key="item.PlayerTypeID"
                                :label="item.PlayerTypeName"
                                :value="item.PlayerTypeID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Tag:" prop="selectedPlayerTypeID">
                        <div class="tags el-input__inner" style="padding: 0px 0px 0px 5px; height: 42px;width: 100%;max-width: 410px;">
                            <el-tag
                                v-for="tag in taglinks"
                                :key="'search'+tag.TagID"
                                closable
                                effect="dark"
                                :type="tag.TagType"
                                :disable-transitions="false"
                                @close="handleCloseSearchTag(tag)"
                            >
                                {{tag.TagName}}
                            </el-tag>
                            <el-select v-if="tagSelectVisible" key="selectsearchtags" v-model="newTagID" ref="saveSearchTagInput" size="small" style="width: auto;" @change="handleNewSearchTag" default-first-option clearable filterable>
                                <el-option
                                    v-for="item in availableSearchTags"
                                    :key="item.TagID"
                                    :label="item.TagName"
                                    :value="item.TagID"
                                >
                                    <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>
                                </el-option>
                            </el-select>
                            <el-button v-else-if="availableSearchTags.length > 0" class="button-new-tag" size="small" @click="showSearchTagSelect">+ New Tag</el-button>
                            <div>
                                <el-radio-group v-model="searchupdate" size="small" style="margin-top: 7px;margin-right: 15px;">
                                    <el-radio-button selected label="s">Search</el-radio-button>
                                    <el-radio-button label="u">Update</el-radio-button>
                                </el-radio-group>
                                <el-radio-group v-if="searchupdate == 'u'" size="small" v-model="addremoveTags" style="margin-top: 7px;margin-right: 15px;">
                                    <el-radio-button selected label="a">Add Tag(s)</el-radio-button>
                                    <el-radio-button label="r">Remove Tag(s)</el-radio-button>
                                </el-radio-group>
                                <el-radio-group v-if="searchupdate != 'u'" size="small" v-model="havenotTags" style="margin-top: 7px;">
                                    <el-radio-button selected label="y">Having Tag(s)</el-radio-button>
                                    <el-radio-button label="n">NOT Having Tag(s)</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </el-form-item>
                    <div style="font-size: 12px;line-height: 15px;padding: 9px;padding-top:35px; font-weight: 400">** Select Search which will show players with or without these tags, select Update for select Players to Mass add/remove Tags.</div>
                    <div class="program-select mb-2">
                        <el-button v-if="searchupdate == 'u' && taglinks.length > 0 && emailSelection.length > 0" class="btn-success" type="success" @click="addremovePlayerTags">
                            <i class="fas fa-tags"></i>  <span>{{(addremoveTags == 'a' ? 'Add Tags to ' : 'Remove Tags From ') + ' Selected Players'}}</span>
                        </el-button>
                        <el-button v-if="playerPayments.length > 0" type="info" class="btn-secondary" style="float:right;" @click="exportCSV">
                            <i class="fas fa-file-export"></i>  <span>Export Players</span>
                        </el-button>
                        <el-button v-if="emailSelection.length > 0" type="success" style="float:right;margin-right: 20px;" @click="getEmailDetails">
                            <i class="far fa-envelope"></i> <span> Email Players</span>
                        </el-button>
                    </div>
                    <div class="program-select mb-2" :style="'margin-top: ' + (emailSelection.length > 0 ? '0px;' : '0px;')">
                        <label class="mb-1 control-label">Show Balances </label>
                        <el-select class="Expression" v-model="selectedExpression" placeholder="" default-first-option filterable>
                            <el-option
                                v-for="item in expressions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input v-if="selectedExpression != 'Show All'" class="Balance" v-model="selectedBalance" placeholder="Balance" >
                        </el-input>
                    </div>
                    <div class="program-select mb-2">
                        <label class="mb-1 control-label">Search Term</label>
                        <el-input
                            class="search"
                            v-model="search"
                            clearable
                            placeholder="Type to search">
                        </el-input>
                    </div>
                    <div class="columns">
                        <el-select key="selectcolumns"  style="float:right;width: 500px; margin-top: 5px;" multiple collapse-tags collapse-tags-tooltip :max-collapse-tags="4" v-model="Columns" size="small" @change="saveSettings" placeholder="Select Columns" default-first-option filterable>
                            <el-option label="Tags" value="Tags" >
                            </el-option>
                            <el-option label="Notes" value="Notes" >
                            </el-option>
                            <el-option label="Player Type" value="PlayerTypeName" >
                            </el-option>
                            <el-option label="Team Name" value="TeamName" >
                            </el-option>
                            <el-option label="Registration Approval Code" value="RegistrationApprovalCode" >
                            </el-option>
                            <el-option label="Registration Date" value="RegistrationDate" >
                            </el-option>
                            <el-option label="Contact Info" value="Contact" >
                            </el-option>
                            <el-option label="Tuition" value="Tuition" >
                            </el-option>
                            <el-option label="Credits" value="Credits" >
                            </el-option>
                            <el-option label="Payments" value="Payments" >
                            </el-option>
                            <el-option label="Balance" value="Balance" >
                            </el-option>
                            <template v-for="(item,index) in ParamFields">
                                <template v-if="ParamsFieldBlackouts.indexOf(item) == -1">
                                    <el-option :label="item" :value="'Custom'+index" >
                                    </el-option>
                                </template>
                            </template>
                        </el-select>
                        <el-button v-if="playerPayments.length > 0 && !selectedall"  type="info" size="small" style="float:left;margin-top: 20px;" @click="toggleAll(true)">
                            Select All
                        </el-button>
                        <el-button v-if="emailSelection.length > 0 && selectedall" type="info" size="small" style="float:left;margin-top: 20px;margin-top: 20px;" @click="toggleAll(false)">
                            DeSelect All
                        </el-button>
                    </div>
                    <el-table
                        :data="filterPlayerPayments"
                        border
                        key="paymenttable"
                        ref="paymenttable"
                        size="small"
                        show-summary
                        :summary-method="getSummaries"
                        empty-text="No Player Payments"
                        :span-method="arraySpanMethod"
                        :row-class-name="tableRowClassName"
                        @header-dragend="PlayerPaymentWidthChange"
                        @sort-change="sortPlayers"
                        style="width: 100%">
                        <el-table-column
                            fixed
                            v-if="modifyAccess"
                            prop="selected"
                            label="Sel"
                            align="center"
                            width="60"
                            key="40"
                        >
                            <template #default="scope">
                                <input type="checkbox" v-model="scope.row.selected" @click="handleSelectionChange(scope.row.PlayerID)"/>
                            </template>
                        </el-table-column>
                        <el-table-column fixed type="expand">
                            <template #default="props">
                                <div class="row">
                                    <div class="col-12" style="max-width: 800px;">
                                        <h5>Payments/Credits</h5>
                                        <div class="row detailheader">
                                            <div class="col-3">Date</div>
                                            <div class="col-4">Title</div>
                                            <div class="col-3">Amount</div>
                                            <div class="col-2">&nbsp;</div>
                                        </div>
                                        <div class="row" style="padding: 3px 0px;" v-for="(payment, paymentindex) in props.row.Payments" :class="{even : paymentindex % 2 == 0}">
                                            <div class="col-3">{{payment.date}}</div>
                                            <div class="col-4">{{payment.title}}</div>
                                            <div class="col-3" v-html="formatPrice(payment.amount)"></div>
                                            <div class="col-2">
                                                <el-popconfirm
                                                    v-if="!payment.params || !payment.params.FormDataID"
                                                    confirm-button-text='OK'
                                                    cancel-button-text='No Thanks'
                                                    @confirm="deletePayment(payment.id)"
                                                    icon-color="red"
                                                    width="250"
                                                    title="Are you sure you wish to Delete this Payment?"
                                                >
                                                    <template #reference>
                                                        <a style="margin-left: 15px;" href="javascript:;" ><i class="fa-solid fa-circle-xmark fa-2x"></i></a>
                                                    </template>
                                                </el-popconfirm>
                                                <a v-if="modifyAccess && payment.id > 0" style="margin-left: 20px;" href="javascript:;" @click.prevent="editPayment(payment, props.row.PlayerID)" title="Edit Payment"><i class="far fa-2x fa-edit"></i></a>
                                            </div>
                                        </div>
                                        <div class="row" style="padding: 3px 0px;" v-for="(credit, creditindex) in props.row.Credits" :class="{even : creditindex % 2 == 0}">
                                            <div class="col-3">{{credit.date}}</div>
                                            <div class="col-4">{{credit.title}}</div>
                                            <div class="col-3" v-html="formatPrice(credit.amount)"></div>
                                            <div class="col-2">
                                                <a v-if="modifyAccess && credit.id > 0" style="margin-left: 20px;" href="javascript:;" @click.prevent="editCredit(credit, props.row.PlayerID)" title="Edit Credit"><i class="far fa-2x fa-edit"></i></a>
                                                <el-popconfirm
                                                    v-if="!credit.params || !credit.params.FormDataID"
                                                    confirm-button-text='OK'
                                                    cancel-button-text='No, Thanks'
                                                    @confirm="deleteCredit(credit.id)"
                                                    icon-color="red"
                                                    width="250"
                                                    title="Are you sure you wish to Delete this Credit?"
                                                >
                                                    <template #reference>
                                                        <a style="margin-left: 15px;" href="javascript:;" ><i class="fa-solid fa-circle-xmark fa-2x"></i></a>
                                                    </template>
                                                </el-popconfirm>       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Name"
                            label="Name"
                            key="col2"
                            :width="(PlayerPaymentWidths.Name ? PlayerPaymentWidths.Name: 150)"
                            fixed
                            sortable
                        >
                            <template #default="scope">
                                <span v-if="scope.row.PlayerID != '0'" v-html="scope.row.FirstName+' '+scope.row.LastName"></span>
                                <span v-else v-html="scope.row.TeamName"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('TeamName') !== -1"
                            prop="TeamName"
                            label="Team"
                            key="col6"
                            :width="(PlayerPaymentWidths.TeamName ? PlayerPaymentWidths.TeamName: 150)"
                            sortable
                        >
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('Tags') !== -1"
                            prop="Tags"
                            label="Tags"
                            key="col3"
                            :min-width="(PlayerPaymentWidths.Tags ? PlayerPaymentWidths.Tags: 250)"
                            >
                            <template #default="scope">
                                <template v-if="scope.row.Params">
                                    <el-tag
                                        v-for="tag in scope.row.Params.taglinks"
                                        :key="tag.TagID"
                                        closable
                                        effect="dark"
                                        :type="tag.TagType"
                                        :disable-transitions="false"
                                        @close="handleCloseTag(tag,scope.$index)"
                                    >
                                        {{tag.TagName}}
                                    </el-tag>
                                </template>
                                <template v-if="scope.row.selected">
                                    <el-select v-if="scope.row.tagSelectVisible" :key="'selecttags'+scope.$index" v-model="scope.row.newTagID" :ref="'saveTagInput'+scope.$index" size="small" style="width: auto;" @change="handleNewTag(scope.$index)" default-first-option clearable filterable>
                                        <el-option
                                            v-for="item in availableTags(scope.$index)"
                                            :key="item.TagID"
                                            :label="item.TagName"
                                            :value="item.TagID"
                                        >
                                            <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>
                                        </el-option>
                                    </el-select>
                                    <el-button v-else-if="availableTags(scope.$index).length > 0" class="button-new-tag" size="small" @click="showTagSelect(scope.$index)">+ New Tag</el-button>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('Notes') !== -1"
                            prop="Params.Notes"
                            label="Notes"
                            key="col4"
                            :width="(PlayerPaymentWidths.Notes ? PlayerPaymentWidths.Notes: 180)"
                        >
                            <template #default="scope">
                                <template v-if="scope.row.Params">
                                    <template v-if="scope.row.selected">
                                        <textarea class="editnotes" rows="4" v-model="scope.row.Params.Notes" v-on:change="scope.row.operation = 'update';dataToSave();"></textarea>
                                    </template>
                                    <template v-else>
                                        <span v-html="scope.row.Params.Notes"></span>
                                    </template>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('PlayerTypeName') !== -1"
                            prop="PlayerTypeName"
                            label="Player Type"
                            key="col5"
                            :width="(PlayerPaymentWidths.PlayerTypeName ? PlayerPaymentWidths.PlayerTypeName: 140)"
                            sortable
                        >
                            <template #default="scope">
                                <template v-if="scope.row.selected">
                                    <el-select size="small" v-model="scope.row.PlayerTypeID" placeholder="Select" v-on:change="scope.row.operation = 'update';dataToSave();" default-first-option filterable clearable>
                                        <el-option
                                            v-for="item in PlayerTypes"
                                            :key="item.PlayerTypeID"
                                            :label="item.PlayerTypeName"
                                            :value="item.PlayerTypeID">
                                        </el-option>
                                    </el-select>
                                </template>
                                <template v-else>
                                    <span v-html="scope.row.PlayerTypeName"></span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('RegistrationApprovalCode') !== -1"
                            prop="RegistrationApprovalCode"
                            key="col7"
                            label="Reg. No"
                            :width="(PlayerPaymentWidths.RegistrationApprovalCode ? PlayerPaymentWidths.RegistrationApprovalCode: 130)"
                        >
                            <template #default="scope">
                                <template v-if="scope.row.selected">
                                    <input type="text" class="playerinput" v-model="scope.row.RegistrationApprovalCode" v-on:change="scope.row.operation = 'update';dataToSave();" />
                                </template>
                                <template v-else>
                                    <span v-html="scope.row.RegistrationApprovalCode"></span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('RegistrationDate') !== -1"
                            prop="RegistrationDate"
                            key="col8"
                            label="Reg. Date"
                            sortable
                            :width="(PlayerPaymentWidths.RegistrationDate ? PlayerPaymentWidths.RegistrationDate: 130)"
                        >
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('Tuition') !== -1"
                            prop="Tuition"
                            label="Tuition"
                            key="col9"
                            align="center"
                            sortable
                            :width="(PlayerPaymentWidths.Tuition ? PlayerPaymentWidths.Tuition: 120)"
                        >
                            <template #default="scope">
                                <span v-html="formatPrice(scope.row.Tuition)"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('Credits') !== -1"
                            prop="TotalCreditAmount"
                            label="Credits"
                            key="col10a"
                            align="center"
                            :width="(PlayerPaymentWidths.Credits ? PlayerPaymentWidths.Credits: 120)"
                        >
                            <template #default="scope">
                                <a v-if="modifyAccess" style="float:right;" href="javascript:;" @click.prevent="addCreditCnfm(scope.$index, scope.row)" title="Add Credit"><i class="fas fa-plus-circle"></i></a>
                                <span v-html="formatPrice(scope.row.TotalCreditAmount)"></span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="Columns.indexOf('Payments') !== -1"
                            prop="TotalPaymentAmount"
                            label="Payments"
                            key="col10b"
                            align="center"
                            :width="(PlayerPaymentWidths.Payments ? PlayerPaymentWidths.Payments: 120)"
                        >
                            <template #default="scope">
                                <a v-if="modifyAccess" style="float:right;" href="javascript:;" @click.prevent="addPaymentCnfm(scope.$index, scope.row)" title="Add Payment"><i class="fas fa-plus-circle"></i></a>
                                <span v-html="formatPrice(scope.row.TotalPaymentAmount)"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="Columns.indexOf('Balance') !== -1"
                            prop="Balance"
                            label="Balance"
                            key="col11"
                            align="center"
                            sortable
                            :width="(PlayerPaymentWidths.Balance ? PlayerPaymentWidths.Balance: 120)"
                        >
                            <template #default="scope">
                                <span v-html="formatPrice(scope.row.Balance)"></span>
                            </template>
                        </el-table-column>
                        <template v-for="(item,index) in ParamFields">
                            <el-table-column
                                v-if="Columns.indexOf('Custom' + index) !== -1 && ParamsFieldBlackouts.indexOf(item) == -1"
                                :prop="'Custom' + index"
                                :label="item"
                                :key="'Custom' + index"
                                align="left"
                                sortable
                                :width="(PlayerPaymentWidths['Custom'+index] ? PlayerPaymentWidths['Custom'+index]: 150)"
                            >
                                <template #default="scope">
                                    <template v-if="scope.row.Params">
                                        <template v-if="scope.row.selected">
                                            <input type="text" class="playerinput" v-model="scope.row.Params[item]" v-on:change="scope.row.operation = 'update';dataToSave();" />
                                        </template>
                                        <template v-else>
                                            <span v-html="scope.row.Params[item]"></span>
                                        </template>
                                    </template>
                                </template>
                            </el-table-column>
                        </template>
                        <el-table-column
                            v-if="modifyAccess"
                            label=""
                            key="col12"
                            width="100"
                            fixed="right"
                            align="center">
                            <template #default="scope">
                                <template  v-if="TeamAccess(scope.row)">
                                    <button type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="editPlayerCnfm(scope.$index, scope.row)">
                                        <div class="v-btn__content">
                                            <i class="far fa-2x fa-edit"></i>
                                        </div>
                                    </button>
                                    <button v-if="scope.row.recordstatus == 'I'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="activatePlayer(scope.$index, scope.row)">
                                        <div class="v-btn__content">
                                            <i class="fa-solid fa-circle-xmark fa-2x"></i>
                                        </div>
                                    </button>
                                    <div v-else-if="scope.row.recordstatus == 'H'" style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="danger fas fa-minus-circle"></i></div>
                                    <div v-else style="float:right; margin-top: 11px; margin-left: 4px;margin-right: 12px;"><i class="success fas fa-check-circle"></i></div>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-else
                            label="Active"
                            key="col13"
                            width="100"
                            fixed="right"
                            align="center">
                            <template #default="scope">
                                <i v-if="scope.row.recordstatus == 'I'" class="fa-solid fa-circle-xmark"></i>
                                <i v-else-if="scope.row.recordstatus != 'H'" class="danger fas fa-minus-circle"></i>
                                <i v-else class="success fas fa-check-circle"></i>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="row pagination">
                        <div class="col-12">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="[50, 100, 200, 500]"
                                :page-size="50"
                                layout="sizes, prev, pager, next"
                                :total="count">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="row mt-4 mb-4" v-if="modifyAccess" >
                        <div class="col-12 text-center">
                            <button v-if="modifyAccess" type="button" class="btn btn-success btn-icon" @click="savePlayers">
                                <i class="fas fa-check"></i> <span> Save Changes</span>
                            </button>
                            <button v-if="modifyAccess" type="button" class="btn btn-icon btn-secondary cancel"  @click="getPlayerPayments">
                                <i class="fas fa-times"></i>  <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <modal v-if="showModal" :top="'400px'" @close="showModal = false">
                <template v-slot:header>
                    <h5 class="modal-title">
                        {{modalTitle}}
                    </h5>
                </template>
                <template v-slot:body >
                    <Player ref="player" :playerData="modalData" :calling="'ProgramPlayers'" :ProgramIDAccess="ProgramIDAccess" :TeamIDs="TeamIDAccess" :createAccess="createAccess" :config="config" @close="showModal = false" @refreshData="refreshData" />
                </template>
                <template v-slot:footer>
                    {{modalFooter}}
                </template>
            </modal>
            <modal v-if="showPaymentModal" :showMask="(this.config.isMobile ? false : true)" :top="'400px'" @close="showPaymentModal = false">
                <template v-slot:header>
                    <h5 class="modal-title">
                        {{paymentmodalTitle}}
                    </h5>
                </template>
                <template v-slot:body>
                    <el-form ref="paymentForm" label-position="top" label-width="120px">
                        <div class="date mt-2">
                            <el-form-item label="Date:" prop="PaymentDate">
                                <el-date-picker key="pickdate" v-model="Payment.PaymentDate" default-first-option filterable
                                                type="date"
                                                format="MMM D, YYYY"
                                                value-format="YYYY-MM-DD"
                                                placeholder="Date">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="itemdetail mt-2 mb-2">
                            <el-form-item label="Description:" prop="Description">
                                <el-input type="textarea"
                                        style="width:300px;"
                                        :autosize="{ minRows: 2, maxRows: 10}"
                                        class="description"
                                        key="Description" select v-model="Payment.Description"
                                >
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="itemdetail mt-2 mb-2">
                            <el-form-item label="Amount:" prop="PaymentAmount">
                                <el-input type="number" class="smallCurrency" v-model="Payment.PaymentAmount">
                                    <template #prepend><span v-html="currencySign"></span></template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="itemdetail mt-2 mb-2">
                            <el-form-item label="Payment Image:" prop="PaymentImage">
                                <form class="form-horizontal fade-in-effect" novalidate method="post" enctype="multipart/form-data">
                                    <div class="fileinput-button " style="text-align:center;">
                                        <div id="PaymentImage" v-bind:class="{ 'success_upload' : isSuccess, 'failed_upload' : isFailed }" class="logo" style="position:relative;" :style="PaymentImage">
                                            <div id="spinner" style="position:absolute;top:24px;left:24px;" v-if="isFileSaving1"><i class="fas fa-spinner fa-spin fa-2x"></i> </div>
                                            <input type="file" id="PaymentImageInput" name="PaymentImage" :disabled="isFileSaving1" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*" class="input-file">
                                            <div v-if="RemoveImage" class="deleteImage" @click.prevent="removeFile"><i class="fas fa-times-circle fa-2x"></i></div>
                                        </div>
                                    </div>
                                </form>
                            </el-form-item>
                        </div>
                    </el-form>
                </template>
                <template v-slot:footer>
                    <div class="row mb-2" v-if="(modifyAccess || createAccess)  && !ProgramIDAccess" >
                        <div class="col-12 text-center">
                            <button class="btn btn-success btn-icon" type="button" @click="savePayment">
                                <i class="fas fa-check"></i> <span> Save Payment</span>
                            </button>
                            <button class="btn btn-icon btn-secondary cancel" type="button"  @click="showPaymentModal = false;">
                                <i class="fas fa-times"></i>  <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                </template>
            </modal>
            <modal v-if="showCreditModal" :showMask="(this.config.isMobile ? false : true)" :top="'400px'" @close="showCreditModal = false" class="credits">
                <template v-slot:header>
                    <h3 class="modal-title">
                        {{creditmodalTitle}}
                    </h3>
                </template>
                <template v-slot:body>
                    <el-form ref="creditForm" label-position="top" label-width="120px">
                        <div class="date mt-4">
                            <el-form-item label="Date:" prop="CreditDate">
                                <el-date-picker key="pickdate" v-model="Credit.CreditDate" default-first-option filterable
                                                type="date"
                                                format="MMM D, YYYY"
                                                value-format="YYYY-MM-DD"
                                                placeholder="Date">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="itemdetail mt-4 mb-4">
                            <el-form-item label="Description:" prop="Description">
                                <el-input   type="textarea"
                                            style="width:300px;"
                                            :autosize="{ minRows: 2, maxRows: 10}"
                                            class="description"
                                            key="Description" select v-model="Credit.Description"
                                >
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="itemdetail mt-4 mb-4">
                            <el-form-item label="Amount:" prop="CreditAmount">
                                <el-input type="number" class="smallCurrency" v-model="Credit.CreditAmount">
                                    <template #prepend><span v-html="currencySign"></span></template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                </template>
                <template v-slot:footer>
                    <div class="row mb-2" v-if="(modifyAccess || createAccess)  && !ProgramIDAccess" >
                        <div class="col-12 text-center">
                            <button class="btn btn-success btn-icon" type="button" @click="saveCredit">
                                <i class="fas fa-check"></i> <span> Save Credit</span>
                            </button>
                            <button class="btn btn-icon btn-secondary cancel" type="button"  @click="showCreditModal = false;">
                                <i class="fas fa-times"></i>  <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                </template>
            </modal>
            <modal v-if="showEmailModal" @close="showEmailModal = false" width="550px" :top="'200px'" class="emailmodal">
                <template v-slot:header>
                    <h5 class="modal-title">
                        Email Players
                    </h5>
                </template>
                <template v-slot:body>
                    <div class="row emailmodal">
                        <el-form>
                            <div class="col-12">
                                <el-form-item label="From Email:" prop="fromEmail">
                                    <el-input 
                                            class="fromEmail" 
                                            style="width: 400px;"
                                            placeholder="From Prefix"
                                            v-model="fromPrefix">
                                        <template #append>
                                            <el-select v-model="fromDomain" key="fromDomain" placeholder="Select" default-first-option filterable>
                                                <el-option
                                                    v-for="item in EmailDomains"
                                                    :key="item.Domain"
                                                    :label="'@' + item.Domain"
                                                    :value="'@' + item.Domain">
                                                    </el-option>
                                            </el-select>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="col-12">
                                <el-form-item label="Test To Email Address:" prop="toEmailAddress">
                                    <el-input style="width: 350px;"
                                            placeholder="Test To Email Address"
                                            v-model="toEmailAddress">
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="col-12">
                                <el-collapse class="mt-4 mb-4" >
                                    <el-collapse-item title="Replacement Variables" name="1">
                                        <ol><strong>For Emails, these fields can be used if enclosed with backticks.  i.e. `FirstName`<br></strong>
                                            <ul style="float:left;width: 50%;">
                                                <li>FirstName</li>
                                                <li>LastName</li>
                                                <li>EmailAddress</li>
                                                <li>IDNumber</li>
                                                <li>RegistrationApprovalCode</li>
                                                <li>TotalCreditAmount</li>
                                                <li>TotalPaymentAmount</li>
                                                <li>PastPayments</li>
                                                <li>Tuition</li>
                                                <li>Balance</li>
                                            </ul>
                                            <ul style="float:left;width: 40%;">
                                                <li>LeagueName</li>
                                                <li>ProgramName</li>
                                                <li>TeamName</li>
                                                <li>PlayerTypeName</li>
                                                <li>JerseyNumber</li>
                                                <li>CellPhoneNumber</li>
                                                <li>ParentFirstName</li>
                                                <li>ParentLastName</li>
                                                <li>DOB</li>
                                            </ul>
                                        </ol>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                            <div class="col-12">
                                <el-form-item label="Email Template" prop="SystemTextID">
                                    <el-select key="emailtemplate" class="oneHundred"  v-model="SystemTextID" placeholder="Select" @change="prefillEmailTemplate" default-first-option filterable>
                                        <el-option-group
                                            v-for="group in CustomEmailsGrouping"
                                            :key="group.label"
                                            :label="group.label"
                                        >
                                            <el-option
                                                v-for="item in group.options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-option-group>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="col-12">
                                <el-form-item label="Subject Line" prop="subject">
                                    <el-input
                                        v-model="subject">
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="col-12">
                                <el-form-item label="Message:" prop="message">
                                    <el-input type="textarea" :autosize="{ minRows:8, maxRows: 45}" v-model="message">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                </template>>
                <template v-slot:footer>
                    <div class="col-12 text-center mb-2">
                        <button v-if="toEmailAddress" type="button" class="btn btn-success btn-icon" @click="sendEmail">
                            <template v-if="!sendingEmails" ><i class="far fa-envelope"></i> <span> Send Test Emails</span></template>
                            <template v-else><i class="fas fa-spinner fa-pulse"></i> <span> Sending</span></template>
                        </button>
                        <button v-else  type="button" class="btn btn-success btn-icon" @click="sendEmail">
                            <template v-if="!sendingEmails" ><i class="far fa-envelope"></i> <span> Send Emails</span></template>
                            <template v-else><i class="fas fa-spinner fa-pulse"></i> <span> Sending</span></template>
                        </button>
                        <button  type="button" class="btn btn-icon btn-secondary cancel"  @click="showEmailModal = false">
                            <i class="fas fa-times"></i>  <span>Cancel</span>
                        </button>
                    </div>
                </template>
            </modal>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Modal from './global/Modal.vue';
    import axios from 'axios';
    import Player from './Player.vue';
    import Util from "../assets/js/util";
    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_FILESAVING1 = 4, STATUS_FILESAVING2 = 5;

    export default {
        name: 'PlayerPayments',
        components: {
            Modal,
            Player
        },
        data() {
            return {
                loading : false,
                currentStatus : STATUS_INITIAL,
                search:'',
                modalTitle: 'Edit Player',
                modalFooter: '',
                creditmodalTitle : 'Add Credit',
                paymentmodalTitle : 'Add Payment',
                showModal: false,
                showPaymentModal : false,
                showCreditModal : false,
                showEmailModal : false,
                fromPrefix : 'support',
                fromDomain : '@valley-associates.com',
                sendingEmails : false,
                subject: '',
                message : '',
                emailSelection : [],
                toEmailAddress : '',
                LeaguePrograms : [],
                PlayerTypes : [],
                Leagues : [{Params: {}}],
                ProgramID : '',
                PrevProgramID : '',
                LeagueID : '',
                PrevLeagueID : '',
                user : [],
                playerPayments : [],
                ProgramLeagues: [],
                playerregistrationinstructions : '',
                host: '',
                ProgramCode: '',
                Params : [],
                PaymentType : '',
                PaymentTypes : [],
                Tags : [],
                taglinks : [],
                newTagID: '',
                tagSelectVisible: false,
                searchupdate : 's',
                addremoveTags : 'a',
                havenotTags : 'y',
                SeasonID : '',
                PrevSeasonID : '',
                ActiveSeasons : [],
                teamNames : [],
                selectedTeamNames: [],
                currentPage: 1,
                count: 0,
                sizePerPage: 50,
                CustomEmails : [],
                CustomEmailsGrouping : [],
                SystemTextID : '',
                selectedBalance : 0,
                selectedExpression : 'Show All',
                expressions : [{label : 'Show All', value : 'Show All'},{label : '=', value : '=='},{label : 'Not =', value : '!='},{label : '>', value : '>'},{label : '>=', value : '>='},{label : '<', value : '<'},{label : '<=', value : '<='}],
                selectedPlayerTypeID : '',
                Payment : {InvoicePaymentID : '', PlayerID : '', Description : '', PaymentDate : '', SeasonID : '', PaymentStatus: '', PaymentAmount : 0, Params : {PaymentImage : ''}},
                PaymentStatus : [{ID : 'A', Name : 'Active'},{ID : 'B', Name : 'Returned from Bank'},{ID : 'V', Name : 'Void'}],
                Columns : ['Tags','Notes','PlayerTypeName','TeamName','RegistrationApprovalCode','RegDate','Contact','Tuition','Payments','Balance'],
                PlayerPaymentWidths : {},
                ParamFields : [],
                ParamsFieldBlackouts : ['Notes', 'Tags', 'taglinks', 'waiver', 'RegistrationTS', 'ActivationTS', 'Registration', 'Activation'],
                ParamsColumnBlackouts : ['taglinks','RegistrationTS','ActivationTS'],
                export: [],
                Credit : {InvoiceCreditID : '', PlayerID : '', Description : '', CreditDate : '', CreditAmount : 0},
	            selectedall : false,
                EmailDomains : [],
            }
        },
        props:["config","Season", "parentrefs"],
        beforeRouteLeave (to, from, next) {
            if (this.haveUnsavedData()) {
                let self = this;
                this.$confirm('You have made changes to Players on this screen and if you LEAVE you will lose them.', 'You have Unsaved Data.', {
                    distinguishCancelAndClose: true,
                    showClose: false,
                    confirmButtonText: 'Stay',
                    cancelButtonText: 'Leave',
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    type: 'warning'
                })
                    .then(function () {
                        next(false);
                    })
                    .catch(function () {
                        self.$noDataToBeSaved();
                        next();
                    });
            } else {
                next();
            }
        },
        computed: {
            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isFileSaving1() {
                return this.currentStatus === STATUS_FILESAVING1;
            },
            isFileSaving2() {
                return this.currentStatus === STATUS_FILESAVING2;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            modifyAccess () {
                return this.$modifyAccess();
            },
            createAccess () {
                return this.$createAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            TeamIDAccess () {
                return this.$TeamIDAccess();
            },
            filterPlayerPayments () {
				this.selectedall = false;
                this.emailSelection = [];
                var recs =  [];
                var num = 0;
                var validBalance;
                var tagsFound;
                var tagsOK;
                var playerHasTags;
                this.export = [];
                for (let i = 0; i < this.playerPayments.length; i++) {
	                this.playerPayments[i].selected = false;

                    if ((this.selectedTeamNames.length == 0 || this.selectedTeamNames.indexOf(this.playerPayments[i].TeamName) !== -1)
                        && (!this.selectedPlayerTypeID || this.selectedPlayerTypeID == this.playerPayments[i].PlayerTypeID))
                    {
                        if (this.selectedExpression == 'Show All') {
                            validBalance = true;
                        } else {
                            validBalance = eval(parseFloat(this.playerPayments[i].Balance) + ' ' + this.selectedExpression + ' ' + parseFloat(this.selectedBalance));
                        }
                        if (validBalance) {
                            tagsOK = true;
                            playerHasTags = false;
                            if (this.searchupdate == 's') {
                                if (this.taglinks.length > 0) {
                                    tagsOK = false;
                                    tagsFound = false;
                                    if (this.playerPayments[i].Params.taglinks && this.playerPayments[i].Params.taglinks.length > 0) {
                                        playerHasTags = true;
                                        tagsFound = false;
                                        for (let j = 0; j < this.taglinks.length; j++) {
                                            for (let k = 0; k < this.playerPayments[i].Params.taglinks.length; k++) {
                                                if (this.taglinks[j].TagID == this.playerPayments[i].Params.taglinks[k].TagID) {
                                                    tagsFound = true;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if (!tagsOK) {
                                if (tagsFound && this.havenotTags == 'y') {
                                    tagsOK = true;
                                } else if (!tagsFound && this.havenotTags == 'n') {
                                    tagsOK = true;
                                }
                            }
                            if (tagsOK) {
                                //see if search term matches pretty much anything
                                var searchOK = false;
                                if (this.search) {
                                    for (let j = 0; j < this.ParamFields.length; j++) {
                                        if (this.ParamsFieldBlackouts.indexOf(this.ParamFields[j]) === -1) {
                                            if (this.playerPayments[i]['Params'][this.ParamFields[j]] && this.playerPayments[i]['Params'][this.ParamFields[j]].toLowerCase().includes(this.search.toLowerCase()) && this.Columns.indexOf(this.ParamFields[j]) !== -1) {
                                                searchOK = true;
                                                break;
                                            }
                                        }
                                    }
                                    if (this.playerPayments[i].Name.toLowerCase().includes(this.search.toLowerCase())) {
                                        searchOK = true;
                                    }

                                    if (this.playerPayments[i].TeamName.toLowerCase().includes(this.search.toLowerCase()) && this.Columns.indexOf('TeamName') !== -1) {
                                        searchOK = true;
                                    }
                                    if (this.playerPayments[i].EmailAddress.toLowerCase().includes(this.search.toLowerCase()) && this.Columns.indexOf('EmailAddress') !== -1) {
                                        searchOK = true;
                                    }
                                    if (this.playerPayments[i].Params.Notes && this.playerPayments[i].Params.Notes.toLowerCase().includes(this.search.toLowerCase()) && this.Columns.indexOf('Notes') !== -1) {
                                        searchOK = true;
                                    }
                                } else {
                                    searchOK = true;
                                }
                                if (searchOK) {
                                    this.export.push(this.playerPayments[i]);
                                    if (num >= ((this.currentPage - 1) * this.sizePerPage) && num < (this.currentPage * this.sizePerPage)) {
                                        recs.push(this.playerPayments[i]);
                                    }
                                    num++;
                                }
                            }
                        }
                    }
                }
                this.count = num;
                return recs;
            },
            PaymentLeagues () {
                var leagues = [];
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.Leagues[i].Params && this.Leagues[i].Params.LeagueBilling == 'pl') {
                        if (this.ProgramIDAccess) {
                            if (this.LeaguePrograms[this.Leagues[i]['LeagueID']]) {
                                for (let j = 0; j < this.LeaguePrograms[this.Leagues[i]['LeagueID']].length; j++) {
                                    if (this.LeaguePrograms[this.Leagues[i]['LeagueID']][j]['ProgramID'] == this.currentUser.ProgramID) {
                                        leagues.push(this.Leagues[i]);
                                        break;
                                    }
                                }
                            }
                        } else {
                            leagues.push(this.Leagues[i]);
                        }
                    }
                }
                return leagues;
            },
            defaultLogo () {
                return require('../images/default_upload.png')
            },
            PaymentImage() {
                let img = 'backgroundImage: url(' + (this.Payment.Params.PaymentImage ? this.Payment.Params.PaymentImage : this.defaultLogo) + ')';
                return img;
            },
            RemoveImage() {
                if (this.Payment.Params.PaymentImage) {
                    return true;
                } else {
                    return false;
                }
            },
            currencySign () {
                return Util.currencySign(this.config);
            },
            availableSearchTags () {
                var tags = [];
                var tagOk = true;
                for (let i = 0; i < this.Tags.length; i++) {
                    tagOk = true;
                    for (let j = 0; j < this.taglinks.length; j++) {
                        if (this.Tags[i].TagID == this.taglinks[j].TagID) {
                            tagOk = false;
                            break;
                        }
                    }
                    if (this.Tags[i].TagArea != 'PL') {
                        tagOk = false;
                    }
                    if (tagOk) {
                        tags.push(this.Tags[i]);
                    }
                }
                return tags;
            },
        },
        methods: {
            dataToSave() {
                this.$haveDataToBeSaved();
            },
            haveUnsavedData() {
                return this.$haveUnsavedData();
            },
            handleSizeChange(val) {
                this.sizePerPage = val;
                this.currentPage = 1;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
            },
            handleNewSearchTag() {
                if (this.searchupdate == 's') {
                    this.currentPage = 1;
                }
                let newTagID = this.newTagID;
                if (newTagID) {
                    for (let i = 0; i < this.Tags.length; i++) {
                        if (this.Tags[i].TagID == newTagID) {
                            this.taglinks.push(this.Tags[i]);
                            break;
                        }
                    }
                }
                this.tagSelectVisible = false;
                this.newTagID = '';
            },
            handleCloseSearchTag(tag) {
                if (this.searchupdate == 's') {
                    this.currentPage = 1;
                }
                this.taglinks.splice(this.taglinks.indexOf(tag), 1);
            },
            showSearchTagSelect() {
                this.tagSelectVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveSearchTagInput.focus();
                });
            },
            availableTags (idx) {
                idx = this.findPlayerIDX(idx);
                var tags = [];
                var tagOk = true;
                for (let i = 0; i < this.Tags.length; i++) {
                    tagOk = true;
                    if (this.playerPayments[idx].Params.taglinks) {
                        for (let j = 0; j < this.playerPayments[idx].Params.taglinks.length; j++) {
                            if (this.Tags[i].TagID == this.playerPayments[idx].Params.taglinks[j].TagID) {
                                tagOk = false;
                                break;
                            }
                        }
                    }
                    if (this.Tags[i].TagArea != 'PL') {
                        tagOk = false;
                    }
                    if (tagOk) {
                        tags.push(this.Tags[i]);
                    }
                }
                return tags;
            },
            handleNewTag(idx) {
                idx = this.findPlayerIDX(idx);
                let newTagID = this.playerPayments[idx].newTagID;
                if (newTagID) {
                    for (let i = 0; i < this.Tags.length; i++) {
                        if (this.Tags[i].TagID == newTagID) {
                            if (!this.playerPayments[idx].Params.taglinks) {
                                this.playerPayments[idx].Params['taglinks'] = [];
                            }
                            this.playerPayments[idx].Params.taglinks.push(this.Tags[i]);
                            break;
                        }
                    }
                }
                this.playerPayments[idx].tagSelectVisible = false;
                this.playerPayments[idx].newTagID = '';
                this.playerPayments[idx].operation = 'update';
                this.dataToSave();
            },
            handleCloseTag(tag, idx) {
                idx = this.findPlayerIDX(idx);
                this.playerPayments[idx].Params.taglinks.splice(this.playerPayments[idx].Params.taglinks.indexOf(tag), 1);
                this.playerPayments[idx].operation = 'update';
                this.dataToSave();
            },
            showTagSelect(idx) {
                var Pidx = this.findPlayerIDX(idx);
                this.playerPayments[Pidx].tagSelectVisible = true;
                var self = this;
                self.$nextTick(_ => {
                    self.$refs['saveTagInput'+idx].focus();
                });
            },
            findPlayerIDX (idx) {
                var PlayerID = this.filterPlayerPayments[idx].PlayerID;
                for (let i = 0; i < this.playerPayments.length; i++) {
                    if (this.playerPayments[i].PlayerID == PlayerID) {
                        return i;
                    }
                }
                return -1;
            },
            prefillEmailTemplate () {
                for (let i = 0; i < this.CustomEmails.length; i++) {
                    if (this.CustomEmails[i].SystemTextID == this.SystemTextID) {
                        this.subject = this.CustomEmails[i].Params.Subject;
                        this.message = this.CustomEmails[i].SystemText;
                        break;
                    }
                }
            },
	        getSummaries(param) {

		        const { columns, data } = param;
		        const sums = [];
		        columns.forEach((column, index) => {
			        if (index === 0) {
				        sums[index] = 'Total';
				        return;
			        }
			        const values = data.map(item => Number(item[column.property]));
			        if (index === 1) {
				        sums[index] = values.length;
				        return;
			        }
			        if (!values.every(value => isNaN(value))) {
				        if (column.property == 'Tuition' || column.property == 'TotalCreditAmount' || column.property == 'TotalPaymentAmount' || column.property == 'Balance') {
					        sums[index] = this.formatPrice(values.reduce((prev, curr) => {
						        const value = Number(curr);
						        if (!isNaN(value)) {
							        return prev + curr;
						        } else {
							        return prev;
						        }
					        }, 0));
				        } else {
					        sums[index] = '';
				        }
			        } else {
				        sums[index] = '';
			        }
		        });
		        return sums;
	        },
	        toggleAll (val) {
		        for (let j = 0; j < this.filterPlayerPayments.length; j++) {
			        this.filterPlayerPayments[j].selected = val;
		        }
				this.selectedall = val;
				if (val) {
					this.emailSelection = JSON.parse(JSON.stringify(this.filterPlayerPayments));
				} else {
					this.emailSelection = [];
				}
	        },
            handleSelectionChange(PlayerID) {
				for (let i = 0; i < this.filterPlayerPayments.length; i++) {
					if (PlayerID == this.filterPlayerPayments[i].PlayerID) {
						if (!this.filterPlayerPayments[i].selected) {
							this.emailSelection.push(this.filterPlayerPayments[i]);
						} else {
							for (let j = 0; j < this.emailSelection.length; j++) {
								if (PlayerID == this.emailSelection[j].PlayerID) {
									//remove
									this.emailSelection.splice(j, 1);
								}
							}
						}
						break;
					}
				}
            },
            sortPlayers (data) {
                if (this.$refs.paymenttable) {
                    this.$refs.paymenttable.clearSort();
                }
                if (data.prop == 'Name' ) {
                    this.playerPayments.sort(function (a, b) {
                        if (data.order == 'ascending') {
                            return (a['LastName']+a['FirstName']).toLowerCase().localeCompare((b['LastName']+b['FirstName']).toLowerCase());
                        } else {
                            return (b['LastName']+b['FirstName']).toLowerCase().localeCompare((a['LastName']+a['FirstName']).toLowerCase());
                        }
                    });
                } else if (data.prop == 'PlayerTypeName' || data.prop == 'TeamName' ) {
                    this.playerPayments.sort(function (a, b) {
                        if (data.order == 'ascending') {
                            return a[data.prop].toLowerCase().localeCompare(b[data.prop].toLowerCase());
                        } else {
                            return b[data.prop].toLowerCase().localeCompare(a[data.prop].toLowerCase());
                        }
                    });
                } else if (data.prop == 'RegistrationDate') {
                    this.playerPayments.sort(function (a, b) {
                        if (data.order == 'ascending') {
                            return parseInt(a.Params.RegistrationTS) - parseInt(b.Params.RegistrationTS);
                        } else {
                            return parseInt(b.Params.RegistrationTS) - parseInt(a.Params.RegistrationTS);
                        }
                    });
                } else if (data.prop == 'Tuition' || data.prop == 'Balance' ) {
                    this.playerPayments.sort(function (a, b) {
                        if (data.order == 'ascending') {
                            return parseFloat((a[data.prop] ? a[data.prop] : 0)) - parseFloat((b[data.prop] ? b[data.prop] : 0));
                        } else {
                            return parseFloat((b[data.prop] ? b[data.prop] : 0)) - parseFloat((a[data.prop] ? a[data.prop] : 0));
                        }
                    });
                }
                this.currentPage = 1;
            },

            leagueChange() {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Players on this screen which will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Get New Players',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            self.LeagueID = self.PrevLeagueID;
                            self.$refs['league'].blur();
                            return;
                        })
                        .catch(function () {
                            self.$refs['league'].blur();
                            self.selectedTeamNames = [];
                            if (!self.ProgramIDAccess) {
                                self.ProgramID = '';
                            }
                            self.refreshData();
                        });
                } else {
                    this.selectedTeamNames = [];
                    if (!this.ProgramIDAccess) {
                        this.ProgramID = '';
                    }
                    this.refreshData();
                }
            },
            programChange () {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Players on this screen which will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Get New Players',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            self.ProgramID = self.PrevProgramID;
                            self.$refs['program'].blur();
                            return;
                        })
                        .catch(function () {
                            self.$refs['program'].blur();
                            self.selectedTeamNames = [];
                            self.refreshData();
                        });
                } else {
                    this.selectedTeamNames = [];
                    this.refreshData();
                }
            },
            seasonChange() {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Players on this screen which will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Get New Players',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            self.SeasonID = self.PrevSeasonID;
                            self.$refs['season'].blur();
                            return;
                        })
                        .catch(function () {
                            self.$refs['season'].blur();
                            self.refreshData();
                        });
                } else {
                    this.refreshData();
                }
            },
            PlayerRegistration(LeagueID) {
                for (let i = 0; i < this.ProgramLeagues.length; i++) {
                    if (this.ProgramLeagues[i].LeagueID == LeagueID) {
                        if (this.ProgramLeagues[i].RegistrationSettings.Params && this.ProgramLeagues[i].RegistrationSettings.Params.AllowRegistration) {
                            return this.ProgramLeagues[i].RegistrationSettings.Params.AllowRegistration;
                        }
                    }
                }
                return 'n';
            },
            TeamAccess(row) {
                if (this.TeamIDAccess.length == 0 || this.TeamIDAccess.indexOf(row.TeamID) >= 0) {
                    return true;
                } else {
                    return false;
                }
            },
            formatPrice(value) {
	            if (value) {
		            return Util.formatPrice(this.config, parseFloat(value), '0');
	            } else {
		            return '';
	            }
            },
            arraySpanMethod({row, column, rowIndex, columnIndex}) {
                if (row.PlayerID == 0) {
                    if (columnIndex == 1) {
                        if (!this.ProgramIDAccess && this.TeamIDAccess.length == 0) {
                            return [1, 9];
                        } else {
                            return [1, 8];
                        }
                    } else {
                        return [0, 0];
                    }
                } else {
                    return [1, 1];
                }
            },
            tableRowClassName({row, rowIndex}) {
                if (row.PlayerID == 0) {
                    return 'team-row';
                } else {
                    return '';
                }
            },
            getInitialData() {
                var tables = ['LeaguePrograms', 'ProgramLeagues', 'ActiveSeasons', 'Leagues', 'PlayerTypes', 'Tags', 'EmailDomains'];
                axios.post('/index/tableData', {'tables': tables,},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                            if (this.componentIDs.PlayerPaymentsLeagueID) {
                                this.LeagueID = this.componentIDs.PlayerPaymentsLeagueID;
                            }
                            if (this.ProgramIDAccess && this.currentUser.ProgramID) {
                                this.ProgramID = this.currentUser.ProgramID;
                            } else {
                                if (this.componentIDs.PlayerPaymentsProgramID) {
                                    this.ProgramID = this.componentIDs.PlayerPaymentsProgramID;
                                }
                            }
                            if (this.componentIDs.PlayerPaymentsSeasonID) {
                                this.SeasonID = this.componentIDs.PlayerPaymentsSeasonID;
                            }
                            if (!this.SeasonID) {
                                this.SeasonID = this.ActiveSeasons[0]['SeasonID'];
	                            for (let  i = 0; i < this.ActiveSeasons.length; i++) {
		                            if (this.ActiveSeasons[i]['Params']['DefaultSeason'] == 'y') {
			                            this.SeasonID = this.ActiveSeasons[i]['SeasonID'];
			                            break;
		                            }
	                            }
                            }
                            this.getEmailTemplates();
                            this.getPlayerPayments();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getEmailTemplates () {
                axios.post('/systemtext/getcustomtext', {CustomTextID : 'emailtemplate', Status : 'A'}	)
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.CustomEmails) {
                                this.CustomEmails = response.data.CustomEmails;
                                this.CustomEmailsGrouping = response.data.CustomEmailsGrouping;
                            }
                        }
                    }).catch(response => {
                });
            },
            getPlayerPayments() {
                this.currentPage = 1;
                let self = this;
                this.emailSelection = [];
                this.PrevLeagueID = this.LeagueID;
                this.PrevSeasonID = this.SeasonID;
                this.PrevProgramID = this.ProgramID;
                if (this.LeagueID) {
                    this.loading = true;
                    axios.post('/programplayers/getAllPayments', {
                            LeagueID: this.LeagueID,
                            ProgramID: this.ProgramID,
                            TeamIDs: this.TeamIDAccess,
                            SeasonID: this.SeasonID,
                            IncludeZeroBalance : 'y'
                        },
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                self.$noDataToBeSaved();
                                self.$store.dispatch('setID', {'PlayerPaymentsLeagueID': self.LeagueID});
                                self.$store.dispatch('setID', {'PlayerPaymentsProgramID': self.ProgramID});
                                self.$store.dispatch('setID', {'PlayerPaymentsSeasonID': self.SeasonID});
                                this.host = response.data.host;
                                this.ProgramCode = response.data.ProgramCode;
                                this.ProgramLeagues = (response.data.ProgramLeagues ? response.data.ProgramLeagues : []);
                                this.playerregistrationinstructions = (response.data.playerregistrationinstructions ? response.data.playerregistrationinstructions : '');
                                this.PaymentTypes = (response.data.PaymentTypes ? response.data.PaymentTypes : []);
                                this.teamNames = (response.data.teamNames ? response.data.teamNames : []);
                                this.ParamFields = (response.data.paramfields ? response.data.paramfields : []);

                                if (!response.data.players) {
                                    response.data.players = [];
                                }
                                for (let i = 0; i < response.data.players.length; i++) {
                                    response.data.players[i].ParamsF = JSON.stringify(response.data.players[i].Params, null, '\t');
                                    this.Params.push(response.data.players[i].Params);
                                }
                                this.count = (response.data.players ? response.data.players.length : 0);
                                this.playerPayments = response.data.players;
                                this.$stopProgressBar(this.parentrefs);
                            } else {
                                this.playerPayments = [];
                                this.$failProgressBar(this.parentrefs);
                            }
                            this.loading = false;
                        }).catch(response => {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                    });
                } else {
                    this.playerPayments = [];
                    this.loading = false;
                }
            },
            editPlayerCnfm(index, row) {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Players on this screen which will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Edit Player',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            return;
                        })
                        .catch(function () {
                            self.editPlayer(index, row);
                        });
                } else {
                    this.editPlayer(index, row);
                }
            },
            editPlayer(index, row) {
                this.modalTitle = 'Edit Player';
                this.modalData = row;
                this.showModal = true;
            },
            addPaymentCnfm(index, row) {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Players on this screen which will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Add Payment',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            return;
                        })
                        .catch(function () {
                            self.addPayment(index, row);
                        });
                } else {
                    this.addPayment(index, row);
                }
            },
            addPayment(index, row) {
                this.paymentmodalTitle = 'Add Payment';
                this.Payment.InvoicePaymentID = '';
                this.Payment.PlayerID = row.PlayerID;
                this.Payment.Description = '';
                this.Payment.PaymentDate = this.todaysDate();
                this.Payment.PaymentAmount = '';
                this.Payment.PaymentStatus = 'A';
                this.Payment.Params = {PaymentImage: ''};
                this.Payment.SeasonID = this.SeasonID;
                this.showPaymentModal = true;
            },
            editPayment(payment, PlayerID) {
                this.paymentmodalTitle = 'Edit Payment';
                this.Payment = {};
                this.Payment.InvoicePaymentID = payment.id;
                this.Payment.PlayerID = PlayerID;
                this.Payment.Description = payment.title;
                this.Payment.PaymentDate = payment.date;
                this.Payment.PaymentAmount = payment.amount;
                this.Payment.Params = payment.params;
                this.showPaymentModal = true;
            },
            savePayment() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (this.Payment.PaymentDate != '' && this.Payment.PaymentAmount != '' && this.Payment.SeasonID != '') {
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);
                    axios.post('/programplayers/savepayment', {Payment: this.Payment}
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.currentStatus = STATUS_INITIAL;
                                this.$stopProgressBar(this.parentrefs);

                                this.$notify({
                                    title: 'Success',
                                    message: "Payment has been saved.",
                                    type: 'success',
                                    duration: 2000,
                                    showClose: false
                                });
                                this.showPaymentModal = false;
                                this.getPlayerPayments();
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup(response.data.message);
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.$confirm('All Fields are Required.', 'Error', {
                        confirmButtonText: 'OK',
                        type: 'error',
                        showClose: false,
                        showCancelButton: false
                    });
                }

            },
            addCreditCnfm(index, row) {
                if (this.haveUnsavedData()) {
                    let self = this;
                    this.$confirm('You have made changes to the Players on this screen which will be lost.', 'You have Unsaved Data.', {
                        distinguishCancelAndClose: true,
                        showClose: false,
                        confirmButtonText: 'Keep Changes',
                        cancelButtonText: 'Add Credit',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'warning'
                    })
                        .then(function () {
                            return;
                        })
                        .catch(function () {
                            self.addCredit(index, row);
                        });
                } else {
                    this.addCredit(index, row);
                }
            },
            addCredit (index, row){
                this.creditmodalTitle = 'Add Credit';
                this.Credit.InvoiceCreditID = '';
                this.Credit.PlayerID = row.PlayerID;
                this.Credit.Description = '';
                this.Credit.CreditDate = this.todaysDate();
                this.Credit.CreditAmount = '';
                this.showCreditModal = true;
            },
            editCredit (credit, PlayerID){
                this.creditmodalTitle = 'Edit Credit';
                this.Credit.InvoiceCreditID = credit.id ;
                this.Credit.PlayerID = PlayerID;
                this.Credit.Description = credit.title;
                this.Credit.CreditDate = credit.date;
                this.Credit.CreditAmount = credit.amount;
                this.showCreditModal = true;
            },
            saveCredit() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (this.Credit.CreditDate != '' && this.Credit.CreditAmount != '') {
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);
                    axios.post('/finance/saveinvoicecredit', {InvoiceCredit : this.Credit}
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.currentStatus = STATUS_INITIAL;
                                this.$stopProgressBar(this.parentrefs);

                                this.$notify({
                                    title: 'Success',
                                    message: "Credit has been saved.",
                                    type: 'success',
                                    duration: 2000,
                                    showClose: false
                                });
                                this.showCreditModal = false;
                                this.getPlayerPayments();
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup(response.data.message);
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.$confirm('Date and Amount are Required.', 'Error', {
                        confirmButtonText: 'OK',
                        type: 'error',
                        showClose: false,
                        showCancelButton: false
                    });
                }

            },

            activatePlayer(index, row) {
                axios.post('/programplayers/activate', {'PlayerID': row.PlayerID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.refreshData();
                            this.$notify({
                                title: 'Success',
                                message: "Player has been Activated",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                        } else {
                            this.$notify({
                                title: 'Error',
                                message: "Player cannot be Activated",
                                type: 'error',
                                duration: 2000,
                                showClose: false
                            });
                        }
                    }).catch(response => {
                    this.$notify({
                        title: 'Error',
                        message: "Player cannot be Activated",
                        type: 'error',
                        duration: 2000,
                        showClose: false
                    });
                });
            },
            deleteCnfm(InvoicePaymentID) {
                this.$confirm('Are you sure you wish to delete this payment?', 'Confirmation', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning',
                    showClose: false,
                    showCancelButton: true
                }).then(() => {
                    this.deletePayment(InvoicePaymentID);
                }).catch(() => {
                });

            },
            deletePayment(InvoicePaymentID) {
                axios.post('/finance/deleteinvoicepayment', {'InvoicePaymentID': InvoicePaymentID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.getPlayerPayments();
                            this.$notify({
                                title: 'Success',
                                message: "Payment has been Deleted",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                        } else {
                            this.$notify({
                                title: 'Error',
                                message: "Payment cannot be Deleted",
                                type: 'error',
                                duration: 2000,
                                showClose: false
                            });
                        }
                    }).catch(response => {
                    this.$notify({
                        title: 'Error',
                        message: "Payment cannot be Deleted",
                        type: 'error',
                        duration: 2000,
                        showClose: false
                    });
                });

            },
            deleteCreditCnfm(InvoiceCreditID) {
                this.$confirm('Are you sure you wish to delete this credit?', 'Confirmation', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning',
                    showClose: false,
                    showCancelButton: true
                }).then(() => {
                    this.deleteCredit(InvoiceCreditID);
                }).catch(() => {
                });
            },
            deleteCredit(InvoiceCreditID) {
                axios.post('/finance/deleteinvoicecredit', {InvoiceCreditID : InvoiceCreditID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.getPlayerPayments();
                            this.$notify({
                                title: 'Success',
                                message: "Credit has been Deleted",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                        } else {
                            this.$notify({
                                title: 'Error',
                                message: "Credit cannot be Deleted",
                                type: 'error',
                                duration: 2000,
                                showClose: false
                            });
                        }
                    }).catch(response => {
                    this.$notify({
                        title: 'Error',
                        message: "Credit cannot be Deleted",
                        type: 'error',
                        duration: 2000,
                        showClose: false
                    });
                });

            },

            displayPopup(message) {
                this.$alert(message, 'Player Error', {
                    confirmButtonText: 'OK',
                });
            },
            uploadFile(formData) {
                if (this.currentStatus == STATUS_SAVING || this.currentStatus == STATUS_FILESAVING1) {
                    return true;
                }
                // upload data to the server
                this.currentStatus = STATUS_FILESAVING1;
                this.$startProgressBar(this.parentrefs);

                axios.post('/settings/uploadlogo', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_SUCCESS;
                            this.Payment.Params['PaymentImage'] = response.data.url;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.displayPopup(response.data.message);
                            this.$failProgressBar(this.parentrefs);
                        }
                        document.getElementById("PaymentImageInput").value = "";
                    }).catch(response => {
                    this.currentStatus = STATUS_FAILED;
                    this.$failProgressBar(this.parentrefs);
                });
            },
            filesChange(fieldName, fileList) {
                if (this.currentStatus == STATUS_SAVING || this.currentStatus == STATUS_FILESAVING1) {
                    return true;
                }
                // handle file changes
                const formData = new FormData();

                if (!fileList.length) return;

                // append the files to FormData
                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {
                        formData.append(fieldName, fileList[x], fileList[x].name);
                    });

                // save it
                this.uploadFile(formData);
            },
            removeFile() {
                this.Payment.Params['PaymentImage'] = '';
            },
            todaysDate() {
                var now = new Date();
                var y = now.getFullYear();
                var m = now.getMonth() + 1;
                var d = now.getDate();
                var mm = m < 10 ? '0' + m : m;
                var dd = d < 10 ? '0' + d : d;
                return '' + y + '-' + mm + '-' + dd;
            },
            refreshData() {
                this.getPlayerPayments();
            },
            getEmailDetails () {
                this.showEmailModal = true;
            },
            sendEmail () {
                if (!this.sendingEmails && this.emailSelection.length > 0) {
                    this.sendingEmails = true;

                    axios.post('/programcontacts/sendemail', {LeagueID : this.LeagueID, 'PlayerRecords': this.emailSelection, 'subject' : this.subject, 'message' : this.message, fromEmailAddress :  (this.fromPrefix ? this.fromPrefix + this.fromDomain : ''),  toEmailAddress :  this.toEmailAddress },
                    )
                        .then(response => {
                            this.sendingEmails = false;
                            if (response.data.result == 'success') {
                                this.$notify({
                                    title: 'Success',
                                    message: (response.data.sent == 1 ? '1 message has' : response.data.sent + ' messages have') + " been sent",
                                    type: 'success',
                                    duration: 1500,
                                    showClose: false
                                });
                                this.showEmailModal = false;
                            } else {
                                this.displayPopup (response.data.message);
                            }
                        }).catch(response => {
                            this.sendingEmails = false;
                    });
                }
            },
            addremovePlayerTags() {
                var PlayerIDs = [];
                if (this.emailSelection.length > 0) {
                    for (let i = 0; i < this.emailSelection.length; i++) {
                        PlayerIDs.push(this.emailSelection[i]['PlayerID']);
                    }

                    for (let i = 0; i < this.playerPayments.length; i++) {
                        if (PlayerIDs.indexOf(this.playerPayments[i]['PlayerID']) !== -1) {
                            //add tag if it does not already exist or remove tag is it exists
                            for (let t = 0; t < this.taglinks.length; t++) {
                                var tagfound = false;
                                var tagj = -1;
                                if (this.playerPayments[i].Params.taglinks) {
                                    for (let j = 0; j < this.playerPayments[i].Params.taglinks.length; j++) {
                                        if (this.taglinks[t].TagID == this.playerPayments[i].Params.taglinks[j]['TagID']) {
                                            tagfound = true;
                                            tagj = j;
                                            break;
                                        }
                                    }
                                } else {
                                    this.playerPayments[i].Params['taglinks'] = [];
                                }
                                if (!tagfound && this.addremoveTags == 'a') {
                                    this.playerPayments[i].Params.taglinks.push(this.taglinks[t]);
                                    this.playerPayments[i].operation = 'update';
                                    this.dataToSave();
                                } else if (tagfound && this.addremoveTags == 'r') {
                                    this.playerPayments[i].Params.taglinks.splice(tagj,1);
                                    this.playerPayments[i].operation = 'update';
                                    this.dataToSave();
                                }
                            }
                        }
                    }
                }
            },
            savePlayers () {
                axios.post('/programplayers/saveall', {PlayerRecords : this.playerPayments },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.updates) {
                                this.$notify({
                                    title: 'Success',
                                    message: response.data.updates + ' Player Changes have been saved',
                                    type: 'success',
                                    duration: 1500,
                                    showClose: false
                                });
                                this.refreshData();
                            }
                        } else {
                            this.displayPopup (response.data.message);
                        }
                    }).catch(response => {
                });
            },
            PlayerPaymentWidthChange (newWidth, oldWidth, column, event) {
                if (column.property) {
                    this.PlayerPaymentWidths[column.property.replace('Params.','')] = newWidth;
                    this.saveSettings();
                }
            },
            saveSettings() {

                axios.post('/users/savesettings', { PlayerPaymentColumns : this.Columns, PlayerPaymentWidths : this.PlayerPaymentWidths },
                )
                    .then(response => {

                    }).catch(response => {

                });
            },
            getSettings() {

                axios.post('/users/getsettings', {  },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.user.Params.PlayerPaymentColumns) {
                                this.Columns = response.data.user.Params.PlayerPaymentColumns;
                            }
                            this.PlayerPaymentWidths = (!Array.isArray(response.data.user.Params.PlayerPaymentWidths) && response.data.user.Params.PlayerPaymentWidths ? response.data.user.Params.PlayerPaymentWidths : {});
                        }

                    }).catch(response => {

                });
            },
            exportCSV () {
                var list = [];
                var newList = [];
                let columns = ['PlayerID','FirstName','LastName','ProgramName','LeagueName','TeamName','PlayerTypeName','IDNumber','EmailAddress','RegistrationApprovalCode', 'RegistrationDate','createdwhen','recordstatus'];
                for (let i = 0; i < this.ParamFields.length; i++) {
                    if (columns.indexOf(this.ParamFields[i]) == -1 && this.ParamsColumnBlackouts.indexOf(this.ParamFields[i]) == -1 ) {
                        columns.push(this.ParamFields[i]);
                    }
                }
                columns.push('Tuition');
                columns.push('TotalCreditAmount');
                columns.push('TotalPaymentAmount');
                columns.push('Balance');

                //this is a Program specific user and will have limited fields.

                for (let i = 0; i < this.export.length; i++) {
                    if (this.export[i].PlayerID != '0') {
                        var newitem = {};
                        for (let j = 0; j < columns.length; j++) {
                            if (this.export[i]['Params'] && this.export[i]['Params'][columns[j]]) {
                                newitem[columns[j]] = this.export[i]['Params'][columns[j]];
                            } else if (this.export[i][columns[j]]) {
                                newitem[columns[j]] = this.export[i][columns[j]];
                            } else {
                                newitem[columns[j]] = '';
                            }
                        }
                        list.push(newitem);
                    }
                }

                var csv = Papa.unparse(list);
                var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                var csvURL =  null;
                if (navigator.msSaveBlob)
                {
                    csvURL = navigator.msSaveBlob(csvData, 'players.csv');
                }
                else
                {
                    csvURL = window.URL.createObjectURL(csvData);
                }

                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', 'players.csv');
                tempLink.click();
            },
        },
        mounted () {
            this.getSettings();
            this.getInitialData();
        },
    }
</script>
<style>
    #playerpayments .el-table th {
        font-size: 120%;
        font-weight: 800;
    }
    #playerpayments .modal-dialog {
        width: 362px;
    }
    #playerpayments .emailmodal .modal-dialog {
        width: 500px;
    }
    #playerpayments .modal-body {
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    #playerpayments .credits .modal-body {
        height: 300px;
    }
	#playerpayments .emailmodal .modal-body {
		height: 550px;
	}
    #playerpayments .modal-footer {
        height: 55px;
	    padding: 0;
    }
    .playerpayments div.el-table .cell {
        word-break: normal;
    }
    #playerpayments .team-row td {
        text-align: left;
    }
    .emailmodal .el-form-item__label {
        line-height: 15px;
    }
    #playerpayments .fromEmail .el-input__wrapper {
        min-width: 120px;
    }
    #playerpayments .fromEmail .el-input-group__append .el-input__wrapper {
        min-width: 250px;
    }
</style>
<style scoped>
    #playerpayments .scores {
        width: 40px;
        text-align: center;
        padding: 1px;
        margin: 1px 0px;
        height: 23px;
    }
    #playerpayments .checkbox {
        display: inline-block;
        width: 25px;
        height: 15px;
    }
    #playerpayments label {
	    font-size: 14px;
    }
    #playerpayments .webnotes {
        width: 90%;
        height: 40px;
        padding: 2px;
    }
    #playerpayments .heading {
        background-color: #1A719C;
        color: #fff;
        font-size: 14px;
    }
    #playerpayments .body {
        color: #333;
        font-size: 14px;
        padding: 2px;
    }
    #playerpayments .body input, #playerpayments .body select, #playerpayments .body textarea {
        color: #333;
        font-size: 14px;
    }
    #playerpayments .body select {
        padding: 1px;
    }

    #playerpayments .body.row:nth-child(even){
        background-color: #dcdcdc;
    }
    #playerpayments .body.row:nth-child(odd){
        background-color: #ffffff;
    }
    #playerpayments .row.buttons {
        margin-top: 20px;
    }
    select.not-available {
        width: auto;
        margin-left: 0px   !important;
    }
    .not-available label {
        margin-top: 4px;
    }
    .program-select {
        font-weight: 800;
    }
    .options {
        margin: 0px 5px;
    }
    .el-select {
        width: 100%;
    }
    .programName {
        display: inline-block;
        font-weight: 800;
    }
    i.success {
        color: var(--el-color-success);
    }
    i.danger {
        color: var(--el-color-danger);
    }
    i.warning {
        color: var(--el-color-warning);
    }
    .regLink {
        width: 100%;
        margin-bottom: 5px;
        -webkit-user-select: all;
        -moz-user-select: all;
        -ms-user-select: all;
        user-select: all;
    }
    .regclosed {
        margin-left: 15px;
        font-weight: 800;
        color: var(--el-color-danger);
    }
    .RegistrationLinks {
        border: 1px solid #ccc;
        padding: 10px;
        background-color: #f9f9f9;
        margin: 20px;
        max-width: 650px;
    }
    .program-select .control-label {
        width: 120px;
    }
    .cell i {
        font-size: 16px;
    }
    .itemdetail .description {
        width: 200px;
    }
    .itemdetail .el-input {
        width: 150px;
    }
    #PaymentImage {
        width: 100%;
        height: 100%;
        min-width: 450px;
        min-height: 200px;
        background: #FFFFFF left top no-repeat;
        background-size: contain;
        cursor: pointer;
    }
    #PaymentImage .image .success_upload {
        border: 4px solid #28a745;
    }
    #PaymentImage .image .failed_upload {
        border: 4px solid #dc3545;
    }
    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        left:0;
    }
    .deleteImage {
        font-size: 14px;
        cursor:pointer;
        position: absolute;
        top: 0;
        background: #FFF;
        left: 0px;
        color: #666;
        padding: 0px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        line-height: 9px;
    }
    .detailheader {
        border-bottom: 1px solid #e0e0e0;
    }
    .even {
        background-color: #f5f5f5;
    }
    .el-select.Expression {
        width: 75px;
    }
    .el-input.Balance {
        margin-left: 5px;
        width: 114px;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 25px;
        line-height: 25px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .largetags {
        height: 127px;
    }
    .playerinput{
        width: 100%;
        text-align: left;
        padding: 1px;
        margin: 1px 0px;
        height: 26px;
        border: 1px solid #ddd;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #fff;
    }
    .editnotes {
        width: 100%;
        text-align: left;
        padding: 1px;
        margin: 1px 0px;
        border: 1px solid #ddd;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #fff;
    }
    .columns {
        margin-top: 5px;
		height: 50px;
    }
    .search {
        width: 100%;
        max-width: 300px;
    }
</style>
