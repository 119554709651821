<template>
    <div id="contractapproval" v-loading="loading">
        <div class="row">
            <div class="col-12">
                <div class="mt-4 heading" v-html="ApprovalHeading"></div>
            </div>
        </div>
        <template v-if="isPurchaseOrder">
            <div class="row">
                <el-card class="box-card mt-4 page" v-for="(text, index) in DisplayText" :key="index">
                    <div class="displaytext" v-html="text"></div>
                    <template v-if="index+1 === DisplayText.length  && purchaseorder.POStatus == 'A'">
                        <div style="position:relative;">
                            <div class="signature" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px; height: 200px;background-image : url(' + purchaseorder.Signature + ');background-size: contain;'"></div>
                            <div class="mt-4 mb-4 printName" >Signee Name: {{purchaseorder.Params.PrintName}}</div>
                            <div class="mt-4 mb-4">
                                <span class="signatureLine mb-4">Purchase Order Executed on: {{purchaseorder.POApprovalTime}}</span><el-button style="margin-left: 40px;" type="success" size="small"  @click="showPDF"><i class="fas fa-file-download"></i>&nbsp;Download Signed Purchase Order</el-button>
                            </div>
                        </div>
                    </template>
                    <div class="pagefooter">Page: {{ index+1 }} of {{DisplayText.length}}</div>
                </el-card>
            </div>
            <div v-if="purchaseorder.POStatus != 'A' && purchaseorder.PurchaseOrderID" class="row">
                <el-card class="box-card mt-4" style="width: 100%;">
                    <span class="mt-4 catractsignature">
                    <el-alert
                        title="By signing below and accepting this purchase order it will become active."
                        type="warning"
                        effect="dark"
                        :closable="false">
                        </el-alert>
                    </span>
                    <div style="position:relative;">
                        <vue3Signature v-if="!purchaseorder.Signature || newSignature" class="signature" ref="signature" :sigOption="signatureoptions" :w="(config.isMobile ? '300' : '600') + 'px'" :h="'200px'" ></vue3Signature>
                        <div v-else class="signature" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px; height: 200px;background-image : url(' + purchaseorder.Signature + ');background-size: contain;'"></div>
                        <div class="signatureX">X</div>
                        <div class="signatureLine mb-4" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px;'">Signature (Sign by using mouse or finger above)</div>
                        <div class="printName mb-4">
                            <label class="control-label">Signee Name:</label>
                            <el-input v-model="purchaseorder.Params.PrintName"  size="small" placeholder="Type Your Name" style="width: 300px;">
                            </el-input>
                        </div>
                        <div class="signatureLine mb-4" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px;'">
                            <el-button v-if="!purchaseorder.Signature || newSignature" type="success" size="small" @click="saveSignature"><i class="fa-solid fa-circle-check"></i>Accept Signature</el-button>
                            <el-button v-if="purchaseorder.Signature && !newSignature" size="small" @click="clearSignature"><i class="fa-solid fa-circle-xmark"></i> Clear</el-button>
                            <el-button v-if="!purchaseorder.Signature || newSignature" size="small" @click="undoSignature"><i class="fa-solid fa-rotate-left"></i> Undo</el-button>
                            <el-button v-if="purchaseorder.Signature && newSignature" type="info" size="small" @click="cancelSignature"><i class="fa-solid fa-circle-xmark" style="color:#ffffff;"></i> Cancel</el-button>
                            <br>
                            <br>
                            <el-button v-if="purchaseorder.Signature && !newSignature" style="font-weight: 800;" type="danger"  @click="savePurchaseOrder"><i class="fa-solid fa-circle-check"></i> Accept Purchase Order</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
            <div v-if="!loading && !purchaseorder.PurchaseOrderID" class="row">
                <el-card class="box-card mt-4" style="width: 100%;">
                    <el-alert
                        title="This Purchase Order Does not Exist"
                        type="error"
                        effect="dark"
                        :closable="false">
                    </el-alert>
                </el-card>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <el-card class="box-card mt-4 page" v-for="(text, index) in DisplayText" :key="index">
                    <div class="displaytext" v-html="text"></div>
                    <template v-if="index+1 === DisplayText.length  && contract.ContractStatus == 'A'">
                        <div style="position:relative;">
                            <div class="signature" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px; height: 200px;background-image : url(' + contract.Signature + ');background-size: contain;'"></div>
                            <div class="mt-4 mb-4 printName" >Signee Name: {{contract.Params.PrintName}}</div>
                            <div class="mt-4 mb-4">
                                <span class="signatureLine mb-4">Contract Executed on: {{contract.ContractApprovalTime}}</span><el-button style="margin-left: 40px;" type="success" size="small"  @click="showPDF"><i class="fas fa-file-download"></i>&nbsp;Download Signed Contract</el-button>
                            </div>
                        </div>
                    </template>
                    <div class="pagefooter">Page: {{ index+1 }} of {{DisplayText.length}}</div>
                </el-card>
            </div>
            <div v-if="contract.ContractStatus != 'A' && contract.ContractID" class="row">
                <el-card class="box-card mt-4" style="width: 100%;">
                    <span class="mt-4 catractsignature">
                    <el-alert
                        title="By signing below and accepting this contract it will become active."
                        type="warning"
                        effect="dark"
                        :closable="false">
                        </el-alert>
                    </span>
                    <div style="position:relative;">
                        <vue3Signature v-if="!contract.Signature || newSignature" class="signature" ref="signature" :sigOption="signatureoptions" :w="(config.isMobile ? '300' : '600') + 'px'" :h="'200px'" ></vue3Signature>
                        <div v-else class="signature" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px; height: 200px;background-image : url(' + contract.Signature + ');background-size: contain;'"></div>
                        <div class="signatureX">X</div>
                        <div class="signatureLine mb-4" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px;'">Signature (Sign by using mouse or finger above)</div>
                        <div class="printName mb-4">
                            <label class="control-label">Signee Name:</label>
                            <el-input v-model="contract.Params.PrintName"  size="small" placeholder="Type Your Name" style="width: 300px;">
                            </el-input>
                        </div>
                        <div class="signatureLine mb-4" :style="'width: ' + (config.isMobile ? '300' : '600') + 'px;'">
                            <el-button v-if="!contract.Signature || newSignature" type="success" size="small" @click="saveSignature"><i class="fa-solid fa-circle-check"></i>Accept Signature</el-button>
                            <el-button v-if="contract.Signature && !newSignature" size="small" @click="clearSignature"><i class="fa-solid fa-circle-xmark"></i> Clear</el-button>
                            <el-button v-if="!contract.Signature || newSignature" size="small" @click="undoSignature"><i class="fa-solid fa-rotate-left"></i> Undo</el-button>
                            <el-button v-if="contract.Signature && newSignature" type="info" size="small" @click="cancelSignature"><i class="fa-solid fa-circle-xmark" style="color:#ffffff;"></i> Cancel</el-button>
                            <br>
                            <br>
                            <el-button v-if="contract.Signature && !newSignature" style="font-weight: 800;" type="danger"  @click="saveContract"><i class="fa-solid fa-circle-check"></i> Accept Contract</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
            <div v-if="!loading && !contract.ContractID" class="row">
                <el-card class="box-card mt-4" style="width: 100%;">
                    <el-alert
                        title="This Contract Does not Exist"
                        type="error"
                        effect="dark"
                        :closable="false">
                    </el-alert>
                </el-card>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Modal from './global/Modal.vue';
    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    export default {
        name: 'contractapproval',
        components: {
            Modal
        },
        data() {
            return {
                loading: true,
                currentStatus : STATUS_INITIAL,
                DisplayText : '',
                ApprovalHeading : '',
                contract : {Signature : ''},
                purchaseorder : {Signature : ''},
                newSignature : false,
                signatureoptions:{
                    penColor:"rgb(0, 0, 0)",
                    backgroundColor:"rgb(255,255,255)"
                },
            }
        },
        props:["config", "parentrefs"],

        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            isPurchaseOrder() {
                return (this.$route.fullPath.indexOf('purchaseorder') !== -1 ? true : false);
            },
        },
        methods: {
            saveSignature(){
                if (this.$refs.signature.isEmpty()) {
                    this.displayPopup('Please Complete your Signature before Accepting');
                    return;
                }
                var png = this.$refs.signature.save("image/png");

                axios.post('/index/savesignature', {signature : png},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.$stopProgressBar(this.parentrefs);
                            if (this.isPurchaseOrder) {
                                this.purchaseorder.Signature = response.data.file;
                            } else {
                                this.contract.Signature = response.data.file;
                            }
                            this.newSignature = false;
                            this.$refs.signature.clear();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                });
            },
            clearSignature(){
                this.newSignature = true;
            },
            undoSignature(){
                this.$refs.signature.undo();
            },
            cancelSignature () {
                this.newSignature = false;
            },

            getContract () {
                this.$startProgressBar(this.parentrefs);
                axios.post('/organizations/getcontract', {ContractCode: this.$route.params.contractcode},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.DisplayText = (response.data.ContractText ? response.data.ContractText : []);
                            this.ApprovalHeading = response.data.ContractApprovalHeading;
                            this.contract = response.data.contract;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });

            },

            getPurchaseOrder () {
                this.$startProgressBar(this.parentrefs);
                axios.post('/finance/getpurchaseorder', {POCode: this.$route.params.pocode},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.DisplayText = (response.data.PurchaseOrderText ? response.data.PurchaseOrderText : []);
                            this.ApprovalHeading = response.data.PurchaseOrderApprovalHeading;
                            this.purchaseorder = response.data.purchaseorder;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });

            },
            showPDF () {
                if(this.isPurchaseOrder) {
                    var File = this.purchaseorder.POPDF;
                } else {
                    var File = this.contract.ContractPDF;
                }

                var link = document.createElement('a');
                link.href = File;
                link.click();
            },
            saveContract() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (!this.contract.Params.PrintName || this.contract.Params.PrintName == '') {
                    this.displayPopup('Please enter the Signee Name');
                    return true;
                }

                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                axios.post('/organizations/savecontractapproval', {contract : this.contract}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_INITIAL;
                            this.contract.ContractApprovalTime = response.data.ContractApprovalTime;
                            this.contract.ContractPDF = response.data.ContractPDF;
                            this.contract.ContractStatus = 'A';
                            this.$notify({
                                title: 'Success',
                                message: "Your Contract Signature and Acceptance have been recorded",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.displayModal (response.data.message);
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                });
            },
            savePurchaseOrder() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
                if (!this.purchaseorder.Params.PrintName || this.purchaseorder.Params.PrintName == '') {
                    this.displayPopup('Please enter the Signee Name');
                    return true;
                }

                this.currentStatus = STATUS_SAVING;
                this.$startProgressBar(this.parentrefs);
                axios.post('/finance/savepurchaseorderapproval', {purchaseorder : this.purchaseorder}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.currentStatus = STATUS_INITIAL;
                            this.purchaseorder.POApprovalTime = response.data.POApprovalTime;
                            this.purchaseorder.POPDF = response.data.POPDF;
                            this.purchaseorder.POStatus = 'A';
                            this.$notify({
                                title: 'Success',
                                message: "Your Purchase Order Signature and Acceptance have been recorded",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.currentStatus = STATUS_FAILED;
                            this.displayModal (response.data.message);
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                        this.currentStatus = STATUS_FAILED;
                        this.$failProgressBar(this.parentrefs);
                });
            },
            displayPopup (message) {
                this.$alert(message, {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
            }
        },
        mounted () {
            if (this.isPurchaseOrder) {
                this.getPurchaseOrder();
            } else {
                this.getContract();
            }
        }
    }
</script>

<style>
    #app.contractapproval, #app.contractapproval  .page-wrapper, #app.purchaseorderapproval, #app.purchaseorderapproval  .page-wrapper  {
         background-color: #f0f0f0;
     }
 
     @media (min-width: 1024px) {
        #app.contractapproval .page-wrapper, #app.purchaseorderapproval .page-wrapper {
             margin: 0;
             padding: 20px;
         }
     }
    #app.contractapproval .page-wrapper, #app.purchaseorderapproval .page-wrapper {
         margin: 0;
         padding: 0px;
     }

    #contractapproval .page .el-card__body {
        min-height: 1000px;
    }
    #contractapproval .el-card__body {
        position:relative;
    }
    #contractapproval .el-card {
        width: 100%;
    }
    #contractapproval .displaytext {
        margin-bottom: 20px;
    }
	.isMobile #contractapproval .signature .canvas {
		width: 300px;
		height: 200px;
	}
	#contractapproval .signature .canvas {
		width: 600px;
		height: 200px;
	}
    #contractapproval table.datepobox {
        border-collapse:collapse;
        border:1px solid #000;
    }

    #contractapproval table.datepobox td {
        border:1px solid #000;
        padding: 5px;
        width: 100px;
        text-align: center;
    }
    #contractapproval table.vendorbox {
        border-collapse:collapse;
        border:1px solid #000;
        width: 75%;
    }

    #contractapproval table.vendorbox td {
        border:1px solid #000;
        padding: 5px;
        width: 100%;
        text-align: left;
    }

    
</style>
<style scoped>
    div.logo {
        width: 75px;
        height: 75px;
        float: right;
    }
    box-card {
        background-color: #ffffff;
    }
    .pagefooter {
        position:absolute;
        bottom: 0;
        left: 40%;
        right: 40%;
        margin: 20px auto;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }

    .signatureLine {
        font-size: 10px;
        text-align: center;
    }
    .printName {
        font-size: 12px;
    }
    .signatureX {
        position:absolute;
        top: 168px;
        left: 4px;
        font-size: 18px;
        color: #999999;
        font-weight: 800;
    }
    .signature {
        margin-top: 20px;
        border: 1px dotted #cccccc;
        border-bottom: 2px solid #cccccc;
    }

</style>
