<template>
    <div id="teamsbillingreport" v-loading="loading">
            <div class="row">
                <div v-if="Programs.length > 1" class="col-12 fullpage">
                        <el-select v-model="ProgramID"  ref="program" :placeholder="'Select ' + config.Params.programName" class="topDropdown" @change="programChange" default-first-option filterable>
                            <el-option
                                v-for="item in Programs"
                                :label="item.ProgramName"
                                :value="item.ProgramID"
                                :key="item.ProgramID">
                            </el-option>
                        </el-select>
                </div>
            </div>
            <div class="row">
                <div v-if="ProgramID && Programs.length > 0" class="col-12 mt-2 fullpage">
                    <el-select v-if="Leagues.length > 0" v-model="LeagueID"  ref="league" placeholder="Select League" class="topDropdown" @change="leagueChange" default-first-option filterable :clearable="(Leagues.length > 1)">
                        <el-option
                            v-for="item in Leagues"
                            :label="item.LeagueName"
                            :value="item.LeagueID"
                            :key="item.LeagueID">
                        </el-option>
                    </el-select>
                    <div v-else class="program">League:  <span class="leagueName">No Leagues for this {{config.Params.programName}}</span></div>
                </div>
            </div>
            <div class="row">
	            <div v-if="Leagues.length > 0 && this.LeagueID" class="col-12 mt-2 fullpage">
                    <el-select class="topDropdown"  v-model="SeasonID"  @change="seasonChange" placeholder="Select Season" default-first-option filterable>
                        <el-option v-for="season in AuditSeasons"
                                   :value="season.SeasonID"
                                   :key="season.SeasonID"
                                   :label="season.SeasonName">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row">
                <div v-if="ProgramID && Programs.length > 0 && LeagueID" class="col-12 mt-2 fullpage">
                    <el-select class="topDropdown"  v-model="TeamID"  no-data-text="No Teams with Billings" @change="teamChange" placeholder="Select" default-first-option filterable>
                        <el-option v-for="team in Teams"
                                   :value="team.TeamID"
                                   :key="team.TeamID"
                                   :label="(team.recordstatus == 'I' ? team.TeamName + ' (Dropped) ' : team.TeamName)">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div v-if="Billings[TeamID]" class="row mt-4">
                <div class="col-12 fullpage">
                    <h5>Billed Amounts</h5>
                    <el-table
                        :data="Billings[TeamID]"
                        border
                        key="billingteams"
                        size="small"
                        show-summary
                        :summary-method="getSummaries"
                        empty-text="No Team Billings"
                        stripe
                        style="width: 100%">
                        <el-table-column
                            prop="InvoiceDate"
                            label="Date"
                            align="center"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Description"
                            label="Description"
                            align="left"
                            min-width="200"
                        >
                            <template #default="scope">
                                <span v-if="scope.row.IDParams" v-html="(scope.row.DetailType == 'AI' ? scope.row.IDParams.TeamName : scope.row.Description)"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Quantity"
                            label="Quantity"
                            align="center"
                            width="120"
                        >
                            <template #default="scope">
                                <span v-if="scope.row.IDParams" v-html="(scope.row.DetailType == 'TR' ? scope.row.IDParams.Quantity : scope.row.IDParams.ToBill)"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="MiscFee"
                            label="Misc Fees"
                            align="right"
                            min-width="100"
                        >
                            <template #default="scope">
                                <span v-html="formatPrice(scope.row.MiscFee,'')"></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="GameFee"
                            label="Game Fees"
                            align="right"
                            min-width="100"
                        >
                            <template #default="scope">
                                <span v-html="formatPrice(scope.row.GameFee,'')"></span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="nogamesbillings" v-else-if="TeamID">There are no Billings for this Team.</div>
            <div v-if="Games[TeamID]" class="row mt-4 mb-4">
                <div class="col-12 fullpage">
                    <h5>Games</h5>
                    <el-table
                        :data="Games[TeamID]"
                        border
                        key="tableGames"
                        show-summary
                        :summary-method="getGameSummaries"
                        size="small"
                        :empty-text="'No Games'"
                        :row-class-name="tableGameRowClassName"
                        stripe
                        style="width: 100%">
                        <el-table-column
                            prop="GameDate"
                            label="Date/Time"
                            width="130">
                            <template #default="scope">
                                <div v-html="scope.row.GameDateF + '<br>' + scope.row.StartTime"></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Location"
                            label="Location"
                            min-width="90">
                            <template #default="scope">
                                <div v-html="scope.row.LocationName"></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Opponent"
                            label="Opponent"
                            min-width="120">
                            <template #default="scope">
                                <div v-html="(TeamID == scope.row.HomeTeamID ? scope.row.VisitingTeamName : scope.row.HomeTeamName)"></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="Score"
                            label="Score"
                            align="center"
                            width="90">
                            <template #default="scope">
                                <div v-html="(TeamID == scope.row.HomeTeamID ? scope.row.HomeScore + ' - ' +  scope.row.VisitingScore : scope.row.VisitingScore + ' - ' +  scope.row.HomeScore)"></div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="StatusCodeName"
                            label="Status"
                            align="center"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="Fees"
                            label="Fees"
                            align="right"
                            width="90">
                            <template #default="scope">
                                <div v-html="formatPrice(scope.row.Fees, '0')"></div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="nogamesbillings mb-4" v-else-if="TeamID">There are no Games for this Team.</div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Util from '../assets/js/util.js';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
    export default {
        data () {
            return {
                loading: false,
                currentStatus : STATUS_INITIAL,
                Programs: [],
                ProgramID: '',
                Leagues: [],
                LeagueID: '',
                ActiveSeasons : [],
                SeasonID : '',
                Teams: [],
                TeamID: '',
                Billings : [],
                Games : []
            }
        },
        name: "teamsbillingreport",
        props:["config", "parentrefs"],
        computed: {

            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            TeamIDAccess () {
                return this.$TeamIDAccess();
            },
            LabelPosition () {
                return (Util.isMobile() ? 'top' : 'left');
            },
            AuditSeasons () {
                var seasons = [];
                var league = '';
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.LeagueID == this.Leagues[i].LeagueID) {
                        if (this.Leagues[i].Params.auditseasons && this.Leagues[i].Params.auditseasons.length > 0) {
                            league = this.Leagues[i];
                        } else {
                            return this.ActiveSeasons;
                        }
                        break;
                    }
                }
                if (league) {
                    for (let i = 0; i < this.ActiveSeasons.length; i++) {
                        if (league.Params.auditseasons.indexOf(this.ActiveSeasons[i].SeasonID) !== -1) {
                            seasons.push(this.ActiveSeasons[i]);
                        }
                    }
                    return seasons;
                } else {
                    return [];
                }
            }
        },
        methods: {
            programChange () {
                this.TeamID = '';
                this.getAllLeagues();
            },
            leagueChange () {
                this.TeamID = '';
                this.getTeams();
            },
            seasonChange() {
                this.TeamID = '';
                this.getTeams();
            },
            teamChange() {
            },
            formatPrice(value, zero) {
	            if (value) {
		            return Util.formatPrice(this.config, parseFloat(value), zero);
	            } else {
		            return '';
	            }
            },
            tableGameRowClassName({row, rowIndex}) {
                if (row.GameStatus == 'F') {
                    return 'forfeit';
                } else {
                    return '';
                }
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Total Billing';
                        return;
                    }
                    if (index >= 3) {
                        const values = data.map(item => Number(item[column.property]));
                        if (!values.every(value => isNaN(value))) {
                            sums[index] = this.formatPrice(values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0),'0');
                        }
                    } else {
                        sums[index] = '';
                    }
                });
                return sums;
            },
            getGameSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Game Totals';
                        return;
                    } else {
                        const values = data.map(item => Number(item[column.property]));
                        if (!values.every(value => isNaN(value))) {
                            if (index === 5) {
                                sums[index] = this.formatPrice(values.reduce((prev, curr) => {
                                    const value = Number(curr);
                                    if (!isNaN(value)) {
                                        return prev + curr;
                                    } else {
                                        return prev;
                                    }
                                }, 0), '0')
                                sums[1] = values.length;
                            } else {
                                sums[index] = '';
                            }
                        }
                    }
                });
                return sums;
            },
            getInitialData () {
                var tables = ['ActiveSeasons'];
                axios.post('/index/tableData', {'tables': tables },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                            this.getPrograms();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getPrograms () {
                axios.post('/analyzer/programs/getallregistration', {SeasonID : this.SeasonID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Programs = response.data.Programs;
                            this.user = { ...this.currentUser };
                            if (!this.ProgramID) {
                                if (this.ProgramIDAccess) {
                                    this.ProgramID = this.user.ProgramID;
                                } else {
                                    this.ProgramID = this.Programs[0]['ProgramID'];
                                }
                            }
                            this.getAllLeagues();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            },
            getAllLeagues () {
                this.Leagues = [];
                this.Teams = [];
                let self = this;
                axios.post('/analyzer/leagues/getall', {ProgramID: this.ProgramID, Finance : 'y'},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let i = 0; i < response.data.Leagues.length; i++) {
                                if (response.data.Leagues[i].Params.LeagueBilling == 'p') {
                                    this.Leagues.push(response.data.Leagues[i]);
                                }
                            }
                            if (this.Leagues.length > 0) {
                                var leaguefound = false;
                                for (let i = 0; i < this.Leagues.length; i++) {
                                    if (this.LeagueID == this.Leagues[i]['LeagueID']) {
                                        leaguefound = true;
                                        break;
                                    }
                                }
                                if (!this.LeagueID || !leaguefound) {
                                    this.LeagueID = this.Leagues[0]['LeagueID'];
                                }
                                this.getTeams();
                            }

                            this.$stopProgressBar(this.parentrefs);
                            this.loading = false;
                            this.$emit('updateHelpID', this.LeagueID);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                            this.loading = false;
                        }
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                });
            },

            getTeams() {
                let self = this;
                axios.post('/analyzer/teams/getbillings', {ProgramID: this.ProgramID, LeagueID: this.LeagueID, SeasonID : this.SeasonID},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            self.$store.dispatch('setID', {'teamsbillingreportProgramID': self.ProgramID});
                            self.$store.dispatch('setID', {'teamsbillingreportLeagueID': self.LeagueID});
                            self.$store.dispatch('setID', {'teamsbillingreportSeasonID': self.SeasonID});
                            this.Teams = response.data.Teams;
                            this.Billings = response.data.Billings;
                            this.Games = response.data.Games;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                });
            },

            displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
                });
            }
        },
        mounted() {
            if (this.componentIDs.teamsbillingreportProgramID) {
                this.ProgramID = this.componentIDs.teamsbillingreportProgramID;
            }
            if (this.componentIDs.teamsbillingreportLeagueID) {
                this.LeagueID = this.componentIDs.teamsbillingreportLeagueID;
            }
            if (this.componentIDs.teamsbillingreportSeasonID) {
                this.SeasonID = this.componentIDs.teamsbillingreportSeasonID;
            }
            this.getInitialData();
        },
    }
</script>

<style>
    #teamsbillingreport .team-row, #teamsbillingreport th div.cell {
        font-weight: 800;
        font-size: 14px;
    }
    #teamsbillingreport .el-table__footer div.cell {
        font-weight: 800;
    }
</style>
<style scoped>

    .programteamregistration .form-horizontal {
        width: 100%;
    }

    .el-select {
        width: 100%;
        max-width: 300px;
    }

    .el-select.level {
        width: 100%;
    }

    .el-select.games {
        width: 70px;
        text-align:center;
    }

    table thead tr th:last-child {
        text-align: center;
    }

    .program {
        font-size: 14px;
    }
	.programName {
		font-weight: 600;
		margin-left: 25px;
	}
	.leagueName {
		font-weight: 600;
		margin-left: 32px;
	}
    .teamDelete {
        padding: 5px 0px;
        text-align: center;
    }
    .divisionHeader {
        margin-top: 10px;
        height: 40px;
        line-height: 40px;
        background-color: #f0f0f0;
        width: 100%;
        border: 1px solid #cccccc;
    }
    .teamRow {
        border-bottom: 1px solid #F0f0f0;
        line-height: 26px;
        padding-top: 6px;
        vertical-align: middle;
        font-size: 14px;
    }
    .el-textarea {
        margin-bottom: 6px;
    }
    .addTeam {
        float:right;
        margin: 6px;
    }
    .row.col-12 div {
        padding-left: 5px;
        padding-right: 0px;
    }
    .danger {
        color: #dc3545;
    }
    .col-10 {
        margin-top: 7px;
    }
    .col-2 {
        margin-top: 7px;
    }
    @media (min-width: 768px) {
        .col-md-3 {
            margin-top: 0px;
        }
        .col-md-2 {
            margin-top: 0px;
        }
    }
    .deleted {
        background-color: rgba(220, 53, 69, 0.1);
    }
    .nogamesbillings {
        margin: 20px;
        border: 1px solid #cccccc;
        background: #f9f9f9;
        padding: 20px;
        color: #dc3545;
        font-weight: 800;
    }
</style>

