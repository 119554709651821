<template>
    <transition name="fade">
        <!-- Start Page Content -->
        <div id="dashboard" class="row">
            <div class="col-12 fullpage">
                <div v-if="ActivePrograms.length >1" style="height: 35px;">
                    <label class="mb-1 control-label">{{config.Params.programName}}:</label>
                    <el-select class="topDropdown dropdowns" v-model="ProgramID" @change="changeProgram" :placeholder="'Select ' + config.Params.programName" default-first-option filterable>
                        <el-option v-for="type in ActivePrograms"
                                   :value="type.ProgramID"
                                   :key="type.ProgramID"
                                   :label="type.ProgramName">
                        </el-option>
                    </el-select>
                </div>
                <div v-else-if="ActivePrograms.length == 1" style="height: 35px;">
                    <label class="mb-1 control-label">{{config.Params.programName}}:</label>
                    <span class="programName dropdowns">{{ActivePrograms[0].ProgramName}}</span>
                </div>
                <div v-else-if="SiteLocations.length >= 1" style="height: 35px;">
                    <el-select class="topDropdown dropdowns" v-model="MainLocationID" @change="getDashboardData" placeholder="Select Site" default-first-option filterable>
                        <el-option v-for="type in SiteLocations"
                                   :value="type.MainLocationID"
                                   :key="type.MainLocationID"
                                   :label="type.MainLocationName">
                        </el-option>
                    </el-select>
                </div>
                <div v-if="Seasons.length >= 1" class="mt-2" style="height: 35px;">
                    <label class="mb-1 control-label">Season:</label>
                    <el-select class="topDropdown dropdowns" v-model="SeasonID"  @change="changeSeason" style="width: 100px;" placeholder="Select Season" default-first-option filterable>
                        <el-option v-for="season in Seasons"
                                :value="season.SeasonID"
                                :key="season.SeasonID"
                                :label="season.SeasonName">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="DashboardText" class="card mt-4 fullpage">
                <div class="card-header bg-primary details"><h5>Message from Valley Associates</h5></div>
                <div class="card-body">
                    <div v-html="DashboardText"></div>
                </div>
            </div>
            <div class="row mt-2" >
                <!-- column -->
                <div class="col-12">
                    <div class="card fullpage">
                        <div class="card-header bg-primary details"><h5>Season Schedule</h5></div>
                        <div v-if="$propertyAccess('Exports')" class="card-body-no mt-4">
                            <h4 class="card-title">{{config.Params.game}} Exports</h4>
                            <el-table
                                :data="programGames"
                                border
                                key="gamesExport"
                                size="small"
                                max-height="400"
                                :empty-text="'No ' + config.Params.game + ' Schedules to Export'"
                                stripe
                                style="width: 100%">
                                <el-table-column
                                    prop="ScheduleName"
                                    label="Export Name"
                                    width="250"
                                >
                                    <template #default="scope">
                                        <div v-html="scope.row.ScheduleName"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="Description"
                                    label="Description">
                                </el-table-column>
                                <el-table-column
                                    prop="button"
                                    label="Download"
                                    width="200"
                                    align="center"
                                >
                                    <template #default="scope">
                                        <el-button v-if="scope.row.Games" type="info" class="btn-secondary exportButton"  @click.prevent="exportCSV(scope.$index)">
                                            <i class="fas fa-file-export"></i>  <span>Export {{config.Params.games}}</span>
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div v-if="$propertyAccess('Schedule')" class="card-body-no mt-2">
                            <h4 class="card-title" style="display:inline-block;" v-html="'Upcoming ' + (SiteLocations.length >= 1  ? 'Events' : config.Params.games + (Practices.length > 0 ? '&nbsp;<span>/Practices</span>' : ''))"></h4>
                            <el-select v-if="ProgramTeams.length > 1" v-model="ScheduleTeamID" class="oneHundred" @change="getDashboardSchedule" placeholder="Select Team" default-first-option filterable clearable>
                                <el-option v-for="type in ProgramTeams"
                                            :value="type.TeamID"
                                            :key="type.TeamID"
                                            :label="type.TeamName">
                                </el-option>
                            </el-select>
                            <div v-if="SiteLocations.length == 0" style="width: 100%">
                                <el-radio-group v-if="Practices.length > 0 && Games.length > 0" size="small" style="margin: 5px 0px 15px 15px;" v-model="gamepracticebtn">
                                    <el-radio-button class="gamepracticeradio" label="gp">Show All</el-radio-button>
                                    <el-radio-button class="gamepracticeradio" label="g">{{config.Params.games}}</el-radio-button>
                                    <el-radio-button class="gamepracticeradio" label="p">Practices</el-radio-button>
                                </el-radio-group>
                            </div>

                            <el-table
                                :data="(gamepracticebtn == 'g' ? Games : gamepracticebtn == 'p' ? Practices : GamesPractices)"
                                v-loading="gamesLoading"
                                border
                                key="tableGames"
                                size="small"
                                max-height="400"
                                :empty-text="(SiteLocations.length >= 1 ? 'No Events' : 'No Games' + (Practices.length > 0 ? '/Practices' : ''))"
                                :row-class-name="tableGameRowClassName"
                                stripe
                                style="width: 100%">
                                <el-table-column
                                    prop="GameDate"
                                    label="Date/Time"
                                    sortable
                                    :sort-method="sortGameTime"
                                    width="130">
                                    <template #default="scope">
                                        <template v-if="scope.row.GameID || scope.row.TournamentGameID">
                                            <div v-html="scope.row.GameDateF + '<br>' + scope.row.StartTime"></div>
                                        </template>
                                        <template v-else-if="scope.row.CalendarSlotID">
                                            <div v-html="scope.row.GameDate + '<br>' + scope.row.StartTime"></div>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="LocationName"
                                    label="Location"
                                    sortable
                                    min-width="90">
                                </el-table-column>
                                <el-table-column
                                    prop=""
                                    label="Type"
                                    min-width="70">
                                    <template #default="scope">
                                        <template v-if="scope.row.ScheduleUsage && scope.row.ScheduleUsage == 'T'">
                                            <div>{{scope.row.ScheduleName}}</div>
                                        </template>
                                        <template v-else-if="scope.row.GameID">
                                            <div>{{scope.row.LeagueName}} {{config.Params.game}}</div>
                                        </template>
                                        <template v-else-if="scope.row.SlotType == 'p'">
                                            <div>Practice</div>
                                        </template>
                                        <template v-else-if="scope.row.SlotType == 'e'">
                                            <div>3rd Party Event</div>
                                        </template>
                                        <template v-else-if="scope.row.SlotType == 'o'">
                                            <div>Open</div>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop=""
                                    label="Info"
                                    min-width="200">
                                    <template #default="scope">
                                        <template v-if="scope.row.GameID || scope.row.TournamentGameID">
                                            <template v-if="ScheduleTeamID">
                                                <div :class="{'teamhighlight' : scope.row.HomeTeamID == ScheduleTeamID }" v-html="scope.row.HomeTeamName + ' vs'"></div>
                                                <div :class="{'teamhighlight' : scope.row.VisitingTeamID == ScheduleTeamID }" v-html="scope.row.VisitingTeamName"></div>
                                            </template>
                                            <template v-else>
                                                <div :class="{'teamhighlight' : ProgramID > 0 && scope.row.HomeProgramID == ProgramID }" v-html="scope.row.HomeTeamName + ' vs'"></div>
                                                <div :class="{'teamhighlight' : ProgramID > 0 && scope.row.VisitingProgramID == ProgramID }" v-html="scope.row.VisitingTeamName"></div>
                                            </template>
                                        </template>
                                        <template v-else-if="scope.row.CalendarSlotID">
                                            <div v-html=" '<b>' + (scope.row.EventUser ? scope.row.EventUser : '') + (scope.row.Title ? (scope.row.EventUser ? '<br>' : '') + scope.row.Title : '')+ '</b>' + (scope.row.TeamNames ? '<br>' + scope.row.TeamNames : '')"></div>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="StatusCodeName"
                                    label="Status"
                                    min-width="90">
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$propertyAccess('DivContacts')" class="row mt-2">
                <!-- column -->
                <div class="col-12">
                    <div class="card fullpage">
                        <div class="card-body-no" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
                             unselectable="on"
                             onselectstart="return false;"
                             onmousedown="return false;">
                            <h4 class="card-title">Division/{{config.Params.game}} Contacts</h4>
                            <el-table
                                :data="divisionContacts[SeasonID]"
                                border
                                empty-text="No Division Contacts"
                                size="small"
                                max-height="500"
                                :span-method="arraySpanMethodDivisionContacts"
                                :row-class-name="tableRowClassNameContacts"
                                style="width: 100%">
                                <el-table-column
                                    prop="TeamName"
                                    label="Team"
                                    min-width="200">
                                </el-table-column>
                                <el-table-column
                                    prop="Name"
                                    label="Name"
                                    min-width="150">
                                </el-table-column>
                                <el-table-column
                                    prop="ContactTypeName"
                                    label="Role"
                                    min-width="100">
                                </el-table-column>
                                <el-table-column
                                    prop="PhoneNumbers"
                                    label="Phone Numbers"
                                    min-width="140">
                                    <template #default="scope">
                                        <div v-html="scope.row.PhoneNumbers"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="EMails"
                                    label="Emails"
                                    min-width="200">
                                    <template #default="scope">
                                        <div v-html="scope.row.EMails"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Util from '../assets/js/util.js';

    export default {
        name: 'Dashboard',
        data () {
            return {
                activeTab : 'playerregistration',
                gamesLoading: false,
                Seasons : [],
                SeasonID : '',
                Games: [],
                Practices: [],
                GamesPractices: [],
                Teams : [],
                loading: true,
                RegSeason: '',
                ActiveSeason: '',
                host: '',
                ProgramCode: '',
                ActiveProgramCode: '',
                search: '',
                programGames : [],
                ActivePrograms : [],
                ProgramID : '',
                MainLocationID : '',
                Users : [],
                SiteLocations : [],
                divisionContacts : [],
                ProgramTeams : [],
                ScheduleTeamID: '',
                DashboardText : '',
                Leagues: [],
                playerregistrationinstructions : '',
                teamcontactregistrationinstructions : '',
                noteamPlayers : [],
                teamPlayers : [],
                adminContacts : [],
                teamContacts : [],
                gamepracticebtn: 'gp',

            }
        },
        props:["config", "parentrefs"],
        computed: {
            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            createAccess () {
                return this.$createAccess();
            },
            modifyAccess () {
                return this.$modifyAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            TeamIDAccess () {
                return this.$TeamIDAccess();
            },
            MainLocationIDAccess () {
                return this.$MainLocationIDAccess();
            },
            TeamRegistrationAccess() {
                return this.$readAccess('programteamregistration');
            },
        },
        methods: {
            levelName(row) {
                var level = '';
                if (row.TParams.RegisteredDivisionLevel) {
                    level = row.TParams.RegisteredDivisionLevel;
                    var parts = level.split('-');
                    if (parts.length == 1) {
                        level = row.DivisionLevelName;
                    } else {
                        level = parts[parts.length - 1];
                    }
                } else {
                    level = row.DivisionLevelName;
                }
                return level;
            },
            sortGameTime (a,b) {
				if (a.GameDate && b.GameDate) {
                    var ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
					if (ret == 0) {
						ret = parseInt(a.StartTimeSort.replace(/:/g, '')) - parseInt(b.StartTimeSort.replace(/:/g, ''));
					}
					return ret;
				} else {
					return 0;
				}
			},
            arraySpanMethodContacts({ row, column, rowIndex, columnIndex }) {
                if (row.ContactID == 0) {
                    if (columnIndex == 0) {
                        let maxCol = 8;
                        if (this.ProgramID) {
                            maxCol--;
                        }
                        if (!this.modifyAccess) {
                            maxCol--;
                        }
                        return [1, maxCol]
                    } else {
                        return [0,0];
                    }
                } else {
                    return [1, 1];
                }
            },
            arraySpanMethodPlayers({ row, column, rowIndex, columnIndex }) {
                if (row.PlayerID == 0) {
                    if (columnIndex == 0) {
                        return [1,4]
                    } else {
                        return [0,0];
                    }
                } else {
                    return [1, 1];
                }
            },
            tableRowClassNameContacts({row, rowIndex}) {
                if (row.ContactID == 0) {
                    return 'team-row';
                } else {
                    return '';
                }
            },
            tableRowClassNamePlayers({row, rowIndex}) {
                if (row.PlayerID == 0) {
                    return 'team-row';
                } else {
                    return '';
                }
            },
            tableRowClassName({row, rowIndex}) {
                if (row.recordstatus == 'I') {
                    return 'teamdropped';
                }
                return '';
            },
            tableGameRowClassName({row, rowIndex}) {
                if (row.GameStatus != 'P' && row.GameStatus != 'C' && row.GameID > 0) {
                    return 'notUpcoming';
                } else {
                    return '';
                }
            },
            arraySpanMethodDivisionContacts({ row, column, rowIndex, columnIndex }) {
                if (row.ContactID == 0) {
                    if (columnIndex == 0) {
                        return [1, 5]
                    } else {
                        return [0,0];
                    }
                } else {
                    return [1, 1];
                }
            },
            formatPrice(value) {
                return Util.formatPrice(this.config, value , '');
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totals';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        if (0 == 1) {
                            sums[index] = this.formatPrice(values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0));
                        } else {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);
                        }
                    } else {
                        sums[index] = '';
                    }
                });
                if (sums.length >= 7) {
                    sums[7] = ''
                }
                return sums;
            },
            getInitialData () {
                this.$startProgressBar(this.parentrefs);
                if (this.MainLocationIDAccess) {
                    var tables = ['SiteLocations'];
                } else {
                    var tables = ['ActivePrograms'];
                }
                axios.post('/index/tableData', {'tables': tables },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                            this.user = { ...this.currentUser };
                            if (this.ProgramIDAccess) {
                                this.ProgramID = this.user.ProgramID;
                            } else if (this.MainLocationIDAccess) {
                                this.MainLocationID = this.SiteLocations[0].MainLocationID;
                            }
                            if (!this.ProgramID && !this.MainLocationIDAccess) {
                                if (this.componentIDs.DashboardProgramID) {
                                    this.ProgramID = this.componentIDs.DashboardProgramID;
                                } else {
                                    this.ProgramID = this.ActivePrograms[0].ProgramID;
                                }
                            }
                            if (this.componentIDs.DashboardSeasonID) {
                                this.SeasonID = this.componentIDs.DashboardSeasonID;
                            }
                            if (this.ProgramID || this.MainLocationID) {
                                this.getDashboardData();
                            } else {
                                this.$stopProgressBar(this.parentrefs);
                                this.loading = false;
                            }
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            changeProgram(){
                this.ScheduleTeamID = '';
                this.gamepracticebtn = 'gp';
                this.getDashboardData();
            },
            changeSeason(){
                this.ScheduleTeamID = '';
                this.gamepracticebtn = 'gp';
                this.getDashboardSchedule();
            },
            getDashboardData () {
                let self = this;
                this.$startProgressBar(this.parentrefs);
                axios.post('/index/dashboard', {ProgramID : this.ProgramID, MainLocationID : this.MainLocationID, TeamIDs : this.TeamIDAccess}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            self.$store.dispatch('setID', {'DashboardProgramID': self.ProgramID});
                            self.$store.dispatch('setID', {'DashboardMainLocationID': self.MainLocationID});
                            this.Games = response.data.Games;
                            this.Users = (response.data.Users ? response.data.Users  : []);
                            this.divisionContacts = (response.data.divisionContacts ? response.data.divisionContacts : []);
                            this.programGames = response.data.programGames;
                            this.Seasons = (response.data.Seasons ? response.data.Seasons : []);
                            this.Teams = (response.data.Teams ? response.data.Teams : []);
                            this.host = response.data.host;
                            this.ProgramCode = response.data.ProgramCode;
                            this.ActiveProgramCode = response.data.ActiveProgramCode;
                            if (this.currentUser.Roles[0].LeagueID && response.data.SystemText.PageText[this.currentUser.Roles[0].LeagueID]) {
	                            this.DashboardText = response.data.SystemText.PageText[this.currentUser.Roles[0].LeagueID];
                            } else {
	                            this.DashboardText = (response.data.SystemText ? response.data.SystemText.SystemText : '');
                            }
                            this.Leagues = (response.data.Leagues ? response.data.Leagues : []);
                            this.playerregistrationinstructions = (response.data.playerregistrationinstructions ? response.data.playerregistrationinstructions : '');
                            this.teamcontactregistrationinstructions = (response.data.teamcontactregistrationinstructions ? response.data.teamcontactregistrationinstructions : '');

                            if (!this.SeasonID && this.Seasons.length > 0) {
                                this.SeasonID = this.Seasons[0]['SeasonID'];
                            }
                            for (let  i = 0; i < this.Seasons.length; i++) {
                                if (this.Seasons[i]['Params']['DefaultSeason'] == 'y') {
                                    this.SeasonID = this.Seasons[i]['SeasonID'];
                                    break;
                                }
                            }
                            if (this.ProgramID) {
                                //this.getNoTeamPlayers();
                                //this.getTeamPlayers();
                                //this.getAdminContacts();
                                //this.getTeamContacts();
                                this.getDashboardSchedule();
                            } else if (this.MainLocationID) {
                                this.getDashboardSchedule();
                            } else {
                                this.$stopProgressBar(this.parentrefs);
                            }
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.loading = false;
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getNoTeamPlayers() {
                axios.post('/programplayers/getAll', { ProgramID : this.ProgramID, TeamPlayers : 'n',  TeamIDs : this.TeamIDAccess},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.noteamPlayers = response.data.players;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.noteamPlayers = [];
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getTeamPlayers() {
                axios.post('/programplayers/getAll', { ProgramID : this.ProgramID, TeamPlayers : 'y',  TeamIDs : this.TeamIDAccess},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.teamPlayers = response.data.players;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.teamPlayers = [];
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getAdminContacts() {
                this.adminContacts = [];
                axios.post('/programcontacts/getAll', { ProgramID : this.ProgramID, AdminContacts : 'y', ContactTypes : "Admin" },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.adminContacts = response.data.contacts;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.adminContacts = [];
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getTeamContacts() {
                this.teamContacts = [];
                axios.post('/programcontacts/getAll', { ProgramID : this.ProgramID, AdminContacts : 'n', ContactTypes : "Team" },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.teamContacts = response.data.contacts;
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.teamContacts = [];
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
            getDashboardSchedule () {
                this.$startProgressBar(this.parentrefs);
                this.gamesLoading = true;
                this.$store.dispatch('setID', {'DashboardSeasonID': this.SeasonID});
                axios.post('/index/dashboardgames', {ProgramID : this.ProgramID, MainLocationID : this.MainLocationID, TeamIDs : this.TeamIDAccess, ScheduleTeamID : this.ScheduleTeamID, SeasonID : this.SeasonID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Games = response.data.Games;
                            this.Practices = response.data.Practices;
                            this.GamesPractices = response.data.GamesPractices;
                            this.programGames = response.data.programGames;
                            this.ProgramTeams = (response.data.ProgramTeams ? response.data.ProgramTeams : []);;
                        }
                        this.gamesLoading = false;
                        this.$stopProgressBar(this.parentrefs);
                    }).catch(response => {
                    this.gamesLoading = false;
                    this.$failProgressBar(this.parentrefs);
                });
            },
            exportCSV (index) {

                let list = this.programGames[index].Games;
                let columns = ['GameNo','GameDate','StartTime','Duration','Location','HomeTeamName','HomeTeamShortName','VisitingTeamName','VisitingTeamShortName',];

                var csv = Papa.unparse(list,{columns : columns});
                var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                var csvURL =  null;
                if (navigator.msSaveBlob)
                {
                    csvURL = navigator.msSaveBlob(csvData, 'games.csv');
                }
                else
                {
                    csvURL = window.URL.createObjectURL(csvData);
                }

                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', 'games.csv');
                tempLink.click();
            },

        },
        mounted() {
            this.getInitialData();
        },
    }
</script>

<style>
    .dashboard div.el-table th {
        font-weight: 800;
    }
    .dashboard div.el-table .cell {
        word-break: normal;
    }
    #app.dashboard div.el-collapse-item__header {
        font-size: 15px;
    }
    #app.dashboard .el-collapse-item__content {
        padding-bottom: 0;
    }
    #app.dashboard .el-table .cell {
        line-height: 17px;
    }
    #app.dashboard .el-table__fixed-footer-wrapper tbody td div.cell {
        font-size: 16px;
        font-weight: 800;
        color: #000;
    }
    #app.dashboard .teamdropped td {
        font-weight: 800;
    }
    #app.dashboard .el-tabs__header.is-top {
        margin: 0px;
    }
    #app.dashboard  .notUpcoming td div.cell {
        color: #dc3545;
        font-weight: 600;
    }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        color: #42b983;
    }
    .card {
        padding: 20px;
    }
    .card-body {
        padding-top: 0;
    }
    table {
        width: 100%;
    }
    .leagueHeader {
        height: 40px;
        vertical-align: middle;
        background-color: #D0D0D0;
        border-bottom: 1px solid #D0D0D0;
        padding: 10px;
    }
    .notes {
        padding-left: 15px;
        font-size: 12px;
    }
    .teams {
        padding-left: 15px;
        font-size: 14px;
    }
    .notesHeader, .statusHeader, .teamsHeader {
        padding-left: 15px;
    }
    .statusHeader {
        width: 60px;
    }
    .LeagueTitle {
        font-weight: 800;
        font-size: 16px;
        padding-top: 10px;
        height: 44px;
        vertical-align: bottom;
    }
    #app h5 a {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: underline;
    }
    #app h4 a {
        font-size: 14px;
        text-decoration: underline;
    }
    .topTeam {
        border-top: 1px solid #D0D0D0;
    }
    .topTeamEven {
        background-color: #f0f0f0;
    }
    .regLink {
        width: 100%;
        margin-bottom: 5px;
        -webkit-user-select: all;
        -moz-user-select: all;
        -ms-user-select: all;
        user-select: all;
    }
    .search {
        width: 50%;
        float: right;
        margin-bottom: 10px;
        clear:both;
    }
    .exportButton {
        height: 22px;
        padding: 2px 4px;
        font-size: 12px;
        float:right;
        width: 150px;
    }
    .exportCard {
        width: 250px;
        float:right;
    }
    .exportCard .name {
        width: 140px;
        float:left;
        text-align: right;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 5px;
    }
    .col-12 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .el-select {
        width: 100%;
        max-width: 300px;
    }
    .success {
        font-size: 16px;
        color: #28a745;
    }
    .danger {
        font-size: 16px;
        color: #dc3545;
    }
    .programName {
        display: inline-block;
        font-weight: 800;
    }
    .gamepracticeradio {
        margin: 0px 0px 0px 0px;
    }
    .teamhighlight {
        font-weight: 600;
    }
    .programName {
        display: inline-block;
        font-weight: 800;
        margin-top: 5px;
    }
    .dropdowns {
        margin-left: 60px;
        position: absolute;
        width: 100%;
    }
    .program-select {
        font-weight: 800;
        height: 35px;
    }
    label {
        position: absolute;
        margin-top: 5px
    }
</style>
