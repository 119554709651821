<template>
	<div id="unassignedgames" v-loading="loading">
		<div style="width: 100%;">
            <div class="program-select" >
				<el-select v-if="Programs.length > 1" class="topDropdown" v-model="ProgramID" :placeholder="'Select ' + config.Params.programName" @change="getProgramLocations()" default-first-option filterable>
					<el-option
						v-for="item in Programs"
						:key="item.ProgramID"
						:label="item.ProgramName"
						:value="item.ProgramID">
					</el-option>
				</el-select>
				<div class="program" v-else-if="Programs.length == 1"><strong>{{config.Params.programName}}:</strong>  <span class="programName">{{Programs[0].ProgramName}}</span></div>
			</div>
        </div>
        <h5>Home {{config.Params.games}}</h5>
		<el-table
				  stripe
				  :data="Games.Home"
				  :empty-text="'No Home ' + config.Params.games + ' needing to be Assigned'"
				  style="width: 100%"
				  size="small"
				  max-height="600">
			<el-table-column
					label="Div"
					prop="DivisionShortName"
					align="center"
					width="60">
			</el-table-column>
			<el-table-column
					label="Teams"
					align="left">
				<template #default="scope">
					<div v-html="scope.row.VisitingTeamName + '<br>&nbsp;&nbsp;at<br>' + scope.row.HomeTeamName"></div>
				</template>
			</el-table-column>
			<el-table-column
					:label="config.Params.locationName"
					width="250"
					align="center">
				<template #default="scope">
					<el-select v-model="scope.row.LocationID" placeholder="Select" class="oneHundred" default-first-option filterable>
						<el-option
								v-for="locs in ProgramLocations"
								:key="locs.LocationID"
								:label="locs.LocationName"
								:value="locs.LocationID">
						</el-option>
					</el-select>
				</template>
			</el-table-column>
			<el-table-column
					label="Date"
					width="150"
					align="center">
				<template #default="scope">
					<el-date-picker v-model="scope.row.GameDate" default-first-option filterable
									type="date"
					                style="width: 140px"
									format="MMM D, YYYY"
									value-format="YYYY-MM-DD"
									placeholder="Game Date">
					</el-date-picker>
				</template>
			</el-table-column>
			<el-table-column
					label="Time"
					width="130"
					align="center">
				<template #default="scope">
					<el-select v-model="scope.row.StartTime" placeholder="Start" default-first-option filterable>
						<el-option
								v-for="time in times"
								:key="time"
								:label="time"
								:value="time">
						</el-option>
					</el-select>
				</template>
			</el-table-column>
		</el-table>

        <div v-if="Games.Home.length > 0 && modifyAccess" class="row buttons mt-4">
            <div class="col-12 text-center">
                <button class="btn btn-success btn-icon" type="button" @click="save">
                    <i class="fas fa-check"></i> <span> Save {{config.Params.game}} Assignments</span>
                </button>
                <button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)">
                    <i class="fas fa-times"></i> <span>Cancel</span>
                </button>
            </div>
        </div>

        <h5 class="mt-4">Away {{config.Params.games}}</h5>
        <el-table
            stripe
            :data="Games.Visiting"
            :empty-text="'No Away ' + config.Params.games + ' to Display'"
            style="width: 100%"
            size="small"
            max-height="600">
            <el-table-column
                label="Div"
                prop="DivisionShortName"
                align="center"
                width="60">
            </el-table-column>
            <el-table-column
                label="Teams"
                align="left">
                <template #default="scope">
                    <div v-html="scope.row.VisitingTeamName + '&nbsp;&nbsp;at&nbsp;&nbsp;' + scope.row.HomeTeamName"></div>
                </template>
            </el-table-column>
        </el-table>

	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'AssignedGames',
	    data() {
	        return {
	            loading : true,
                currentStatus : STATUS_INITIAL,
                ProgramLocations : [],
                Programs : [],
                ProgramID : '',
                Games : { Home : [], Visiting : []},
                times : [],
                currentPage: 1,
                count: 0,
                sizePerPage: 10,
	        }
	    },
		props:["config", "parentrefs"],
	    computed: {
		    ...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
	        ProgramIDAccess () {
                return this.$ProgramIDAccess();
            }
		},
		methods: {
			reset () {
				this.getGames();
			},
			getInitialData () {
				var tables = ['Programs'];
				axios.post('/index/tableData', {'tables': tables },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let value of tables) {
								this[value] = response.data[value];
							}
							if (!this.ProgramIDAccess) {
								if (this.componentIDs.AssignProgramID) {
									this.ProgramID = this.componentIDs.AssignProgramID;
								} else {
									this.ProgramID = this.Programs[0].ProgramID;
								}
							} else{
								this.ProgramID = this.Programs[0].ProgramID;
							}
							this.getProgramLocations();
						} else {
							this.$failProgressBar(this.parentrefs);
						}
						this.loading = false;
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
					this.loading = false;
				});
			},
			getProgramLocations () {
				let self = this;
				var tables = ['ProgramLocations'];
				axios.post('/index/tableData', {'tables': tables, ProgramID : this.ProgramID },
				)
					.then(response => {
						if (response.data.result == 'success') {
							for (let value of tables) {
								this[value] = response.data[value];
							}
							self.$store.dispatch('setID', {'AssignProgramID': self.ProgramID});
							this.getGames();
						} else {
							this.$failProgressBar(this.parentrefs);
						}
						this.loading = false;
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
					this.loading = false;
				});
			},
			getGames() {
				this.loading = true;
				this.Games = { Home : [], Visiting : []};
				axios.post('/assigngames/get', { ProgramID : this.ProgramID }
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        if (response.data.Games) {
                            this.Games = response.data.Games;
                        }
                        this.$stopProgressBar(this.parentrefs);
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.loading = false;
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
			},
			save() {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/assigngames/save', {Games : this.Games.Home}
				)
				.then(response => {
					if (response.data.result == 'success') {
						this.currentStatus = STATUS_INITIAL;
						this.$confirm((response.data.message ? response.data.message :"'These ' + config.Params.games + ' have been assigned.  Contacts for both teams will receive email confirmations.'"), 'Success', {
							confirmButtonText: 'OK',
							type: 'success',
							dangerouslyUseHTMLString: true,
							showClose: false,
							showCancelButton: false
						});
						this.$stopProgressBar(this.parentrefs);
						this.getGames();
					} else {
						this.currentStatus = STATUS_FAILED;
						this.displayPopup (response.data.message);
						this.$failProgressBar(this.parentrefs);
					}
				}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
	        displayPopup (message) {
                this.$alert(message, 'Assignment Error', {
                    confirmButtonText: 'OK',
                });
            }
		},
		mounted () {
			var x = 5; //minutes interval
			var tt = parseInt((this.config.Params.calendarStartHour ? this.config.Params.calendarStartHour*60 : 0)); // start time
			var ap = ['AM', 'PM']; // AM-PM

			//loop to increment the time and push results in array
			for (var i=0;tt<24*60; i++) {
				var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
				var mm = (tt%60); // getting minutes of the hour in 0-55 format
				this.times[i] = ("" + (hh == 12 ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
				tt = tt + x;
			}
			this.getInitialData();
		}
	}
</script>

<style>
.mini-sidebar .left-sidebar .tableheader {
	left: 75px;
}
.left-sidebar .tableheader {
	left: 255px;
}
</style>
<style scoped>
#unassignedgames {
	max-width: 920px;
}
.tableheader {
	font-weight: 800;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	height: 50px;
	vertical-align: middle;
	line-height: 50px;
}

.games {
	padding: 10px 0px;
	font-size: 12px;
	vertical-align: middle;
	line-height: 14px;
}
.games:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}
.select {
	margin-top: 7px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
	width: auto;
}
.programName {
	font-weight: 600;
}
.program-select {
	margin-bottom: 15px;
}
.el-select {
	width: 100%;
	max-width: 300px;
}
</style>
