
const LOCALE_ENTRY = 0;

export default [
  { text: LOCALE_ENTRY, value: '#F44336' },
  { text: LOCALE_ENTRY, value: '#E91E63' },
  { text: LOCALE_ENTRY, value: '#9C27B0' },
  { text: LOCALE_ENTRY, value: '#673AB7' },
  { text: LOCALE_ENTRY, value: '#3F51B5' },
  { text: LOCALE_ENTRY, value: '#2196F3' },
  { text: LOCALE_ENTRY, value: '#1976d2' },
  { text: LOCALE_ENTRY, value: '#03A9F4' },
  { text: LOCALE_ENTRY, value: '#00BCD4' },
  { text: LOCALE_ENTRY, value: '#009688' },
  { text: LOCALE_ENTRY, value: '#4CAF50' },
  { text: LOCALE_ENTRY, value: '#8BC34A' },
  { text: LOCALE_ENTRY, value: '#CDDC39' },
  { text: LOCALE_ENTRY, value: '#FFEB3B' },
  { text: LOCALE_ENTRY, value: '#FFC107' },
  { text: LOCALE_ENTRY, value: '#FF9800' },
  { text: LOCALE_ENTRY, value: '#FF5722' },
  { text: LOCALE_ENTRY, value: '#795548' },
  { text: LOCALE_ENTRY, value: '#607D8B' },
  { text: LOCALE_ENTRY, value: '#9E9E9E' },
  { text: LOCALE_ENTRY, value: '#000000' }
];
