<template>
    <div id="gamereport" v-loading="loading">
        <div v-if="ActiveSeasons.length > 1" class="row">
            <div class="col-12 fullpage">
                <el-select class="topDropdown" v-model="SeasonID"  @change="seasonChange" placeholder="Select" default-first-option filterable>
                    <el-option v-for="season in ActiveSeasons"
                               :value="season.SeasonID"
                               :key="season.SeasonID"
                               :label="season.SeasonName">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-if="!MainLocationIDAccess" class="row">
            <div class="col-12 mt-1 fullpage">
                <el-select v-model="ProgramID"  placeholder="All Programs" class="topDropdown" @change="programChange" default-first-option filterable clearable>
                    <el-option
                        v-for="item in Programs"
                        :label="item.ProgramName"
                        :value="item.ProgramID"
                        :key="item.ProgramID">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 fullpage">
                <el-select ref="teamids" v-model="TeamIDs" @change="teamChange" placeholder="All Teams" class="topDropdown" multiple default-first-option filterable clearable>
                    <template v-for="item in Teams">
                        <el-option
                            v-if="item.ProgramID == ProgramID && item.SeasonID == SeasonID"
                            :key="item.TeamID"
                            :label="item.TeamName"
                            :value="item.TeamID">
                        </el-option>
                    </template>
                </el-select>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 col-md-6 fullpage">
	            <el-radio-group v-model="showforfeits">
	                <el-radio-button selected label="A">All Forfeits</el-radio-button>
	                <el-radio-button label="W">Only Wins</el-radio-button>
	                <el-radio-button label="L">Only Loses</el-radio-button>
	            </el-radio-group>
            </div>
	        <template v-if="games.length > 0">
		        <div class="col-12 col-md-6">
			        <el-input
				        class="search"
				        v-model="search"
				        clearable
				        placeholder="Type to search by date, team, or location">
			        </el-input>
		        </div>
	        </template>
        </div>
        <div v-if="games.length > 0" class="row">
            <div class="col-12 fullpage">
                <el-table
                          stripe border
                          :data="filtergames.filter(data => !search
						  							|| (data.GameDateF ? data.GameDateF.toLowerCase().includes(search.toLowerCase()) :'')
						  							|| (data.StartTime ? data.StartTime.toLowerCase().includes(search.toLowerCase()) :'')
						  							|| (data.LocationName ? data.LocationName.toLowerCase().includes(search.toLowerCase()) :'')
						  							|| (data.HomeTeamName ? data.HomeTeamName.toLowerCase().includes(search.toLowerCase()) :'')
						  							|| (data.VisitingTeamName ? data.VisitingTeamName.toLowerCase().includes(search.toLowerCase()) :'')
						  							)"
                          style="width: 100%"
                          size="small"
                          max-height="600">
                    <el-table-column
                        prop="GameDateF"
                        label="Date"
                        column-key="GameDateF"
                        width="125"
                        align="left"
                        key="23"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="StartTime"
                        label="Time"
                        column-key="StartTime"
                        width="80"
                        align="left"
                        key="24"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="LocationName"
                        label="Location"
                        column-key="LocationName"
                        width="150"
                        align="left"
                        key="25"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="Team"
                        label="Team"
                        column-key="Team"
                        min-width="150"
                        key="Team"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="TeamScore"
                        column-key="TeamScore"
                        width="80"
                        label="Score"
                        align="center"
                        key="30"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="Opponent"
                        label="Opponent"
                        column-key="Opponent"
                        min-width="150"
                        key="Opponent"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="OpponentScore"
                        column-key="OpponentScore"
                        width="80"
                        label="Score"
                        align="center"
                        key="28"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    name: 'gamereport',
    data() {
        return {
            loading : true,
            currentStatus : STATUS_INITIAL,
            ProgramID : '',
            TeamIDs : [],
            GameStatus : 'F',
            MainLocationIDs : [],
            SeasonID : '',
            Programs : [],
            Teams: [],
            ActiveSeasons : [],
            SiteLocations : [],
            search:'',
            games : [],
            showforfeits : 'A',
        }
    },
    props:["config", "parentrefs"],
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
        modifyAccess () {
            return this.$modifyAccess();
        },
        ProgramIDAccess () {
            return this.$ProgramIDAccess();
        },
        MainLocationIDAccess () {
            return this.$MainLocationIDAccess();
        },
        TeamIDAccess () {
            return this.$TeamIDAccess();
        },
        filtergames () {
            var filtered = [];
            if (this.showforfeits == 'A') {
                return this.games;
            } else {
                for (let i = 0; i < this.games.length; i++) {
                    if (this.games[i]['Forfeit'] == this.showforfeits) {
                        filtered.push(this.games[i]);
                    }
                }
            }
            return filtered;
        },
    },
    methods: {
        refreshData() {
            this.getGameData();
        },
        programChange () {
            this.TeamIDs = [];
            this.getTeams();
        },
        teamChange () {
            this.getGameData();
        },
        seasonChange () {
            this.TeamIDs = [];
            this.getTeams();
        },
        locationChange () {
            this.getGameData();
        },
        sortGameDate (a,b) {
            if (a.GameDate && b.GameDate) {
                var ret = b.selected - a.selected;
                if (ret == 0) {
                    ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
                    if (ret == 0) {
                        ret = parseInt(a.StartTimeSort.replace(/:/g, '')) - parseInt(b.StartTimeSort.replace(/:/g, ''));
                    }
                }
                return ret;
            } else {
                return 0;
            }
        },
        sortGameTime (a,b) {
            if (a.GameDate && b.GameDate) {
                var ret = b.selected - a.selected;
                if (ret == 0) {
                    ret = parseInt(a.StartTimeSort.replace(/:/g, '')) - parseInt(b.StartTimeSort.replace(/:/g, ''));
                    if (ret == 0) {
                        ret = parseInt(a.GameDate.replace(/-/g, '')) - parseInt(b.GameDate.replace(/-/g, ''));
                    }
                }
                return ret;
            } else {
                return 0;
            }
        },
        getInitialData () {
            this.loading = true;
            var tables = ['Programs', 'Teams', 'ActiveSeasons', 'SiteLocations'];
            axios.post('/index/tableData', {tables : tables },
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (!this.SeasonID) {
                            this.SeasonID = this.ActiveSeasons[0]['SeasonID'];
	                        for (let  i = 0; i < this.ActiveSeasons.length; i++) {
		                        if (this.ActiveSeasons[i]['Params']['DefaultSeason'] == 'y') {
			                        this.SeasonID = this.ActiveSeasons[i]['SeasonID'];
			                        break;
		                        }
	                        }
                        }
                        if (!this.ProgramID && this.Programs[0]) {
                            this.ProgramID = this.Programs[0]['ProgramID'];
                        }
                        if (this.TeamIDAccess) {
                            this.getTeams();
                        } else {
                            this.getGameData();
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
            });
        },
        getTeams () {
            this.loading = true;
            var tables = ['Teams'];
            axios.post('/index/tableData', {tables : tables, SeasonID : this.SeasonID, ProgramID : this.ProgramID, TeamIDs : this.TeamIDAccess },
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        this.getGameData();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = true;
                    }

                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
                this.loading = true;
            });
        },

        getGameData () {
            this.loading = true;
            let self = this;
            axios.post('/analyzer/games/getall', {ProgramID : this.ProgramID, SeasonID : this.SeasonID, TeamIDs : (this.TeamIDs.length == 0 && this.TeamIDAccess ? this.TeamIDAccess : this.TeamIDs) , GameStatus : this.GameStatus },
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.games = (response.data.games ? response.data.games : []);
                        this.$stopProgressBar(this.parentrefs);
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.loading = false;
                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
                this.loading = false;
            });

        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.GameStatus = 'F';
        }
        this.getInitialData();
    }
}
</script>
<style>
#gamereport .el-table .cell{
    word-break: keep-all;
}
</style>

<style scoped>
.search {
    float:right;
    width: 80%;
}
</style>
