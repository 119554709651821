<template>
	<div v-if="selfshow || caller == 'topheader'" id="contactUsForm" :class="caller">
		<i class="fas fa-times close" @click="closeContactUs"></i>
	    <h4 class="m-b-20">CONTACT US</h4>
	    <!-- Contact -->
		<el-form ref="contactUsForm" label-position="left" :model="contactDetails" label-width="120px">
			<el-form-item
				label="Your Name"
				prop="ContactName"
			    :rules="[
					{ required: true, message: 'Name is required', trigger: 'blur'}
	            ]"
	        >
				<el-input :disabled="(currentUser ? true : false)" v-model="contactDetails.ContactName"></el-input>
			</el-form-item>
			<el-form-item
				label="Email Address"
				prop="ContactEmailAddress"
			    :rules="[
					{ required: true, message: 'Email Address is required'},
					{ type: 'email', message: 'Email Address is invalid', trigger: 'blur'}
	            ]"
	        >
				<el-input :disabled="(currentUser ? true : false)" v-model="contactDetails.ContactEmailAddress"></el-input>
			</el-form-item>
			<el-form-item
	            label="Your Message"
	            prop="ContactMessage"
	            :rules="[
	                { required: true, message: 'Message is required', trigger: 'blur'}
	            ]"
	        >
				<el-input type="textarea" v-model="contactDetails.ContactMessage"></el-input>
			</el-form-item>
			<el-button type="success" class="btn-success" @click="submit">Submit</el-button>
		</el-form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Util from '../../assets/js/util.js';
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    export default{
        name: 'ContactUs',
        data() {
            return {
                clickable: true,
                contactDetails: {ContactName :'', ContactEmailAddress: '', ContactMessage: ''},
                selfshow: false
            }
        },
        props: ['show','caller'],
	    computed: {
		    ...mapGetters({currentUser: 'currentUser'}),
	    },
		watch: {
			show: function(newVal, oldVal) {
				this.selfshow = true;
			}
		},
        methods: {
            toggle(e){
                e.target.parentElement.classList.toggle('close')
            },
            handleSidebarToggle(){
                if( this.clickable ){
                    this.clickable = false;
                    document.body.classList.toggle('mini-sidebar');
                    this.clickable = true;
                }
            },
		    submit() {
                if (this.currentStatus == STATUS_SAVING) {
                    return true;
                }
				this.$refs['contactUsForm'].validate((valid) => {
					if (valid) {
						this.currentStatus = STATUS_SAVING;
						axios.post('/Index/ContactUs', {
							message: this.contactDetails
						}).then(response => {
							this.currentStatus = STATUS_INITIAL;
							if (response.data.result == 'success') {
								this.$notify({
									title: 'Success',
									message: "Message has been sent. Someone will contact you shortly.",
									type: 'success',
									duration: 3000,
									showClose: false
								});
				                this.contactDetails.ContactMessage = '';
			                } else {
								this.$notify({
									title: 'Error',
									message: (response.data.message ? response.data.message : 'Submission could not be processed'),
									type: 'error',
									duration: 2000,
									showClose: false
								});
			                }
			                this.closeContactUs ();
						}).catch(response => {
							this.currentStatus = STATUS_FAILED;
						});
                    } else {
                        return false;
                    }
                });
		    },
		    closeContactUs () {
			    this.$emit('close');
		        this.selfshow = false;
		    }
        },
		mounted() {
        	if (this.currentUser) {
        		this.contactDetails.ContactName = this.currentUser.FirstName + ' ' + this.currentUser.LastName;
		        this.contactDetails.ContactEmailAddress = this.currentUser.EmailAddress;
	        }
		}
    }
</script>
<style>
.form-control {
	width: 100%;
}
textarea.form-control {
	height: 100px;
}
#contactUsForm .el-form--label-top .el-form-item__label {
	margin:0;
	padding:0;
	line-height: 20px;
	float:left;
}
#contactUsForm.login {
	background: #FFF;
	position: absolute;
	padding: 5px;
	border: 2px solid #606266;
    width: auto;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
}
#contactUsForm.topheader {
	background: #FFF;
	padding: 5px;
	width: 100%;
}

#contactUsForm h4 {
	color : #606266;
}
#contactUsForm .close {
	float:right;
	right: 10px;
}
#contactUsForm.topheader .close {
	display: none;
}
#app .navbar-collapse .el-button span {
	color: #ffffff;
}
#app .navbar-collapse .btn-success span, #app.Analyzer .navbar-collapse .btn-success span {
	color: #ffffff;
}
</style>
