import axios from 'axios';
import { ElMessage } from 'element-plus';
const API_URL = process.env.API_URL;

export default function setup(router) {
	axios.interceptors.request.use(function(config) {
		const token = localStorage.token;
		var ActiveSeasonID = 0;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		config.headers['Content-Type'] = 'application/json';
		config.headers['X-Requested-With'] =  'XMLHttpRequest';
		config.baseURL = API_URL;

        var timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : '');
        if (timezone) {
            if (config.data) {
                config.data.timezone = timezone;
            } else {
                config.data = {timezone: timezone}
            }
        }
		return config;
	}, function(err) {
		return Promise.reject(err);
	});
	axios.interceptors.response.use((config) => {
        if (config.data.logout) {
            //alert('You have been logged out');
            localStorage.returnURL = window.location.pathname;
            router.push('/logout?msg=toolong');
            return false;
        }
        const token = localStorage.token;
        if (config.data.version) {
            if (parseInt(version.replace(/[ ,.]/g, "")) < parseInt(config.data.version.replace(/[ ,.]/g, ""))) {
                //Back-end version greater than that of front-end...reload
                if (token) {
	                ElMessage({
                        showClose: true,
                        message: 'NOTICE, Version ' + config.data.version + ' as been launched. Please save any work and close this message to install the new version.',
                        duration: 0,
                        customClass: "newversion",
                        onClose: () => refresh(),
                    });
                } else {
                    //if not logged in, will avoid refresh...this is for things like filling forms
                    //that users will typically not need new front-end code in the middle of an installation
                    //refresh();
                }
            }
        }
		return config;
	}, (error) => {
        if (error.response.status >= 500) {
            error.config.data = JSON.parse(error.config.data);
            if (error.config.data.attempt) {
                error.config.data.attempt++;
            } else {
                error.config.data.attempt = 1;
            }
            if (error.config.data.attempt < 1) {
                console.log('retry attempt : ' + error.config.data.attempt);
                return sleepRequest(1000, error.config);
            } else {

            }
        }
        console.log(error);
		return Promise.reject(error);
	});
	function refresh() {
        window.location.reload(true);
    }
    const sleepRequest = (milliseconds, originalRequest) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(axios.request(originalRequest)), milliseconds);
        });
    };
}
