<template>

  <div class="ds-week-header" :style="style">

    <div class="ds-hour-list"></div>

    <template v-for="(day, index) in liveDays">

      <ds-week-day-header
        :day="day"
        :config="config"
        :calendar="calendar"
        :placeholder="placeholder"
        :placeholder-for-create="placeholderForCreate"
        @edit="edit"
        @mouseenter="mouseEnterDay"
        @mouseleave="mouseLeaveDay"
        @mousemove="mouseMoveDay"
        @mousedown="mouseDownDay"
        @mouseup="mouseUpDay"
      ></ds-week-day-header>

    </template>

  </div>

</template>

<script>
import { CalendarDay, Calendar, CalendarEvent, Functions as fn } from 'dayspan';

export default {

  name: 'dsWeekHeader',

  props:
  {
    days:
    {
      required: true,
      type: Array
    },

    calendar:
    {
      required: true,
      type: Calendar
    },
	config:
	{
	  type: Object,
	  default() {
		  {};
	  }
	},
    placeholder:
    {
      type: CalendarEvent
    },

    placeholderForCreate:
    {
      type: Boolean,
      default: false
    },

    scrollPush:
    {
      type: Number,
      default: 0
    }
  },

  computed:
  {
    style()
    {
      return {
        marginRight: this.scrollPush + 'px'
      };
    },
    liveDays () {
      var days = [];
      var startIdx = -1;
      for (let i=0; i < this.calendar.days.length; i++) {
        if (this.calendar.type == 1) {
          days.push(this.calendar.days[i]);
        } else if (this.calendar.days[i].currentDay || this.calendar.days.length < 7) {
          startIdx = i;
          days.push(this.calendar.days[i]);
        } else if (startIdx >= 0 && i < startIdx + this.calendar.size) {
          days.push(this.calendar.days[i]);
        }
      }
      return days;
    }
  },

  methods:
  {
	  mouseEnterDay($event)
	  {
		  this.$emit('mouse-enter-day', this.day);
	  },

	  mouseLeaveDay($event)
	  {
		  this.$emit('mouse-leave-day', this.day);
	  },

	  mouseMoveDay($event)
	  {
		  this.$emit('mouse-move-day', this.getEvent('mouse-move-day', $event));
	  },

	  mouseDownDay($event)
	  {
		  this.$emit('mouse-down-day', this.getEvent('mouse-down-day', $event));
	  },

	  mouseUpDay($event)
	  {
		  this.$emit('mouse-up-day', this.getEvent('mouse-up-day', $event));
	  },

	  getEvent(type, $event, extra = {})
	  {
		  return fn.extend({

			  type: type,
			  day: this.day,
			  calendar: this.calendar,
			  left: $event.button === 0 && $event.buttons > 0,
			  right: $event.button === 1 && $event.buttons > 0,
			  handled: false,
			  $event: $event,
			  $vm: this,
			  $element: this.$el

		  }, extra);
	  },
	  edit(calendarEvent)
	  {
		  this.$emit('edit', calendarEvent);
	  },
  }
}
</script>

<style scoped lang="scss">

.ds-week-header {
  flex: none;
  display: flex;

  .ds-hour-list {
    flex: none;
    width: 44px;
    border-right: #e0e0e0 1px solid;
    // background-color: #fafafa;
  }
}

</style>
