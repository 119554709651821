<template>

  <div
    class="ds-calendar-event-menu"
    :class="contentClass">

    <div class="ds-calendar-event"
      :style="style"
      @click="editCheck"
      @mouseenter="mouseEnterEvent"
      @mouseleave="mouseLeaveEvent"
      @mousedown="mouseDownEvent"
      @mouseup="mouseUpEvent">

      <span v-if="showName">
        <slot name="eventTitle" v-bind="{calendarEvent, hasPrefix, getPrefix, details}">
          <span v-if="hasPrefix">
            {{ getCalendarTime( calendarEvent ) }}
          </span>
          <strong class="ds-ev-title">{{ detailsTitle }}</strong>
          <span class="ds-ev-description"> - {{ details.description }}</span>
        </slot>
      </span>

      <span v-else>
        <slot name="eventEmpty" v-bind="{calendarEvent, details}">&nbsp;</slot>
      </span>

    </div>

    <slot name="eventPopover" v-bind="{calendarEvent, calendar, edit, details, close}"></slot>

  </div>

</template>

<script>
import { CalendarEvent, Calendar, Day, Functions as fn } from 'dayspan';


export default {

  name: 'dsCalendarEvent',

  props:
  {
    calendarEvent:
    {
      required: true,
      type: CalendarEvent
    },

    calendar:
    {
      required: true,
      type: Calendar
    },

    index:
    {
      type: Number,
      default() {
        return 0;
      }
    },

    popoverProps:
    {
      validate(x) {
        return this.$dsValidate(x, 'popoverProps');
      },
      default() {
        return {
	        nudgeWidth: 200,
	        closeOnContentClick: false,
	        offsetOverflow: true,
	        offsetX: true,
	        maxWidth: 500
        };
      }
    },
    locationid:
    {
      type: Number,
      default: 0
    },
    isPlaceholderWithDay:
    {
      type: Day
    }
  },

  computed:
  {
    style()
    {
      let style =  (this.isPlaceholderWithDay ?
        this.$dayspan.getStylePlaceholderFull( this.details, this.calendarEvent, this.index, this.isPlaceholderWithDay ) :
        this.$dayspan.getStyleFull( this.details, this.calendarEvent, this.index ));

      if (this.locationid > 0) {
        if (this.locationid != this.calendarEvent.event.data.LocationID) {
          style["opacity"] = '0';
          style["zIndex"] = '-1';
          style["z-index"] = '-1';
          style["cursor"] = 'default';
        } else {
          style["opacity"] = '1';
          style["cursor"] = 'pointer';
        }
      } else {
        style["opacity"] = '1';
        style["cursor"] = 'pointer';
      }

      return style;
    },

    contentClass()
    {
      return this.$dayspan.fullscreenPopovers ? 'ds-fullscreen' : '';
    },
    isConstraints () {
      return (this.$route.fullPath.indexOf('constraint') !== -1 ? true : false);
    },
    sameDayEvents()
    {
      return this.calendarEvent.event.schedule.iterateSpans(this.calendarEvent.day, true).list();
    },

    hasPrefix()
    {
      return !this.calendarEvent.fullDay && this.sameDayEvents.length > 0;
    },

    hasIcon()
    {
      return !!(this.$dayspan.supports.icon && this.details.icon);
    },

    getPrefix()
    {
      return this.$dayspan.getPrefix( this.calendarEvent, this.sameDayEvents );
    },

    showName()
    {
      return this.isPlaceholderWithDay ?
        this.isPlaceholderWithDay.sameDay( this.calendarEvent.start ) :
        this.calendarEvent.starting || (
          this.calendar &&
          !this.calendar.filled.contains( this.calendarEvent.time.start ) &&
          this.calendar.filled.start.sameDay( this.calendarEvent.day )
        );
    },

    hasPopover()
    {
      if (this.locationid > 0) {
        if (this.locationid != this.calendarEvent.event.data.LocationID) {
          return false;
        }
      }
      return false;
    },

    details()
    {
      return this.calendarEvent.event.data;
    },
      detailsTitle () {
        var slottype = '';
          if (!this.isConstraints) {
            if (!this.details.readOnly) {
              slottype = (this.details.SlotType == 'g' ? 'Game' : (this.details.SlotType == 'e' ? '3rd Party' : (this.details.SlotType == 'p' ? 'Practice' : 'Open'))) + ' - ';
            }
          }
          var title = '';
          if (this.details.title) {
              title = this.details.title.replace(/open/ig, '');
          }

          if (title) {
              return slottype + title;
          } else {
              if (this.isConstraints) {
                  return ((this.details.TeamName ? this.details.TeamName : (this.details.ProgramName ? this.details.ProgramName : (this.details.LeagueName ? this.details.LeagueName : (this.details.OrganizationName ? this.details.OrganizationName : this.details.LocationName)))));
              } else {
                  return slottype + ((this.details.TeamName ? this.details.TeamName : (this.details.OrganizationName ? this.details.OrganizationName : this.details.LocationName)));
              }
          }
      },

},

  data: vm => ({
    menu: false
  }),

  methods:
  {
      getCalendarTime(calendarEvent)
      {
          let sa = calendarEvent.start.format('a');
          let sh = calendarEvent.start.format('h');
          if (calendarEvent.start.minute !== 0)
          {
              sh += calendarEvent.start.format(':mm');
          }
          return sh + sa;
      },
    close()
    {
      this.menu = false;
    },

    edit()
    {
      if (this.handlesEvents())
      {
        this.$emit('edit', this.calendarEvent);
      }
    },

    editCheck($event)
    {
      if (this.locationid > 0) {
        if (this.locationid != this.calendarEvent.event.data.LocationID) {
          return false;
        }
      }
      if (this.handlesEvents($event))
      {
        if (!this.hasPopover)
        {
          this.edit();
        }
        else
        {
          this.menu = !this.menu;
        }
      }
    },

    mouseEnterEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-enter-event', this.getEvent('mouse-enter-event', $event));
      }
    },

    mouseLeaveEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-leave-event', this.getEvent('mouse-leave-event', $event));
      }
    },

    mouseDownEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-down-event', this.getEvent('mouse-down-event', $event));
      }
    },

    mouseUpEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-up-event', this.getEvent('mouse-up-event', $event));
      }
    },

    handlesEvents($event)
    {
      var handles = !this.isPlaceholderWithDay;

      if (handles && $event)
      {
        $event.stopPropagation();
      }

      return handles;
    },

    getEvent(type, $event, extra = {})
    {
      return fn.extend({

        type: type,
        calendarEvent: this.calendarEvent,
        calendar: this.calendar,
        details: this.details,
        left: $event.button === 0,
        right: $event.button === 1,
        handled: false,
        $event: $event,
        $vm: this,
        $element: this.$el

      }, extra);
    }
  }
}
</script>

<style scoped lang="scss">
.ds-ev-title {
	padding-left: 5px;
}
.ds-calendar-event-menu {
  width: 100%;
  height: 18px;
  pointer-events: none;
  position:relative;
  margin-bottom: 3px;
}

.ds-calendar-event {
  margin: 2px 1px 1px 1px;
  top: 0   !important;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.5em;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  user-select: none;
  display: block;
  margin: 0;
  border-radius: 2px;
  pointer-events: all;
  border: 1px solid #ffffff;
}

</style>
