<template>
  	<div class="ds-event">
		<el-dialog
		  title="Attendees"
		  :modelValue="dialogVisible"
		  :modal="false"
		  :show-close="false"
		  :before-close="saveAttendees"
		  width="400px">
		  <el-button v-if="!details.Attendees || details.Attendees.length < details.eventMaxAttendance" @click="addAttendee()" size="small" class="mb-2"><i class="fa-solid fa-circle-plus"></i> Add Attendee</el-button>
		  <el-table v-if="details.Attendees"
		      ref="attendees"
		      :max-height="300"
		      :data="details.Attendees"
		      size="small"
		      stripe border
		        empty-text="No Attendees"
		      style="width: 350px;"
		  >
		      <el-table-column
		          prop="Name"
		          label="Name"
		          min-width="100">
		          <template #default="scope">
		              <el-input style="width: 40%;margin-left:5px;" size="small" v-model="scope.row.FirstName" ></el-input>
		              <el-input style="width: 55%;" size="small" v-model="scope.row.LastName" ></el-input>
		           </template>
		      </el-table-column>
		      <el-table-column
		               label=""
		               width="80"
		               align="center">
		          <template #default="scope">
					  <button v-if="scope.row.TestPayment == 'y' || !scope.row.TestPayment || scope.row.PaymentAmount == 0 || !scope.row.PaymentAmount" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click.prevent="removeAttendee(scope.$index)">
						<div class="v-btn__content">
							<i class="far fa-2x fa-trash-alt"></i>
						</div>
					</button>
		          </template>
		      </el-table-column>
		  </el-table>
			<template v-slot:footer>
			  <span class="dialog-footer">
			    <el-button type="info" class="btn-secondary" @click="saveAttendees">Close</el-button>
			  </span>
			</template>
		</el-dialog>

	    <div class="ds-event-header ds-event-area">
	        <el-input
	          class="ds-event-title"
	          style="font-size: 16px;height: 40px"
	          :placeholder="labels.title"
	          :readonly="isReadOnly || details.SlotType == 'g'"
	          v-model="details.title"
	        ></el-input>
	    </div>

	    <div class="ds-event-body ds-event-area">
			<div v-if="!isConstraints && $propertyAccess('DisconnectSlots') && details.repeats" class="disconnect">
				<el-checkbox
							:readonly="isReadOnly || $propertyDisabled('DisconnectSlots')"
							v-model="details.disconnectSlots"
				>Check to Create disconnected Slots on each day</el-checkbox>
		    	<div v-if="details.SlotType == 'o'" style="font-size: 11px;font-weight: 800;color: #cc0000;padding-left: 10px;">Important: For use in Scheduling, slots must be disconnected and split into the correct number of minutes.</div>
			</div>
            <div v-else-if="!isConstraints && details.ParentCalendarSlotID" class="disconnect">
                <el-checkbox
                    :readonly="isReadOnly || $propertyDisabled('DisconnectSlots')"
                    v-model="details.reconnectSlots">
                    Check to Put Disconnected Slots back Together
                </el-checkbox>
                <div v-if="details.SlotType == 'o'" style="font-size: 11px;font-weight: 800;color: #cc0000;padding-left: 10px;">Typically done to make bulk changes followed by disconnecting again.</div>
            </div>
	    	<div v-if="!details.repeats">
		        <el-date-picker
		            v-model="details.StartDate"
		            format="MMM D, YYYY"
		            value-format="YYYY-MM-DD"
		            type="date"
			        :clearable="false"
		            placeholder="Date">
		        </el-date-picker>
		    </div>
	    	<div v-else>
				<el-date-picker
					v-model="details.StartEndDate"
					type="daterange"
					unlink-panels
					:clearable="false"
					range-separator="To"
					start-placeholder="Start date"
					end-placeholder="End date"
					value-format="YYYY-MM-DD"
					format="MMM D, YYYY">
				</el-date-picker>
		    </div>
		    <div class="mt-2">
				<el-select v-model="details.repeats" class="repeats">
				    <el-option
				    	v-for="(repeat,index) in repeatOptions"
				    	:label="repeat.label"
				    	:key="repeat.value"
				    	:value="repeat.value">
				    </el-option>
				</el-select>
			</div>
			<div class="mt-2">
		        <ds-schedule-times
		          :schedule="schedule"
		          :details="details"
		          :read-only="readOnly"
		          :SlotType="details.SlotType"
		          :isConstraints="isConstraints"
		          :GameSetup="GameSetup"
		        ></ds-schedule-times>
	      	</div>
			<div v-if="!isConstraints"  class="mt-4">
				<el-radio v-if="details.CalendarSlotID > 0" v-model="details.SlotType" :disabled="true" label="g" border>Game/Used</el-radio>
				<el-radio v-model="details.SlotType" :disabled="isReadOnly || (details.CalendarSlotID > 0 && details.OriginalSlotType == 'g')"  label="o" border>Open Slot</el-radio>
				<el-radio v-if="$propertyAccess('Practices')" v-model="details.SlotType" :disabled="isReadOnly || $propertyDisabled('Practices') || (details.CalendarSlotID > 0 && details.OriginalSlotType == 'g')"  label="p" border>Practice/Skills</el-radio>
				<el-radio v-if="$propertyAccess('3rdParty')" v-model="details.SlotType" :disabled="isReadOnly || $propertyDisabled('3rdParty') || (details.CalendarSlotID > 0 && details.OriginalSlotType == 'g')"  label="e" border>3rd Party</el-radio>
			</div>
	    </div>
    	<!-- Tabs -->
		<el-tabs v-if="hasDetails && (details.SlotType || isConstraints)" v-model="tabs">
			<el-tab-pane label="SLOT DETAILS" name="details" key="details">
			  	<template v-if="Data.Locations.length > 0 && !isConstraints">
				  	<div class="fieldicon"><i class="fas fa-map-marked"></i></div>
				  	<div class="field">
					  <el-select  class="eventDropdown" v-model="details.LocationID" :teleported="false" popper-class="Analyzer" default-first-option filterable clearable
								  @change="locationChange('y')"
								  :disabled="isReadOnly || details.SlotType == 'g' "
	                              :placeholder="'Select ' + config.Params.locationName">
						  <el-option
								  v-for="item in Data.Locations"
								  :key="item.LocationID"
								  :label="item.LocationName"
								  :value="item.LocationID"
								  >
						  </el-option>
					  </el-select>
					</div>
			  	</template>
			  	<div v-if="Data.Organizations.length > 0 && !isConstraints && details.SlotType != 'o' && details.SlotType != 'g'" class="mt-2">
				  	<div class="fieldicon"><i class="fas fa-building"></i></div>
				  	<div class="field">
					  <el-select  class="eventDropdown" v-model="details.OrganizationID" clearable :teleported="false" popper-class="Analyzer" default-first-option filterable
								  :disabled="isReadOnly"
								  placeholder="Select Organization"
								  @change="organizationChange"
								  no-data-text="No Organizations">
						  <el-option
								  v-for="item in Data.Organizations"
								  :key="item.OrganizationID"
								  :label="item.OrganizationName"
								  :value="item.OrganizationID">
						  </el-option>
					  </el-select>
				  	</div>
			  	</div>
			  	<div v-if="!isConstraints && details.SlotType != 'o' && details.SlotType != 'g'" class="mt-2">
				  	<div class="fieldicon"><i class="fas calendar-check"></i></div>
				  	<div class="field">
					  <el-select  class="eventDropdown" v-model="details.EventStatus" :teleported="false" popper-class="Analyzer" default-first-option filterable
								  :disabled="isReadOnly"
								  placeholder="Status">
						  <el-option
								  v-for="item in EventStatus"
								  :key="item.EventStatus"
								  :label="item.EventStatusName"
								  :value="item.EventStatus">
						  </el-option>
					  </el-select>
				  </div>
			  	</div>
              	<div v-if="Data.Seasons.length > 0 && (details.SlotType == 'p' || isConstraints)" class="mt-2">
				  	<div class="fieldicon"><i class="fas fa-calendar-days"></i></div>
				  	<div class="field">
                      <el-select  class="eventDropdown" v-model="details.SeasonID" :teleported="false" popper-class="Analyzer" default-first-option filterable clearable
                                  @change="seasonChange"
                                  key="eventDetailsSeason"
                                  :disabled="isReadOnly || details.SlotType == 'g' "
                                  placeholder="Select Season">
                          <el-option
                              v-for="item in ValidSeasons"
                              :key="item.SeasonID"
                              :label="item.SeasonName"
                              :value="item.SeasonID"
                          >
                          </el-option>
                      </el-select>
                  </div>
              	</div>
			  	<div v-if="Data.Programs.length > 0 && isConstraints" class="mt-2">
				  	<div class="fieldicon" style="color: #333;font-size: 18px;font-weight: 800;">
					    <i class="fas fa-building"></i>
				  	</div>
				  	<div class="field">
					  <el-select  class="eventDropdown" v-model="details.ProgramID" :teleported="false" popper-class="Analyzer" default-first-option filterable clearable
								  :disabled="isReadOnly"
								  placeholder="All Programs"
								  no-data-text="No Programs"
								  key="eventDetailsProgram"
								  @change="programChange">
						  <el-option
								  v-for="item in Data.Programs"
								  :key="item.ProgramID"
								  :label="item.ProgramName"
								  :value="item.ProgramID">
						  </el-option>
					  </el-select>
				  </div>
			  	</div>
			  	<div v-if="isConstraints" class="mt-2">
				  	<div class="fieldicon"><i class="fas fa-shield-alt"></i></div>
				  	<div class="field">
					  <el-select  class="eventDropdown" v-model="details.TeamID" :teleported="false" popper-class="Analyzer" default-first-option filterable clearable
								  :disabled="isReadOnly"
								  no-data-text="No Teams"
								  placeholder="All Teams"
								  :key="teamkey"
								  @change="teamChange"
					  >
                          <template v-for="item in programTeams" >
						    <el-option
                                  v-if="!details.SeasonID || (details.SeasonID == item.SeasonID)"
								  :key="item.TeamID"
								  :label="item.TeamName + (!details.SeasonID ? ' (' + item.SeasonName + ')' : '')"
								  :value="item.TeamID">
						    </el-option>
                          </template>
					  </el-select>
				  </div>
			  	</div>
              	<div v-if="Data.MainLocations.length > 0 && isConstraints && $propertyAccess('Locations')" class="mt-2">
				  	<div class="fieldicon"><i class="fas fas fa-map-marked"></i></div>
				  	<div class="field">
                      <el-select  class="eventDropdown" v-model="details.MainLocationIDs" :teleported="false" popper-class="Analyzer" default-first-option filterable clearable multiple
                                  :disabled="isReadOnly"
                                  :placeholder="'All ' + config.Params.mainlocationNames">
                          <el-option
                              v-for="item in Data.MainLocations"
                              :key="item.MainLocationID"
                              :label="item.MainLocationName"
                              :value="item.MainLocationID"
                          >
                          </el-option>
                      </el-select>
                  </div>
              	</div>
              	<div v-if="Data.Locations.length > 0 && isConstraints" class="mt-2">
				  	<div class="fieldicon"><i class="fas fas fa-map-marked"></i></div>
				  	<div class="field">
                      <el-select  class="eventDropdown" v-model="details.LocationIDs" :teleported="false" popper-class="Analyzer" default-first-option filterable clearable multiple
                                  :disabled="isReadOnly"
                                  :placeholder="'All ' + config.Params.locationNames">
                          <template v-for="item in Data.Locations">
                              <el-option
                                  v-if="details.MainLocationIDs.length > 0 && details.MainLocationIDs.indexOf(item.MainLocationID) !== -1"
                                  :key="item.LocationID"
                                  :label="item.LocationName"
                                  :value="item.LocationID"
                              >
                              </el-option>
                          </template>
                      </el-select>
					  <template v-if="details.LocationIDs && details.LocationIDs.length > 0">
						<el-alert :title="'Only ' + config.Params.locationNames + ' will be considered for this constraint.'" type="info" show-icon :closable="false" />
					  </template>
                  </div>
              	</div>
			  	<div v-if="details.SlotType == 'p'" class="mt-2">
				  	<div class="fieldicon"><i class="fas fa-shield-alt"></i></div>
				  	<div class="field">
					  <el-select  v-model="details.TeamIDs" multiple class="multiple"
								  :disabled="isReadOnly"
								  :placeholder="(details.OrganizationID || details.SeasonID ? 'Select Team(s)' : 'Select Organization or Season')" clearable
								  no-data-text="No Teams"
								  key="practiceDetailsTeam"
								  :teleported="false" popper-class="Analyzer" default-first-option filterable>
						  <el-option
								  v-for="item in practiceTeams"
								  :key="item.TeamID"
								  :label="item.TeamName+ (!details.SeasonID ? ' (' + item.SeasonName + ')' : '')"
								  :value="item.TeamID">
						  </el-option>
					  </el-select>
				  </div>
			  	</div>

				<!-- Description -->
			  	<div v-if="details.SlotType != 'g'" class="mt-2">
				  	<div class="fieldicon"><i class="fa-solid fa-bars-staggered"></i></div>
				  	<div class="field">
					  <el-input
							  type="textarea"
							  :autosize="{ minRows: 2, maxRows: 3}"
							  :placeholder="labels.description"
							  :readonly="isReadOnly"
							  v-model="details.description">
					  </el-input>
				  </div>
			  	</div>

				<!-- Fee -->
			  	<div v-if= "!isConstraints && ($propertyAccess('Costs') || $propertyAccess('Fees'))" class="mt-2">
				  	<div class="fieldicon"><i class="fa-solid fa-dollar-sign"></i></div>
				  	<div class="field">
                      <template v-if="$propertyAccess('Costs')">
                          <span>Cost&nbsp;&nbsp;</span>
                          <el-input style="width: 120px;" class="smallCurrency" placeholder="Cost" :readonly="isReadOnly || $propertyDisabled('Costs')" v-model="details.LocationCost"></el-input>
                      </template>
                      <template v-if="$propertyAccess('Fees')">
                          <span style="margin-left: 43px;">Fee&nbsp;&nbsp;</span>
                          <el-input style="width: 120px;" class="smallCurrency" placeholder="Fee" :readonly="isReadOnly || $propertyDisabled('Fees')" v-model="details.RinkFee"></el-input>
                          <el-radio-group style="float:right;margin-right: 60px;height:30px;" v-model="details.HourlyFlat" :disabled="isReadOnly || $propertyDisabled('Fees')">
                              <el-radio-button label="h">Hourly Rate</el-radio-button>
                              <el-radio-button label="f">Flat Rate</el-radio-button>
                          </el-radio-group>
                      </template>
				  </div>
			  	</div>

				<div v-if="!isConstraints && details.SlotType == 'e'" class="mt-2">
				  	<div class="fieldicon"><i class="fas fa-hashtag"></i></div>
				  	<div class="field">
				      <el-input style="width: 100px;" class="smallCurrency" placeholder="Max Number" :readonly="isReadOnly" v-model="details.eventMaxAttendance"></el-input>
				      <span style="margin-left: 43px;">Attendees</span>
				      <el-input style="width: 100px;" sclass="smallCurrency" placeholder="" :readonly="isReadOnly" v-model="details.eventCurrentAttendance"></el-input>
				      <i style="margin-left: 30px; cursor:pointer; font-size: 20px;" class="far fa-copy" @click="dialogVisible=true;"></i>
				  </div>
				</div>

	            <!-- Color -->
	            <div class="eventDetailsColor mt-2">
				  	<div class="fieldicon" :class="colorClass()" :style="(colorClass() != 'Custom' ? '' : 'color: ' + details.color + ';')"><i class="fa-solid fa-droplet"></i></div>
				  	<div class="field">
						<el-select v-model="details.color" placeholder="Select" :class="colorClass()" :style="(colorClass() != 'Custom' ? '' : 'color: ' + details.color + ';')" :teleported="false" popper-class="Analyzer" default-first-option filterable>
							<el-option
									v-for="item in colors"
									:key="item.value"
									:label="item.text"
									:value="item.value">
								<div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.value + ';'">{{ item.text }}</div>
							</el-option>
						</el-select>
					</div>
	            </div>

			  <div v-if="Data.Leagues.length > 0 && isConstraints && details.SlotType != 'g'" class="mt-2">
				  	<div class="fieldicon" style="color: #333;font-size: 18px;font-weight: 800;">L</div>
				  	<div class="field">
					  <el-select  class="eventDropdown" v-model="details.LeagueID"
                        :disabled="isReadOnly"
                        no-data-text="No Leagues"
                        placeholder="Select League"
						:teleported="false"
                        popper-class="Analyzer" default-first-option filterable clearable
                        @change="leagueChange">
                        <template v-for="item in Data.Leagues">
						  <el-option
                          		  v-if="(!$ProgramIDAccess() && $TeamIDAccess().length == 0) || item.ManageConstraints == 'y'"
								  :key="item.LeagueID"
								  :label="item.LeagueName"
								  :value="item.LeagueID">
						  </el-option>
						</template>
					  </el-select>
				  </div>
			  </div>
              <!-- Tags -->
              <div v-if="Data.Tags.length > 0 && !isConstraints && $propertyAccess('Tags')" class="mt-2">
				  	<div class="fieldicon"><i class="fas fa-tags"></i></div>
				  	<div class="field">
	                    <div class="tags el-input__inner" style="padding: 0px; min-height: 35px; height: auto; line-height: 40px;width: 100%;padding-left: 5px;">
	                        <el-tag
	                            v-for="tag in details.TagLinks"
	                            :key="tag.TagID"
	                            :closable="!$propertyDisabled('Tags')"
	                            effect="dark"
	                            :type="tag.TagType"
	                            :disable-transitions="false"
	                            @close="handleCloseTag(tag)"
	                        >
	                            {{tag.TagName}}
	                        </el-tag>
	                        <el-select v-if="tagSelectVisible" key="selecttags" v-model="newTagID" ref="saveTagInput" style="width: auto;margin:0px" @change="handleNewTag" :disabled="isReadOnly || $propertyDisabled('Tags')" :teleported="false" popper-class="Analyzer" default-first-option clearable filterable>
	                            <el-option
	                                v-for="item in availableTags"
	                                :key="item.TagID"
	                                :label="item.TagName"
	                                :value="item.TagID"
	                            >
	                                <div :style="'width: 100%; padding-left: 5px;line-height: 25px; color: #fff;background-color:' + item.TagColor + ';'">{{ item.TagName }}</div>

	                            </el-option>
	                        </el-select>
	                        <el-button v-else-if="availableTags.length > 0 && !isReadOnly && !$propertyDisabled('Tags')" class="button-new-tag" size="small" @click="showTagSelect">+ Add Tag</el-button>
	                    </div>
                  </div>
              </div>
			  <!-- Schedule Type -->
			  <div v-if="Data.ScheduleTypes.length > 0 && !isConstraints && $propertyAccess('ScheduleTypes')" class="mt-2">
				  	<div class="fieldicon"><i class="fa-solid fa-calendar-day"></i></div>
				  	<div class="field">
					  <el-select class="eventDropdown" style="width: 100%;min-height: 40px;" v-model="details.ScheduleTypes" allow-create :teleported="false" popper-class="Analyzer" default-first-option filterable
								 :disabled="isReadOnly || $propertyDisabled('ScheduleTypes')"
								 multiple
								 placeholder="Select"
								 no-data-text="No Schedule Types">
						  <el-option
								  v-for="item in Data.ScheduleTypes"
								  :key="item.ScheduleTypeID"
								  :label="item.ScheduleTypeName"
								  :value="item.ScheduleTypeID">
						  </el-option>
					  </el-select>
				  </div>
			  </div>
			  <div v-if="!details.TeamID && Data.DivisionTypes[details.LeagueID] && Data.DivisionTypes[details.LeagueID][details.SeasonID] && Data.DivisionTypes[details.LeagueID][details.SeasonID].length > 0 && isConstraints" class="mt-2">
				  	<div class="fieldicon" style="color: #333;font-size: 18px;font-weight: 800;">D</div>
				  	<div class="field">
					  <el-select class="eventDropdown" style="width: 100%;min-height: 40px;" v-model="details.DivisionTypes" popper-class="Analyzer" default-first-option filterable clearable
								 :disabled="isReadOnly"
								 multiple
								 no-data-text="No Division Types"
								 placeholder="Select">
						  <el-option
								  v-for="item in Data.DivisionTypes[details.LeagueID][details.SeasonID]"
								  :key="item.DivisionTypeID"
								  :label="item.DivisionTypeName"
								  :value="item.DivisionTypeID">
						  </el-option>
					  </el-select>
				  </div>
			  </div>
			  <div v-if="isConstraints" class="mt-2">
				<div class="field">
					Apply Constraint to:<br>
					<el-radio-group  v-model="details.XIce">
						<el-radio-button label="y">Only X-{{config.Params.playingsurface}} {{config.Params.games}}</el-radio-button>
						<el-radio-button label="n">Only Regular {{config.Params.games}}</el-radio-button>
						<el-radio-button label="b">All {{config.Params.games}}</el-radio-button>
					</el-radio-group>
				</div>
			  </div>

              <div class="mt-4" style="text-align: center;margin-bottom: 220px;">
                  <template v-if="!isReadOnly && (($createAccess() && !details.CalendarSlotID) || ($modifyAccess()  && details.CalendarSlotID)) && (!$MainLocationIDAccess() || $MainLocationIDAccess() && details.SlotType == 'o') ">
                      <button class="btn btn-success btn-icon" type="button" @click="save">
                          <i class="fas fa-check"></i> <span> Save {{(details.CalendarSlotID || details.ConstraintSlotID ? '' : (isConstraints ? ' New Constraint' : ' New Slot'))}}</span>
                      </button>
                      <button v-if="(details.CalendarSlotID || details.ConstraintSlotID) && $modifyAccess()" @click="remove" class="btn btn-icon btn-danger delete" type="button">
                          <i class="far fa-2x fa-trash-alt"></i> <span>Delete</span>
                      </button>
                  </template>
                  <button class="btn btn-icon btn-secondary cancel" type="button" @click="cancel">
                      <i class="fas fa-times"></i> <span>Close</span>
                  </button>
              </div>
		</el-tab-pane>
		<el-tab-pane v-if="showForecast" label="FORECAST" name="forecast" key="forecast">
              <ds-schedule-forecast
                :schedule="schedule"
                :day="day"
                :read-only="readOnly"
              ></ds-schedule-forecast>
      	</el-tab-pane>
    </el-tabs>
	</div>
</template>

<script>
import { Day, Calendar, CalendarEvent, Schedule, Functions as fn } from 'dayspan';
import axios from 'axios';
import Util from "../../../assets/js/util";
import { mapGetters } from 'vuex';

export default {

  name: 'dsEvent',

  props:
  {
	targetSchedule:
	{
		required: true,
		type: Schedule
	},

	targetDetails:
	{
		type: Object,
		required: true
	},

	calendarEvent:
	{
		type: CalendarEvent
	},
	calendar:
	{
		type: Calendar
	},

	day:
	{
		type: Day
	},
	readOnly:
	{
		type: Boolean,
		default: false
	},
	labels:
	{
		validate(x) {
			return this.$dsValidate(x, 'labels');
		},
		default() {
			return {
				moreActions:  'More actions...',
				cancel:       'Cancel slot changes',
				save:         'Save',
				title:        'Title',
				exclusions:   'These are slots or spans of time where a normally occurring slot was excluded from the schedule. Slots are excluded here if a slot occurrence is moved.',
				inclusions:   'These are slots or spans of time where slots were added outside the normally occurring schedule. Slots are added here if a slot occurrence is moved.',
				cancelled:    'These are slots or spans of time where slots were cancelled.',
				edit:         'Edit slot',
				add:          'Add slot',
				location:     'Add location',
				description:  'Add description',
				calendar:     'Calendar',
				tabs: {
					details:    'Slot Details',
					forecast:   'Forecast',
					removed:    'Removed',
					added:      'Added',
					cancelled:  'Cancelled'
				}
			};
		}
	},
	hasDetails:
	{
		type: Boolean,
		default() {
			return true;
		}
	},

	hasForecast:
	{
		type: Boolean,
		default() {
			return true;
		}
	},

	Data:
	{
		type: Object,
		default() {
		 	{};
		}
	},
	isConstraints:
	{
		type: Boolean,
		default: false
	},
	config:
	  {
	      type: Object,
	      default() {
	          {};
	      }
	  },

  },

  data: vm => ({
        tab: 'details',
        schedule: new Schedule(),
        details: vm.$dayspan.getDefaultEventDetails(),
        totalFee : '',
        GameSetup : 0,
        DefaultCost : 0,
        newTagID: '',
        dialogVisible: false,
        tagSelectVisible: false,
        teamkey : 1,
        tabs : 'details',
        repeatOptions : [{label : 'Does Not Repeat', value : ''},{label : 'Daily', value : 'D'},{label : 'Weekly', value : 'W'},{label : 'Every Weekday (Monday thru Friday)', value : 'MF'}],
        EventStatus : [{EventStatus : "A", EventStatusName : 'Active'},{EventStatus : "CP", EventStatusName : 'Cancelled - Will Pay'},{EventStatus : "CPO", EventStatusName : 'Cancelled - Will Pay, Time Open'},{EventStatus : "CN", EventStatusName : 'Cancelled - No Payment'},{EventStatus : "CNO", EventStatusName : 'Cancelled - No Payment, Time Open'}],
		colors :
		[
			{ text: 'Red', value: '#F44336' },
			{ text: 'Pink', value: '#E91E63' },
			{ text: 'Purple', value: '#9C27B0' },
			{ text: 'Deep Purple', value: '#673AB7' },
			{ text: 'Indigo', value: '#3F51B5' },
			{ text: 'Blue', value: '#2196F3' },
			{ text: 'Highlight Color', value: '#1976d2' },
			{ text: 'Light Blue', value: '#03A9F4' },
			{ text: 'Cyan', value: '#00BCD4' },
			{ text: 'Teal', value: '#009688' },
			{ text: 'Green', value: '#4CAF50' },
			{ text: 'Light Green', value: '#8BC34A' },
			{ text: 'Lime', value: '#CDDC39' },
			{ text: 'Yellow', value: '#FFEB3B' },
			{ text: 'Amber', value: '#FFC107' },
			{ text: 'Orange', value: '#FF9800' },
			{ text: 'Deep Orange', value: '#FF5722' },
			{ text: 'Brown', value: '#795548' },
			{ text: 'Blue Gray', value: '#607D8B' },
			{ text: 'Gray', value: '#9E9E9E' },
			{ text: 'Black', value: '#000000' }
		],
	}),
  watch:
  {
  	targetSchedule:
	  	{
			handler: 'updateSchedule',
			immediate: true
	  	},

    targetDetails:
	    {
	      handler: 'updateDetails',
	      immediate: true
	    }
  },

  computed:
  {
    ...mapGetters({ currentUser: 'currentUser' }),
    slotData()
    {
      return {
        targetSchedule: this.targetSchedule,
        targetDetails: this.targetDetails,
        schedule: this.schedule,
        details: this.details,
        busyOptions: this.busyOptions,
        day: this.day,
        calendar: this.calendar,
        calendarEvent: this.calendarEvent,
        labels: this.labels,
        readOnly: this.readOnly,
        StartDate : '',
      };
    },
	ValidSeasons () {
		var seasons = [];
		if (this.details.CalendarSlotID || this.details.ConstraintSlotID) {
			seasons = this.Data.Seasons;
		} else {
			for (let j = 0; j < this.Data.Seasons.length; j++) {
				for (let i = 0; i < this.Data.Leagues.length; i++) {
					if (this.Data.Seasons[j].SeasonID == this.Data.Leagues[i].ActiveSeasonID) {
						seasons.push(this.Data.Seasons[j]);
						break;
					}
				}
			}
		}
		return seasons;
	},
  	programTeams () {
    	return this.Data.Teams[this.details.ProgramID];
  	},
	practiceTeams () {
		if (this.isConstraints) {
			return [];
		}
        let teams = [];
        let teamids = [];
        if (this.details.OrganizationID && this.details.OrganizationID > 0 && this.details.SlotType == 'p') {
            for (let i = 0; i < this.Data.Organizations.length; i++) {
                if (this.Data.Organizations[i]['OrganizationID'] == this.details.OrganizationID) {
                    this.details.ProgramID = this.Data.Organizations[i]['ProgramID'];
                }
            }
        }
        if (this.$ProgramIDAccess() || this.$TeamIDAccess().length > 0) {
            for (let i = 0; i < this.Data.Teams.length; i++) {
                teams.push(this.Data.Teams[i]);
                teamids.push(this.Data.Teams[i].TeamID);
            }
        } else {
            if (this.details.SeasonID) {
                for (let i = 0; i < this.Data.Teams[this.details.SeasonID].length; i++) {
                    if (!this.details.ProgramID || this.Data.Teams[this.details.SeasonID][i]['ProgramID'] == this.details.ProgramID) {
                        teams.push(this.Data.Teams[this.details.SeasonID][i]);
                        teamids.push(this.Data.Teams[this.details.SeasonID][i].TeamID);
                    }
                }
            } else if (this.details.ProgramID) {
                for (let j = 0; j < this.Data.Seasons.length; j++) {
                    for (let i = 0; i < this.Data.Teams[this.Data.Seasons[j]['SeasonID']].length; i++) {
                        if (!this.details.ProgramID || this.Data.Teams[this.Data.Seasons[j]['SeasonID']][i]['ProgramID'] == this.details.ProgramID) {
                            teams.push(this.Data.Teams[this.Data.Seasons[j]['SeasonID']][i]);
                            teamids.push(this.Data.Teams[this.Data.Seasons[j]['SeasonID']][i].TeamID);
                        }
                    }
                }
            }
        }
        if (this.details.TeamIDs) {
            for (let j = this.details.TeamIDs.length - 1; j >= 0; j--) {
                if (teamids.indexOf(this.details.TeamIDs[j]) === -1) {
                    //not in so remove
                    this.details.TeamIDs.splice(j, 1);
                }
            }
        }
        if (this.details.TeamID) {
            if (teamids.indexOf(this.details.TeamID) === -1) {
                //not in so remove
                this.details.TeamID = '';
            } else {
                this.details.TeamID = this.details.TeamID.toString();
            }
        }
	    return teams;
	},

    repeats()
    {
      return !this.schedule.isSingleEvent();
    },

    showForecast()
    {
      return this.$dayspan.features.forecast &&
        this.repeats &&
        this.hasForecast;
    },
    isReadOnly()
    {
      return (this.readOnly || this.$dayspan.readOnly || (this.details.CalendarSlotID > 0 && !this.$modifyAccess()));
    },
      availableTags () {
          var tags = [];
          var tagOk = true;
          for (let i = 0; i < this.Data.Tags.length; i++) {
              tagOk = true;
              if (this.details.TagLinks) {
                  for (let j = 0; j < this.details.TagLinks.length; j++) {
                      if (this.Data.Tags[i].TagID == this.details.TagLinks[j].TagID) {
                          tagOk = false;
                          break;
                      }
                  }
              } else {
                  this.details.TagLinks = [];
              }
              if (this.Data.Tags[i].TagArea != 'SC') {
                  tagOk = false;
              }
              if (tagOk) {
                  tags.push(this.Data.Tags[i]);
              }
          }
          return tags;
      },
  	  times() {
		  var x = 5; //minutes interval
		  var tt = (this.$dayspan.startHour*60); // start time
		  var ap = ['AM', 'PM']; // AM-PM
		  var myTimes = [];

		  for (var i=0;tt<24*60; i++) {
			  var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
			  var mm = (tt%60); // getting minutes of the hour in 0-55 format
			  myTimes[i] = ("" + (hh == 12 ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
			  tt = tt + x;
		  }
		  return myTimes;
	  }
  },

  methods:
  {
	colorClass () {
		for (let i = 0; i < this.colors.length; i++) {
			if (this.details.color == this.colors[i].value) {
				return this.colors[i].text.replace(' ','_');
			}
		}
		return '';
	},
  	handleNewTag() {
          let newTagID = this.newTagID;
          if (newTagID) {
              for (let i = 0; i < this.Data.Tags.length; i++) {
                  if (this.Data.Tags[i].TagID == newTagID) {
                      this.details.TagLinks.push(this.Data.Tags[i]);
                      break;
                  }
              }
          }
          this.tagSelectVisible = false;
          this.newTagID = '';
      },
      handleCloseTag(tag) {
          this.details.TagLinks.splice(this.details.TagLinks.indexOf(tag), 1);
      },
      showTagSelect() {
          this.tagSelectVisible = true;
          this.$nextTick(_ => {
              this.$refs.saveTagInput.focus();
          });
      },
      saveAttendees () {
        if (this.details.Attendees) {
          this.details.eventCurrentAttendance = this.details.Attendees.length;
        }
        this.dialogVisible = false;
        return true;
      },
      remove()
      {
          this.$confirm('Are you sure you want to delete this slot and all occurrences?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
          }).then(({ value }) => {
              var ev = this.getEvent('remove');

              this.$emit('remove', ev);

              if (!ev.handled && ev.calendar)
              {
                  ev.calendar.removeEvent( ev.event );
                  ev.handled = true;
              }

              this.$emit('finish', ev);

              this.$emit('event-remove', ev.event);
          }).catch(() => {

          });
      },
    save()
    {
      var ev = this.getEvent('save');
	    if (!this.isConstraints) {
		    if (ev.details.repeats && ev.details.StartEndDate[0] == ev.details.StartEndDate[1]) {
                this.$alert('Start and End Dates must not be the same', 'Error', {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
                return;
		    }
            if (!ev.details.SlotType) {
                this.$alert('Select What Type of Slot this is', 'Error', {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
                return;
            }
		    if (!ev.details.LocationID) {
			    this.$alert(this.config.Params.locationName + ' is Required', 'Error', {
				    type: 'error',
				    confirmButtonText: 'OK',
			    });
			    return;
		    }
            if (this.details.SlotType == 'p' && this.details.TeamIDs.length == 0) {
                this.$alert('At Least one Team is Required for a Practice', 'Error', {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
                return;
            }
            if (this.details.SlotType == 'e' && !this.details.OrganizationID) {
                this.$alert('An Organization is Required for a 3rd Party Event', 'Error', {
                    type: 'error',
                    confirmButtonText: 'OK',
                });
                return;
            }
            if (this.details.SlotType != 'o' && this.details.SlotType != 'g') {
                if (this.$ProgramIDAccess() || this.$TeamIDAccess().length > 0) {
                    ev.details.EventStatus = 'A';
                } else {
                    if (!ev.details.EventStatus) {
                        this.$alert('Status is Required', 'Error', {
                            type: 'error',
                            confirmButtonText: 'OK',
                        });
                        return;
                    }
                    if (ev.details.EventStatus != 'CN' && ev.details.EventStatus != 'CNO') {
                        if (ev.details.RinkFee == '') {
                            this.$alert('Fee is Required', 'Error', {
                                type: 'error',
                                confirmButtonText: 'OK',
                            });
                            return;
                        }
                        if (!ev.details.HourlyFlat) {
                            this.$alert('Select Hourly or Flat Rate', 'Error', {
                                type: 'error',
                                confirmButtonText: 'OK',
                            });
                            return;
                        }
                    }
                }
		    }
            if (this.details.SlotType != 'g' && this.$propertyAccess('ScheduleTypes') && !this.$propertyDisabled('ScheduleTypes')) {
                if (ev.details.ScheduleTypes.length == 0) {
                    if (this.details.SlotType == 'o') {
                        this.$alert('One Schedule Type is Required for Open Slots', 'Error', {
                            type: 'error',
                            confirmButtonText: 'OK',
                        });
                        return;
                    } else if ((this.details.EventStatus == 'CPO' || this.details.EventStatus == 'CNO') && this.details.prevEventStatus != 'CPO' && this.details.EventStatus != 'CNO') {
                        this.$alert('One Schedule Type is Required for the Open Slot that will be created.', 'Error', {
                            type: 'error',
                            confirmButtonText: 'OK',
                        });
                        return;
                    }
                }
            }
		    let self = this;
		    axios.post('/analyzer/locations/checkoverlappingslot', {
				    details: ev.details,
				    schedule: ev.schedule.toInput(true)
			    },
		    )
			    .then(response => {
				    if (response.data.result == 'success') {
					    self.update(ev);
				    } else if (ev.details.SlotType == 'p') {
				    	//for practices just give a warning
					    this.$confirm(response.data.message + '. Do you wish to continue to save?', 'Overlapping Slot Violation.', {
						    distinguishCancelAndClose: true,
						    showClose: false,
						    confirmButtonText: "Don't Save",
						    cancelButtonText: 'Save Anyway',
						    closeOnClickModal: false,
						    closeOnPressEscape: false,
						    type: 'warning'
					    })
						    .then(function () {
							    return;
						    })
						    .catch(function () {
							    self.update(ev);
						    });
				    } else {
					    this.$alert(response.data.message, 'Error', {
						    type: 'error',
						    confirmButtonText: 'OK',
					    });
					    return;
				    }
			    }).catch(response => {
		    });
	    } else {
		    this.update(ev);
	    }

    },
	  update (ev) {
		  ev.details.operation = 'update';


		  if (!ev.handled)
		  {
			  if (ev.target && ev.schedule)
			  {
				  ev.target.set( ev.schedule );
			  }
			  if (ev.details.TeamID) {
				  for (let i = 0; this.Data.Teams.length; i++) {
					  if (this.Data.Teams[i].TeamID == ev.details.TeamID) {
						  ev.details.TeamName = this.Data.Teams[i].TeamName;
						  break;
					  }
				  }
			  } else if (ev.details.ProgramID) {
				  for (let i = 0; this.Data.Programs.length; i++) {
					  if (this.Data.Programs[i].ProgramID == ev.details.ProgramID) {
						  ev.details.ProgramName = this.Data.Programs[i].ProgramName;
						  break;
					  }
				  }
			  } else if (ev.details.LeagueID) {
				  for (let i = 0; this.Data.Leagues.length; i++) {
					  if (this.Data.Leagues[i].LeagueID == ev.details.LeagueID) {
						  ev.details.LeagueName = this.Data.Leagues[i].LeagueName;
						  break;
					  }
				  }
              } else if (ev.details.LocationID) {
                  for (let i = 0; this.Data.Locations.length; i++) {
                      if (this.Data.Locations[i].LocationID == ev.details.LocationID) {
                          ev.details.LocationName = this.Data.Locations[i].LocationName;
                          break;
                      }
                  }
              } else if (ev.details.MainLocationID) {
                  for (let i = 0; this.Data.MainLocations.length; i++) {
                      if (this.Data.MainLocations[i].MainLocationID == ev.details.MainLocationID) {
                          ev.details.MainLocationName = this.Data.MainLocations[i].MainLocationName;
                          break;
                      }
                  }
			  }
			  if (ev.calendarEvent)
			  {
				  this.$dayspan.setEventDetails(
					  ev.details,
					  ev.targetDetails,
					  ev.calendarEvent.event,
					  ev.calendarEvent
				  );

				  this.$emit('update', ev);

				  this.$emit('event-update', ev);
			  }
			  else if (ev.create)
			  {
				  ev.created = this.$dayspan.createEvent( ev.details, ev.schedule );

				  if (ev.calendar)
				  {
					  ev.calendar.addEvent( ev.created );
					  ev.added = true;
				  }

				  this.$emit('create', ev);
			  }

			  if (ev.calendar && ev.refresh)
			  {
				  ev.calendar.refreshEvents();
			  }

			  ev.handled = true;

			  if (ev.created)
			  {
				  this.$emit('event-create', ev.created);
				  let lastSaved = ev.details;
				  let json = JSON.stringify(lastSaved);

				  localStorage.setItem('lastSavedDetail'+(this.isConstraints ? 'Constraint' : 'Calendar'), json);
			  }
		  }

		  this.$emit('saved', ev);
	  },
	  programChange() {
		  this.details.TeamID = '';
		  this.details.TeamName = '';
	  },
	  teamChange() {
		  this.details.DivisionTypes = [];
		  this.teamkey++;
	  },
      addAttendee() {
          if (!this.details.hasOwnProperty('Attendees')) {
              this.details['Attendees'] = [];
          }
          this.details.Attendees.unshift(
              {
                  FirstName: '',
                  LastName : ''
              }
          );
      },
      removeAttendee(idx) {
          this.details.Attendees.splice(idx,1);
      },
      organizationChange () {
    	for (let i=0; i < this.Data.Organizations.length; i++) {
    		if (this.Data.Organizations[i]['OrganizationID'] == this.details.OrganizationID) {
                if (this.Data.Organizations[i]['DefaultFee']) {
                    this.details.RinkFee = this.Data.Organizations[i]['DefaultFee'];
                }
                if (this.Data.Organizations[i]['HourlyFlat']) {
                    this.details.HourlyFlat = this.Data.Organizations[i]['HourlyFlat'];
                } else {
                    this.details.HourlyFlat = 'f';
                }
			    if (this.Data.Organizations[i]['DefaultColor']) {
				    this.details.color = this.Data.Organizations[i]['DefaultColor'];
			    }
    			break;
		    }
	    }
	  },
	  leagueChange() {
		  this.details.DivisionLevelID = [];
	  },
      seasonChange () {
          this.details.TeamIDs = [];
          this.details.TeamID = '';
      },
	  locationChange(dropdown) {
          if (!this.isConstraints) {
              for (let i = 0; i < this.Data.Locations.length; i++) {
                  if (this.Data.Locations[i]['LocationID'] == this.details.LocationID) {
                      this.GameSetup = (this.Data.Locations[i]['MLParams'] && this.Data.Locations[i]['MLParams']['GameSetup'] ? parseInt(this.Data.Locations[i]['MLParams']['GameSetup']) : 0);
                      this.DefaultCost = (this.Data.Locations[i]['MLParams'] && this.Data.Locations[i]['MLParams']['DefaultCost'] ? parseFloat(this.Data.Locations[i]['MLParams']['DefaultCost']) : 0);
                      if (dropdown == 'y' && this.DefaultCost) {
                          this.details.LocationCost = this.DefaultCost;
                      }
                      break;
                  }
              }
          }
	  },

    cancel()
    {
      this.$emit('cancel', this.getEvent('cancel'));
    },

    updateSchedule(schedule)
    {
      this.schedule = schedule.clone();
      if (this.schedule.year.input) {
		  var month = (this.schedule.month.input - (-1));
		  var day = this.schedule.dayOfMonth.input;
          this.StartDate = this.schedule.year.input + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
      }
      this.tab = 'details';
    },

    updateDetails(details)
    {
      this.details = this.$dayspan.copyEventDetails( details );
      if (this.StartDate) {
          this.details.StartDate = this.StartDate;
          this.details.StartEndDate = [this.StartDate, this.StartDate];
      }
      this.tab = 'details';
    },

    getEvent(type, extra = {})
    {
      return fn.extend({

        type: type,
        day: this.day,
        schedule: this.schedule,
        target: this.targetSchedule,
        details: this.details,
        targetDetails: this.targetDetails,
        calendar: this.calendar,
        calendarEvent: this.calendarEvent,
        handled: false,
        refresh: true,
        create: true,
        added: false,
        $vm: this,
        $element: this.$el

      }, extra);
    }

  },
	mounted() {
      //first if this is popped up without data and does not have create access, then quit

        if (!this.$createAccess() && !this.details.CalendarSlotID) {
            this.$emit('cancel', this.getEvent('cancel'));
        }
        //set proper tab
        this.tabs = 'details';
  	    //we need to check the save details are in our dropdown
  	    this.details.color = this.details.color.toUpperCase();
		if (!this.details.XIce) {
			this.details.XIce = 'b';
		}

		if (!this.details.hasOwnProperty('repeats')) {
			this.details.repeats = '';
		}
  	    //let's see if this is an imported custom color
  	    var colorfound = false;
		for (let i = 0; i < this.colors.length; i++) {
			if (this.details.color == this.colors[i].value) {
				colorfound = true;
				break;
			}
		}
		if (!colorfound) {
			this.colors.push({text : 'Custom', value : this.details.color});
		}
        this.details.prevEventStatus = this.details.EventStatus;
        this.details.OriginalSlotType = this.details.SlotType;
		var found;
        if (this.details.LocationID) {
            found = false;
            for (let i = 0; i < this.Data.Locations.length; i++) {
                if (this.Data.Locations[i]['LocationID'] == this.details.LocationID) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.details.LocationID = '';
            } else {
                this.details.LocationID = this.details.LocationID.toString();
            }
            this.locationChange('n');
        }
        if (this.details.MainLocationID) {
            found = false;
            for (let i = 0; i < this.Data.MainLocations.length; i++) {
                if (this.Data.MainLocations[i]['MainLocationID'] == this.details.MainLocationID) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.details.MainLocationID = '';
            } else {
                this.details.MainLocationID = this.details.MainLocationID.toString();
            }
        }
		if (this.details.ProgramID) {
			found = false;
			for (let i = 0; i < this.Data.Programs.length; i++) {
				if (this.Data.Programs[i]['ProgramID'] == this.details.ProgramID) {
					found = true;
					break;
				}
			}
			if (!found) {
				this.details.ProgramID = '';
            } else {
                this.details.ProgramID = this.details.ProgramID.toString();
            }
		}
		if (this.details.LeagueID) {
			found = false;
			for (let i = 0; i < this.Data.Leagues.length; i++) {
				if (this.Data.Leagues[i]['LeagueID'] == this.details.LeagueID) {
					found = true;
					break;
				}
			}
			if (!found) {
				this.details.LeagueID = '';
			} else {
          this.details.LeagueID = this.details.LeagueID.toString();
      }
		} else {
			if (this.isConstraints) {
				//(!$ProgramIDAccess() && $TeamIDAccess().length == 0) || item.ManageConstraints == 'y'
				//this.details.LeagueID = this.Data.Leagues[0]['LeagueID'];
			}
		}
		if (this.details.OrganizationID) {
			found = false;
			for (let i = 0; i < this.Data.Organizations.length; i++) {
				if (this.Data.Organizations[i]['OrganizationID'] == this.details.OrganizationID) {
					found = true;
					break;
				}
			}
			if (!found) {
				this.details.OrganizationID = '';
			} else {
                this.details.OrganizationID = this.details.OrganizationID.toString();
            }
		}
		if (this.details.ScheduleTypes) {
			var newItems = [];
			for (let i = 0; i < this.Data.ScheduleTypes.length; i++) {
				if (this.details.ScheduleTypes.indexOf(this.Data.ScheduleTypes[i]['ScheduleTypeID']) !== -1) {
					newItems.push(this.Data.ScheduleTypes[i]['ScheduleTypeID']);
				}
			}
			this.details.ScheduleTypes = newItems;
		}
		if (this.details.DivisionTypes) {
			var newItems = [];
			if (this.details.LeagueID && this.details.SeasonID && this.Data.DivisionTypes[this.details.LeagueID] && this.Data.DivisionTypes[this.details.LeagueID][this.details.SeasonID]) {
				for (let i = 0; i < this.Data.DivisionTypes[this.details.LeagueID][this.details.SeasonID].length; i++) {
					if (this.details.DivisionTypes.indexOf(this.Data.DivisionTypes[this.details.LeagueID][this.details.SeasonID][i]['DivisionTypeID']) !== -1) {
						newItems.push(this.Data.DivisionTypes[this.details.LeagueID][this.details.SeasonID][i]['DivisionTypeID']);
					}
				}
			}
			this.details.DivisionTypes = newItems;
		}
		if (!this.details.HourlyFlat) {
			this.details.HourlyFlat = 'f';
		}
	}
}
</script>
<style>

	.ds-event .el-select .el-input__inner, .ds-event .el-date-editor--date .el-input__inner, .ds-event .el-date-editor--daterange.el-input__inner, .ds-event .el-date-editor--daterange.el-input__inner input {
		border-radius: 5px;
		height : 40px;
		color: #000;
		font-size: 16px;
		min-height: 40px;
	}
	.ds-event .eventDetailsColor .el-select .el-input__inner, .ds-event .eventDetailsColor .el-select .el-input .el-select__caret {
		color: #FFFFFF;
	}
	.ds-event .eventDetailsColor .el-select.Custom .el-input__inner, .ds-event .eventDetailsColor .el-select.Custom  .el-input .el-select__caret {
		color: #000000;
	}
	.ds-event .el-select .el-input .el-select__caret {
		color: #000;
	}
	#app .el-select.multiple input {
		min-height: 50px;
	}
	#app .el-select.eventDropdown {
		width: 100%;
	}
	#app .ds-event .el-date-editor, #app .ds-event .repeats {
		width: 400px;
	}
	#app .ds-calendar-app .ds-event .eventDetailsColor .el-select {
		width: 100%;
	}
	div.disconnect {
		float:right;
		width: 43%
	}
	.ds-event .eventDetailsColor .White .el-input__wrapper { color: #ffffff; background-color: #FFFFFF; box-shadow: none;}
	.ds-event .eventDetailsColor .Red .el-input__wrapper { color: #ffffff; background-color: #F44336; box-shadow: none; }
	.ds-event .eventDetailsColor .Pink .el-input__wrapper { color: #ffffff; background-color: #E91E63; box-shadow: none; }
	.ds-event .eventDetailsColor .Purple .el-input__wrapper { color: #ffffff; background-color: #9C27B0; box-shadow: none; }
	.ds-event .eventDetailsColor .Deep_Purple .el-input__wrapper { color: #ffffff; background-color: #673AB7; box-shadow: none; }
	.ds-event .eventDetailsColor .Indigo .el-input__wrapper { color: #ffffff; background-color: #3F51B5; box-shadow: none; }
	.ds-event .eventDetailsColor .Blue .el-input__wrapper { color: #ffffff; background-color: #2196F3; box-shadow: none; }
	.ds-event .eventDetailsColor .Highlight_Color .el-input__wrapper { color: #ffffff; background-color: #1976d2; box-shadow: none; }
	.ds-event .eventDetailsColor .Light_Blue .el-input__wrapper { color: #ffffff; background-color:  #03A9F4; box-shadow: none; }
	.ds-event .eventDetailsColor .Cyan .el-input__wrapper { color: #ffffff; background-color:  #00BCD4; box-shadow: none; }
	.ds-event .eventDetailsColor .Teal .el-input__wrapper { color: #ffffff; background-color:  #009688; box-shadow: none; }
	.ds-event .eventDetailsColor .Green .el-input__wrapper { color: #ffffff; background-color:  #4CAF50; box-shadow: none; }
	.ds-event .eventDetailsColor .Light_Green .el-input__wrapper { color: #ffffff; background-color:  #8BC34A; box-shadow: none; }
	.ds-event .eventDetailsColor .Lime .el-input__wrapper { color: #ffffff; background-color:  #CDDC39; box-shadow: none; }
	.ds-event .eventDetailsColor .Yellow .el-input__wrapper { color: #ffffff; background-color:  #FFEB3B; box-shadow: none; }
	.ds-event .eventDetailsColor .Amber .el-input__wrapper { color: #ffffff; background-color:  #FFC107; box-shadow: none; }
	.ds-event .eventDetailsColor .Orange .el-input__wrapper { color: #ffffff; background-color:  #FF9800; box-shadow: none; }
	.ds-event .eventDetailsColor .Deep_Orange .el-input__wrapper { color: #ffffff; background-color:  #FF5722; box-shadow: none; }
	.ds-event .eventDetailsColor .Brown .el-input__wrapper { color: #ffffff; background-color:  #795548; box-shadow: none; }
	.ds-event .eventDetailsColor .Blue_Gray .el-input__wrapper { color: #ffffff; background-color:  #607D8B; box-shadow: none; }
	.ds-event .eventDetailsColor .Gray .el-input__wrapper { color: #ffffff; background-color:  #9E9E9E; box-shadow: none; }
	.ds-event .eventDetailsColor .Black .el-input__wrapper { color: #ffffff; background-color:  #000000; box-shadow: none; }
</style>
<style scoped lang="scss">

	.ds-calendar-event-title {
		font-size: 18px;
		padding-right: 8px;
		padding-top: 4px;
        width: 75%;
	}

	.ds-color-option {
		width: 100%;
		color: white;
		padding: 4px;
	}

	.ds-button-tall {
		height: 35px;
	}

	.ds-event {

		&.ds-has-cancel {

			.ds-event-area {
				margin-left: 60px;
			}
		}

		&.ds-event-small {

			&.ds-has-cancel {

				.ds-event-area {
					margin-left: 0px;
				}
				.ds-event-header {
					margin-left: 60px;
				}
			}

			.ds-event-body {
				clear: both;
			}
		}

		.ds-event-area {
			position: relative;
		}

		.ds-event-header {
			min-height: 47px;
			width: 400px;
		}

		.ds-event-cancel {
			position: absolute;
			left: -60px;
		}

		.v-input {
			margin-bottom: 8px;
		}
		.el-input, .el-select {
			width: 50%;
		}
		.multiple.el-select {
			width: 100%;
		}
		.v-list__tile__avatar {
			display: inline-block;
		}
		.v-list__tile__action, .v-list__tile__avatar {
			min-width: 25px;
		}
		.v-avatar {
			vertical-align: text-bottom;
			width: 30px      !important;
		}
		.v-list__tile__content {
			width: 95%;
			display: inline-block;
		}
	}
	.fieldicon {
		float: left;
		margin-top: 7px;
		font-size: 18px;
	}
	.field {
		width: 650px;
		margin-left: 42px;
	}
	#app.constraints .field {
		width: 500px;
	}
	.ds-event-header .ds-event-title {
		width: 100%;
	}
	.ds-event .color.White i {
		color: #606266;
	}

	.ds-event .White i { color: #FFFFFF; }
	.ds-event .Red i { color: #F44336; }
	.ds-event .Pink i { color: #E91E63; }
	.ds-event .Purple i { color: #9C27B0; }
	.ds-event .Deep_Purple i { color: #673AB7; }
	.ds-event .Indigo i { color: #3F51B5; }
	.ds-event .Blue i { color: #2196F3; }
	.ds-event .Highlight_Color i { color: #1976d2; }
	.ds-event .Light_Blue i { color:  #03A9F4; }
	.ds-event .Cyan i { color:  #00BCD4; }
	.ds-event .Teal i { color:  #009688; }
	.ds-event .Green i { color:  #4CAF50; }
	.ds-event .Light_Green i { color:  #8BC34A; }
	.ds-event .Lime i { color:  #CDDC39; }
	.ds-event .Yellow i { color:  #FFEB3B; }
	.ds-event .Amber i { color:  #FFC107; }
	.ds-event .Orange i { color:  #FF9800; }
	.ds-event .Deep_Orange i { color:  #FF5722; }
	.ds-event .Brown i { color:  #795548; }
	.ds-event .Blue_Gray i { color:  #607D8B; }
	.ds-event .Gray i { color:  #9E9E9E; }
	.ds-event .Black i { color:  #000000; }

</style>
