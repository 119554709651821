export default {
	items: [
		{
			name: 'Analyzer Software <i style="line-height: 30px;" class="fas fa-arrow-alt-circle-right"></i>',
			url: '/analyzer/',
			security: true,
			icon: 'fas fa-laptop-code'
		},
		{
			name: 'Dashboard',
			url: '/dashboard',
			security: false,
			icon: 'fas fa-tachometer-alt'
		},
        {
            name: 'Analyzer',
            icon: 'fas fa-laptop-code',
            children: [
                {
                    name: 'Location Calendar',
                    url: '/locationcalendar',
                    security: true,
                    icon: 'far fa-clock'
                }
            ]
        },
		{
			name: 'Reports',
			icon: 'far fa-file-alt',
			children: [
				{
					name: 'Form Submissions',
					url: '/formsubmissionreport',
					security: true,
					icon: 'fab fa-wpforms'
				},
				{
					name: 'GAME Cancellations',
					url: '/gamecancellationreport',
					security: true,
					icon: 'fas fa-edit'
				},
                {
                    name: 'Facility Usage',
                    url: '/facilityusage',
                    security: true,
                    icon: 'far fa-calendar-times'
                },
                {
                    name: 'Forfeit Report',
                    url: '/forfeitreport',
                    security: true,
                    icon: 'fas fa-ban',
                },
                {
                    name: 'Event Attendance',
                    url: '/eventattendance',
                    security: true,
                    icon: 'fas fa-paste'
                },
                {
                    name: 'Report Writer',
                    url: '/reportwriter',
                    security: true,
                    icon: 'fas fa-pencil-ruler'
                },
                {
                    name: 'Universal Search',
                    url: '/search',
                    security: true,
                    icon: 'fas fa-search'
                },
			]
		},
		{
			name: 'Finances',
			icon: 'fas fa-file-invoice-dollar',
			children: [
				{
					name: 'Dashboard',
					url: '/financedashboard',
					security: true,
					icon: 'fas fa-tachometer-alt'
				},
				{
					name: 'Email Statements',
					url: '/emailstatements',
					security: true,
					icon: 'fa-solid fa-file-contract'
				},
				{
					name: 'Generate Invoices',
					url: '/generateinvoices',
					security: true,
					icon: 'fas fa-calculator'
				},
				{
					name: 'Invoices',
					url: '/invoices',
					security: true,
					icon: 'fas fa-file-invoice',
				},
                {
                    name: 'Payments',
                    url: '/invoicepayments',
                    security: true,
                    icon: 'fas fa-money-check-alt'
                },
				{
					name: 'Adjustments',
					url: '/adjustments',
					security: true,
					icon: 'fas fa-file-invoice-dollar'
				},
                {
                    name: 'Purchase Orders',
                    url: '/purchaseorder',
                    security: true,
                    icon: 'fas fa-shopping-basket'
                },
                {
                    name: 'Player League Balances',
                    url: '/playerleaguebalances',
                    security: true,
                    icon: 'fas fa-file-invoice-dollar'
                },
                {
                    name: 'Player Payments',
                    url: '/teamplayerpayments',
                    security: true,
                    icon: 'fas fa-file-invoice-dollar'
                },
                {
                    name: 'Season Audit Report',
                    url: '/teamsbillingreport',
                    security: true,
                    icon: 'fas fa-file-alt',
                },
                {
                    name: 'Balances',
                    url: '/invoicebalances',
                    security: true,
                    icon: 'fas fa-balance-scale'
                },
                {
                    name: 'Bank Accounts',
                    url: '/bankaccounts',
                    security: true,
                    icon: 'fas fa-university'
                },
                {
                    name: 'Billing Accounts',
                    url: '/billingaccounts',
                    security: true,
                    icon: 'fas fa-cog'
                },
				{
					name: 'Organizations',
					url: '/organizations',
					security: true,
					icon: 'fas fa-building',
				},
			]
		},
		{
			name: 'Admin Functions',
			icon: 'fa fa-puzzle-piece',
			children: [
				{
					name: 'Settings',
					url: '/settings',
					security: true,
					icon: 'fas fa-wrench'
				},
				{
					name: 'Help Text',
					url: '/helptext',
					security: true,
					icon: 'fas fa-question-circle'
				},
				{
					name: 'System Text',
					url: '/systemtext',
					security: true,
					icon: 'fas fa-file-alt'
				},
				{
					name: 'Email/SMS/Export',
					url: '/emailcampaign',
					security: true,
					icon: 'fa fa-envelope'
				},
				{
					name: 'Form Builder',
					url: '/formdefinitions',
					security: true,
					icon: 'fab fa-wpforms'
				},
				{
					name: 'LiveBarn LOCATION_S',
					url: '/livebarnlocations',
					security: true,
					icon: 'fas fa-map-marked',
				},
				{
					name: 'Login Roles',
					url: '/loginroles',
					security: true,
					icon: 'fas fa-lock'
				},
                {
                    name: 'PROGRAM LOCATION_S',
                    url: '/programlocations',
                    security: true,
                    icon: 'fas fa-map',
                },
                {
                    name: 'Player Suspensions',
                    url: '/suspensions',
                    security: true,
                    icon: 'fas fa-user-times',
                },
                {
                    name: 'Security Areas',
                    url: '/securityareas',
                    security: true,
                    icon: 'fa-solid fa-location-pin-lock'
                },
                {
					name: 'Super User Password',
					url: '/superuserpassword',
					security: true,
					icon: 'fas fa-lock-open'
				},
                {
                    name: 'User Logins',
                    url: '/users',
                    security: true,
                    icon: 'fas fa-user',
                },
				{
					name: 'Widget Builder',
					url: '/widgetbuilder',
					security: true,
					icon: 'fas fa-code'
				},
                {
                    name: 'Widget Formats',
                    url: '/widgetformats',
                    security: true,
                    icon: 'fas fa-palette'
                },
				{
					name: 'Customer Dashboard',
					url: '/customerdashboard',
					security: true,
					icon: 'fas fa-tachometer-alt'
				},
				{
                    name: 'Features',
                    url: '/features',
                    security: true,
                    icon: 'fa-solid fa-clipboard-check'
                },
				{
                    name: 'Plans',
                    url: '/plans',
                    security: true,
                    icon: 'fa-solid fa-list-check'
                },
			]
		},
        {
            name: 'Team Registration',
            url: '/programteamregistration',
            security: true,
            icon: 'fas fa-registered',
        },
        {
            name: 'Teams Report',
            url: '/teamsreport',
            security: true,
            icon: 'fas fa-file-alt',
        },

		{
			name: 'GAME Reporting',
			url: '/gamereporting',
			security: true,
			icon: 'fas fa-edit',

		},
		{
			name: 'Mite GAME Reporting',
			url: '/mitegamereporting',
			security: true,
			icon: 'fas fa-edit',

		},
		{
			name: 'Mobile GAME Reporting',
			url: '/mobilegamereporting',
			security: true,
			icon: 'fas fa-tablet-alt',

		},
        {
            name: 'Mite Mobile GAME Reporting',
            url: '/mitemobilegamereporting',
            security: true,
            icon: 'fas fa-tablet-alt',
        },
        {
            name: 'Full GAME Reporting',
            url: '/gamesheets',
            security: true,
            icon: 'fas fa-table',
        },
		{
			name: 'Site Contacts',
			url: '/sitecontacts',
			security: true,
			icon: 'fas fa-user-tag',
		},
        {
            name: 'PROGRAM Contacts',
            url: '/programcontacts',
            security: true,
            icon: 'fas fa-user-cog',
        },
        {
            name: 'Team Contacts',
            url: '/teamcontacts',
            security: true,
            icon: 'fas fa-user-cog',
        },
		{
			name: 'PROGRAM Players',
			url: '/programplayers',
			security: true,
			icon: 'fas fa-child',
		},
		{
			name: 'Assign GAME_S',
			url: '/assigngames',
			security: true,
			icon: 'fas fa-arrow-alt-circle-right',
		},
		{
			name: 'GAME Cancellation',
			url: '/gamecancellation',
			security: true,
			icon: 'fas fa-ban',
		},
		{
			name: 'Team Blackouts',
			url: '/teamblackouts',
			security: true,
			icon: 'fa-solid fa-calendar-xmark',
		},
	]
}
