<template>
	<div id="loginselect" class="app">
		<div class="text" v-html="RoleSelectText"></div>
		<template v-for="(item,index) in Roles">
			<template v-if="item.ContactRoleID" >
				<div class="rolerow" :class="{'active' : activeRole == index}" @click="roleselected(index)" ><div class="image"><img class="leaguelogo" :src="(item.LeagueParams.LeagueSmallLogo ? item.LeagueParams.LeagueSmallLogo : config.Params.SmallLogo)" alt="Logo" /></div><div class="leaguerole"><div class="leaguename" v-html="item.LeagueShortName + ' - ' + item.ProgramName"></div><br><div class="contactrole" v-html="item.ContactTypeName"></div></div></div>
			</template>
			<template v-else >
				<div class="rolerow" :class="{'active' : activeRole == index}" @click="roleselected(index)" ><div class="image"><img class="leaguelogo" :src="config.Params.SmallLogo" alt="Logo" /></div><div class="adminrole" v-html="item.RoleName"></div></div>
			</template>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Util from '../assets/js/util.js';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
export default {
	name: 'LoginSelect',
	data() {
		return {
			currentStatus : STATUS_INITIAL,

		}
	},
	props:["config", "parentrefs", "RoleSelectText", "Roles", "activeRole"],

	methods: {
		roleselected(index) {
			if
			(index != this.activeRole) {
				this.$emit('roleselected', index);
			}
		}
	}
}
</script>

<style scoped>
div.text {
	font-weight: 800;
	font-size: 16px;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 25px;
}
div.rolerow {
	min-height: 51px;
	padding-top: 5px;
	border-bottom: 1px solid #cccccc;
	cursor:pointer;
}
div.rolerow:Hover {
	background-color: #f0f0f0;
}
div.rolerow.active {
	background-color: #FFFFE0;
	cursor:default;
}
img.leaguelogo {
	height: 35px;
	margin-top: 3px;
	max-width: 70px;
}
div.image {
	position: absolute;
	float:left;
	width: 75px;
	margin-left: 3px;
}
div.adminrole {
	margin-left: 90px;
	line-height: 40px;
	margin-right: 10px;
	font-size: 14px;
	font-weight: 800;
}
div.leaguerole {
	margin-left: 90px;
	line-height: 10px;
	margin-right: 10px;
	padding-top: 5px;
}
div.leaguename {
	font-size: 14px;
	font-weight: 800;
}
div.contactrole {
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
}
</style>
