<template>

  <div
    class="ds-calendar-event"
    :class="contentClass"
    :style="style"
    v-bind="popoverProps">

    <div
      class="ds-calendar-event-span"
      @click="editCheck"
      @mouseenter="mouseEnterEvent"
      @mouseleave="mouseLeaveEvent"
      @mousedown="mouseDownEvent"
      @mouseup="mouseUpEvent">

      <span v-if="showName">
        <slot name="eventTimeTitle">
          <div>
            <span class="ds-ev-title">{{ detailsTitle }}</span>
          </div>
          <div class="ds-ev-description">{{ getCalendarTime }} {{ detailsDescription }}</div>
        </slot>
      </span>
      <span v-else>
        <slot name="eventTimeEmpty" v-bind="{calendarEvent, details}"></slot>
      </span>
    </div>
  </div>
</template>

<script>
import { Day, Constants, CalendarEvent, Units, Calendar, Functions as fn } from 'dayspan';

export default {

  name: 'dsCalendarEventTime',

  props:
  {
    calendarEvent:
    {
      required: true,
      type: CalendarEvent
    },
	config:
	  {
		  type: Object,
		  default() {
			  {};
		  }
	  },
    calendar:
    {
      required: true,
      type: Calendar
    },

    popoverProps:
    {
      validate(x) {
        return this.$dsValidate(x, 'popoverProps');
      },
      default() {
        return {
		        nudgeWidth: 200,
			      closeOnContentClick: false,
			      offsetOverflow: true,
			      offsetX: true,
			      maxWidth: 500
	      };
      }
    },

    isPlaceholderWithDay:
    {
      type: Day
    },
    locationid:
    {
      type: Number,
      default: 0
    },
    filterlocationids:
    {
      type: Array,
      default: []
    },
  },

  computed:
  {
    style()
    {
      let style =  (this.isPlaceholderWithDay ?
        this.$dayspan.getStylePlaceholderTimed( this.details, this.calendarEvent, this.isPlaceholderWithDay ) :
        this.$dayspan.getStyleTimed( this.details, this.calendarEvent ));
        style.minHeight = style.height;
        var widths = this.locationwidth (this.details.LocationID).split('-');
        
        if (widths[1] == 1 || widths[1] == 1 > 3) {
          style.left = '0%';
          style.width = '100%';
        } else if (widths[1] == 2) {
            if (widths[0] == 1) {
              style.left = '0%';
              style.width = '50%';
            } else {
              style.left = '50%';
              style.width = '50%';
            }
        }  else if (widths[1] == 3) {
            if (widths[0] == 1) {
              style.left = '0%';
              style.width = '33%';
            } else if (widths[0] == 2) {
              style.left = '33%';
              style.width = '33%';
            } else {
              style.left = '66%';
              style.width = '33%';
            }
          }

      if (this.locationid > 0) {
        if (this.locationid != this.calendarEvent.event.data.LocationID) {
          style["opacity"] = '0';
          style["zIndex"] = '-1';
          style["z-index"] = '-1';
          style["cursor"] = 'default';
        } else {
          style["opacity"] = '1';
          style["cursor"] = 'pointer';
        }
      } else {
        style["opacity"] = '1';
        style["cursor"] = 'pointer';
      }

      return style;
    },
	isConstraints () {
	    return (this.$route.fullPath.indexOf('constraint') !== -1 ? true : false);
	},
	detailsTitle () {
      var slottype = '';
          if (!this.isConstraints) {
            if (!this.details.readOnly) {
              slottype = (this.details.SlotType == 'g' ? 'Game' : (this.details.SlotType == 'e' ? '3rd Party' : (this.details.SlotType == 'p' ? 'Practice' : 'Open')));
            }
          }

		  if (this.details.title) {
			  var title = this.details.title.replace(/open/ig, '');
		  } else {
			  var title = '';
		  }

		  if (title) {
			  if (this.isConstraints) {
				  return title;
			  } else {
				  return slottype + ' - ' + title;
			  }
		  } else {
			  if (this.isConstraints) {
				  return ((this.details.TeamName ? this.details.TeamName : (this.details.ProgramName ? this.details.ProgramName : (this.details.LeagueName ? this.details.LeagueName : (this.details.OrganizationName ? this.details.OrganizationName : this.details.LocationName)))));
			  } else {
				  return slottype + ' - ' + ((this.details.TeamName ? this.details.TeamName : (this.details.OrganizationName ? this.details.OrganizationName : this.details.LocationName)));
			  }
		  }
	  },
	  detailsDescription () {
		  var description = '';
		  if (this.details.title) {
			  if (this.isConstraints) {
				  description =  ((this.details.TeamName ? this.details.TeamName : (this.details.ProgramName ? this.details.ProgramName : (this.details.LeagueName ? this.details.LeagueName : (this.details.OrganizationName ? this.details.OrganizationName : this.details.LocationName)))));
			  } else {
				  description =  ((this.details.TeamName ? this.details.TeamName : (this.details.OrganizationName ? this.details.OrganizationName : this.details.LocationName)));
			  }
		  } else {
			  description = this.details.description
		  }
		  return (description ? ' - ' + description : '');
	  },
	  getCalendarTime() {
		  if (this.calendarEvent.data.CalendarSlotID) {
			  return this.getOldCalendarTime(this.calendarEvent);
		  }
		  //first time array is not offset properly, all afterwards are by 1 hour...so correct
		  //console.log(this.calendarEvent);
		  //console.log(this.calendarEvent.schedule.times[0].hour + ' : ' + this.calendarEvent.schedule.times[0].hour + ' - ' + this.calendarEvent.schedule.duration + ' ' + this.calendarEvent.schedule.durationUnit);
		  if (!this.calendarEvent.data.CalendarSlotID && !this.calendarEvent.data.ConstraintSlotID) {
			  var startminute = parseInt((this.config.Params.calendarStartHour ? this.config.Params.calendarStartHour * 60 : 0));
			  var ratio = 4 / 5;
		  } else {
			  var startminute = 0 ;
			  var ratio = 1;
		  }

		  var hour = this.calendarEvent.schedule.times[0].hour;
		  var minute = this.calendarEvent.schedule.times[0].minute;

		  var minutes = (minute + (hour * 60)) * ratio;
		  var realminutes = minutes + startminute;


		  hour = parseInt(realminutes / 60);
		  minute = realminutes - (hour * 60);
		  minute = (minute % 5) >= 2.5 ? parseInt(minute / 5) * 5 + 5 : parseInt(minute / 5) * 5;
		  if (minute >= 60) {
			  hour += 1;
			  minute -= 60;
		  }
		  minute = minute + '';
		  minute = minute.padStart(2, '0');
		  let sh = (hour > 12 ? hour - 12 : hour) + ':' + minute;

		  var duration = this.calendarEvent.schedule.duration * (this.calendarEvent.schedule.durationUnit == 'hours' ? 60 : 1) * ratio;
		  duration = (duration % 5) >= 2.5 ? parseInt(duration / 5) * 5 + 5 : parseInt(duration / 5) * 5;
		  realminutes += duration;
		  hour = parseInt(realminutes / 60);
		  minute = realminutes - (hour * 60);
		  minute = (minute % 5) >= 2.5 ? parseInt(minute / 5) * 5 + 5 : parseInt(minute / 5) * 5;
		  if (minute >= 60) {
			  hour += 1;
			  minute -= 60;
		  }
		  minute = minute + '';
		  minute = minute.padStart(2, '0');
		  let eh = (hour > 12 ? hour - 12 : hour) + ':' + minute;

		  return sh + ' - ' + eh;

	  },
    showName()
    {
      return this.calendarEvent.starting || (
        this.calendar &&
        !this.calendar.span.contains( this.calendarEvent.time.start )
      );
    },

    hasPopover()
    {
      if (this.locationid > 0) {
        if (this.locationid != this.calendarEvent.event.data.LocationID) {
          return false;
        }
      }
      return false;
    },

    hasIcon()
    {
      return !!(this.$dayspan.supports.icon && this.details.icon);
    },

    contentClass()
    {
      return (this.$dayspan.fullscreenPopovers ? 'ds-fullscreen' : '') + ' ' + (this.type == 0 ? 'day' : 'week');
    },

    details()
    {
      return this.calendarEvent.event.data;
    },
	type()
	{
		return this.calendar ? this.calendar.type : null;
	},
	isDay()
	{
		return this.type === Units.DAY;
	},

	isWeek()
	{
		return this.type === Units.WEEK;
	},

	isMonth()
	{
		return this.type === Units.MONTH;
	},
  },

  data: vm => ({
    menu: false
  }),

  methods:
  {
    locationwidth (locationid) {
					if (this.locationid > 0) {
						return '1-1';
					} else {
						for (let i = 0; i < this.filterlocationids.length; i++) {
							if (this.filterlocationids[i] == parseInt(locationid)) {
								return (i - (-1)) + '-' + this.filterlocationids.length;
							}
						}
						return '1-1';
					}
				},
	  getOldCalendarTime(calendarEvent)
	  {
		  let sa = calendarEvent.start.format('a');
		  let ea = calendarEvent.end.format('a');
		  let sh = calendarEvent.start.format('h');
		  let eh = calendarEvent.end.format('h');
		  if (calendarEvent.start.minute !== 0)
		  {
			  sh += calendarEvent.start.format(':mm');
		  }
		  if (calendarEvent.end.minute !== 0)
		  {
			  eh += calendarEvent.end.format(':mm');
		  }
		  return (sa === ea) ? (sh + ' - ' + eh + ea) : (sh + sa + ' - ' + eh + ea);
	  },
    close()
    {
      this.menu = false;
    },

    edit()
    {
      if (this.handlesEvents())
      {
        this.$emit('edit', this.calendarEvent);
      }
    },

    editCheck($event)
    {
      if (this.handlesEvents($event))
      {
        if (!this.hasPopover)
        {
          this.edit()
        }
        else
        {
          this.menu = !this.menu;
        }
      }
    },

    mouseEnterEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-enter-event', this.getEvent('mouse-enter-event', $event));
      }
    },

    mouseLeaveEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-leave-event', this.getEvent('mouse-leave-event', $event));
      }
    },

    mouseDownEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-down-event', this.getEvent('mouse-down-event', $event));
      }
    },

    mouseUpEvent($event)
    {
      if (this.handlesEvents($event))
      {
        this.$emit('mouse-up-event', this.getEvent('mouse-up-event', $event));
      }
    },

    handlesEvents($event)
    {
      var handles = !this.isPlaceholderWithDay;

      if (handles && $event)
      {
        $event.stopPropagation();
      }

      return handles;
    },

    getEvent(type, $event, extra = {})
    {
      return fn.extend({

        type: type,
        calendarEvent: this.calendarEvent,
        calendar: this.calendar,
        details: this.details,
        offset: this.getRelativeTimeAt( $event ),
        left: $event.button === 0,
        right: $event.button === 1,
        handled: false,
        $event: $event,
        $vm: this,
        $element: this.$el

      }, extra);
    },

    getRelativeTimeAt($event)
    {
      var bounds = this.$el.getBoundingClientRect();
      var y = ($event.clientY - bounds.y) / this.$dayspan.dayHeight;
      var millis = y * Constants.MILLIS_IN_DAY;

      return millis;
    }
  }
}
</script>

<style scoped lang="scss">

.ds-calendar-event-menu {
  width: 100%;
}

.ds-calendar-event {
  margin: 1px;
  color: white;
  overflow: hidden;
  padding-left: 0.5em;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  user-select: none;
  border-radius: 4px;
  pointer-events: none;
  border: 1px solid #FFFFFF;
  line-height: 13px;
  font-size: 12px;

  .v-menu__activator {
    align-items: end;
  }

  .ds-calendar-event-span {
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
}
.ds-calendar-event .ds-calendar-event-span .ds-ev-title {
    text-overflow: ellipsis;
    cursor: pointer;
    word-break: break-all;
    overflow:hidden;
    white-space: nowrap;
  }
  .ds-calendar-event .ds-calendar-event-span .ds-ev-title:hover {
    overflow: visible; 
    white-space: normal;
    height:auto    !important;  
  }
  div:not(.constraints) .ds-calendar-event:hover {
    height: auto      !important;
    z-index: 999;
  }
div.constraints .ds-calendar-event:hover {
    z-index: 999;
  }
</style>
