<template>
	<div id="invoices" v-loading="loading">
		<div class="row ">
			<div class="col-12 fullpage">
				<FinanceDropdowns @dropdownsChange="dropdownsChange" :config="config" :parentrefs="parentrefs" :calling="'Invoices'" />
				<el-form ref="Invoices" label-position="top" >
					<div v-if="!ProgramIDAccess" class="mt-2">
						<el-form-item label="Date Range:" prop="invoicedates" style="width: 350px;">
							<el-date-picker
									v-model="invoicedates"
									type="daterange"
									unlink-panels
									format="MMM D, YYYY"
									@change="getInvoices()"
									range-separator="To"
									start-placeholder="Start date"
									end-placeholder="End date"
									value-format="YYYY-MM-DD">
							</el-date-picker>
						</el-form-item>
					</div>
					<div v-if="modifyAccess" style="width: 100%;border-top: 1px solid #ccc;" class="mt-4 pt-2">
						<div><strong>Complete these fields when emailing Invoices.</strong></div>
						<EmailTemplate calling="invoices" v-model="SystemText" BillingType="" :config="config" width="75%" />
						<div v-if="SystemText" style="width: 100%;">
							<div style="width:100%;">
								<el-checkbox v-model="EmailStatements" false-label="n" true-label="y" ><strong>Include Statement in Email</strong></el-checkbox>
							</div>
							<el-button type="success" @click="emailAllInvoices">
								<i class="far fa-envelope"></i>&nbsp;<span> Email All Selected</span>
							</el-button>
							<el-button type="success" style="margin-left: 20px;margin-top:0;" @click="printAllInvoices" >
								<i class="fas fa-file-download"></i>&nbsp;<span> Download All Invoices</span>
							</el-button>
						</div>
					</div>
				</el-form>
			</div>
		</div>
        <div class="row ">
	        <div class="col-12 mt-4 mb-4 fullpage">
                <el-input
                        class="search"
                        v-model="search"
                        clearable
                        placeholder="Type to search"/>
                <el-table
                        :data="filteredRecords"
                        stripe border
                        ref="invoicetable"
                        empty-text="No Invoices"
                        size="small"
                        @selection-change="handleSelectionChange"
						:row-class-name="tableRowClassName"
                        style="width: 100%">
                    <el-table-column type="expand">
                        <template #default="props">
							<div style="max-width: 915px;">
								<span v-if="props.row.InvoiceType == 'TR'">
									<div class="row detailheader">
										<div class="col-1">No.</div>
										<div class="col-3">Team</div>
										<div class="col-2" style="text-align:center;">Games</div>
										<div class="col-3">Notes</div>
										<div class="col-3" style="text-align:center;">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
										<div class="col-1" style="line-height: 35px;" v-html="(index+1)"></div>
										<div class="col-3" style="line-height: 35px;">{{item.TeamName}}</div>
										<div class="col-2" style="line-height: 35px;text-align:center;">{{item.RegisteredGames}}</div>
										<div class="col-3" style="line-height: 35px;">{{item.RegisteredNotes}}</div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="formatPrice((item.EventType == 'Balance' ? item.Balance : item.DetailAmount))"></div>
									</div>
								</span>
								<span v-if="props.row.InvoiceType == 'IU'">
									<div class="row detailheader">
										<div class="col-2">Date</div>
										<div class="col-2">Time</div>
										<div class="col-3">Location</div>
										<div class="col-3">Description</div>
										<div class="col-2">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
										<div style="line-height: 35px;" class="col-2">{{item.StartDate}}</div>
										<div style="line-height: 35px;" class="col-2" v-html="(item.type != 'e' ? '' : (item.StartTime ? item.StartTime + ' - ' + item.EndTime : 'All Day'))"></div>
										<div style="line-height: 35px;" class="col-3">{{item.LocationName}}</div>
										<div style="line-height: 35px;" class="col-3">{{(item.type == 'c' ? 'Credit - ' : '') + item.Title}}</div>
										<div style="line-height: 35px;" class="col-2" v-html="formatPrice((item.type == 'x' && item.EventType == 'Balance' ? item.Balance : item.DetailAmount))"></div>
									</div>
								</span>
								<span v-if="props.row.InvoiceType == 'BG'">
									<div class="row detailheader">
										<div class="col-1">No.</div>
										<div class="col-3">Desription</div>
										<div class="col-2" style="text-align:center;">Qty</div>
										<div class="col-3" style="text-align:center;">Rate</div>
										<div class="col-3" style="text-align:center;">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0}">
										<div class="col-1" style="line-height: 35px;" v-html="(index+1)"></div>
										<div class="col-3" style="line-height: 35px;">{{item.TeamName}}</div>
										<div class="col-2" style="line-height: 35px;text-align:center;">{{(item.EventType == 'Balance' ? '' : (item.ToBill == 0 ? 'ALL' : item.ToBill))}}</div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="(item.EventType == 'Balance' ? '' : formatPrice(item.DefaultGameFee))"></div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="formatPrice((item.EventType == 'Balance' ? item.Balance : item.DetailAmount))"></div>
									</div>
								</span>
								<span v-if="props.row.InvoiceType == 'MI'">
									<div class="row detailheader">
										<div class="col-1">No.</div>
										<div class="col-8">Description</div>
										<div class="col-3" style="text-align:center;">Amount</div>
									</div>
									<div class="row" v-for="(item, index) in props.row.Details" :class="{even : index % 2 == 0, pastDue : item.TeamName == 'Past Due'}">
										<div class="col-1" style="line-height: 35px;" v-html="(index+1)"></div>
										<div class="col-8" style="line-height: 35px;" v-html="(item.TeamID ? item.TeamName + ' - ' : '') + item.Description"></div>
										<div class="col-3" style="line-height: 35px;text-align:center;" v-html="formatPrice(item.DetailAmount)"></div>
									</div>
								</span>
							</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="modifyAccess"
                            type="selection"
                            align="center"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            prop="InvoiceNumber"
                            label="#"
                            width="130px"
                            sortable
                            align="center"
                    >
                        <template #default="scope">
                            <span v-if="!modifyAccess || scope.row.InvoiceStatus == 'Void'">
                                <div v-html="(scope.row.LeagueChar ? scope.row.LeagueChar : '') + scope.row.InvoiceNumber"></div>
                            </span>
                            <span v-else>
                                <span><strong>{{(scope.row.LeagueChar ? scope.row.LeagueChar : '')}}&nbsp;</strong></span>
                                <input type="text" class="invoiceNumber" v-model="scope.row.InvoiceNumber" @change="updateInvoiceData(scope.row.InvoiceID)" />
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="InvoiceDate"
                            label="Date"
                            width="130px"
                            sortable
                            :sort-method="sortDate"
                    >
                    </el-table-column>
                    <el-table-column
					v-if="OrganizationID == ''"
                        prop="OrganizationName"
                        label="Organization"
                        sortable
                        min-width="150">
                    </el-table-column>
                    <el-table-column
                        prop="LeagueName"
                        label="League"
                        sortable
                        min-width="150">
                    </el-table-column>
					<el-table-column
                            prop="Description"
                            label="Description"
                            sortable
                            min-width="150px"
                    >
                        <template #default="scope">
                            <span v-if="!modifyAccess || scope.row.InvoiceStatus == 'Void'">
                                <div v-html="scope.row.Description"></div>
                            </span>
                            <span v-else>
                                <input type="text" class="description" v-model="scope.row.Description" @change="updateInvoiceData(scope.row.InvoiceID)" />
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="!ProgramIDAccess"
                        prop="BillingName"
                        label="Account"
                        sortable
                        min-width="150">
                    </el-table-column>
                    <el-table-column
                            prop="Amount"
                            label="Total"
                            align="right"
                            sortable
                            :sort-method="sortAmount"
                            width="100px">
                        <template #default="scope">
                            <div v-html="formatPrice(scope.row.Amount)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="modifyAccess"
                            prop="InvoiceStatus"
                            label="Status"
                            :filters="[{ text: 'Active', value: 'Pending' }, { text: 'Void', value: 'Void' }]"
                            :filter-method="filterStatus"
                            filter-placement="bottom-end"
                            :filtered-value="['Pending','Void']"
                            align="center"
                            width="100px">
                        <template #default="scope">
                            <div v-if="scope.row.InvoiceStatus != 'Void'">Active</div>
                            <span v-if="scope.row.InvoiceStatus == 'Void'" class="paid"><el-button style="cursor:default; height: 25px;" type="danger" class="btn-danger" :disabled="true" size="small" plain>Voided</el-button></span>
                            <span v-else>
                                <el-button @click.prevent="cnfmMarkVoid(scope.row.InvoiceID, 0)" type="danger" class="btn-danger" size="small"><i class="fa-solid fa-circle-minus"></i> Void</el-button>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="modifyAccess"
                            prop=""
                            label="Email"
                            :filters="[{ text: 'All', value: 'All' }, { text: 'Emailed', value: 'y' }, { text: 'Not-Emailed', value: 'n' }]"
                            :filter-method="filterEmail"
                            filter-placement="bottom-end"
                            :filtered-value="['All']"
                            align="center"
                            width="120px">
                        <template #default="scope">
							<button v-if="scope.row.InvoiceStatus != 'Void'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="emailInvoice(scope.row)">
								<div class="v-btn__content">
									<i class="far fa-2x fa-envelope"></i>
								</div>
							</button><br>
                            <span v-if="scope.row.InvoiceStatus != 'Void' && scope.row.Params && scope.row.Params.InvoiceEmailed && scope.row.Params.InvoiceEmailed == 'y'" class="emailsuccess"><i class="emailsuccess fas fa-check-square"></i>&nbsp; Emailed</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="Download"
                            align="center"
                            width="120px">
                        <template #default="scope">
							<button v-if="scope.row.InvoiceStatus != 'Void'" type="button" class="v-toolbar__side-icon v-btn v-btn--icon theme--light" @click="downloadInvoice(scope.row)">
								<div class="v-btn__content">
									<i class="fas fa-2x fa-file-download"></i>
								</div>
							</button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
    import Util from "../assets/js/util";
	import FinanceDropdowns from './global/FinanceDropdowns.vue';
	import EmailTemplate from './global/EmailTemplate.vue';

	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
	export default {
		data () {
			return {
				loading : false,
                currentStatus : STATUS_INITIAL,
				Invoices: [],
                AllInvoices : [],
				search : '',
				OrganizationID: '',
                SeasonID : '',
				invoicedates: [],
				SystemText : '',
				EmailStatements : 'n',
                invoicesSelected : [],
                InvoiceSettingID : '',
                LeagueID : '',
				TeamID : ''
			}
		},
		name: 'Invoices',
		components: {
            FinanceDropdowns,
			EmailTemplate
        },
        props:["config", "parentrefs"],
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
			...mapGetters({ componentIDs: 'componentIDs' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
			modifyAccess () {
				return this.$modifyAccess();
			},
			createAccess () {
				return this.$createAccess();
			},
			ProgramIDAccess () {
				return this.$ProgramIDAccess();
			},
			filteredRecords () {
				var records = [];
				if (this.Invoices.length == 0 ) { return []; }
				if (!this.search ) { return this.Invoices; }

				for (let i=0; i < this.Invoices.length; i++) {
					if ((this.Invoices[i].InvoiceNumber == this.search) ||
						(this.Invoices[i].AmountOwing == this.search) ||
						(this.Invoices[i].InvoiceDate && this.Invoices[i].InvoiceDate.toLowerCase().includes(this.search.toLowerCase())) ||
						(this.Invoices[i].InvoiceStatus && this.Invoices[i].InvoiceStatus.toLowerCase().includes(this.search.toLowerCase())) ||
						(this.Invoices[i].Description && this.Invoices[i].Description.toLowerCase().includes(this.search.toLowerCase()))) {
						records.push(this.Invoices[i]);
					}
				}

				return records;
			}
		},
		methods: {
			tableRowClassName({row, rowIndex}) {
				if (row.InvoiceStatus == 'Void') {
					return 'voided-row';
                } else {
					return '';
				}
			},
			handleSelectionChange(val) {
				this.invoicesSelected = val;
			},
			formatPrice(value) {
                return Util.formatPrice(this.config, value , '');
			},
			filterStatus(value, row) {
				return row.InvoiceStatus === value;
			},
			filterEmail(value, row) {
				if (value == 'All') {
					return true;
				} else if (value == 'y') {
					return row.Params.InvoiceEmailed == 'y';
				} else {
					return row.Params.InvoiceEmailed != 'y';
				}
			},
			sortDate (a,b) {
				var ret = parseInt(a.InvoiceDate.replace(/-/g, '')) - parseInt(b.InvoiceDate.replace(/-/g, ''));
				return ret;
			},
			sortAmount (a,b) {
				var ret = parseInt(a.Amount) - parseInt(b.Amount);
				return ret;
			},
			filterValue(value) {
				console.log(value);
			},
			dropdownsChange (OrganizationID, LeagueID, InvoiceSettingID, SeasonID, TeamID) {
				this.OrganizationID = OrganizationID;
                this.LeagueID = LeagueID;
				this.InvoiceSettingID = InvoiceSettingID;
				this.SeasonID = SeasonID;
				this.getInvoices();
			},
			getInvoices () {
				this.Invoices = [];
			    if (this.OrganizationID || this.LeagueID || this.InvoiceSettingID) {
                    this.loading = true;
                    let self = this;
                    axios.post('/finance/getinvoices', {
                            OrganizationID: this.OrganizationID,
                            SeasonID: this.SeasonID,
                            InvoiceSettingID: this.InvoiceSettingID,
                            LeagueID: this.LeagueID,
                            ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : ''),
                            invoicedates: this.invoicedates,
                            ActiveOnly: (this.ProgramIDAccess ? 'y' : 'n')
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                self.$store.dispatch('setID', {'InvoiceDates': self.invoicedates});
                                this.Invoices = (response.data.Invoices ? response.data.Invoices : []);

                                this.$stopProgressBar(this.parentrefs);
                            } else {
                                this.$failProgressBar(this.parentrefs);
                            }
                            this.loading = false;
                        }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                    });
                }
			},
			emailAllInvoices() {
				if (this.SystemText) {
					for (let i = 0; i < this.invoicesSelected.length; i++) {
						let row = this.invoicesSelected[i];
						axios.post('/finance/emailinvoice', {InvoiceID: row.InvoiceID, SystemText : this.SystemText, EmailStatements : this.EmailStatements}
						)
							.then(response => {
								if ((i + 1) == this.invoicesSelected.length) {
									this.getInvoices ();
								}
							}).catch(response => {
						});
					}
					if (this.invoicesSelected.length > 0) {
						this.$notify({
							title: 'Success',
							message: 'A total of ' + this.invoicesSelected.length + ' invoice(s) have been emailed',
							type: 'success',
							duration: 0,
							showClose: true
						});
						this.$refs.invoicetable.clearSelection();
					} else {
						this.displayPopup ('No Invoices were selected to Email');
					}
				} else {
					this.displayPopup ('An Email Template must be selected');
				}
			},
			emailInvoice(row) {
				if (this.SystemText) {
					if (this.currentStatus == STATUS_SAVING) {
						return true;
					}
					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
					axios.post('/finance/emailinvoice', {InvoiceID: row.InvoiceID, SystemText : this.SystemText, EmailStatements : this.EmailStatements}
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$stopProgressBar(this.parentrefs);

								this.$notify({
									title: 'Success',
									message: (response.data.message ? response.data.message : "Invoice has been emailed to the contact on file."),
									type: 'success',
									duration: 0,
									showClose: true
								});
								this.getInvoices ();
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup (response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
						}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
					});
				} else {
					this.displayPopup ('An Email Template must be selected');
				}
			},
			printAllInvoices() {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				if (this.invoicesSelected.length > 0) {
					var InvoiceIDs = [];
					for (let i = 0; i < this.invoicesSelected.length; i++) {
						InvoiceIDs.push(this.invoicesSelected[i].InvoiceID);
					}

					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
					axios.post('/finance/getinvoice', {InvoiceIDs: InvoiceIDs}
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$stopProgressBar(this.parentrefs);
								if (response.data.invoiceFile) {
									var invoiceFile = response.data.invoiceFile;

									var link = document.createElement('a');
									link.href = invoiceFile;
									link.click();
								} else {
									this.displayPopup ('Invoices could not be created');
								}
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup (response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
						}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
					});
				} else {
					this.displayPopup ('No Invoices were selected to Print');
				}
			},
			downloadInvoice(row) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/finance/getinvoice', {InvoiceID: row.InvoiceID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							this.$stopProgressBar(this.parentrefs);
							if (response.data.invoiceFile) {
								var invoiceFile = response.data.invoiceFile;

								var link = document.createElement('a');
								link.href = invoiceFile;
								link.click();
							} else {
								this.displayPopup ('Invoice could not be created');
							}
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},

			markPaid(InvoiceID, InvoiceDetailID) {

				var amountPaid = 0;
				var amount = 0;

				for (let i=0; i < this.Invoices.length; i++) {
					if (this.Invoices[i].InvoiceID == InvoiceID) {
						amountPaid = this.Invoices[i].AmountPaid;
						amount = this.Invoices[i].Amount;
						break;
					}
				}
				if (isNaN(amountPaid)) {
					this.displayPopup ('Must enter a valid amount paid.');
					return true;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/finance/markpaid', {InvoiceID: InvoiceID, InvoiceDetailID: InvoiceDetailID, AmountPaid : amountPaid, Amount : amount}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							let idx = 0;
							for (let i=0; i < this.Invoices.length; i++) {
								if (this.Invoices[i].InvoiceID == InvoiceID) {
									idx = i;
									if (response.data.invoicePaid && response.data.invoicePaid == 'y') {
										this.Invoices[idx].InvoiceStatus = 'Paid';
									}
									break;
								}
							}
							for (let i=0; i < this.Invoices[idx].Details.length; i++) {
								if (this.Invoices[idx].Details[i].InvoiceDetailID == InvoiceDetailID || InvoiceDetailID == 0) {
									this.Invoices[idx].Details[i].InvoiceDetailStatus = 'Paid';
									if (!response.data.invoicePaid || response.data.invoicePaid == 'n') {
										this.Invoices[idx].AmountOwing = this.Invoices[idx].AmountOwing - this.Invoices[idx].Details[i].DetailAmount;
									}
								}
							}
							if (amountPaid > amount) {
								this.displayPopup('A Credit has been created for this account due to the overpayment');
							} else if (amountPaid < amount) {
								this.displayPopup('An Amount Owing has been created for this account due to the underpayment');
							}
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},
			cnfmMarkVoid(InvoiceID, InvoiceDetailID) {
				this.$confirm('This will void this Invoice. It cannot be undone, you will have to redo the Invoice.  Continue?', 'Warning', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}).then(() => {
					this.markVoid(InvoiceID, InvoiceDetailID);
				}).catch(() => {
				});
			},
			markVoid(InvoiceID, InvoiceDetailID) {

				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				axios.post('/finance/markvoid', {InvoiceID: InvoiceID, InvoiceDetailID: InvoiceDetailID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							let idx = 0;
							for (let i=0; i < this.Invoices.length; i++) {
								if (this.Invoices[i].InvoiceID == InvoiceID) {
									idx = i;
									this.Invoices[idx].AmountOwing = 0;
									this.Invoices[idx].AmountPaid = '';
									this.Invoices[idx].InvoiceStatus = 'Void';
									break;
								}
							}
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});
			},

			markUnPaid(InvoiceID, InvoiceDetailID) {
				if (!this.modifyAccess) {
					return;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);

				axios.post('/finance/markunpaid', {InvoiceID: InvoiceID, InvoiceDetailID: InvoiceDetailID}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							let idx = 0;
							for (let i=0; i < this.Invoices.length; i++) {
								if (this.Invoices[i].InvoiceID == InvoiceID) {
									idx = i;
									this.Invoices[idx].InvoiceStatus = 'Pending';
									break;
								}
							}
							this.Invoices[idx].AmountOwing = 0;
							this.Invoices[idx].AmountPaid = '';
							for (let i=0; i < this.Invoices[idx].Details.length; i++) {
								if (this.Invoices[idx].Details[i].InvoiceDetailID == InvoiceDetailID || InvoiceDetailID == 0) {
									this.Invoices[idx].Details[i].InvoiceDetailStatus = 'Pending';
									this.Invoices[idx].AmountOwing = this.Invoices[idx].AmountOwing - (-this.Invoices[idx].Details[i].DetailAmount);
								}
							}
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
				});
			},
			updateInvoiceData(InvoiceID) {
				if (!this.modifyAccess) {
					return;
				}
				this.currentStatus = STATUS_SAVING;
				this.$startProgressBar(this.parentrefs);
				for (let i=0; i < this.Invoices.length; i++) {
					if (this.Invoices[i].InvoiceID == InvoiceID) {
						var InvoiceNumber = this.Invoices[i].InvoiceNumber;
						var Description = this.Invoices[i].Description;
						break;
					}
				}

				axios.post('/finance/updateInvoiceData', {InvoiceID: InvoiceID, InvoiceNumber: InvoiceNumber, Description: Description}
				)
					.then(response => {
						if (response.data.result == 'success') {
							this.currentStatus = STATUS_INITIAL;
							this.$stopProgressBar(this.parentrefs);
						} else {
							this.currentStatus = STATUS_FAILED;
							this.displayPopup (response.data.message);
							this.$failProgressBar(this.parentrefs);
						}
					}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
				});
			},
			displayPopup (message) {
				this.$alert(message, 'Error', {
					confirmButtonText: 'OK',
				});
			}
		},
		mounted() {
			if (this.$route.params.id) {
				this.search = this.$route.params.id;
			}
            this.invoicedates = this.componentIDs.InvoiceDates;

		},
	}
</script>

<style>
	#invoices .el-table__column-filter-trigger i {
		color: #fff;
	}
	#invoices .el-table th>.cell.highlight {
		color: #fff;
	}
	#app #invoices .el-select.topDropdown {
		width: 75%;
	}
	#invoices .el-table__row.voided-row td, #invoices .el-table--striped .el-table__row--striped.voided-row td {
		background-color: rgb(220, 53, 69, 0.2);
	}
	#invoices .el-table__row.voided-row .el-checkbox {
		display:none;
	}
</style>
<style scoped>
	.row.header {
		font-weight: 800;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		height: 50px;
	}
	.invoicesTable {
		border: 1px solid #f0f0f0;
	}
	.footer {
	    width: 100%;
	    margin: 10px 0px;
	}
	.odd {
		background-color: #efefef;
	}
	.invoicesTable {
		border: 1px solid #f0f0f0;
		padding: 0px 15px;
	}
	.detailheader {
		border-bottom: 1px solid #e0e0e0;
	}
	.even {
		background-color: #f5f5f5;
	}
	.col-4 button {
		margin: 5px 0px;
	}
	.paid button {
		height: 20px;
		padding-top: 3px;
		margin-top: 7px;
	}
	.search {
		float:right;
		width: 25%;
		margin-bottom: 10px;
	}
	.amountPaid {
		width: 80%;
		padding: 5px;
		margin: 1px 0px;
		height: 33px;
		border: 1px solid #DCDFE6;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		text-align: right;
	}
	.invoiceNumber {
		width: 70px;
		text-align: center;
		padding: 2px;
		margin: 1px 0px;
		height: 33px;
		border: 1px solid #DCDFE6;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}
	.description {
		width: 100%;
		text-align: left;
		padding: 2px;
		margin: 1px 0px;
		height: 33px;
		border: 1px solid #DCDFE6;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}
	#invoices .el-button+.el-button {
		margin-left: 0px;
		margin-top: 4px;
	}
	.danger {
		color: #DC3545;
	}
	.emailsuccess {
		color: #4CAF50;
	}
	.pastDue {
		font-weight: 800;
		color: #DC3545;
	}
	a i {
		font-size: 20px;
	}
    #app .el-date-editor--daterange.el-input__inner {
        max-width: 350px;
    }
</style>
