<template>
    <div transition name="alert" class="el-alert el-alert--error is-dark notavailable" role="alert">
        <i class="el-icon el-alert__icon is-big"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"></path></svg></i>
        <div class="el-alert__content">
            <span class="el-alert__title is-bold" v-html="title"></span>
            <p class="el-alert__description" v-html="description + (currentUser ? link : linknotloggedin)"></p>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
	export default {
        name: 'Alert',
        data() {
            return {
                link : ' In order to use this, please update your <a href="/plans" style="color: #fff;font-weight: 800;"><u>Plan</u></a> to include this function.',
                linknotloggedin : ' In order to use this, please contact your Administrator to include this function.',
            }
        },
		props: {
			title: {
				type: String,
				required: true,
			},
            description: {
				type: String,
				required: false,
			},
		},
        computed: {
	        ...mapGetters({ currentUser: 'currentUser' }),
        },
	}
</script>