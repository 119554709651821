<template>
	<div class="emailtemplate" :style="(width.includes('%') ? 'width: ' + width +';' : 'width: 100%; max-width: ' + width +';')">
		<el-form ref="emailtemplateform" label-position="top" label-width="120px" >
			<el-form-item label="From Email:" prop="fromEmail">
				<el-input 
						class="fromEmail" 
						style="width: 400px;"
						placeholder="From Prefix"
						v-model="SystemText.fromPrefix">
					<template #append>
						<el-select v-model="SystemText.fromDomain" key="fromDomain" placeholder="Select" popper-class="Analyzer" default-first-option filterable clearable>
							<el-option
								v-for="item in EmailDomains"
								:key="item.Domain"
								:label="'@' + item.Domain"
								:value="'@' + item.Domain">
							</el-option>
						</el-select>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="Email Template:" prop="SystemTextID" style="width: 400px;">
				<template v-if="calling == 'invoices'">
					<el-select key="customEmailList" v-model="SystemTextID" @change="prefillEmailTemplate" placeholder="Select Email Template" default-first-option filterable>
						<el-option-group 
							v-if="BillingType == 'IU' || BillingType == ''"
							:label="config.Params.playingsurface + ' Usage Invoices'"
						>
							<el-option
									key="iceusageinvoice"
									:label="'Default ' + config.Params.playingsurface + ' Usage Invoice'"
									value="iceusageinvoice">
							</el-option>
						</el-option-group>
						<el-option-group
							v-if="BillingType == 'IU' || BillingType == ''"
							v-for="group in CustomEmailsGrouping['iceusageinvoice']"
							:key="group.label"
							:label="group.label"
						>
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-option-group>
						<el-option-group 
							v-if="BillingType == 'BG' || BillingType == ''"
							:label="'By ' + config.Params.game + ' Invoices'"
						>
							<el-option
									key="bygameinvoice"
									:label=" 'Default By ' + config.Params.game + ' Invoice'"
									value="bygameinvoice">
							</el-option>
						</el-option-group>
						<el-option-group
							v-if="BillingType == 'BG' || BillingType == ''"
							v-for="group in CustomEmailsGrouping['bygameinvoice']"
							:key="group.label"
							:label="group.label"
						>
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-option-group>
						<el-option-group 
							v-if="BillingType == 'TR' || BillingType == ''"
							label="Team Registration Invoices"
						>
							<el-option
									key="teamreginvoice"
									label="Default Team Registration Invoice"
									value="teamreginvoice">
							</el-option>
						</el-option-group>
						<el-option-group
							v-if="BillingType == 'TR' || BillingType == ''"
							v-for="group in CustomEmailsGrouping['teamreginvoice']"
							:key="group.label"
							:label="group.label"
						>
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-option-group>
						<el-option-group 
							v-if="BillingType == 'MI' || BillingType == ''"
							label="Generic Invoices & Statements"
						>
							<el-option
									key="manualinvoice"
									label="Default Generic Invoice"
									value="manualinvoice">
							</el-option>
						</el-option-group>
						<el-option-group
							v-if="BillingType == 'MI' || BillingType == ''"
							v-for="group in CustomEmailsGrouping['manualinvoice']"
							:key="group.label"
							:label="group.label"
						>
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-option-group>
					</el-select>
				</template>
				<template v-else>
					<el-select v-model="SystemTextID" key="customEmailList" @change="prefillEmailTemplate" placeholder="Select Email Template" default-first-option filterable clearable>
						<el-option-group
							label=""
						>
							<el-option
							:key="calling"
							:label="defaultText[calling]"
							:value="calling">
							</el-option>
						</el-option-group>
						<el-option-group
							v-for="group in CustomEmailsGrouping"
							:key="group.label"
							:label="group.label"
						>
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-option-group>
					</el-select>
				</template>
			</el-form-item>
			<div v-if="SystemTextID" class="updateData">
				<el-collapse v-model="activeNames">
					<el-collapse-item title="Email Template Details" name="1">
						<div class="row mt-2">
							<div class="col-12">
								<div class="small mb-2"><strong>Prior to sending the Email, make any necessary changes. If you wish to make these change permanent, click the Save button.</strong></div>
								<el-form-item label="Subject Line:" prop="subject">
									<el-input
										v-model="SystemText.Params.Subject">
									</el-input>
								</el-form-item>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<el-form-item label="Message:" prop="message">
									<el-input type="textarea" v-model="SystemText.SystemText" :autosize="{ minRows:5, maxRows: 25}">
									</el-input>
								</el-form-item>
							</div>
						</div>
						<div v-if="modifyAccess" class="row mt-2" >
							<div class="col-12 text-center">
								<button class="btn btn-success btn-icon btn-sm" type="button" @click="save">
									<i class="fas fa-check"></i> <span> Save Changes Permanently</span>
								</button>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</el-form>
	</div>
</template>

<script>
	import axios from 'axios';

	export default  {
		name: `EmailTemplate`,
        props: {
			modelValue: {
				type: [Object, String],
				required: true,
			},
            calling: {
                type: String,
                required: true,
            },
            config: {
				type: Object,
				required: true,
				default: () => {}
            },
			BillingType: {
                type: String,
                required: false,
				default: ''
            },
			width: {
                type: String,
                required: false,
				default: '100%'
            },
        },
		emits: ['update:modelValue'],
		data() {
			return {
				SystemTextID : '',
				CustomEmailsGrouping : {}, 
				CustomEmails : [],
				SystemText : {'SystemTextID' : 0, Params : {'Subject' : ''}, SystemText : '', SystemTextType : 'E', recordstatus : 'A', fromPrefix : '', fromDomain : ''},
                SMSText : '',
				defaultText : {'welcome' : 'Default Welcome Email', 'financestatement' : 'Default Finance Statements Email', 'purchaseorder' : 'Purchase Orders', '3rdpartycontract' : 'Default 3rd Party Contract', 'swapschedules' : 'Default Swap Schedules Email'},
				activeNames : "1",
				EmailDomains : [],
			};
		},
		watch: {
			SystemTextID (current, prev) {
				this.updateValue();
			},
			SystemText: {
				handler: function(current, prev) {
					this.updateValue();
				},
				deep: true,
			},
		},
		computed: {
	        modifyAccess () {
				if (this.$currentUser && this.$currentUser.RoleSecurity['systemtext']) {
					if (this.$currentUser.RoleSecurity['systemtext'].Params.ModifyAccess && this.$currentUser.RoleSecurity['systemtext'].Params.ModifyAccess) {
						return true;
					}
				}
				return false;
			},
		},
		methods: {
			updateValue() {
				this.$emit('update:modelValue', this.SystemText);
			},
			getAllEmails () {
				if (this.calling == 'invoices') {
					this.getCustomEmails('iceusageinvoice', 'y');
					this.getCustomEmails('bygameinvoice', 'y');
					this.getCustomEmails('teamreginvoice', 'y');
					this.getCustomEmails('manualinvoice', 'y');
				} else {
					this.getCustomEmails(this.calling, 'n');
				}

			},
			getInitialData () {
                var tables = ['EmailDomains'];
                axios.post('/index/tableData', {tables : tables, UserArea : 'p'},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                            this.getAllEmails ();
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
			getCustomEmails (type, grouping) {
				axios.post('/systemtext/getcustomtext', {CustomTextID : type, Status : 'A'})
					.then(response => {
						if (response.data.result == 'success') {
							if (response.data.CustomEmailsGrouping) {
								if (grouping == 'y') {
                                	this.CustomEmailsGrouping[type] = response.data.CustomEmailsGrouping;
								} else {
									this.CustomEmailsGrouping = response.data.CustomEmailsGrouping;
								}
								for (let i = 0; i < response.data.CustomEmails.length; i++) {
									this.CustomEmails.push(response.data.CustomEmails[i]);
								}
							}
						}
					}).catch(response => {
					this.$failProgressBar(this.parentrefs);
				});
			},
			prefillEmailTemplate () {
				let found = false;
                for (let i = 0; i < this.CustomEmails.length; i++) {
                    if (this.CustomEmails[i].SystemTextID == this.SystemTextID) {
						var prefix = this.SystemText.fromPrefix;
						var domain = this.SystemText.fromDomain;
                        this.SystemText = this.CustomEmails[i];
						this.SystemText.fromPrefix = prefix;
						this.SystemText.fromDomain  = domain;
						found = true;
                        break;
                    }
                }
				//no email found, so add
				if (!found) {
					this.SystemText = {'SystemTextID' : this.SystemTextID, Params : {'Subject' : ''}, SystemText : '', SystemTextType : 'E', recordstatus : 'A', fromPrefix : '', fromDomain : ''};
				}
            },
			save() {
				axios.post('/systemtext/save', {details: this.SystemText, isHelp : 'n'}
				)
						.then(response => {
							if (response.data.result == 'success') {
								this.$notify({
									title: 'Success',
									message: "Email Text has been saved",
									type: 'success',
									duration: 1500,
									showClose: false
								});
							} else {
								this.displayPopup(response.data.message);
							}
						}).catch(response => {
					this.currentStatus = STATUS_FAILED;
					this.$failProgressBar(this.parentrefs);
				});

			},
		},
		mounted () {
			this.getInitialData ();
		},
	};
</script>
<style lang="scss">
#app div.emailtemplate div.el-select {
	width: 100%;
}
#app div.emailtemplate div.el-input-group__append div.el-select {
	width: auto;
}
el-input-group__append
#app .updateData {
	margin: 5px;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 5px;
}
#app div.el-collapse-item__header {
    font-size: 14px;
	padding-left: 14px;
    color: #333;
	height: 30px;
}
#app div.emailtemplate .fromEmail .el-input-group__append .el-input__wrapper {
    min-width: 250px;
}
</style>
