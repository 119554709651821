<template>
	<div id="forgotpassword" class="app flex-row text-center">
		<img class="logo" :src="config.Params.MainLogo"><br>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 mt-4">
					<div class="card-group" style="border: 2px solid #FFFFFF;">
						<div class="card">
							<div class="card-body">
								<h1>Forgot Password</h1>
								<p class="text-muted">Enter your Email Address used to Sign In to your {{ SiteName }} Administrative Account</p>
								<el-form ref="forgotpasswordForm" label-position="top" :model="user" label-width="120px">
									<el-form-item
                                        prop="username"
                                        :rules="[
                                            { required: true, message: 'Email is required'},
                                            { type: 'email',  message: 'Email is invalid', trigger: 'blur'}

                                        ]"
                                    >
	                                    <el-input placeholder="Email" v-model="user.username" @keyup.enter="submitForm">
	                                        <template #prepend><i class="fas fa-user"></i></template>
	                                    </el-input>
	                                </el-form-item>
	                            </el-form>
							</div>
							<div class="row">
								<div class="col-12">
									<el-button class="btn-success" type="success" style="width: 250px;text-align: center;" v-on:click="submitForm">Submit</el-button>
								</div>
							</div>
						</div>
						<div class="card text-white bg-primary py-5 d-md-down-none" style="width: 44%;">
							<ContactUs ref="contactus" :show="showContactUsForm" :caller="'forgotpassword'" @close="showContactUsForm = false"></ContactUs>
							<div v-if="!showContactUsForm"  class="card-body text-center">
								<div>
									<h2>Instructions</h2>
									<p>You should receive an Email with reset instructions.  If you do not receive an email, contact us for access details.</p>
									<el-button type="primary" class="btn-primary mt-3" v-on:click.stop="showContactUs">Contact Us</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import ContactUs from './global/ContactUs.vue';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
export default {
	name: 'ForgotPassword',
	data() {
		return {
			user: {
				username: ''
			},
			currentStatus: 0,
			showContactUsForm: false,
		}
	},
	components: {
        ContactUs
    },
	props:["config", "parentrefs"],
	computed: {
		SiteName() {
			return this.config.Params.SiteName
		}
	},
	methods: {
		showContactUs () {
            this.showContactUsForm = true;
        },
	    submitForm: function(event) {
	        event.stopPropagation();
            this.$refs['forgotpasswordForm'].validate((valid) => {
            	if (valid) {
					if (this.currentStatus == STATUS_SAVING) {
						return true;
					}
					this.currentStatus = STATUS_SAVING;
					axios.post('/Login/ForgotPassword', {
						username: this.user.username,
					}).then(response => {
						this.currentStatus = STATUS_INITIAL;
						if (response.data.result == 'success') {
							this.$notify({
								title: 'Success',
								message: "Forgot Password Reset Email Sent",
								type: 'success',
								duration: 2000,
								showClose: false
							});
			                let self = this;
			                setTimeout(() => { self.$router.replace('/login'); }, 1500);
		                } else {
							this.displayPopup (response.data.message);
		                }
					}).catch(response => {
						this.currentStatus = STATUS_FAILED;
					});
		        } else {
		            this.displayPopup ('Please enter an Valid Email Address');
		        }
		    });
	    },
        displayPopup (message) {
			this.$alert(message, 'Forgot Password Error', {
				confirmButtonText: 'OK',
			});
        }
	},
}
</script>
<style>
#app.forgotpassword {
	margin-top: 0px;
	height: 100vh;
	overflow: hidden;
}
#app.forgotpassword .page-wrapper {
	margin-left: 0px;
	margin-top: 0px;
	padding-top: 30px;
	}

#app.forgotpassword .card {
	margin-top: 0px;
	padding-top: 20px;
}
</style>
<style scoped>

@media (max-width: 991.98px) {
	.d-md-down-none {
        display: none!important;
    }
}
#app {
  margin-top: 0px;
}

.logo {
	max-width: 50%;
	max-height: 250px;
}

.card-body p, .card-body h2 {
	color: #fff;
}
.align-items-center {
	text-align:center;
}
</style>
