<template>
    <transition name="reportprinter">
        <div class="print-dialog" role="document">
            <div class="print-content">
                <h5>PDF Printing</h5>
                <div style="float:left; width: 45%;">
                Print Orientation:<br>
                <el-radio-group v-model="orientation" size="small">
                    <el-radio-button selected label="P">Portrait</el-radio-button>
                    <el-radio-button label="L">Landscape</el-radio-button>
                </el-radio-group>
                </div>
                <div style="float:left; width: 45%;">
                    Page Break on Groups:<br>
                    <el-radio-group v-model="pagebreak" size="small">
                        <el-radio-button selected label="y">Yes</el-radio-button>
                        <el-radio-button label="n">No</el-radio-button>
                    </el-radio-group>
                </div>
                <br><br><br><br>
                <div class="print-footer">
                    <button v-if="printDomID" class="btn btn-icon btn-secondary" style="float:right;" v-print="printDomID">
                        <i class="fas fa-print"></i> <span>Print Page Content</span>
                    </button>
                    <button class="btn btn-icon btn-success" style="float:right; margin-right: 20px;" @click="viewPDF">
                        <i class="far fa-file-pdf"></i> <span>Generate PDF</span>
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'reportprinter',
        data() {
            return {
                orientation: 'P',
                pagebreak: 'y',
            }
        },
        props: {
            data : {
                type: [Object,Array],
                required: true,
                default: () => {}
            },
            columns : {
                type: [Object,Array],
                required: true,
                default: () => {}
            },
            groupdata : {
                type: [Object,Array],
                required: false,
                default: () => {}
            },
            groupcolumns : {
                type: [Object,Array],
                required: false,
                default: () => {}
            },
            printDomID : {
                type: String,
                required: false,
                default: ''
            },
            reportName : {
                type: String,
                required: false,
                default: ''
            },
            totals : {
                type: String,
                required: false,
                default: 'y'
            }
        },
        methods: {
            viewPDF() {
                if (this.data) {
                    axios.post('/reportwriter/printpdf', {
                            data: this.data,
                            columns: this.columns,
                            groupdata: this.groupdata,
                            groupcolumns: this.groupcolumns,
                            orientation: this.orientation,
                            pagebreak : this.pagebreak,
                            reportName : this.reportName,
                            totals : this.totals,
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                if (response.data.File) {
                                    var File = response.data.File;

                                    var link = document.createElement('a');
                                    link.href = File;
                                    link.click();
                                } else {
                                    this.displayPopup('Report could not be generated');
                                }
                            } else {
                                this.displayPopup(response.data.message);
                            }
                            this.$emit('close');
                        }).catch(response => {
                            this.displayPopup('Report could not be generated');
                            this.$emit('close');
                    });
                }
            },
            displayPopup (message) {
                this.$alert(message, 'Print Error', {
                    confirmButtonText: 'OK',
                });
            },
        }
    }
</script>
<style>


</style>
