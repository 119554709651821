<template>
	<div id="gamecancellation" v-loading.fullscreen.lock="loading">
		<el-form ref="gamecancellationForm" :model="formdetails" :rules="rules" label-position="top" label-width="120px" >
			<div class="row program-select">
				<div class="col-12 fullpage topselects">
					<label class="mb-1 control-label">{{config.Params.programName}}:</label>
					<el-select v-if="Programs.length > 1" v-model="ProgramID" class="topDropdown dropdowns" @change="getTeams()" placeholder="Select" default-first-option filterable>
						<el-option v-for="type in Programs"
								:value="type.ProgramID"
								:key="type.ProgramID"
								:label="type.ProgramName">
						</el-option>
					</el-select>
					<div v-else-if="Programs.length == 1" class="programName dropdowns">&nbsp;{{Programs[0].ProgramName}}</div>
				</div>
			</div>
			<div v-if="ProgramID" class="row mt-1 program-select">
				<div class="col-12 fullpage topselects">
					<label class="mb-1 control-label">Team:</label>
					<el-select v-if="Teams.length > 1" v-model="formdetails.TeamID" class="topDropdown dropdowns" @change="getGames()" placeholder="Select" default-first-option filterable>
						<el-option
								v-for="item in Teams"
								:key="item.TeamID"
								:label="item.TeamName"
								:value="item.TeamID">
						</el-option>
					</el-select>
					<div v-else-if="Teams.length == 1" class="programName dropdowns">&nbsp;{{Teams[0].TeamName}}</div>
					<div v-else><strong>No Teams yet this Season</strong></div>
				</div>
			</div>
			<div v-if="formdetails.TeamID" class="row mt-1 program-select">
				<div class="col-12 fullpage topselects">
					<label class="mb-1 control-label">Game:</label>
					<el-select v-if="Games.length >= 1" class="topDropdown dropdowns" v-model="formdetails.GameID" placeholder="Select" default-first-option filterable>
						<el-option
								v-for="item in Games"
								:disabled="(item.FormGameCancellationID ? true :false)"
								:key="item.GameID"
								:label="item.GameDesc"
								:value="item.GameID">
						</el-option>
					</el-select>
					<div v-else-if="formdetails.TeamID"><strong>No Games are Available</strong></div>
				</div>
			</div>
			<div v-if="formdetails.GameID" class="row">
				<div class="card col-12 fullpage">
					<div class="card-header bg-primary" style="margin-left:0;"><h5>Cancellation Details</h5></div>
					<div v-if="modifyAccess" class="card-body" style="padding: 15px;">
						<p style="font-weight:600;">Forfeit wins and losses will be recorded as a <u>place holder</u>.  Forfeits will be removed once a make up game sheet has been received by the league office.</p>
						<el-form-item label="Who Cancelled:" prop="WhoCancelled"
							:rules="[{ required: true, message: 'Who Cancelled is required', trigger: 'blur'}]"
						>
							<el-select class="fifty" v-model="formdetails.WhoCancelled" placeholder="Select" default-first-option filterable>
								<el-option
									v-for="item in whoCancelled"
									:label="item"
									:value="item"
									:key="item">
								</el-option>
							</el-select>
						</el-form-item>
						<div v-if="formdetails.WhoCancelled == 'Opponent'">
							<h5>{{config.Params.playingsurface}} Usage Information</h5>
							<el-form-item label="My Teams Ice Usage:" prop="GameID"
								:rules="[{ required: true, message: 'My Teams Ice Usage is required', trigger: 'blur'}]"
							>
								<el-select class="fifty" v-model="formdetails.MyTeamUsage" placeholder="Select" default-first-option filterable>
									<el-option
										v-for="item in myTeamUsage"
										:label="item"
										:value="item"
										:key="item">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<h5>Contact Information</h5>
						<el-form-item label="First Name:" prop="FirstName"
							:rules="[{ required: true, message: 'First Name is required', trigger: 'blur'}]"
						>
							<el-input v-model="formdetails.FirstName">
							</el-input>
						</el-form-item>
						<el-form-item label="Last Name:" prop="LastName"
							:rules="[{ required: true, message: 'Last Name is required', trigger: 'blur'}]"
						>
							<el-input v-model="formdetails.LastName">
							</el-input>
						</el-form-item>
						<el-form-item label="Email Address:" prop="EmailAddress"
							:rules="[{ required: true, message: 'Email Address is required', trigger: 'blur'},
									{ type: 'email',  message: 'Email Address is invalid', trigger: 'blur'}]"
						>
							<el-input v-model="formdetails.EmailAddress">
							</el-input>
						</el-form-item>
						<div class="el-form-item is-required" style="margin-bottom: 20px;">
							<el-form-item label="Phone Number:" prop="PhoneNumber">
								<div class="el-form-item__content">
									<div class="el-input el-input--default el-input__wrapper">
										<input autocomplete="off" class="el-input__inner" type="tel" v-maska="'(###) ###-####'" v-model="formdetails.PhoneNumber" />
									</div>
								</div>
							</el-form-item>
						</div>
						<el-form-item label="Reason for Cancellation:" prop="Reason"
									:rules="[{ required: true, message: 'Reason for Cancellation is required', trigger: 'blur'}]"
						>
							<el-input v-model="formdetails.Reason"
								type="textarea"
								:autosize="{ minRows: 2, maxRows: 4}"
							>
							</el-input>
						</el-form-item>
					</div>
					<div v-else class="card-body">
						<p style="font-weight:600;">You do not have Access to submit Game Cancellations</p>
					</div>
				</div>
			</div>
			<div v-if="formdetails.GameID && modifyAccess" class="row buttons mb-4">
				<div class="col-12 text-center">
					<button class="btn btn-success btn-icon" type="button" @click="submit">
						<i class="fas fa-check"></i> <span> Submit</span>
					</button>
					<button class="btn btn-icon btn-secondary cancel" type="button"  @click="reset(true)">
						<i class="fas fa-times"></i> <span>Cancel</span>
					</button>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'GameCancellation',
	    data() {
			var validatePhoneNumber = (rule, value, callback) => {
                if (value) {
                    if (value.length != 14) {
                        callback(new Error('Phone Number must be 10 numbers'));
                    } else {
                        callback();
                    }
                } else {
                    callback(new Error('Phone Number is Required'));
                }
            };
	        return {
	            loading : true,
                currentStatus : STATUS_INITIAL,
                Programs : [],
                ProgramID : '',
                Teams : [],
                Games : [],
                whoCancelled : ["Opponent No Showed","My team","Opponent","Both teams"],
                myTeamUsage : [ "Will not use ice","Will use Ice but do not need refs","Will use ice and needs refs"],
                formdetails : {GameID:"", TeamID:"", ContactID: "", MyTeamUsage: "", WhoCancelled: "",FirstName:"", LastName:"", EmailAddress:"", PhoneNumber:"", Reason : ''},
                contact: {},
                rules: {
                    PhoneNumber: [
                        { validator: validatePhoneNumber, trigger: 'blur' }
                    ],
                }
	        }
	    },
	    computed: {
            ...mapGetters({ currentUser: 'currentUser' }),
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
	        modifyAccess () {
	            return this.$modifyAccess();
	        },
		    ProgramIDAccess () {
			    return this.$ProgramIDAccess();
		    },
		    TeamIDAccess () {
			    return this.$TeamIDAccess();
		    }
		},
		props:["config", "parentrefs"],
		methods: {
			reset () {
				this.formdetails.TeamID = '';
				this.formdetails.GameID = '';
				this.formdetails.MyTeamUsage = '';
				this.formdetails.WhoCancelled = '';
			},
			getInitialData () {
				var tables = ['Programs'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (this.Programs.length == 1) {
                            this.ProgramID = this.Programs[0].ProgramID;
                            this.getTeams();
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                    this.loading = false;
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
			},
			getUserDetails () {
				axios.post('/users/get', {'UserLoginID': this.currentUser.UserLoginID },
                )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.formdetails.FirstName = response.data.contact.FirstName;
                        this.formdetails.LastName = response.data.contact.LastName;
                        this.formdetails.EmailAddress = response.data.contact.EmailAddress;
                        this.formdetails.PhoneNumber = (response.data.contact.Params.CellPhoneNumber ? response.data.contact.Params.CellPhoneNumber : (response.data.contact.Params.HomePhoneNumber ? response.data.contact.Params.HomePhoneNumber :(response.data.contact.Params.WorkPhoneNumber ? response.data.contact.Params.WorkPhoneNumber : '')));
	                    this.formdetails.ContactID = response.data.contact.ContactID;
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
	                });
			},
			getTeams () {
				this.Teams = [];
				this.formdetails.TeamID = '';
				let self = this;
				if (this.ProgramID) {
					var tables = ['Teams'];
					axios.post('/index/tableData', {'tables': tables, 'ProgramID' : this.ProgramID, TeamIDs : this.TeamIDAccess },
					)
	                .then(response => {
	                    if (response.data.result == 'success') {
	                        for (let value of tables) {
	                            this[value] = response.data[value];
	                        }
		                    if (this.Teams.length == 1) {
			                    this.formdetails.TeamID = this.Teams[0].TeamID;
			                    this.getGames();
		                    }
	                    } else {
	                        this.$failProgressBar(this.parentrefs);
	                    }
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
	                });
	            }
			},
			getGames () {
				this.Games = [];
				this.formdetails.GameID = '';
				if (this.formdetails.TeamID) {
					var tables = ['Games'];
					axios.post('/gamecancellation/getgames', {'TeamID' : this.formdetails.TeamID },
					)
	                .then(response => {
	                    if (response.data.result == 'success') {
	                        this.Games = (response.data.Games ? response.data.Games : []);
	                    } else {
	                        this.$failProgressBar(this.parentrefs);
	                    }
	                }).catch(response => {
	                    this.$failProgressBar(this.parentrefs);
	                });
	            }
			},
			submit() {
				this.$refs['gamecancellationForm'].validate((valid) => {
                    if (valid) {
						if (this.currentStatus == STATUS_SAVING) {
							return true;
						}
                    	if (this.formdetails.WhoCancelled != 'Opponent') {
		                    this.formdetails.MyTeamUsage = 'Will not use ice';
	                    }
						this.currentStatus = STATUS_SAVING;
						this.$startProgressBar(this.parentrefs);
						this.loading = true;
						axios.post('/gamecancellation/submit', {details : this.formdetails}
						)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								let GameDesc = ''
								for (let i = 0; i < this.Games.length; i++) {
									if (this.Games[i].GameID == this.formdetails.GameID) {
										GameDesc = this.Games[i].GameDesc;
										break;
									}
								}
								this.$confirm("Game Cancellation has been accepted for:<br><br><strong>" + GameDesc + "</strong><br><br>Thank you.", 'Success', {
									confirmButtonText: 'OK',
									type: 'success',
									dangerouslyUseHTMLString: true,
									showClose: false,
									showCancelButton: false
								});
								this.$stopProgressBar(this.parentrefs);
								this.reset();
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayPopup (response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
							this.loading = false;
						}).catch(response => {
							this.currentStatus = STATUS_FAILED;
							this.$failProgressBar(this.parentrefs);
							this.loading = false;
						});
					}
				});
			},
	        displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
                });
            }
		},
		mounted () {
			this.getInitialData();
			this.getUserDetails ();
		}
	}
</script>

<style>
.mini-sidebar .left-sidebar .tableheader {
	left: 75px;
}
.left-sidebar .tableheader {
	left: 255px;
}
</style>
<style scoped>
#gamecancellation {
	margin: 0px;
}
.intro {
	border-bottom: 1px solid #dcdfe6;
	padding-bottom: 14px;
	margin-bottom: 14px;
	font-size: 13px;
}
.intro h5 {
	font-size: 16px;
}
.select {
	margin-top: 7px;
}

li {
	margin-left: 20px;
}
select.el-select {
	-webkit-appearance: menulist-button;
}
.el-select.game, .el-input {
	max-width: 600px;
	width: 100%;
}
.card, .buttons {
	max-width: 600px;
}
.programName {
	display: inline-block;
	font-weight: 800;
	margin-top: 5px;
}
label {
	position: absolute;
	margin-top: 5px
}
.dropdowns {
	margin-left: 60px;
	position: absolute;
	width: 100%;
}
.program-select {
	font-weight: 800;
	height: 35px;
}
</style>
