
import Util from '../assets/js/util.js';

const state = {
	componentIDs: (localStorage.ids ? JSON.parse(localStorage.ids) : {})
}

const mutations = {
	'setID' (state, obj) {
		let entries = Object.entries(obj);
		for (const [field, value] of entries) {
			state.componentIDs[field] = value;
		}
		localStorage.ids = JSON.stringify(state.componentIDs);
	}
}

const getters = {
	componentIDs (state) {
		return state.componentIDs;
	}
}

const actions = {
	setID ({ commit }, obj) {
		commit('setID', obj);
	},
}

export default {
	state,
	mutations,
	getters,
	actions
}
