<template>
	<div id="mobilegamereporting">
		<div class="row">
			<div class="row col-12 mt-4" style="height: 35px;">
				<label class="mb-1 control-label">{{config.Params.mainlocationName}}:</label>
				<el-select v-model="MainLocationID" ref="mainlocation" @change="getGames" class="dropdowns" placeholder="Select" default-first-option filterable>
					<el-option v-for="type in SiteLocations"
							   :value="type.MainLocationID"
							   :key="type.MainLocationID"
							   :label="type.MainLocationName">
					</el-option>
				</el-select>
			</div>
			<div v-if="this.Games.length <= 0 && MainLocationID && SiteLocations.length > 0 && getgames">
				<span class="nogames">Unfortunately there are no games today.</span>
			</div>
			<template v-for="(game, index) in Games">
				<template v-if="index == GameIndex">
					<div class="row gameInfo" v-loading="loading">
						<div class="col-6">
							Game No: <span class="text-primary"><strong>{{game.SlotNumber}}</strong></span><br>
							Date: <span class="text-primary"><strong>{{game.GameDateF}}</strong></span>
						</div>
						<div class="col-6">
							<span v-html="(game.ScheduleUsage == 'T' ? 'Bracket' : 'Division')"></span>: <span class="text-primary"><strong>{{game.DivisionShortName}}</strong></span><br>
							Time: <span class="text-primary"><strong>{{game.StartTime}}</strong></span>
						</div>
						<div class="col-12">
							<el-button v-if="GameIndex > 0" style="float:left;" type="primary" class="btn-primary" @click="prevGame"><i class="fa-solid fa-angle-left"></i> Prev Game</el-button>
							<el-button v-if="!(GameIndex == (Games.length-1))" style="float:right;" type="primary" class="btn-primary" @click="nextGame">Next Game <i class="fa-solid fa-angle-right"></i></el-button>
						</div>
					</div>
					<span v-if="game.LeagueParams.GameReporting =='y'">
						<div style="font-size: 16px; font-weight: 800;text-align: center; padding: 25px;" v-html="HomeTeamReportsMessage"></div>
					</span>
					<span v-else>
						<div class="row gameDetails">
							<div class="col-12 text-center">
								<el-radio-group v-model="game.GameStatus"  @change="statusChange();">
									<el-radio-button label="C">Completed</el-radio-button>
									<el-radio-button label="x">Not Played</el-radio-button>
								</el-radio-group>
							</div>
						</div>
						<div class="row gameScore">
							<div class="col-12">
								<div class="teams">
									&nbsp;<br>
									<div class="teamname">Home Team: <span class="text-primary"><strong>{{game.HomeTeamName}}</strong></span></div>
									<div class="teamname">Visiting Team: <span class="text-primary"><strong>{{game.VisitingTeamName}}</strong></span></div>
								</div>
								<div  v-if="game.GameStatus == 'C'" class="scores">
									<strong>Scores</strong>
									<input type="number" min="0" @focus="$event.target.select()" class="form-control score"  @change="dataToSave()" v-model="game.HomeScore"  /><br>
									<input type="number" min="0" @focus="$event.target.select()" class="form-control score"  @change="dataToSave()" v-model="game.VisitingScore"  />
								</div>
							</div>
							<div v-if="game.GameStatus == 'C'" class="col-6 text-center">
								<div class="suspensions">Suspensions:</div>
								<el-radio-group v-model="game.Params.Suspensions" @change="dataToSave" size="small">
									<el-radio-button label="n">No</el-radio-button>
									<el-radio-button label="y">Yes</el-radio-button>
								</el-radio-group>
							</div>
							<div v-if="game.GameStatus == 'C'" class="col-6 text-center">
								<div class="suspensions">Single Official:</div>
								<el-radio-group v-model="game.Params.NoOnIceOfficials" @change="dataToSave" size="small">
									<el-radio-button label="0">No</el-radio-button>
									<el-radio-button label="1">Yes</el-radio-button>
								</el-radio-group><br>
							</div>
						</div>
						<div v-if="hasGames && (game.GameStatus == 'C' || game.GameStatus == 'x')" class="row gameNotes">
							<div class="col-12">
								<div class="webnote">Web Notes:</div>
								<el-input
										@change="dataToSave()"
										type="textarea"
										autosize
										placeholder=""
										v-model="game.WebNotes">
								</el-input>
							</div>
						</div>
						<div class="row buttons" v-if="hasGames  && (game.GameStatus == 'C' || game.GameStatus == 'x')">
							<div class="col-12 text-center">
								<button class="btn btn-success btn-icon" type="button" @click="saveGames(0)">
									<i class="fas fa-check"></i> <span> Submit Game</span>
								</button>
							</div>
						</div>
					</span>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import axios from 'axios';
	import Modal from './global/Modal.vue';
	const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

	export default {
		name: 'GameReporting',
		components: {
			Modal
		},
	    data() {
	        return {
		        SiteLocations : [],
				loading: false,
		        getgames: false,
                currentStatus : STATUS_INITIAL,
                Games : [],
		        GameIndex: -1,
		        MainLocationID : '',
		        PrevMainLocationID : '',
		        Suspensions: '',
				NoOnIceOfficials : '',
		        HomeTeamReportsMessage : '',
	        }
	    },
		props:["config", "parentrefs"],
		beforeRouteLeave (to, from, next) {
			if (this.haveUnsavedData()) {
				let self = this;
				this.$confirm('You have made changes to Games on one of the data you have not Saved. If you LEAVE you will lose them.', 'You have Unsaved Data.', {
					distinguishCancelAndClose: true,
					showClose: false,
					confirmButtonText: 'Stay',
					cancelButtonText: 'Leave',
					closeOnClickModal: false,
					closeOnPressEscape: false,
					type: 'warning'
				})
					.then(function () {
						next(false);
					})
					.catch(function () {
						self.$noDataToBeSaved();
						next();
					});
			} else {
				next();
			}
		},
	    computed: {
		    ...mapGetters({ componentIDs: 'componentIDs' }),
	        hasGames () {
	            return (this.Games.length > 0  && this.GameIndex >= 0 ? true : false);
	        },
			isInitial() {
				return this.currentStatus === STATUS_INITIAL;
			},
			isSaving() {
				return this.currentStatus === STATUS_SAVING;
			},
			isSuccess() {
				return this.currentStatus === STATUS_SUCCESS;
			},
			isFailed() {
				return this.currentStatus === STATUS_FAILED;
			},
		    isMite () {
			    return (this.$route.fullPath.indexOf('mite') !== -1 ? true : false);
		    },
		},
		methods: {
            haveUnsavedData() {
                return this.$haveUnsavedData();
            },
			prevGame() {
				if (this.haveUnsavedData()) {
					let self = this;
					this.$confirm('You have made changes to the Games on this screen which will not be saved going to the previous game.', 'You have Unsaved Data.', {
						distinguishCancelAndClose: true,
						showClose: false,
						confirmButtonText: 'Save Changes',
						cancelButtonText: 'Prev Game',
						closeOnClickModal: false,
						closeOnPressEscape: false,
						type: 'warning'
					})
						.then(function () {
							self.saveGames(-1);
							return;
						})
						.catch(function () {
							self.gotoPrevGame();
						});
				} else {
					this.gotoPrevGame();
				}

			},
			gotoPrevGame () {
				var nextGame = this.GameIndex - 1;

				if (this.Games[nextGame].WebNotes  && this.Games[nextGame].GameStatus == 'P') {
					this.Games[nextGame].GameStatus = 'x';
				}

				this.GameIndex--;
			},
			dataToSave() {
				this.$haveDataToBeSaved();
			},
			nextGame() {
				if (this.haveUnsavedData()) {
					let self = this;
					this.$confirm('You have made changes to the Games on this screen which will not be saved going to the next game.', 'You have Unsaved Data.', {
						distinguishCancelAndClose: true,
						showClose: false,
						confirmButtonText: 'Save Changes',
						cancelButtonText: 'Next Game',
						closeOnClickModal: false,
						closeOnPressEscape: false,
						type: 'warning'
					})
					.then(function () {
						self.saveGames(1);
						return;
					})
					.catch(function () {
						self.gotoNextGame();
					});
				} else {
					this.gotoNextGame();
				}

			},
			gotoNextGame () {
				var nextGame = this.GameIndex + 1;

				if (this.Games[nextGame].WebNotes  && this.Games[nextGame].GameStatus == 'P') {
					this.Games[nextGame].GameStatus = 'x';
				}

				this.GameIndex = nextGame;
			},
			gotoFirstGameNoStats () {
				var nextGame = 0;
				for (let i=0; i < this.Games.length; i++) {
					if (this.Games[i].GameStatus != 'C' && this.Games[i].GameStatus != 'x' && !this.Games[i].WebNotes) {
						this.GameIndex = i - 1;
						break;
					}
				}
				this.gotoNextGame();
			},

			statusChange() {
				if (this.Games[this.GameIndex].GameStatus == 'x') {
					this.Games[this.GameIndex].HomeScore = '';
					this.Games[this.GameIndex].VisitingScore = '';
					this.Games[this.GameIndex].Params.NoOnIceOfficials = '';
					this.Games[this.GameIndex].Params.Suspensions = '';
				}
			},
			getInitialData () {
				var tables = ['SiteLocations'];
				axios.post('/index/tableData', {'tables': tables },
				)
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
	                        if (this.componentIDs.GRMainLocationID) {
		                        this.MainLocationID = this.componentIDs.GRMainLocationID;
	                        }
                            if (!this.MainLocationID) {
                            	this.MainLocationID = this[value][0]['MainLocationID'];
                            }
	                        this.getGames();
                        }
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
			},
			getGames() {
				if (this.haveUnsavedData()) {
					let self = this;
					this.$confirm('You have made changes to the Games on this screen which will be lost changing Sites.', 'You have Unsaved Data.', {
						distinguishCancelAndClose: true,
						showClose: false,
						confirmButtonText: 'Keep Changes',
						cancelButtonText: 'Get Games',
						closeOnClickModal: false,
						closeOnPressEscape: false,
						type: 'warning'
					})
						.then(function () {
							self.MainLocationID = self.PrevMainLocationID;
							self.$refs['mainlocation'].blur();
							return;
						})
						.catch(function () {
							self.getGameCnfm ();
						});
				} else {
					this.getGameCnfm ();
				}
			},
			getGameCnfm() {
				if (this.MainLocationID) {
					this.$store.dispatch('setID', {'GRMainLocationID': this.MainLocationID});
					this.$noDataToBeSaved();
					this.PrevMainLocationID = this.MainLocationID;
					this.loading = true;
					var date = new Date();
					var day = date.getDate();
					var month = date.getMonth()+1;
					var year = date.getFullYear();
					var tables = ['GameDates'];
					this.Games = [];
					this.GameIndex = -1;
					axios.post('/gamereporting/getByDate', {
							'MainLocationID': this.MainLocationID,
                            'Calling' : 'sitesheets',
							'GameDate': year+'-'+month+'-'+day,
							'Mites': (this.isMite ? 'y' : 'n')
						},
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.Games = response.data.Games;
								this.gotoFirstGameNoStats();
							} else {
								this.$failProgressBar(this.parentrefs);
							}
							this.getgames = true;
							this.loading = false;
							this.HomeTeamReportsMessage = response.data.hometeamreports;
						}).catch(response => {
						this.$failProgressBar(this.parentrefs);
						this.loading = false;
					});
				}
			},
			saveGames(nextIdx) {
				if (this.currentStatus == STATUS_SAVING) {
					return true;
				}
				var game = JSON.parse(JSON.stringify(this.Games[this.GameIndex]));

				if (game.GameStatus == 'C' && (game.HomeScore == '' || game.VisitingScore == '')) {
					this.$alert('Must enter a Score for Both Teams', 'Error', {
						type: 'error',
						confirmButtonText: 'OK',
					});
				} else {
					this.loading = true;
					this.currentStatus = STATUS_SAVING;
					this.$startProgressBar(this.parentrefs);
					axios.post('/gamereporting/saveResults', {Games: [game]}
					)
						.then(response => {
							if (response.data.result == 'success') {
								this.currentStatus = STATUS_INITIAL;
								this.$noDataToBeSaved();
								this.$notify({
									title: 'Success',
									message: (response.data.message ? response.data.message : "Game has been saved"),
									type: 'success',
									duration: 1500,
									showClose: false
								});
								this.$stopProgressBar(this.parentrefs);
								if (nextIdx == 1){
									this.gotoNextGame();
								} else if (nextIdx == -1){
									this.gotoPrevGame();
								}
							} else {
								this.currentStatus = STATUS_FAILED;
								this.displayModal(response.data.message);
								this.$failProgressBar(this.parentrefs);
							}
							this.loading = false;
						}).catch(response => {
						this.currentStatus = STATUS_FAILED;
						this.$failProgressBar(this.parentrefs);
						this.loading = false;
					});
				}
			}
		},
		mounted () {
			this.getInitialData();
		}
	}
</script>


<style scoped>
	#mobilegamereporting {
		max-width: 700px;
	}
	.col-6 {
		padding: 0;
	}
	.el-collapse {
		width: 100%;
	}
	#mobilegamereporting div.gameInfo {
		margin-top: 15px;
		border: 2px solid #dedede;
		padding: 5px;
		height: 125px;
		font-size: 16px;
		color: #333;
		line-height: 35px;
	}
	.buttons {
		margin-top: 15px;
	}
	.gameDetails {
		margin-top: 15px;
	}
	.gameScore {
		line-height: 50px;
	}
	.score {
		width: 50px;
		height: 35px;
		display: inline-block;
		text-align: center;
	}
	.teams {
		display: inline-block;
		width: 83%;
	}
	.scores {
		display: inline-block;
		margin-left: 15px;
		width: 10%;
		vertical-align: top;
	}
	.webnotes {
		width: 50%;
		display:inline-block;
		height: 60px;
	}
	.suspensions {
		font-weight: 600;
		line-height: 15px;
	}
	.webnote {
		font-weight: 600;
	}
	ul {
		margin-left: 20px;
		list-style-type: disc;
	}
	li {
		list-style: disc;

	}
	.border {
		border-bottom: 1px solid #dedede;
	}
	.danger {
		color: #dc3545;
	}
	.el-select {
		margin-left: 15px;
	}
	.nogames {
		color: #dc3545;
		font-size: 16px;
		font-weight: 800;
	}
	.dropdowns {
		margin-left: 60px;
		position: absolute;
		width: 100%;
	}
</style>
