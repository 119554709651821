<template>
	<div class="FormDate el-input" :class="{ 'is-disabled' : disableDate,'el-input--small' : size == 'small','el-input--default' : size == 'default' || size == '' }">
	  <div class="el-input__wrapper"  @keyup.capture="updateValue" >
			<input
				ref="month"
				v-model="month"
				class="FormDate__input FormDate__input--month"
				:disabled="disableDate"
				placeholder="mm"
				v-maska="'##'"
				@input="updateMonth"
				@blur="month = (month+'').substring(0, 2).padStart(2, 0)">
			<span class="FormDate__divider">/</span>
			<input
				ref="day"
				v-model="day"
				class="FormDate__input FormDate__input--day"
				:disabled="disableDate"
				placeholder="dd"
				v-maska="'##'"
				@input="updateDay"
				@blur="day = (day+'').substring(0, 2).padStart(2, 0)">
			<span class="FormDate__divider">/</span>
			<input
				ref="year"
				v-model="year"
				class="FormDate__input FormDate__input--year"
				:disabled="disableDate"
				maxlength="4"
				placeholder="yyyy"
				v-maska="'####'"
				@blur="year = (year+'').substring(0, 4).padStart(4, 0)">
	  </div>
	</div>
</template>

<script>
	export default  {
		name: `FormDate`,
			props: {
				modelValue: {
				type: [Number, String],
				required: true,
			},
			disableDate: {
				type: Boolean,
				default: false,
			},
			size: {
				type: String,
			}
		},
		emits: ['update:modelValue'],
		data() {
			return {
				day: `${this.modelValue ? this.modelValue.substring(8,10) : ``}`,
				month: `${this.modelValue ? this.modelValue.substring(5,7) : ``}`,
				year: `${this.modelValue ? this.modelValue.substring(0,4): ``}`,
			};
		},
		watch: {
			modelValue(current, prev) {
				if (prev == '') {
					this.day = `${this.modelValue ? this.modelValue.substring(8, 10) : ``}`;
					this.month = `${this.modelValue ? this.modelValue.substring(5, 7) : ``}`;
					if (this.modelValue.substring(0, 4) == '0000') {
						this.year = '';
					} else {
						this.year = `${this.modelValue ? this.modelValue.substring(0, 4) : ``}`;
					}
				}
				this.updateValue();
			},
		},
		methods: {
			updateDay() {
				if (!this.day.length || parseInt(this.day) < 4) {
					this.updateValue();
					return;
				}
				this.$refs.year.select();
			},
			updateMonth() {
				if (!this.month.length || parseInt(this.month) < 2) {
					this.updateValue();
					return;
				}
				this.$refs.day.select();
			},
			updateValue() {
				this.$emit('update:modelValue', `${(this.year+"").padStart(4, 0)}-${this.month}-${this.day}`);
			},
		},
	};
</script>
<style lang="scss">
.el-input--small .FormDate__input, .el-input--mini .FormDate__input {
	padding-top: 0px;
}
.el-input--small.is-disabled .FormDate__input, .el-input--mini.is-disabled .FormDate__input {
	color: #C0C4CC;
}
.FormDate {
	$spacing: 0.75em;
	display: block;
	position: relative;
	overflow: hidden;
	border-radius: 0.25em;
	background-color: #FFF;

	// 1. Hide the spinner button in Chrome, Safari and Firefox.
	&__input {
		padding: $spacing;
		padding-right: $spacing / 2;
		padding-left: $spacing / 2;
		border: none;
		background: none;
		text-align: center;
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-moz-appearance: textfield; // 1

		&::-webkit-inner-spin-button {
			display: none; // 1
		}

		&:first-child {
			padding-left: $spacing;
		}

		&:last-child {
			padding-right: $spacing;
		}

		&:focus {
			outline: none;
		}

		&--day,
		&--month {
			width: 3em;
		}

		&--year {
			width: 4em;
		}
	}

	&__divider {
		padding-top: $spacing;
		padding-bottom: $spacing;
		pointer-events: none;
	}
	.disabled {
		background-color: #F5F7FF;
	}
}
.FormDate.disabled {
    background-color: #F5F7FF;
}
.FormDate.el-input.el-input--default {
	height: 32px;
}
.FormDate.el-input.el-input--small {
	height: 24px;
}
.FormDate.el-input {
	width: 150px;
}
.FormDate__input.FormDate__input--month, .FormDate__input.FormDate__input--day {
	width: 27%;
}
.FormDate__input.FormDate__input--year {
	width: 46%;
}
.el-input--small .FormDate__input {
	padding-top: 9px;
}
</style>
