<template>
    <div id="balances">
        <div v-if="!ProgramIDAccess" class="row">
            <div class="col-12 fullpage">
                <FinanceDropdowns @dropdownsChange="dropdownsChange" :config="config" :parentrefs="parentrefs" :calling="'InvoiceBalances'" />
                <div v-if="modifyAccess" class="mt-4" style="border: 2px solid #000; padding: 10px;margin: 0 ;width: 97%;" >
                <h5> Carry Balances Forward</h5>
                    <p>Select the New Carry Forward Season to move this Seasons balances to. Then enter a description for the Credit/Debit that will be created.  Finally click the
                    Carry Balances Forward button and all non-zero balances will have a credit or debit balance created for the Carry Forward Season.</p>
                    <div class="mt-2">
                        <el-select key="selectseason" v-model="CarryForwardSeasonID" placeholder="Carry Forward Season" style="width: 200px;" default-first-option filterable>
                            <template v-for="item in ActiveSeasons">
                                <el-option
                                    v-if="SeasonID < item.SeasonID"
                                    :key="item.SeasonID"
                                    :label="item.SeasonName"
                                    :value="item.SeasonID">
                                </el-option>
                            </template>
                        </el-select>
                    </div>
                    <div style="width: 100%;" class="mt-2">
                        <el-input v-model="Description" placeholder="Credit/Debit Description" class="description" >
                        </el-input>
                    </div>
                    <el-button class="mt-2 mb-1" @click="carryForward" type="success" :disabled="Balances.length <= 0 || !CarryForwardSeasonID || !InvoiceSettingID || !Description">
                        <span> Carry Balances Forward </span><i class="fa-solid fa-caret-right"></i>
                    </el-button>
                </div>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-12 fullpage" v-loading="loading">
                <el-table
                    :data="Balances"
                    stripe border
                    ref="balancetable"
                    @selection-change="handleSelectionChange"
                    show-summary
                    :summary-method="getSummaries"
                    size="small">
                    <el-table-column type="expand" fixed>
                        <template #default="props">
                            <span>
								<div class="row detailheader">
									<div class="col-3">Type</div>
									<div class="col-3">Date</div>
									<div class="col-3">Description</div>
									<div class="col-3">Amount</div>
								</div>
								<div class="row" v-for="(item, index) in props.row.details" :class="{even : index % 2 == 0}">
									<div style="line-height: 22px;" class="col-3">{{item.Type}}</div>
									<div style="line-height: 22px;" class="col-3" v-html="(item.Date)"></div>
									<div style="line-height: 22px;" class="col-3">{{item.Description}}</div>
									<div style="line-height: 22px;" class="col-3" v-html="formatPrice(item.Amount)"></div>
								</div>
							</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="modifyAccess"
                        type="selection"
                        align="center"
                        fixed
                        width="55">
                    </el-table-column>
                    <el-table-column
                        prop="OrganizationName"
                        label="Organization"
                        min-width="150px"
                        fixed
                        sortable
                    >
                    </el-table-column>
	                <el-table-column
		                prop="TeamRegInvoicedAmount"
		                label="Team Reg. Invoices"
		                align="right"
		                min-width="125px">
		                <template #default="scope">
			                <div v-html="formatPrice(scope.row.TeamRegInvoicedAmount)"></div>
		                </template>
	                </el-table-column>TeamRegInvoicedAmount
	                <el-table-column
		                prop="InvoicedAmount"
		                label="Game Invoices"
		                align="right"
		                min-width="125px">
		                <template #default="scope">
			                <div v-html="formatPrice(scope.row.InvoicedAmount)"></div>
		                </template>
	                </el-table-column>TeamRegInvoicedAmount
	                <el-table-column
		                prop="BalanceForwardAmount"
		                label="Incl. Balance Forward"
		                align="right"
		                min-width="125px">
		                <template #default="scope">
			                <div v-html="formatPrice(scope.row.BalanceForwardAmount)"></div>
		                </template>
	                </el-table-column>
	                <el-table-column
		                prop="PaymentAmount"
		                label="Payments"
		                align="right"
		                min-width="125px">
		                <template #default="scope">
			                <div v-html="formatPrice(scope.row.PaymentAmount)"></div>
		                </template>
	                </el-table-column>
	                <el-table-column
		                prop="CreditAmount"
		                label="Credits"
		                align="right"
		                min-width="125px">
		                <template #default="scope">
			                <div v-html="formatPrice(scope.row.CreditAmount)"></div>
		                </template>
	                </el-table-column>
	                <el-table-column
		                prop="Balance"
		                label="Balance"
		                align="right"
		                sortable
                        fixed="right"
		                :sort-method="sortAmount"
		                min-width="125px">
		                <template #default="scope">
			                <div v-html="formatPrice(scope.row.Balance)"></div>
		                </template>
	                </el-table-column>
                 </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Util from "../assets/js/util";
	import FinanceDropdowns from './global/FinanceDropdowns.vue';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
    export default {
        data () {
            return {
                loading : false,
                currentStatus : STATUS_INITIAL,
                Balances: [],
                BalanceOrganizations : [],
                OrganizationID: '',
                organizationsSelected : [],
                LeagueID : '',
                TeamID : '',
                SeasonID : '',
                CarryForwardSeasonID : '',
                ActiveSeasons : [],
                Description : '',
                InvoiceSettingID : '',
            }
        },
        name: 'InvoiceBalances',
        components: {
            FinanceDropdowns
        },
        props:["config", "parentrefs"],
        computed: {
            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            modifyAccess () {
                return this.$modifyAccess();
            },
            createAccess () {
                return this.$modifyAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
        },
        methods: {
            handleSelectionChange(val) {
                this.organizationsSelected = val;
            },
            formatPrice(value) {
                return Util.formatPrice(this.config, value , '0');
            },
            sortAmount(a, b) {
                var ret = parseInt(a.Balance) - parseInt(b.Balance);
                return ret;
            },
            dropdownsChange (OrganizationID, LeagueID, InvoiceSettingID, SeasonID, TeamID) {
				this.OrganizationID = OrganizationID;
                this.LeagueID = LeagueID;
				this.InvoiceSettingID = InvoiceSettingID;
				this.SeasonID = SeasonID;
                this.TeamID = TeamID;
				this.getBalances();
			},
	        getSummaries(param) {
		        const { columns, data } = param;
		        const sums = [];
		        columns.forEach((column, index) => {
			        if (index === 2) {
				        sums[index] = 'Totals';
				        return;
			        }
			        const values = data.map(item => Number(item[column.property]));

			        if (!values.every(value => isNaN(value))) {
				        if (index >= 3) {
					        sums[index] = this.formatPrice(values.reduce((prev, curr) => {
						        const value = Number(curr);
						        if (!isNaN(value)) {
							        return prev + curr;
						        } else {
							        return prev;
						        }
					        }, 0));
				        } else {
					        sums[index] = '';
				        }
			        } else {
				        sums[index] = '';
			        }
		        });
		        return sums;
	        },
            getInitialData() {
                var tables = ['ActiveSeasons'];
                axios.post('/index/tableData', {'tables': tables},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
                        }
                    }).catch(response => {

                });
            },
            getBalances() {
                if (this.InvoiceSettingID && this.SeasonID) {
                    this.loading = true;
                    this.Balances = [];
                    let self = this;
                    axios.post('/finance/getbalances', {
                            OrganizationID: this.OrganizationID,
                            SeasonID: this.SeasonID,
                            InvoiceSettingID: this.InvoiceSettingID,
                            LeagueID : this.LeagueID,
                            ProgramID: (this.ProgramIDAccess ? this.currentUser.ProgramID : '')
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.Balances = (response.data.Balances ? response.data.Balances : []);
                                this.$stopProgressBar(this.parentrefs);
                            } else {
                                this.$failProgressBar(this.parentrefs);
                            }
                            this.loading = false;
                        }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                    });
                } else {
                    this.Balances = [];
                }
            },
            carryForward() {
                if (this.SeasonID == this.CarryForwardSeasonID) {
                    this.displayPopup('Current Season and Carry Forward Season Must not be the same.');
                    return;
                }

                if (!this.Description) {
                    this.displayPopup('Credit/Debit Description is required.');
                    return;
                }

                var OrganizationIDs = [];
                for (let i = 0; i < this.organizationsSelected.length; i++) {
                    if (this.organizationsSelected[i]['Balance'] != 0) {
                        OrganizationIDs.push({
                            OrganizationID: this.organizationsSelected[i]['OrganizationID'],
                            Balance: this.organizationsSelected[i]['Balance']
                        });
                    }
                }
                if (OrganizationIDs.length > 0) {
                    this.currentStatus = STATUS_SAVING;
                    this.$startProgressBar(this.parentrefs);
                    let self = this;
                    axios.post('/finance/carryforwardbalances', {
                            SeasonID: this.SeasonID,
                            InvoiceSettingID : this.InvoiceSettingID,
                            LeagueID : this.LeagueID,
                            CarryForwardSeasonID: this.CarryForwardSeasonID,
                            OrganizationIDs: OrganizationIDs,
                            Description: this.Description
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                self.$store.dispatch('setID', {'CarryForwardSeasonID': self.CarryForwardSeasonID});
                                this.currentStatus = STATUS_INITIAL;
                                this.$notify({
                                    title: 'Success',
                                    message: "Balances have been carried forward",
                                    type: 'success',
                                    duration: 2500,
                                    showClose: false
                                });
                                this.$stopProgressBar(this.parentrefs);
                                this.getBalances();
                            } else {
                                this.currentStatus = STATUS_FAILED;
                                this.displayPopup(response.data.message);
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                            this.currentStatus = STATUS_FAILED;
                            this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.displayPopup('You need to select at least one Organization with a Balance.');
                }
            },
            displayPopup (message) {
                this.$alert(message, 'Error', {
                    confirmButtonText: 'OK',
                });
            }
        },
        mounted() {
            this.getInitialData();
        },
    }
</script>
<style>
	#balances .el-table__footer div.cell {
		font-size: 12px;
		font-weight: 800;
		color: #000;
	}
</style>
<style scoped>
    .row.header {
        font-weight: 800;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        height: 50px;
    }

    .odd {
        background-color: #efefef;
    }
    .balancesTable {
        border: 1px solid #f0f0f0;
        padding: 0px 15px;
    }
    .detailheader {
        border-bottom: 1px solid #e0e0e0;
    }
    .even {
        background-color: #f5f5f5;
    }
    .description.el-input {
        width: 250px;
    }
    #app label.control-label {
        width: 125px;
    }
    .topDropdown {
        max-width: 300px;
        margin-left: 3px;
    }
</style>
