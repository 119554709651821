<template>
    <div id="teamplayerpayments">
        <div v-if="PaymentLeagues.length > 1" class="row">
            <div class="col-12 fullpage">
                <div class="program-select">
                    <el-select v-model="LeagueID" class="topDropdown"  @change="leagueChange" placeholder="Select League" default-first-option filterable>
                        <el-option v-for="type in PaymentLeagues"
                                   :value="type.LeagueID"
                                   :key="type.LeagueID"
                                   :label="type.LeagueName">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
	    <div class="row mt-2">
		    <div class="col-12 fullpage">
			    <div class="program-select">
				    <el-select key="selectseason" v-model="SeasonID" class="topDropdown" @change="seasonChange" placeholder="Select Season" default-first-option filterable>
					    <el-option
						    v-for="item in ActiveSeasons"
						    :key="item.SeasonID"
						    :label="item.SeasonName"
						    :value="item.SeasonID">
					    </el-option>
				    </el-select>
			    </div>
		    </div>
	    </div>
        <div v-if="LeagueID && SeasonID && OrganizationPrograms.length != 1" class="row mt-2">
            <div class="col-12 fullpage">
                <div class="program-select">
                    <el-select v-model="ProgramID" class="topDropdown" @change="programChange" :placeholder="'Select ' + config.Params.programName" default-first-option filterable>
                        <el-option v-for="type in OrganizationPrograms"
                                   :value="type.ProgramID"
                                   :key="type.ProgramID"
                                   :label="(type.OrganizationName ? type.OrganizationName + ' (' + type.ProgramName + ')' : type.ProgramName)">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row mt-1 mb-4">
            <div class="col-12 fullpage">
	            <div class="buttons mb-1">
                    <el-button v-if="playerPayments.length > 0" type="info" class="btn-secondary" style="float:right;" @click="exportCSV">
                        <i class="fas fa-file-export"></i>  <span>Export Players</span>
                    </el-button>
                    <el-button v-if="emailSelection.length > 0" type="success" style="float:right;margin-right: 20px;" @click="getEmailDetails">
                        <i class="far fa-envelope"></i> <span> Email Players</span>
                    </el-button>
                </div>
                <el-table
                    :data="playerPayments"
                    border
                    max-height="600"
                    size="small"
                    show-summary
                    :summary-method="getSummaries"
                    empty-text="No Player Payments"
                    :span-method="arraySpanMethod"
                    :row-class-name="tableRowClassName"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                    <el-table-column
                        prop="PaymentDate"
                        label="Payment Date"
                        fixed
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="Name"
                        label="Name"
                        fixed
                        width="150"
                        sortable
                    >
                        <template #default="scope">
                            <span v-if="scope.row.PlayerID != '0'" v-html="scope.row.FirstName+' '+scope.row.LastName"></span>
                            <span v-else v-html="scope.row.TeamName"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="TeamName"
                        label="Team"
                        min-width="150"
                        sortable
                    >
                    </el-table-column>
                    <el-table-column
                        prop="RegistrationApprovalCode"
                        label="Reg. No"
                        width="130"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="Contact"
                        label="Contact Info"
                        min-width="150">
                        <template #default="scope">
                            <span v-html="scope.row.EMails + (scope.row.PhoneNumbers ? '<br>' + scope.row.PhoneNumbers : '')"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="PaymentTitle"
                        label="Payment"
                        min-width="150"
                        sortable
                    >
                    </el-table-column>
                    <el-table-column
                        prop="PaymentAmount"
                        label="Amount"
                        align="center"
                        width="120">
                        <template #default="scope">
                            <span v-html="formatPrice(scope.row.PaymentAmount)"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="modifyAccess"
                        label=""
                        width="120"
                        align="center">
                        <template #default="scope">
	                        <template v-if="TeamAccess(scope.row)">
	                            <a v-if="scope.row.recordstatus != 'A'" href="javascript:;" @click="activatePlayer(scope.$index, scope.row)" title="Activate"><i class="danger fas fa-times-circle"></i></a>
	                            <i v-else class="success fas fa-check-circle"></i>
	                        </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-else
                        label="Active"
                        width="100"
                        align="center">
                        <template #default="scope">
                            <i v-if="scope.row.recordstatus != 'A'" class="danger fas fa-times-circle"></i>
                            <i v-else class="success fas fa-check-circle"></i>
                        </template>
                    </el-table-column>

                </el-table>
            </div>
        </div>
        <modal v-if="showEmailModal" @close="showEmailModal = false" width="450px" :draggable="false" class="emailmodal">
	        <template v-slot:header>
		        <h5 class="modal-title">
			        Email Players
		        </h5>
	        </template>
	        <template v-slot:body>
                <div class="row emailmodal">
                    <el-form>
                        <div class="col-12">
                            <el-form-item label="From Email Prefix:" prop="fromPrefix">
                                <el-input style="width: 350px;"
                                          placeholder="From Prefix"
                                          v-model="fromPrefix">
                                    <template #append>@valley-associates.com</template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="col-12">
                            <el-collapse class="mt-4 mb-4" >
                                <el-collapse-item title="Replacement Variables" name="1">
                                    <ol><strong>For Emails, these fields can be used if enclosed with backticks.  i.e. `FirstName`<br></strong>
                                        <ul style="float:left;width: 50%;">
                                            <li>FirstName</li>
                                            <li>LastName</li>
                                            <li>EmailAddress</li>
                                            <li>IDNumber</li>
                                            <li>TeamName</li>
                                            <li>RegistrationApprovalCode</li>
                                            <li>TotalPaymentAmount</li>
                                            <li>Tuition</li>
                                            <li>Balance</li>
                                        </ul>
                                        <ul style="float:left;width: 40%;">
                                            <li>LeagueName</li>
                                            <li>ProgramName</li>
                                            <li>PayerTypeName</li>
                                            <li>JerseyNumber</li>
                                            <li>CellPhoneNumber</li>
                                            <li>ParentFirstName</li>
                                            <li>ParentLastName</li>
                                            <li>DOB</li>
                                        </ul>
                                    </ol>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="col-12">
                            <el-form-item label="Email Template" prop="SystemTextID">
                                <el-select key="emailtemplate" class="oneHundred"  v-model="SystemTextID" placeholder="Select" @change="prefillEmailTemplate" default-first-option filterable clearable>
                                    <el-option-group
                                        v-for="group in CustomEmailsGrouping"
                                        :key="group.label"
                                        :label="group.label"
                                    >
                                        <el-option
                                            v-for="item in group.options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-option-group>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-12">
                            <el-form-item label="Subject Line" prop="subject">
                                <el-input
                                    v-model="subject">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="col-12">
                            <el-form-item label="Message:" prop="message">
                                <el-input type="textarea" :autosize="{ minRows:8, maxRows: 45}" v-model="message">
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </template>
	        <template v-slot:footer>
	            <div style="width: 100%;">
	                <div class="col-12 text-center">
	                    <button class="btn btn-success btn-icon" type="button" @click="sendEmail">
	                        <template v-if="!sendingEmails" ><i class="far fa-envelope"></i> <span> Send Emails</span></template>
	                        <template v-else><i class="fas fa-spinner fa-pulse"></i> <span> Sending</span></template>
	                    </button>
	                    <button class="btn btn-icon btn-secondary cancel" type="button"  @click="showEmailModal = false">
	                        <i class="fas fa-times"></i>  <span>Cancel</span>
	                    </button>
	                </div>
	            </div>
	        </template>
        </modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Modal from './global/Modal.vue';
    import axios from 'axios';
    import Util from "../assets/js/util";
    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_FILESAVING1 = 4, STATUS_FILESAVING2 = 5;

    export default {
        name: 'TeamPlayerPayments',
        components: {
            Modal
        },
        data() {
            return {
                currentStatus : STATUS_INITIAL,
                modalTitle: 'Edit Player',
                modalFooter: '',
                showModal: false,
                showEmailModal : false,
                fromPrefix : 'support',
                sendingEmails : false,
                subject: '',
                message : '',
                emailSelection : [],
                OrganizationPrograms : [],
                SeasonTeams : [],
                Leagues : [{Params: {}}],
                ProgramID : '',
                LeagueID : '',
                TeamID : '',
                InvoiceAmounts : 0,
                PlayerPaymentAmounts: 0,
                user : [],
                playerPayments : [],
                ProgramLeagues: [],
                Params : [],
                SeasonID : '',
                ActiveSeasons : [],
                CustomEmails : [],
                CustomEmailsGrouping : [],
                SystemTextID : '',
                PaymentStatus : [{ID : 'A', Name : 'Active'},{ID : 'B', Name : 'Returned from Bank'},{ID : 'V', Name : 'Void'}],
            }
        },
        props:["config","Season", "parentrefs"],
        computed: {
            ...mapGetters({ currentUser: 'currentUser' }),
            ...mapGetters({ componentIDs: 'componentIDs' }),
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isFileSaving1() {
                return this.currentStatus === STATUS_FILESAVING1;
            },
            isFileSaving2() {
                return this.currentStatus === STATUS_FILESAVING2;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            modifyAccess () {
                return this.$modifyAccess();
            },
            createAccess () {
                return this.$createAccess();
            },
            ProgramIDAccess () {
                return this.$ProgramIDAccess();
            },
            TeamIDAccess () {
                return this.$TeamIDAccess();
            },
            PaymentLeagues () {
                var leagues = [];
                var leaguefound = false;
                for (let i = 0; i < this.Leagues.length; i++) {
                    if (this.Leagues[i].Params && this.Leagues[i].Params.TeamBilling == 'pl') {
	                    leagues.push(this.Leagues[i]);
	                    if (this.Leagues[i].LeagueID == this.LeagueID) {
		                    leaguefound = true;
	                    }
                    }
                }
                if (!leaguefound) {
                    this.LeagueID = '';
                    this.leagueChange();
                }
                if (!this.LeagueID && leagues.length > 0) {
                    this.LeagueID = leagues[0]['LeagueID'];
                }

                return leagues;
            },
            currencySign () {
                return Util.currencySign(this.config);
            },
            Balance () {
                return this.InvoiceAmounts - this.PlayerPaymentAmounts;
            }
        },
        methods: {
	        getSummaries(param) {

		        const { columns, data } = param;
		        const sums = [];
		        columns.forEach((column, index) => {
			        if (index === 0) {
				        sums[index] = 'Total';
				        return;
			        }
			        const values = data.map(item => Number(item[column.property]));
			        if (index === 1) {
				        sums[index] = values.length;
				        return;
			        }
			        if (!values.every(value => isNaN(value))) {
				        if (index == 6) {
					        sums[index] = this.formatPrice(values.reduce((prev, curr) => {
						        const value = Number(curr);
						        if (!isNaN(value)) {
							        return prev + curr;
						        } else {
							        return prev;
						        }
					        }, 0));
				        } else {
					        sums[index] = '';
				        }
			        } else {
				        sums[index] = '';
			        }
		        });
		        return sums;
	        },
            prefillEmailTemplate () {
                for (let i = 0; i < this.CustomEmails.length; i++) {
                    if (this.CustomEmails[i].SystemTextID == this.SystemTextID) {
                        this.subject = this.CustomEmails[i].Params.Subject;
                        this.message = this.CustomEmails[i].SystemText;
                        break;
                    }
                }
            },
            handleSelectionChange(val) {
                this.emailSelection = val;
            },
            sortTuition(a, b) {
                return parseFloat((a.Tuition ? a.Tuition : 0)) - parseFloat((b.Tuition ? b.Tuition : 0));
            },
            sortBalance(a, b) {
                return parseFloat((a.Balance ? a.Balance : 0)) - parseFloat((b.Balance ? b.Balance : 0));
            },
            leagueChange() {
                this.ProgramID = '';
	            this.playerPayments = [];
                this.TeamInvoices = [];
	            this.getPrograms();
            },
	        seasonChange () {
		        this.playerPayments = [];
		        this.TeamInvoices = [];
		        this.getPrograms();
	        },
            programChange() {
                this.playerPayments = [];
                this.getInvoices();
	            this.getPlayerPayments();
            },
            teamChange () {
	            this.InvoiceAmounts = 0;
	            if (this.TeamInvoices[this.TeamID]) {
		            for (let i = 0; i < this.TeamInvoices[this.TeamID].length; i++) {
			            this.InvoiceAmounts += this.TeamInvoices[this.TeamID][i].InvoiceAmounts;
		            }
	            }
	            this.getPlayerPayments();
            },

            TeamAccess(row) {
                if (this.TeamIDAccess.length == 0 || this.TeamIDAccess.indexOf(row.TeamID) >= 0) {
                    return true;
                } else {
                    return false;
                }
            },
            formatPrice(value) {
	            if (value) {
		            return Util.formatPrice(this.config, parseFloat(value), '0');
	            } else {
		            return '';
	            }
            },
            arraySpanMethod({row, column, rowIndex, columnIndex}) {
                if (row.PlayerID == 0) {
                    if (columnIndex == 1) {
                        if (!this.ProgramIDAccess && this.TeamIDAccess.length == 0) {
                            return [1, 9];
                        } else {
                            return [1, 8];
                        }
                    } else {
                        return [0, 0];
                    }
                } else {
                    return [1, 1];
                }
            },
            tableRowClassName({row, rowIndex}) {
                if (row.PlayerID == 0) {
                    return 'team-row';
                } else {
                    return '';
                }
            },
            getInitialData() {
                var tables = ['Leagues', 'ActiveSeasons'];
                axios.post('/index/tableData', {tables : tables},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            for (let value of tables) {
                                this[value] = response.data[value];
                            }
	                        if (this.componentIDs.TeamPlayerPaymentsLeagueID) {
		                        this.LeagueID = this.componentIDs.TeamPlayerPaymentsLeagueID;
	                        }
	                        if (this.componentIDs.TeamPlayerPaymentsSeasonID) {
		                        this.SeasonID = this.componentIDs.TeamPlayerPaymentsSeasonID;
	                        }
	                        if (this.componentIDs.TeamPlayerPaymentsProgramID) {
                                this.ProgramID = this.componentIDs.TeamPlayerPaymentsProgramID;
                            }
	                        if (!this.SeasonID) {
		                        this.SeasonID = this.ActiveSeasons[0]['SeasonID'];
		                        for (let  i = 0; i < this.ActiveSeasons.length; i++) {
			                        if (this.ActiveSeasons[i]['Params']['DefaultSeason'] == 'y') {
				                        this.SeasonID = this.ActiveSeasons[i]['SeasonID'];
				                        break;
			                        }
		                        }
	                        }
                            this.user = {...this.currentUser};
							this.getPrograms ();
                            this.getEmailTemplates();
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                });
            },
	        getPrograms () {
		        var tables = ['OrganizationPrograms'];
		        axios.post('/index/tableData', {tables : tables, LeagueID : this.LeagueID, SeasonID : this.SeasonID},
		        )
			        .then(response => {
				        if (response.data.result == 'success') {
					        for (let value of tables) {
						        this[value] = response.data[value];
					        }
							if (this.ProgramID) {
								//see if already selected program is good this season
								var programfound = false;
								for (let i=0; i < this.OrganizationPrograms.length; i++) {
									if (this.OrganizationPrograms[i].ProgramID == this.ProgramID) {
										programfound = true;
										break;
									}
								}
								if (!programfound) {
									this.ProgramID = '';
								} else {
									this.getInvoices();
								}
							}
				        }
			        }).catch(response => {
		        });
	        },
            getEmailTemplates () {
                axios.post('/systemtext/getcustomtext', {CustomTextID : 'emailtemplate', Status : 'A'}	)
                    .then(response => {
                        if (response.data.result == 'success') {
                            if (response.data.CustomEmails) {
                                this.CustomEmails = response.data.CustomEmails;
                                this.CustomEmailsGrouping = response.data.CustomEmailsGrouping;
                            }
							this. getInvoices();
                        }
                    }).catch(response => {
                });
            },
            getInvoices() {
                let self = this;
                this.InvoiceAmounts = 0;
	            if (this.LeagueID && this.SeasonID && this.ProgramID) {
                    axios.post('/analyzer/programs/getteaminvoices', {
		                    LeagueID: this.LeagueID,
		                    ProgramID: this.ProgramID,
		                    TeamIDs: [],
		                    SeasonID: this.SeasonID
                        }
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                self.$store.dispatch('setID', {'TeamPlayerPaymentsLeagueID': self.LeagueID});
	                            self.$store.dispatch('setID', {'TeamPlayerPaymentsSeasonID': self.SeasonID});
	                            self.$store.dispatch('setID', {'TeamPlayerPaymentsProgramID': self.ProgramID});

	                            this.InvoiceAmounts = (response.data.InvoiceAmounts ? response.data.InvoiceAmounts : 0);
								this.getPlayerPayments();
                            }
                        }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                    });
                }
            },

            getPlayerPayments() {
                let self = this;
                this.emailSelection = [];
                if (this.LeagueID && this.SeasonID && this.ProgramID) {
                    axios.post('/programplayers/getAllPayments', {
                            LeagueID: this.LeagueID,
                            ProgramID: this.ProgramID,
                            TeamIDs: [],
                            SeasonID: this.SeasonID
                        },
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                if (!response.data.playerpayments) {
                                    response.data.playerpayments = [];
                                }
                                this.PlayerPaymentAmounts = 0;
                                for (let i = 0; i < response.data.players.length; i++) {
                                    response.data.players[i].ParamsF = JSON.stringify(response.data.players[i].Params, null, '\t');
                                    this.Params.push(response.data.players[i].Params);
                                    this.PlayerPaymentAmounts += (response.data.players[i].TotalPaymentAmount ? response.data.players[i].TotalPaymentAmount : 0);
                                }

                                this.playerPayments = response.data.playerpayments;
                                this.$stopProgressBar(this.parentrefs);
                            } else {
                                this.playerPayments = [];
                                this.$failProgressBar(this.parentrefs);
                            }
                        }).catch(response => {
                        this.$failProgressBar(this.parentrefs);
                    });
                } else {
                    this.playerPayments = [];
                }
            },
            activatePlayer(index, row) {
                axios.post('/programplayers/activate', {'PlayerID': row.PlayerID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.refreshData();
                            this.$notify({
                                title: 'Success',
                                message: "Player has been Activated",
                                type: 'success',
                                duration: 1500,
                                showClose: false
                            });
                        } else {
                            this.$notify({
                                title: 'Error',
                                message: "Player cannot be Activated",
                                type: 'error',
                                duration: 2000,
                                showClose: false
                            });
                        }
                    }).catch(response => {
                    this.$notify({
                        title: 'Error',
                        message: "Player cannot be Activated",
                        type: 'error',
                        duration: 2000,
                        showClose: false
                    });
                });
            },
            displayPopup(message) {
                this.$alert(message, 'Player Error', {
                    confirmButtonText: 'OK',
                });
            },
            todaysDate() {
                var now = new Date();
                var y = now.getFullYear();
                var m = now.getMonth() + 1;
                var d = now.getDate();
                var mm = m < 10 ? '0' + m : m;
                var dd = d < 10 ? '0' + d : d;
                return '' + y + '-' + mm + '-' + dd;
            },
            getEmailDetails () {
                this.showEmailModal = true;
            },
            sendEmail () {
                if (!this.sendingEmails && this.emailSelection.length > 0) {
                    this.sendingEmails = true;

                    axios.post('/programcontacts/sendemail', {LeagueID : this.LeagueID, 'PlayerRecords': this.emailSelection, 'subject' : this.subject, 'message' : this.message, fromEmailAddress :  this.fromPrefix },
                    )
                        .then(response => {
                            this.sendingEmails = false;
                            if (response.data.result == 'success') {
                                this.$notify({
                                    title: 'Success',
                                    message: (response.data.sent == 1 ? '1 message has' : response.data.sent + ' messages have') + " been sent",
                                    type: 'success',
                                    duration: 1500,
                                    showClose: false
                                });
                                this.showEmailModal = false;
                            } else {
                                this.displayPopup (response.data.message);
                            }
                        }).catch(response => {
                        this.sendingEmails = false;
                    });
                }
            },
            exportCSV () {
                var list = this.playerPayments;
                var newList = [];
                let columns = [];
                for (let i = 0; i <= this.Params.length; i++) {
                    for (var property in this.Params[i]) {
                        if (this.Params[i].hasOwnProperty(property)) {
                            if (columns.indexOf(property) == -1) {
                                columns.push(property);
                            }
                        }
                    }
                }
                columns.sort();
                //this is a Program specific user and will have limited fields.
                for(let i=0; i<list.length; i++) {
                    if (this.ProgramIDAccess || this.TeamIDAccess.length > 0) {
                        delete list[i].ProgramID;
                        delete list[i].Name;
                        delete list[i].PhoneNumbers;
                        delete list[i].EMails;
                        delete list[i].DOB;
                        delete list[i].Address;
                        delete list[i].recordstatus;
                        delete list[i].IDNumber;
                        delete list[i].createdwhen;
                        delete list[i].modifiedwhen;
                        delete list[i].RegistrationApprovalCode;
                    } else {
                        for (let j=0; j<columns.length; j++) {
                            if (list[i]['Params']) {
                                if (list[i]['Params'][columns[j]]) {
                                    list[i][columns[j]] = list[i]['Params'][columns[j]];
                                } else {
                                    list[i][columns[j]] = '';
                                }
                            }
                        }
                    }
                    delete list[i].Params;
                    delete list[i].ParamsF;
                    if (list[i].PlayerID != '0') {
                        newList.push(list[i]);
                    }
                }

                var csv = Papa.unparse(newList);
                var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                var csvURL =  null;
                if (navigator.msSaveBlob)
                {
                    csvURL = navigator.msSaveBlob(csvData, 'players.csv');
                }
                else
                {
                    csvURL = window.URL.createObjectURL(csvData);
                }

                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', 'players.csv');
                tempLink.click();
            },
        },
        mounted () {
            this.getInitialData();
        },
    }
</script>
<style>
    #teamplayerpayments .el-table th {
        font-size: 120%;
        font-weight: 800;
    }
    #teamplayerpayments .modal-dialog {
        width: 362px;
    }
    #teamplayerpayments .emailmodal .modal-dialog {
        width: 500px;
    }
    #teamplayerpayments .modal-body {
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    #teamplayerpayments .modal-footer {
        height: 55px;
    }
    .teamplayerpayments div.el-table .cell {
        word-break: normal;
    }
    #teamplayerpayments .team-row td {
        text-align: left;
    }
    .emailmodal .el-form-item__label {
        line-height: 15px;
    }
	#teamplayerpayments .el-table__footer div.cell {
		font-size: 12px;
		font-weight: 800;
		color: #000;
	}
</style>
<style scoped>
    #teamplayerpayments .scores {
        width: 40px;
        text-align: center;
        padding: 1px;
        margin: 1px 0px;
        height: 23px;
    }
    #teamplayerpayments .checkbox {
        display: inline-block;
        width: 25px;
        height: 15px;
    }
    #teamplayerpayments label.checkbox {

    }
    #teamplayerpayments .webnotes {
        width: 90%;
        height: 40px;
        padding: 2px;
    }
    #teamplayerpayments .heading {
        font-weight: 800;
        background-color: #1A719C;
        color: #fff;
        font-size: 14px;
        height: 25px;
        margin: 0;
    }
    #teamplayerpayments .body {
        color: #333;
        font-size: 14px;
        padding: 2px;
    }
    #teamplayerpayments .body input, #teamplayerpayments .body select, #teamplayerpayments .body textarea {
        color: #333;
        font-size: 14px;
    }
    #teamplayerpayments .body select {
        padding: 1px;
    }

    #teamplayerpayments .body.row:nth-child(even){
        background-color: #dcdcdc;
    }
    #teamplayerpayments .body.row:nth-child(odd){
        background-color: #ffffff;
    }
    #teamplayerpayments .row.buttons {
        margin-top: 20px;
    }
	#teamplayerpayments .body.row:nth-child(even){
		background-color: #dcdcdc;
	}
	#teamplayerpayments .body.row:nth-child(odd){
		background-color: #ffffff;
	}
    select.not-available {
        width: auto;
        margin-left: 0px   !important;
    }
    .not-available label {
        margin-top: 4px;
    }
    .program-select {
        font-weight: 800;
    }
    .options {
        margin: 0px 5px;
    }
    .el-select {
        width: 100%;
        max-width: 300px;
    }
    .programName {
        display: inline-block;
        font-weight: 800;
    }
    .program-select .control-label {
        width: 120px;
    }
    .cell i {
        font-size: 16px;
    }
    .itemdetail .description {
        width: 200px;
    }
    .itemdetail .el-input {
        width: 150px;
    }

    .detailheader {
        border-bottom: 1px solid #e0e0e0;
    }
    .even {
        background-color: #f5f5f5;
    }
    .buttons {
        height: 40px;
    }
</style>
