<template>
	<div v-if="planFetched" id="app" :class="classname" :trigger="name">
		<div id="main-wrapper">
			<vue-min-header v-if="secure" :config="config" :helpText="helpText" :navType="(isAnalyzer ? 'A' : 'R')" :parentrefs="$refs" :isCollapse="isCollapse" @collapse="collapse" :page="fullPath"></vue-min-header>
			<Sidebar v-if="secure" :navItems="nav" :reportnavItems="reportNav" :isCollapse="isCollapse" :config="config" :height="windowHeight" :navType="(isAnalyzer ? 'A' : 'R')" @collapse="collapse" />
			<div id="page-wrapper" class="page-wrapper">
				<vue-min-breadcrumb v-if="secure" :route="name" ></vue-min-breadcrumb>
				<div class="container-fluid" id="contentStart">
                    <template v-if="secure && pageTextDisplay">
					    <div class="pageTextIntro" v-html="pageTextDisplay"></div>
                    </template>
					<router-view :key="$route.path" :secure="secure" :config="config" :height="windowHeight" :parentrefs="$refs" @updateSettings="updateSettings" @updateHelpID="updateHelpID"></router-view>
				</div>
			</div>
			<vue-footer v-if="secure" :config="config"></vue-footer>
		</div>
	</div>
</template>

<script>
import nav from './nav';
import navanalyzer from './navanalyzer';
import { mapGetters } from 'vuex';
import Preloader from './components/global/Preloader.vue';
import Header from './components/global/Header.vue';
import Breadcrumb from './components/global/Breadcrumb.vue';
import Sidebar from './components/global/Sidebar.vue';
import Footer from './components/global/Footer.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import Util from './assets/js/util.js';

	router.beforeEach((to, from, next) => {
		//first handle back and forth from analyzer
		if (to.fullPath == '/analyzer/') {
			if (localStorage.analyzerreturnURL && localStorage.returnURL != '/analyzer/') {
				next({
					path: localStorage.analyzerreturnURL,
					query: {}
				});
			} else {
				next({
					path: '/analyzer/dashboard',
					query: {}
				})
			}
		} else if (to.fullPath == '/') {
			if (localStorage.returnURL && localStorage.returnURL != '/' && loggedInUser()) {
				next({
					path: localStorage.returnURL,
					query: {}
				});
			} else {
				next({
					path: '/dashboard',
					query: {}
				})
			}
		} else {
			if (to.matched.some(record => record.meta.requiresAuth)) {
				// this route requires auth, check if logged in
				// if not, redirect to login page.
				if (!loggedInUser()) {
					localStorage.returnURL = to.fullPath;
					next({
						path: '/login',
						query: {}
					});
				} else {
					if (to.matched.some(record => record.meta.requiresSecurity)) {
						//user is logged in as required, now determine if their roles allows access to this page
						let validAccess = false;
						var fullPath = to.fullPath.substr(1).split('/');

						if (store.state.auth.user.RoleSecurity[fullPath[0]]) {
							if ((store.state.auth.user.RoleSecurity[fullPath[0]].Params.Access && store.state.auth.user.RoleSecurity[fullPath[0]].Params.Access == 'true') || store.state.auth.user.RoleSecurity[fullPath[0]].Params.ReadAccess) {
								validAccess = true;
							}
						}
						if (validAccess && loggedInUser()) {
							if (to.fullPath.indexOf('analyzer') !== -1) {
								localStorage.analyzerreturnURL = to.fullPath;
							} else {
								localStorage.returnURL = to.fullPath;
							}
							next();
						} else {
							next({
								path: '/dashboard',
								query: {}
							});
						}
					} else if (loggedInUser()) {
						if (to.fullPath.indexOf('analyzer') !== -1) {
							localStorage.analyzerreturnURL = to.fullPath;
						} else {
							localStorage.returnURL = to.fullPath;
						}
						next();
					}
				}
			} else {
				next(); // make sure to always call next()!
			}
		}
	});

	function loggedInUser () {
		if (Util.readCookie('login') && store.state.auth.user) {
			return true;
		} else {
			return false;
		}
	}
	function convertSportsTerm (SecurityAreaName) {
		return Util.convertSportsTerm(config,SecurityAreaName);
	}
    export default {
        data (){
          	return{
                config: {Params : {}},
	            helpText : '',
                pageText : [],
                pageHelpID : '',
	            isCollapse : false,
	            windowHeight: 600,
	            trickleSpeed: 250,
                reportNav : [],
                reportNavFetched : false,
				planFetched : false,  
          	}
        },
        components: {
			VueMinPreloader: Preloader,
			VueMinHeader: Header,
			VueMinBreadcrumb: Breadcrumb,
			Sidebar,
			VueFooter: Footer
        },
        computed: {
	        nav() {
	        	//want to erase this cookie as it prevents notifications from being called
				//and if we are getting nav, we know long running jobs have stopped.
		        Util.eraseCookie('scheduleRunning');
		        return (this.$route.meta.isAnalyzer ? navanalyzer.items : nav.items);
	        },
			name() {
	        	if (this.$route.name) {
			        var title =  Util.convertSportsTerm(this.config,this.$route.name);
					document.title = title;
					return title;
		        } else {
	        		return 'test';
		        }
			},
	        isAnalyzer () {
		        return (this.$route.fullPath.indexOf('analyzer') === -1  ? false : true);
	        },
			classname(){
				if (this.$route.name) {
					return this.$route.name.replace(/\s/g, '').toLowerCase() + ' ' + (this.isAnalyzer ? 'Analyzer' : 'Frontend') + (this.config.isMobile ? ' isMobile' : '');
				} else {
					return '';
				}
			},
			securityPath() {
	        	var fullPath = this.$route.fullPath.substr(1).split('/');
				return fullPath[0];
			},
			secure(){
				return this.$route.meta.requiresAuth;
			},
	        list() {
		        return this.$route.matched;
	        },
            pageTextDisplay () {
	            if (this.pageText && this.pageText[this.pageHelpID]) {
                    return Util.nl2br(this.pageText[this.pageHelpID]);
                } else {
                    return '';
                }
            },
			...mapGetters({ currentUser: 'currentUser' }),

		},
	    watch:{
		    $route (to, from){
		        if (to.name == 'Login' || to.name == 'Logout' ) {
                    this.reportNavFetched = false;
                }
				if (this.currentUser && this.$Plan.PlanType == 'X') {
					//plan expired, only allow access to Plan screen
					this.$router.replace('/plans');
				} else {
					this.$noDataToBeSaved();
					this.getHelp(to.fullPath);
					this.fullPath = to.fullPath;
					this.getReportNav();
				}
		    }
	    },
		methods: {
			updateSettings () {
				setTimeout(() => { self.$router.go(this.$router.currentRoute); }, 500);
			},
            updateHelpID (pageHelpID) {
                this.pageHelpID = pageHelpID;
            },
            getHelp (helpPage) {
                if (this.secure) {
                    axios.post('/systemtext/get', {SystemTextID: helpPage, isHelp : 'y', CustomerID : (this.currentUser.CustomerID > 0 ? this.currentUser.CustomerID : 0)}
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                if (response.data.SystemText) {
	                                let firstchar = response.data.SystemText.SystemText.charAt(0);
                                	if (firstchar == '<') {
		                                this.helpText = response.data.SystemText.SystemText;
	                                } else {
		                                this.helpText = Util.nl2br(response.data.SystemText.SystemText);
	                                }
                                    if ((this.$createAccess() || this.$modifyAccess()) && response.data.SystemText.Params.AdminSystemText) {
                                        this.helpText += ( this.helpText ? '<br><br>' : '')  + Util.nl2br(response.data.SystemText.Params.AdminSystemText);
                                    }
                                    this.pageText = response.data.SystemText.PageText;
                                } else {
                                    this.helpText = '';
                                    this.pageText = [];
                                }
								this.helpText = Util.convertSportsTerm(this.config,this.helpText);
                            }
                        }).catch(response => {

                    });
                }

            },
            getReportNav () {
                if (this.secure && !this.reportNavFetched && this.$featureAllowed('reportwriter')) {
                    axios.post('/reportwriter/getallnav'
                    )
                        .then(response => {
                            if (response.data.result == 'success') {
                                if (response.data.nav) {
                                    this.reportNav = (response.data.nav ? response.data.nav : []);
                                }
                                this.reportNavFetched = true;
                            }
                        }).catch(response => {

                    });
                }

            },
            setStyles () {

			if (!this.config.Params.TitleBackgroundColor) {
					this.config.Params.TitleBackgroundColor = this.config.Params.PrimaryColor;
				}
				if (!this.config.Params.TitleColor) {
					this.config.Params.TitleColor = '#FFFFFF';
				}
				if (!this.config.Params.HeaderBackgroundColor) {
					this.config.Params.HeaderBackgroundColor = this.config.Params.NavBackgroundColor;
				}
				if (!this.config.Params.AnalyzerTitleBackgroundColor) {
					this.config.Params.AnalyzerTitleBackgroundColor = this.config.Params.AnalyzerPrimaryColor;
				}
				if (!this.config.Params.AnalyzerTitleColor) {
					this.config.Params.AnalyzerTitleColor = '#FFFFFF';
				}
				if (!this.config.Params.AnalyzerHeaderBackgroundColor) {
					this.config.Params.AnalyzerHeaderBackgroundColor = this.config.Params.NavBackgroundColor;
				}
	            let style = document.createElement('style');
	            document.head.append(style);
                style.innerText = '#app {--el-color-primary: ' + this.config.Params.PrimaryColor + ';} ' +
					'#app {--el-checkbox-checked-bg-color: ' + this.config.Params.PrimaryColor + ';} ' +
					'#app {--el-checkbox-checked-input-border-color: ' + this.config.Params.PrimaryColor + ';} ' +
					':root {--bs-body-bg: ' + this.config.Params.BackgroundColor + ';} ' +
					
					'#app.Analyzer {--el-color-primary: ' + this.config.Params.AnalyzerPrimaryColor + ';} ' +
					'#app.Analyzer {--el-checkbox-checked-bg-color: ' + this.config.Params.AnalyzerPrimaryColor + ';} ' +
					'#app.Analyzer {--el-checkbox-checked-input-border-color ' + this.config.Params.AnalyzerPrimaryColor + ';} ' +
					
					'#app .bg-primary { background-color: ' + this.config.Params.PrimaryColor + ' !important;} ' +
	                '#app .text-primary, .el-dialog__title {   color: ' + this.config.Params.PrimaryColor + ' !important;} ' +
	                '#app .background-primary {   background-color: ' + this.config.Params.NavColor + ' !important; } ' +
	                '#app a, #app .el-menu--vertical .el-menu-item a.router-link-active, #app .el-menu--vertical .el-menu-item a.router-link-active, .el-menu--vertical ul li a, li.el-select-dropdown__item.selected, .el-select-dropdown.is-multiple .el-select-dropdown__item.selected, li.el-cascader-menu__item.is-active, div.el-tabs__item.is-active { color: ' + this.config.Params.PrimaryColor + ';}' +
					'#app .page-item.active a.page-link {background-color: ' + this.config.Params.PrimaryColor + ';border-color: ' + this.config.Params.PrimaryColor + ';} ' +
					'#app .VueTables__table th, div.el-tabs__active-bar {background-color: ' + this.config.Params.PrimaryColor + ';} ' +
                    '.el-menu-vertical li.el-menu-item:hover i, .el-menu--vertical ul li a:hover, .el-menu--vertical li.el-menu-item:hover i, #app a .el-menu-item:hover, .el-menu--vertical a .el-menu-item:hover, #app .el-sub-menu__title:hover i, #app .el-sub-menu__title:hover span, #app a .el-menu-item:hover li, .el-menu--vertical .el-sub-menu__title:hover span, .el-menu--vertical a .el-menu-item:hover li, #app .el-menu-item.is-active  i, #app .el-menu-item:hover i, #app .el-menu-item:hover span, #app .el-menu-item.is-active span, #app .router-link-active .el-menu-item span, #app .router-link-active .el-menu-item i, #app .navbar-collapse .dropdown.nav li:hover i, #app .navbar-collapse .dropdown.nav li:hover a { color: ' + this.config.Params.PrimaryColor + ';}' +
					'#app .btn-link { color: ' + this.config.Params.PrimaryColor + ';}' +
					'#app .wizard__next, #app .wizard__back { background-color: ' + this.config.Params.PrimaryColor + ';} ' +
					'#app a.wizard__next:hover, #app a.wizard__back:hover { opacity: 0.5; background-color: ' + this.config.Params.PrimaryColor + ';} ' +
					'#app a.wizard__next span, #app a.wizard__next, #app a.wizard__back span { color: #fff;} ' +
					'#app .el-pagination .el-button--primary { background-color: ' + this.config.Params.PrimaryColor + '; border-color: ' + this.config.Params.PrimaryColor + ';}' +
					'#app .el-pagination .el-button--primary:hover { opacity: 0.7; background-color: ' + this.config.Params.PrimaryColor + '; border-color: ' + this.config.Params.PrimaryColor + ';}' +
					'.el-table__body tr.el-table__row.team-row td { background-color: ' + this.config.Params.PrimaryColor + '; color: #FFFFFF;}' +
					'.dashboard	div.el-table th, #billings .el-table__fixed-footer-wrapper tbody td, #billings .el-table__footer-wrapper tbody td, #billings  .el-table__header-wrapper tbody td { background-color: ' + this.config.Params.PrimaryColor + '; color: #FFFFFF;}' +
					'#app .el-table th, #programlocations .heading, #formsubmissionreport table thead, #gamereporting table thead, #unassignedgames .tableheader { background-color: ' + this.config.Params.PrimaryColor + '; color: #FFFFFF;}' +
                    '#sidebarnav, #app .el-submenu, #app .el-menu { background-color: ' + this.config.Params.NavBackgroundColor + ';}' +
                    '#app .navbar-collapse .dropdown.nav .dropdown-menu, .el-menu.el-menu--popup.el-menu--popup-right-start, #app .header.is_stuck .navbar, #app .header .top-navbar .navbar-header { background-color: ' + this.config.Params.HeaderBackgroundColor + ';}' +
					'#app .header .top-navbar .navbar-header, #app .el-sub-menu__title, #app a .el-menu-item, #app .el-menu-item i, #app .el-sub-menu__title i,  #app .el-menu-item span, #app .el-sub-menu__title span, .el-menu--vertical a .el-menu-item, .el-menu--vertical .el-menu-item i, .el-menu--vertical .el-sub-menu__title i { color: ' + this.config.Params.NavColor + ';}' +
                    '#app .navbar-collapse .SchedulingServerRunning { border-color: ' + this.config.Params.IconColor + ';}' +
                    '#app .navbar-collapse i, #app .navbar-collapse span, #app .navbar-collapse .dropdown.nav a, #app .navbar-collapse .dropdown-menu i, #app .navbar-collapse .SchedulingServerRunning a { color: ' + this.config.Params.IconColor + ';}' +
					'#app .navbar-collapse div.bar1, #app .navbar-collapse div.bar2, #app .navbar-collapse div.bar3 { background-color: ' + this.config.Params.IconColor + ';}'
					+ (this.config.Params.TitleBackgroundColor ? '#app .page-titles, #app .footer, #app .text-navbackground { background-color: ' + this.config.Params.TitleBackgroundColor + ';}' : '')
					+ (this.config.Params.TitleColor ? '#app .text-navbackground { color: ' + this.config.Params.TitleColor + ';}' : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .bg-primary { background-color: ' + this.config.Params.AnalyzerPrimaryColor + ' !important;} '  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .text-primary, #app.Analyzer .el-dialog__title {   color: ' + this.config.Params.AnalyzerPrimaryColor + ' !important;} '  : '')
	                + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .background-primary {   background-color: ' + this.config.Params.AnalyzerNavColor + ' !important; } ' : '')
	                + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer a, #app.Analyzer .el-menu--vertical .el-menu-item a.router-link-active i, #app.Analyzer .el-menu--vertical .el-menu-item a.router-link-active span, #app.Analyzer .el-menu--vertical ul li a, .Analyzer li.el-select-dropdown__item.selected, .Analyzer .el-select-dropdown.is-multiple .el-select-dropdown__item.selected, #app.Analyzer li.el-cascader-menu__item.is-active, #app.Analyzer div.el-tabs__item.is-active { color: ' + this.config.Params.AnalyzerPrimaryColor + ';}' : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .page-item.active a.page-link {background-color: ' + this.config.Params.AnalyzerPrimaryColor + ';border-color: ' + this.config.Params.AnalyzerPrimaryColor + ';} ' : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .VueTables__table th, #app.Analyzer div.el-tabs__active-bar {background-color: ' + this.config.Params.AnalyzerPrimaryColor + ';} ' : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .el-menu-vertical li.el-menu-item:hover i, #app.Analyzer .el-menu-vertical li.el-menu-item:hover span, #app.Analyzer ul.el-menu--vertical li a:hover, #app.Analyzer .el-menu--vertical li.el-menu-item:hover i,  #app.Analyzer .el-menu--vertical li.el-menu-item:hover span, #app.Analyzer .el-menu-item:hover, #app.Analyzer .el-menu--vertical .el-menu-item:hover, #app.Analyzer .el-sub-menu__title:hover i, #app.Analyzer .el-sub-menu__title:hover span, #app.Analyzer a .el-menu-item:hover li, #app.Analyzer .el-menu--vertical .el-sub-menu__title:hover span, #app.Analyzer .el-menu--vertical a .el-menu-item:hover li, #app.Analyzer .el-menu-vertical .router-link-active .el-menu-item  i, #app.Analyzer  .el-menu-item:hover i, #app.Analyzer  .el-menu-vertical .router-link-active .el-menu-item  span, #app.Analyzer  .navbar-collapse .dropdown.nav li:hover i, #app.Analyzer .navbar-collapse .dropdown.nav li:hover a, #app.Analyzer  .navbar-collapse .dropdown-menu i { color: ' + this.config.Params.AnalyzerPrimaryColor + ';}'  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .btn-link { color: ' + this.config.Params.AnalyzerPrimaryColor + ';}'  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .wizard__next, #app.Analyzer  .wizard__back { background-color: ' + this.config.Params.AnalyzerPrimaryColor + ';} '  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer a.wizard__next:hover, #app.Analyzer  a.wizard__back:hover { opacity: 0.5; background-color: ' + this.config.Params.AnalyzerPrimaryColor + ';} '  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .el-pagination .el-button--primary { background-color: ' + this.config.Params.AnalyzerPrimaryColor + '; border-color: ' + this.config.Params.AnalyzerPrimaryColor + ';}'  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .el-pagination .el-button--primary:hover { opacity: 0.7; background-color: ' + this.config.Params.AnalyzerPrimaryColor + '; border-color: ' + this.config.Params.AnalyzerPrimaryColor + ';}'  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .el-table__body tr.el-table__row.team-row td { background-color: ' + this.config.Params.AnalyzerPrimaryColor + '; color: #FFFFFF;}'  : '')
                    + (this.config.Params.AnalyzerPrimaryColor ?'#app.Analyzer .dashboard	div.el-table th, #billings .el-table__fixed-footer-wrapper tbody td, #app.Analyzer #billings .el-table__footer-wrapper tbody td, #app.Analyzer #billings  .el-table__header-wrapper tbody td { background-color: ' + this.config.Params.AnalyzerPrimaryColor + '; color: #FFFFFF;}' : '')
                    + (this.config.Params.AnalyzerPrimaryColor ? '#app.Analyzer .el-menu-vertical li.el-menu-item:hover i, #app.Analyzer ul.el-menu--vertical li a:hover, #app.Analyzer .el-menu--vertical li.el-menu-item:hover i, #app.Analyzer  a .el-menu-item:hover, #app.Analyzer .el-menu--vertical a .el-menu-item:hover, #app.Analyzer  .el-sub-menu__title:hover i, #app.Analyzer  .el-sub-menu__title:hover span, #app.Analyzer a .el-menu-item:hover li, #app.Analyzer .el-menu--vertical .el-sub-menu__title:hover span, #app.Analyzer .el-menu--vertical a .el-menu-item:hover li, #app.Analyzer  .router-link-active .el-menu-item  i, #app.Analyzer .el-menu-item:hover i, #app.Analyzer .el-menu-vertical .router-link-active .el-menu-item  span, #app.Analyzer .navbar-collapse .dropdown.nav li:hover i, #app.Analyzer .navbar-collapse .dropdown.nav li:hover a { color: ' + this.config.Params.AnalyzerPrimaryColor + ';}' : '')
                    + (this.config.Params.AnalyzerPrimaryColor ? '#app.Analyzer .el-table th, #app.Analyzer #programlocations .heading, #app.Analyzer #formsubmissionreport table thead, #app.Analyzer #gamereporting table thead, #app.Analyzer #unassignedgames .tableheader { background-color: ' + this.config.Params.AnalyzerPrimaryColor + '; color: #FFFFFF;}' : '')
                    + (this.config.Params.AnalyzerNavBackgroundColor ? '#app.Analyzer #sidebarnav, #app.Analyzer .el-submenu, #app.Analyzer .el-menu { background-color: ' + this.config.Params.AnalyzerNavBackgroundColor + ';}' : '')
                    + (this.config.Params.AnalyzerHeaderBackgroundColor ? '#app.Analyzer .navbar-collapse .dropdown.nav .dropdown-menu, #app.Analyzer .el-menu.el-menu--popup.el-menu--popup-right-start, #app.Analyzer .header.is_stuck .navbar, #app.Analyzer .header .top-navbar .navbar-header { background-color: ' + this.config.Params.AnalyzerHeaderBackgroundColor + ';}' : '')
                    + (this.config.Params.AnalyzerNavColor ? '#app.Analyzer .header .top-navbar .navbar-header, #app.Analyzer .el-sub-menu__title, #app.Analyzer a .el-menu-item, #app.Analyzer .el-menu-item i, #app.Analyzer .el-menu-item span, #app.Analyzer .el-sub-menu__title i, #app.Analyzer .el-sub-menu__title span, #app.Analyzer .el-sub-menu__title i, #app.Analyzer .el-menu--vertical a .el-menu-item, #app.Analyzer .el-menu--vertical .el-menu-item i, #app.Analyzer .el-menu--vertical .el-sub-menu__title i { color: ' + this.config.Params.AnalyzerNavColor + ';}' : '')
                    + (this.config.Params.AnalyzerIconColor ? '#app.Analyzer .navbar-collapse .SchedulingServerRunning { border-color: ' + this.config.Params.AnalyzerIconColor + ';}' : '')
                    + (this.config.Params.AnalyzerIconColor ? '#app.Analyzer .navbar-collapse .dropdown.nav i, #app.Analyzer .navbar-collapse i, #app.Analyzer .navbar-collapse .dropdown-menu i, #app.Analyzer .navbar-collapse span, #app.Analyzer .navbar-collapse .dropdown.nav a, #app.Analyzer .navbar-collapse .SchedulingServerRunning a { color: ' + this.config.Params.AnalyzerIconColor + ';}' : '')
                    + (this.config.Params.AnalyzerIconColor ? '#app.Analyzer .navbar-collapse div.bar1, #app.Analyzer .navbar-collapse div.bar2, #app.Analyzer .navbar-collapse div.bar3 { background-color: ' + this.config.Params.AnalyzerIconColor + ';}' : '')
                    + (this.config.Params.AnalyzerTitleBackgroundColor ? '#app.Analyzer .page-titles, #app.Analyzer .footer, #app.Analyzer .text-navbackground { background-color: ' + this.config.Params.AnalyzerTitleBackgroundColor + ';}' : '')
					+ (this.config.Params.AnalyzerTitleColor ? '#app.Analyzer .text-navbackground { color: ' + this.config.Params.AnalyzerTitleColor + ';}' : '')
					;

            },
			collapse(data) {
				this.isCollapse = data.isCollapse;
			}
		},
        created () {
            var configElement = document.getElementById( 'config-settings' );
            if (configElement && configElement.innerHTML != '') {
                this.config = JSON.parse( configElement.innerHTML );
                this.config.windowHeight = 600;
                this.setStyles();
            } else {
                axios.post('/settings/get', {},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.config = response.data.settings;
                            this.config.windowHeight = 600;
                            this.setStyles();
                        }
                    }).catch(response => {
                    this.$failProgressBar();
                });
            }
            this.config.isMobile = Util.isMobile();

			if (!this.planFetched) {
				//we do not have a plan, need to get the plan for this customer
				axios.post('/plans/getactive', {},
                )
                    .then(response => {
                        if (response.data.result == 'success') {
							this.$setPlan(response.data.Plan ? response.data.Plan : {});
							this.planFetched = true;
							this.getReportNav();
                        }
                    }).catch(response => {
                    	this.$failProgressBar();
                });
			}

            this.$nextTick(() => {
                if(window.addEventListener) {
                    window.addEventListener('resize', () => {
                        this.windowHeight = window.innerHeight;
                    });
                } else { //IE
                    window.attachEvent('onresize',() => {
                        this.windowHeight = window.innerHeight;
                    });
                }
            });
            this.windowHeight = window.innerHeight;
        },
        mounted () {
            this.$dataToBeSaved = {};
			this.pageHelpID = (this.currentUser ? this.currentUser.LeagueID : '');
            this.getHelp (this.$route.fullPath);
            if (Util.isMobile()) {
                document.body.classList.toggle('mini-sidebar');
                this.isCollapse = true;
            }
            this.windowHeight = window.innerHeight;
        },
    }

</script>

<style lang="scss">
[v-cloak] {
    display: none;
}
body {
	--bs-body-font-family: "Chakra Petch", "Avenir", Helvetica, Arial, sans-serif;
	--el-color-success: #28a745;
	--el-color-success-light-3: rgb(40, 167, 69, 0.8);
    --el-color-warning: #E6A23C;
	--el-color-danger: #dc3545;
	--el-color-error: #dc3545;
	--el-color-blue: #1976d2;
}
html .el-button--primary {
	--el-button-bg-color: #1976d2;
    --el-button-border-color: #1976d2;
}

@import url("//fonts.googleapis.com/css?family=Chakra+Petch");
@import "./assets/scss/helper.css";
@import "./assets/scss/style.css";
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import "../../node_modules/vue-client-recaptcha/dist/style.css";
@import url("//use.fontawesome.com/releases/v6.4.2/css/all.css");

.el-tag.el-tag--default:not(.el-tag--danger):not(.el-tag--warning):not(.el-tag--success):not(.el-tag--info) {
    --el-tag-bg-color: #1976d2;
    --el-tag-border-color: #1976d2;
    --el-tag-hover-color: var(--el-color-primary-light-3);
}
#app.login, #app.login .page-wrapper, #app.forgotpassword, #app.forgotpassword .page-wrapper {
	background-color: var(--bs-body-bg);
}
body #app {
	font-size: 13px;
}
.card-header{
	margin: -20px -20px 20px -20px;
}
.card-footer{
	margin: 20px -20px -20px -20px;
}
.card-text{
	padding: 10px;
}
#app i.fas, #app i.fa {
	font-family: "Font Awesome 6 Free Solid", "Font Awesome 6 Free" !important;
	font-weight: 900;
	text-rendering: optimizeLegibility;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
}
html, #app .page-wrapper { 
	background-color: var(--bs-body-bg);
}
div.el-message-box {
	width: 370px;
}
#app .toast-top-right {
	top: 62px;
}

#app .btn-primary {
	color: #fff;
}
#app .card-header h5 {
	margin-bottom:0;
	color: #fff;
}
#app .page-item.active a.page-link {
	z-index: 1;
	color: #fff;
}
th.HomePhoneNumber {
	min-width: 140px;
}
#app .pull-right {
	float:none;
	margin-left: 10px;
}
#app .form-control:disabled.not-available {
		border : 0px;
		-webkit-appearance: none;
		margin: 0px 0px 0px -11px;
}
#app select::-ms-expand.form-control:disabled.not-available  {
        display: none;
}
#app .btn-link:hover,  #app a:hover {
	text-decoration: underline;
}
#app .wizard__step__indicator {
	background-color: #333;
}
#app .wizard__step.active .wizard__step__indicator, #app .wizard__next.final-step {
	background-color: varl(--el-color-success);
}
#app .wizard__next.final-step:hover {
	background-color: var(--el-color-success);
	opacity: 0.5;
}
#app .el-form {
	width: 100%;
}
#app .el-select {
	width: auto;
}
#app .el-select.multiple {
	width: 100%;
}
#app .el-select.topDropdown, #app .el-select.fifty, #app .el-input.fifty, #app .el-textarea.fifty {
	width: 50%;
}
#app .el-select.oneHundred {
	width: 100%;
}
#app .el-form--label-top .el-form-item__label {
	margin:0;
	padding:0;
	line-height: 20px;
}
#app .el-form-item {
 	text-align: left;
 	width: 100%;
	margin-bottom: 15px;
}

#app .left .el-tooltip__popper,
#app .right .el-tooltip__popper {
	padding: 8px 10px;
}
#app .el-tooltip__popper {
	z-index:9999    !important;
}
#app .el-tooltop {
	height: 20px;
	width: 20px;
	border-radius: 100%;
	margin: 0 5px;
}
#app .is-required .el-form-item__label:before {
    content: '*';
    color: var(--el-color-danger);
    margin-right: 4px;
}
#app .el-button.badge-success {
	color: #fff;
	background-color: var(--el-color-success);
}
#app .el-button.badge-warning {
	color: #fff;
	background-color: var(--el-color-warning);
}
#app .el-button.badge {
	display: inline-block;
	padding: 0.22em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 100%;
}
#app .el-menu-item {
	height: 35px;
	line-height: 31px;
}
.el-menu--vertical .el-menu--popup .el-menu-item {
	height: 35px;
	line-height: 23px;
}
#app .el-sub-menu__title {
	height: 35px;
	line-height: 35px;
	color: #000000;
	border-bottom: 1px solid #d9d9d9;
}
.el-menu--vertical .el-submenu .el-menu-item {
	height: 32px;
	line-height: 32px;
	min-width: 214px;
}
#app .el-collapse-item__header {
	font-size: 18px;
	color: var(--el-color-danger);
}
#app .el-alert {
	border: 1px solid #ccc;
}
#app .el-select__tags-text {
	color: #000;
}
#app .el-tag.el-tag--info {
	border: 1px solid #cccccc;
}
#app .smallNumber input, #app input.smallNumber, #app .el-input.smallNumber {
	width: 75px;
	min-width: unset;
	text-align: center;
}
#app .smallCurrency input, #app .smallCurrency.el-input {
	width: 120px;
	text-align: center;
}
#app .row {
	width: 100%;
	margin-left: 0px;
	margin-right: 0px;
	padding-left: 0px;
	padding-right: 0px;
}
#app .card-body {
	padding: .25rem;
}
#app .pageTextIntro {
	margin-bottom: 15px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}
#app .el-table .cell {
  word-break: normal;
}
#app .el-form-item__content {
    line-height: 30px;
}
#app .el-date-editor--daterange.el-input, #app .el-date-editor--daterange.el-input__inner, #app .el-date-editor--timerange.el-input, #app .el-date-editor--timerange.el-input__inner {
    min-width: 270px;
	max-width: 400px;
    width: 100%
}
#app .unsavedTab {
    color: var(--el-color-danger);
}
#app .pageTextIntro  ul li {
    list-style: disc;
}
#app .pageTextIntro   li {
    margin-left: 10px;
    line-height: 20px;
}
#app .pageTextIntro   ul {
    padding-left: 24px;
}
#app .pageTextIntro p {
    margin-bottom: 0;
    line-height: 20px;
}
#app .pageTextIntro ol li {
    list-style: decimal;
}
#app table {
    page-break-after: always;
}
#app thead {
    display: table-header-group;
}
.el-message.newversion + .el-message.newversion {
    display:none;
}
.el-message.newversion {
    border: 2px solid var(--el-color-danger);
    background-color: #ffffff;
}
div.el-message.newversion .el-message__content {
    color: #000000;
}
div.el-message.newversion .el-message__closeBtn {
    border-radius: 5px;
    border: 1px solid var(--el-color-danger);
    color: var(--el-color-danger);
    background-color: #ffffff;
}
div.el-message.newversion .el-icon-info {
    color: #000000;
    font-size: 22px;
}
#app .el-date-editor .el-range-separator {
    min-width: 30px;
}
#app .el-button.badge.new {
    width: 38px;
    border-radius: 11px;
    padding-top: 2px;
	height: 23px
}
#app .el-button.badge.upgrade {
    width: 55px;
    border-radius: 11px;
    padding-top: 2px;
	height: 23px
}
#app button.btn {
	margin-right: 10px;
	margin-bottom: 10px;
}
#app button.btn i, #app button.el-button i {
	margin: 0px 3px;
}
#app .tags.el-input__inner {
	-webkit-appearance: none;
	background-color: #FFF;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #DCDFE6;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	font-size: inherit;
    height: auto;
    min-height: 40px;
	line-height: 40px;
	outline: 0;
	padding: 0 15px;
	-webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
	transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
	width: 100%;
}
div.el-notification__content p {
    text-align: left;
}
#app .el-table .sort-caret.descending {
	border-top-color: #ffffff;
}
#app .el-table .sort-caret.ascending {
	border-bottom-color: #ffffff;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.el-checkbox__input.is-checked span.el-checkbox__inner::after,  span.el-checkbox__inner::after, #app .el-transfer-panel span.el-checkbox__inner::after {
    height: 11px;
    width: 5px;
    margin-left: 2px;
    margin-top: 1px;
}
.el-table .el-checkbox__input.is-checked span.el-checkbox__inner::after,  .el-table span.el-checkbox__inner::after {
    height: 9px;
    width: 4px;
    margin-left: 0px;
    margin-top: 0px;
}
.el-table label.el-checkbox {
	height: 17px;
}
.el-transfer label.el-checkbox {
	border: 0;
}
#app span.el-checkbox__inner, span.el-checkbox__inner {
	height: 20px;
	width: 20px;
}
#app .el-table span.el-checkbox__inner, .el-table span.el-checkbox__inner {
	height: 15px;
	width: 15px;
}
#app label.el-checkbox {
	margin-bottom: 0px;
}
#app .el-table label.el-checkbox {
	border: 1px solid #ffffff;
}
#app i.fa-trash, #app i.fa-2x fa-trash-alt {
	color: var(--el-color-danger);
}
#app .btn-danger i.fa-trash, #app .btn-danger i.fa-2x fa-trash-alt {
	color: #FFFFFF;
}
#app .fa-2x {
    font-size: 18px;
}
#app i.success {
	color: var(--el-color-success);
}
#app i.danger {
	color: var(--el-color-danger);
}
#app h5.modal-title {
	color: var(--el-color-primary);
	font-size: 20px;
	font-weight: 800;
	margin-top: 0px;
}
#app i.far.fa-edit {
	color: var(--el-color-blue);
}
#app .v-btn__content i.far.fa-trash-alt {
	color: var(--el-color-danger);
}
#app .v-btn__content i.far.fa-envelope {
	color: var(--el-color-success);
}
#app .v-toolbar__side-icon {
	margin: 2px;
}
#app .el-alert.el-alert--info {
	padding: 3px 10px;
    margin-top: 3px;
}
#app .el-progress-bar__inner {
	background-color: var(--el-color-blue);
	border-radius:0;
}
#app .el-progress-bar__outer {
	height: 3px       !important;
	border-radius:0;
}
#app .row .col-12.fullpage {
	padding-left: 0px;
}
#app .row .card.col-12, #app .card.fullpage {
	margin-left: 0px;
}
#app i.fas.fa-times-circle, #app i.fa-solid.fa-circle-xmark {
	color: var(--el-color-danger);
}
#app i.fas.fa-plus-circle {
	color: var(--el-color-success);
}
#app .btn-icon i.fas.fa-plus-circle {
	color: #FFFFFF;
}
#app .danger {
	color: var(--el-color-danger)   !important;
}
#app .el-alert.notavailable .el-alert__title {
	font-size: 18px;
}
#app .el-alert.notavailable .el-alert__description {
	margin-top: 20px;
	font-size: 14px;
}
</style>
