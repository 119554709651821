<template>
    <div id="bankaccounts">
        <el-form ref="BankAccountForm" label-position="top" :model="BankAccount" label-width="120px" >
            <div class="row mt-4">
                <div class="col-12 fullpage">
                    <el-form-item label="Bank Account:" prop="BankAccountID">
                        <el-select key="selectseason" v-model="BankAccountID" placeholder="Select" @change="getBankAccount" class="topDropdown" default-first-option filterable>
                            <el-option v-if="$createAccess()" key="" label="-- Add New --" value=""></el-option>
                            <el-option
                                v-for="item in BankAccounts"
                                :key="item.BankAccountID"
                                :label="item.BankAccountName"
                                :value="item.BankAccountID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 fullpage">
                    <el-form-item label="Bank Account Name:" prop="BankAccountName"
                                  :rules="[{ required: true, message: 'Bank Account Name is Required', trigger: 'blur'}]"
                    >
                        <el-input v-model="BankAccount.BankAccountName">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Bank Account Code:" prop="BankAccountCode"
                                  :rules="[{ required: true, message: 'Bank Account Code is Required', trigger: 'blur'}]"
                    >
                        <el-input v-model="BankAccount.BankAccountCode" :disabled="(BankAccountID ? true: false)">
                        </el-input>
                    </el-form-item>
	                <el-form-item v-if="$propertyAccess('AllowedCards')" label="Credit Cards Allowed" prop="AllowedCards" autocomplete="off">
		                <div class="ccTypes">
			                <el-checkbox-group v-model="BankAccount.Params.AllowedCards">
				                <el-checkbox label="Visa" value="Visa" key="Visa"><span ><i class="fab fa-cc-visa"></i></span></el-checkbox>
				                <el-checkbox label="Mastercard" value="Mastercard" key="Mastercard"><span ><i class="fab fa-cc-mastercard"></i></span></el-checkbox>
				                <el-checkbox label="Amex" value="Amex" key="Amex"><span ><i class="fab fa-cc-amex"></i></span></el-checkbox>
				                <el-checkbox label="Discover" value="Discover" key="Discover"><span ><i class="fab fa-cc-discover"></i></span></el-checkbox>
			                </el-checkbox-group>
		                </div>
	                </el-form-item>
                    <el-form-item v-if="$propertyAccess('UN')" label="UserName" prop="UN" autocomplete="off">
                        <el-input v-model="BankAccount.UN" ref="UN" :disabled="$propertyDisabled('UN')" >
                        </el-input>
                    </el-form-item>
                    <el-form-item v-if="$propertyAccess('PD')" label="Password:" prop="PD" autocomplete="off">
                        <el-input v-model="BankAccount.PD" :disabled="$propertyDisabled('PD')" show-password>
                        </el-input>
                    </el-form-item>
                    <el-form-item v-if="$propertyAccess('PaymentURL')" label="Payment Gateway URL:" prop="PaymentURL"
                                  :rules="[{ required: true, message: 'Payment URL is Required', trigger: 'blur'}]"
                    >
                        <el-input v-model="BankAccount.PaymentURL" :disabled="$propertyDisabled('PaymentURL')">
                        </el-input>
                    </el-form-item>
                    <div v-if="$modifyAccess() || $createAccess()" class="mt-4 mb-4" style="text-align: center;">
                        <button class="btn btn-success btn-icon" type="button" @click.prevent="saveBankAccount">
                            <i class="fas fa-check"></i> <span> Save Changes</span>
                        </button>
                        <button v-if="BankAccountID && $modifyAccess()" type="button" class="btn btn-icon btn-danger delete"  @click="deleteCnfm">
                            <i class="far fa-trash-alt"></i>  <span>Delete</span>
                        </button>
                        <button class="btn btn-icon btn-secondary cancel" type="button" @click.prevent="getBankAccount">
                            <i class="fas fa-times"></i> <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    name: 'BankAccounts',
    data() {
        return {
            loading: false,
            currentStatus : STATUS_INITIAL,
            BankAccount : {Params : {AllowedCards : []}},
            BankAccounts : [],
            BankAccountID : ''
        }
    },
    props:["config","Season", "parentrefs"],
    computed: {
        ...mapGetters({ componentIDs: 'componentIDs' }),
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
    },
    methods: {
        getInitialData () {
            var tables = ['BankAccounts'];
            axios.post('/index/tableData', {tables: tables},
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (!this.BankAccountID && this.BankAccounts.length > 0) {
                            this.BankAccountID = this.BankAccounts[0]['BankAccountID'];
                        }

                        this.getBankAccount();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                    }
                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
            });
        },

        getBankAccount() {
            this.loading = true;
            let self = this;
            if (this.BankAccountID) {
                axios.post('/finance/getbankaccount', {
                        BankAccountID: this.BankAccountID,
                        modifyAccess: this.$modifyAccess()
                    },
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.BankAccount = (response.data.BankAccount ? response.data.BankAccount : {});
                            this.$stopProgressBar(this.parentrefs);
                        } else {
                            this.$failProgressBar(this.parentrefs);
                        }
                        this.loading = false;
                    }).catch(response => {
                    this.$failProgressBar(this.parentrefs);
                    this.loading = false;
                });
            } else {
	            this.BankAccount = {Params : {AllowedCards : []}};
            }
        },
        deleteCnfm (index,row) {
            this.$confirm('Are you sure you wish to delete this Bank Account?', 'Confirmation', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning',
                showClose: false,
                showCancelButton: true
            }).then(() => {
                this.deleteBankAccount();
            }).catch(() => {
            });

        },
        deleteBankAccount() {

            axios.post('/finance/deletebankaccount', {BankAccountID: this.BankAccountID}
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        this.$notify({
                            title: 'Success',
                            message: "Bank Account has been deleted",
                            type: 'success',
                            duration: 1500,
                            showClose: false
                        });
                        this.BankAccountID = '';
                        this.BankAccount = {Params : {AllowedCards : []}};
                        this.getInitialData();
                    } else {
                        this.$notify({
                            title: 'Error',
                            message: "Bank Account cannot be deleted",
                            type: 'error',
                            duration: 2000,
                            showClose: false
                        });
                    }
                }).catch(response => {
                this.$notify({
                    title: 'Error',
                    message: "Bank Account cannot be deleted",
                    type: 'error',
                    duration: 2000,
                    showClose: false
                });
            });
        },
        saveBankAccount () {
            this.$refs['BankAccountForm'].validate((valid) => {
                if (valid) {
                    if (!this.isDuplicate ()) {
                        if (this.currentStatus == STATUS_SAVING) {
                            return true;
                        }
                        this.currentStatus = STATUS_SAVING;
                        this.$startProgressBar(this.parentrefs);

                        axios.post('/finance/savebankaccount', {BankAccount: this.BankAccount}
                        )
                            .then(response => {
                                if (response.data.result == 'success') {
                                    this.currentStatus = STATUS_INITIAL;
                                    this.$stopProgressBar(this.parentrefs);
                                    this.BankAccountID = response.data.BankAccountID;
                                    this.getInitialData();
                                    this.$notify({
                                        title: 'Success',
                                        message: "BankAccount has been saved",
                                        type: 'success',
                                        duration: 1500,
                                        showClose: false
                                    });
                                    this.showModal = false;
                                } else {
                                    this.currentStatus = STATUS_FAILED;
                                    this.displayPopup (response.data.message);
                                    this.$failProgressBar(this.parentrefs);
                                }
                            }).catch(response => {
                                this.currentStatus = STATUS_FAILED;
                                this.$failProgressBar(this.parentrefs);
                        });
                    } else {
                        this.displayPopup ('A Bank with this Bank Account Code Already Exists.');
                    }
                }
            });
        },
        isDuplicate () {
            for (let i = 0; i < this.BankAccounts.length; i++) {
                if (this.BankAccounts[i].BankAccountID != this.BankAccount.BankAccountID &&
                    (this.BankAccounts[i].BankAccountCode.toLowerCase() == this.BankAccount.BankAccountCode.toLowerCase().trim()) ){
                    return true;
                }
            }
            return false;
        },
        displayPopup (message) {
            this.$alert(message, 'Error', {
                confirmButtonText: 'OK',
            });
        }
    },
    mounted () {
        this.getInitialData();
    },

}
</script>

<style>
	#bankaccounts .ccTypes .el-checkbox__label {
		font-size: 30px;
	}
</style>
