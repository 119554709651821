<template>
    <footer class="footer">
	    <span class="text-navbackground ">&copy; {{ (new Date()).getFullYear() }} <a class="text-navbackground" :href="config.Params.MainURL" target="_blank">{{ config.Params.SiteName }}</a></span>
    </footer>
</template>

<script>

    export default {
        props:["config"],
    }

</script>
<style scoped>

.footer {
	position:fixed;
	bottom:0;
    margin: 0;
    padding:5px 5px 5px 30px;
	z-index: 9999;
}
</style>
