<template>
	<!-- Left Sidebar  -->
	<div class="left-sidebar">
		<!-- Sidebar scroll-->
		<div class="scroll-sidebar">
			<!-- Sidebar navigation-->
			<nav class="sidebar-nav">
				<ul id="sidebarnav" :style="'height:' + sidebarHeight + 'px'">
					<el-menu v-if="!loading"
							:default-openeds="openedMenu"
							class="el-menu-vertical"
                            @open="handleOpen"
                            @close="handleClose"
							:collapse="isCollapse">
						<template v-for="(item, index) in navItems">
							<el-sub-menu v-if="item.name && item.children && AccessAllowed(item,'0')" :index="navType + '-' + index.toString()">
								<template #title>
									<i :class="item.icon"></i>
									<span v-html="convertedAreaName(item.name)"></span>
								</template>
								<template v-for="( childL1, index2 ) in item.children">
									<router-link v-if="AccessAllowed(childL1,index)" :to="childL1.url" >
										<el-menu-item @click="menuClicked" :index="navType + '-' + index + '-' + index2" :key="index+'-'+index2">
                                            <i :class="childL1.icon"></i>
											<span v-html="convertedAreaName(childL1.name)"></span>
                                        </el-menu-item>
									</router-link>
								</template>
								<template v-if="item.name == 'Reports'">
									<template v-for="( customreport, index3 ) in reportnavItems"  :key="navType + '-cr-' + index + '-' + index3">
										<router-link :to="customreport.url" exact>
										<el-menu-item route @click="menuClicked" :index="navType + '-cr-' + index + '-' + index3">
			                                    <i :class="customreport.icon"></i>
			                                    <span>{{customreport.name}}</span>
		                                </el-menu-item>
										</router-link>
									</template>
								</template>
                            </el-sub-menu>
							<router-link v-else-if="item.name && AccessAllowed(item, '0')" :to="item.url" >
								<el-menu-item  @click="menuClicked" :index="index.toString()">
										<i :class="item.icon"></i>
										<span v-html="convertedAreaName(item.name)"></span>
								</el-menu-item>
							</router-link>
						</template>
					</el-menu>
				</ul>
			</nav>
			<!-- End Sidebar navigation -->
		</div>
		<!-- End Sidebar scroll-->
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Util from '../../assets/js/util.js';

	export default {
		data (){
			return {
				openedMenu : [],
				unqiue : true,
				urlPath : '',
				sidebarHeight: 600,
				loading: true
			}
		},
		name: 'sidebar',
		props: {
            navItems: {
                type: Array,
                required: true,
                default: () => []
            },
            reportnavItems: {
                type: Array,
                required: true,
                default: () => []
            },
			isCollapse: {
				type: Boolean,
				required: true,
				default: false
			},
			config: {
				type: Object,
				required: true,
				default: () => {}
			},
			height: {
				type: Number,
				required: true,
				default: 600
			},
            navType: {
                type: String,
                required: true,
                default: 'R'
            }
		},
		computed: {
			...mapGetters({ currentUser: 'currentUser' }),
		},
		watch: {
			height: {
				handler(newVal, oldVal) {
					this.sidebarHeight =  newVal - 57;
				},
				deep: true
			},
		},
		methods: {
			convertedAreaName (SecurityAreaName) {
				return Util.convertSportsTerm(this.config,SecurityAreaName);
			},
            handleOpen(key, keyPath) {
	            if (key.substring(0,1) == 'R' || key.substring(0,1) == 'A') {
		            this.openedMenu.push(key);
		            localStorage.openedMenu = this.openedMenu;
	            }
            },
            handleClose(key, keyPath) {
	            const index = this.openedMenu.indexOf(key);
	            if (index > -1) {
		            this.openedMenu.splice(index, 1);
	            }
                localStorage.openedMenu = this.openedMenu;
            },
            AccessAllowed (item, index) {
				var access = false;
				if (this.$Plan.PlanType == 'X') {
					if (item.name == 'Admin Functions' || item.name == 'Plans') {
						return true;
					} else {
						return false;
					}
				}
				if (item.name == 'Reports' && this.reportnavItems.length > 0 ) {
                    return true;
                }
				if (item.name == 'Finances' && !this.$featureAllowed('finances') ) {
                    return false;
                }
				if (item.url == '/formsubmissionreport' && !this.$featureAllowed('formbuilder') ) {
                    return false;
				}				
				if (item.url == '/analyzer/registrationsettings' && !this.$featureAllowed('formbuilder') ) {
                    return false;
                }
				if (item.children) {
					//need to have access to at least one child or no access to dropdown
					for (let j=0; j < item.children.length; j++) {
						var fullPath = item.children[j].url.substr(1).split('/');
						if (item.children[j].security) {
							if (this.currentUser.RoleSecurity[fullPath[0]]) {
								if ((this.currentUser.RoleSecurity[fullPath[0]].Params.Access && this.currentUser.RoleSecurity[fullPath[0]].Params.Access == 'true') || this.currentUser.RoleSecurity[fullPath[0]].Params.ReadAccess) {
									access = true;
								}
							}
						} else {
							access = true;
						}
					}
				} else {
					if (item.security) {
						var fullPath = item.url.substr(1).split('/');
						if (this.urlPath == item.url.substr(1)) {
							if (this.openedMenu.length == 0) {
								//this.openedMenu = [index.toString()];
								this.urlPath = '';
							}
						}
						if (this.currentUser.RoleSecurity[fullPath[0]]) {
							if ((this.currentUser.RoleSecurity[fullPath[0]].Params.Access && this.currentUser.RoleSecurity[fullPath[0]].Params.Access == 'true') || this.currentUser.RoleSecurity[fullPath[0]].Params.ReadAccess) {
								access = true;
							}
						}
					} else {
						access = true;
					}
				}
				return access;
			},
			menuClicked(menu) {
				if (Util.isMobile()) {
					document.body.classList.toggle('mini-sidebar');
					let collapse = document.body.classList.contains('mini-sidebar');
					this.$emit('collapse',{isCollapse: true});
				}
			}
		},
		mounted() {
		    var opened = (localStorage.openedMenu ? localStorage.openedMenu : '');
		    this.openedMenu = (opened ? opened.split(',') : []);
			this.sidebarHeight = this.height - 57;
			this.urlPath = this.$route.fullPath.substr(1);
			//if the last path piece is numeric, get rid of it
			let urlParts = this.urlPath.split('/');
			if (!isNaN(urlParts[urlParts.length-1])) {
				urlParts.splice(-1,1);
				this.urlPath = urlParts.join('/');
			}
			this.loading = false;
		}
	}


</script>
<style>
	#sidebarnav div.el-sub-menu__title {
		height: 35px;
		border-bottom: 1px solid #d9d9d9;
	}
	#sidebarnav li.el-sub-menu ul.el-menu???:before {
		content: '';
		height: 100%;
		opacity: 1;
		width: 3px;
		background: #e0f3ff;
		position: absolute;
		left: 20px;
		top: 0;
		border-radius: 15px;
	}
</style>
<style scoped>
    .el-menu-vertical.el-menu--collapse span {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline-block;
    }
	#sidebarnav {
		overflow-y: auto;
		height: auto;
		border-right: 0;
	}
	.el-menu {
		border-right: 0;
		overflow-x: hidden;
	}
	.el-sub-menu__title {
		height: 35px;
		line-height: 35px;
		border-bottom: 1px solid #d9d9d9;
	}
	.el-menu-item a {
		display:inline-block;
		width:100%;
		height:100%;
	}
	.el-menu-item {
		padding-right: 0px;
	}
	.el-sub-menu.is-opened {
		border-bottom: 1px solid #d9d9d9;
	}
	.scroll-sidebar {
		margin-bottom: 5px;
		padding-bottom: 0px;
		border-bottom: solid 1px #e6e6e6;
	}

	li.el-menu-item.is-active, .router-link-active li.el-menu-item  {
        background-color: #ecf5ff;
    }
    #sidebarnav .el-menu-vertical.el-menu {
        margin-bottom: 30px;
    }
	#sidebarnav .el-menu-item * {
		vertical-align: baseline;
	}
	#sidebarnav i.fas, #sidebarnav i.fa, #sidebarnav i.far, #sidebarnav i.fab, #sidebarnav i.fa-solid {
		padding-right: 5px;
		min-width: 22px;
	}
	#sidebarnav div.el-sub-menu__title {
		height: 35px;
		border-bottom: 1px solid #d9d9d9;
	}
</style>
