<template>

  <div class="ds-week-view-container">

    <ds-week-header
      :calendar="calendar"
      :config="config"
      :placeholder="placeholder"
      :placeholder-for-create="placeholderForCreate"
      :days="calendar.days"
      :scroll-push="scrollPush"
      @edit="edit"
      @mouse-enter-day="mouseEnterDay"
      @mouse-leave-day="mouseLeaveDay"
      @mouse-move="mouseMoveDay"
      @mouse-down="mouseDownDay"
      @mouse-up="mouseUpDay"
    ></ds-week-header>

    <div class="ds-week-view-bottom">

      <div class="ds-week-view-scrollable" ref="scrollArea">

        <div class="ds-week-view-pane" ref="pane" :style="dayHeight">

          <div class="ds-week">

            <div class="ds-hour-list">
				<template v-for="(hour, i) in hours">
		          <div v-if="i >= $dayspan.startHour"
		              class="ds-hour"
		              :style="'height: ' + $dayspan.hourHeight + 'px;'"
		              :class="hourClasses[ i ]">
		            <div class="ds-hour-text">{{ hour }}</div>

		          </div>
				</template>
            </div>

            <template v-for="day in liveDays" :key="day.dayIdentifier">

              <ds-day-times
                :day="day"
                :config="config"
                :locationid="locationid"
                :filterlocationids="filterlocationids"
                :placeholder="placeholder"
                :placeholder-for-create="placeholderForCreate"
                :calendar="calendar"
                @edit="edit"
                @mouse-enter-day="mouseEnterDay"
                @mouse-leave-day="mouseLeaveDay"
                @mouse-move="mouseMove"
                @mouse-down="mouseDown"
                @mouse-up="mouseUp"
              ></ds-day-times>

            </template>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import { Calendar, CalendarEvent, Constants, Functions as fn } from 'dayspan';


export default {

  name: 'dsDaysView',

  props:
  {
    calendar:
    {
      required: true,
      type: Calendar
    },
	config:
	{
	  type: Object,
	  default() {
		  {};
	  }
	},
    placeholder:
    {
      type: CalendarEvent
    },

    placeholderForCreate:
    {
      type: Boolean,
      default: false
    },

    scrollToFirst:
    {
      type: Boolean,
      default() {
        return true;
      }
    },

    scrollBuffer:
    {
      type: Number,
      default() {
        return 60;
      }
    },

    hours:
    {
      type: Array,
      default() {
        return [
	        '    ', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am',
	        '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'
        ];
      }
    },
    locationid:
    {
      type: Number,
      default: 0
    },
    filterlocationids:
    {
      type: Array,
      default: []
    },
  },

  computed:
  {
    dayHeight()
    {
      return {
        height: this.$dayspan.dayHeight + 'px'
      };
    },

    hourClasses()
    {
      var currentHour = this.$dayspan.now.hour;

      return this.hours.map((hour, index) =>
      {
        return {
          'ds-same-hour': index === currentHour,
          'ds-past-hour': index < currentHour
        };
      });
    },
    liveDays () {
      var days = [];
      var startIdx = -1;
      for (let i=0; i < this.calendar.days.length; i++) {
        if (this.calendar.type == 1) {
          days.push(this.calendar.days[i]);
        } else if (this.calendar.days[i].currentDay || this.calendar.days.length < 7) {
          startIdx = i;
          days.push(this.calendar.days[i]);
        } else if (startIdx >= 0 && i < startIdx + this.calendar.size) {
          days.push(this.calendar.days[i]);
        }
      }
      return days;
    }
  },

  data: vm => ({
    scrollPush: 0
  }),

  watch:
  {
    'calendar.start': 'scrollToEvent'
  },

  mounted()
  {
    this.scrollToEvent();
    this.scrollPush = this.getScrollPush();
  },

  methods:
  {
	  mouseEnterDay($event)
	  {
		  this.$emit('mouse-enter-day', $event);
	  },

	  mouseLeaveDay($event)
	  {
		  this.$emit('mouse-leave-day', $event);
	  },

	  mouseMoveDay($event)
	  {
		  this.$emit('mouse-move-day', $event);
	  },

	  mouseDownDay($event)
	  {
		  this.$emit('mouse-down-day', $event);
	  },

	  mouseUpDay($event)
	  {
		  this.$emit('mouse-up-day', $event);
	  },
	  mouseMove($event)
	  {
		  this.$emit('mouse-move', $event);
	  },

	  mouseDown($event)
	  {
		  this.$emit('mouse-down', $event);
	  },

	  mouseUp($event)
	  {
		  this.$emit('mouse-up', $event);
	  },
	edit(calendarEvent)
	{
	  this.$emit('edit', calendarEvent);
	},
    scrollToEvent()
    {
      if (this.scrollToFirst)
      {
        let last = Constants.MINUTES_IN_DAY;
        let first = this.calendar
          .iterateDays()
          .reduce( last,
            (day, first) => day.iterateEvents().reduce( first,
              (event, first) => Math.min( first, event.start.hour * Constants.MINUTES_IN_HOUR + event.start.minute ),
              (event) => !event.fullDay
            )
          );

        if (last !== first)
        {
          let dayDelta = (first - this.scrollBuffer) / Constants.MINUTES_IN_HOUR;
          let dayPixels = dayDelta * this.$dayspan.hourHeight;

          this.$refs.scrollArea.scrollTop = Math.floor( dayPixels );
        }
      }
    },
    getScrollPush()
    {
      var area = this.$refs.scrollArea;
      var pane = this.$refs.pane;

      return area && pane ? (area.offsetWidth - pane.offsetWidth) : 0;
    }
  }

}
</script>

<style scoped lang="scss">

.ds-week-view-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .ds-week-view-bottom {
    flex: 1 1 60%;
    overflow: hidden;
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .ds-week-view-scrollable {
    overflow-y: scroll;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
  }
  .ds-week-view-pane {
    width: 100%;
    overflow-y: hidden;
    flex: none;
    display: flex;
    align-items: flex-start;

    .ds-day {
      position: relative;
    }
  }
  .ds-week {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .ds-hour-list {
    flex: none;
    width: 44px;
    border-right: #e0e0e0 1px solid;
    user-select: none;

    .ds-hour {
      text-align: center;
      border-bottom: none;

      .ds-hour-text {
        display: block;
        position: relative;
        top: -4px;
        font-size: 10px;
        color: #212121;
      }

      /*
      &.ds-same-hour {
        border-right: 3px solid #4285f4;
        background-color: #f5f5f5;
      }

      &.ds-past-hour {
        background-color: #fafafa;
      }
      */
    }
  }
}

</style>
