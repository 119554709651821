<template>
	<transition name="fade" v-loading="loading">
	<!-- Start Page Content -->
        <div id="customerdashboard">
            <div class="row">
                <div class="col-12 fullpage">
                    <el-select v-model="CustomerID" placeholder="Select Customer" class="topDropdown" popper-class="Analyzer" default-first-option filterable @change="changedCustomer">
                        <el-option
                            v-for="item in Customers"
                            :key="item.CustomerID"
                            :label="item.CustomerName"
                            :value="item.CustomerID">
                        </el-option>
                    </el-select>
                </div>
	            <!-- column -->
                <div class="col-12 fullpage">
                    <div class="card fullpage">
                        <div class="card-body">
                            <h5>Plans</h5>
                            <el-table
                                :data="Plans"
                                stripe border
                                empty-text="No Plans"
                                key="Plans"
                                size="small"
                                style="width: 100%">
                                <el-table-column 
                                        type="expand" 
                                        label="Details" 
                                        width="80"
                                        fixed>
                                    <template #default="props">
                                        <div style="padding: 10px;font-size: 12px;">
                                            <div class="row" style="font-weight: 800; color: #fff; background-color: #ccc;">
                                                <div class="col-5">
                                                    <span v-html="'Feature Name'"></span>
                                                </div>
                                                <div class="col-4">   
                                                    <span v-html="'Value'"></span>  
                                                </div>
                                                <div class="col-3" style="text-align: right;" v-html="'Monthly Fee'">
                                                </div>
                                            </div>
                                            <template v-for="(feature, index) in props.row.Features">
                                                <template v-if="feature.SortOrder > 0">
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <span v-html="feature.FeatureName"></span>
                                                        </div>
                                                        <div class="col-5">   
                                                            <span v-html="feature.Params.Value"></span>  
                                                        </div>
                                                        <div class="col-2" style="text-align: right;" v-html="formatPrice(feature.Params.PaymentAmount)">
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="PlanType"
                                        label="Plan Type"
                                        min-width="150">
                                    <template #default="scope">
                                        <span v-html="scope.row.PlanTypeF" ></span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="Start/End Dates"
                                        min-width="150">
                                    <template #default="scope">
                                        Start Date: {{scope.row.StartDateF}}<br>
                                        End Date: {{scope.row.EndDateF}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="MonthlyFee"
                                        label="Monthly Fee"
                                        align="right"
                                        width="150px">
                                    <template #default="scope">
                                        <div v-if="scope.row.EndDateF == 'Ongoing'" >
                                            {{scope.row.PlanPrice}}
                                        </div>
                                        <div v-else>
                                            {{scope.row.PlanPrice}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="col-12 fullpage">
                    <div class="card fullpage">
                        <div class="card-body">
                            <h5>Payment Method</h5>
                            <template v-if="paymentMethod.billing_details">
                                <div class="row">
                                    <div class="col-2">Name on Card:</div><div class="col-10" style="font-weight: 800;" v-html="paymentMethod.billing_details.name"></div>
                                </div>
                                <div class="row">
                                    <div class="col-2">Card Type:</div><div class="col-10" style="font-weight: 800;" v-html="cardBrand"></div>
                                </div>
                                <div class="row">
                                    <div class="col-2">Last 4 Digits:</div><div class="col-10" style="font-weight: 800;" v-html="'...&nbsp;' + paymentMethod.card.last4"></div>
                                </div>
                                <div class="row">
                                    <div class="col-2">Expiry:</div><div class="col-10" style="font-weight: 800;" v-html="lpad(paymentMethod.card.exp_month.toString(), 2) + ' / ' + paymentMethod.card.exp_year"></div>
                                </div>
                            </template>
                            <template v-else>
                                <strong>No Payment Details on File</strong>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-12 fullpage">
                    <div class="card fullpage">
                        <div class="card-body">
                            <h5>Payments</h5>
                            <el-table
                                :data="Payments"
                                stripe border
                                key="Payments"
                                empty-text="No Payments"
                                size="small"
                                style="width: 100%">
                                <el-table-column 
                                        type="expand" 
                                        label="Details" 
                                        width="80"
                                        fixed>
                                    <template #default="props">
                                        <div style="padding: 10px;font-size: 12px;">
                                            {{ props.row.Params }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="PaymentDateF"
                                        label="Date"
                                        min-width="100">
                                </el-table-column>
                                <el-table-column
                                        prop="Description"
                                        label="Description"
                                        min-width="150">
                                </el-table-column>
                                <el-table-column
                                        prop="PaymentAmount"
                                        label="Payment Amount"
                                        align="right"
                                        min-width="100">
                                    <template #default="scope">
                                        <span v-html="formatPrice(scope.row.PaymentAmount)"></span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="col-12 fullpage">
                    <div class="card fullpage">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <h5>Team Count</h5>
                                    <el-table
                                        :data="TeamCount"
                                        stripe border
                                        empty-text="No Teams"
                                        key="Teams"
                                        size="small">
                                        <el-table-column
                                                prop="SeasonName"
                                                label="Season"
                                                align="center"
                                                min-width="100">
                                        </el-table-column>
                                        <el-table-column
                                                prop="NumOfTeams"
                                                label="Num of Teams"
                                                align="center"
                                                min-width="100">
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div class="col-6">
                                    <h5>League Count</h5>
                                    <el-table
                                        :data="LeagueCount"
                                        stripe border
                                        empty-text="No Leagues"
                                        key="Leagues"
                                        size="small">
                                        <el-table-column
                                                prop="SeasonName"
                                                label="Season"
                                                align="center"
                                                min-width="100">
                                        </el-table-column>
                                        <el-table-column
                                                prop="NumOfLeagues"
                                                label="Num of Leagues"
                                                align="center"
                                                min-width="100">
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</transition>
</template>

<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import Util from '../../assets/js/util';

export default {
	name: 'CustomerDashboard',
	data () {
		return {
		    loading: true,
            Customers: [],
			CustomerID : '',
            Plans : [],
            paymentMethod : {},
            Payments : [],
            TeamCount : [],
            LeagueCount : [],
		}
	},
	props:["config","parentrefs"],
    computed: {
        ...mapGetters({currentUser: 'currentUser'}),
        ...mapGetters({componentIDs: 'componentIDs'}),
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
        cardBrand () {
            if (this.paymentMethod.card && this.paymentMethod.card.brand) {
                if (this.paymentMethod.card.brand == 'visa') {
                    return '<i class="fab fa-2x fa-cc-visa"></i>';
                } else if (this.paymentMethod.card.brand == 'mastercard') {
                    return '<i class="fab fa-2x fa-cc-mastercard"></i>';
                } else if (this.paymentMethod.card.brand == 'amex') {
                    return '<i class="fab fa-2x fa-cc-amex"></i>';
                } else if (this.paymentMethod.card.brand == 'discover') {
                    return '<i class="fab fa-2x fa-cc-discover"></i>';
                } else {
                    return this.paymentMethod.card.brand;
                }   
            } else {
                return '';
            }
        },
    },
    methods: {
        changedCustomer () {
            this.$store.dispatch('setID', {'ADCustomerID': this.CustomerID});
            this.getCustomerData();
        },
        formatPrice(value) {
            if (value == 'n/a') {
                return 'n/a';
            } else {
                return Util.formatPrice(this.config, value , '0.00');
            }
        },
        lpad (str, length) {
            var padString = '0';
            while (str.length < length)
                str = padString + str;
            return str;
        },
        getInitialData () {
            this.loading = true;
	        var tables = ['Customers'];
            axios.post('/index/tableData', {'tables': tables},
            )
                .then(response => {
                    if (response.data.result == 'success') {
                        for (let value of tables) {
                            this[value] = response.data[value];
                        }
                        if (!this.CustomerID && this.componentIDs.ADCustomerID) {
                            this.CustomerID = this.componentIDs.ADCustomerID;
                        }
	                    this.getCustomerData();
                    } else {
                        this.$failProgressBar(this.parentrefs);
                        this.loading = false;
                    }
                }).catch(response => {
                this.$failProgressBar(this.parentrefs);
                this.loading = false;
            });
        },
        getCustomerData () {
            this.TeamCount = [];
            this.LeagueCount = [];
            if (this.CustomerID) {
                this.loading = true;
                axios.post('/analyzer/index/customerdata', {CustomerID: this.CustomerID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.TeamCount = (response.data.TeamCount ? response.data.TeamCount : []);
                            this.LeagueCount = (response.data.LeagueCount ? response.data.LeagueCount : []);
                            this.getPlans();
                        } else {
                            this.loading = false;
                        }
                    }).catch(response => {
                        this.loading = false;
                });
            } else {
                this.loading = false;
            }
        },
        getPlans () {
            this.Plans = [];
            this.paymentMethod = {};
            this.Payments = [];
            if (this.CustomerID) {
                this.loading = true;
                axios.post('/plans/getall', {CustomerID: this.CustomerID}
                )
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.Plans = (response.data.Plans ? response.data.Plans : []);
                            this.paymentMethod = (response.data.paymentMethod ? response.data.paymentMethod : {});
                            this.Payments = (response.data.Payments ? response.data.Payments : []);
                        }
                        this.loading = false;
                    }).catch(response => {
                        this.loading = false;
                });
            } else {
                this.loading = false;
            }

        }
    },
	mounted() {
        //only customerid = 0 can use this screen
        let self = this;
        if (this.currentUser.CustomerID != 0) {
            setTimeout(() => { self.$router.go('/dashboard'); }, 100);
            this.$router.replace('/dashboard');
        }
		this.getInitialData();
	},
}
</script>

<style>
	#customerdashboard div.el-table th {
		font-weight: 800;
	}
    #customerdashboard div.el-table .cell {
		word-break: normal;
	}
    #customerdashboard .div-row td {
        text-align: left;
        font-weight: 800;
        font-color: #fff;
        background-color: #999999;
    }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#customerdashboard .card {
		padding: 20px;
	}

</style>
